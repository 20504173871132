interface EqualIconProps {
  fill?: string;
  className?: string;
}

const EqualIcon = ({ fill, className }: EqualIconProps) => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M5.30306 3.34913C5.03337 3.34913 4.77041 3.30622 4.5142 3.22042C4.27147 3.12032 4.02874 3.01307 3.78602 2.89867C3.43541 2.74137 3.16571 2.61981 2.97692 2.53401C2.78814 2.43391 2.59935 2.38386 2.41056 2.38386C2.0195 2.38386 1.81048 2.69846 1.78351 3.32767H0.327148C0.354118 2.46966 0.542906 1.79755 0.893513 1.31134C1.24412 0.825135 1.70935 0.582031 2.2892 0.582031C2.59935 0.582031 2.88927 0.632082 3.15897 0.732184C3.42867 0.832285 3.68488 0.953837 3.92761 1.09684C4.1973 1.25414 4.41306 1.36854 4.57488 1.44004C4.7367 1.51155 4.90526 1.5473 5.08056 1.5473C5.53905 1.5473 5.78178 1.23984 5.80875 0.624932H7.24488C7.21791 1.42574 7.0561 2.08355 6.75943 2.59836C6.46276 3.09887 5.97731 3.34913 5.30306 3.34913Z'
        fill={fill}
      />
      <line
        x1='0.327148'
        y1='6.80823'
        x2='7.24488'
        y2='6.80823'
        stroke={fill}
        strokeWidth='1.38355'
      />
    </svg>
  );
};

export default EqualIcon;
