import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';

import liquidLockingABI from '@/abis/liquid-locking';
import client from '@/services/blockchain/client';
import {
  LockedToken,
  UnlockedToken,
} from '@/services/blockchain/liquid-locking/types';

export const getUnbondPeriod = async (
  liquidLockingAddress: string,
): Promise<string> => {
  const response = await client({
    address: liquidLockingAddress,
    method: 'unbondPeriod',
    abi: AbiRegistry.create(liquidLockingABI),
  });

  const value = parseInt(response);

  return (value + 1).toString();
};

export const getLockedTokenAmounts = async (
  liquidLockingAddress: string,
  accountAddress: string,
): Promise<LockedToken[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: liquidLockingAddress,
    method: 'lockedTokenAmounts',
    abi: AbiRegistry.create(liquidLockingABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response || [];
};

export const getUnlockedTokenAmounts = async (
  liquidLockingAddress: string,
  accountAddress: string,
): Promise<UnlockedToken[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: liquidLockingAddress,
    method: 'unlockedTokenAmounts',
    abi: AbiRegistry.create(liquidLockingABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response || [];
};
