export enum TABS {
  SUPPLY = 'supply',
  WITHDRAW = 'withdraw',
  COLLATERAL = 'collateral',
}


export const SUPPLY_TABS = [TABS.SUPPLY, TABS.WITHDRAW, TABS.COLLATERAL];

export const getTabIndex = (id: TABS) => {
  return SUPPLY_TABS.indexOf(id as TABS);
};