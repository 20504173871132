import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';

import useSetVariantOnLoadingEnd, {
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';

import clsxm from '@/services/clsxm';

import { Question, Stone } from './components';

const Faqs: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation('liquid-app');

  const items = Array(t('questions', { joinArrays: '%' }).split('%').length)
    .fill(null)
    .map((_, i) => ({
      q: t(`questions.${i}.q`),
      a: t(`questions.${i}.a`),
      list: t(`questions.${i}.list`).startsWith('key')
        ? t(`questions.${i}.list`, { joinArrays: '%' }).split('%')
        : [],
    }));

  const [open, setOpen] = useState(0);
  const { controls } = useSetVariantOnLoadingEnd({ timeout: 150 });

  const scrollable = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (open == items.length - 1) {
      setTimeout(() => {
        if (!scrollable?.current) return;
        scrollable.current.scrollTo({
          top: scrollable.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, scrollable.current]);

  const scrollableNodeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!scrollableNodeRef?.current) return;

    const handler = (e: React.MouseEvent) => {
      const target = e.currentTarget;
      toggleBorder({
        t: target.scrollTop <= 2,
        b: target.clientHeight + target.scrollTop >= target.scrollHeight,
      });
    };

    scrollableNodeRef?.current?.addEventListener('scroll', handler as any);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollableNodeRef?.current?.removeEventListener('scroll', handler as any);
    };
  }, [scrollableNodeRef]);

  const [showBorder, toggleBorder] = useState({ t: true, b: false });

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        custom={{ reversed: true, movePx: 12 }}
        initial='hidden'
        variants={TRANSITION_VARIANTS}
        animate={controls}
        className={clsxm('relative')}
      >
        <Stone />

        <div className={clsxm('relative mb-4', className)}>
          <div className='mb-[15px] font-hass text-[18px] md:text-[20px] font-semibold leading-[24px]'>
            {t('faq')}
          </div>

          <div className='relative w-full'>
            <div
              className={clsxm(
                'pointer-events-none absolute inset-x-0 top-0 z-10 h-6',
                '[background-image:linear-gradient(to_bottom,var(--start-color),transparent)]',
                'transition-opacity duration-300 ease-out',
                '[--start-color:#FAFAFD] dark:opacity-0'
              )}
            />
            <div
              className={clsxm(
                'pointer-events-none absolute inset-x-0 top-0 z-10 h-6',
                '[background-image:linear-gradient(to_bottom,var(--start-color),transparent)]',
                'dark:oapcity-100 opacity-0 [--start-color:#0C0B21]',
                'transition-opacity duration-300 ease-out'
              )}
            />
            <div className='theme-switch-transition pointer-events-none absolute inset-x-0 bottom-0 z-10 h-6 [background:linear-gradient(to_top,#FAFAFD,transparent)] dark:[background:linear-gradient(to_top,#0C0B21,transparent)]'></div>
            <div
              className={clsxm(
                'pointer-events-none absolute inset-x-0 top-0 z-30 h-6',
                'rounded-t-2xl border-2 border-b-0 border-[#F4F1FD] dark:border-[#2E2C51]',
                showBorder.t ? 'opacity-100' : 'opacity-0',
                'theme-switch-transition'
              )}
            />

            <SimpleBar
              scrollableNodeProps={{ ref: scrollableNodeRef }}
              className='track-none'
              style={{
                height: 490,
                width: 'calc(100% + 20px)',
              }}
            >
              <ul
                ref={scrollable}
                className={clsxm(
                  'mb-6 flex flex-col gap-[15px]',
                  '[width:calc(100%-20px)]'
                )}
              >
                {items.map((e, i) => (
                  <li key={i}>
                    <Question
                      {...e}
                      open={i == open}
                      onChange={() => {
                        setOpen((_) => i);
                      }}
                    />
                  </li>
                ))}
              </ul>
            </SimpleBar>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default Faqs;
