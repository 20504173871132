import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useToggle } from 'usehooks-ts';

import ProposalItem, { ProposalItemProps } from '@/components/ProposalItem';

import { governanceSelector, PROPOSAL_STATUS } from '@/store/governance';
import { useAppSelector } from '@/store/index';
import { protocolSelector } from '@/store/protocol';

export const LatestProposals = ({
  selectedFilterTag,
}: {
  selectedFilterTag: string;
}) => {
  const [showMore, toggleShowMore] = useToggle(false);
  const {
    governance: { blockNonce, votingDelayInBlocks, votingPeriodInBlocks },
    isLoadingFirstInfo
  } = useAppSelector(protocolSelector);
  const { proposals } = useAppSelector(governanceSelector);
  const { t } = useTranslation('governance');

  const latestProposals = useMemo(() => {
    const filteredProposals = proposals.filter(({ status }) =>
      [
        PROPOSAL_STATUS.defeated,
        PROPOSAL_STATUS.executed,
        PROPOSAL_STATUS.succeeded,
      ].includes(status)
    );

    const populatedProposals = filteredProposals.map(
      ({
        id,
        title,
        status,
        description,
        downvotes,
        upvotes,
        percentageDownVotes,
        percentageUpVotes,
        publishedBlock,
        tag,
      }) => {
        const votingStart = new DefiUtils(publishedBlock).plus(
          votingDelayInBlocks
        );
        const votingEnd = votingStart.plus(votingPeriodInBlocks);
        const votingEndInMiliseconds = new DefiUtils(votingEnd)
          .minus(blockNonce)
          .multipliedBy(6)
          .dividedBy(3600)
          .multipliedBy('3.6e+6')
          .toString();

        const finishTimestamp = new Date(
          new Date().getTime() + parseFloat(votingEndInMiliseconds)
        ).toISOString();

        return {
          id,
          tag,
          title,
          status,
          voteEndTimestamp: finishTimestamp,
          finishTimestamp,
          upvotes,
          percentageUpVotes,
          downvotes,
          percentageDownVotes,
          description,
        };
      }
    ) as ProposalItemProps[];

    return populatedProposals;
  }, [blockNonce, proposals, votingDelayInBlocks, votingPeriodInBlocks]);

  const filteredActiveProposals = useMemo(() => {
    if (selectedFilterTag === '') {
      return latestProposals;
    }

    return latestProposals.filter(
      (proposalItem) => selectedFilterTag === (proposalItem?.tag || '')
    );
  }, [latestProposals, selectedFilterTag]);

  return (
    <div className={classNames('')}>
      <div className={classNames('flex', 'justify-between', 'mb-6')}>
        <div className={classNames('text-[16px] font-semibold leading-[125%]')}>
          {t('latest-proposals', 'Latest Proposals')}
        </div>
      </div>

      {/* proposals */}
      <div className={classNames('flex', 'flex-col', 'gap-6')}>
        {isLoadingFirstInfo ? (
          <Skeleton width='100%' height={100} className='!rounded-[20px]' />
        ) : (
          <>
            {filteredActiveProposals.length === 0 && (
              <motion.div
                className={classNames('flex w-full justify-center')}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src='https://cdn.app.hatom.com/images/governance/proposal/no-latest.svg'
                  alt='no active proposals'
                  className='h-full w-full max-w-[329px]'
                />
              </motion.div>
            )}
            {filteredActiveProposals
              .slice(0, showMore ? latestProposals.length : 3)
              .map((proposal) => (
                <ProposalItem key={proposal.id} {...proposal} />
              ))}
          </>
        )}
      </div>

      {/* show more */}
      {!showMore &&
        !isLoadingFirstInfo &&
        filteredActiveProposals.length > 3 && (
          <div
            className={classNames(
              'bg-[#EAE6F7]',
              'h-[36px]',
              'w-100%',
              'border-[1.4px]',
              'border-[#E0DBF0]',
              'flex',
              'items-center',
              'justify-center',
              'rounded-[20px]',
              'mt-6',
              'cursor-pointer',
              'dark:border-[#2E2C51]',
              'dark:bg-[#171430]',
              'dark:text-[#625E8F]'
            )}
            onClick={toggleShowMore}
          >
            {t('show-more', 'Show more')}
          </div>
        )}
    </div>
  );
};
