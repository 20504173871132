import React from 'react';

import useTheme from '@/hooks/useTheme';

import clsxm from '@/services/clsxm';

const ResponsiveBgImage: React.FC<any> = () => {
  const { isDark } = useTheme();

  return (
    <div
      className={clsxm(
        'absolute top-0 -left-[0.05rem] md:-left-1 -z-10 h-fit',
        'w-[100%] md:w-[100%] xl:w-[100%]',
        'dark:w-[101%]',
      )}
    >
      <img
        src={
          !isDark
            ? '/images/liquidstaking/app/card-bg-sm.svg'
            : '/images/liquidstaking/app/card-bg-sm-dark.svg'
        }
        alt='card'
        className='h-[200px] w-full -translate-y-1.5 object-fill block md:hidden'
      />
      <img
        src={
          !isDark
            ? '/images/liquidstaking/app/card-bg.svg'
            : '/images/liquidstaking/app/card-bg-dark.svg'
        }
        alt='card'
        className='h-full w-full object-cover hidden md:block xl:hidden'
      />
      <img
        src={
          !isDark
            ? '/images/liquidstaking/app/card-bg-xl.svg'
            : '/images/liquidstaking/app/card-bg-xl-dark.svg'
        }
        alt='card'
        className='h-full w-full object-cover hidden xl:block'
      />
    </div>
  );
};

export default ResponsiveBgImage;
