import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';

import { ReturnUseSupplyForm } from '@/components/popups/SupplyPopup/hooks/useSupplyForm';
import { TABS } from '@/components/popups/SupplyPopup/types/tab';
import isRemainingValue from '@/components/popups/SupplyPopup/utils/isRemainingValue';

import { useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import { boosterV2Selector } from '@/store/booster-v2';
import { lendAppSelector } from '@/store/lend-app';
import { popupSelector } from '@/store/popup';
import {
  calcHasMaxMarketPerAccount,
  H_TOKEN_DECIMALS,
  hasEnoughEGLDBalanceSelector,
  MARKET_KEY,
  protocolSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';
import { hasPendingTransactionsSelector } from '@/store/transaction';

import {
  getBorrowLimitUsedPercent,
  getTokenBorrowLimit,
  subtractGasFee,
  wadBasicUnit,
} from '@/utils/helpers';
import {
  getTotalSupplyAPYByMarket,
  getTotalSupplyAPYByMarketLiquidity,
} from '@/utils/math/apy';

const useSupplyData = ({ supplyForm }: { supplyForm: ReturnUseSupplyForm }) => {
  const {
    isAddingCollateral,
    inputValueAsHTokenBigNumber,
    inputValueAsBigNumber,
    isSupplying,
    selectedTab,
    maxSelected,
    inputValue,
    isCollateralying,
    isRemovingCollateral,
    isWithdrawing,
    isRemovingCollateralAndWithdraw,
    isAddingSupplyAndCollateral,
  } = supplyForm;

  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    userBalances,
    controller,
    marketsInteracted,
    marketsInteractedAmount,
  } = useAppSelector(protocolSelector);
  const { proxyAddress, selectedTypeAddress } = useAppSelector(accountSelector);
  const { controllerRewardBatches: controllerRewardBatchesV2 } =
    useAppSelector(boosterV2Selector);

  const { markets } = useAppSelector(lendAppSelector);
  const {
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
    account: { borrowBalanceUSD, borrowLimitUSD },
  } = useAppSelector(lendAppSelector);
  const { markets: controllerRewardBatchesV1 } =
    useAppSelector(rewardBatchSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);

  const {
    data: { assetKey },
  } = useAppSelector(popupSelector);

  const { t } = useTranslation();
  const { isLoggedIn } = useLogin();

  const market = markets[assetKey as MARKET_KEY];

  const hasMaxMarketPerAccount = useMemo(
    () =>
      calcHasMaxMarketPerAccount({
        userBalances,
        controller,
        token: market.underlying.symbol as MARKET_KEY,
      }),
    [market.underlying.symbol, controller, userBalances],
  );

  const appliedCollateralFactor = useMemo(() => {
    if (new DefiUtils(userBalances[MARKET_KEY.USH]?.borrowBalance).isZero()) {
      return market.collateralFactor;
    }

    return market.ushBorrowerCollateralFactor;
  }, [
    market.ushBorrowerCollateralFactor,
    market.collateralFactor,
    userBalances,
  ]);

  const supplyControllerRewardBatchesV1 = useMemo(() => {
    const rewards =
      controllerRewardBatchesV1[market.underlying.symbol as MARKET_KEY]
        ?.rewards || [];

    return rewards
      .filter(({ type }) => type === 'Supply')
      .filter(({ speed }) => new DefiUtils(speed).isGreaterThan('0'));
  }, [market.underlying.symbol, controllerRewardBatchesV1]);

  const supplyControllerRewardBatchesV2 = useMemo(() => {
    const rewards =
      controllerRewardBatchesV2.find(
        ({ tokenId }) => tokenId === market.hToken.id,
      )?.rewards || [];

    return rewards.filter(({ speed }) =>
      new DefiUtils(speed).isGreaterThan('0'),
    );
  }, [controllerRewardBatchesV2, market.hToken.id]);

  const supplyControllerAPYV1 = useMemo(() => {
    return (
      controllerRewardBatchesV1[market.underlying.symbol as MARKET_KEY]
        ?.totalRewardsSupplyAPY || '0'
    );
  }, [market.underlying.symbol, controllerRewardBatchesV1]);

  /** For EGLD, it considers the cost of the gas when the user wants to spend
   * the maximum available amount in the
   */

  const maxAccountBalanceAsBigNumber = useMemo(
    () =>
      market.underlying.symbol === MARKET_KEY.EGLD
        ? subtractGasFee(market.accountBalances.underlyingWallet)
        : market.accountBalances.underlyingWallet,
    [market.accountBalances.underlyingWallet, market.underlying.symbol],
  );

  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);

  /** Decimal notation of account balance of underlying tokens */
  const maxAccountBalanceDecimals = useMemo(
    () =>
      new DefiUtils(market.accountBalances.underlyingWallet)
        .toFullDecimals(market.underlying.decimals)
        .toString(),
    [market.accountBalances.underlyingWallet, market.underlying.decimals],
  );

  const maxCash = useMemo<string>(() => {
    const value = new DefiUtils(market.cash).minus(
      new DefiUtils(market.totalBorrow).multipliedBy('0.0001'),
    );

    return value.isLessThanOrEqualTo(0)
      ? '0'
      : value.toFixed(0, DefiUtils.ROUND_DOWN);
  }, [market.cash, market.totalBorrow]);

  /** Native action (Tx) is executed in Htoken but expressed in underlying */
  const maxWithdrawAmountFullDecimals = useMemo(
    () =>
      new DefiUtils(market.accountBalances.underlyingHTokenWallet)
        .toFullDecimals(market.underlying.decimals)
        .toString(),
    [market.accountBalances.underlyingHTokenWallet, market.underlying.decimals],
  );

  const collateralUnderlyingBalanceFullDecimals = useMemo(
    () =>
      new DefiUtils(market.accountBalances.underlyingCollateral)
        .toFullDecimals(market.underlying.decimals)
        .toString(),
    [market.accountBalances.underlyingCollateral, market.underlying.decimals],
  );

  const hasTakenBorrows = useMemo(
    () =>
      Object.entries(markets)
        .map(([, { accountBalances }]) => accountBalances.borrow)
        .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
        .isGreaterThan('0'),
    [markets],
  );

  const maxRemoveCollateralUnderlyingAmount = useMemo(() => {
    const maxBorrowLimitUSD = new DefiUtils(borrowLimitUSD || 0).minus(
      new DefiUtils(borrowBalanceUSD).dividedBy(
        hasTakenBorrows ? MAX_RECOMMENDED_BORROW_LIMIT_MARGIN : 1,
      ),
    );

    const safeMaxBorrowLimitUSD = maxBorrowLimitUSD.isGreaterThan(0)
      ? maxBorrowLimitUSD
      : 0;

    const collateralFactorFullDecimals = new DefiUtils(
      appliedCollateralFactor,
    ).dividedBy(wadBasicUnit);

    const maxCollateralBorrowLimitUSD = new DefiUtils(
      safeMaxBorrowLimitUSD,
    ).dividedBy(collateralFactorFullDecimals);

    return new DefiUtils(maxCollateralBorrowLimitUSD)
      .fromUSD(market.underlying.priceUSD)
      .toString();
  }, [
    borrowBalanceUSD,
    borrowLimitUSD,
    appliedCollateralFactor,
    hasTakenBorrows,
    market.underlying.priceUSD,
  ]);

  const recommendedRemovableCollateralBalance = useMemo(
    () =>
      new DefiUtils(maxRemoveCollateralUnderlyingAmount).isLessThanOrEqualTo(
        new DefiUtils(collateralUnderlyingBalanceFullDecimals),
      )
        ? maxRemoveCollateralUnderlyingAmount
        : collateralUnderlyingBalanceFullDecimals,
    [
      collateralUnderlyingBalanceFullDecimals,
      maxRemoveCollateralUnderlyingAmount,
    ],
  );

  const removableCollateralBalanceLimitUSD = useMemo(
    () =>
      new DefiUtils(borrowLimitUSD || 0).minus(
        new DefiUtils(borrowBalanceUSD || 0),
      ),
    [borrowBalanceUSD, borrowLimitUSD],
  );

  const removableCollateralEquivalentLimit = useMemo(
    () =>
      new DefiUtils(
        removableCollateralBalanceLimitUSD.isGreaterThan(0)
          ? removableCollateralBalanceLimitUSD
          : 0,
      )
        .dividedBy(
          new DefiUtils(appliedCollateralFactor).dividedBy(wadBasicUnit),
        )
        .multipliedBy(MAX_BORROW_LIMIT_MARGIN),
    [appliedCollateralFactor, removableCollateralBalanceLimitUSD],
  );

  const removableCollateralTokenLimit = useMemo(
    () =>
      new DefiUtils(removableCollateralEquivalentLimit)
        .fromUSD(market.underlying.priceUSD)
        .toString(),
    [market.underlying.priceUSD, removableCollateralEquivalentLimit],
  );

  const maxRemovableCollateral = useMemo(
    () =>
      new DefiUtils(removableCollateralTokenLimit).isGreaterThanOrEqualTo(
        collateralUnderlyingBalanceFullDecimals,
      )
        ? collateralUnderlyingBalanceFullDecimals
        : removableCollateralTokenLimit,
    [collateralUnderlyingBalanceFullDecimals, removableCollateralTokenLimit],
  );

  /** Max amounts of input expressed in underlying token, used for input
   * validations and to set the max amount value (for each use case)
   * when MAX button is selected */

  const collateralMaxValueAsBigNumber = useMemo(
    () =>
      isAddingCollateral
        ? new DefiUtils(maxWithdrawAmountFullDecimals)
            .toBasicUnits(market.underlying.decimals)
            .toString()
        : new DefiUtils(recommendedRemovableCollateralBalance)
            .toBasicUnits(market.underlying.decimals)
            .toString(),
    [
      market.underlying.decimals,
      isAddingCollateral,
      maxWithdrawAmountFullDecimals,
      recommendedRemovableCollateralBalance,
    ],
  );

  const collateralLimit = useMemo(
    () =>
      isAddingCollateral
        ? maxWithdrawAmountFullDecimals
        : maxRemovableCollateral,
    [isAddingCollateral, maxRemovableCollateral, maxWithdrawAmountFullDecimals],
  );

  const hasEnoughCash = useMemo(
    () =>
      new DefiUtils(maxCash).isGreaterThanOrEqualTo(
        new DefiUtils(inputValueAsBigNumber || 0),
      ),
    [inputValueAsBigNumber, maxCash],
  );

  const isCashGreatherThanUnderlyingWalletBalance = useMemo(
    () =>
      new DefiUtils(maxCash).isGreaterThan(
        new DefiUtils(market.accountBalances.underlyingHTokenWallet || 0),
      ),
    [market.accountBalances.underlyingHTokenWallet, maxCash],
  );

  const isCashGreatherThanUnderlyingCollateralBalance = useMemo(() => {
    return new DefiUtils(maxCash).isGreaterThan(
      market.accountBalances.underlyingCollateral,
    );
  }, [market.accountBalances.underlyingCollateral, maxCash]);

  const withdrawMaxValueAsBigNumber = useMemo(
    () =>
      isCashGreatherThanUnderlyingWalletBalance
        ? market.accountBalances.underlyingHTokenWallet
        : maxCash,
    [
      market.accountBalances.underlyingHTokenWallet,
      isCashGreatherThanUnderlyingWalletBalance,
      maxCash,
    ],
  );

  const collateralLimitValue = useMemo(
    () =>
      new DefiUtils(collateralLimit)
        .multipliedBy(`1e${market.underlying.decimals}`)
        .toString(),
    [collateralLimit, market.underlying.decimals],
  );

  const footerNotes = useMemo(() => {
    const map = {
      [TABS.SUPPLY]: [
        {
          label: t('wallet-balance'),
          value: maxAccountBalanceDecimals,
        },
      ],
      [TABS.WITHDRAW]: [
        ...(isRemovingCollateralAndWithdraw
          ? [
              {
                label: t('current-collateral'),
                value: collateralUnderlyingBalanceFullDecimals,
              },
            ]
          : [
              {
                label: t('currently-supplying'),
                value: maxWithdrawAmountFullDecimals,
              },
              {
                label: t('non-collateral-liquidity'),
                value: undefined,
              },
            ]),
      ],
      [TABS.COLLATERAL]: [
        {
          label: t('supplied-liquidity'),
          value: maxWithdrawAmountFullDecimals,
        },
        {
          label: t('current-collateral'),
          value: collateralUnderlyingBalanceFullDecimals,
        },
      ],
    };

    return map[supplyForm.selectedTab] || [];
  }, [
    t,
    maxAccountBalanceDecimals,
    isRemovingCollateralAndWithdraw,
    collateralUnderlyingBalanceFullDecimals,
    maxWithdrawAmountFullDecimals,
    supplyForm.selectedTab,
  ]);

  const isValidInput = useMemo(
    () =>
      inputValueAsBigNumber &&
      new DefiUtils(inputValueAsBigNumber).isGreaterThan('0')
        ? true
        : false,
    [inputValueAsBigNumber],
  );

  const hasMinHTokenValue = useMemo(
    () =>
      inputValueAsBigNumber &&
      new DefiUtils(inputValueAsHTokenBigNumber).isGreaterThanOrEqualTo(
        isSupplying ? 2 : 1.5,
      )
        ? true
        : false,
    [inputValueAsBigNumber, isSupplying, inputValueAsHTokenBigNumber],
  );

  const maxRequestAmounts = useMemo(() => {
    switch (selectedTab) {
      case TABS.SUPPLY: {
        return {
          maxValue: maxAccountBalanceAsBigNumber,
          limitValue: maxAccountBalanceAsBigNumber,
        };
      }

      case TABS.WITHDRAW: {
        if (isRemovingCollateralAndWithdraw) {
          return {
            maxValue: collateralMaxValueAsBigNumber,
            limitValue: collateralLimitValue,
          };
        }

        return {
          maxValue: withdrawMaxValueAsBigNumber,
          limitValue: withdrawMaxValueAsBigNumber,
        };
      }

      case TABS.COLLATERAL: {
        return {
          maxValue: collateralMaxValueAsBigNumber,
          limitValue: collateralLimitValue,
        };
      }

      default: {
        return {
          maxValue: '0',
          limitValue: '0',
        };
      }
    }
  }, [
    selectedTab,
    maxAccountBalanceAsBigNumber,
    isRemovingCollateralAndWithdraw,
    withdrawMaxValueAsBigNumber,
    collateralMaxValueAsBigNumber,
    collateralLimitValue,
  ]);

  const hasFunds = useMemo(
    () => new DefiUtils(maxRequestAmounts.limitValue).isGreaterThan('0'),
    [maxRequestAmounts.limitValue],
  );

  const isValidAmountRequest = useMemo(() => {
    if (!isValidInput) {
      return false;
    }

    if (maxSelected) {
      return true;
    }

    return new DefiUtils(inputValueAsBigNumber).isLessThanOrEqualTo(
      maxRequestAmounts.limitValue,
    );
  }, [
    maxRequestAmounts.limitValue,
    inputValueAsBigNumber,
    isValidInput,
    maxSelected,
  ]);

  // user put the same number as max button
  const maxSelectedByInput = useMemo(
    () =>
      new DefiUtils(maxRequestAmounts.maxValue).isEqualTo(
        inputValueAsBigNumber,
      ),
    [maxRequestAmounts.maxValue, inputValueAsBigNumber],
  );

  const isAddingAllCollateral = useMemo(
    () => isAddingCollateral && (maxSelected || maxSelectedByInput),
    [isAddingCollateral, maxSelected, maxSelectedByInput],
  );

  const isWithdrawingAll = useMemo(() => {
    return isWithdrawing && (maxSelected || maxSelectedByInput);
  }, [isWithdrawing, maxSelected, maxSelectedByInput]);

  /** Input value (in underlying) converted to USD */
  const inputPriceUSD = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue).toUSD(market.underlying.priceUSD).toString()
        : '0',
    [inputValue, isValidAmountRequest, market.underlying.priceUSD],
  );

  const inputPriceHToken = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue)
            .toBasicUnits(market.underlying.decimals)
            .toTokens(market.hTokenExchangeRate)
        : '0',
    [
      market.underlying.decimals,
      market.hTokenExchangeRate,
      inputValue,
      isValidAmountRequest,
    ],
  );

  /** Used only to display HToken conversion */
  const inputPriceHTokenFullDecimals = useMemo(
    () =>
      new DefiUtils(inputPriceHToken)
        .toFullDecimals(H_TOKEN_DECIMALS)
        .toNumber(),
    [inputPriceHToken],
  );

  /**Determines how the input value should be used in the estimation calculation
  for each use case */
  const getEstimationFactor = (): string => {
    if (isRemovingCollateralAndWithdraw) return '-1';
    if (isAddingSupplyAndCollateral) return '1';
    if (isCollateralying) return isAddingCollateral ? '1' : '-1';
    return '0';
  };

  /** The real borrow limit that results froml the collateral input,
   * considering the collateralFactor
   */
  const collateralInputBorrowLimit = getTokenBorrowLimit(
    inputPriceUSD,
    appliedCollateralFactor,
  );

  /** Borrow Limits estimation box */
  const nextBorrowLimitAmountUSD =
    isValidAmountRequest &&
    new DefiUtils(borrowLimitUSD)
      .plus(
        new DefiUtils(collateralInputBorrowLimit as string).multipliedBy(
          getEstimationFactor(),
        ),
      )
      .toNumber();

  /** Next Borrow Limit Estimation based in inputValue (expressed in underlying) */
  const nextBorrowLimitUsedPercentage =
    isValidAmountRequest &&
    nextBorrowLimitAmountUSD &&
    getBorrowLimitUsedPercent(borrowBalanceUSD, nextBorrowLimitAmountUSD);

  const isRemovingAllCollateral = useMemo(() => {
    if (
      (isRemovingCollateral || isRemovingCollateralAndWithdraw) &&
      (maxSelected || maxSelectedByInput) &&
      !hasTakenBorrows
    ) {
      return true;
    }

    const s_inputValueAsHTokenBigNumber = new DefiUtils(
      inputValueAsHTokenBigNumber,
    )
      .toFixed(0, DefiUtils.ROUND_HALF_UP)
      .toString();

    return new DefiUtils(s_inputValueAsHTokenBigNumber).isGreaterThanOrEqualTo(
      market.accountBalances.collateral,
    );
  }, [
    isRemovingCollateral,
    isRemovingCollateralAndWithdraw,
    maxSelected,
    maxSelectedByInput,
    hasTakenBorrows,
    inputValueAsHTokenBigNumber,
    market.accountBalances.collateral,
  ]);

  const prefix = useMemo<string>(() => {
    const hasRemainingSymbol =
      (isCollateralying || isWithdrawing) &&
      isRemainingValue(inputValueAsBigNumber);

    if (hasRemainingSymbol) {
      return '> ';
    }

    const hasApproximationSymbol =
      (isWithdrawing &&
        maxSelected &&
        new DefiUtils(inputValue).isGreaterThan(0)) ||
      (isAddingCollateral &&
        maxSelected &&
        new DefiUtils(inputValue).isGreaterThan(0)) ||
      (isRemovingCollateral &&
        maxSelected &&
        new DefiUtils(inputValue).isGreaterThan(0) &&
        !hasTakenBorrows);

    if (hasApproximationSymbol) {
      return '≈ ';
    }

    return '';
  }, [
    hasTakenBorrows,
    inputValue,
    inputValueAsBigNumber,
    isAddingCollateral,
    isCollateralying,
    isRemovingCollateral,
    isWithdrawing,
    maxSelected,
  ]);

  const floatMinValue = new DefiUtils('1')
    .toFullDecimals(market.underlying.decimals)
    .toString();

  const currencyInputValue =
    market.underlying.decimals < H_TOKEN_DECIMALS &&
    isRemainingValue(inputValueAsBigNumber) &&
    maxSelected
      ? floatMinValue
      : inputValue;

  const recommendedMaxRemovableCollateralBalance = useMemo(() => {
    const maxRemoveCollateralUSD = new DefiUtils(borrowLimitUSD || 0)
      .multipliedBy(MAX_RECOMMENDED_BORROW_LIMIT_MARGIN)
      .minus(borrowBalanceUSD || 0);

    const collateralEquivalentToBorrowLimitUSD = new DefiUtils(
      maxRemoveCollateralUSD.isGreaterThan(0) ? maxRemoveCollateralUSD : 0,
    ).dividedBy(
      new DefiUtils(appliedCollateralFactor).dividedBy(DefiUtils.WAD),
    );

    const maxRemoveCollateralUnderlyingAmount = new DefiUtils(
      collateralEquivalentToBorrowLimitUSD,
    )
      .dividedBy(market.underlying.priceUSD)
      .toString();

    const result = new DefiUtils(
      maxRemoveCollateralUnderlyingAmount,
    ).isLessThanOrEqualTo(
      new DefiUtils(collateralUnderlyingBalanceFullDecimals),
    )
      ? maxRemoveCollateralUnderlyingAmount
      : collateralUnderlyingBalanceFullDecimals;

    return result;
  }, [
    borrowLimitUSD,
    borrowBalanceUSD,
    appliedCollateralFactor,
    market.underlying.priceUSD,
    collateralUnderlyingBalanceFullDecimals,
  ]);

  const totalSupplyUnderlying = useMemo(
    () =>
      new DefiUtils(market.accountBalances.underlyingCollateral)
        .plus(new DefiUtils(market.accountBalances.underlyingHTokenWallet))
        .toString(),
    [
      market.accountBalances.underlyingCollateral,
      market.accountBalances.underlyingHTokenWallet,
    ],
  );

  const totalSupplyUnderlyingFullDecimals = useMemo(
    () =>
      new DefiUtils(totalSupplyUnderlying)
        .toFullDecimals(market.underlying.decimals)
        .toString(),
    [market.underlying.decimals, totalSupplyUnderlying],
  );

  const controllerAPYV2 = useMemo(() => {
    return (
      (isLoggedIn
        ? controllerRewardBatchesV2.find(
            ({ tokenId }) => tokenId === market.hToken.id,
          )?.account.totalAPY
        : controllerRewardBatchesV2.find(
            ({ tokenId }) => tokenId === market.hToken.id,
          )?.totalAPY) || '0'
    );
  }, [controllerRewardBatchesV2, market.hToken.id, isLoggedIn]);

  const totalSupplyAPY = useMemo(() => {
    if (market.underlying.symbol === MARKET_KEY.sEGLD && showLiquidStakingAPY) {
      return getTotalSupplyAPYByMarketLiquidity({
        accountBalance: '0',
        // accountBalance: accountBalances.underlyingWallet, // aca_aca:fix
        hTokenExchangeRate: market.hTokenExchangeRate,
        hTokenAccountBalance: market.accountBalances.hTokenWallet,
        collateralBalance: market.accountBalances.collateral,
        liquidStakingAPY,
        supplyAPY: market.supplyAPY,
        rewardsTokensAPY: new DefiUtils(supplyControllerAPYV1)
          .plus(controllerAPYV2)
          .toString(),
      });
    }

    if (
      market.underlying.symbol === MARKET_KEY.sWTAO &&
      showLiquidStakingTaoAPY
    ) {
      return getTotalSupplyAPYByMarketLiquidity({
        accountBalance: '0',
        // accountBalance: accountBalances.underlyingWallet, // aca_aca:fix
        hTokenExchangeRate: market.hTokenExchangeRate,
        hTokenAccountBalance: market.accountBalances.hTokenWallet,
        collateralBalance: market.accountBalances.collateral,
        liquidStakingAPY: liquidStakingTaoAPY,
        supplyAPY: market.supplyAPY,
        rewardsTokensAPY: new DefiUtils(supplyControllerAPYV1)
          .plus(controllerAPYV2)
          .toString(),
      });
    }

    const totalSupplyAPYByMarket = getTotalSupplyAPYByMarket({
      totalSupplyUnderlying: totalSupplyUnderlyingFullDecimals,
      collateralUnderlyingBalance: collateralUnderlyingBalanceFullDecimals,
      supplyAPY: String(market.supplyAPY),
      rewardsTokensAPY: new DefiUtils(supplyControllerAPYV1)
        .plus(controllerAPYV2)
        .toString(),
    }).toString();

    return totalSupplyAPYByMarket;
  }, [
    market.underlying.symbol,
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
    totalSupplyUnderlyingFullDecimals,
    collateralUnderlyingBalanceFullDecimals,
    market.supplyAPY,
    supplyControllerAPYV1,
    controllerAPYV2,
    market.hTokenExchangeRate,
    market.accountBalances.hTokenWallet,
    market.accountBalances.collateral,
    liquidStakingAPY,
    liquidStakingTaoAPY,
  ]);

  const showEightyPercentButton = useMemo(() => {
    return (
      ((isCollateralying && isRemovingCollateral) ||
        isRemovingCollateralAndWithdraw) &&
      hasTakenBorrows &&
      !new DefiUtils(collateralUnderlyingBalanceFullDecimals).isEqualTo(
        recommendedMaxRemovableCollateralBalance,
      )
    );
  }, [
    collateralUnderlyingBalanceFullDecimals,
    hasTakenBorrows,
    isCollateralying,
    isRemovingCollateralAndWithdraw,
    isRemovingCollateral,
    recommendedMaxRemovableCollateralBalance,
  ]);

  const isMaxSupplyCap = useMemo(() => {
    if (!isSupplying || market.supplyCap === 'Infinity') {
      return false;
    }

    const totalSupplyUnderlying = new DefiUtils(market.totalSupply)
      .toUnderlying(market.hTokenExchangeRate)
      .toFullDecimals(market.underlying.decimals)
      .toString();

    const futureSupplyCap = new DefiUtils(totalSupplyUnderlying)
      .plus(inputValue)
      .toString();

    return new DefiUtils(futureSupplyCap).isGreaterThanOrEqualTo(
      market.supplyCap,
    );
  }, [
    market.underlying.decimals,
    market.hTokenExchangeRate,
    inputValue,
    isSupplying,
    market.supplyCap,
    market.totalSupply,
  ]);

  return {
    market,
    isLoggedIn,
    hasMaxMarketPerAccount,
    hasPendingTransactions,
    supplyControllerRewardBatchesV1,
    supplyControllerRewardBatchesV2,
    supplyControllerAPYV1,
    liquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
    borrowBalanceUSD,
    borrowLimitUSD,
    marketsInteracted,
    marketsInteractedAmount,
    proxyAddress,
    selectedTypeAddress,
    maxCash,
    hasEnoughCash,
    isCashGreatherThanUnderlyingWalletBalance,
    isCashGreatherThanUnderlyingCollateralBalance,
    hasEnoughEGLDBalance,
    footerNotes,
    isValidInput,
    hasMinHTokenValue,
    maxRequestAmounts,
    hasFunds,
    isValidAmountRequest,
    isAddingAllCollateral,
    isWithdrawingAll,
    inputPriceUSD,
    inputPriceHTokenFullDecimals,
    nextBorrowLimitAmountUSD,
    nextBorrowLimitUsedPercentage,
    isRemovingAllCollateral,
    prefix,
    currencyInputValue,
    totalSupplyAPY,
    showEightyPercentButton,
    isMaxSupplyCap,
    controller,

    maxSelectedByInput,
  };
};

export type ReturnUseSupplyData = ReturnType<typeof useSupplyData>;

export default useSupplyData;
