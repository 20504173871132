import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import useToggle from '@/hooks/useToggle';

import CheckmarkIcon from '@/components/Icons/CheckmarkIcon';

type RepayDustCheckboxProps = {
  checked: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
};

const RepayDustCheckbox = ({
  checked,
  disabled,
  onChange,
}: RepayDustCheckboxProps) => {
  const [isOn, toggle, forceState] = useToggle(checked && !disabled);
  const { t } = useTranslation();
  useEffect(() => {
    onChange(isOn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOn]);

  useEffect(() => {
    forceState(!disabled && checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, disabled]);

  return (
    <button
      type='button'
      onClick={() => {
        if (disabled) return;
        toggle();
      }}
      className={classNames(
        'flex items-center gap-1.5 rounded-[8px] border border-current p-1 pr-2.5 text-sm leading-none',
        {
          'text-[#9999B8]': true,
          'hover:text-[#6A6A8C]': !isOn && !disabled,
          'hover:bg-warning/5': isOn && disabled,
        },
        {
          'text-warning': isOn,
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
          'opacity-70': disabled,
        },
      )}
    >
      <div className='grid h-4 w-4 place-content-center rounded border border-current'>
        {isOn && <CheckmarkIcon height={12} width={12} />}
      </div>
      {t('repay-dust')}
    </button>
  );
};

export default RepayDustCheckbox;
