import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import React, { CSSProperties } from 'react';

import CustomLink from '@/components/CustomLink';
import Hint from '@/components/Hint';
import WrappedImg from '@/components/WrappedImg';

import clsxm from '@/services/clsxm';

import { Campaign } from './types';

import { ROUTES } from '@/types/enums';

const Banner: React.FunctionComponent<{
  text?: string;
  className?: string;
  campaign?: Campaign;
}> = ({ text, className, campaign = Campaign.ASH }) => {
  const { t } = useTranslation();

  const campaigns: Record<Campaign, any> = {
    [Campaign.ASH]: {
      pannelGradient: 'linear-gradient(165deg, #640024 7.44%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner'
          tOptions={{ token: 'EGLD' }}
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(85.97deg,#EC1212 5.71%,#FFAB7C 52.9%,#FFB31F 99.12%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(269.82deg, #006FFF -12.68%, #3E92FF 18.85%, #3E92FF 96.42%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
          ]}
          ns='liquid-app'
        />
      ),
      avatar: 'https://cdn.app.hatom.com/images/liquidstaking/app/ash.png',
      url: 'https://app.ashswap.io/pool/',
    },
    [Campaign.LP_SEGLD]: {
      pannelGradient: 'linear-gradient(165deg, #606060 7.44%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner-lps'
          tOptions={{
            token: 'sEGLD',
            exchange: 'DX25',
          }}
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(269.82deg, #006FFF -12.68%, #3E92FF 18.85%, #3E92FF 96.42%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
          ]}
          ns='liquid-app'
        />
      ),
      avatar:
        'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/LP.png',
      url: 'https://app.dx25.com/trade?mode=pro&tokens=egld%25SEGLD-3ad2d0',
    },
    [Campaign.SEGLD_HTM_BOOST]: {
      pannelGradient: 'linear-gradient(165deg, #B93C3C 7.44%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner-boost'
          tOptions={{ token: 'sEGLD' }}
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(269.82deg, #006FFF -12.68%, #3E92FF 18.85%, #3E92FF 96.42%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
            <span className='text-[#5AF1B0]' />,
          ]}
          ns='liquid-app'
        />
      ),
      avatar:
        'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/htm_boost.png',
      url: 'https://docs.hatom.com/getting-started/hatoms-ecosystem/booster',
    },
    [Campaign.XOXNO]: {
      pannelGradient: 'linear-gradient(165deg, #124366 12.16%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner-xoxno'
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(90.05deg, #AFE12A 0.04%, #80C352 36.98%, #49A082 50.51%, #3D9DC8 70.64%, #842293 99.95%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(269.82deg, #006FFF -12.68%, #3E92FF 18.85%, #3E92FF 96.42%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
          ]}
          ns='liquid-app'
        />
      ),
      avatar: 'https://cdn.app.hatom.com/images/liquidstaking/app/xoxno.png',
      url: 'https://xoxno.com/',
    },
    [Campaign.SEGLD]: {
      pannelGradient: 'linear-gradient(165deg, #34634c 7.44%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner-ls-token'
          tOptions={{ token: 'sEGLD' }}
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(269.82deg, #006FFF -12.68%, #3E92FF 18.85%, #3E92FF 96.42%)',
                } as CSSProperties
              }
              className='gradient-text bg-[#34634c]'
            />,
          ]}
          ns='liquid-app'
        />
      ),
      avatar:
        'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/segld.png',
      url: '/lend',
    },
    [Campaign.USH]: {
      pannelGradient: 'linear-gradient(165deg, #003A64 7.44%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner-ush'
          tOptions={{ token: 'sEGLD' }}
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(269.82deg, #006FFF -12.68%, #3E92FF 18.85%, #3E92FF 96.42%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(92.44deg, #9ADAF6 13.14%, #68D2FF 98.09%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
          ]}
          ns='liquid-app'
        />
      ),
      avatar: 'https://cdn.app.hatom.com/images/liquidstaking/app/ush.png',
      url: '#',
    },
    [Campaign.LIQUID_STAKING]: {
      pannelGradient: 'linear-gradient(165deg, #1A3996 7.44%, #000000 39.76%)',
      motivation: (
        <Trans
          i18nKey='banner-liquidstaking'
          ns='liquid-app'
          components={[
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(90deg, #E2FF31 85.39%, #39FFAC 100%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
            <span
              style={
                {
                  [`--gradient`]:
                    'linear-gradient(90deg, #44FFA5 3.26%, #55E0FF 50.97%, #1448FE 93.52%)',
                } as CSSProperties
              }
              className='gradient-text'
            />,
          ]}
        />
      ),
      avatar:
        'https://cdn.app.hatom.com/images/liquidstaking/app/liquid-staking.png',
      url: ROUTES.LIQUID,
    },
  };

  const { pannelGradient, motivation, avatar, url } = campaigns[campaign];

  return (
    <div
      className={classNames(
        'grid grid-cols-[auto,1fr,auto] items-center gap-x-2 rounded-[12px] p-3 font-hass text-[10px] font-semibold tracking-[0.01em] text-white md:grid-cols-[auto,1fr,107px] md:gap-x-3 md:p-5 md:pl-[20px] md:pr-[53px] md:text-[12px]',
        className,
      )}
      style={
        {
          background: pannelGradient,
        } as CSSProperties
      }
    >
      <WrappedImg className='h-10 w-10' src={avatar} />
      <p className='max-w-[250px]'>{text || motivation}</p>
      <Hint
        placement='top-center'
        content={t('coming-soon', 'Coming Soon').replace('!', '')}
        hidden={url != '#'}
      >
        <CustomLink href={url} external={url.startsWith('http')}>
          <button
            type='button'
            className={clsxm(
              'h-6 whitespace-nowrap rounded bg-[#006FFF] px-2 text-[10px] tracking-wide transition-opacity hover:opacity-75 md:h-8 md:rounded-[6px] md:px-[19px] md:text-[12px]',
            )}
          >
            {t('staking-app:learn-more')}
          </button>
        </CustomLink>
      </Hint>
    </div>
  );
};

export default Banner;
