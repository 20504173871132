const lensABI = {
  buildInfo: {
    rustc: {
      version: '1.81.0',
      commitHash: 'eeb90cda1969383f56a2637cbd3037bdf598841c',
      commitDate: '2024-09-04',
      channel: 'Stable',
      short: 'rustc 1.81.0 (eeb90cda1 2024-09-04)',
    },
    contractCrate: {
      name: 'lens',
      version: '0.0.0',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.53.2',
    },
  },
  name: 'Lens',
  constructor: {
    inputs: [
      {
        name: 'ush_minter',
        type: 'Address',
      },
      {
        name: 'wrapped_tao',
        type: 'Address',
      },
      {
        name: 'egld_liquid_staking',
        type: 'Address',
      },
      {
        name: 'wtao_liquid_staking',
        type: 'Address',
      },
      {
        name: 'controller',
        type: 'Address',
      },
      {
        name: 'ush_money_market',
        type: 'Address',
      },
      {
        name: 'ush_staking',
        type: 'Address',
      },
      {
        name: 'egld_lendegate',
        type: 'Address',
      },
      {
        name: 'wtao_lendegate',
        type: 'Address',
      },
      {
        name: 'egld_isolated_lending_protocol',
        type: 'Address',
      },
      {
        name: 'wtao_isolated_lending_protocol',
        type: 'Address',
      },
      {
        name: 'booster_v1',
        type: 'Address',
      },
      {
        name: 'booster_v2',
        type: 'Address',
      },
      {
        name: 'account_manager_deployer',
        type: 'Address',
      },
    ],
    outputs: [],
  },
  upgradeConstructor: {
    inputs: [],
    outputs: [],
  },
  endpoints: [
    {
      name: 'getHatomData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'HatomData',
        },
      ],
    },
    {
      name: 'getControllerData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'ControllerData',
        },
      ],
    },
    {
      name: 'getControllerRewardsBatches',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<ControllerRewardsBatch>',
        },
      ],
    },
    {
      name: 'getControllerRewardsTokenIds',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
    },
    {
      name: 'getAccountControllerRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountRewardsTokenData>',
        },
      ],
    },
    {
      name: 'getInterestRateModelData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'InterestRateModelData',
        },
      ],
    },
    {
      name: 'getInterestRateModelsData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<InterestRateModelData>',
        },
      ],
    },
    {
      name: 'getDiscountRateModelData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'variadic<DiscountData>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'getAccountDiscountRateModelData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'action',
          type: 'ActionType',
        },
        {
          name: 'opt_delta_borrow',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'AccountDiscountsData',
        },
      ],
    },
    {
      name: 'getMarketData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'MarketData',
        },
      ],
    },
    {
      name: 'getMarketsData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<MarketData>',
        },
      ],
    },
    {
      name: 'getLightMarketData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'LightMarketData',
        },
      ],
    },
    {
      name: 'getAccountMarketData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountMarketData',
        },
      ],
    },
    {
      name: 'getAccountMarketsData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountMarketData>',
        },
      ],
    },
    {
      name: 'getLiquidStakingData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'LiquidStakingData',
        },
      ],
    },
    {
      name: 'getDelegationContractData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'delegation_contract',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'DelegationContractData',
        },
      ],
    },
    {
      name: 'getDelegationContractsData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<DelegationContractData>',
        },
      ],
    },
    {
      name: 'getDelegationContracts',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'variadic<Address>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'getBoosterV1Data',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BoosterV1Data',
        },
      ],
    },
    {
      name: 'getMarketBoosterV1Data',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'MarketBoosterV1Data',
        },
      ],
    },
    {
      name: 'getMarketsBoosterV1Data',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<MarketBoosterV1Data>',
        },
      ],
    },
    {
      name: 'getAccountMarketBoosterV1Data',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountMarketBoosterV1Data',
        },
      ],
    },
    {
      name: 'getAccountMarketsBoosterV1Data',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountMarketBoosterV1Data>',
        },
      ],
    },
    {
      name: 'getBoosterV1RewardsBatches',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterV1RewardsBatch>',
        },
      ],
    },
    {
      name: 'getBoosterV1RewardsTokenIds',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
    },
    {
      name: 'getAccountBoosterV1Markets',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<Address>',
        },
      ],
    },
    {
      name: 'getAccountBoosterV1Rewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountRewardsTokenData>',
        },
      ],
    },
    {
      name: 'getBoosterV1Accumulator',
      mutability: 'readonly',
      inputs: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'AccumulatorData',
        },
      ],
    },
    {
      name: 'getBoosterV1Accumulators',
      mutability: 'readonly',
      inputs: [
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccumulatorData>',
        },
      ],
    },
    {
      name: 'getControllerAccumulator',
      mutability: 'readonly',
      inputs: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'AccumulatorData',
        },
      ],
    },
    {
      name: 'getControllerAccumulators',
      mutability: 'readonly',
      inputs: [
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccumulatorData>',
        },
      ],
    },
    {
      name: 'getBoosterV1AccountClaims',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterClaim>',
        },
      ],
    },
    {
      name: 'getPriceAggregatorPrices',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<PriceAggregatorPrice>',
        },
      ],
    },
    {
      name: 'getTokenPrices',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<TokenPriceQuote>',
        },
      ],
    },
    {
      name: 'getWrappedTaoData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'WrappedTaoData',
        },
      ],
    },
    {
      name: 'getTaoLiquidStakingData',
      mutability: 'mutable',
      inputs: [],
      outputs: [
        {
          type: 'TaoLiquidStakingData',
        },
      ],
    },
    {
      name: 'getTaoData',
      mutability: 'mutable',
      inputs: [],
      outputs: [
        {
          type: 'TaoData',
        },
      ],
    },
    {
      name: 'getEgldIsolatedLendingProtocolData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'IsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getWtaoIsolatedLendingProtocolData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'IsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getIsolatedLendingProtocolsData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'IsolatedLendingProtocolData',
        },
        {
          type: 'IsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getIsolatedLendingProtocolData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'ilp',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'IsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getAccountEgldIsolatedLendingProtocolData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountIsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getAccountWtaoIsolatedLendingProtocolData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountIsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getAccountIsolatedLendingProtocolData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'ilp',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountIsolatedLendingProtocolData',
        },
      ],
    },
    {
      name: 'getUSHMinterData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'USHMinterData',
        },
      ],
    },
    {
      name: 'getUSHStakingData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'USHStakingData',
        },
      ],
    },
    {
      name: 'getAccountUSHStakingNonces',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'List<u32>',
        },
      ],
    },
    {
      name: 'getAccountUSHStakingData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<StakeAccountDetails>',
        },
      ],
    },
    {
      name: 'getUSHStakingStakePools',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<StakingStakePool>',
        },
      ],
    },
    {
      name: 'getBoosterV2BatchData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterV2BatchData>',
        },
      ],
    },
    {
      name: 'getBoosterV2AccountInstaCompliance',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'action_token',
          type: 'TokenIdentifier',
        },
        {
          name: 'action',
          type: 'ActionType',
        },
        {
          name: 'opt_delta_value',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getBoosterV2AccountRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterV2RewardsAccrued>',
        },
      ],
    },
    {
      name: 'getBoosterV2AccountRewardsPerMarket',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterV2RewardsAccruedPerMarket>',
        },
      ],
    },
    {
      name: 'getBoosterV2Stakes',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'List<TokenIdentifier>',
        },
      ],
    },
    {
      name: 'getBoosterV2AccountStakes',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterV2AccountStakes>',
        },
      ],
    },
    {
      name: 'getBoosterV2AccountExtraAmount',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterV2AccountExtraAmount>',
        },
      ],
    },
    {
      name: 'getBoosterV2AccountClaims',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program_id',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<Claim>',
        },
      ],
    },
    {
      name: 'getBoosterV2TokenPriceUsd',
      mutability: 'readonly',
      inputs: [
        {
          name: 'tokens',
          type: 'List<TokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<BigUint>',
        },
      ],
    },
    {
      name: 'getAccountManager',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getManagedAccount',
      mutability: 'readonly',
      inputs: [
        {
          name: 'manager',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getController',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getLiquidStaking',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getLendegate',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getBooster',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getBoosterV2',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getWrappedTao',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getTaoLiquidStaking',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getTaoLendegate',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getUshMinter',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getUshMoneyMarket',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getEgldIsolatedLendingProtocol',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getWtaoIsolatedLendingProtocol',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getUshStaking',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getAccountManagerDeployer',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
  ],
  esdtAttributes: [],
  hasCallback: false,
  types: {
    AccountDiscountData: {
      type: 'struct',
      fields: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'market_data',
          type: 'DiscountData',
        },
        {
          name: 'discounted_ush',
          type: 'BigUint',
        },
      ],
    },
    AccountDiscountsData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'discount',
          type: 'BigUint',
        },
        {
          name: 'discount_data',
          type: 'List<AccountDiscountData>',
        },
      ],
    },
    AccountIsolatedLendingProtocolData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'collateral',
          type: 'BigUint',
        },
        {
          name: 'borrow',
          type: 'BigUint',
        },
        {
          name: 'ilp_data',
          type: 'IsolatedLendingProtocolData',
        },
      ],
    },
    AccountMarketBoosterV1Data: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'market',
          type: 'Address',
        },
        {
          name: 'stake',
          type: 'BigUint',
        },
        {
          name: 'price_integral',
          type: 'BigUint',
        },
        {
          name: 'price_integral_timestamp',
          type: 'u64',
        },
        {
          name: 'market_booster_data',
          type: 'MarketBoosterV1Data',
        },
        {
          name: 'stored_collateral_tokens',
          type: 'BigUint',
        },
      ],
    },
    AccountMarketData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'collateral',
          type: 'BigUint',
        },
        {
          name: 'borrow_amount',
          type: 'BigUint',
        },
        {
          name: 'borrow_index',
          type: 'BigUint',
        },
        {
          name: 'discount',
          type: 'BigUint',
        },
        {
          name: 'stored_borrow_amount',
          type: 'BigUint',
        },
        {
          name: 'market_data',
          type: 'LightMarketData',
        },
        {
          name: 'current_borrow_amount',
          type: 'BigUint',
        },
      ],
    },
    AccountRewardsTokenData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'rewards',
          type: 'BigUint',
        },
      ],
    },
    AccumulatorData: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'premium',
          type: 'BigUint',
        },
        {
          name: 'amount_left',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'swap_path',
          type: 'List<SwapStep>',
        },
      ],
    },
    ActionType: {
      type: 'enum',
      variants: [
        {
          name: 'None',
          discriminant: 0,
        },
        {
          name: 'Mint',
          discriminant: 1,
        },
        {
          name: 'Borrow',
          discriminant: 2,
        },
        {
          name: 'RepayBorrow',
          discriminant: 3,
        },
        {
          name: 'EnterMarket',
          discriminant: 4,
        },
        {
          name: 'ExitMarket',
          discriminant: 5,
        },
        {
          name: 'Stake',
          discriminant: 6,
        },
        {
          name: 'Unstake',
          discriminant: 7,
        },
      ],
    },
    BoosterClaim: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'claim_timestamp',
          type: 'u64',
        },
        {
          name: 'claimed',
          type: 'bool',
        },
      ],
    },
    BoosterV1Data: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'stake_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'total_stake',
          type: 'BigUint',
        },
        {
          name: 'staking_ratio_threshold',
          type: 'BigUint',
        },
        {
          name: 'cooldown_period',
          type: 'u64',
        },
      ],
    },
    BoosterV1RewardsBatch: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'speed',
          type: 'BigUint',
        },
        {
          name: 'index',
          type: 'BigUint',
        },
        {
          name: 'last_time',
          type: 'u64',
        },
        {
          name: 'end_time',
          type: 'u64',
        },
      ],
    },
    BoosterV2AccountExtraAmount: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'extra_amount',
          type: 'BigUint',
        },
        {
          name: 'base_amount',
          type: 'BigUint',
        },
      ],
    },
    BoosterV2AccountStakes: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'nonce',
          type: 'u32',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    BoosterV2BatchData: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'market_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'rewards_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'speed',
          type: 'BigUint',
        },
        {
          name: 'is_extra',
          type: 'bool',
        },
        {
          name: 'end_time',
          type: 'u64',
        },
        {
          name: 'base_ratio',
          type: 'BigUint',
        },
        {
          name: 'staking_ratio',
          type: 'BigUint',
        },
        {
          name: 'total_collateral',
          type: 'BigUint',
        },
        {
          name: 'total_extra_collateral',
          type: 'BigUint',
        },
      ],
    },
    BoosterV2RewardsAccrued: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    BoosterV2RewardsAccruedPerMarket: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'rewards_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    Claim: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
        {
          name: 'claim_timestamp',
          type: 'u64',
        },
        {
          name: 'claimed',
          type: 'bool',
        },
      ],
    },
    ControllerData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'markets',
          type: 'List<Address>',
        },
        {
          name: 'price_oracle',
          type: 'Address',
        },
        {
          name: 'max_markets_per_account',
          type: 'u32',
        },
        {
          name: 'global_seize_allowed',
          type: 'bool',
        },
      ],
    },
    ControllerRewardsBatch: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'market_type',
          type: 'MarketType',
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'speed',
          type: 'BigUint',
        },
        {
          name: 'index',
          type: 'BigUint',
        },
        {
          name: 'last_time',
          type: 'u64',
        },
        {
          name: 'end_time',
          type: 'u64',
        },
      ],
    },
    DelegationContractData: {
      type: 'struct',
      fields: [
        {
          name: 'contract',
          type: 'Address',
        },
        {
          name: 'total_value_locked',
          type: 'BigUint',
        },
        {
          name: 'cap',
          type: 'Option<BigUint>',
        },
        {
          name: 'nr_nodes',
          type: 'u64',
        },
        {
          name: 'apr',
          type: 'BigUint',
        },
        {
          name: 'service_fee',
          type: 'BigUint',
        },
        {
          name: 'delegation_score',
          type: 'BigUint',
        },
        {
          name: 'pending_to_delegate',
          type: 'BigUint',
        },
        {
          name: 'total_delegated',
          type: 'BigUint',
        },
        {
          name: 'pending_to_undelegate',
          type: 'BigUint',
        },
        {
          name: 'total_undelegated',
          type: 'BigUint',
        },
        {
          name: 'total_withdrawable',
          type: 'BigUint',
        },
        {
          name: 'outdated',
          type: 'bool',
        },
        {
          name: 'blacklisted',
          type: 'bool',
        },
      ],
    },
    DiscountData: {
      type: 'struct',
      fields: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'discount',
          type: 'BigUint',
        },
      ],
    },
    EsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    HatomData: {
      type: 'struct',
      fields: [
        {
          name: 'controller',
          type: 'Address',
        },
        {
          name: 'markets',
          type: 'List<Address>',
        },
        {
          name: 'liquid_staking',
          type: 'Address',
        },
        {
          name: 'lendegate',
          type: 'Address',
        },
        {
          name: 'booster',
          type: 'Address',
        },
        {
          name: 'tao_liquid_staking',
          type: 'Address',
        },
        {
          name: 'tao_lendegate',
          type: 'Address',
        },
        {
          name: 'egld_isolated_lending_protocol',
          type: 'Address',
        },
        {
          name: 'wtao_isolated_lending_protocol',
          type: 'Address',
        },
        {
          name: 'price_aggregator',
          type: 'Address',
        },
      ],
    },
    InterestRateModelData: {
      type: 'struct',
      fields: [
        {
          name: 'market',
          type: 'Address',
        },
        {
          name: 'base_rate',
          type: 'BigUint',
        },
        {
          name: 'first_slope',
          type: 'BigUint',
        },
        {
          name: 'last_slope',
          type: 'BigUint',
        },
        {
          name: 'optimal_utilization',
          type: 'BigUint',
        },
        {
          name: 'max_borrow_rate',
          type: 'BigUint',
        },
      ],
    },
    IsolatedLendingProtocolData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'state',
          type: 'State',
        },
        {
          name: 'redeem_status',
          type: 'Status',
        },
        {
          name: 'liquidation_status',
          type: 'Status',
        },
        {
          name: 'collateral_token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'min_collateral_amount',
          type: 'BigUint',
        },
        {
          name: 'ls_token_money_market',
          type: 'Address',
        },
        {
          name: 'liquid_staking',
          type: 'Address',
        },
        {
          name: 'ush_minter',
          type: 'Address',
        },
        {
          name: 'controller',
          type: 'Address',
        },
        {
          name: 'price_oracle',
          type: 'Address',
        },
        {
          name: 'ls_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'h_ls_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'ush_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'collateral_cap',
          type: 'Option<BigUint>',
        },
        {
          name: 'total_collateral',
          type: 'BigUint',
        },
        {
          name: 'borrow_cap',
          type: 'Option<BigUint>',
        },
        {
          name: 'total_borrows',
          type: 'BigUint',
        },
        {
          name: 'total_h_ls_tokens',
          type: 'BigUint',
        },
        {
          name: 'total_reserves',
          type: 'BigUint',
        },
        {
          name: 'staking_rewards',
          type: 'BigUint',
        },
        {
          name: 'revenue',
          type: 'BigUint',
        },
        {
          name: 'collateral_factor',
          type: 'BigUint',
        },
        {
          name: 'close_factor',
          type: 'BigUint',
        },
        {
          name: 'liquidation_incentive',
          type: 'BigUint',
        },
        {
          name: 'protocol_seize_share',
          type: 'BigUint',
        },
        {
          name: 'depeg_factor',
          type: 'BigUint',
        },
        {
          name: 'stake_factor',
          type: 'BigUint',
        },
        {
          name: 'redeem_time_window',
          type: 'u64',
        },
        {
          name: 'max_victims',
          type: 'u32',
        },
      ],
    },
    LightMarketData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'borrow_index',
          type: 'BigUint',
        },
        {
          name: 'accrual_timestamp',
          type: 'u64',
        },
        {
          name: 'borrow_rate_per_second',
          type: 'BigUint',
        },
      ],
    },
    LiquidStakingData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'ls_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'undelegate_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'total_supply',
          type: 'BigUint',
        },
        {
          name: 'cash_reserve',
          type: 'BigUint',
        },
        {
          name: 'rewards_reserve',
          type: 'BigUint',
        },
        {
          name: 'protocol_reserve',
          type: 'BigUint',
        },
        {
          name: 'exchange_rate',
          type: 'BigUint',
        },
        {
          name: 'total_fee',
          type: 'BigUint',
        },
        {
          name: 'unbond_period',
          type: 'u64',
        },
      ],
    },
    MarketBoosterV1Data: {
      type: 'struct',
      fields: [
        {
          name: 'market',
          type: 'Address',
        },
        {
          name: 'price_integral',
          type: 'BigUint',
        },
        {
          name: 'price_integral_timestamp',
          type: 'u64',
        },
        {
          name: 'stored_total_collateral_tokens',
          type: 'BigUint',
        },
      ],
    },
    MarketData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'underlying_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'cash',
          type: 'BigUint',
        },
        {
          name: 'total_borrows',
          type: 'BigUint',
        },
        {
          name: 'total_reserves',
          type: 'BigUint',
        },
        {
          name: 'staking_rewards',
          type: 'BigUint',
        },
        {
          name: 'revenue',
          type: 'BigUint',
        },
        {
          name: 'exchange_rate',
          type: 'BigUint',
        },
        {
          name: 'borrow_index',
          type: 'BigUint',
        },
        {
          name: 'total_supply',
          type: 'BigUint',
        },
        {
          name: 'total_collateral',
          type: 'BigUint',
        },
        {
          name: 'close_factor',
          type: 'BigUint',
        },
        {
          name: 'stake_factor',
          type: 'BigUint',
        },
        {
          name: 'reserve_factor',
          type: 'BigUint',
        },
        {
          name: 'collateral_factor',
          type: 'BigUint',
        },
        {
          name: 'ush_borrower_collateral_factor',
          type: 'BigUint',
        },
        {
          name: 'liquidation_incentive',
          type: 'BigUint',
        },
        {
          name: 'protocol_seize_share',
          type: 'BigUint',
        },
        {
          name: 'supply_rate_per_second',
          type: 'BigUint',
        },
        {
          name: 'borrow_rate_per_second',
          type: 'BigUint',
        },
        {
          name: 'accrual_time_threshold',
          type: 'u64',
        },
        {
          name: 'accrual_timestamp',
          type: 'u64',
        },
        {
          name: 'liquidity_cap',
          type: 'BigUint',
        },
        {
          name: 'borrow_cap',
          type: 'BigUint',
        },
        {
          name: 'is_mint_allowed',
          type: 'bool',
        },
        {
          name: 'is_borrow_allowed',
          type: 'bool',
        },
        {
          name: 'is_seize_allowed',
          type: 'bool',
        },
        {
          name: 'max_rewards_batches',
          type: 'u32',
        },
      ],
    },
    MarketType: {
      type: 'enum',
      variants: [
        {
          name: 'Supply',
          discriminant: 0,
        },
        {
          name: 'Borrow',
          discriminant: 1,
        },
      ],
    },
    PriceAggregatorPrice: {
      type: 'struct',
      fields: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'underlying_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'ticker',
          type: 'bytes',
        },
        {
          name: 'timestamp',
          type: 'u64',
        },
        {
          name: 'price',
          type: 'BigUint',
        },
      ],
    },
    StakeAccountDetails: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'nonce',
          type: 'u64',
        },
        {
          name: 'total_stake',
          type: 'BigUint',
        },
      ],
    },
    StakeDetails: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'total_stake',
          type: 'BigUint',
        },
      ],
    },
    StakingStakePool: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'main_token',
          type: 'TokenIdentifier',
        },
        {
          name: 'pool_tokens',
          type: 'List<TokenIdentifier>',
        },
        {
          name: 'total_stake',
          type: 'BigUint',
        },
      ],
    },
    State: {
      type: 'enum',
      variants: [
        {
          name: 'Empty',
          discriminant: 0,
        },
        {
          name: 'Active',
          discriminant: 1,
        },
        {
          name: 'Inactive',
          discriminant: 2,
        },
      ],
    },
    Status: {
      type: 'enum',
      variants: [
        {
          name: 'Empty',
          discriminant: 0,
        },
        {
          name: 'Active',
          discriminant: 1,
        },
        {
          name: 'Paused',
          discriminant: 2,
        },
      ],
    },
    SwapStep: {
      type: 'struct',
      fields: [
        {
          name: 'pair_address',
          type: 'Address',
        },
        {
          name: 'input_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'output_token_id',
          type: 'TokenIdentifier',
        },
      ],
    },
    TaoData: {
      type: 'struct',
      fields: [
        {
          name: 'wrapped_tao_data',
          type: 'WrappedTaoData',
        },
        {
          name: 'tao_liquid_staking_data',
          type: 'TaoLiquidStakingData',
        },
        {
          name: 'tao_lendegate',
          type: 'Address',
        },
      ],
    },
    TaoLiquidStakingData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'ls_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'ls_token_supply',
          type: 'BigUint',
        },
        {
          name: 'cash',
          type: 'BigUint',
        },
        {
          name: 'apr',
          type: 'u64',
        },
        {
          name: 'pending_rewards',
          type: 'BigUint',
        },
        {
          name: 'accrual_timestamp',
          type: 'u64',
        },
        {
          name: 'cap_percentage',
          type: 'Option<u64>',
        },
        {
          name: 'stored_exchange_rate',
          type: 'BigUint',
        },
        {
          name: 'current_exchange_rate',
          type: 'BigUint',
        },
      ],
    },
    TokenPriceQuote: {
      type: 'struct',
      fields: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'underlying_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'ticker',
          type: 'bytes',
        },
        {
          name: 'timestamp',
          type: 'u64',
        },
        {
          name: 'price',
          type: 'BigUint',
        },
        {
          name: 'currency',
          type: 'bytes',
        },
      ],
    },
    USHMinterData: {
      type: 'struct',
      fields: [
        {
          name: 'ush_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'active',
          type: 'bool',
        },
        {
          name: 'facilitator_pools',
          type: 'List<USHMinterFacilitatorPool>',
        },
      ],
    },
    USHMinterFacilitatorPool: {
      type: 'struct',
      fields: [
        {
          name: 'capacity',
          type: 'BigUint',
        },
        {
          name: 'minted',
          type: 'BigUint',
        },
        {
          name: 'burned',
          type: 'BigUint',
        },
      ],
    },
    USHStakingData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'whitelisted_stake_tokens',
          type: 'List<TokenIdentifier>',
        },
        {
          name: 'total_stakes',
          type: 'List<StakeDetails>',
        },
      ],
    },
    WrappedTaoData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_supply',
          type: 'BigUint',
        },
        {
          name: 'fwd_fee',
          type: 'u64',
        },
        {
          name: 'bwd_fee',
          type: 'u64',
        },
        {
          name: 'next_fwd_fee',
          type: 'Option<tuple<u64,u64>>',
        },
        {
          name: 'revenue',
          type: 'BigUint',
        },
        {
          name: 'max_rewards',
          type: 'BigUint',
        },
        {
          name: 'bridged_rewards',
          type: 'BigUint',
        },
        {
          name: 'min_burn_amount',
          type: 'BigUint',
        },
      ],
    },
  },
};

export default lensABI;
