import classNames from 'classnames';

import ChevronRightIcon from '@/components/Icons/ChevronRightIcon';

const PaginationButton: React.FC<{
  onClick: () => void;
  disabled?: boolean;
  next?: boolean;
}> = ({ onClick, disabled, next }) => {
  const previous = !next;
  return (
    <button
      className={classNames(
        'flex items-center justify-center gap-2.5',
        'uppercase',
        {
          'text-[#3E8788]': next,
          'text-red': previous,
        },
        'disabled:cursor-not-allowed',
        'disabled:text-[#A4A4AB] dark:disabled:text-[#625E8F]',
        'p-2 px-4',
        'min-w-[140px] cursor-pointer',
        {
          'flex-row-reverse': next,
        },
      )}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <div
        className={classNames({
          'rotate-180': !next,
        })}
      >
        <ChevronRightIcon height={12} width='auto' />
      </div>
      {next ? 'Next' : 'Previous'}
    </button>
  );
};

export default PaginationButton;
