import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

interface FilterTagsProps {
  value: string;
  onClick: (value: string) => void;
  items: {
    id: string;
    name: string;
    image: string;
  }[];
}

export const FilterTags = ({ items, value, onClick }: FilterTagsProps) => {
  const { t } = useTranslation('governance');

  return (
    <ul
      className={classNames(
        'absolute',
        'top-full',
        'right-0',
        'border border-[#E7E7F9] bg-white',
        'rounded-[8px]',
        'p-1.5',
        'w-[158px]',
        'flex',
        'flex-col',
        'gap-1.5',
        'mt-2',
        'dark:bg-[#2E2C51]',
      )}
    >
      <li
        className={classNames(
          'flex',
          'gap-1',
          'cursor-pointer',
          'p-1',
          'text-[#6A6A8C]',
          'dark:text-[#BFBFE3]',
        )}
        onClick={() => onClick('')}
      >
        <div
          className={classNames(
            'w-[20px]',
            'h-[20px]',
            'rounded-[7.82878px]',
            'bg-[#F3F0FF]',
            'dark:bg-[#F3F0FF]',
            'flex',
            'items-center',
            'justify-center',
          )}
        >
          <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2.3125 10.64C2.3125 10.4246 2.48711 10.25 2.7025 10.25H11.0225C11.2379 10.25 11.4125 10.4246 11.4125 10.64C11.4125 10.8554 11.2379 11.03 11.0225 11.03H2.7025C2.48711 11.03 2.3125 10.8554 2.3125 10.64Z'
              fill='#6A6A8C'
              stroke='#6A6A8C'
              strokeWidth='0.5'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2.3125 3.35875C2.3125 3.14336 2.48711 2.96875 2.7025 2.96875H11.0225C11.2379 2.96875 11.4125 3.14336 11.4125 3.35875C11.4125 3.57414 11.2379 3.74875 11.0225 3.74875H2.7025C2.48711 3.74875 2.3125 3.57414 2.3125 3.35875Z'
              fill='#6A6A8C'
              stroke='#6A6A8C'
              strokeWidth='0.5'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M2.3125 7.00328C2.3125 6.7879 2.48711 6.61328 2.7025 6.61328H11.0225C11.2379 6.61328 11.4125 6.7879 11.4125 7.00328C11.4125 7.21867 11.2379 7.39328 11.0225 7.39328H2.7025C2.48711 7.39328 2.3125 7.21867 2.3125 7.00328Z'
              fill='#6A6A8C'
              stroke='#6A6A8C'
              strokeWidth='0.5'
            />
          </svg>
        </div>
        <span className={classNames('text-sm capitalize', 'leading-[18px]')}>
          {t('see-all', 'See all')}
        </span>
      </li>

      {items?.map(({ name, image, id }) => (
        <li
          key={name}
          className={classNames(
            'flex',
            'gap-1',
            'cursor-pointer',
            'p-1',
            'hover:rounded-[7.82878px] hover:bg-[#E0DBF0] dark:hover:bg-[#171430]',
            id === value
              ? ['bg-[#F3F0FF]', 'rounded-[7.82878px]', 'dark:bg-[#16142E]']
              : ['text-[#6A6A8C]', 'dark:text-[#BFBFE3]'],
          )}
          onClick={() => onClick(id)}
        >
          <img
            src={image}
            alt={name}
            className={classNames('w-[20px]', 'h-[20px]', 'block')}
          />
          <span
            className={classNames(
              'text-sm',
              'whitespace-nowrap capitalize leading-[18px]',
            )}
          >
            {name}
          </span>
        </li>
      ))}
    </ul>
  );
};
