import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatUserBalances,
  formatVoteNfts,
} from '@/store/parsers/governance-parser';
import { htmMarketSelector } from '@/store/protocol';
import { addAction } from '@/store/queue';

import logger from '@/utils/logger';

export enum PROPOSAL_STATUS {
  building = 'building',
  pending = 'pending',
  snapshot = 'snapshot',
  active = 'active',
  defeated = 'defeated',
  succeeded = 'succeeded',
  executed = 'executed',
}

export enum PROPOSAL_TAG {
  lendingProtocol = 'lendingProtocol',
  liquidStaking = 'liquidStaking',
  ushStaking = 'ushStaking',
  isolatedPools = 'isolatedPools',
}

export enum VOTE_TYPE {
  For,
  Against,
}

export interface Vote {
  tokenNonce: number;
  amount: string;
  proposalId: string;
  tokenIdentifier: string;
  voteType: VOTE_TYPE;
}

export interface Proposal {
  id: string;
  creationBlock: string;
  creationTimestamp: string;
  publishedBlock: string;
  publishedTimestamp: string;
  title: string;
  description: string;
  published: boolean;
  executed: boolean;
  downvotes: string;
  upvotes: string;
  totalVotes: string;
  status: PROPOSAL_STATUS;
  percentageUpVotes: string;
  percentageDownVotes: string;
  highLighted: string | null;
  tag: PROPOSAL_TAG;
}

export interface VoteNft {
  proposalId: string;
  amount: string;
  tokenIdentifier: string;
}

export enum USER_BALANCE_SOURCE {
  wallet = 'wallet',
  staked = 'staked',
  safety = 'safety',
}

export const staticProposals: Proposal[] = [
  {
    id: '159',
    title: 'Booster & Accumulator Devnet Deployment',
    description:
      'Initiate the development of an innovative Liquid Staking model that adheres to DeFi 2.0 principles and splits the liquidity fairly among the whitelisted validators making the Blockchain even more decentralized. This will allow users to access the value of their staked assets by receiving sEGLD, a tokenised version of their position that can be used across all DeFi Applications within the MultiversX ecosystem, unlocking tremendous liquidity on the blockchain.',
    tag: PROPOSAL_TAG.liquidStaking,
    status: PROPOSAL_STATUS.snapshot,
    upvotes: '312.12',
    downvotes: '0',
    publishedBlock: '5013216',
    creationBlock: '800',
    creationTimestamp: new Date().toISOString(),
    publishedTimestamp: new Date().toISOString(),
    published: true,
    executed: true,
    totalVotes: '312.12',
    percentageUpVotes: '100',
    percentageDownVotes: '0',
    highLighted: 'for',
  },
  {
    id: '160',
    title: 'Integrate HTM Money Market',
    description:
      'Initiate the development of an innovative Liquid Staking model that adheres to DeFi 2.0 principles and splits the liquidity fairly among the whitelisted validators making the Blockchain even more decentralized. This will allow users to access the value of their staked assets by receiving sEGLD, a tokenised version of their position that can be used across all DeFi Applications within the MultiversX ecosystem, unlocking tremendous liquidity on the blockchain.',
    tag: PROPOSAL_TAG.liquidStaking,
    status: PROPOSAL_STATUS.active,
    upvotes: '312.12',
    downvotes: '0',
    publishedBlock: '5013216',
    creationBlock: '800',
    creationTimestamp: new Date().toISOString(),
    publishedTimestamp: new Date().toISOString(),
    published: true,
    executed: true,
    totalVotes: '312.12',
    percentageUpVotes: '100',
    percentageDownVotes: '0',
    highLighted: 'for',
  },
  // {
  //   title: 'Lending Protocol and Governance audits',
  //   description:
  //     'The Lending Protocol and Governance have undergone extensive internal development and rigorous testing, both by the community and internally, for a period exceeding 5 months. Furthermore, they have have been audited by Runtime Verification and Peckshield, one of the most prestigious audit firms in the crypto space. The purpose of this proposal is to determine whether additional audits of the smart contracts should be conducted by CertiK, Hacken, Halborn, Arda, and ABDK Consulting.',
  //   tag: PROPOSAL_TAG.lendingProtocol,
  //   status: PROPOSAL_STATUS.executed,
  //   upvotes: '2642703',
  //   downvotes: '2509959',
  //   publishedBlock: '5229216',
  // },
  // {
  //   title: 'Development of Hatom USD',
  //   description:
  //     'This proposal aims to determine whether we should start the development of USH, an over-collateralized Stablecoin that will be incorporated into the Lending Protocol. Users will have the possibility to supply their assets to the Lending Protocol, earn an APY on them, and use them as collateral for minting Hatom USD. Additionally, it will also include a discount functionality, enabling users to obtain a reduction on the borrowing interest rate when minting USH by using a designated asset as collateral.',
  //   tag: PROPOSAL_TAG.ushStaking,
  //   status: PROPOSAL_STATUS.executed,
  //   upvotes: '3618460',
  //   downvotes: '2375738',
  //   publishedBlock: '5530216',
  // },
  // {
  //   title: 'Upgrade the Price Oracle Smart Contract',
  //   description:
  //     'A Price Pracle is an essential component of any Lending Protocol. This proposal aims to decide whether to strengthen the current Price Oracle by upgrading it to a model that would allow for automated and immediate actions to pause a specific Money Market or the entire Lending Protocol. It would prevent transactions from being processed if any price manipulation is detected for the integrated assets of the Lending Protocol, without the requirement of a manual intervention.',
  //   tag: PROPOSAL_TAG.lendingProtocol,
  //   status: PROPOSAL_STATUS.executed,
  //   upvotes: '2900490',
  //   downvotes: '2078421',
  //   publishedBlock: '5952216',
  // },
  // {
  //   title: 'Set UTK collateral factor to 60%',
  //   description:
  //     'The Collateral Factor is a sensitive parameter that defines the maximum amount you can borrow against your Collateral inside the Lending Protocol. This proposition intends to decide whether we should update the Collateral Factor of the UTK token and increase it to 60%.',
  //   tag: PROPOSAL_TAG.lendingProtocol,
  //   status: PROPOSAL_STATUS.defeated,
  //   upvotes: '3331150',
  //   downvotes: '3725108',
  //   publishedBlock: '6005216',
  // },
  // {
  //   title: 'Website Translation',
  //   description:
  //     'The Hatom Protocol is a decentralized ecosystem designed to be used by anyone, anywhere around the world. This proposal seeks to enhance the user experience for the community by translating the website into additional languages, namely Romanian, French, Spanish, Portuguese, Korean, and Chinese, alongside English',
  //   tag: PROPOSAL_TAG.lendingProtocol,
  //   status: PROPOSAL_STATUS.executed,
  //   upvotes: '3766742',
  //   downvotes: '2370943',
  //   publishedBlock: '6023216',
  // },
  // {
  //   status: PROPOSAL_STATUS.executed,
  //   totalVotes: '149.2',
  //   percentageUpVotes: '87.26541554959785522788',
  //   percentageDownVotes: '12.73458445040214477212',
  //   highLighted: 'for',
  //   title: 'Launching Hatom Modules on the Mainnet',
  //   tag: 'lendingProtocol',
  //   id: '7',
  //   publishedBlock: '6200495',
  //   publishedTimestamp: '2023-06-06T13:06:54Z',
  //   description: `
  //     This proposal is about deciding whether to launch the Lending Protocol and Liquid Staking on the Mainnet between July and August. Both applications have been operating smoothly on the Devnet for months and have undergone extensive security audits, in addition to an upgraded App Design.
  //     The lending protocol has undergone thorough audits from renowned firms such as Runtime Verification, Hacken, Certik, Peckshield, Halborn, and Arda; and is currently being audited by ABDk Consulting. Sigma Prime will soon perform an audit as well. Similarly, the Liquid Staking module has been audited by Runtime Verification, CertiK, and Arda.
  //   `,
  //   published: true,
  //   executed: false,
  //   upvotes: '130.2',
  //   downvotes: '19',
  // },
];

export const USER_BALANCE_SOURCE_LIST = [
  USER_BALANCE_SOURCE.wallet,
  USER_BALANCE_SOURCE.staked,
  USER_BALANCE_SOURCE.safety,
];

export interface UserBalance {
  source: USER_BALANCE_SOURCE;
  amount: string;
}

export interface VoteAvailableToRedeem {
  voteId: number;
  proposalId: string;
}

export interface GovernanceState {
  proposals: Proposal[];
  htmEngagedInVoting: string | any;
  availableToRedeem: string | any;
  votesAvailableToRedeem: VoteAvailableToRedeem[];
  userBalances: Record<USER_BALANCE_SOURCE, UserBalance>;
  votes: Vote[];
}

const defaultUserBalances = USER_BALANCE_SOURCE_LIST.reduce(
  (prev, current) => ({
    ...prev,
    [current]: {
      source: current,
      amount: '0',
    },
  }),
  {} as Record<USER_BALANCE_SOURCE, UserBalance>,
);

const initialState: GovernanceState = {
  proposals: [],
  htmEngagedInVoting: '0',
  availableToRedeem: '0',
  userBalances: defaultUserBalances,
  votes: [],
  votesAvailableToRedeem: [],
};

export const governanceSlice = createSlice({
  name: 'governance',
  initialState,
  reducers: {
    setGovernance: (state, action: PayloadAction<Partial<GovernanceState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof GovernanceState] = value;
      });
    },
  },
});

export const { setGovernance } = governanceSlice.actions;

export const getProposals =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      dispatch(
        addAction(
          setGovernance({
            proposals: staticProposals,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getProposals', error);
      captureException(error);
    }
  };

export const getVoteNfts =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { tokens } = state.auth.account;

      const { voteNftId } = state.protocol.governance;
      const htmMarket = htmMarketSelector(state);

      const votes = formatVoteNfts(tokens, voteNftId, htmMarket);

      dispatch(
        addAction(
          setGovernance({
            votes,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getVoteNfts', error);
      captureException(error);
    }
  };

export const getUserBalances =
  () => (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();

      const { tokens } = state.auth.account;
      const htmMarket = htmMarketSelector(state);

      const htmToken = tokens.find(
        ({ tokenIdentifier }) => tokenIdentifier === htmMarket.underlying.id,
      );

      if (!htmToken) {
        dispatch(
          addAction(setGovernance({ userBalances: defaultUserBalances })),
        );
        return;
      }

      const userBalances = formatUserBalances(htmToken, htmMarket);

      dispatch(addAction(setGovernance({ userBalances })));
    } catch (error) {
      logger.error('store:getUserBalances', error);
      captureException(error);
    }
  };

export const governanceSelector = (state: RootState) => state.governance;

export default governanceSlice.reducer;
