import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import CurrencyInput from 'react-currency-input-field';

import useMediaQuery from '@/hooks/useMediaQuery';

import CurrencyInputButton from '@/components/CurrencyInputButton';
import Hint from '@/components/Hint';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import FutureStatsRightArrow from '@/components/Icons/FutureStatsRightArrow';
import InfoIcon from '@/components/Icons/InfoIcon';
import LabeledImage from '@/components/LabeledImage';
import { NetAPYTooltipContent } from '@/components/Lending/NetAPYTooltipContent';
import { SEGLDLiquidStakingApyPercentage } from '@/components/Lending/SEGLDLiquidStakingApyPercentage';
import OperationFormBottomAlerts from '@/components/OperationFormBottomAlerts';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import CRUDButton from '@/components/popups/SupplyPopup/components/CRUDButton';
import AddSupplyCheckbox from '@/components/popups/SupplyPopup/components/CRUDButton/AddSupplyCheckbox';
import AddWithdrawCheckbox from '@/components/popups/SupplyPopup/components/CRUDButton/AddWithdrawCheckbox';
import useSupplyData from '@/components/popups/SupplyPopup/hooks/useSupplyData';
import useSupplyForm from '@/components/popups/SupplyPopup/hooks/useSupplyForm';
import useSupplyListenerFormFields from '@/components/popups/SupplyPopup/hooks/useSupplyListenerFormFields';
import useSupplyListenerSubmitButton from '@/components/popups/SupplyPopup/hooks/useSupplyListenerSubmitButton';
import useSupplySubmit from '@/components/popups/SupplyPopup/hooks/useSupplySubmit';
import { COLLATERAL_MODE } from '@/components/popups/SupplyPopup/types/collateral-mode';
import {
  getTabIndex,
  SUPPLY_TABS,
} from '@/components/popups/SupplyPopup/types/tab';
import Tabber from '@/components/Tabber';
import TransactionButton from '@/components/TransactionButton';

import { ASSET_ALT_NAME, MARKET_KEY } from '@/store/protocol';

import {
  formatNumber,
  getBorrowLimitUsedPercent,
  nFormatter,
  toFormattedDecimalNum,
} from '@/utils/helpers';

const SupplyPopup = () => {
  const { t } = useTranslation();
  const md = useMediaQuery('(min-width: 768px)');

  const supplyForm = useSupplyForm({});
  const supplyData = useSupplyData({
    supplyForm,
  });

  const { handleSubmitInteraction } = useSupplySubmit({
    supplyForm,
    supplyData,
  });

  useSupplyListenerFormFields({
    supplyForm,
    supplyData,
  });
  useSupplyListenerSubmitButton({
    supplyForm,
    supplyData,
  });

  return (
    <PopupBed close={false}>
      <form
        className='font-semibold leading-tight tracking-normal'
        onSubmit={(event) => {
          event.preventDefault();

          if (supplyForm.submitButton.disabled) {
            return;
          }

          handleSubmitInteraction();
        }}
      >
        <PopupHead>
          <LabeledImage
            name={
              ASSET_ALT_NAME[supplyData.market.underlying.symbol] ??
              supplyData.market.name
            }
            path={supplyData.market.logo.normal}
            night={supplyData.market.logo.nightMode}
          />
        </PopupHead>
        <>
          <div className='relative mx-2.5 my-2'>
            <div>
              <div className='relative'>
                <input type='text' style={{ display: 'none' }} />
                <Hint
                  autoReplace
                  unvisible={!supplyForm.maxSelected}
                  content={
                    <span className='text-[8px] md:text-[10px]'>
                      {t('maximum-amount-is-selected')}
                    </span>
                  }
                  placement='top-center'
                  block
                >
                  <CurrencyInput
                    ref={supplyForm.inputRef}
                    prefix={supplyData.prefix}
                    className={classNames(
                      'w-full max-w-[442px]',
                      'p-4 px-[70px]',
                      'text-center text-[28px] font-normal leading-tight placeholder:text-[#6A6A8C] disabled:text-[#6A6A8C] dark:text-indigo-100 placeholder:dark:text-[#6A6A8C] disabled:dark:text-[#6A6A8C]',
                      'placeholder:text-current',
                      supplyForm.maxSelected &&
                        '!text-[#6A6A8C] !opacity-100',
                      'outline-none',
                      'bg-transparent',
                    )}
                    autoFocus
                    placeholder={`0 ${supplyForm.hasFocus? '' : supplyData.market.underlying.symbol}`}
                    onFocus={supplyForm.handleInputFocus}
                    onBlur={supplyForm.handleInputBlur}
                    suffix={
                      !supplyForm.hasFocus
                        ? ` ${supplyData.market.underlying.symbol}`
                        : ''
                    }
                    value={supplyForm.inputValue}
                    autoComplete='off'
                    aria-autocomplete='none'
                    decimalsLimit={supplyData.market.underlying.decimals}
                    groupSeparator=','
                    decimalSeparator='.'
                    allowNegativeValue={false}
                    // disabled={maxSelected}
                    onValueChange={supplyForm.handleInputValueChange}
                    inputMode='text'
                  />
                </Hint>
                <div className='absolute right-3 top-1/2 grid -translate-y-1/2 place-items-end'>
                  <Hint
                    content={
                      <span className='text-[8px] md:text-[10px]'>
                        {supplyForm.isCollateralying &&
                        !supplyForm.isAddingCollateral
                          ? t('set-maximum-recommended-by-the-protocol')
                          : t('set-maximum')}
                      </span>
                    }
                    placement={md ? 'top-center' : 'top-end'}
                  >
                    <CurrencyInputButton
                      onClick={supplyForm.handleSetMaxAmount}
                      selected={supplyForm.maxSelected}
                    >
                      {supplyData.showEightyPercentButton ? (
                        <div>
                          80% <br />
                          {t('limit')}
                        </div>
                      ) : (
                        <span>{t('max')}</span>
                      )}
                    </CurrencyInputButton>
                  </Hint>
                </div>
              </div>
              <div className='mt-3 flex justify-between'>
                <div className='EstimatedPrice'>
                  <div className='text-xs font-semibold dark:text-white'>
                    {t('estimated-price')}
                  </div>
                  <div className='flex items-center gap-1 text-xs text-[#6A6A8C] dark:text-[#625E8F]'>
                    {`${nFormatter(
                      supplyData.inputPriceHTokenFullDecimals,
                      3,
                    )} ${supplyData.market.hToken.symbol}`}
                    <ApproEqualIcon className='mt-0.5' />
                    {`$${formatNumber(supplyData.inputPriceUSD, 2)}`}
                  </div>
                </div>
                {supplyForm.isCollateralying && (
                  <div className='flex gap-3.5 self-start'>
                    <CRUDButton
                      label={t('add')}
                      action='add'
                      isActive={supplyForm.isAddingCollateral}
                      onClick={() =>
                        supplyForm.setCollateralOption(COLLATERAL_MODE.ADD)
                      }
                    />
                    <CRUDButton
                      label={t('remove')}
                      action='remove'
                      isActive={supplyForm.isRemovingCollateral}
                      onClick={() =>
                        supplyForm.setCollateralOption(COLLATERAL_MODE.REMOVE)
                      }
                    />
                  </div>
                )}

                {supplyForm.isSupplying && (
                  <AddSupplyCheckbox
                    checked={supplyForm.isAddingSupplyAndCollateral}
                    onChange={(checked) =>
                      supplyForm.setIsAddingSupplyAndCollateral(checked)
                    }
                  />
                )}

                {supplyForm.isWithdrawing && (
                  <AddWithdrawCheckbox
                    checked={supplyForm.isRemovingCollateralAndWithdraw}
                    onChange={(checked) =>
                      supplyForm.setIsRemovingCollateralAndWithdraw(checked)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>

        <>
          <div>
            <Tabber
              onChange={supplyForm.onTabToggle}
              tabs={SUPPLY_TABS.map((key) => t(key.toLowerCase()))}
              defaultSelected={getTabIndex(supplyForm.selectedTab)}
              customTheme={(tabIdx) => {
                if (Number.isInteger(tabIdx))
                  return 'text-[#6431B7] dark:text-[#8C80D4] border-current dark:border-current';
                else
                  return 'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] hover:border-[#BEC2E6] dark:border-[#3F3B76]';
              }}
            >
              <div className='bg-[#FCFAFF] p-5 pb-3 dark:bg-[#292651]'>
                {/* Rate */}
                <>
                  <div className='pb-4'>
                    <div className='text-xs dark:text-indigo-100'>
                      {t('supply-rates')}
                    </div>
                    <div className='mt-2 flex items-center justify-between px-1 dark:text-white'>
                      <div className='flex items-center gap-2'>
                        <div>
                          <img
                            className='h-[36px]'
                            src={supplyData.market.logo.normal}
                            alt=''
                          />
                        </div>
                        <div className='text-lg'>{t('supply-apy')}</div>
                      </div>
                      <div>{`${supplyData.market.supplyAPY}%`}</div>
                    </div>
                  </div>
                </>

                {/* Rewards */}
                <div className='space-y-2'>
                  {supplyData.market.underlying.symbol === MARKET_KEY.sEGLD &&
                    supplyData.showLiquidStakingAPY && (
                      <SEGLDLiquidStakingApyPercentage
                        token={MARKET_KEY.sEGLD}
                        apy={supplyData.liquidStakingAPY}
                      />
                    )}

                  {supplyData.market.underlying.symbol === MARKET_KEY.sWTAO &&
                    supplyData.showLiquidStakingTaoAPY && (
                      <SEGLDLiquidStakingApyPercentage
                        token={MARKET_KEY.sWTAO}
                        apy={supplyData.liquidStakingTaoAPY}
                      />
                    )}

                  {supplyData.supplyControllerRewardBatchesV1.length > 0 ||
                  supplyData.supplyControllerRewardBatchesV2.length > 0 ||
                  (supplyData.market.underlying.symbol === MARKET_KEY.sEGLD &&
                    supplyData.showLiquidStakingAPY) ||
                  (supplyData.market.underlying.symbol === MARKET_KEY.sWTAO &&
                    supplyData.showLiquidStakingTaoAPY) ? (
                    <div className='-mx-1 flex flex-col rounded-[8px] bg-[#D9D7FB] p-2 pt-1 text-sm dark:bg-[#4A45A3] dark:text-white'>
                      <div className='pl-2'>
                        {supplyData.supplyControllerRewardBatchesV1.map(
                          (rewardTokenItem) => (
                            <div
                              key={rewardTokenItem.batchId}
                              className='flex items-center gap-3.5 py-[3px]'
                            >
                              <div className='rounded-full border border-[#9097D9] dark:border-[#9097D9]'>
                                <img
                                  src={rewardTokenItem.logo}
                                  alt={`Logo of ${rewardTokenItem.tokenId}`}
                                  className='h-5 w-5'
                                />
                              </div>
                              <span>{t('rewards-apy')}</span>
                              <span className='ml-auto'>
                                {formatNumber(rewardTokenItem.apy)}%
                              </span>
                            </div>
                          ),
                        )}

                        {supplyData.supplyControllerRewardBatchesV2
                          .filter(({ isActive }) => isActive)
                          .map((rewardTokenItem, rewardTokenItemIndex) => (
                            <div
                              key={rewardTokenItemIndex}
                              className='flex items-center gap-3.5 py-[3px]'
                            >
                              <div className='rounded-full border border-[#9097D9] dark:border-[#9097D9]'>
                                <img
                                  src={rewardTokenItem.logo}
                                  alt={`Logo of ${rewardTokenItem.name}`}
                                  className='h-5 w-5'
                                />
                              </div>
                              <span>
                                {rewardTokenItem.isExtra
                                  ? `${t(
                                      'extra-booster-apy',
                                      'Extra Booster APY',
                                    )}`
                                  : `${t(
                                      'base-booster-apy',
                                      'Base Booster APY',
                                    )}`}
                              </span>
                              <span className='ml-auto'>
                                {formatNumber(rewardTokenItem.totalAPY)}%
                              </span>
                            </div>
                          ))}
                      </div>

                      <div className='ml-11 flex h-7 items-center'>
                        <div className='flex w-fit items-center gap-1'>
                          {t('net-apy')}
                          <Hint
                            delayEnter={0}
                            placement={md ? 'top-center' : 'top-center'}
                            content={
                              <NetAPYTooltipContent
                                parts={[
                                  ...((supplyData.market.underlying.symbol ===
                                    MARKET_KEY.sEGLD &&
                                    supplyData.showLiquidStakingAPY) ||
                                  (supplyData.market.underlying.symbol ===
                                    MARKET_KEY.sWTAO &&
                                    supplyData.showLiquidStakingTaoAPY)
                                    ? ['Liquid Staking']
                                    : []),
                                  'Supply',
                                  'Rewards',
                                ]}
                              />
                            }
                          >
                            <InfoIcon
                              className='text-[#7A77A5] dark:text-[#A29ED6]'
                              strokeWidth={1.3}
                              width={12}
                            />
                          </Hint>
                        </div>
                        <span className='grow'></span>
                        <span>
                          {formatNumber(supplyData.totalSupplyAPY, 3)}%
                        </span>
                      </div>
                      <div className='min-h-[28px] rounded-md bg-[#746FC0] px-2 py-1.5 text-center text-xs text-[#fff] dark:bg-[#716BE0]'>
                        {t('only-rewards-tokens-as-collateral')}
                      </div>
                    </div>
                  ) : (
                    <div className='py-5'></div>
                  )}
                </div>

                {/* Borrow Limit */}
                <>
                  <div className='mt-3'>
                    <span className='text-xs dark:text-indigo-100'>
                      {t('borrow-limits')}
                    </span>
                    <div className='mt-2 flex items-center justify-between border-b-2 border-current py-2 pt-0 text-sm leading-tight text-[#6A6A8C] dark:border-[#625E8F]/50 dark:text-[#625E8F]'>
                      <div>{t('borrow-limit')}</div>
                      <div className='flex items-center justify-center gap-1'>
                        <div className='flex items-center gap-1'>
                          {`$${formatNumber(supplyData.borrowLimitUSD, 2)}`}
                          {supplyData.isValidAmountRequest && (
                            <>
                              <FutureStatsRightArrow />
                              {`$${formatNumber(
                                supplyData.nextBorrowLimitAmountUSD as number,
                                2,
                              )}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center justify-between py-2 text-sm leading-tight text-[#6A6A8C] dark:text-[#625E8F]'>
                      <div>{t('borrow-limit-used')}</div>
                      <div className='flex items-center gap-1'>
                        {`${formatNumber(
                          getBorrowLimitUsedPercent(
                            supplyData.borrowBalanceUSD,
                            supplyData.borrowLimitUSD,
                          ),
                          2,
                        )}%`}
                        {supplyData.isValidAmountRequest && (
                          <>
                            <FutureStatsRightArrow />
                            {`${formatNumber(
                              supplyData.nextBorrowLimitUsedPercentage as number,
                              2,
                            )}%`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/* Action Button */}
                <div className='mt-3 flex gap-1'>
                  <div className='flex-1'>
                    <Hint
                      autoReplace
                      content={
                        <span className='text-[8px] md:text-[10px]'>
                          {supplyData.hasPendingTransactions
                            ? t('translation:wait-tx-inprogress')
                            : t('translation:tx-not-allowed')}
                        </span>
                      }
                      placement={md ? 'top-center' : 'top-end'}
                      className='w-full'
                      unvisible={!supplyData.hasPendingTransactions}
                    >
                      <TransactionButton
                        text={supplyForm.submitButton.label}
                        disabled={supplyForm.submitButton.disabled}
                      />
                    </Hint>
                  </div>
                </div>

                {/* Bottom Note */}
                <div className='mt-3.5 space-y-1.5 text-sm text-[#6A6A8C] dark:text-[#625E8F]'>
                  {supplyData.footerNotes.map(({ label, value }) => {
                    return (
                      <div key={label} className='flex justify-between'>
                        <span>{label}</span>
                        {value && (
                          <span>{`${toFormattedDecimalNum(
                            value,
                            value == '0'
                              ? 2
                              : supplyData.market.underlying.decimals,
                          )} ${supplyData.market.underlying.symbol}`}</span>
                        )}
                      </div>
                    );
                  })}

                  <OperationFormBottomAlerts />
                </div>
              </div>
            </Tabber>
          </div>
        </>
      </form>
    </PopupBed>
  );
};

export default SupplyPopup;
