import { Message, Transaction } from '@multiversx/sdk-core';
import { IDAppProviderAccount } from '@multiversx/sdk-dapp-utils/out';
import { providerNotInitializedError } from '@multiversx/sdk-dapp-utils/out/helpers/providerNotInitializedError';
import { WebviewProvider } from '@multiversx/sdk-webview-provider/out/WebviewProvider';

import logger from '@/utils/logger';

import { IDappProvider } from '@/types/dappProvider';

export class ExperimentalWebviewProvider implements IDappProvider {
  private static _instance: ExperimentalWebviewProvider;
  private readonly _provider: WebviewProvider;

  static getInstance() {
    if (!ExperimentalWebviewProvider._instance) {
      ExperimentalWebviewProvider._instance = new ExperimentalWebviewProvider();
    }
    return ExperimentalWebviewProvider._instance;
  }

  getAccount(): IDAppProviderAccount | null {
    const data = this._provider.getAccount();
    return { address: data?.address ?? '' };
  }

  setAccount(account: IDAppProviderAccount): void {
    this._provider.setAccount(account);
  }

  constructor() {
    this._provider = WebviewProvider.getInstance({});
  }

  init = async () => {
    return await this._provider.init();
  };

  login = async () => {
    const data = await this._provider.login();
    return { address: data?.address ?? '' };
  };

  logout = async () => {
    return await this._provider.logout();
  };

  relogin = async () => {
    const accessToken = await this._provider.relogin();

    if (!accessToken) {
      logger.error('Unable to re-login. Missing accessToken.');
      return null;
    }

    return accessToken;
  };

  signTransactions = async (
    transactionsToSign: Transaction[],
  ): Promise<Transaction[]> => {
    const response = await this._provider.signTransactions(transactionsToSign);

    if (!response) {
      this._provider.cancelAction();
      return [];
    }

    return response;
  };

  signTransaction = async (transaction: Transaction) => {
    return (await this._provider.signTransaction(transaction)) as Transaction;
  };

  signMessage = async (message: Message): Promise<Message | null> => {
    return await this._provider.signMessage(message);
  };

  cancelAction = async () => {
    return await this._provider.cancelAction();
  };

  isInitialized = () => {
    return this._provider.isInitialized();
  };

  isConnected = () => {
    return this._provider.isConnected();
  };

  sendCustomRequest = async (payload: {
    request: { method: string; params: any };
  }) => {
    this._provider.sendPostMessage({
      type: payload.request.method as any,
      payload: payload.request.params,
    });
  };

  getAddress = providerNotInitializedError('getAddress');
}
