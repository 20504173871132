import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOnClickOutside } from 'usehooks-ts';

import { ManageDropdownContent } from '@/components/popups/HTMBoosterPopup1/components/XExchangeDashboard/components/Tokens/components/TokenSubCategories/ManageDropdown';
import { ManageEnergyPositionRowActionsDropdown } from '@/components/popups/ManageEnergyPopup/components/EnergyPositionTable/EnergyPositionRowDropdown';

import { useAppSelector } from '@/store';
import { closeDropdown, dropdownSelector } from '@/store/dropdown';

import clsxm from '@/services/clsxm';

const dropdownsMap: Record<string, any> = {
  htm_booster_manage: <ManageDropdownContent />,
  manage_energy_position_row_actions_dropdown: (
    <ManageEnergyPositionRowActionsDropdown />
  ),
};

interface DropdownContextProviderProps {
  children: Array<JSX.Element> | JSX.Element;
}

export const PopupContext = createContext({});

const useGlobalScroll = (callback: (event: Event) => void) => {
  useEffect(() => {
    // Attach the scroll event listener to the document in capture phase to catch all scroll events.
    const handleScroll = (event: Event) => {
      callback(event);
    };

    document.addEventListener('scroll', handleScroll, { capture: true });

    // Cleanup the event listener on unmount.
    return () => {
      document.removeEventListener('scroll', handleScroll, { capture: true });
    };
  }, [callback]);
};

export const AbsoluteDropdownProvider = ({
  children,
}: DropdownContextProviderProps) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const { parentButtonRef, name, isOpen, className } =
    useAppSelector(dropdownSelector);
  const dispatch = useDispatch();

  const ref = useRef<any>();
  useOnClickOutside(ref, () => {
    dispatch(closeDropdown());
  });

  useGlobalScroll(() => {
    dispatch(closeDropdown());
  });

  useEffect(() => {
    if (parentButtonRef) {
      const rect = parentButtonRef.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [parentButtonRef]);

  return (
    <PopupContext.Provider value={{}}>
      {children}

      <AnimatePresence>
        {isOpen && (
          <LazyMotion features={domAnimation}>
            <m.div
              ref={ref}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
              className={clsxm('absolute z-[99999]')}
              style={{
                top: position.top,
                left: position.left,
              }}
              onClick={() => {
                dispatch(closeDropdown());
              }}
            >
              <div className={className}>
                {isOpen && name && dropdownsMap[name]}
              </div>
            </m.div>
          </LazyMotion>
        )}
      </AnimatePresence>
    </PopupContext.Provider>
  );
};
