import React from 'react';

import clsxm from '@/services/clsxm';

export const InputButton: React.FC<any> = ({
  onClick,
  children,
  selected,
  ...props
}) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={clsxm(
        'whitespace-nowrap rounded-[10px] px-2.5 py-1.5 font-hass text-[14px] capitalize text-[#535367] transition-colors hover:bg-[#E0E1FF] hover:text-[#006FFF] dark:text-[#625E8F] dark:hover:bg-[#403C7C] dark:hover:text-[#006FFF] md:rounded-[16px] md:text-[16px]',
        {
          'bg-[#E0E1FF] text-[#006FFF] dark:bg-[#403C7C] dark:text-[#006FFF]':
            selected,
        },
      )}
      {...props}
    >
      {children}
    </button>
  );
};
