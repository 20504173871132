import DefiUtils from 'defi-utils';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { Trans } from 'next-i18next';

import useBoosterV2Position from '@/hooks/boosterV2/useBoosterV2Position';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTheme from '@/hooks/useTheme';

import Hint from '@/components/Hint';

import { useAppDispatch, useAppSelector } from '@/store';
import { openPopup } from '@/store/popup';
import { MAX_BOOSTER_RATIO, protocolSelector } from '@/store/protocol';

import FlagInfos from '@/sections/Liquid/App/Form/components/HtmBoosterLiquid/components/FlagInfos';
import Minus from '@/sections/Liquid/App/Form/components/HtmBoosterLiquid/components/Minus';
import Plus from '@/sections/Liquid/App/Form/components/HtmBoosterLiquid/components/Plus';
import {
  BackdropShadow,
  BackdropShadowDark,
  BoosterLogo,
  CurrentApiInteractiveSlider,
  heightClassName,
  StatusBadge,
} from '@/sections/Liquid/App/Form/components/HtmBoosterLiquid/HtmBoosterLiquid.components';
import { ushTooltipBaseClassNames } from '@/sections/Ush/components/constants';
import UshShimmer from '@/sections/Ush/components/UshShimmer';
import clsxm from '@/services/clsxm';
import { formatNumber, nFormatter } from '@/utils/helpers';

import { ROUTES } from '@/types/enums';

function HtmBoosterLiquid({ programId }: { programId: number }) {
  const { isDark } = useTheme();
  const router = useRouter();
  const {
    totalCurrentAPY,
    currentAPYWidth,
    totalMaxAPY,
    baseAPYWidth,
    baseAPYHover,
    setBaseAPYHover,
    totalStakedHTM,
    totalStakedUSD,
    instaCompliance,
    totalBoosterNormalAPY,
    hasFirstInfo,
    hasBoosterPosition,
  } = useBoosterV2Position({
    programId,
    positionType:
      router.route === ROUTES.LIQUID_MULTIVERSX_APP ? 'ls-egld' : 'ls-wtao',
  });

  const dispatch = useAppDispatch();
  const md = useMediaQuery('(min-width: 768px)');
  const { isLoadingFirstInfo } = useAppSelector(protocolSelector);

  return (
    <div
      className={clsxm(
        'select-none relative w-[197px] md:w-[264px] h-[84px] md:h-[110px]',
        {
          'pointer-events-none': !hasFirstInfo,
        },
      )}
    >
      <div
        className={clsxm('absolute top-0 z-0', isDark ? '-left-1' : 'left-0')}
      >
        {!isDark ? <BackdropShadow /> : <BackdropShadowDark />}
      </div>

      <div className='relative z-0 h-full w-full pl-[8px] pr-[8px] py-[7px] md:pl-[11.6px] md:pr-[12.7px] md:pt-[11.1px] md:pb-[10px] xl:py-[10px] xl:px-[12px]'>
        <UshShimmer on={isLoadingFirstInfo} w={md ? 240 : 180} h={md ? 24 : 21}>
          <div className='flex items-start justify-between h-[21px] md:h-[24px] w-full'>
            <div className='h-full flex items-center gap-[4.5px] md:gap-[5.5px]'>
              <Hint
                autoReplace
                backgroundColor='#030303'
                placement='bottom-center'
                content={
                  <div
                    className={clsxm('max-w-[416px]', ushTooltipBaseClassNames)}
                  >
                    <Trans
                      i18nKey='tooltip-ush-412'
                      className='!tracking-[0.01em] text-[8px] md:text-[10px]'
                      components={[
                        <span className='text-[#5AF1B0]' />,
                        <span className='text-[#E24949]' />,
                        <span className='text-[#5CD1FB]' />,
                        <span className='text-[#347A98]' />,
                        <span className='text-[#2F8A68]' />,
                        <span className='text-[#FBCD17]' />,
                      ]}
                      ns='translation'
                    />
                  </div>
                }
              >
                <BoosterLogo />
              </Hint>
              <div className='text-[8px] md:text-[10px]  font-semibold translate-y-px md:translate-y-[0.5px]'>
                <p className='leading-[125%] text-[#3F3B65] dark:text-white'>
                  {nFormatter(totalStakedHTM)} HTM
                </p>
                <p className='translate-y-[-1.7px] leading-[125%] text-[#7E78B2] dark:text-[#7E78B2]'>
                  ${formatNumber(totalStakedUSD)}
                </p>
              </div>
            </div>

            <div className='flex items-center gap-[4px] md:gap-[5.5px] h-full translate-y-[-3.1px] md:translate-y-[-4.2px] translate-x-px'>
              <div className='flex items-center gap-1 translate-x-[-0.5px]'>
                <button
                  type='button'
                  className='flex-xy-center w-[14px] h-[14px] md:w-[15px] md:h-[15px] rounded-[5px] bg-[#5AF1B0]/30 dark:bg-[#5AF1B04D] hover:bg-[#5AF1B0]/60 cursor-pointer'
                  onClick={() => {
                    dispatch(
                      openPopup({
                        name: 'htm_booster_ush',
                        data: {
                          programId,
                          tab: 'stake',
                        },
                      }),
                    );
                  }}
                >
                  <span className=' inline-block md:translate-y-[-0.5px]'>
                    <Plus />
                  </span>
                </button>
                <button
                  type='button'
                  className='flex-xy-center w-[14px] h-[14px] md:w-[15px] md:h-[15px] rounded-[5px] bg-[#FFEBD9] dark:bg-[#EF77484D] hover:bg-[#FFC899] cursor-pointer'
                  onClick={() => {
                    dispatch(
                      openPopup({
                        name: 'htm_booster_ush',
                        data: {
                          programId,
                          tab: 'unstake',
                        },
                      }),
                    );
                  }}
                >
                  <span className=' inline-block md:translate-y-[-0.5px]'>
                    <Minus />
                  </span>
                </button>
              </div>

              <div className='h-[14px] md:h-[15px] w-px rounded-full bg-[#E4E4FE] dark:bg-[#2E2C51] translate-x-[0px] md:translate-x-[-0.6px]' />

              <div className='min-w-[51px] md:min-w-[57px] flex justify-end'>
                <StatusBadge programId={programId} />
              </div>
            </div>
          </div>
        </UshShimmer>
        <UshShimmer
          on={isLoadingFirstInfo}
          w={md ? 240 : 180}
          h={md ? 56 : 37}
          className='translate-y-[6px]'
        >
          <div className='relative h-[39px] md:h-[52px] translate-y-[-1px] md:translate-y-[4.8px] translate-x-px w-full flex justify-end flex-col '>
            <div
              className={clsxm(
                'px-2 h-[8px] absolute bottom-[-8px] bg-transparent z-[9999] cursor-pointer',
                {
                  'pointer-events-none': !hasBoosterPosition,
                },
              )}
              style={{ left: 0, width: `${baseAPYWidth}%` }}
              onMouseEnter={() => {
                setBaseAPYHover(true);
              }}
              onMouseLeave={() => {
                setBaseAPYHover(false);
              }}
            />

            {/* current APY */}
            {hasBoosterPosition ? (
              <>
                <AnimatePresence mode='wait'>
                  {!baseAPYHover && (
                    <FlagInfos
                      apy={totalCurrentAPY}
                      instaCompliance={instaCompliance}
                      label='Current APY:'
                      left={0.4}
                      lineColor='#E04040'
                      bgColor={isDark ? '#8F586166' : '#FFF1F0'}
                      direction='right'
                      className='bottom-[0px] z-0'
                      hint={
                        <div
                          className={clsxm(
                            'max-w-[330px]',
                            ushTooltipBaseClassNames,
                          )}
                        >
                          <Trans
                            i18nKey='tooltip-ush-413'
                            className='!tracking-[0.01em] text-[8px] md:text-[10px]'
                            components={[
                              <span className='text-[#5CD1FB]' />,
                              <span className='text-[#8FFFDB]' />,
                              <span className='text-[#5AF1B0]' />,
                              <span className='text-[#E04040]' />,
                            ]}
                            ns='translation'
                          />
                        </div>
                      }
                    />
                  )}
                </AnimatePresence>

                <CurrentApiInteractiveSlider
                  value={currentAPYWidth}
                  // value={60}
                  baseAPYWidth={baseAPYWidth}
                  baseAPYHover={baseAPYHover}
                  onChange={(value: number) => {
                    // setLeft(value);
                  }}
                />
              </>
            ) : (
              <AnimatePresence mode='wait'>
                <FlagInfos
                  apy={totalBoosterNormalAPY}
                  instaCompliance='1'
                  label='Base APY'
                  left={0.6}
                  lineColor='#5D47E8'
                  bgColor={isDark ? '#5338A666' : '#F1EFFF'}
                  hint={
                    <div
                      className={clsxm(
                        'max-w-[264px]',
                        ushTooltipBaseClassNames,
                      )}
                    >
                      <Trans
                        i18nKey='tooltip-ush-418'
                        className='!tracking-[0.01em] text-[8px] md:text-[10px]'
                        components={[
                          <span className='text-[#B487FE]' />,
                          <span className='text-[#5AF1B0]' />,
                          <span className='text-[#E24949]' />,
                        ]}
                        ns='translation'
                      />
                    </div>
                  }
                />
              </AnimatePresence>
            )}

            {/* pink bar with diagonal stripes */}
            <div
              className={clsxm(
                'absolute top-[100%] cursor-pointer',
                heightClassName,
              )}
              style={{
                // width: `${30}%`,
                width: `${baseAPYWidth <= 0 ? 0.3 : baseAPYWidth}%`,
              }}
            >
              <div className='w-full h-full absolute left-0 top-0 pinkDiagonalStripes z-10' />
              <div className='relative w-full h-full '>
                <AnimatePresence mode='wait'>
                  {baseAPYHover && (
                    <FlagInfos
                      apy={totalBoosterNormalAPY}
                      instaCompliance='1'
                      label='Base APY: '
                      left={baseAPYWidth <= 0 ? 100 : 100}
                      lineColor='#5D47E8'
                      bgColor={isDark ? '#5338A666' : '#F1EFFF'}
                      direction='right'
                      className='bottom-[8px] z-0'
                      hint={
                        <div
                          className={clsxm(
                            'max-w-[264px]',
                            ushTooltipBaseClassNames,
                          )}
                        >
                          <Trans
                            i18nKey='tooltip-ush-418'
                            className='!tracking-[0.01em] text-[8px] md:text-[10px]'
                            components={[
                              <span className='text-[#B487FE]' />,
                              <span className='text-[#5AF1B0]' />,
                              <span className='text-[#E24949]' />,
                            ]}
                            ns='translation'
                          />
                        </div>
                      }
                    />
                  )}
                </AnimatePresence>

                <div className='absolute top-1/2 -translate-y-1/2 right-0 translate-x-[0.5px] w-[2px] h-[13px] bg-[#412EB5] rounded-[20px] z-[999]' />
              </div>
            </div>

            {/* max apy */}
            <>
              <AnimatePresence mode='wait'>
                {!baseAPYHover && (
                  <FlagInfos
                    apy={totalMaxAPY}
                    instaCompliance={
                      new DefiUtils(instaCompliance).isGreaterThan(
                        MAX_BOOSTER_RATIO,
                      )
                        ? instaCompliance
                        : MAX_BOOSTER_RATIO.toString()
                    }
                    label='Max APY:'
                    left={100}
                    lineColor='#5AF1B0'
                    bgColor={isDark ? '#2D585966' : '#F0FFF9'}
                    direction='left'
                    hint={
                      <div
                        className={clsxm(
                          'max-w-[284px]',
                          ushTooltipBaseClassNames,
                        )}
                      >
                        <Trans
                          i18nKey='tooltip-ush-415'
                          className='!tracking-[0.01em] text-[8px] md:text-[10px]'
                          components={[
                            <span className='text-[#00FF5D]' />,
                            <span className='text-[#5AF1B0]' />,
                          ]}
                          ns='translation'
                        />
                      </div>
                    }
                  />
                )}
              </AnimatePresence>

              <div
                className={clsxm(
                  heightClassName,
                  'w-full absolute top-[100%] left-0 bg-[#5AF1B0] z-0',
                )}
              />
            </>
          </div>
        </UshShimmer>
      </div>
    </div>
  );
}

export default HtmBoosterLiquid;
