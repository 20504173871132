import DefiUtils from 'defi-utils';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useAccountManagerDeployerInteraction from '@/hooks/interaction/useAccountManagerDeployerInteraction';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';

import { ReturnUseSupplyData } from '@/components/popups/SupplyPopup/hooks/useSupplyData';
import { ReturnUseSupplyForm } from '@/components/popups/SupplyPopup/hooks/useSupplyForm';
import { TABS } from '@/components/popups/SupplyPopup/types/tab';

import { useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import {
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

const useSupplySubmit = ({
  supplyForm,
  supplyData,
}: {
  supplyForm: ReturnUseSupplyForm;
  supplyData: ReturnUseSupplyData;
}) => {
  const {
    inputValueAsBigNumber,
    inputValueAsHTokenBigNumber,
    inputValue,
    isAddingCollateral,
    isRemovingCollateral,
    isAddingSupplyAndCollateral,
    selectedTab,
    isRemovingCollateralAndWithdraw,
  } = supplyForm;

  const {
    isRemovingAllCollateral,
    hasMaxMarketPerAccount,
    isAddingAllCollateral,
    isCashGreatherThanUnderlyingWalletBalance,
    isCashGreatherThanUnderlyingCollateralBalance,
    maxCash,
    isWithdrawingAll,
    market,
    hasPendingTransactions,
    marketsInteractedAmount,
    marketsInteracted,
    controller,
  } = supplyData;

  const { signTransactions } = useSignMultipleTransactions();
  const { deployAccountManager } = useAccountManagerDeployerInteraction();
  const { proxyAddress, selectedTypeAddress } = useAppSelector(accountSelector);
  const {
    supplyLiquidity,
    supplyLiquidityAndAddCollateral,
    withdrawLiquidity,
    removeAccountMarket,
    removeCollateralAndWithdrawLiquidity,
    addCollateral,
    removeCollateral,
  } = useLendInteraction();

  const handleSubmitInteraction = async () => {
    if (hasPendingTransactions) {
      return;
    }

    const s_inputValueAsBigNumber = new DefiUtils(
      inputValueAsBigNumber,
    ).toFixed(0, DefiUtils.ROUND_DOWN);

    const s_inputValueAsHTokenBigNumber = new DefiUtils(
      inputValueAsHTokenBigNumber,
    ).toFixed(0, DefiUtils.ROUND_HALF_UP);

    switch (selectedTab) {
      case TABS.SUPPLY: {
        if (isAddingSupplyAndCollateral) {
          await signTransactions(
            [
              ...(selectedTypeAddress === 'proxy' && !proxyAddress
                ? [deployAccountManager()]
                : []),
              supplyLiquidityAndAddCollateral({
                tokenKey: market.underlying.symbol,
                amountAsBigNumber: s_inputValueAsBigNumber,
              }),
            ],
            {
              token: market.underlying.symbol,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.SUPPLY_AND_ADD_COLLATERAL,
              result: inputValue,
              isSecuencial: true,
            },
          );

          return;
        }

        await signTransactions(
          [
            ...(selectedTypeAddress === 'proxy' && !proxyAddress
              ? [deployAccountManager()]
              : []),
            supplyLiquidity({
              tokenKey: market.underlying.symbol,
              amountAsBigInteger: s_inputValueAsBigNumber,
            }),
          ],
          {
            token: market.underlying.symbol,
            group: TRANSACTION_GROUP_TYPE.LENDING,
            subgroup: TRANSACTION_SUBGROUP_TYPE.SUPPLY,
            result: inputValue,
            isSecuencial: true,
          },
        );

        return;
      }

      case TABS.WITHDRAW: {
        if (isRemovingCollateralAndWithdraw) {
          if (
            isRemovingAllCollateral &&
            !isCashGreatherThanUnderlyingCollateralBalance
          ) {
            await signTransactions(
              [
                removeCollateralAndWithdrawLiquidity({
                  tokenKey: market.underlying.symbol,
                  amountAsBigNumber: maxCash,
                  isUnderlyingAmount: true,
                }),
              ],
              {
                token: market.underlying.symbol,
                group: TRANSACTION_GROUP_TYPE.LENDING,
                subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
                result: inputValue,
              },
            );

            return;
          }

          if (
            isRemovingAllCollateral &&
            isCashGreatherThanUnderlyingCollateralBalance
          ) {
            await signTransactions(
              [
                removeCollateralAndWithdrawLiquidity({
                  tokenKey: market.underlying.symbol,
                  amountAsBigNumber: market.accountBalances.collateral,
                  isUnderlyingAmount: false,
                }),
              ],
              {
                token: market.underlying.symbol,
                group: TRANSACTION_GROUP_TYPE.LENDING,
                subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
                result: inputValue,
              },
            );

            return;
          }

          if (!isRemovingAllCollateral) {
            const isInputValueAsHTokenBigNumberGreatherThanHTokenAccountBalance =
              new DefiUtils(s_inputValueAsHTokenBigNumber).isGreaterThan(
                market.accountBalances.collateral,
              );

            const amountAsBigNumber =
              isInputValueAsHTokenBigNumberGreatherThanHTokenAccountBalance
                ? market.accountBalances.collateral
                : s_inputValueAsHTokenBigNumber;

            await signTransactions(
              [
                removeCollateralAndWithdrawLiquidity({
                  tokenKey: market.underlying.symbol,
                  amountAsBigNumber,
                  isUnderlyingAmount: false,
                }),
              ],
              {
                token: market.underlying.symbol,
                group: TRANSACTION_GROUP_TYPE.LENDING,
                subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
                result: inputValue,
              },
            );

            return;
          }

          return;
        }

        if (isWithdrawingAll && !isCashGreatherThanUnderlyingWalletBalance) {
          await signTransactions(
            [
              withdrawLiquidity({
                tokenKey: market.underlying.symbol,
                amountAsBigNumber: maxCash,
                isUnderlyingAmount: true,
              }),
            ],
            {
              token: market.underlying.symbol,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
              result: inputValue,
            },
          );

          return;
        }

        if (isWithdrawingAll && isCashGreatherThanUnderlyingWalletBalance) {
          await signTransactions(
            [
              withdrawLiquidity({
                tokenKey: market.underlying.symbol,
                amountAsBigNumber: market.accountBalances.hTokenWallet,
                isUnderlyingAmount: false,
              }),
            ],
            {
              token: market.underlying.symbol,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
              result: inputValue,
            },
          );

          return;
        }

        if (!isWithdrawingAll) {
          const isInputValueAsHTokenBigNumberGreatherThanHTokenAccountBalance =
            new DefiUtils(s_inputValueAsHTokenBigNumber).isGreaterThan(
              market.accountBalances.hTokenWallet,
            );

          const amountAsBigNumber =
            isInputValueAsHTokenBigNumberGreatherThanHTokenAccountBalance
              ? market.accountBalances.hTokenWallet
              : s_inputValueAsHTokenBigNumber;

          await signTransactions(
            [
              withdrawLiquidity({
                tokenKey: market.underlying.symbol,
                amountAsBigNumber,
                isUnderlyingAmount: false,
              }),
            ],
            {
              token: market.underlying.symbol,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.WITHDRAW,
              result: inputValue,
            },
          );

          return;
        }

        return;
      }

      case TABS.COLLATERAL: {
        if (isAddingCollateral) {
          const marketInteractedAvaiableToRemove = marketsInteracted.find(
            ({ canRemove }) => canRemove,
          );

          const hasMaxMarketPerAccountByController =
            marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
            !marketsInteracted
              .map(({ address }) => address)
              .includes(market.address) &&
            (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

          await signTransactions(
            [
              ...(hasMaxMarketPerAccountByController
                ? [
                    removeAccountMarket(
                      marketInteractedAvaiableToRemove?.address || '',
                    ),
                  ]
                : []),
              addCollateral({
                tokenKey: market.underlying.symbol,
                amountAsBigNumber: s_inputValueAsBigNumber,
                isMax: isAddingAllCollateral,
                maxHTokenBalance: market.accountBalances.hTokenWallet,
              }),
            ],
            {
              token: market.underlying.symbol,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.ADD_COLLATERAL,
              result: inputValue,
              isSecuencial: hasMaxMarketPerAccount,
            },
          );

          return;
        }

        if (isRemovingCollateral) {
          const amountAsHTokenBigNumber = isRemovingAllCollateral
            ? market.accountBalances.collateral
            : s_inputValueAsHTokenBigNumber;

          await signTransactions(
            [
              removeCollateral({
                tokenKey: market.underlying.symbol,
                amountAsHTokenBigNumber,
                isMax: isRemovingAllCollateral,
              }),
            ],
            {
              token: market.underlying.symbol,
              group: TRANSACTION_GROUP_TYPE.LENDING,
              subgroup: TRANSACTION_SUBGROUP_TYPE.REMOVE_COLLATERAL,
              result: inputValue,
            },
          );

          return;
        }

        return;
      }
    }
  };

  return {
    handleSubmitInteraction,
  };
};

export default useSupplySubmit;
