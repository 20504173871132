import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { Operation } from '@/sections/Liquid/App/Form/contexts/LiquidStakingFormContext';

export const StakeUnstakeMigrateRadioButton: React.FC<any> = ({
  defaultValue = Operation.STAKE,
  onChange,
}) => {
  const [selected, setSelected] = useState<Operation>(defaultValue);
  const { t } = useTranslation('liquid-app');

  useEffect(() => {
    onChange && onChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div
      className={classNames(
        'bg-[#F4F1FD] dark:bg-[#292651]',
        'border-2 border-[#DCDCE3] dark:border-[#2E2C51]',
        'mb-9 h-[60px] p-1.5 px-2.5',
        'grid grid-cols-3 gap-2.5 rounded-full',
        'theme-switch-transition',
      )}
    >
      {Object.values(Operation).map((e, i) => (
        <button
          type='button'
          data-testid={`tab:${e}`}
          key={i}
          onClick={() => setSelected(e)}
          className={classNames(
            'rounded-full capitalize text-[12px] md:text-[16px]',
            selected === e
              ? 'bg-white text-black dark:bg-[#676198] dark:text-white'
              : 'text-[#535367] dark:text-[#625E8F]',
            'theme-switch-transition',
          )}
        >
          {t(e)}
        </button>
      ))}
    </div>
  );
};
