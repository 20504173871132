import React from 'react';

export const CrossIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_7619_12589)'>
        <rect width='16' height='16' fill='none' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.5131 2.5999C16.099 2.01422 16.0992 1.06447 15.5135 0.478583C14.9278 -0.107303 13.9781 -0.107465 13.3922 0.478222L7.97633 5.89222L2.56048 0.478222C1.97459 -0.107465 1.02485 -0.107303 0.439159 0.478583C-0.146528 1.06447 -0.146366 2.01422 0.439521 2.5999L5.85465 8.01318L0.439521 13.4265C-0.146366 14.0121 -0.146528 14.9619 0.439159 15.5478C1.02485 16.1337 1.97459 16.1338 2.56048 15.5481L7.97633 10.1341L13.3922 15.5481C13.9781 16.1338 14.9278 16.1337 15.5135 15.5478C16.0992 14.9619 16.099 14.0121 15.5131 13.4265L10.098 8.01318L15.5131 2.5999Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_7619_12589'>
          <rect width='16' height='16' fill='none' />
        </clipPath>
      </defs>
    </svg>
  );
};
