import {
  BigUIntType,
  BigUIntValue,
  Field,
  FieldDefinition,
  List,
  Struct,
  StructType,
  TokenIdentifierType,
  TokenIdentifierValue,
  U64Type,
  U64Value,
} from '@multiversx/sdk-core/out';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import { H_MARKET_KEY, MARKET_KEY, protocolSelector } from '@/store/protocol';

export enum LIQUID_LOCKING_METHOD {
  LOCK = 'lock',
  UNBOND = 'unbond',
  UNLOCK = 'unlock',
}

const useLiquidLockingInteraction = () => {
  const { buildTransaction } = useSignMultipleTransactions();
  const {
    liquidLocking: { address: liquidLockingAddress },
    markets,
  } = useAppSelector(protocolSelector);

  const lock = ({
    amount,
    symbol,
  }: {
    amount: string;
    symbol: MARKET_KEY | H_MARKET_KEY;
  }) => {
    const items = Object.values(markets)
      .map((marketItem) => [marketItem.underlying, marketItem.hToken])
      .flat();
    const foundedToken = items.find(
      (marketItem) => marketItem.symbol === symbol,
    );

    if (!foundedToken) {
      throw new Error('Token not found');
    }

    return buildTransaction({
      smartContractAddress: liquidLockingAddress,
      func: LIQUID_LOCKING_METHOD.LOCK,
      group: 'locking',
      isPayable: true,
      token: {
        tokenIdentifier: foundedToken.id,
        amount: amount,
        numDecimals: foundedToken.decimals,
        isFromBigInteger: false,
      },
    });
  };

  const unlock = ({
    tokens,
  }: {
    tokens: {
      tokenIdentifier: string;
      tokenNonce: string;
      amount: string;
    }[];
  }) => {
    const structType = new StructType('unlock_item', [
      new FieldDefinition('token_identifier', '', new TokenIdentifierType()),
      new FieldDefinition('token_nonce', '', new U64Type()),
      new FieldDefinition('amount', '', new BigUIntType()),
    ]);

    return buildTransaction({
      smartContractAddress: liquidLockingAddress,
      func: LIQUID_LOCKING_METHOD.UNLOCK,
      group: 'locking',
      args: [
        new List(
          structType,
          tokens.map(
            ({ tokenIdentifier, tokenNonce, amount }) =>
              new Struct(structType, [
                new Field(
                  new TokenIdentifierValue(tokenIdentifier),
                  'token_identifier',
                ),
                new Field(new U64Value(tokenNonce), 'token_nonce'),
                new Field(new BigUIntValue(amount), 'amount'),
              ]),
          ),
        ),
      ],
    });
  };

  const unbond = ({ tokensIdentifier }: { tokensIdentifier: string[] }) => {
    return buildTransaction({
      smartContractAddress: liquidLockingAddress,
      func: LIQUID_LOCKING_METHOD.UNBOND,
      group: 'locking',
      args: [
        new List(
          new TokenIdentifierType(),
          tokensIdentifier.map(
            (tokenIdentifier) => new TokenIdentifierValue(tokenIdentifier),
          ),
        ),
      ],
    });
  };

  return {
    lock,
    unlock,
    unbond,
  };
};

export default useLiquidLockingInteraction;
