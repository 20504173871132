import React from 'react';

type Props = {};

const Plus = (props: Props) => {
  return (
    <svg
      width='8'
      height='9'
      viewBox='0 0 8 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='translate-x-[-0.3px]'
    >
      <path
        d='M1.10254 4.50342H6.89827'
        stroke='#3FD695'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.98633 1.60205V7.39778'
        stroke='#3FD695'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Plus;
