const FutureStatsRightArrow = ({ color = '#0E2D99', width, height,className }: { className?:string,color?: string, width?: string, height?: string }) => (
  <svg
  className={className}
    xmlns='http://www.w3.org/2000/svg'
    width={width ?? '12'}
    height={height ?? '8'}
    fill={color}
    viewBox='0 0 12 8'
  >
    <path d='M10.8 5H1.2c-.318 0-.623-.105-.849-.293A.924.924 0 010 4c0-.265.126-.52.351-.707C.577 3.105.881 3 1.2 3h9.6c.318 0 .623.105.848.293A.924.924 0 0112 4c0 .265-.126.52-.351.707-.225.188-.53.293-.849.293z'></path>
    <path d='M8 8a1 1 0 01-.71-1.71L9.59 4l-2.3-2.29A1.004 1.004 0 118.71.29l3 3a.998.998 0 010 1.42l-3 3A1 1 0 018 8z'></path>
  </svg>
);

export default FutureStatsRightArrow;
