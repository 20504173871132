import { domAnimation, LazyMotion, m } from 'framer-motion';

import clsxm from '@/services/clsxm';

const TransactionButton: React.FC<any> = ({
  text = '',
  disabled = false,
  secondary = false,
  className = '',
  textClassName = '',
  onClick,
  customTheme,
  ...props
}) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.button
        data-testid='submitTransaction'
        onTap={!disabled ? onClick : undefined}
        type='submit'
        disabled={disabled}
        className={clsxm(
          'btnHover',
          'h-[42px] w-full rounded-[8px] md:rounded-[5px] lg:rounded-[8px]',
          'text-sm font-semibold uppercase leading-tight',
          'flex items-center justify-center',
          customTheme
            ? customTheme(disabled)
            : disabled
              ? 'cursor-not-allowed bg-[#D3D6EE] text-[#6A6A8C] dark:bg-[#676198] dark:text-white'
              : [
                secondary
                  ? '[background:linear-gradient(270deg,_#1B4347_-1.39%,_#3D906A_99.46%)]'
                  : '[background-image:linear-gradient(90deg,#8474E3_0%,#49D2AE_100%)]',
                'text-white',
              ],
          className
        )}
        {...props}
      >
        <span className={textClassName} >{text}</span>
      </m.button>
    </LazyMotion>
  );
};

export default TransactionButton;
