import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import {
  QueryUndelegateTokens,
  ResponseUndelegateToken,
} from '@/services/indexer/liquid-staking-app/types';

export const getUndelegateTokens = async (
  ids: string[],
): Promise<ResponseUndelegateToken[]> => {
  if (ids.length === 0) {
    return [];
  }

  const query = gql`
    query LiquidStakingApp_QueryUndelegateTokens($ids: [String])
    @cacheControl(maxAge: 10) {
      queryUndelegateToken(filter: { id: { in: $ids } }) {
        id
        source
        hLsTokenAmount
      }
    }
  `;

  const variables = {
    ids,
  };

  const queryUndelegateTokensResponse =
    await client.request<QueryUndelegateTokens>(query, variables);

  return queryUndelegateTokensResponse.queryUndelegateToken || [];
};
