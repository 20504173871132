import DefiUtils from 'defi-utils';
import { useRef, useState } from 'react';

import { BORROW_TABS, TABS } from '@/components/popups/BorrowPopup/types/tab';

import { useAppSelector } from '@/store';
import { lendAppSelector } from '@/store/lend-app';
import { popupSelector } from '@/store/popup';
import { MARKET_KEY } from '@/store/protocol';

const useBorrowForm = ({}: {}) => {
  const { markets } = useAppSelector(lendAppSelector);
  const {
    data: { assetKey },
  } = useAppSelector(popupSelector);

  const inputRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.BORROW);
  const [maxSelected, setMaxSelected] = useState<boolean>(false);
  const [repayDailyInterestSelected, setRepayDailyInterestSelected] =
    useState<boolean>(false);
  const [submitButton, setSubmitButton] = useState({
    disabled: true,
    label: '',
  });

  const market = markets[assetKey as MARKET_KEY];

  const isBorrowTab = selectedTab === TABS.BORROW;
  const isRepayTab = selectedTab === TABS.REPAY_BORROW;

  const [inputValue, setInputValue] = useState<string>('');
  const [inputValueAsBigNumber, setInputValueAsBigNumber] =
    useState<string>('');

  const onTabToggle = (tabNumber: number) => {
    setSelectedTab(BORROW_TABS[tabNumber]);
  };

  const handleInputFocus = () => {
    setHasFocus(true);
    inputRef.current?.focus();
  };

  const handleInputBlur = () => {
    setHasFocus(false);
  };

  const handleInputValueChange = (value?: string) => {
    if (maxSelected) {
      return;
    }

    if (!value) {
      setInputValue('');
      setInputValueAsBigNumber('');
      return;
    }

    setInputValue(value);
    setInputValueAsBigNumber(
      new DefiUtils(value)
        .multipliedBy(`1e${market.underlying.decimals}`)
        .toString(),
    );
  };

  return {
    inputRef,
    inputValue,
    setInputValue,
    inputValueAsBigNumber,
    setInputValueAsBigNumber,
    hasFocus,
    setHasFocus,
    selectedTab,
    setSelectedTab,
    maxSelected,
    setMaxSelected,
    repayDailyInterestSelected,
    setRepayDailyInterestSelected,
    isBorrowTab,
    isRepayTab,
    handleInputFocus,
    handleInputBlur,
    onTabToggle,
    handleInputValueChange,
    submitButton,
    setSubmitButton,
  };
};

export type ReturnUseBorrowForm = ReturnType<typeof useBorrowForm>;

export default useBorrowForm;
