export enum TABS {
  ADD_COLLATERAL = 'supply',
  REMOVE_COLLATERAL = 'withdraw',
}

export const COLLATERAL_TABS = [TABS.ADD_COLLATERAL, TABS.REMOVE_COLLATERAL];

export const staticDataMap: Record<
  string,
  {
    collateralTokenUrl: string;
    lsTokenUrl: string;
    removeCollateralTokenTitle: string;
    removeCollateralTokenDescription: string;
    removeLsTokenTitle: string;
    removeLsTokenDescription: string;
  }
> = {
  ['EGLD']: {
    collateralTokenUrl: '/images/egld-token.png',
    lsTokenUrl: '/images/segld-token.png',
    removeCollateralTokenTitle: 'MultiversX',
    removeCollateralTokenDescription: 'Receive EGLD after 10 days',
    removeLsTokenTitle: 'Staked MultiversX',
    removeLsTokenDescription: 'Receive sEGLD instantly',
  },
  ['WTAO']: {
    collateralTokenUrl: '/images/wtao-token.png',
    lsTokenUrl: '/images/swtao-token.png',
    removeCollateralTokenTitle: 'Wrapped TAO',
    removeCollateralTokenDescription: 'Receive WTAO instantly',
    removeLsTokenTitle: 'Staked WTAO',
    removeLsTokenDescription: 'Receive sWTAO instantly',
  },
};
