import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';

import { ReturnUseBorrowData } from '@/components/popups/BorrowPopup/hooks/useBorrowData';
import { ReturnUseBorrowForm } from '@/components/popups/BorrowPopup/hooks/useBorrowForm';


const useBorrowListenerSubmitButton = ({
  borrowForm,
  borrowData,
}: {
  borrowForm: ReturnUseBorrowForm;
  borrowData: ReturnUseBorrowData;
}) => {
  const {
    isBorrowTab,
    isRepayTab,
    selectedTab,
    inputValue,
    setSubmitButton,
  } = borrowForm;

  const {
    hasFunds,
    isValidInput,
    isValidAmountRequest,
    market,
    isMaxBorrowCap,
    hasMaxMarketPerAccount,
    isMaxMarketReservesToBorrowGreatherThanRemainingTokenLimit,
    hasEnoughMarketReserves,
    hasEnoughCollateral,
    hasEnoughEGLDBalance,
  } = borrowData;

  const { t } = useTranslation();
  const { isLoggedIn } = useLogin();

  const getActionButtonLabel = (): string => {
    if (isRepayTab) {
      if (!isLoggedIn || !hasFunds) return t('no-balance-to-repay');
      if (!isValidInput && hasFunds) return t('repay-borrow');
      if (isValidInput && isValidAmountRequest) return t('repay-borrow');
      if (isValidInput && !isValidAmountRequest)
        return t('no-balance-to-repay');
      return t('repay-borrow');
    }

    if (isBorrowTab) {
      if (!isLoggedIn) return t('no-funds-available');

      if (!market.borrowEnabled)
        return t('borrow-not-enabled', 'Borrow not enabled');
      if (isValidInput && isMaxBorrowCap) return t('amount-exceeds-borrow-cap');
      if (isValidInput && hasMaxMarketPerAccount)
        return t('limit-money-market-reached');
      if (
        isMaxMarketReservesToBorrowGreatherThanRemainingTokenLimit &&
        !hasEnoughMarketReserves
      )
        return t('insufficient-market-liquidity');

      if (!hasEnoughCollateral) return t('insufficient-collateral');
      if (!hasEnoughMarketReserves) return t('insufficient-market-liquidity');
      if (isValidInput && !isValidAmountRequest)
        return t('insufficient-collateral');
      return t('borrow');
    }

    return t('unknown');
  };

  const getActionButtonDisabled = (): boolean => {
    return (
      !isLoggedIn ||
      !isValidInput ||
      !isValidAmountRequest ||
      isMaxBorrowCap ||
      !hasFunds ||
      !hasEnoughEGLDBalance ||
      (isBorrowTab && !hasEnoughMarketReserves) ||
      (isBorrowTab && !hasEnoughCollateral) ||
      (isBorrowTab && hasMaxMarketPerAccount) ||
      (isBorrowTab && !market.borrowEnabled)
    );
  };

  useEffect(() => {
    setSubmitButton({
      disabled: getActionButtonDisabled(),
      label: getActionButtonLabel(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, inputValue]);
};

export default useBorrowListenerSubmitButton;
