import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import CurrencyInput from 'react-currency-input-field';

import useMediaQuery from '@/hooks/useMediaQuery';

import CurrencyInputButton from '@/components/CurrencyInputButton';
import Hint from '@/components/Hint';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import FutureStatsRightArrow from '@/components/Icons/FutureStatsRightArrow';
import InfoIcon from '@/components/Icons/InfoIcon';
import LabeledImage from '@/components/LabeledImage';
import { NetAPYTooltipContent } from '@/components/Lending/NetAPYTooltipContent';
import { SEGLDLiquidStakingApyPercentage } from '@/components/Lending/SEGLDLiquidStakingApyPercentage';
import OperationFormBottomAlerts from '@/components/OperationFormBottomAlerts';
import RepayDustCheckbox from '@/components/popups/BorrowPopup/components/RepayDustCheckbox';
import useBorrowData from '@/components/popups/BorrowPopup/hooks/useBorrowData';
import useBorrowForm from '@/components/popups/BorrowPopup/hooks/useBorrowForm';
import useBorrowListenerFormFields from '@/components/popups/BorrowPopup/hooks/useBorrowListenerFormFields';
import useBorrowListenerSubmitButton from '@/components/popups/BorrowPopup/hooks/useBorrowListenerSubmitButton';
import useBorrowSubmit from '@/components/popups/BorrowPopup/hooks/useBorrowSubmit';
import {
  BORROW_TABS,
  getTabIndex,
} from '@/components/popups/BorrowPopup/types/tab';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import Tabber from '@/components/Tabber';
import TransactionButton from '@/components/TransactionButton';

import { ASSET_ALT_NAME, MARKET_KEY } from '@/store/protocol';

import { PrettyToken } from '@/sections/Liquid/Common/PrettyToken';
import {
  formatNumber,
  getBorrowLimitUsedPercent,
  toFormattedDecimalNum,
} from '@/utils/helpers';

const BorrowPopup = () => {
  const { t } = useTranslation();
  const md = useMediaQuery('(min-width: 768px)');

  const borrowForm = useBorrowForm({});
  const {} = borrowForm;

  const borrowData = useBorrowData({
    borrowForm,
  });

  const { handleSubmitInteraction } = useBorrowSubmit({
    borrowForm,
    borrowData,
  });

  useBorrowListenerFormFields({
    borrowForm,
    borrowData,
  });
  useBorrowListenerSubmitButton({
    borrowForm,
    borrowData,
  });

  return (
    <PopupBed close={false}>
      <form
        className='font-semibold leading-tight tracking-normal'
        onSubmit={(event) => {
          event.preventDefault();

          if (borrowForm.submitButton.disabled) {
            return;
          }

          handleSubmitInteraction();
        }}
      >
        <PopupHead>
          <LabeledImage
            name={
              ASSET_ALT_NAME[borrowData.market.underlying.symbol] ??
              borrowData.market.name
            }
            path={borrowData.market.logo.normal}
            night={borrowData.market.logo.nightMode}
          />
        </PopupHead>

        {/* Body */}

        <>
          <div className='relative mx-2.5 my-2'>
            <div>
              <div className='relative'>
                <input type='text' style={{ display: 'none' }} />
                <Hint
                  unvisible={!borrowForm.maxSelected}
                  autoReplace={!md}
                  content={t('maximum-amount-is-selected')}
                  placement='top-center'
                  block
                >
                  <CurrencyInput
                    ref={borrowForm.inputRef}
                    className={classNames(
                      'w-full max-w-[442px]',
                      'p-4 px-[70px]',
                      'text-center text-[28px] font-normal leading-tight placeholder:text-[#6A6A8C] disabled:text-[#6A6A8C] dark:text-indigo-100 placeholder:dark:text-[#6A6A8C] disabled:dark:text-[#6A6A8C]',
                      borrowForm.maxSelected && '!text-[#6A6A8C] !opacity-100',
                      'placeholder:text-current',
                      'outline-none',
                      'bg-transparent',
                    )}
                    placeholder={`0 ${
                      borrowForm.hasFocus
                        ? ''
                        : borrowData.market.underlying.symbol
                    }`}
                    onFocus={borrowForm.handleInputFocus}
                    onBlur={borrowForm.handleInputBlur}
                    suffix={
                      !borrowForm.hasFocus
                        ? ` ${borrowData.market.underlying.symbol}`
                        : ''
                    }
                    value={borrowForm.inputValue}
                    autoComplete='off'
                    aria-autocomplete='none'
                    decimalsLimit={borrowData.market.underlying.decimals}
                    groupSeparator=','
                    decimalSeparator='.'
                    allowNegativeValue={false}
                    autoFocus
                    onValueChange={borrowForm.handleInputValueChange}
                    inputMode='text'
                    data-testid='borrowInput'
                  />
                </Hint>
                <div className='absolute right-3 top-1/2 grid -translate-y-1/2 place-items-end'>
                  <Hint
                    autoReplace={!md}
                    content={
                      borrowForm.isBorrowTab
                        ? t('set-maximum-recommended-by-the-protocol')
                        : t('set-maximum')
                    }
                    placement={md ? 'top-center' : 'right-end'}
                  >
                    <CurrencyInputButton
                      onClick={() =>
                        borrowForm.setMaxSelected(!borrowForm.maxSelected)
                      }
                      selected={borrowForm.maxSelected}
                    >
                      {borrowData.showMaxButton ? (
                        <div>
                          80% <br />
                          {t('limit')}
                        </div>
                      ) : (
                        <span>{t('max')}</span>
                      )}
                    </CurrencyInputButton>
                  </Hint>
                </div>
              </div>
              <div className='mt-3 flex justify-between'>
                <div>
                  <div className='text-xs font-semibold dark:text-white'>
                    {t('estimated-price')}
                  </div>
                  <div className='flex items-center gap-1 text-xs text-[#6A6A8C] dark:text-[#625E8F]'>
                    <ApproEqualIcon className='mt-0.5' />
                    {`$${formatNumber(borrowData.inputPriceUSD)}`}
                  </div>
                </div>
                {!borrowForm.isBorrowTab && (
                  <Hint
                    autoReplace={!md}
                    content={t('include-daily-accrued-interest-repay')}
                    placement='bottom-center'
                  >
                    <RepayDustCheckbox
                      checked={borrowForm.maxSelected}
                      disabled={!borrowForm.maxSelected}
                      onChange={(checked) =>
                        borrowForm.setRepayDailyInterestSelected(checked)
                      }
                    />
                  </Hint>
                )}
              </div>
            </div>
          </div>
        </>

        <>
          <div>
            <Tabber
              onChange={borrowForm.onTabToggle}
              tabs={[...BORROW_TABS].map((key) => t(key.toLowerCase()))}
              defaultSelected={getTabIndex(borrowForm.selectedTab)}
              customTheme={(tabIdx) => {
                if (Number.isInteger(tabIdx))
                  return 'text-[#45A373] border-current';
                else
                  return 'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] hover:border-[#BEC2E6] dark:border-[#3F3B76]';
              }}
            >
              <div className='bg-[#FCFAFF] p-5 pb-3 dark:bg-[#292651]'>
                {/* Borrow Rate */}
                <>
                  <div className='pb-4'>
                    <div className='text-xs dark:text-indigo-100'>
                      {t('borrow-rates')}
                    </div>
                    <div className='mt-2 flex items-center justify-between px-1 dark:text-white'>
                      <div className='flex items-center gap-2'>
                        <div>
                          <img
                            className='h-[36px]'
                            src={borrowData.market.logo.normal}
                            alt=''
                          />
                        </div>
                        <div className='text-lg'>{t('borrow-apy')}</div>
                      </div>
                      <div>{`${new DefiUtils(borrowData.market.borrowAPY)
                        .multipliedBy('-1')
                        .toNumber()}%`}</div>
                    </div>
                  </div>
                </>

                {/* Rewards */}
                <div className='space-y-2 '>
                  {(borrowData.showLiquidStakingAPY ||
                    borrowData.showLiquidStakingTaoAPY) && (
                    <SEGLDLiquidStakingApyPercentage
                      token={
                        borrowData.showLiquidStakingAPY
                          ? MARKET_KEY.sEGLD
                          : MARKET_KEY.sWTAO
                      }
                      tooltipContent={
                        <div className='max-w-[394px] text-[10px] leading-tight'>
                          The{' '}
                          <span className='text-[#1679F9]'>
                            Liquid Staking APY
                          </span>{' '}
                          represents the value generated by{' '}
                          <PrettyToken
                            token={borrowData.market.underlying.symbol}
                          />{' '}
                          from staking EGLD with validators. You need to be
                          cautious when borrowing{' '}
                          <PrettyToken
                            token={borrowData.market.underlying.symbol}
                          />{' '}
                          as its value will constantly accrue over time
                          following the{' '}
                          <span className='text-[#1679F9]'>
                            Liquid Staking APY
                          </span>
                          .
                        </div>
                      }
                      apy={formatNumber(
                        new DefiUtils(
                          borrowData.showLiquidStakingAPY
                            ? borrowData.liquidStakingAPY
                            : borrowData.showLiquidStakingTaoAPY
                            ? borrowData.liquidStakingTaoAPY
                            : '0',
                        )
                          .multipliedBy('-1')
                          .toNumber(),
                        3,
                      )}
                    />
                  )}

                  {borrowData.rewardsBorrowToken.length > 0 ||
                  borrowData.showLiquidStakingAPY ||
                  borrowData.showLiquidStakingTaoAPY ? (
                    <div className='-mx-1 flex flex-col rounded-[8px] bg-[#E0F3E9] p-2 pt-1 text-sm dark:bg-[#2A6362] dark:text-white'>
                      <div className='pl-2'>
                        {borrowData.rewardsBorrowToken.map(
                          (rewardTokenItem) => (
                            <div
                              key={rewardTokenItem.batchId}
                              className='flex items-center gap-3.5 py-[3px]'
                            >
                              <div className='rounded-full border border-[#9097D9] dark:border-[#45A387]'>
                                <img
                                  src={rewardTokenItem.logo}
                                  alt={`Logo of ${rewardTokenItem.tokenId}`}
                                  className='h-5 w-5'
                                />
                              </div>
                              <span>{t('rewards-apy')}</span>
                              <span className='ml-auto'>
                                {formatNumber(rewardTokenItem.apy)}%
                              </span>
                            </div>
                          ),
                        )}
                      </div>
                      <div className='ml-11 flex h-7 items-center'>
                        <div className='flex items-center gap-1'>
                          {t('net-apy')}
                          <Hint
                            delayEnter={0}
                            placement='top-center'
                            content={
                              <NetAPYTooltipContent
                                parts={[
                                  ...(borrowData.showLiquidStakingAPY ||
                                  borrowData.showLiquidStakingTaoAPY
                                    ? ['Liquid Staking']
                                    : []),
                                  'Borrow',
                                  (borrowData.rewardsBorrowToken.length > 0 &&
                                    'Rewards') as any,
                                ]}
                              />
                            }
                          >
                            <InfoIcon width={12} />
                          </Hint>
                        </div>
                        <span className='grow'></span>
                        <span>
                          {formatNumber(
                            new DefiUtils(borrowData.totalBorrowAPY)
                              .multipliedBy('-1')
                              .toNumber(),
                            3,
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className='py-5'></div>
                  )}
                </div>

                {/* Borrow Limit */}
                <>
                  <div className='mt-3'>
                    <span className='text-xs dark:text-indigo-100'>
                      {t('borrow-limits')}
                    </span>
                    <div className='mt-2 flex items-center justify-between border-b-2 border-current py-2 pt-0 text-sm leading-tight text-[#6A6A8C] dark:border-[#625E8F]/50 dark:text-[#625E8F]'>
                      <div>{t('borrow-balance')}</div>
                      <div className='flex items-center justify-center gap-1'>
                        <div className='flex items-center gap-1'>
                          {`$${formatNumber(borrowData.borrowBalanceUSD, 2)}`}
                          {borrowData.isValidAmountRequest && (
                            <>
                              <FutureStatsRightArrow />
                              {`$${formatNumber(
                                borrowData.nextBorrowAmountUSD,
                                2,
                              )}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center justify-between py-2 text-sm leading-tight text-[#6A6A8C] dark:text-[#625E8F]'>
                      <div>{t('borrow-limit-used')}</div>
                      <div className='flex items-center gap-1'>
                        {`${formatNumber(
                          getBorrowLimitUsedPercent(
                            borrowData.borrowBalanceUSD,
                            borrowData.borrowLimitUSD,
                          ),
                          2,
                        )}%`}
                        {borrowData.isValidAmountRequest && (
                          <>
                            <FutureStatsRightArrow />
                            {`${formatNumber(
                              borrowData.nextBorrowLimitUsedPercentage as number,
                              2,
                            )}%`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/* Action Button */}
                <div className='mt-3'>
                  <Hint
                    content={
                      borrowData.hasPendingTransactions
                        ? t('translation:wait-tx-inprogress')
                        : t('translation:tx-not-allowed')
                    }
                    placement='top-center'
                    className='w-full'
                    unvisible={!borrowData.hasPendingTransactions}
                  >
                    <TransactionButton
                      text={borrowForm.submitButton.label}
                      disabled={borrowForm.submitButton.disabled}
                    />
                  </Hint>
                </div>

                {/* Bottom Note */}
                <div className='mt-3.5 space-y-1.5 text-sm text-[#6A6A8C] dark:text-[#625E8F]'>
                  {borrowData.footerNotes.map(({ label, value }) => {
                    return (
                      <div key={label} className='flex justify-between'>
                        <span>{label}</span>
                        <span>{`${toFormattedDecimalNum(
                          +(value as number),
                          value == 0
                            ? 2
                            : borrowData.market.underlying.decimals,
                        )} ${borrowData.market.underlying.symbol}`}</span>
                      </div>
                    );
                  })}

                  <OperationFormBottomAlerts />
                </div>
              </div>
            </Tabber>
          </div>
        </>
      </form>
    </PopupBed>
  );
};

export default BorrowPopup;
