/* eslint-disable react-hooks/exhaustive-deps */
import {
  AbiRegistry,
  Address,
  AddressType,
  AddressValue,
  BigUIntType,
  BigUIntValue,
  BooleanValue,
  Field,
  FieldDefinition,
  List,
  ListType,
  OptionValue,
  Struct,
  StructType,
  TokenIdentifierValue,
  TokenTransfer,
  U64Value,
} from '@multiversx/sdk-core';
import DefiUtils from 'defi-utils';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { accountSelector } from '@/store/auth';
import { useAppSelector } from '@/store/index';
import {
  H_TOKEN_DECIMALS,
  MARKET_KEY,
  nativeMarketSelector,
  protocolSelector,
} from '@/store/protocol';

import accountManagerTemplateABI from '@/abis/account-manager-template';

import { ROUTES } from '@/types/enums';

export enum MONEY_MARKET_METHOD {
  MINT = 'mint',
  REDEEM = 'redeem',
  BORROW = 'borrow',
  REPAY_BORROW = 'repayBorrow',
  MINT_AND_ENTER_MARKET = 'mintAndEnterMarket',
  EXIT_MARKET_ADN_REDEEM = 'exitMarketAndRedeem',
}

export enum CONTROLLER_METHOD {
  CLAIM_REWARDS = 'claimRewards',
  ENTER_MARKETS = 'enterMarkets', // Add Collateral
  EXIT_MARKET = 'exitMarket', // Remove Collateral
  REMOVE_ACCOUNT_MARKET = 'removeAccountMarket',
}

export const MARKET_ACTION_GAS_LIMIT = 200_000_000;

// minimum safe EGLD margin to consider transaction fees
export const MIN_EGLD_FEE = 5_000_000_000_000_000;

export const MAX_RECOMMENDED_BORROW_LIMIT_MARGIN = 0.8;
export const MAX_BORROW_LIMIT_MARGIN = 0.9999;

const useLendInteraction = () => {
  const {
    controller,
    markets,
    marketsInteractedAmount,
    accountManagerDeployer,
  } = useAppSelector(protocolSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const { proxyAddress, selectedTypeAddress } = useAppSelector(accountSelector);
  const router = useRouter();

  const isMainPool = useMemo(
    () =>
      router.route === ROUTES.LEND && selectedTypeAddress === 'proxy'
        ? false
        : true,
    [router.route, selectedTypeAddress],
  );

  const { buildTransaction } = useSignMultipleTransactions();

  const claimRewards = (boost: boolean, minBoostedRewardsOut?: string) => {
    return buildTransaction({
      smartContractAddress: isMainPool ? controller.address : proxyAddress,
      func: CONTROLLER_METHOD.CLAIM_REWARDS,
      group: 'market',
      args: [
        new BooleanValue(boost),
        new BooleanValue(true),
        new BooleanValue(true),
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new AddressType()), []),
        ...(!minBoostedRewardsOut ||
        new DefiUtils(minBoostedRewardsOut).isLessThanOrEqualTo(0)
          ? []
          : [new BigUIntValue(minBoostedRewardsOut)]),
      ],
    });
  };

  const supplyLiquidity = ({
    tokenKey,
    amountAsBigInteger,
  }: {
    tokenKey: string;
    amountAsBigInteger: string;
  }) => {
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;
    const { address: moneyMarketAddress, underlying } =
      markets[tokenKey as MARKET_KEY];

    const provider = isMainPool
      ? 'money-market'
      : proxyAddress
      ? 'account-manager-template'
      : 'account-manager-deployer';

    const commonArgs = {
      func: MONEY_MARKET_METHOD.MINT,
      group: 'market',
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: underlying.id,
              amount: amountAsBigInteger,
              numDecimals: underlying.decimals,
              isFromBigInteger: true,
            },
          }
        : {
            value: amountAsBigInteger,
            isFromBigInteger: true,
          }),
    };

    switch (provider) {
      case 'money-market': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: moneyMarketAddress,
        });
      }

      case 'account-manager-deployer': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: accountManagerDeployer.address,
          args: [new AddressValue(new Address(moneyMarketAddress))],
        });
      }

      case 'account-manager-template': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: proxyAddress,
          args: [new AddressValue(new Address(moneyMarketAddress))],
        });
      }

      default: {
        throw new Error('Invalid provider');
      }
    }
  };

  const supplyLiquidityAndAddCollateral = ({
    tokenKey,
    amountAsBigNumber,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
  }) => {
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;
    const { address: moneyMarketAddress, underlying } =
      markets[tokenKey as MARKET_KEY];

    const provider = isMainPool
      ? 'money-market'
      : proxyAddress
      ? 'account-manager-template'
      : 'account-manager-deployer';

    const commonArgs = {
      func: MONEY_MARKET_METHOD.MINT_AND_ENTER_MARKET,
      group: 'market',
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: underlying.id,
              amount: amountAsBigNumber,
              numDecimals: underlying.decimals,
              isFromBigInteger: true,
            },
          }
        : {
            value: amountAsBigNumber,
            isFromBigInteger: true,
          }),
    };

    switch (provider) {
      case 'money-market': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: moneyMarketAddress,
        });
      }

      case 'account-manager-template': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: proxyAddress,
          args: [new AddressValue(new Address(moneyMarketAddress))],
        });
      }

      case 'account-manager-deployer': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: accountManagerDeployer.address,
          args: [new AddressValue(new Address(moneyMarketAddress))],
        });
      }

      default: {
        throw new Error('Invalid provider');
      }
    }
  };

  const getAmountForAddCollateral = ({
    isMax,
    maxHTokenBalance,
    hTokenEquivalentAmount,
  }: {
    isMax?: boolean;
    maxHTokenBalance: string;
    hTokenEquivalentAmount: string;
  }) => {
    if (isMax) {
      return maxHTokenBalance;
    }

    if (hTokenEquivalentAmount === '0') {
      return '1';
    }

    if (
      new DefiUtils(hTokenEquivalentAmount).isGreaterThanOrEqualTo(
        maxHTokenBalance,
      )
    ) {
      return maxHTokenBalance;
    }

    return hTokenEquivalentAmount;
  };

  const addCollateral = ({
    tokenKey,
    amountAsBigNumber,
    isMax,
    maxHTokenBalance,
    isUnderlyingAmount = true,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
    isMax?: boolean;
    isUnderlyingAmount?: boolean;
    maxHTokenBalance: string;
  }) => {
    const abi = AbiRegistry.create(accountManagerTemplateABI);
    const paymentType = abi.getEndpoint(MONEY_MARKET_METHOD.REDEEM).input[0];

    const parameters = (paymentType.type as any)
      .fieldsDefinitions as FieldDefinition[];

    if (isUnderlyingAmount) {
      const { hTokenExchangeRate, hToken } = markets[tokenKey as MARKET_KEY];

      const hTokenEquivalentAmount = new DefiUtils(amountAsBigNumber)
        .toTokens(hTokenExchangeRate)
        .toFixed(0, DefiUtils.ROUND_HALF_UP);

      const tokenAmount = getAmountForAddCollateral({
        isMax,
        maxHTokenBalance,
        hTokenEquivalentAmount,
      });

      return buildTransaction({
        smartContractAddress: isMainPool ? controller.address : proxyAddress,
        func: CONTROLLER_METHOD.ENTER_MARKETS,
        group: 'market',
        ...(isMainPool
          ? {
              isPayable: true,
              token: {
                tokenIdentifier: hToken.id,
                amount: tokenAmount,
                numDecimals: H_TOKEN_DECIMALS,
                isFromBigInteger: true,
              },
            }
          : {
              args: [
                new List(paymentType.type, [
                  new Struct(paymentType.type as StructType, [
                    new Field(
                      new TokenIdentifierValue(hToken.id),
                      parameters[0].name,
                    ),
                    new Field(new U64Value(0), parameters[1].name),
                    new Field(
                      new BigUIntValue(tokenAmount),
                      parameters[2].name,
                    ),
                  ]),
                ]),
              ],
            }),
      });
    }

    const { hToken } = markets[tokenKey as MARKET_KEY];

    const tokenAmount = getAmountForAddCollateral({
      isMax,
      maxHTokenBalance,
      hTokenEquivalentAmount: amountAsBigNumber,
    });

    return buildTransaction({
      smartContractAddress: isMainPool ? controller.address : proxyAddress,
      func: CONTROLLER_METHOD.ENTER_MARKETS,
      group: 'market',
      ...(isMainPool
        ? {
            isPayable: true,
            token: {
              tokenIdentifier: hToken.id,
              amount: tokenAmount,
              numDecimals: H_TOKEN_DECIMALS,
              isFromBigInteger: true,
            },
          }
        : {
            args: [
              new List(paymentType.type, [
                new Struct(paymentType.type as StructType, [
                  new Field(
                    new TokenIdentifierValue(hToken.id),
                    parameters[0].name,
                  ),
                  new Field(new U64Value(0), parameters[1].name),
                  new Field(new BigUIntValue(tokenAmount), parameters[2].name),
                ]),
              ]),
            ],
          }),
    });
  };

  const removeCollateral = ({
    tokenKey,
    amountAsHTokenBigNumber,
    isMax,
  }: {
    tokenKey: string;
    amountAsHTokenBigNumber: string;
    isMax: boolean;
  }) => {
    const { address } = markets[tokenKey as MARKET_KEY];

    const moneyMarketExitAddress = new Address(address);

    return buildTransaction({
      smartContractAddress: isMainPool ? controller.address : proxyAddress,
      func: CONTROLLER_METHOD.EXIT_MARKET,
      args: [
        new AddressValue(moneyMarketExitAddress),
        ...(isMax
          ? []
          : [
              new BigUIntValue(
                amountAsHTokenBigNumber === '0' ? '1' : amountAsHTokenBigNumber,
              ),
            ]),
      ],
      group: 'market',
      isPayable: false,
      gasLimitArgs: [marketsInteractedAmount],
    });
  };

  const withdrawLiquidity = ({
    tokenKey,
    amountAsBigNumber,
    isUnderlyingAmount,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
    isUnderlyingAmount: boolean;
  }) => {
    const {
      hTokenExchangeRate,
      hToken,
      address: moneyMarketAddress,
    } = markets[tokenKey as MARKET_KEY];

    const abi = AbiRegistry.create(accountManagerTemplateABI);
    const paymentType = abi.getEndpoint(MONEY_MARKET_METHOD.REDEEM).input[0];

    const parameters = (paymentType.type as any)
      .fieldsDefinitions as FieldDefinition[];

    if (isUnderlyingAmount) {
      const hTokenAmount = new DefiUtils(amountAsBigNumber)
        .toTokens(hTokenExchangeRate)
        .toFixed(0, DefiUtils.ROUND_HALF_UP);

      return buildTransaction({
        smartContractAddress: isMainPool ? moneyMarketAddress : proxyAddress,
        func: MONEY_MARKET_METHOD.REDEEM,
        group: 'market',
        ...(isMainPool
          ? {
              args: [new BigUIntValue(amountAsBigNumber)],
              isPayable: true,
              token: {
                tokenIdentifier: hToken.id,
                amount: hTokenAmount,
                numDecimals: H_TOKEN_DECIMALS,
                isFromBigInteger: true,
              },
            }
          : {
              args: [
                new Struct(paymentType.type as StructType, [
                  new Field(
                    new TokenIdentifierValue(hToken.id),
                    parameters[0].name,
                  ),
                  new Field(new U64Value(0), parameters[1].name),
                  new Field(new BigUIntValue(hTokenAmount), parameters[2].name),
                ]),

                new OptionValue(
                  new BigUIntType(),
                  new BigUIntValue(amountAsBigNumber),
                ),

                new OptionValue(
                  new AddressType(),
                  new AddressValue(new Address(moneyMarketAddress)),
                ),
              ],
            }),
      });
    }

    return buildTransaction({
      smartContractAddress: isMainPool ? moneyMarketAddress : proxyAddress,
      func: MONEY_MARKET_METHOD.REDEEM,
      group: 'market',
      ...(isMainPool
        ? {
            isPayable: true,
            token: {
              tokenIdentifier: hToken.id,
              amount: amountAsBigNumber === '0' ? '1' : amountAsBigNumber,
              numDecimals: H_TOKEN_DECIMALS,
              isFromBigInteger: true,
            },
          }
        : {
            args: [
              new Struct(paymentType.type as StructType, [
                new Field(
                  new TokenIdentifierValue(hToken.id),
                  parameters[0].name,
                ),
                new Field(new U64Value(0), parameters[1].name),
                new Field(
                  new BigUIntValue(amountAsBigNumber),
                  parameters[2].name,
                ),
              ]),

              new OptionValue(new BigUIntType(), null),

              new OptionValue(
                new AddressType(),
                new AddressValue(new Address(moneyMarketAddress)),
              ),
            ],
          }),
    });
  };

  const removeCollateralAndWithdrawLiquidity = ({
    tokenKey,
    amountAsBigNumber,
    isUnderlyingAmount,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
    isUnderlyingAmount: boolean;
  }) => {
    const { hTokenExchangeRate, address: moneyMarketAddress } =
      markets[tokenKey as MARKET_KEY];

    if (isUnderlyingAmount) {
      const hTokenAmount = new DefiUtils(amountAsBigNumber)
        .toTokens(hTokenExchangeRate)
        .toFixed(0, DefiUtils.ROUND_HALF_UP);

      return buildTransaction({
        smartContractAddress: isMainPool ? controller.address : proxyAddress,
        func: MONEY_MARKET_METHOD.EXIT_MARKET_ADN_REDEEM,
        group: 'market',
        args: [
          new AddressValue(new Address(moneyMarketAddress)),
          new OptionValue(new BigUIntType(), new BigUIntValue(hTokenAmount)),
          new OptionValue(
            new BigUIntType(),
            new BigUIntValue(amountAsBigNumber),
          ),
        ],
      });
    }

    return buildTransaction({
      smartContractAddress: isMainPool ? controller.address : proxyAddress,
      func: MONEY_MARKET_METHOD.EXIT_MARKET_ADN_REDEEM,
      group: 'market',
      args: [
        new AddressValue(new Address(moneyMarketAddress)),
        new OptionValue(new BigUIntType(), new BigUIntValue(amountAsBigNumber)),
        new OptionValue(new BigUIntType(), null),
      ],
    });
  };

  const borrow = ({
    tokenKey,
    amountAsBigInteger,
  }: {
    tokenKey: string;
    amountAsBigInteger: string;
  }) => {
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;
    const { underlying, address } = markets[tokenKey as MARKET_KEY];

    const value = isEsdtToken
      ? TokenTransfer.fungibleFromBigInteger(
          underlying.id,
          amountAsBigInteger,
          underlying.decimals,
        ).valueOf()
      : TokenTransfer.egldFromBigInteger(amountAsBigInteger).valueOf();

    return buildTransaction(
      isMainPool
        ? {
            smartContractAddress: address,
            func: MONEY_MARKET_METHOD.BORROW,
            args: [new BigUIntValue(value)],
            group: 'market',
            gasLimitArgs: [marketsInteractedAmount],
            isPayable: false,
          }
        : {
            smartContractAddress: proxyAddress,
            func: MONEY_MARKET_METHOD.BORROW,
            args: [
              new AddressValue(new Address(address)),
              new BigUIntValue(value),
            ],
            group: 'market',
            gasLimitArgs: [marketsInteractedAmount],
            isPayable: false,
          },
    );
  };

  const repayBorrow = ({
    tokenKey,
    amountAsBigInteger,
  }: {
    tokenKey: string;
    amountAsBigInteger: string;
  }) => {
    const { underlying, address } = markets[tokenKey as MARKET_KEY];
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;

    return buildTransaction(
      isMainPool
        ? {
            smartContractAddress: address,
            func: MONEY_MARKET_METHOD.REPAY_BORROW,
            group: 'market',
            isPayable: true,
            ...(isEsdtToken
              ? {
                  token: {
                    tokenIdentifier: underlying.id,
                    amount: amountAsBigInteger,
                    numDecimals: underlying.decimals,
                    isFromBigInteger: true,
                  },
                }
              : {
                  value: amountAsBigInteger,
                  isFromBigInteger: true,
                }),
          }
        : {
            smartContractAddress: proxyAddress,
            func: MONEY_MARKET_METHOD.REPAY_BORROW,
            group: 'market',
            isPayable: true,
            args: [new AddressValue(new Address(address))],
            ...(isEsdtToken
              ? {
                  token: {
                    tokenIdentifier: underlying.id,
                    amount: amountAsBigInteger,
                    numDecimals: underlying.decimals,
                    isFromBigInteger: true,
                  },
                }
              : {
                  value: amountAsBigInteger,
                  isFromBigInteger: true,
                }),
          },
    );
  };

  const removeAccountMarket = (moneyMarketAddress: string) => {
    return buildTransaction({
      smartContractAddress: controller.address,
      func: CONTROLLER_METHOD.REMOVE_ACCOUNT_MARKET,
      group: 'market',
      args: [
        new AddressValue(new Address(moneyMarketAddress)),
        ...(isMainPool ? [new AddressValue(new Address(proxyAddress))] : []),
      ],
    });
  };

  return {
    supplyLiquidityAndAddCollateral,
    supplyLiquidity,
    addCollateral,
    removeCollateral,
    withdrawLiquidity,
    borrow,
    repayBorrow,
    claimRewards,
    removeAccountMarket,
    removeCollateralAndWithdrawLiquidity,
  };
};

export default useLendInteraction;
