import {
  AbiRegistry,
  Address,
  ResultsParser,
  SmartContract,
} from '@multiversx/sdk-core';
import { ContractQueryResponse } from '@multiversx/sdk-network-providers';
import DefiUtils from 'defi-utils';

import { MIN_EGLD_FEE } from '@/hooks/interaction/useLendInteraction';

export const wadBasicUnit = new DefiUtils(1e18);

export const padTwo = (value: number) =>
  String(value).length === 1 ? `0${value}` : value;

export const formatNumberDecimals = (
  value: number | string,
  decimals: number,
) => {
  const valueBigNumber = new DefiUtils(
    new DefiUtils(value).toFixed(decimals, DefiUtils.ROUND_DOWN),
  );

  const decimalPlaces = valueBigNumber.decimalPlaces();

  return valueBigNumber.toFixed(
    decimalPlaces > decimals ? decimals : decimalPlaces,
    DefiUtils.ROUND_DOWN,
  );
};

export const formatNumberDecimalsExtended = (
  value: number | string,
  decimals: number,
) => {
  const valueBigNumber = new DefiUtils(
    new DefiUtils(value).toFixed(decimals, DefiUtils.ROUND_DOWN),
  );

  const decimalPlaces = valueBigNumber.decimalPlaces();

  return valueBigNumber.toFixed(
    decimalPlaces > decimals ? decimals : decimalPlaces < 2 ? 2 : decimalPlaces,
    DefiUtils.ROUND_DOWN,
  );
};

export const sleep = (duration: number) =>
  new Promise((resolve: Function) => setTimeout(() => resolve(), duration));

export const getTypeFromData = ({
  type,
  value,
  abi,
}: {
  type: string;
  value: string;
  abi: AbiRegistry;
}) => {
  try {
    const contractAddress = new Address(
      'erd1qqqqqqqqqqqqqpgqhrln64a9kxysfr4an9t5r250xpp7qha9rkks57njmz',
    );
    const endpointName = `fake${type}`;

    const fakeEndpoint = {
      name: endpointName,
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type,
        },
      ],
    };

    const abiWithFakeEnpoint = {
      ...abi,
      endpoints: [fakeEndpoint],
    };

    const abiRegistry = AbiRegistry.create(abiWithFakeEnpoint);
    const parser = new ResultsParser();

    const contract = new SmartContract({
      address: contractAddress,
      abi: abiRegistry,
    });

    const queryResponse = new ContractQueryResponse({
      returnData: [value],
      returnCode: 'ok',
      returnMessage: '',
      gasUsed: 4601615,
    });

    const endpointDefinition = contract.getEndpoint(endpointName);
    const result = parser.parseQueryResponse(queryResponse, endpointDefinition);
    return result?.values[0];
  } catch (error) {
    return null;
  }
};

export const isRemaingValue = (value: string): boolean => value.includes('e-');

export const formatLowValue = (value: string): string => {
  if (!value.includes('e-')) return value;
  const [first, decimalPlaces] = value.split('e-');
  const amount = first.replace('.', '');
  return `0.${new Array(new DefiUtils(decimalPlaces).minus(1).toNumber())
    .fill('0')
    .join('')}${amount}`;
};

export const spreadAvailable = (preset = [25], totalCells = 4) => {
  const emptyCells = totalCells - preset.length;
  const availableSpace = 100 - preset.reduce((a, b) => a + b, 0);

  for (let i = 0; i < emptyCells; i++) {
    preset.push(availableSpace / emptyCells);
  }

  return preset;
};

export const splitTime = (
  numberOfHours: number,
): { days: number; hours: number; minutes: number } => {
  const days = Math.floor(numberOfHours / 24);
  const remainder = numberOfHours % 24;
  const hours = Math.floor(remainder);
  const minutes = Math.floor(60 * (remainder - hours));

  return { days, hours, minutes };
};

export const calcDuration = ({
  blockNonce,
  publishedBlock,
  votingDelayInBlocks,
  votingPeriodInBlocks,
}: {
  blockNonce: number;
  publishedBlock: number;
  votingDelayInBlocks: string;
  votingPeriodInBlocks: string;
}) => {
  const votingStart = new DefiUtils(publishedBlock).plus(votingDelayInBlocks);
  const votingEnd = votingStart.plus(votingPeriodInBlocks);
  const durationInHours = new DefiUtils(votingEnd)
    .minus(blockNonce)
    .multipliedBy(6)
    .dividedBy(3600)
    .toNumber();

  return timeLeft(splitTime(durationInHours));
};

export const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime());

export const timeLeft = ({
  days,
  hours,
  minutes,
}: {
  days: number;
  hours: number;
  minutes: number;
}): string => {
  const dayText = days <= 0 ? null : `${days} ${days === 1 ? 'day' : 'days'}`;
  const hourText =
    hours <= 0 ? null : `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  const minuteText = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;

  return `${[
    ...(dayText ? [dayText] : []),
    ...(hourText ? [hourText] : []),
    ...(!dayText ? [minuteText] : []),
  ].join(', ')} left`;
};

export const formatNumber = (
  number: number | string = 0,
  decimals: number = 2,
  roundingMode: DefiUtils.RoundingMode = DefiUtils.ROUND_DOWN,
): string => {
  const _decimals = new DefiUtils(decimals);

  if (_decimals.isZero()) {
    const result = new DefiUtils(number)
      .toFixed(2, roundingMode)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return result.slice(0, result.length - 3);
  }

  return new DefiUtils(number)
    .toFixed(_decimals.toNumber(), roundingMode)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const nFormatter = (
  value: DefiUtils.Value,
  decimals: number = 2,
  roundingMode: DefiUtils.RoundingMode = DefiUtils.ROUND_DOWN,
) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Q' },
    { value: 1e18, symbol: 'Z' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return new DefiUtils(value).isGreaterThanOrEqualTo(item.value);
    });

  return item
    ? new DefiUtils(new DefiUtils(value).dividedBy(item.value))
        .toSafeFixed(decimals, roundingMode)
        .replace(rx, '$1') + item.symbol
    : new DefiUtils(value).toSafeFixed(decimals, roundingMode);
};

export const mapValueInRange = (
  val: number | any,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number,
) => ((val - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;

export const proportionalWidth = (px: number, digit: any) => {
  const value = (px * 100) / 1512;
  if (digit) {
    return value;
  }
  return `${value}vw`;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(string: string) {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

export const getBorrowLimitUsedPercent = (
  borrowedBalance: DefiUtils.Value,
  borrowLimit: DefiUtils.Value,
) => {
  if (new DefiUtils(borrowLimit).isLessThanOrEqualTo(0)) {
    return 0;
  }

  return new DefiUtils(borrowedBalance)
    .dividedBy(borrowLimit)
    .multipliedBy(100)
    .toNumber();
};

export const uint8ArrToHex = (uint8Array: Uint8Array): string => {
  return Buffer.from(uint8Array).toString('hex');
};

const SAFE_MINIMUM_FACTOR = '400000000000000000';

export const getTokenBorrowLimit = (
  tokenAmount: string = '0',
  collateralFactor: string = SAFE_MINIMUM_FACTOR,
): string => {
  const decimalFactor = new DefiUtils(collateralFactor).dividedBy(wadBasicUnit);
  // as this is a multiplication by a coeficient (value between 0 and 1), we need a rounded big int without decimals
  return new DefiUtils(tokenAmount).multipliedBy(decimalFactor).toFixed();
};

export const toFormattedDecimalNum = (
  num: number | string | DefiUtils,
  showDecimals?: number,
): string => {
  const showNum = new DefiUtils(num).toFormat(
    showDecimals || new DefiUtils(num).decimalPlaces() || 0,
    // this helps to truncate the values
    DefiUtils.ROUND_DOWN,
    {
      // string to prepend
      prefix: '',
      // decimal separator
      decimalSeparator: '.',
      // grouping separator of the integer part
      groupSeparator: ',',
      // primary grouping size of the integer part
      groupSize: 3,
      // secondary grouping size of the integer part
      secondaryGroupSize: 0,
      // grouping separator of the fraction part
      fractionGroupSeparator: ' ',
      // grouping size of the fraction part
      fractionGroupSize: 0,
      // string to append
      suffix: '',
    },
  );
  return showNum.toString();
};

/** Balance available in EGLD minus the gas fee */
export const subtractGasFee = (balance: string): string => {
  const maxAmount = new DefiUtils(balance).minus(new DefiUtils(MIN_EGLD_FEE));
  return maxAmount.isGreaterThan('0') ? maxAmount.toString() : '0';
};

export function clamp(input: number, min: number, max: number): number {
  return input < min ? min : input > max ? max : input;
}

export function mapNumbersRange(
  current: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number,
): number {
  const mapped: number =
    ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  return clamp(mapped, out_min, out_max);
}

export const JSONSafeParse = (value: string, defaultValue: any) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return defaultValue;
  }
};

export const slugify = (text: string) => {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
};

/**
 * @param color Hex value format: #ffffff or ffffff
 * @param decimal lighten or darken decimal value, example 0.5 to lighten by 50% or 1.5 to darken by 50%.
 */
export function shadeColor(color: string, decimal: number): string {
  const base = color.startsWith('#') ? 1 : 0;

  let r = parseInt(color.substring(base, 3), 16);
  let g = parseInt(color.substring(base + 2, 5), 16);
  let b = parseInt(color.substring(base + 4, 7), 16);

  r = Math.round(r / decimal);
  g = Math.round(g / decimal);
  b = Math.round(b / decimal);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const rr =
    r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16);
  const gg =
    g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16);
  const bb =
    b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16);

  return `#${rr}${gg}${bb}`;
}

export function addAlpha(color: string, opacity: number) {
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const formatChangeRate = (value: string) => {
  const maxDecimalPlaces = 2;
  const valueBigNumberWithMaxDecimals = new DefiUtils(
    new DefiUtils(value).toFixed(maxDecimalPlaces, DefiUtils.ROUND_DOWN),
  );
  const decimalPlaces = valueBigNumberWithMaxDecimals.decimalPlaces();

  if (valueBigNumberWithMaxDecimals.isEqualTo('0')) {
    const valueBigNumber = new DefiUtils(value);

    if (valueBigNumber.isEqualTo('0')) {
      return '';
    }

    if (valueBigNumber.isGreaterThan('0')) {
      return `+0.00%`;
    }

    return '-0.00%';
  }

  if (valueBigNumberWithMaxDecimals.isGreaterThan('0')) {
    return `+${valueBigNumberWithMaxDecimals.toFixed(
      decimalPlaces > maxDecimalPlaces ? maxDecimalPlaces : decimalPlaces,
      DefiUtils.ROUND_DOWN,
    )}%`;
  }

  return `${valueBigNumberWithMaxDecimals.toFixed(
    decimalPlaces > maxDecimalPlaces ? maxDecimalPlaces : decimalPlaces,
    DefiUtils.ROUND_DOWN,
  )}%`;
};

export const formatNumberWithK = (number: any) => {
  if (number >= 1000) {
    return `${(number / 1000).toFixed(2)}k`;
  }
  return formatNumber(number);
};

export const NumberWithK = (number: any) => {
  if (number >= 1000) {
    return `${(number / 1000).toFixed(2)}k`;
  }
  return number;
};
