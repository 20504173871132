import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { Trans } from 'next-i18next';
import { useMemo, useState } from 'react';

import useMediaQuery from '@/hooks/useMediaQuery';
import useTheme from '@/hooks/useTheme';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';
import InfoIcon2 from '@/components/Icons/InfoIcon2';
import ArrowLeft from '@/components/popups/AirdropAnalyticsPopup/components/Icons/ArrowLeft';
import ArrowRight from '@/components/popups/AirdropAnalyticsPopup/components/Icons/ArrowRight';
import { fakeData2 } from '@/components/popups/AirdropAnalyticsPopup/data/index';
import useTableData from '@/components/popups/AirdropAnalyticsPopup/data/TableData';
import PopupBed from '@/components/popups/PopupBed';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';

import { chainType } from '@/config/network';
import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

const AirdropAnalyticsPopup = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const lg = useMediaQuery('(min-width: 1024px)');
  const md = useMediaQuery('(min-width: 768px)');

  const showData = chainType === 'mainnet';
  const { isDark } = useTheme();
  const tableHeaderData = useTableData();
  const { accountSnapshots } = useAppSelector(boosterSelector);

  const userAirdrop = useMemo(() => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);

    return accountSnapshots
      .filter(
        (airdropItem) =>
          new Date(airdropItem.snapshot.date).getTime() < currentDate.getTime(),
      )
      .map((accountSnapshotItem, accountSnapshotItemIndex, array) => {
        const airdropTimestamp = new Date(
          accountSnapshotItem.snapshot.date,
        ).toISOString();
        const [timestampYear, timestampMonth, timestampDay] = airdropTimestamp
          .split('T')[0]
          .split('-');
        const [timestampHours, timestampMinutes] = airdropTimestamp
          .split('T')[1]
          .split(':');

        const isLowerPoolShare =
          new DefiUtils(accountSnapshotItem.poolShare).isLessThan(0.01) &&
          !new DefiUtils(accountSnapshotItem.poolShare).isZero();

        return {
          id: array.length - accountSnapshotItemIndex,
          percentageBoost: accountSnapshotItem.percentageBoost,
          date: `${timestampDay}/${timestampMonth}/${timestampYear} at ${timestampHours}:${timestampMinutes}`,
          total: `$${formatNumber(accountSnapshotItem.totalCollateralUSD, 0)}`,
          totalUSD: `$${formatNumber(accountSnapshotItem.totalStakedUSD, 0)}`,
          boosterEnabledDate: 'To be determined',
          isBoosterEnabled: accountSnapshotItem.hasUshReward,
          collateralPercentage: accountSnapshotItem.collateralPercentage,
          poolShare: accountSnapshotItem.poolShare,
          isLowerPoolShare,
        };
      });
  }, [accountSnapshots]);

  const currentData = showData ? userAirdrop : fakeData2;

  const itemsPerPage = md ? 6 : 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currentData.slice(indexOfFirstItem, indexOfLastItem);
  const lastPage = Math.ceil(currentData.length / itemsPerPage);

  const paginationItems = useMemo(() => {
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(lastPage, currentPage + 1);

    const paginations = [];

    for (let i = start; i <= end; i++) {
      paginations.push(i);
    }

    return paginations;
  }, [lastPage, currentPage]);

  return (
    <PopupBed
      close={false}
      className={clsxm(!md && '!max-w-[370px] h-[100vh] rounded-none')}
      enableScaling={!lg && md ? true : false}
      width={!md ? '100vw' : 'auto'}
      scaleClassName={clsxm(!lg && md && '!scale-[80%]')}
    >
      <div className={clsxm('relative h-fit')}>
        <div
          className={classNames(
            'text-[#3C3A40] dark:text-white',
            'lg:h-fit lg:p-[16px] lg:pt-[16px] ',
            'h-[43.45px] px-[12.41px] py-[12.23px]',
          )}
        >
          <div
            className={clsxm(
              'relative flex h-full w-full  items-center ',
              'lg:h-[24px] lg:max-h-[24px] lg:px-[121.5px]',
              'px-[81px] justify-center',
            )}
          >
            <div className='flex gap-2'>
              <div className='flex-xy-center w-6 h-6 translate-y-[3px] rounded-full bg-[#E0E0EB]'>
                <img src='https://cdn.app.hatom.com/images/airdrop-gray.svg' alt='airdrop-gray' />
              </div>
              <p
                className={clsxm([
                  'inline-block font-hass font-[600] ',
                  'lg:pt-[2px] lg:text-[18px] md:leading-[25px] ',
                  ' -mb-[0.25px] pt-[0.2px] text-[14px] leading-[18.62px]',
                  !lg && 'translate-y-0.5',
                ])}
              >
                Airdrop Analytics
              </p>
            </div>
            <button autoFocus={true}>
              <ClosePopupBtn
                className={clsxm([
                  'absolute right-0 top-1/2 z-10  m-0 flex -translate-y-1/2 items-center  justify-center ',
                  'lg:mt-[0.7px] lg:w-[18px] lg:px-[1.6px] lg:pb-[1.05px] lg:pt-[2.05px]',
                  // 'w-[14px] h-[14px] pl-[3px] pb-[2.9px] pt-[2px] pr-[2.8px]  mt-[1.25px]',
                  'mt-[0.8px] h-[13.97px] w-[13.97px] px-[2.8px] py-[2.7px]',
                ])}
                iconClassName={clsxm(['w-[11px]'])}
                theme={{ light: '#1E1E1E', dark: 'white' }}
              />
            </button>
          </div>
        </div>
      </div>

      <div
        className={clsxm(
          'relative overflow-x-none sm:rounded-lg md:min-w-[867px] px-4 pt-[17px]  pb-4',
          !md && 'h-[115vh]',
        )}
      >
        {md ? (
          <table className='w-full text-sm text-left border-spacing-y-[11.5px] border-separate rounde-[8px]'>
            <thead className='text-[10px] lg:text-xs text-[#535367] dark:text-[#837FB4]'>
              <tr className='bg-[#F9F9FE] rounded-[8px] h-8 lg:h-10 font-semibold dark:bg-[#27234E]'>
                {tableHeaderData.map((header, index) => (
                  <td
                    key={index}
                    scope='col'
                    className={clsxm(
                      'py-2 lg:py-3',
                      header.style,
                      index === 0 && 'rounded-l-[8px]',
                      index === tableHeaderData.length - 1 && 'rounded-r-[8px]',
                    )}
                  >
                    <span className='flex items-center gap-1 whitespace-nowrap'>
                      {header.label}{' '}
                      <Hint
                        placement={
                          index == 6 && !lg ? 'bottom-end' : 'bottom-center'
                        }
                        containerClassName={clsxm(
                          'translate-y-0 md:translate-y-4 lg:translate-y-0',
                          index == 6 && 'md:translate-x-10 lg:translate-x-0',
                        )}
                        content={
                          <div className={clsxm(header.tooltipStyle)}>
                            <Trans
                              i18nKey={header.tooltip}
                              components={header.components}
                              ns='translation'
                            />
                          </div>
                        }
                      >
                        <InfoIcon2
                          className='w-2 translate-y-px'
                          fill={isDark ? '#837FB4' : '#535367'}
                        />
                      </Hint>
                    </span>
                  </td>
                ))}
              </tr>
            </thead>
            <tbody className=''>
              {currentItems.map((data, index) => (
                <tr
                  key={index}
                  className='bg-[#F2F2FD] !rounded-[8px] h-8 lg:h-10 font-semibold  text-[#3C3A40] text-[10px] lg:text-xs whitespace-nowrap dark:text-white dark:bg-[#302C60]'
                >
                  <td
                    scope='row'
                    className={clsxm('px-3 py-2 lg:py-3 rounded-l-[8px]')}
                  >
                    #{data.id}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[8px] py-2 lg:py-3',
                      !showData && 'pl-2',
                      currentData == fakeData2 && 'pl-8',
                    )}
                  >
                    {data.date}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[38px] py-2 lg:py-3',
                      !showData && 'pl-[37px]',
                      currentData == fakeData2 && 'pl-20',
                    )}
                  >
                    {data.total}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[20px] py-2 lg:py-3',
                      !showData && 'pl-[18px]',
                      currentData == fakeData2 && 'pl-16',
                    )}
                  >
                    {data.totalUSD}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[20px] py-2 lg:py-3',
                      !showData && 'pl-[20px]',
                      currentData == fakeData2 && 'pl-7',
                    )}
                  >
                    {showData ? (
                      <div
                        className={clsxm(
                          'flex-xy-center rounded-[12px] text-[10px] lg:text-xs !inline px-[6px]',
                          data.isBoosterEnabled
                            ? 'bg-[#D6EEE3] text-[#40B683] dark:bg-[#2F5657] dark:text-[#37CE86]'
                            : 'bg-[#FFDEDE] text-[#E24949] dark:bg-[#6E2F2F] dark:text-[#E04040]',
                        )}
                      >
                        <span>{formatNumber(data.percentageBoost)}%</span>
                      </div>
                    ) : (
                      <div
                        className={clsxm(
                          'flex-xy-center rounded-[12px] text-[10px] lg:text-xs !inline px-[6px]',
                        )}
                      >
                        <span>{data.percentageBoost}</span>
                      </div>
                    )}
                  </td>
                  <td
                    className={clsxm(
                      'py-2 lg:py-3 text-center pl-[18px]',
                      !showData && 'pl-[32px]',
                      currentData == fakeData2 && 'pl-10',
                    )}
                  >
                    {showData ? (
                      <div
                        className={clsxm(
                          'flex-xy-center rounded-[12px] text-[10px] lg:text-xs !inline px-[6px]',
                          new DefiUtils(
                            data.collateralPercentage,
                          ).isGreaterThanOrEqualTo(20)
                            ? 'bg-[#D6EEE3] text-[#40B683] dark:bg-[#2F5657] dark:text-[#37CE86]'
                            : 'bg-[#FFDEDE] text-[#E24949] dark:bg-[#6E2F2F] dark:text-[#E04040]',
                        )}
                      >
                        <span>{formatNumber(data.collateralPercentage)}%</span>
                      </div>
                    ) : (
                      <div
                        className={clsxm(
                          'flex-xy-center rounded-[12px] text-[10px] lg:text-xs !inline px-[6px]',
                        )}
                      >
                        <span>{data.collateralPercentage}</span>
                      </div>
                    )}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[20px] py-2 lg:py-3 flex items-center justify-center',
                    )}
                  >
                    {showData ? (
                      <img
                        src={
                          data.isBoosterEnabled
                            ? 'https://cdn.app.hatom.com/images/true-icon.svg'
                            : 'https://cdn.app.hatom.com/images/false-icon.svg'
                        }
                        alt='icon'
                      />
                    ) : (
                      <span>{data.isBoosterEnabled}</span>
                    )}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[20px] pr-2 py-2 lg:py-3 dark:text-[#B8B5DE] text-center',
                      !showData && 'pl-11',
                    )}
                  >
                    {showData ? (
                      <Hint
                        autoReplace
                        backgroundColor='#030303'
                        content={`${formatNumber(
                          data.poolShare,
                          new DefiUtils(data.poolShare).decimalPlaces() || 2,
                        )}%`}
                        placement='bottom-center'
                      >
                        {data.isLowerPoolShare && '<'}
                        {formatNumber(
                          data.isLowerPoolShare ? 0.01 : data.poolShare,
                        )}
                        %
                      </Hint>
                    ) : (
                      <span>{data.isBoosterEnabled}</span>
                    )}
                  </td>
                  <td
                    className={clsxm(
                      'pl-[25px] pr-2 py-2 lg:py-3 rounded-r-[8px] dark:text-[#B8B5DE]',
                      !showData && 'pl-6',
                      currentData == fakeData2 && 'pl-16',
                    )}
                  >
                    {data.boosterEnabledDate}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='flex flex-col gap-2'>
            {currentItems.map((data, index) => (
              <div
                key={index}
                className={clsxm(
                  'flex flex-col gap-2 rounded-[8px] bg-[#F9F9FE] dark:bg-[#27234E] p-4 w-full',
                )}
              >
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Snapshot{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[0].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-snapshot'
                            components={tableHeaderData[0].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>

                  <span
                    className={clsxm('dark:text-white', showData ? '' : 'w-4')}
                  >
                    {' '}
                    #{data.id}
                  </span>
                </div>

                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Date & Time (UTC){' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[1].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-date'
                            components={tableHeaderData[1].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span className='dark:text-white'>{data.date}</span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Collateral Value{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[2].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-collateral-value'
                            components={tableHeaderData[2].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span className='dark:text-white'>{data.total}</span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Staked HTM Value{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[3].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-htm-staked'
                            components={tableHeaderData[3].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span className='dark:text-white'>{data.totalUSD}</span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Boost %{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[4].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-boost'
                            components={tableHeaderData[4].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span>
                    {showData ? (
                      <div
                        className={clsxm(
                          'flex-xy-center',
                          'rounded-[12px]',
                          'px-[6px]',
                          'text-center',
                          'text-[10px]',
                          'lg:text-xs',
                          data.isBoosterEnabled
                            ? 'bg-[#D6EEE3] text-[#40B683] dark:bg-[#2F5657] dark:text-[#37CE86]'
                            : 'bg-[#FFDEDE] text-[#E24949] dark:bg-[#6E2F2F] dark:text-[#E04040]',
                        )}
                      >
                        <span>{formatNumber(data.percentageBoost)}%</span>
                      </div>
                    ) : (
                      <span className='dark:text-white'>
                        {data.percentageBoost}
                      </span>
                    )}
                  </span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Collateral %{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[5].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-collateral-percentage'
                            components={tableHeaderData[5].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span>
                    {showData ? (
                      <div
                        className={clsxm(
                          'flex-xy-center',
                          'rounded-[12px]',
                          'px-[6px]',
                          'text-center',
                          'text-[10px]',
                          'lg:text-xs',
                          new DefiUtils(
                            data.collateralPercentage,
                          ).isGreaterThanOrEqualTo(20)
                            ? 'bg-[#D6EEE3] text-[#40B683] dark:bg-[#2F5657] dark:text-[#37CE86]'
                            : 'bg-[#FFDEDE] text-[#E24949] dark:bg-[#6E2F2F] dark:text-[#E04040]',
                        )}
                      >
                        <span>{formatNumber(data.collateralPercentage)}%</span>
                      </div>
                    ) : (
                      <span className='dark:text-white'>
                        {data.collateralPercentage}
                      </span>
                    )}
                  </span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Eligibility{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[6].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-elegibility'
                            components={tableHeaderData[6].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span>
                    {showData ? (
                      <img
                        src={
                          data.isBoosterEnabled
                            ? 'https://cdn.app.hatom.com/images/true-icon.svg'
                            : 'https://cdn.app.hatom.com/images/false-icon.svg'
                        }
                        alt='icon'
                      />
                    ) : (
                      <span className='dark:text-white'>
                        {data.isBoosterEnabled}
                      </span>
                    )}
                  </span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Pool Share %{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[7].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-pool-share'
                            components={tableHeaderData[7].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span className='dark:text-white'>
                    {showData ? (
                      <span>
                        <Hint
                          autoReplace
                          backgroundColor='#030303'
                          content={`${formatNumber(
                            data.poolShare,
                            new DefiUtils(data.poolShare).decimalPlaces() || 2,
                          )}%`}
                          placement='bottom-center'
                        >
                          {data.isLowerPoolShare && '<'}
                          {formatNumber(
                            data.isLowerPoolShare ? 0.01 : data.poolShare,
                          )}
                          %
                        </Hint>
                      </span>
                    ) : (
                      <span className='dark:text-white'>
                        {data.isBoosterEnabled}
                      </span>
                    )}
                  </span>
                </div>
                <div className='text-[#535367] dark:text-[#837FB4] text-[10px] font-semibold leading-tight flex justify-between'>
                  <span className='flex gap-1 items-center'>
                    Amount Earned{' '}
                    <Hint
                      autoReplace
                      content={
                        <div className={clsxm(tableHeaderData[8].tooltipStyle)}>
                          <Trans
                            i18nKey='tooltip-airdrop-amount-earned'
                            components={tableHeaderData[8].components}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      <InfoIcon className='w-2' />
                    </Hint>
                  </span>
                  <span className='dark:text-white'>
                    {' '}
                    {data.boosterEnabledDate}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className='w-full flex-xy-center mt-3 pb-3 md:pb-0'>
          <button
            className='flex  items-center gap-1 mr-[11px]'
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ArrowLeft
              className='translate-y-[0.5px]'
              color={currentPage === 1 ? '#87879E' : '#535367'}
            />
            <span
              className={clsxm(
                'text-[10px] text-[#535367] font-semibold',
                currentPage === 1 && 'text-[#87879E]',
              )}
            >
              Prev
            </span>
          </button>

          <div className='flex items-center gap-2'>
            {!paginationItems.includes(1) && '...'}

            {paginationItems.map((number) => (
              <div
                onClick={() => setCurrentPage(number)}
                key={number}
                className={clsxm(
                  'flex-xy-center w-5 h-5 rounded border border-[#F2F2FD] dark:border-[#302C60] dark:bg-[transparent] cursor-pointer',
                  currentPage === number && 'bg-[#F2F2FD] dark:bg-[#302C60]',
                )}
              >
                <span
                  className={clsxm(
                    'text-[#87879E] text-[10px] font-semibold',
                    currentPage === number && 'text-[#3C3A40] dark:text-white',
                  )}
                >
                  {number}
                </span>
              </div>
            ))}

            {!paginationItems.includes(lastPage) && '...'}
          </div>

          <button
            className='flex  items-center gap-1 ml-[14px]'
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === lastPage}
          >
            <span
              className={clsxm(
                'text-[10px] text-[#535367] font-semibold',
                currentPage === lastPage && 'text-[#87879E]',
              )}
            >
              Next
            </span>
            <ArrowRight
              className='translate-y-px'
              color={currentPage === lastPage ? '#87879E' : '#535367'}
            />
          </button>
        </div>
      </div>
    </PopupBed>
  );
};

export default AirdropAnalyticsPopup;
