import dynamic from 'next/dynamic';
import { createContext, FC, useEffect } from 'react';

import { LiquidTaoPendingTransactionPopup } from '@/components/popups';
import HTMStakePopup from '@/components/popups/HTMStakePopup';
import UshCollateralPopup from '@/components/popups/UshCollateralPopup';

import { useAppSelector } from '@/store';
import { popupSelector } from '@/store/popup';

interface PopupProviderProps {
  children: Array<JSX.Element> | JSX.Element;
}

const HTMBoosterPopup1 = dynamic(
  () => import('@/components/popups/HTMBoosterPopup1'),
  { ssr: false },
);
const MaiarLoginPopup = dynamic(
  () => import('@/components/popups/MaiarLoginPopup'),
  { ssr: false },
);
const AccountDetailsPopup = dynamic(
  () => import('@/components/popups/AccountDetailsPopup'),
  { ssr: false },
);
const BorrowPopup = dynamic(() => import('@/components/popups/BorrowPopup'), {
  ssr: false,
});
const ConnectWalletPopup = dynamic(
  () => import('@/components/popups/ConnectWalletPopup'),
  { ssr: false },
);
const CurrentPositionPopup = dynamic(
  () => import('@/components/popups/CurrentPositionPopup'),
  { ssr: false },
);
const CurrentUshPositionPopup = dynamic(
  () => import('@/components/popups/CurrentUshPositionPopup'),
  { ssr: false },
);
const HatomValidators = dynamic(
  () => import('@/components/popups/HatomValidators'),
  { ssr: false },
);
const LedgerAccountsPopup = dynamic(
  () => import('@/components/popups/LedgerAccountsPopup'),
  { ssr: false },
);
const LendingCurrentPositionPopup = dynamic(
  () => import('@/components/popups/LendingCurrentPositionPopup'),
  { ssr: false },
);
const LendingPendingTransactionPopup = dynamic(
  () => import('@/components/popups/LendingPendingTransactionPopup'),
  { ssr: false },
);
const LiquidPendingTransactionPopup = dynamic(
  () => import('@/components/popups/LiquidPendingTransactionPopup'),
  { ssr: false },
);
const UshPendingTransactionPopup = dynamic(
  () => import('@/components/popups/UshPendingTransactionPopup'),
  { ssr: false },
);
const PendingTransactionPopup = dynamic(
  () => import('@/components/popups/PendingTransactionPopup'),
  { ssr: false },
);
const RewardsPopup = dynamic(() => import('@/components/popups/RewardsPopup'), {
  ssr: false,
});
const XLaunchpadSnapshotPopup = dynamic(
  () => import('@/components/popups/XLaunchpadSnapshotPopup'),
  {
    ssr: false,
  },
);

const StakedHTMLockedSchedulePopup = dynamic(
  () => import('@/components/popups/StakedHTMLockedSchedulePopup'),
  { ssr: false },
);
const SupplyPopup = dynamic(() => import('@/components/popups/SupplyPopup'), {
  ssr: false,
});
const VoteV2Popup = dynamic(() => import('@/components/popups/VoteV2Popup'), {
  ssr: false,
});

const AirdropAnalyticsPopup = dynamic(
  () => import('@/components/popups/AirdropAnalyticsPopup'),
  {
    ssr: false,
  },
);
const ConnectBridgeWalletPopup = dynamic(
  () => import('@/components/popups/ConnectBridgeWalletPopup'),
  {
    ssr: false,
  },
);

const UshMintPopup = dynamic(() => import('@/components/popups/UshMintPopup'), {
  ssr: false,
});
const MintingPopup = dynamic(() => import('@/components/popups/MintingPopup'), {
  ssr: false,
});

const ManageMyEnergyPopup = dynamic(
  () => import('@/components/popups/ManageMyEnergyPopup'),
  {
    ssr: false,
  },
);
const UnlockEnergyPopup = dynamic(
  () => import('@/components/popups/UnlockEnergyPopup'),
  {
    ssr: false,
  },
);
const LockEnergyPopup = dynamic(
  () => import('@/components/popups/LockEnergyPopup'),
  {
    ssr: false,
  },
);

const AddRemoveLpPopup = dynamic(
  () => import('@/components/popups/AddHTMLiquidityPopup'),
  { ssr: false },
);
const ClosePositionPopup = dynamic(
  () => import('@/components/popups/ClosePositionPopup'),
  { ssr: false },
);

const UshStakeUnstakePopup = dynamic(
  () => import('@/components/popups/UshStakeUnstakePopup'),
  {
    ssr: false,
  },
);

const MetastakeHTMAndFarmTokenPopup = dynamic(
  () => import('@/components/popups/MetastakeHTMAndFarmTokenPopup'),
  {
    ssr: false,
  },
);

const FarmLpTokenPopup = dynamic(
  () => import('@/components/popups/FarmLpTokenPopup'),
  {
    ssr: false,
  },
);

const StakeHtmPopup = dynamic(
  () => import('@/components/popups/StakeHtmPopup'),
  {
    ssr: false,
  },
);

const UnstakeHtmPopup = dynamic(
  () => import('@/components/popups/UnstakeHtmPopup'),
  {
    ssr: false,
  },
);

const UpgradePositionPopup = dynamic(
  () => import('@/components/popups/UpgradePositionPopup'),
  {
    ssr: false,
  },
);

const ManageEnergyPopup = dynamic(
  () => import('@/components/popups/ManageEnergyPopup'),
  {
    ssr: false,
  },
);

const MergePositionsPopup = dynamic(
  () => import('@/components/popups/MergePositionsPopup'),
  {
    ssr: false,
  },
);

const NewEnergyPosition = dynamic(
  () => import('@/components/popups/NewEnergyPosition'),
  {
    ssr: false,
  },
);

const RemoveEnergyPositionPopup = dynamic(
  () => import('@/components/popups/RemoveEnergyPositionPopup'),
  {
    ssr: false,
  },
);

const ActivateUshPool = dynamic(
  () => import('@/components/popups/ActivateUshPool'),
  {
    ssr: false,
  },
);

const popups: Record<string, JSX.Element> = {
  borrow: <BorrowPopup />,
  supply: <SupplyPopup />,
  connectwallet: <ConnectWalletPopup />,
  maiarlogin: <MaiarLoginPopup />,
  accountdetails: <AccountDetailsPopup />,
  connect_bridge_multiverse_wallet: (
    <ConnectBridgeWalletPopup sourceType='MULTIVERSE_X' />
  ),
  pendingtransaction: <PendingTransactionPopup />,
  liquidpendingtransaction: <LiquidPendingTransactionPopup />,
  ushpendingtransaction: <UshPendingTransactionPopup />,
  liquidtaopendingtransaction: <LiquidTaoPendingTransactionPopup />,
  lendingpendingtransaction: <LendingPendingTransactionPopup />,
  rewards: <RewardsPopup />,
  x_launchpad_snapshot: <XLaunchpadSnapshotPopup />,
  ledgeraccounts: <LedgerAccountsPopup />,
  votev2: <VoteV2Popup />,
  htm_stake: <HTMStakePopup />,
  staked_htm_locked_schedule_popup: <StakedHTMLockedSchedulePopup />,
  hatom_validators: <HatomValidators />,
  current_position: <CurrentPositionPopup />,
  lending_current_position: <LendingCurrentPositionPopup />,
  airdrop_analytics: <AirdropAnalyticsPopup />,
  ush_mint: <UshMintPopup />,
  ush_collateral: <UshCollateralPopup />,
  mintingPopup: <MintingPopup />,
  ush_stake_unstake: <UshStakeUnstakePopup />,
  htm_booster_ush: <HTMBoosterPopup1 />,
  activate_ush_pool: <ActivateUshPool />,
  manage_my_energy: <ManageMyEnergyPopup />,
  unlock_energy: <UnlockEnergyPopup />,
  lock_energy: <LockEnergyPopup />,
  add_remove_lp: <AddRemoveLpPopup />,
  metastake_htm_and_farm_token: <MetastakeHTMAndFarmTokenPopup />,
  farm_lp_token: <FarmLpTokenPopup />,
  close_position: <ClosePositionPopup />,
  stakeHtmPopup: <StakeHtmPopup />,
  unstakeHtmPopup: <UnstakeHtmPopup />,
  upgrade_position: <UpgradePositionPopup />,
  manage_energy: <ManageEnergyPopup />,
  merge_positions: <MergePositionsPopup />,
  new_energy_position: <NewEnergyPosition />,
  remove_energy_position: <RemoveEnergyPositionPopup />,
  current_ush_position: <CurrentUshPositionPopup />,
};

export const PopupContext = createContext({});

const PopupProvider: FC<PopupProviderProps> = (props: PopupProviderProps) => {
  const popup = useAppSelector(popupSelector);

  useEffect(() => {
    const feedbackEl = document.querySelector(
      '[class*="_feedback_container"]',
    ) as HTMLElement | null;

    if (!feedbackEl) {
      return;
    }

    if (popup.isOpen) {
      feedbackEl.style.display = 'none';
    } else {
      feedbackEl.style.display = 'block';
    }
  }, [popup.isOpen]);

  return (
    <PopupContext.Provider value={{}}>
      {props.children}

      {popup.isOpen && popup.name && popups[popup.name]}
    </PopupContext.Provider>
  );
};
export { PopupProvider };
