const boosterV2ABI = {
  buildInfo: {
    rustc: {
      version: '1.80.0-nightly',
      commitHash: '791adf759cc065316f054961875052d5bc03e16c',
      commitDate: '2024-05-21',
      channel: 'Nightly',
      short: 'rustc 1.80.0-nightly (791adf759 2024-05-21)',
    },
    contractCrate: {
      name: 'rewards-booster',
      version: '0.0.0',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.53.2',
    },
  },
  name: 'RewardsBoosterV2',
  constructor: {
    docs: [
      'Initializes the contract with essential parameters and an optional admin address.',
      '',
      '# Arguments:',
      '',
      '- `opt_admin` - An optional admin address.',
      '',
      'Notes:',
      '',
      '- If the admin address is not provided, the admin will be set as the deployer.',
      '',
    ],
    inputs: [
      {
        name: 'controller',
        type: 'Address',
      },
      {
        name: 'staking',
        type: 'Address',
      },
      {
        name: 'opt_admin',
        type: 'optional<Address>',
        multi_arg: true,
      },
    ],
    outputs: [],
  },
  upgradeConstructor: {
    inputs: [],
    outputs: [],
  },
  endpoints: [
    {
      docs: [
        'A utility function to highlight that this smart contract is a Rewards Booster.',
        '',
      ],
      name: 'isRewardsBooster',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: [
        'A utility function to highlight that this smart contract is a Stake Module Observer.',
        '',
      ],
      name: 'isStakeObserver',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: [
        'A utility function that returns the version of the smart contract.',
        '',
      ],
      name: 'getVersion',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u8',
        },
      ],
    },
    {
      docs: ['Returns the current admin address.', ''],
      name: 'getAdmin',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: ['Returns the current pending admin address, if there is one.', ''],
      name: 'getPendingAdmin',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Option<Address>',
        },
      ],
    },
    {
      docs: [
        'Sets the pending admin address to the given address.',
        '',
        '# Arguments:',
        '',
        '- `new_pending_admin` - The new pending admin address.',
        '',
      ],
      name: 'setPendingAdmin',
      mutability: 'mutable',
      inputs: [
        {
          name: 'pending_admin',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Attempts to accept the pending admin, which must be set first using the `set_pending_admin` endpoint.',
      ],
      name: 'acceptAdmin',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      docs: [
        'Sets the pricing Oracle smart contract address.',
        '',
        '# Arguments:',
        '',
        '- `new_price_oracle` - The address of the pricing oracle smart contract.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The provided address must be a valid oracle smart contract.',
        '',
      ],
      name: 'setPriceOracle',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      docs: [
        "Sets the Governance smart contract address. From this point onwards, the account's voting power will be",
        'considered.',
        '',
        '# Arguments:',
        '',
        '- `governance` - The address of the Governance smart contract.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setGovernance',
      mutability: 'mutable',
      inputs: [
        {
          name: 'governance',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: ['Stores the price oracle smart contract address.'],
      name: 'getPriceOracle',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getLendingController',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getStakingModule',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: ['Stores the governance smart contract address.'],
      name: 'getGovernance',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: ['Stores the governance token identifier.'],
      name: 'getGovernanceTokenId',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'TokenIdentifier',
        },
      ],
    },
    {
      docs: [
        'Sets the WEGLD token ID, the EGLD wrapper, and Router smart contract addresses.',
        '',
        '# Arguments:',
        '',
        '- `egld_wrapper` - the address of the EGLD wrapper smart contract',
        '- `router` - the address of the router smart contract',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- This function should be called before enabling boosting for any program.',
        '',
      ],
      name: 'setWrapperAndRouterConfig',
      mutability: 'mutable',
      inputs: [
        {
          name: 'egld_wrapper',
          type: 'Address',
        },
        {
          name: 'router',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the XMEX token ID and the XMEX wrapper smart contract address.',
        '',
        '# Arguments:',
        '',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- This function should be called before supporting boosting for any program.',
        '',
      ],
      name: 'setXmexWrapperConfig',
      mutability: 'mutable',
      inputs: [
        {
          name: 'xmex_wrapper',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Stores wrapper smart contract address used to wrap the `locked_token`.',
      ],
      name: 'getXmexWrapper',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: [
        'Stores the ID of the locked token that some Providers return as rewards.',
      ],
      name: 'getXmexId',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'TokenIdentifier',
        },
      ],
    },
    {
      docs: ['Stores wrapped EGLD smart contract address.'],
      name: 'getEgldWrapper',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: ['Stores the token identifier of the wrapped EGLD token.'],
      name: 'getWegldId',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'TokenIdentifier',
        },
      ],
    },
    {
      docs: ['Stores the xExchange Router address.'],
      name: 'getRouter',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: ['Stores whether the wrapper and router have been configured.'],
      name: 'isWrapperAndRouterConfigured',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: ['Stores whether the XMEX wrapper and XMEX have been configured.'],
      name: 'isXmexWrapperAndXmexConfigured',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: [
        'Sets the price time threshold, which is considered when fetching new exchange rates from Money Markets or prices',
        'from the Oracle or Price Providers.',
        '',
        '# Arguments:',
        '',
        '- `price_time_threshold` - The desired price time threshold value.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- If the threshold equals to zero, the price will be fetched every time, even in the same block.',
        '',
      ],
      name: 'setPriceTimeThreshold',
      mutability: 'mutable',
      inputs: [
        {
          name: 'price_time_threshold',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Retrieves the token price using the specified pricing method.',
        '',
        '# Arguments:',
        '',
        '- `token` - Identifier of the token.',
        '- `method` - Method to use for pricing (`LastPrice` or `ReliablePrice`).',
        '',
        '# Notes:',
        '',
        '- When using `ReliablePrice`, the function checks if the last price is within the `price_time_threshold`. If so, it returns the last price instead of fetching a new one.',
        '',
      ],
      name: 'getTokenPrice',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
        {
          name: 'method',
          type: 'PricingMethod',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Retrieves the last recorded token price and timestamp. Returns a dummy value if no price exists.',
        '',
      ],
      name: 'getLastTokenPrice',
      mutability: 'readonly',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,BigUint>',
        },
      ],
    },
    {
      docs: ['Stores price time threshold.'],
      name: 'getPriceTimeThreshold',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      docs: [
        'Adds support for an AshSwap LP token by registering its price provider. The LP token should not have been whitelisted before.',
        '',
        '# Arguments:',
        '',
        '- `derivative_token` - Identifier of the derivative token.',
        '- `lp_address` - Address of the associated liquidity pool.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The token must not be already supported by any other price provider.',
        '- The token must be a valid token.',
        '',
      ],
      name: 'supportAshswapLpToken',
      mutability: 'mutable',
      inputs: [
        {
          name: 'derivative_token',
          type: 'TokenIdentifier',
        },
        {
          name: 'lp_address',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports a new hToken with the hToken price provider. This will allow the HTokenProvider to provide prices for the given token.',
        '',
        '# Arguments',
        '',
        '- `money_market` - Address of the associated money market.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The token must not be already supported by any other price provider.',
        '- The token must be a valid token.',
        '',
      ],
      name: 'supportHToken',
      mutability: 'mutable',
      inputs: [
        {
          name: 'money_market',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Support a new Mirror token with the Mirror price provider. This will allow the MirrorProvider to provide prices for the given token.',
        '',
        '# Arguments',
        '',
        '- `token` - Identifier of the token.',
        '- `mirror_token` - Identifier of the mirror token.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The token must not be already supported by any other price provider.',
        '- The token must be a valid token.',
        '',
      ],
      name: 'supportMirrorToken',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
        {
          name: 'mirror_token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports a new stake token with the Oracle price provider. This will allow the Oracle to provide prices for the given token.',
        '',
        '# Arguments:',
        '',
        '- `token` - The token identifier of the stake token to support.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The token must not be already supported by any other price provider.',
        '- The token must be a valid token.',
        '',
      ],
      name: 'supportOracleToken',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Adds support for an xExchange LP token by registering its price provider.',
        '',
        '# Arguments:',
        '',
        '- `derivative_token` - Identifier of the derivative token.',
        '- `lp_address` - Address of the associated liquidity pool.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The token must not be already supported by any other price provider.',
        '- The token must be a valid token.',
        '',
      ],
      name: 'supportXExchangeLpToken',
      mutability: 'mutable',
      inputs: [
        {
          name: 'derivative_token',
          type: 'TokenIdentifier',
        },
        {
          name: 'lp_address',
          type: 'Address',
        },
        {
          name: 'whole_price',
          type: 'bool',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Handles changes in collateral for a specific account in a money market.',
        '',
        'This function is triggered when tokens are added or removed as collateral in the Controller. It ensures the caller is a valid controller provider,',
        'retrieves the token identifier from the Controller, constructs a `Pool` object, and calls `on_pool_change` to update the pool state.',
        '',
        '# Arguments',
        '',
        '- `money_market` - Address of the money market associated with the change.',
        '- `account` - Address of the account involved in the collateral change.',
        '- `tokens` - New tokens amount.',
        '',
      ],
      name: 'onMarketChange',
      mutability: 'mutable',
      inputs: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'tokens',
          type: 'BigUint',
        },
        {
          name: 'old_amount',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Handles changes in staking for a specific token and account.',
        '',
        'Ensures the caller is a valid staking provider, constructs a `Pool` object, and calls `on_pool_change` to update the pool state.',
        '',
        '# Arguments',
        '',
        '- `token` - Identifier of the token whose staking status changed.',
        '- `account` - Address of the account with the staking change.',
        '- `amount` - New staking amount.',
        '',
      ],
      name: 'onStakeChange',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'amount_new',
          type: 'BigUint',
        },
        {
          name: 'amount_old',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      name: 'swapEnter',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'provider',
          type: 'TokenIdentifier',
        },
        {
          name: 'pay_with',
          type: 'variadic<multi<TokenIdentifier,u64,BigUint>>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'swapExit',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'provider',
          type: 'TokenIdentifier',
        },
        {
          name: 'pay_with',
          type: 'variadic<multi<TokenIdentifier,u64,BigUint>>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'swapClaim',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'provider',
          type: 'TokenIdentifier',
        },
        {
          name: 'pay_with',
          type: 'variadic<multi<TokenIdentifier,u64,BigUint>>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports an Ashswap LP provider by setting the swap provider to `AshLPProvider` with the given LP address and tokens.',
        '',
        '# Arguments:',
        '',
        "- `lp_address` - The Ashswap LP's address.",
        '',
        '# Notes:',
        '',
        '- Can only be called by the administrator.',
        '- The specified Ashswap LP must be active.',
        '- The swap provider for the LP token must not already be supported.',
        '',
      ],
      name: 'supportAshswapLpProvider',
      mutability: 'mutable',
      inputs: [
        {
          name: 'lp_address',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports an Ashswap farm provider by setting the swap provider to `AshFarmProvider` with the given farm address and underlying token.',
        '',
        '# Arguments:',
        '',
        "- `farm_address` - The Ashswap farm's address.",
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The specified Ashswap farm must be active.',
        '- The swap provider for the farm token must not already be supported.',
        '',
      ],
      name: 'supportAshswapFarmProvider',
      mutability: 'mutable',
      inputs: [
        {
          name: 'farm_address',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports an XExchange LP provider by setting the swap provider to `XexLpProvider` with the given LP address and tokens.',
        '',
        '# Arguments:',
        '',
        "- `lp_address` - The XExchange LP's address.",
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The specified XExchange LP must be active.',
        '- The swap provider for the LP token must not already be supported.',
        '',
      ],
      name: 'supportXExchangeLpProvider',
      mutability: 'mutable',
      inputs: [
        {
          name: 'lp_address',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports an XExchange farm provider by setting the swap provider to `XexFarmProvider` with the given farm address and underlying token.',
        '',
        '# Arguments:',
        '',
        "- `farm_address` - The XExchange farm's address.",
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The specified XExchange farm must be active.',
        '- The swap provider for the farm token must not already be supported.',
        '',
      ],
      name: 'supportXExchangeFarmProvider',
      mutability: 'mutable',
      inputs: [
        {
          name: 'farm_address',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports an XExchange stake farm provider by setting the swap provider to `XexFarmStakingProvider` with the given farm address and underlying token.',
        '',
        '# Arguments:',
        '',
        "- `farm_address` - The XExchange farm's address.",
        '',
        '# Notes:',
        '',
        '- Can only be called by the administrator.',
        '- The specified XExchange farm must be active.',
        '- The swap provider for the farm token must not already be supported.',
        '',
      ],
      name: 'supportXExchangeStakeFarmProvider',
      mutability: 'mutable',
      inputs: [
        {
          name: 'farm_address',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: ['A utility function used by the Governance smart contract.', ''],
      name: 'isTrustedContract',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: [
        'Synchronizes the voting power for a list of accounts.',
        '',
        '# Arguments:',
        '',
        "- `accounts` - A list of managed addresses whose voting power needs to be synchronized. If empty, the caller's address is used.",
        '',
        '# Notes:',
        '',
        "- If no accounts are provided, the caller's address will be used.",
        '- If accounts are provided, the function requires admin privileges to execute.',
        '',
      ],
      name: 'syncVotingPower',
      mutability: 'mutable',
      inputs: [
        {
          name: 'accounts',
          type: 'variadic<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Stores whether the account has synchronized its voting power or not.',
      ],
      name: 'hasSyncVotingPower',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: ['Stores the account local voting power.'],
      name: 'getAccountLocalVotingPower',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the total amount staked by a given account in a specific token across all programs.',
      ],
      name: 'getGlobalAccountStake',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Sets the maximum slippage allowed for configuration swaps for a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `max_slippage` - The new maximum slippage allowed.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setMaxSlippage',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'max_slippage',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Enables rewards batch boosting for a given program. It is set by program because the boosting token can be different for each program.',
        '',
        '# Arguments:',
        '',
        '- `program` - the identifier of the program.',
        '- `boosting_token_id` - the token identifier for boosting.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- Requires that the global boosting configuration is set.',
        '',
      ],
      name: 'supportRewardsBatchBoosting',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'boosting_token_id',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Enables support for boosting rewards batches for a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - the identifier of the program.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'enableRewardsBatchBoosting',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Disables support for boosting rewards batches for a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - the identifier of the program.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'disableRewardsBatchBoosting',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        "Boosts the rewards of a given rewards token in the specified program by converting the rewards tokens into Hatom's governance token with",
        'a premium.',
        '',
        '# Arguments:',
        '',
        '- `program` - the identifier of the program.',
        '- `premium` - the premium in wad, such that 1 wad = 100%.',
        '- `fwd_swap_amount` - the amount of tokens to swap.',
        "- `fwd_swap_path` - the swap path to convert the rewards batch tokens into Hatom's governance tokens.",
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '- If rewards token is EGLD, swaps will add a EGLD => WEGLD step first. Also, the swap path needs to use the',
        '  WEGLD token identifier.',
        '',
      ],
      name: 'boostRewards',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'premium',
          type: 'BigUint',
        },
        {
          name: 'fwd_swap_amount',
          type: 'BigUint',
        },
        {
          name: 'fwd_swap_path',
          type: 'List<SwapStep>',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        "Updates the premium of a given booster in the specified program and, if a payment is provided, adds it to the booster's amount.",
        '',
        '# Arguments:',
        '',
        '- `program` - the identifier of the program.',
        '- `rewards_token_id` - the rewards token identifier for which we wish to update its booster.',
        '- `premium` - the premium in wad, such that 1 wad = 100%.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '- Cannot change the swap path. That requires canceling the booster and creating a new one.',
        '',
      ],
      name: 'updateBooster',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'premium',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Cancels a given booster in the specified program and sends the remaining tokens back to the caller.',
        '',
        '# Arguments:',
        '',
        '- `program` - the identifier of the program.',
        '- `rewards_token_id` - the rewards token identifier for which we wish to cancel its booster.',
        '- `opt_to` - the beneficiary address for the remaining tokens (optional).',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '',
      ],
      name: 'cancelBooster',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'opt_to',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Creates a new staking program within the smart contract. The program is created with the specified parameters and receives a unique `program`.',
        'Once the program is created, it sets up the relevant configurations such as the cooldown period, the maximum number of stakes and tokens per account,',
        'and the maximum number of rewards batches.',
        '',
        '# Arguments:',
        '',
        '- `pool_provider`: The address of the token provider.',
        '- `cooldown_period`: The cooldown period (in seconds) that an account must wait between an unstake and a successful claim.',
        '- `max_account_stakes`: The maximum number of stake tokens per account.',
        '- `max_tokens`: The maximum number of tokens the program can have.',
        '- `max_batches`: The maximum number of batches a token can have.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- Adding tokens and stake tokens to this program is handled in a separate transaction.',
        '- The booster should not be finalized.',
        '',
      ],
      name: 'createProgram',
      mutability: 'mutable',
      inputs: [
        {
          name: 'pool_provider',
          type: 'Address',
        },
        {
          name: 'cooldown_period',
          type: 'u64',
        },
        {
          name: 'max_account_stakes',
          type: 'u32',
        },
        {
          name: 'max_pools',
          type: 'u32',
        },
        {
          name: 'max_batches',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Activates a given program. Once a program is activated, users can start interacting with it.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '',
        '# Notes:',
        '',
        '- can only be called by the admin',
        '- program should exist and be in state Inactive',
        '- finalized programs cannot be activated again',
        '',
      ],
      name: 'activateProgram',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Finalizes a given program. Once a program is finalized, the only interactions allowed are unstaking and claiming rewards.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The program should exist and be in state Active.',
        '- Once finalized, the program cannot be activated again.',
        '- If the program has tokens, and they have rewards batches, all rewards batches should have distributed all their rewards.',
        '',
      ],
      name: 'finalizeProgram',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Activates the entire booster.',
        'This function requires that the booster is observing the Controller and the Staking smart contracts.',
        'Once the booster is activated, the admin can start adding programs to it.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The booster should be inactive.',
        '- The booster should be observing the Controller and the Staking smart contracts.',
        '',
      ],
      name: 'activateBooster',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      docs: [
        'Finalizes the entire booster.',
        'This function is used to finalize the entire booster and remove it from the Controller.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- All programs should be finalized before calling this function.',
        '',
      ],
      name: 'finalizeBooster',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      docs: [
        'Sets the sanction threshold for the token within a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `token_id` - The token identifier of the token token.',
        '- `staking_ratio_threshold` - The desired sanction threshold value.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setStakingRatioThreshold',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'staking_ratio_threshold',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the base reward ratio for the token within a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `token_id` - The token identifier of the token token.',
        '- `base_rewards_ratio` - The desired base rewards ratio.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setBaseRewardsRatio',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'base_rewards_ratio',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the cooldown period for a given program, defining the time that must elapse between unstaking and a successful claim.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `cooldown_period` - The desired cooldown period in seconds.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setCooldownPeriod',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'cooldown_period',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the maximum number of stake tokens per account within a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `max_account_stakes` - The new maximum number of stake tokens per account.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setMaxAccountStakeTokens',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'max_account_stakes',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the maximum number of tokens within a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `max_tokens` - The new maximum number of tokens.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The new maximum number of tokens must be greater than the current one.',
        '',
      ],
      name: 'setMaxTokens',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'max_tokens',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the maximum amount of rewards batches per token within a given program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `max_batches` - The new maximum amount of rewards batches.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The provided address must be a whitelisted money market.',
        '',
      ],
      name: 'setMaxBatches',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'max_batches',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports a new stake token for a given program. The stake token must have a valid Price Provider and it must be working properly',
        'this means that it must consume gas within the expected limits. The stake token is added to the whitelist and its price is integrated.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `stake` - The stake token identifier.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The stake token must not be already whitelisted.',
        '',
      ],
      name: 'supportStake',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Supports a new pool for a given program. The pool must have a valid token Provider and be operating properly, meaning that pricing the pool  should not consume more gas than expected.',
        "The pool is added to the whitelist, and the program is also associated with the pool’s existing programs. The pool's price is integrated using the Reliable Price method.",
        'After the pool is added to the whitelist, the staking ratio threshold and base rewards ratio are set.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `token_provider` - The address of the token provider.',
        '- `pool` - The pool identifier of the pool.',
        '- `staking_ratio_threshold` - The staking ratio threshold.',
        '- `base_rewards_ratio` - The base rewards ratio.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '- The Token must exist in the Token Provider.',
        '- The Token must not be already whitelisted in the program.',
        '',
      ],
      name: 'supportProgramPool',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'staking_ratio_threshold',
          type: 'BigUint',
        },
        {
          name: 'base_rewards_ratio',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Sets the Rewards Manager of the Rewards Booster.',
        '',
        '# Arguments:',
        '',
        '- `rewards_manager` - The address of the new Rewards Manager.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin.',
        '',
      ],
      name: 'setRewardsManager',
      mutability: 'mutable',
      inputs: [
        {
          name: 'rewards_manager',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Adds a rewards batch to the specified money market. EGLD or ESDT tokens are supported.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `pool` - The pool identifier of the pool.',
        '- `period` - The period of time in seconds in which rewards are distributed.',
        '- `is_extra` - A flag indicating if the rewards batch is extra or not.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '- The provided pool must be whitelisted.',
        '- Should be paid with the rewards token.',
        '',
      ],
      name: 'setRewardsBatch',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'period',
          type: 'u64',
        },
        {
          name: 'is_extra',
          type: 'bool',
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
    {
      docs: [
        'Adds an amount of reward token to an existing rewards batch maintaining the same speed.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `pool` - The pool identifier of the pool.',
        '- `batch` - The rewards batch identifier.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '',
      ],
      name: 'addRewardsBatch',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Cancel a specified rewards batch. Remaining tokens are sent back to a beneficiary.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `pool` - The pool identifier of the pool.',
        '- `batch` - The rewards batch identifier.',
        '- `opt_to` - The beneficiary address for the remaining tokens (optional).',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '- The caller is selected if no beneficiary is given.',
        '',
      ],
      name: 'cancelRewardsBatch',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
        {
          name: 'opt_to',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Removes a specified rewards batch from the array of rewards batches iff it has been fully distributed.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `pool` - The pool identifier of the pool.',
        '- `batch` - The rewards batch identifier.',
        '',
        '# Notes',
        '',
        '- Can be called by anyone.',
        '- Takes into consideration possible rounding errors but it is conservative.',
        '',
      ],
      name: 'removeRewardsBatch',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Removes a specified rewards batch from the array of rewards batches iff it has been fully distributed within a given',
        'tolerance amount.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `pool` - The pool identifier of the pool.',
        '- `batch` - The rewards batch identifier.',
        '- `tolerance` - The tolerance in wad, such that 1 wad = 100%.',
        '',
        '# Notes',
        '',
        '- Can only be called by the admin or rewards manager.',
        '',
      ],
      name: 'adminRemoveRewardsBatch',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
        {
          name: 'tolerance',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Updates a given rewards batch based on a new speed. The new speed of rewards also changes the remaining distribution',
        'time period.',
        '',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `pool` - The pool identifier of the pool.',
        '- `batch` - The rewards batch identifier.',
        '- `new_speed` - The new speed of rewards in wad.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '',
      ],
      name: 'updateRewardsBatchSpeed',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
        {
          name: 'new_speed',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Updates a given rewards batch based on a new period. The new period also changes the speed of rewards.',
        '',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `token_id` - The token identifier of the token.',
        '- `batch` - The rewards batch identifier.',
        '- `new_dt` - The new period.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '',
      ],
      name: 'updateRewardsBatchRemainingPeriod',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
        {
          name: 'new_dt',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Withdraws the undistributed or penalized rewards.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `rewards_token_id` - The rewards token identifier.',
        '',
        '# Notes:',
        '',
        '- Can only be called by the admin or rewards manager.',
        '- Withdraws the total undistributed amounts for the specified token ID or EGLD and sends them to the admin',
        '  address.',
        '',
      ],
      name: 'withdrawnPenalizedRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Reallocate tokens from one program into another program. The caller must be a staker in the source program.',
        'The pricing method used in both unstake and stake operations is last price this allows to save gas.',
        '',
        '# Arguments',
        '',
        '- `from` - The program id of the source program.',
        '- `to` - The program id of the destination program.',
        '- `payment` - The payment to be used in both unstake and stake operations.',
        '',
      ],
      name: 'reallocate',
      mutability: 'mutable',
      inputs: [
        {
          name: 'from',
          type: 'u32',
        },
        {
          name: 'to',
          type: 'u32',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'This endpoint is the main entry point to the Booster module by allowing users to stake valid tokens in a program to start earning rewards.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        "- `opt_account` - An optional account address. If not provided, the caller's address will be used.",
        '',
        '# Notes:',
        '',
        '- The program should be active.',
        '- Amount should be greater than zero.',
        '- The token should be whitelisted in the program.',
        '',
      ],
      name: 'stake',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'opt_account',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'This endpoint allows users to unstake their staked tokens. This process will start a cooldown period after which',
        'the user can claim back the tokens.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `payment` - An ESDT token payment describing the token identifier, nonce and amount to be unstaked.',
        '- `pricing_method` - The pricing method to be used.',
        '- `opt_max_cooldown_period` - The maximum accepted cooldown period for the claim.',
        '',
        'Notes:',
        '',
        '- The program should exist.',
        '- Amount should be greater than zero.',
        '',
      ],
      name: 'unstake',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
        {
          name: 'pricing_method',
          type: 'PricingMethod',
        },
        {
          name: 'opt_max_cooldown_period',
          type: 'optional<u64>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
    {
      docs: [
        'This endpoint allows users to claim their unstaked tokens after the cooldown period has elapsed.',
        '',
        '# Arguments:',
        '',
        '- `program`: The program identifier.',
        '- `claim_id`: The claim identifier.',
        '',
      ],
      name: 'claim',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'claim_id',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      docs: [
        'This endpoint allows users to stake the tokens available in a claim, even if the cooldown period has not elapsed',
        '',
        '# Arguments:',
        '',
        '- `from` - The program id of the source program.',
        '- `claim_id`: The claim identifier.',
        '- `to` - The program id of the destination program.',
        '',
      ],
      name: 'stakeClaim',
      mutability: 'mutable',
      inputs: [
        {
          name: 'from',
          type: 'u32',
        },
        {
          name: 'claim_id',
          type: 'u32',
        },
        {
          name: 'to',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Updates the rewards batches state for all tokens in the given program.',
        '',
        '# Arguments:',
        '',
        '- `program`: The identifier of the program.',
        '- `tokens`: An array of token identifiers.',
        '',
        '# Notes:',
        '',
        '- If no tokens are provided, then all whitelisted tokens in the program will be updated.',
        '',
      ],
      name: 'updateRewardsBatchesState',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pools',
          type: 'variadic<TokenIdentifier>',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Distributes all rewards from all the tokens in the given program to the given accounts.',
        '',
        '# Arguments:',
        '',
        '- `program`: The identifier of the program.',
        '- `accounts`: An array of account addresses.',
        '- `pools` - The list of pools.',
        '',
        '# Notes:',
        '',
        '- If no markets are provided, then all whitelisted money markets will be used.',
        '- If no accounts are provided, then only the caller will get his rewards distributed.',
        '',
      ],
      name: 'distributeRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'accounts',
          type: 'List<Address>',
        },
        {
          name: 'pools',
          type: 'variadic<TokenIdentifier>',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Claims all rewards to the specified accounts from all rewards batches corresponding to the tokens in the given program.',
        '',
        '# Arguments:',
        '',
        '- `program`: The identifier of the program.',
        '- `boost`: Whether to boost the rewards or not.',
        '- `accounts`: An array of account addresses.',
        '- `tokens`: An array of token identifiers.',
        '- `opt_min_boosted_rewards_out`: An optional minimum amount of boosted rewards out.',
        '',
        '# Notes:',
        '',
        '- If `boost` is enabled, then the rewards will be boosted using the rewards booster.',
        '- If no tokens are specified, then all the tokens where the user has balance in the Pool Provider will be used.',
        '- If no accounts are provided, then only the caller will claim his rewards.',
        '',
      ],
      name: 'claimRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'boost',
          type: 'bool',
        },
        {
          name: 'accounts',
          type: 'List<Address>',
        },
        {
          name: 'pools',
          type: 'List<TokenIdentifier>',
        },
        {
          name: 'opt_min_boosted_rewards_out',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'variadic<multi<Address,EgldOrEsdtTokenPayment>>',
          multi_result: true,
        },
      ],
    },
    {
      docs: [
        "Returns the account's compliance integral in the specified program.",
        'The compliance integral is calculated as the integral of both capped and uncapped compliance for the account over time.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `account` - The account address.',
        '',
      ],
      name: 'getAccountComplianceIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,u64,BigUint,BigUint>',
        },
      ],
    },
    {
      docs: [
        "Returns the account's token compliance integral in the specified program.",
        "This value represents the account's compliance integral at the time of rewards distribution for a given token in the specified program.",
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `account` - The account address.',
        '- `pool` - The pool token identifier.',
        '',
      ],
      name: 'getAccountPoolComplianceIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,u64,BigUint,BigUint>',
        },
      ],
    },
    {
      docs: [
        "Retrieves the account's current pool capped and uncapped compliance factor for the specified program and pool, and updates the stored compliance integrals used for its calculation.",
        "The compliance factor is calculated as the change in the account's compliance integral over time, normalized by the change in the kappa integral.",
        '',
        '# Arguments',
        '',
        '- `program` - The identifier of the program.',
        '- `account` - The address of the account.',
        '- `pool` - The identifier of the pool.',
        '',
      ],
      name: 'getAccountPoolComplianceFactor',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<BigUint,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Returns the price integral for a given program and token.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `token` - The identifier of the token, which could represent either a collateral token or a stake token.',
        '',
      ],
      name: 'getPriceIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,u64,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Returns the price integral for a given account and token in the specified program.',
        '',
        '# Arguments:',
        '',
        '- `program` - The identifier of the program.',
        '- `account` - The address of the account.',
        '- `token` - The identifier of the token.',
        '',
      ],
      name: 'getAccountPriceIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Integrates the price information for a specified program across multiple tokens and stake tokens.',
        '',
        '# Arguments',
        '',
        '- `program` - The unique identifier of the program.',
        '- `tokens` - A list of token identifiers to integrate. This can include both stake tokens and other types of tokens associated with a pool provider.',
        '',
      ],
      name: 'integratePrices',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'tokens',
          type: 'variadic<TokenIdentifier>',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Checks whether the current state of the smart contract is finalized.',
        '',
      ],
      name: 'isFinalized',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      name: 'getWhitelistedStakes',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'List<TokenIdentifier>',
        },
      ],
    },
    {
      name: 'getAccountStakes',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<TokenIdentifier>',
        },
      ],
    },
    {
      name: 'getGlobalAccountStakes',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<TokenIdentifier>',
        },
      ],
    },
    {
      name: 'getWhitelistedPools',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'List<TokenIdentifier>',
        },
      ],
    },
    {
      name: 'getAccountPoolBatchAccruedRewards',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'RewardsAccrued',
        },
      ],
    },
    {
      name: 'getTotalAmount',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getAccountAmount',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getAccountStakeNonces',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'List<u32>',
        },
      ],
    },
    {
      docs: ['Retrieves the current state of the Booster module.'],
      name: 'getState',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'State',
        },
      ],
    },
    {
      docs: [
        'Stores the timestamp and staking ratio threshold integral for a given program and pool.',
      ],
      name: 'getStakingRatioThresholdIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Stores the account staking ratio threshold integral and its timestamp.',
      ],
      name: 'getAccountStakingRatioThresholdIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Stores the base rewards ratio, its integral value and its timestamp by pool and program.',
      ],
      name: 'getBaseRewardsRatioIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Stores the account base rewards ratio integral by pool and its timestamp.',
      ],
      name: 'getAccountBaseRewardsRatioIntegral',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'tuple<u64,BigUint>',
        },
      ],
    },
    {
      docs: [
        "Stores the rewards that have accrued but haven't been distributed to users.",
      ],
      name: 'getPenalizedRewards',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getNextProgramId',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
    {
      name: 'getProgram',
      mutability: 'readonly',
      inputs: [
        {
          name: 'id',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'Program',
        },
      ],
    },
    {
      name: 'getTotalExtraPoolAmount',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getAccountExtraPoolAmount',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the rewards batch booster for a given rewards token identifier.',
      ],
      name: 'getRewardsBooster',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'RewardsBooster',
        },
      ],
    },
    {
      docs: ['Stores the rewards manager address.'],
      name: 'getRewardsManager',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getAccountPoolPendingBatchIds',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'variadic<u32>',
          multi_result: true,
        },
      ],
    },
    {
      docs: [
        'Stores the rewards index for a given program, account, pool and batch id.',
      ],
      name: 'getAccountBatchRewardsIndex',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: ['Stores the ID of the next rewards batch in the specified pool.'],
      name: 'getNextRewardsBatchId',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
    {
      docs: ['Stores whether the Token has extra rewards set.'],
      name: 'hasExtraRewardsBatch',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: ['Stores the list of rewards batches in the specified pool.'],
      name: 'getRewardsBatches',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'variadic<RewardsBatch>',
          multi_result: true,
        },
      ],
    },
    {
      docs: [
        'Stores the staking ratio threshold value for the specified program and pool.',
      ],
      name: 'getStakingRatioThreshold',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the base rewards ratio value for the specified program and pool.',
      ],
      name: 'getBaseRewardsRatio',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the current position of a rewards batch in the specified pool at the corresponding VecMapper.',
      ],
      name: 'getRewardsBatchPosition',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'batch',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
    {
      docs: ['Stores the total staked amount.'],
      name: 'getTotalStake',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: ['Stores the amount a given account has staked by token.'],
      name: 'getAccountStake',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the amount a given account has staked by token and nonce.',
      ],
      name: 'getAccountTokenNonceStake',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
        },
        {
          name: 'stake_nonce',
          type: 'u64',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: ['Stores the claim made by an account based on their claim ID.'],
      name: 'getAccountClaimsById',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'claim_id',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'Claim',
        },
      ],
    },
    {
      docs: [
        'Stores the current claim ID for a particular account and program.',
      ],
      name: 'getLastClaimId',
      mutability: 'readonly',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
  ],
  events: [
    {
      docs: ['Event emitted when the pending admin is updated.'],
      identifier: 'new_pending_admin_event',
      inputs: [
        {
          name: 'pending_admin',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when the admin is updated.'],
      identifier: 'new_admin_event',
      inputs: [
        {
          name: 'admin',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when a new pricing oracle smart contract address is set.',
      ],
      identifier: 'new_price_oracle_event',
      inputs: [
        {
          name: 'price_oracle',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the Governance smart contract address is set.'],
      identifier: 'set_governance_event',
      inputs: [
        {
          name: 'governance',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'governance_token_id',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new price time threshold is set.'],
      identifier: 'new_price_time_threshold_event',
      inputs: [
        {
          name: 'price_time_threshold',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new AshSwap LP token is supported.'],
      identifier: 'support_ashswap_lp_token_event',
      inputs: [
        {
          name: 'derivative_token',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'lp_address',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new Mirror token is supported.'],
      identifier: 'support_h_token_event',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new Mirror token is supported.'],
      identifier: 'support_mirror_token_event',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'mirror_token',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'support_oracle_token_event',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new xExchange LP token is supported.'],
      identifier: 'support_xexchange_lp_token_event',
      inputs: [
        {
          name: 'derivative_token',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'lp_address',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a controller pool provider is supported.'],
      identifier: 'support_controller_pool_provider_event',
      inputs: [
        {
          name: 'pool_provider',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a staking pool provider is supported.'],
      identifier: 'support_staking_pool_provider_event',
      inputs: [
        {
          name: 'pool_provider',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a user interacts with a swap provider.'],
      identifier: 'swap_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'operation',
          type: 'bytes',
          indexed: true,
        },
        {
          name: 'provider',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'payments',
          type: 'List<EsdtTokenPayment>',
          indexed: true,
        },
        {
          name: 'pay_with',
          type: 'variadic<multi<TokenIdentifier,u64,BigUint>>',
          indexed: true,
        },
        {
          name: 'back_transfers_egld',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'back_transfers_esdt',
          type: 'List<EsdtTokenPayment>',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when an Ashswap LP provider is supported.'],
      identifier: 'support_ashswap_lp_provider_event',
      inputs: [
        {
          name: 'lp_address',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when an Ashswap Farm provider is supported.'],
      identifier: 'support_ashswap_farm_provider_event',
      inputs: [
        {
          name: 'farm_address',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when an xExchange LP provider is supported.'],
      identifier: 'support_xexchange_lp_provider_event',
      inputs: [
        {
          name: 'lp_address',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when an xExchange Farm provider is supported.'],
      identifier: 'support_xexchange_farm_provider_event',
      inputs: [
        {
          name: 'farm_address',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when an xExchange Staking Farm provider is supported.'],
      identifier: 'support_xexchange_stake_farm_provider_event',
      inputs: [
        {
          name: 'farm_address',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the voting power of an account is synchronized.'],
      identifier: 'has_sync_voting_power_event',
      inputs: [
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the voting power of an account is increased.'],
      identifier: 'increase_local_voting_power_event',
      inputs: [
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'voting_power',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when the voting power of an account is decreased through',
      ],
      identifier: 'decrease_local_voting_power_event',
      inputs: [
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'voting_power',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the booster state is modified'],
      identifier: 'set_state_event',
      inputs: [
        {
          name: 'state',
          type: 'State',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the price integral is updated.'],
      identifier: 'price_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'theta_integral',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'price_integral',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the account price integral is updated.'],
      identifier: 'account_price_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'token',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'theta_integral',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'price_integral',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when the compliance integral and kappa are updated for a specific account within a program.',
      ],
      identifier: 'account_compliance_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'kappa',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'compliance_integral',
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Emitted when the compliance integral and kappa are updated for a specific program, account, and pool.',
      ],
      identifier: 'account_pool_compliance_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'kappa',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'compliance_integral',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a program is created.'],
      identifier: 'program_created_event',
      inputs: [
        {
          name: 'id',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'program',
          type: 'Program',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the program state is modified.'],
      identifier: 'set_program_state_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'state',
          type: 'State',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new cooldown period is set.'],
      identifier: 'new_cooldown_period_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'cooldown_period',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new staking ratio threshold is set.'],
      identifier: 'new_staking_ratio_threshold_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'staking_ratio_threshold',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the staking ration integral is updated.'],
      identifier: 'staking_ratio_threshold_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'integral_staking_ratio_threshold',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the account price integral is updated.'],
      identifier: 'account_staking_ratio_threshold_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'integral_staking_ratio_threshold',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new base rewards ratio is set.'],
      identifier: 'new_base_rewards_ratio_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'base_rewards_ratio',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the base rewards ratio is updated.'],
      identifier: 'base_rewards_ratio_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'integral_staking_ratio_threshold',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the account price integral is updated.'],
      identifier: 'account_base_rewards_ratio_integral_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'integral_staking_ratio_threshold',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when a new maximum amount of rewards batches is defined for a given token within a program.',
      ],
      identifier: 'new_max_rewards_batches_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'max_rewards_batches',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when a new maximum amount of stake tokens per account is set.',
      ],
      identifier: 'new_max_account_stakes_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'max_account_stakes',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when a new maximum amount of pools in the program is set.',
      ],
      identifier: 'new_max_pools_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'max_pools',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new stake is supported.'],
      identifier: 'support_stake_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'stake',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new pool is supported.'],
      identifier: 'support_pool_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new rewards manager is set.'],
      identifier: 'new_rewards_manager_event',
      inputs: [
        {
          name: 'rewards_manager',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when a rewards batch is set.'],
      identifier: 'set_rewards_batch_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when a rewards batch adds more rewards.'],
      identifier: 'add_rewards_batch_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when a rewards batch is cancelled.'],
      identifier: 'cancel_rewards_batch_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when a rewards batch is removed.'],
      identifier: 'remove_rewards_batch_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'batch',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when the rewards batch speed is updated.'],
      identifier: 'update_rewards_batch_speed_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Event emitted when the remaining period of a rewards batch is updated.',
      ],
      identifier: 'update_rewards_batch_remaining_period_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when the rewards batch state is updated.'],
      identifier: 'rewards_batches_updated_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when account rewards are distributed.'],
      identifier: 'account_rewards_distributed_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
        {
          name: 'account_rewards',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'penalty_rewards',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when user rewards are claimed.'],
      identifier: 'rewards_claimed_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'claimer',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch',
          type: 'RewardsBatch',
          indexed: true,
        },
        {
          name: 'claimed_amount',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when user rewards are claimed.'],
      identifier: 'rewards_token_claimed_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'claimer',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
          indexed: true,
        },
        {
          name: 'claimed_amount',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when penalized rewards are distributed.'],
      identifier: 'penalized_rewards_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'rewards_token_id',
          type: 'EgldOrEsdtTokenIdentifier',
          indexed: true,
        },
        {
          name: 'rewards_accrued',
          type: 'BigUint',
        },
      ],
    },
    {
      docs: ['Emitted when penalized rewards are withdrawn.'],
      identifier: 'withdraw_penalized_rewards_event',
      inputs: [
        {
          name: 'admin',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'rewards_token_id',
          type: 'EgldOrEsdtTokenIdentifier',
          indexed: true,
        },
        {
          name: 'amount',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Emitted when a user claims a specific claim ID to get its own previously unstaked tokens.',
      ],
      identifier: 'claim_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'claim_id',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a user re-stake a claim into new_program'],
      identifier: 'stake_claim_event',
      inputs: [
        {
          name: 'from',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'claim_id',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'to',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when the account rewards batch index is updated.'],
      identifier: 'account_batch_rewards_index_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
          indexed: true,
        },
        {
          name: 'batch',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'rewards_index',
          type: 'BigUint',
        },
      ],
    },
    {
      docs: ['Emitted when a user stakes his tokens.'],
      identifier: 'stake_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a user unstakes his previously staked tokens.'],
      identifier: 'unstake_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
          indexed: true,
        },
        {
          name: 'opt_claim',
          type: 'Option<Claim>',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a user reallocate his tokens.'],
      identifier: 'reallocate_event',
      inputs: [
        {
          name: 'account',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'from',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'to',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Emitted when a new maximum slippage is set.'],
      identifier: 'new_max_slippage_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'max_slippage',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when rewards batch boosting is supported.'],
      identifier: 'support_rewards_batch_boosting_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'boosting_token_id',
          type: 'TokenIdentifier',
        },
      ],
    },
    {
      docs: ['Event emitted when rewards batch boosting is enabled.'],
      identifier: 'enable_rewards_batch_boosting_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when rewards batch boosting is disabled.'],
      identifier: 'disable_rewards_batch_boosting_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Event emitted when rewards are boosted for a specific rewards token.',
      ],
      identifier: 'boost_rewards_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'rewards_batch_booster',
          type: 'RewardsBooster',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Event emitted when a booster is updated for a specific rewards token.',
      ],
      identifier: 'update_booster_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'rewards_batch_booster',
          type: 'RewardsBooster',
          indexed: true,
        },
      ],
    },
    {
      docs: [
        'Event emitted when a booster is cancelled for a specific rewards token.',
      ],
      identifier: 'cancel_booster_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when boosted rewards are claimed.'],
      identifier: 'boosted_rewards_claimed_event',
      inputs: [
        {
          name: 'program',
          type: 'u32',
          indexed: true,
        },
        {
          name: 'claimer',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'rewards_batch_booster',
          type: 'RewardsBooster',
          indexed: true,
        },
        {
          name: 'claimed_amount',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
  ],
  esdtAttributes: [],
  hasCallback: false,
  types: {
    BoostingState: {
      type: 'enum',
      variants: [
        {
          name: 'Inactive',
          discriminant: 0,
        },
        {
          name: 'Active',
          discriminant: 1,
        },
        {
          name: 'Finalized',
          discriminant: 2,
        },
      ],
    },
    Claim: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
        {
          name: 'claim_timestamp',
          type: 'u64',
        },
        {
          name: 'claimed',
          type: 'bool',
        },
      ],
    },
    EgldOrEsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    EsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    PricingMethod: {
      type: 'enum',
      variants: [
        {
          name: 'ReliablePrice',
          discriminant: 0,
        },
        {
          name: 'LastPrice',
          discriminant: 1,
        },
      ],
    },
    Program: {
      type: 'struct',
      fields: [
        {
          name: 'state',
          type: 'State',
        },
        {
          name: 'cooldown_period',
          type: 'u64',
        },
        {
          name: 'max_account_stakes',
          type: 'u32',
        },
        {
          name: 'max_pools',
          type: 'u32',
        },
        {
          name: 'max_batches',
          type: 'u32',
        },
        {
          name: 'pool_provider',
          type: 'Address',
        },
        {
          name: 'boosting_supported',
          type: 'bool',
        },
        {
          name: 'boosting_state',
          type: 'BoostingState',
        },
        {
          name: 'boosting_token',
          type: 'Option<TokenIdentifier>',
        },
        {
          name: 'max_slippage',
          type: 'BigUint',
        },
      ],
    },
    RewardsAccrued: {
      type: 'struct',
      fields: [
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    RewardsBatch: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'pool',
          type: 'TokenIdentifier',
        },
        {
          name: 'rewards_token',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'speed',
          type: 'BigUint',
        },
        {
          name: 'index',
          type: 'BigUint',
        },
        {
          name: 'is_extra',
          type: 'bool',
        },
        {
          name: 'start_time',
          type: 'u64',
        },
        {
          name: 'last_time',
          type: 'u64',
        },
        {
          name: 'end_time',
          type: 'u64',
        },
      ],
    },
    RewardsBooster: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'premium',
          type: 'BigUint',
        },
        {
          name: 'amount_left',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'swap_path',
          type: 'List<SwapStep>',
        },
      ],
    },
    State: {
      type: 'enum',
      variants: [
        {
          name: 'Inactive',
          discriminant: 0,
        },
        {
          name: 'Active',
          discriminant: 1,
        },
        {
          name: 'Finalized',
          discriminant: 2,
        },
      ],
    },
    SwapStep: {
      type: 'struct',
      fields: [
        {
          name: 'pair_address',
          type: 'Address',
        },
        {
          name: 'input_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'output_token_id',
          type: 'TokenIdentifier',
        },
      ],
    },
  },
};

export default boosterV2ABI;
