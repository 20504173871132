import { TABS } from "@/components/popups/UshCollateralPopup/constants";

import clsxm from "@/services/clsxm";

function CollateralOptionCard({
  isSelected,
  onClick,
  iconUrl,
  title,
  description,
  showDescription,
  variant,
}: {
  iconUrl: string,
  title: string,
  description: string,
  isSelected: boolean,
  token: string,
  showDescription: boolean,
  selectedTab: TABS,
  onClick: () => void,
  variant: "left" | "right",
}) {
  return (
    <button
      className={clsxm(
        'group pl-[4.7px] py-[11px] pr-[5px] w-[204px] rounded-[12px] cursor-pointer border-[1.4px]',
        variant === "left" && [
          "bg-[#EFE8F9] border-[#EFE8F9] dark:!border-[#4C469F]",
          isSelected && "bg-[#E2DFFB] border-[#E2DFFB] dark:bg-[#746CDB]  dark:!border-[#746CDB]",
          !isSelected && "hover:border-[#C8C4E8] hover:bg-[#E8E1F6] dark:bg-[#4C469F] dark:hover:border-[#8F81D0]",
        ],
        variant === "right" && [
          "bg-[#EBF3FE] border-[#EBF3FE] dark:!border-[#4C469F] dark:hover:border-[#8F81D0]",
          isSelected && "bg-[#D9EAFF] border-[#D9EAFF]  dark:bg-[#746CDB] dark:!border-[#746CDB] ",
          !isSelected && "hover:bg-[#DEEAFA] hover:border-[#C2D8F3] dark:bg-[#4C469F] dark:hover:border-[#8F81D0]",
        ]

      )}
      onClick={onClick}
      type='button'
    >
      <div className='flex items-center justify-between px-1 dark:text-white'>
        <div className='flex items-center gap-[6.2px]'>
          <div>
            <img
              className='h-[28.4px] w-[28.1px]'
              src={iconUrl}
              alt=''
            />
          </div>
          <div className='flex flex-col text-[12px] font-semibold'>
            <span>{title}</span>

            {showDescription && (
              <span className={clsxm(
                'text-[10px] text-[#8282A5] dark:text-[] leading-[0.8em] font-semibold whitespace-nowrap',
                'dark:text-[#ffffff81]'
              )}>
                {description}
              </span>
            )}
          </div>
        </div>

        <div className='w-2 h-full'>
          <div className={clsxm(
            'w-2 h-2 rounded-full bg-[#006FFF] border-[1.4px]',
            variant === "left" && [
              "bg-[#FCFAFF] border-[#E2DFFB]",
              !isSelected && "group-hover:border-[#C8C4E8] group-hover:bg-[#FFFFFF]",
              isSelected && "border-[#BFB9F0] bg-[#4C31B7] dark:bg-[#292651] dark:border-[#8F81D0]"
            ],
            variant === "right" && [
              "bg-[#FCFAFF] border-[#D9EAFF]",
              !isSelected && "group-hover:border-[#C2D8F3] group-hover:bg-[#FFFFFF]",
              isSelected && "border-[#88BBFF] bg-[#006FFF] dark:bg-[#292651] dark:border-[#8F81D0]"
            ]
          )} />
        </div>
      </div>
    </button>

  );
}

export default CollateralOptionCard;