import { Account, Address } from '@multiversx/sdk-core';
import { ExtensionProvider } from '@multiversx/sdk-extension-provider';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { toast } from 'react-hot-toast';

import { Logout } from '@/hooks/auth/useLogout';

import LogoutIcon from '@/components/Icons/Logout';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  accountSelector,
  clearAuthStates,
  setAccountState,
  setLoggingInState,
  setLoginInfoState,
} from '@/store/auth';
import {
  clearDappProvider,
  networkStateSelector,
  setNetworkState,
} from '@/store/network';

import { chainType, networkConfig } from '@/config/network';
import { nativeAuth } from '@/services/nativeAuth';
import { getNewLoginExpiresTimestamp } from '@/utils/expiresAt';
import { optionalRedirect } from '@/utils/optionalRedirect';

import { Login } from '@/types/account';
import { LoginMethodsEnum } from '@/types/enums';

export const useExtensionLogin = (params?: Login) => {
  const dispatch = useAppDispatch();
  const apiNetworkProvider = useAppSelector(
    networkStateSelector<ApiNetworkProvider>('apiNetworkProvider'),
  );
  const provider = useAppSelector(
    networkStateSelector<ExtensionProvider>('dappProvider'),
  );
  const accountSnap = useAppSelector(accountSelector);
  const providerRef = useRef<any>(provider);
  const { t } = useTranslation();

  const initiateLogin = async () => {
    const dappProvider = ExtensionProvider.getInstance();

    try {
      const isSuccessfullyInitialized: boolean = await dappProvider.init();
      dappProvider.setAddress(accountSnap.address);

      if (!isSuccessfullyInitialized) {
        return;
      }
      dispatch(setNetworkState('dappProvider', dappProvider));
    } catch (e) {
      captureException(e);
    }
  };

  const logout = async (params?: Logout) => {
    const provider = params?.dappProvider || providerRef.current;

    if (!provider) {
      dispatch(clearAuthStates());
      dispatch(clearDappProvider());
      return;
    }

    try {
      dispatch(setLoggingInState('pending', true));
      await provider.logout();

      dispatch(clearAuthStates());
      dispatch(clearDappProvider());

      if (params?.callbackRoute) {
        if (typeof params?.redirectFn === 'function') {
          params?.redirectFn(params?.callbackRoute);
        } else if (typeof window !== 'undefined') {
          window.location.href = params?.callbackRoute;
        }
      }

      dispatch(setLoggingInState('loggedIn', false));
      toast(t('logged-out'), {
        icon: (
          <div className='grid h-[18px] w-[18px] place-content-center rounded bg-[#E45059] text-white'>
            <LogoutIcon height={14} />
          </div>
        ),
        id: 'logged-out',
      });
    } catch (e: any) {
      captureException(e);
      toast.error(t('logout-error-retry'), { duration: 4000 });
      dispatch(setLoggingInState('error', e?.message));
    } finally {
      dispatch(setLoggingInState('pending', false));
    }
  };

  const login = async () => {
    const providerInstance = ExtensionProvider.getInstance();

    try {
      if (!providerInstance.isInitialized()) {
        const isSuccessfullyInitialized: boolean =
          await providerInstance.init();

        if (!isSuccessfullyInitialized) {
          return;
        }
      }

      const client = nativeAuth({
        apiAddress: networkConfig[chainType].apiAddress,
      });

      const token = await client.initialize();

      const callbackUrl: string =
        typeof window !== 'undefined'
          ? encodeURIComponent(
              `${window.location.origin}${params?.callbackRoute || ''}`,
            )
          : '/';
      const providerLoginData = {
        callbackUrl,
        token,
        // ...(params?.token && { token: params?.token }),
      };

      const providerResponse = await providerInstance.login(providerLoginData);

      if (!providerResponse) {
        return;
      }

      dispatch(setNetworkState('dappProvider', providerInstance));

      const { signature, address } = providerInstance.account;

      const userAddressInstance = new Address(address);
      const userAccountInstance = new Account(userAddressInstance);

      dispatch(setLoggingInState('pending', true));

      const userAccountOnNetwork =
        await apiNetworkProvider.getAccount(userAddressInstance);
      userAccountInstance.update(userAccountOnNetwork);

      const accountAddress = userAccountInstance.address.bech32();

      dispatch(setAccountState('address', accountAddress));

      if (signature) {
        dispatch(setLoginInfoState('signature', signature));
      }
      dispatch(setLoginInfoState('loginToken', String(token)));
      dispatch(setLoginInfoState('loginMethod', LoginMethodsEnum.extension));
      dispatch(setLoginInfoState('expires', getNewLoginExpiresTimestamp()));

      dispatch(setLoggingInState('loggedIn', Boolean(address)));

      optionalRedirect({
        callbackRoute: params?.callbackRoute,
      });
    } catch (e: any) {
      captureException(e);
      dispatch(setLoggingInState('error', `Error logging in ${e?.message}`));
    } finally {
      dispatch(setLoggingInState('pending', false));
    }
  };

  return {
    login,
    logout,
    initiateLogin,
  };
};
