import { domAnimation, LazyMotion, m } from 'framer-motion';
import React, { ReactNode } from 'react';

import Hint from '@/components/Hint';

import clsxm from '@/services/clsxm';

const Tabber: React.FC<{
  onChange: Function;
  tabs: Array<string>;
  defaultSelected?: number;
  className?: string;
  buttonTextClassName?: (selectedTabIndex: number | null) => string;
  secondary?: boolean;
  children?: ReactNode;
  customTheme?: (selectedTabIndex: number | null, index?: number) => string;
  soonTabs?: Array<number>;
}> = ({
  onChange,
  tabs = ['Tab1'],
  defaultSelected,
  buttonTextClassName,
  children,
  customTheme,
  className,
  soonTabs,
}) => {
  const act = (index: any) => {
    onChange(index);
  };

  return (
    <div className={className}>
      <div className='flex'>
        {tabs.map((t, i) => (
          <LazyMotion features={domAnimation} key={i}>
            <m.button
              data-testid={`tab:${t.toLowerCase()}`}
              type='button'
              className={clsxm(
                'flex-1 outline-none  focus:outline-none relative',

                // height
                'md:h-[21.6px] lg:h-[39px]',

                // padding
                'pt-[3.5px] pb-[7.5px]',
                'md:pt-[2.58px] md:pb-[2.58px]',
                'lg:pb-2 lg:pt-[12px] lg:pl-[1px]',

                // text
                'text-center uppercase  tracking-wide',
                'text-[10px] leading-[12.5px]',
                'md:text-[8px] md:leading-[10px]',
                'lg:text-[12px] lg:leading-tight lg:font-semibold',

                // border
                'border-b-[3.5px]',
                'md:border-b-[2.5px]',
                'lg:border-b-[4px]',

                defaultSelected === i
                  ? 'border-[#006FFF] text-[#006FFF]'
                  : 'border-current text-[#535367] dark:text-[#625E8F]',
                customTheme && customTheme(defaultSelected === i ? i : null, i),
              )}
              onTap={() => act(i)}
            >
              {soonTabs?.includes(i) && (
                <Hint
                  content="Coming Soon"
                  hidden={!soonTabs?.includes(i)}
                  className={clsxm(
                    'absolute top-0 left-0 z-10 w-full min-h-full flex-xy-center',
                    !soonTabs?.includes(i)
                      ? '!cursor-pointer'
                      : '!cursor-not-allowed',
                  )}
                >
                  <div></div>
                </Hint>
              )}

              <span
                className={clsxm(
                  'inline-block',
                  buttonTextClassName && buttonTextClassName(i),
                )}
              >
                {t}
              </span>
            </m.button>
          </LazyMotion>
        ))}
      </div>

      {children}
    </div>
  );
};

export default Tabber;
