import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import { H_TOKEN_DECIMALS, MARKET_KEY } from '@/store/protocol';
import { addAction } from '@/store/queue';

import logger from '@/utils/logger';
import { calcBorrowLimit } from '@/utils/math/market';

export const RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN = 0.99999;

const defaultToken: Token = {
  id: '0',
  balance: '0',
  priceUSD: '0',
  symbol: '',
  source: '',
  balanceUSD: '0',
  decimals: 0,
  index: 0,
};

export interface Token {
  id: string;
  symbol: string;
  priceUSD: string;
  balance: string;
  source: string;
  balanceUSD: string;
  decimals: number;
  index: number;
}

export interface LiquidStakingTaoAppState {
  address: string;
  apy: string;
  stakers: string;
  totalFee: string;
  totalLocked: string;
  totalLockedUSD: string;
  borrowBalanceUSD: string;
  borrowLimitUSD: string;
  tokens: Record<string, Token>;
  validators: Validator[];
  validatorsCount: string;
}

export enum TOKEN_SOURCE {
  wallet = 'wallet',
  collateral = 'collateral',
}

export interface Validator {
  address: string;
  name: string;
  logo: { dark: string; light: string };
  staked: string;
  website?: string;
}

const initialState: LiquidStakingTaoAppState = {
  address: '',
  apy: '0',
  stakers: '0',
  totalFee: '0',
  totalLocked: '0',
  totalLockedUSD: '0',
  borrowBalanceUSD: '0',
  borrowLimitUSD: '0',
  tokens: {
    [`${EXCHANGE_RATE_KEY.sWTAO}-${TOKEN_SOURCE.wallet}`]: {
      ...defaultToken,
      id: `${EXCHANGE_RATE_KEY.sWTAO}-${TOKEN_SOURCE.wallet}`,
      symbol: EXCHANGE_RATE_KEY.sWTAO,
      source: TOKEN_SOURCE.wallet,
      decimals: 9,
      index: 0,
    },
    [`${EXCHANGE_RATE_KEY.HsWTAO}-${TOKEN_SOURCE.wallet}`]: {
      ...defaultToken,
      id: `${EXCHANGE_RATE_KEY.HsWTAO}-${TOKEN_SOURCE.wallet}`,
      symbol: EXCHANGE_RATE_KEY.HsWTAO,
      source: TOKEN_SOURCE.wallet,
      decimals: H_TOKEN_DECIMALS,
      index: 1,
    },
    [`${EXCHANGE_RATE_KEY.HsWTAO}-${TOKEN_SOURCE.collateral}`]: {
      ...defaultToken,
      id: `${EXCHANGE_RATE_KEY.HsWTAO}-${TOKEN_SOURCE.collateral}`,
      symbol: EXCHANGE_RATE_KEY.HsWTAO,
      source: TOKEN_SOURCE.collateral,
      decimals: H_TOKEN_DECIMALS,
      index: 2,
    },
  },
  validators: [],
  validatorsCount: '0',
};

export const liquidStakingTaoAppSlice = createSlice({
  name: 'liquidStakingTaoApp',
  initialState,
  reducers: {
    setLiquidStakingTaoApp: (
      state,
      action: PayloadAction<Partial<LiquidStakingTaoAppState>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof LiquidStakingTaoAppState] = value;
      });
    },
  },
});

export const { setLiquidStakingTaoApp } = liquidStakingTaoAppSlice.actions;

export const getLiquidStakingTaoAppData =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { liquidStakingTao, userBalances, markets },
      } = getState();

      const { apy, address, totalFee, totalStaked, totalStakers } =
        liquidStakingTao;

      const borrowBalanceUSD = Object.entries(userBalances)
        .reduce((prev, [tokenKey, { borrowBalance }]) => {
          const market = markets[tokenKey as MARKET_KEY];

          const result = new DefiUtils(borrowBalance)
            .toFullDecimals(market.underlying.decimals)
            .toUSD(market.underlying.priceUSD)
            .toString();

          return prev.plus(result);
        }, new DefiUtils('0'))
        .toString();

      const borrowLimitUSD = Object.entries(userBalances)
        .reduce((acc, [tokenKey, { underlyingCollateralBalance }]) => {
          const market = markets[tokenKey as MARKET_KEY];

          const appliedCollateralFactor = new DefiUtils(
            userBalances[MARKET_KEY.USH].borrowBalance,
          ).isZero()
            ? market.collateralFactor
            : market.ushBorrowerCollateralFactor;

          const collateralBalance = calcBorrowLimit(
            underlyingCollateralBalance,
            appliedCollateralFactor,
            market.underlying.priceUSD,
            market.underlying.decimals,
          );

          return acc.plus(collateralBalance);
        }, new DefiUtils('0'))
        .toString();

      const totalLocked = new DefiUtils(totalStaked)
        .toFullDecimals(markets.WTAO?.underlying.decimals || 9)
        .toString();

      const totalLockedUSD = new DefiUtils(totalLocked)
        .toUSD(markets.WTAO?.underlying.priceUSD || 0)
        .toString();

      const validators: Validator[] = [
        {
          address: 'TAO Validator',
          name: 'TAO-Validator',
          logo: {
            dark: 'https://cdn.app.hatom.com/images/node-operators/tao-validator-dark.png',
            light:
              'https://cdn.app.hatom.com/images/node-operators/tao-validator.png',
          },
          staked: new DefiUtils(totalStaked)
            .toFullDecimals(markets.WTAO?.underlying.decimals || 0)
            .toSafeString(),
          website: 'https://taostats.io/validators/5Fy3MjrdKRvUWSuJa4Yd5dmBYunzKNmXnLcvP22NfaTvhQCY',
        },
      ];

      dispatch(
        addAction(
          setLiquidStakingTaoApp({
            apy,
            address,
            stakers: totalStakers,
            totalFee,
            borrowBalanceUSD,
            borrowLimitUSD,
            totalLocked,
            totalLockedUSD,
            validators,
            validatorsCount: validators.length.toString(),
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getLiquidStakingTaoAppData', error);
      captureException(error);
    }
  };

export const liquidStakingTaoAppSelector = (state: RootState) =>
  state.liquidStakingTaoApp;

export default liquidStakingTaoAppSlice.reducer;
