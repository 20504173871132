import DefiUtils from 'defi-utils';

import {
  Market,
  MARKET_KEY,
  TOKEN_LOGO_V2_MAP,
  UserBalance,
} from '@/store/protocol';
import {
  AccountControllerReward as RewardBatchAccountControllerReward,
  RewardBatch,
  RewardBatchState,
} from '@/store/reward-batch';

import {
  AccountControllerReward,
  ControllerRewardsBatch,
} from '@/services/blockchain/lens/types';
import multiversxSDK from '@/services/multiversx-sdk';
import { getRewardTokenAPY } from '@/utils/math/apy';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export const formatUpdatedAccountAccruedRewardsMap = (
  accountAccruedRewards: {
    id: string;
    amount: string;
  }[],
): Record<string, string> => {
  return accountAccruedRewards.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.id}`]: current.amount,
    }),
    {},
  );
};

export const formatSupplySpeedsMap = (
  supplySpeeds: {
    id: string;
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
  }[],
): Record<
  string,
  {
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
    id: string;
  }
> => {
  return supplySpeeds.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.id}`]: current,
    }),
    {},
  );
};

export const formatBorrowSpeedsMap = (
  borrowSpeeds: {
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
    id: string;
  }[],
): Record<
  string,
  {
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
    id: string;
  }
> => {
  return borrowSpeeds.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.id}`]: current,
    }),
    {},
  );
};

export const formatAccountRewardsWithoutIndexer = ({
  protocolMarkets,
  accountControllerRewards,
  rewardsPrices,
}: {
  protocolMarkets: Record<MARKET_KEY, Market>;
  accountControllerRewards: AccountControllerReward[];
  rewardsPrices: Record<string, string>;
}): RewardBatchAccountControllerReward[] => {
  const tokensIdsMap = Object.values(protocolMarkets).reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.id]: current.underlying,
    }),
    {} as Record<
      string,
      { id: string; decimals: number; symbol: string; name: string }
    >,
  );

  return accountControllerRewards.map(({ rewards, tokenId }) => {
    const {
      decimals = 18,
      symbol = '',
      name = '',
    } = tokensIdsMap[tokenId] || {};

    const priceUSD = rewardsPrices[tokenId.split('-')[0]] || '0';

    const userRewardBalanceAsBigNumber = rewards;

    const userRewardBalance = new DefiUtils(userRewardBalanceAsBigNumber)
      .toFullDecimals(decimals)
      .toString();
    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(priceUSD)
      .toString();

    return {
      tokenId,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol,
      priceUSD,
      logo:
        TOKEN_LOGO_V2_MAP[symbol] ||
        'https://cdn.app.hatom.com/images/coin_placeholder.png',
      name,
      decimals,
    };
  });
};

export const formatRewardsTokenWithoutIndexer = ({
  controllerRewardsBatches,
  rewardsPrices,
  protocolMarkets,
  totalCollateralTokensMap,
  userBalances,
}: {
  controllerRewardsBatches: ControllerRewardsBatch[];
  rewardsPrices: Record<string, string>;
  protocolMarkets: Record<MARKET_KEY, Market>;
  totalCollateralTokensMap: Record<string, string>;
  userBalances: Record<MARKET_KEY, UserBalance>;
}): RewardBatchState['markets'] => {
  const currentTime = Date.now();

  const controllerRewardsBatchesMap = controllerRewardsBatches.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.tokenId}-${current.moneyMarket}-${current.id}`]: {
        ...current,
        speed:
          currentTime >= new Date(current.endTime).getTime()
            ? '0'
            : new DefiUtils(current.speed).toFullDecimals(18).toString(),
      },
    }),
    {} as Record<string, ControllerRewardsBatch>,
  );

  const moneyMarketAddressSymbolMap = Object.values(protocolMarkets).reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.underlying.symbol,
    }),
    {} as Record<string, string>,
  );

  const rewardsTokenIds = Object.values(
    controllerRewardsBatches
      .map(({ moneyMarket, id, tokenId }) => {
        return {
          moneyMarketSymbol: moneyMarketAddressSymbolMap[moneyMarket],
          rewardBatchId: `${moneyMarket}-${id}`,
          rewardTokenId: tokenId,
        };
      })
      .reduce(
        (prev, { moneyMarketSymbol, rewardBatchId, rewardTokenId }) => {
          const key = `${moneyMarketSymbol}-${rewardTokenId}`;

          return {
            ...prev,
            [key]: {
              moneyMarketSymbol,
              rewardsTokenIds: [
                ...(prev[key]?.rewardsTokenIds || []),
                {
                  rewardBatchId,
                  rewardTokenId,
                },
              ],
            },
          };
        },
        {} as Record<
          string,
          {
            moneyMarketSymbol: string;
            rewardsTokenIds: {
              rewardBatchId: string;
              rewardTokenId: string;
            }[];
          }
        >,
      ),
  );

  const tokensMap = Object.values(protocolMarkets).reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.id]: current.underlying,
    }),
    {} as Record<
      string,
      {
        decimals: number;
        symbol: string;
        name: string;
      }
    >,
  );

  return rewardsTokenIds
    .filter((item) => item && item.moneyMarketSymbol)
    .map((item) => {
      const hTokenExchangeRate =
        protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]
          ?.hTokenExchangeRate || '0';

      const tokenId = item.moneyMarketSymbol;

      const underlying = protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]
        ?.underlying || {
        id: '',
        symbol: '',
        decimals: 0,
        name: '',
        priceUSD: '',
      };

      const totalBorrows =
        protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]?.totalBorrow ||
        '0';

      const hTokenId =
        protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]?.hToken?.id || '';

      const totalCollateralTokens = totalCollateralTokensMap[hTokenId] || '0';

      const { collateralBalance, hTokenBalance } = userBalances[
        item.moneyMarketSymbol as MARKET_KEY
      ] || { collateralBalance: '0', hTokenBalance: '0' };

      const totalHtokenBalance = new DefiUtils(collateralBalance)
        .plus(hTokenBalance)
        .toString();

      const rewards = item.rewardsTokenIds
        .map((rewardTokenIdItem) => {
          const {
            decimals = 18,
            symbol = '',
            name = '',
          } = tokensMap[rewardTokenIdItem.rewardTokenId] || {};

          const controllerRewardBatchKey = `${rewardTokenIdItem.rewardTokenId}-${rewardTokenIdItem.rewardBatchId}`;

          const { speed = '0', marketType: type = '' } =
            controllerRewardsBatchesMap[controllerRewardBatchKey] || {};

          const speedPerDay = new DefiUtils(speed)
            .multipliedBy(DefiUtils.SECONDS_PER_DAY)
            .toString();

          const priceUSD =
            rewardsPrices[rewardTokenIdItem.rewardTokenId.split('-')[0]] || '0';
          const distributedDollarAmountPerDay = new DefiUtils(1)
            .multipliedBy(speedPerDay)
            .dividedBy(`1e${decimals}`)
            .multipliedBy(priceUSD)
            .toString();

          const logo =
            TOKEN_LOGO_V2_MAP[symbol] ||
            'https://cdn.app.hatom.com/images/coin_placeholder.png';

          const totalUSD =
            type === 'Supply'
              ? new DefiUtils(totalCollateralTokens)
                  .toUnderlying(hTokenExchangeRate)
                  .toFullDecimals(underlying.decimals)
                  .toUSD(underlying.priceUSD)
                  .toString()
              : new DefiUtils(totalBorrows)
                  .toFullDecimals(underlying.decimals)
                  .toUSD(underlying.priceUSD)
                  .toString();

          const apy = getRewardTokenAPY(
            distributedDollarAmountPerDay,
            totalUSD,
          );

          const userSupplyApy = new DefiUtils(apy)
            .multipliedBy(
              new DefiUtils(collateralBalance)
                .multipliedBy(100)
                .dividedBy(totalHtokenBalance)
                .dividedBy(100),
            )
            .toSafeString();

          const userApy =
            type === 'Supply' ||
            !new DefiUtils(totalHtokenBalance).isEqualTo(collateralBalance)
              ? userSupplyApy
              : apy;

          return {
            type,
            speed,
            tokenId: rewardTokenIdItem.rewardTokenId,
            batchId: rewardTokenIdItem.rewardBatchId,
            symbol,
            priceUSD,
            logo,
            name,
            decimals: String(decimals),
            apy,
            userApy,
          } as RewardBatch;
        })
        .filter((item) => item.symbol !== '-');

      const totalRewardsBorrowAPY = rewards
        .filter(({ type }) => type === 'Borrow')
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString();

      const totalRewardsSupplyAPY = rewards
        .filter(({ type }) => type === 'Supply')
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString();

      const userRewardsBorrowAPY = rewards
        .filter(({ type }) => type === 'Borrow')
        .reduce(
          (prev, current) => prev.plus(current.userApy),
          new DefiUtils('0'),
        )
        .toString();

      const userRewardsSupplyAPY = rewards
        .filter(({ type }) => type === 'Supply')
        .reduce(
          (prev, current) => prev.plus(current.userApy),
          new DefiUtils('0'),
        )
        .toString();

      return {
        tokenId,
        totalRewardsBorrowAPY,
        totalRewardsSupplyAPY,
        userRewardsBorrowAPY,
        userRewardsSupplyAPY,
        rewards,
      };
    })
    .reduce((prev, { tokenId, ...rest }) => {
      return {
        ...prev,
        [tokenId]: { ...rest },
      };
    }, {}) as Record<MARKET_KEY, Market>;
};

export const getBalanceByIdentifiers = async (
  controllerAddress: string,
  identifiers: string[],
): Promise<Record<string, string>> => {
  if (!controllerAddress) {
    return {} as Record<string, string>;
  }

  const accountTokens = await queryClient.fetchQuery({
    queryKey: ['accountTokens', controllerAddress, identifiers.join(',')],
    queryFn: () =>
      multiversxSDK.accountTokens(controllerAddress, {
        identifiers: identifiers.join(','),
      }),
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  });

  const hTokensIdsMap = accountTokens.reduce(
    (prev, { name, balance }) => ({
      ...prev,
      [`${name.replace('Hatom', 'H')}-`]: balance,
    }),
    {},
  ) as Record<string, string>;

  const wrappedIdsMap = accountTokens.reduce(
    (prev, { name, balance }) => ({
      ...prev,
      [`${name.replace('Wrapped', '')}-`]: balance,
    }),
    {},
  ) as Record<string, string>;

  const result = identifiers.reduce(
    (prev, currentIdenfitier) => {
      const balanceByIdentifier = accountTokens.find(
        ({ identifier }) => identifier === currentIdenfitier,
      )?.balance;
      const balanceByAsset = accountTokens.find(
        ({ assets }) => assets?.pngUrl?.includes(currentIdenfitier),
      )?.balance;
      const balanceByHToken =
        hTokensIdsMap[`${currentIdenfitier.split('-')?.[0] || ''}-`];

      const balanceByWrapped =
        wrappedIdsMap[`${currentIdenfitier.split('-')?.[0] || ''}-`];

      return {
        ...prev,
        [currentIdenfitier]:
          balanceByIdentifier ||
          balanceByAsset ||
          balanceByHToken ||
          balanceByWrapped ||
          '0',
      };
    },
    {} as Record<string, string>,
  );

  return result;
};

export const getAccountMarketsIds = (
  markets: Record<string, Market>,
  accountAddress: string,
) => {
  return accountAddress
    ? [
        // @ts-ignore
        ...new Set(
          Object.values(markets)
            .filter((market) => market.supported)
            .map(({ address }) => `${address}-${accountAddress}`),
        ),
      ]
    : [];
};

export const getAccumulatorsPairsIds = (
  accumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[],
) => {
  return accumulators
    .map(({ rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];

      return tokensIds
        .slice(0, -1)
        .map((tokenId, index) => `${tokenId}/${tokensIds[index + 1]}`);
    })
    .flat();
};

export const formatControllerAccumulators = ({
  accumulators,
  pairs,
}: {
  accumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[];
  pairs: {
    pairsIds: string;
    exchangeRate: string;
  }[];
}) => {
  const pairsMap = pairs.reduce(
    (prev, { pairsIds, exchangeRate }) => ({
      ...prev,
      [pairsIds]: exchangeRate,
    }),
    {} as Record<string, string>,
  );

  const boosterBoosters = accumulators.reduce(
    (prev, { premium, rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];
      const exchangeRates = tokensIds
        .slice(0, -1)
        .map(
          (tokenId, index) =>
            pairsMap[`${tokenId}/${tokensIds[index + 1]}`] || '0',
        );

      const exchangeRate = exchangeRates
        .reduce((prev, current) => prev.multipliedBy(current), new DefiUtils(1))
        .toString();

      return {
        ...prev,
        [rewardsToken.symbol]: {
          premium,
          exchangeRate,
        },
      };
    },
    {} as Record<string, { premium: string; exchangeRate: string }>,
  );

  return boosterBoosters;
};
