import DefiUtils from 'defi-utils';

const limitMaxGasFee = (value: number) => {
  const MAX_GAS_FEE = 600_000_000;

  if (new DefiUtils(value).isGreaterThan(MAX_GAS_FEE)) {
    return MAX_GAS_FEE;
  }

  return value;
};

const gasLimitMap = () => {
  return {
    'governance.proposeTransferExecute': () => 140_000_000,
    'governance.publishProposal': () => 140_000_000,
    'governance.upvote': () => 14_000_000,
    'governance.downvote': () => 14_000_000,
    'governance.redeem': () => 14_000_000,

    'liquid.delegate': () => 45_000_000,
    'liquid.unDelegate': () => 45_000_000,
    'liquid.lendegate': () => 250_000_000,
    'liquid.unlendegate': () => 78_000_000,
    'liquid.withdraw': () => 12_000_000,

    'liquid-tao.delegate': () => 45_000_000,
    'liquid-tao.unDelegate': () => 45_000_000,
    'liquid-tao.lendegate': () => 250_000_000,
    'liquid-tao.unlendegate': () => 78_000_000,

    'market.mint': () => 50_000_000,
    'market.mintAndEnterMarket': () => 300_000_000,
    'market.enterMarkets': () => 250_000_000,
    'market.redeem': () => 20_000_000,
    'market.repayBorrow': () => 400_000_000,
    'market.claimRewards': () => 500_000_000,
    'market.removeAccountMarket': () => 30_000_000,
    'market.exitMarketAndRedeem': () => 400_000_000,

    'booster-v2.stake': () => 500_000_000,
    'booster-v2.unstake': () => 500_000_000,
    'booster-v2.reallocate': () => 400_000_000,
    'booster-v2.claimRewards': () => 500_000_000,
    'booster-v2.claimRewardsBoosterV2': () => 500_000_000,

    'booster.rebalancePortfolio': () => 525_000_000,
    'booster.stake': () => 500_000_000,
    'booster.unstake': () => 500_000_000,
    'booster.claimRewards': () => 600_000_000,
    'booster.claim': () => 100_000_000,
    'token-vesting.claimTokens': () => 15_000_000,
    'account-manager-deployer.deployAccountManager': () => 500_000_000,

    'market.borrow': (marketAmount: number) => {
      const base = 200_000_000;
      const perMarket = 40_000_000;
      const extraGasLimit = new DefiUtils(perMarket).multipliedBy(marketAmount);

      return limitMaxGasFee(new DefiUtils(base).plus(extraGasLimit).toNumber());
    },
    'market.exitMarket': (marketAmount: number) => {
      const base = 150_000_000;
      const perMarket = 40_000_000;
      const extraGasLimit = new DefiUtils(perMarket).multipliedBy(marketAmount);

      return limitMaxGasFee(new DefiUtils(base).plus(extraGasLimit).toNumber());
    },
  };
};

export default gasLimitMap;
