import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/index';

export interface DropdownPayload {
  name: string;
  parentButtonRef: any;
  className?: string;
  data?: any;
}

export interface DropdownState {
  name: string;
  isOpen: boolean;
  data: any;
  className?: string;
  parentButtonRef: any;
}

const initialState: DropdownState = {
  name: '',
  isOpen: false,
  data: {},
  parentButtonRef: undefined,
};

export const dropdownSlice = createSlice({
  name: 'dropdown',
  initialState,
  reducers: {
    openDropdown: (state, action: PayloadAction<DropdownPayload>) => {
      state.isOpen = true;
      state.name = action.payload.name;
      state.data = action.payload.data || {};
      state.className = action.payload.className;
      state.parentButtonRef = action.payload.parentButtonRef;
    },

    closeDropdown: (state) => {
      state.name = '';
      state.isOpen = false;
      state.data = {};
      state.className = '';
      state.parentButtonRef = undefined;
    },
  },
});

export const { openDropdown, closeDropdown } = dropdownSlice.actions;

export const dropdownSelector = (state: RootState) => state.dropdown;

export default dropdownSlice.reducer;
