import { useEffect } from 'react';

import { accountSelector } from '@/store/auth';
import { boosterV2Selector } from '@/store/booster-v2';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  lendAppSelector,
  updateAccountMarket,
  updateAccountMarketNetAPY,
} from '@/store/lend-app';
import { calculateNetAPY } from '@/store/parsers/lend-app-parser';
import { protocolSelector } from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';


const useStoreAccountMarket = () => {
  const dispatch = useAppDispatch();

  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    markets: protocolMarkets,
    userBalances,
    accountDiscountRateModel,
  } = useAppSelector(protocolSelector);
  const {
    address: accountAddress,
    proxyAddress,
    selectedTypeAddress,
  } = useAppSelector(accountSelector);
  const { markets } = useAppSelector(lendAppSelector);
  const { controllerRewardBatches } = useAppSelector(boosterV2Selector);
  const { showLiquidStakingAPY, showLiquidStakingTaoAPY } =
    useAppSelector(lendAppSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);

  useEffect(() => {
    dispatch(updateAccountMarket());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets]);

  useEffect(() => {
    const accountsBoosterAPYMap = controllerRewardBatches.reduce(
      (prev, { account, tokenId }) => {
        return {
          ...prev,
          [tokenId]: account.totalAPY,
        };
      },
      {} as Record<string, string>,
    );

    const netAPY = calculateNetAPY({
      markets: protocolMarkets,
      rewardMarkets,
      liquidStakingAPY,
      showLiquidStakingAPY,
      liquidStakingTaoAPY,
      showLiquidStakingTaoAPY,
      accountsBoosterAPYMap,
      accountDiscountRateModel,
      userBalances,
    });

    dispatch(updateAccountMarketNetAPY(netAPY));
  }, [
    rewardMarkets,
    liquidStakingAPY,
    showLiquidStakingAPY,
    protocolMarkets,
    dispatch,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    accountDiscountRateModel,
    controllerRewardBatches,
    userBalances,
    accountAddress,
    proxyAddress,
    selectedTypeAddress,
  ]);
};

export default useStoreAccountMarket;
