import DefiUtils from 'defi-utils';
import { useEffect } from 'react';

import { ReturnUseSupplyData } from '@/components/popups/SupplyPopup/hooks/useSupplyData';
import { ReturnUseSupplyForm } from '@/components/popups/SupplyPopup/hooks/useSupplyForm';

import { sleep } from '@/utils/helpers';

const useSupplyListenerFormFields = ({
  supplyForm,
  supplyData,
}: {
  supplyForm: ReturnUseSupplyForm;
  supplyData: ReturnUseSupplyData;
}) => {
  const {
    handleInputFocus,
    maxSelected,
    isAddingCollateral,
    selectedTab,
    isRemovingCollateralAndWithdraw,
    isAddingSupplyAndCollateral,
    setInputValue,
    setInputValueAsHTokenBigNumber,
    setInputValueAsBigNumber,
    setMaxSelected,
    setIsAddingSupplyAndCollateral,
    setIsRemovingCollateralAndWithdraw,
  } = supplyForm;
  const { market, maxRequestAmounts } = supplyData;

  useEffect(() => {
    setMaxSelected(false);
    setIsAddingSupplyAndCollateral(false);
    setIsRemovingCollateralAndWithdraw(false);
    // eslint-disable-next-line
  }, [selectedTab, isAddingCollateral]);

  useEffect(() => {
    handleInputFocus();
    setMaxSelected(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTab,
    isAddingCollateral,
    isRemovingCollateralAndWithdraw,
    isAddingSupplyAndCollateral,
  ]);

  useEffect(() => {
    const handleMax = async () => {
      if (!maxSelected) {
        setInputValue('');
        setInputValueAsHTokenBigNumber('');
        setInputValueAsBigNumber('');


      await sleep(50)
  
      handleInputFocus()
        return;
      }
  
      const newInputValue = new DefiUtils(maxRequestAmounts.maxValue)
        .toFullDecimals(market.underlying.decimals)
        .toFixed(market.underlying.decimals, DefiUtils.ROUND_DOWN);
  
      const decimalsPlaces = new DefiUtils(newInputValue).decimalPlaces() || 0;
  
      const formattedInputValue = new DefiUtils(newInputValue).toFixed(
        decimalsPlaces,
        DefiUtils.ROUND_DOWN,
      );
  
      const inputValueAsHToken = new DefiUtils(maxRequestAmounts.maxValue)
        .toTokens(market.hTokenExchangeRate)
        .toString();
  
      if (new DefiUtils(maxRequestAmounts.maxValue).isLessThanOrEqualTo(0)) {
        setInputValue('');
        setInputValueAsHTokenBigNumber('');
        setInputValueAsBigNumber('');
      } else {
        setInputValue(formattedInputValue);
        setInputValueAsHTokenBigNumber(inputValueAsHToken);
        setInputValueAsBigNumber(maxRequestAmounts.maxValue);
      }

      await sleep(50)
  
      handleInputFocus()
    }

    handleMax()
    // eslint-disable-next-line
  }, [maxSelected]);
};

export default useSupplyListenerFormFields;
