import DefiUtils from 'defi-utils';

import { MARKET_KEY } from '@/store/protocol';

import {
  QueryHTokenBalance,
  QueryTokenPricesV2,
  ResponseHTokenBalance,
  ResponseTokenPricesTuple,
} from '@/services/indexer/common/types';

export const formatHTokenBalance = ({
  getAccount,
}: QueryHTokenBalance): ResponseHTokenBalance => {
  return (getAccount?.markets || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.market.underlying.symbol]: current.tokens,
    }),
    {},
  );
};

export const formatTokenPricesV2 = (
  { queryAggregatorPrice }: QueryTokenPricesV2,
  liquidStakingExchangeRate: string,
  liquidStakingTaoExchangeRate: string,
) => {
  const formattedAggregatorPrices = queryAggregatorPrice.map(
    ({ id, price }) => {
      const symbol = id.split('-')?.[0] || '-';

      return {
        symbol,
        price: new DefiUtils(price).toFullDecimals(18).toString(),
      };
    },
  );

  const tuppleAggregatorPricesMap = formattedAggregatorPrices.reduce(
    (prev, { symbol, price }) => ({
      ...prev,
      [symbol]: [...(prev[symbol] || []), price],
    }),
    {} as Record<string, string[]>,
  );

  const aggregatorPrices = Object.entries(tuppleAggregatorPricesMap).map(
    ([symbol, prices]) => ({
      symbol,
      price: prices.find((price) => price !== '0') || '0',
    }),
  );

  const aggregatorPricesEGLD = aggregatorPrices.find(
    ({ symbol }) => symbol === 'EGLD',
  );

  const aggregatorPricesWTAO = aggregatorPrices.find(
    ({ symbol }) => symbol === 'WTAO',
  );

  return [
    ...aggregatorPrices,
    {
      symbol: MARKET_KEY.sEGLD,
      price: new DefiUtils(1)
        .toUnderlying(liquidStakingExchangeRate)
        .toUSD(aggregatorPricesEGLD?.price || '0')
        .toSafeString(),
    },
    {
      symbol: MARKET_KEY.sWTAO,
      price: new DefiUtils(1)
        .toUnderlying(liquidStakingTaoExchangeRate)
        .toUSD(aggregatorPricesWTAO?.price || '0')
        .toSafeString(),
    },
  ].reduce(
    (prev, current) => ({
      ...prev,
      [current.symbol]: current.price,
    }),
    {} as Record<string, string>,
  );
};

export const formatTokenPricesTuple = (
  ids: string[],
  todayPricesMap: Record<string, string>,
  yesterdayPricesMap: Record<string, string>,
): ResponseTokenPricesTuple => {
  const variation24hPercentMap = ids.reduce(
    (prev, current) => {
      const todayPriceUSD = todayPricesMap[current] || '0';
      const yesterdayPriceUSD = yesterdayPricesMap[current] || '0';

      const variation = new DefiUtils(todayPriceUSD)
        .multipliedBy(100)
        .dividedBy(yesterdayPriceUSD)
        .minus(100);

      return {
        ...prev,
        [current]: new DefiUtils(
          variation.toSafeString(),
        ).removeScientificNotation(),
      };
    },
    {} as Record<string, string>,
  );

  return [todayPricesMap, yesterdayPricesMap, variation24hPercentMap];
};
