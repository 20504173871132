import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/index';

export enum TOKEN_KEY {
  sEGLD = 'sEGLD',
  HsEGLD = 'HsEGLD',
  xEGLD = 'xEGLD',
}

const TOKEN_KEY_LIST = [TOKEN_KEY.sEGLD, TOKEN_KEY.HsEGLD, TOKEN_KEY.xEGLD];

export const defaultToken: Token = {
  staked: '0',
  stakers: '0',
  apr: '0',
};

export interface Token {
  staked: string;
  stakers: string;
  apr: string;
}

export interface LiquidStakingLandingState {
  tokens: Record<string, Token>;
}

const initialState: LiquidStakingLandingState = {
  tokens: TOKEN_KEY_LIST.reduce(
    (prev, current) => ({
      ...prev,
      [current]: defaultToken,
    }),
    {} as Record<string, Token>
  ),
};

export const liquidStakingLandingSlice = createSlice({
  name: 'liquidStakingLanding',
  initialState,
  reducers: {
    setLiquidStakingLanding: (
      state,
      action: PayloadAction<Partial<LiquidStakingLandingState>>
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof LiquidStakingLandingState] = value;
      });
    },

    setTokens: (
      state,
      action: PayloadAction<Record<string, Partial<Token>>>
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state.tokens[key as keyof Record<string, Token>] = {
          ...state.tokens[key],
          ...value,
        };
      });
    },
  },
});

export const { setLiquidStakingLanding, setTokens } = liquidStakingLandingSlice.actions;

export const liquidStakingLandingSelector = (state: RootState) =>
  state.liquidStakingLanding;

export default liquidStakingLandingSlice.reducer;
