import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatAccountRewardsWithoutIndexer,
  formatControllerAccumulators,
  formatRewardsTokenWithoutIndexer,
  getAccumulatorsPairsIds,
  getBalanceByIdentifiers,
} from '@/store/parsers/reward-batch-parser';
import { MARKET_KEY } from '@/store/protocol';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import * as xexchangeService from '@/services/xexchange';
import logger from '@/utils/logger';

export interface RewardBatch {
  batchId: string;
  tokenId: string;
  type: 'Borrow' | 'Supply';
  speed: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: string;
  apy: string;
  userApy: string;
}

export interface AccountControllerReward {
  tokenId: string;
  userRewardBalance: string;
  userRewardBalanceUSD: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: number;
}

export interface Market {
  totalRewardsBorrowAPY: string;
  totalRewardsSupplyAPY: string;
  userRewardsBorrowAPY: string;
  userRewardsSupplyAPY: string;
  rewards: RewardBatch[];
}

export interface RewardBatchState {
  markets: Record<MARKET_KEY, Market>;
  accountRewards: AccountControllerReward[];
  controllerAccumulators: {
    [key: string]: {
      exchangeRate: string;
      premium: string;
    };
  };
}

export const fakeMarketsRewards: Record<string, Market> = {};

const initialState: RewardBatchState = {
  markets: {} as Record<MARKET_KEY, Market>,
  accountRewards: [],
  controllerAccumulators: {},
};

export const rewardBatchSlice = createSlice({
  name: 'rewardBatch',
  initialState,
  reducers: {
    setRewardBatch: (
      state,
      action: PayloadAction<Partial<RewardBatchState>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof RewardBatchState] = value;
      });
    },
  },
});

export const { setRewardBatch } = rewardBatchSlice.actions;

export const getRewardsBatchData =
  ({ accountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: {
          controller: { address: controllerAddress },
          markets: protocolMarkets,
          userBalances,
        },
      } = getState();

      dispatch(getControllerAccumulators());

      const [
        controllerRewardsBatches,
        accountControllerRewards,
        totalCollateralTokensMap,
      ] = await Promise.all([
        blockchainService.lens.getControllerRewardsBatches(),
        blockchainService.lens.getAccountControllerRewards(accountAddress),
        getBalanceByIdentifiers(controllerAddress, [
          ...(new Set(
            Object.values(protocolMarkets).map(({ hToken }) => hToken.id),
          ) as any),
        ]),
      ]);

      const rewardsPrices = Object.values(protocolMarkets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.symbol]: current.underlying.priceUSD,
        }),
        {} as Record<string, string>,
      );

      await dispatch(
        addAction(
          setRewardBatch({
            markets: formatRewardsTokenWithoutIndexer({
              controllerRewardsBatches,
              rewardsPrices,
              protocolMarkets,
              totalCollateralTokensMap,
              userBalances,
            }),
            accountRewards: formatAccountRewardsWithoutIndexer({
              protocolMarkets,
              accountControllerRewards,
              rewardsPrices,
            }),
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getRewardsBatch', error);
      captureException(error);
    }
  };

/**
 * WARNING:this function does not wait for the result (save time)
 */
const getControllerAccumulators =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { markets },
      } = getState();
      const controllerAccumulators =
        await blockchainService.lens.getControllerAccumulators();

      const tokensMap = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.id]: current.underlying,
        }),
        {} as Record<
          string,
          {
            id: string;
            name: string;
            decimals: number;
            symbol: string;
          }
        >,
      );

      const accumulators = controllerAccumulators.map(
        ({ premium, tokenId, swapPath }) => {
          return {
            premium,
            rewardsToken: {
              id: tokenId,
              symbol: tokensMap[tokenId]?.symbol || '',
            },
            swapPath: swapPath.map(({ outputTokenId }) => ({
              id: outputTokenId,
            })),
          };
        },
      );

      const pairsIds = getAccumulatorsPairsIds(accumulators);

      // @ts-ignore
      const uniquePairsIds = [...new Set(pairsIds)];

      const pairs = await xexchangeService.getPairs(uniquePairsIds);

      await dispatch(
        setRewardBatch({
          controllerAccumulators: formatControllerAccumulators({
            pairs,
            accumulators,
          }),
        }),
      );
    } catch (error) {
      logger.error('store:getControllerBatch', error);
      captureException(error);
    }
  };

export const rewardBatchSelector = (state: RootState) => state.rewardBatch;

export default rewardBatchSlice.reducer;
