import { createContext, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setBoosterV2 } from '@/store/booster-v2';
import { protocolSelector } from '@/store/protocol';

const BoosterV2Context = createContext({});

const BoosterV2Consumer = BoosterV2Context.Consumer;

type BoosterV2ProviderProps = {
  children: React.ReactNode;
};

const BoosterV2Provider = ({ children }: BoosterV2ProviderProps) => {
  const { hasFirstInfo } = useAppSelector(protocolSelector);
  const dispatch = useAppDispatch();

  const [internalCurrentBatch, setInternalCurrentBatch] = useState(0);

  useEffect(() => {
    if (!hasFirstInfo) {
      return;
    }

    const intervalId = setInterval(() => {
      setInternalCurrentBatch((prev) => prev + 1);
    }, 10_000);

    return () => {
      clearInterval(intervalId);
    };
  }, [hasFirstInfo]);

  useEffect(() => {
    dispatch(
      setBoosterV2({
        currentBatch: internalCurrentBatch,
      }),
    );
  }, [internalCurrentBatch]);

  return (
    <BoosterV2Context.Provider value={{}}>{children}</BoosterV2Context.Provider>
  );
};

export default BoosterV2Context;
export { BoosterV2Consumer, BoosterV2Provider };
