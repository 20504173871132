import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { get } from 'lodash';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useBoosterInteraction from '@/hooks/interaction/useBoosterInteraction';
import useBoosterV2Interaction from '@/hooks/interaction/useBoosterV2Interaction';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';
import useTheme from '@/hooks/useTheme';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';
import OperationFormBottomAlerts from '@/components/OperationFormBottomAlerts';
import PopupBed from '@/components/popups/PopupBed';
import ClaimRewardButton from '@/components/popups/RewardsPopup/ClaimRewardButton';
import Reward from '@/components/popups/RewardsPopup/components/Reward';
import RewardSkeleton from '@/components/popups/RewardsPopup/components/RewardSkeleton';
import Separator from '@/components/popups/RewardsPopup/components/Separator';
import FilterButton from '@/components/popups/RewardsPopup/FilterButton';
import SlippageSettingsPopupContent from '@/components/popups/RewardsPopup/SlippageSettingsPopupContent';
import TransactionButton from '@/components/TransactionButton';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { useAppDispatch, useAppSelector } from '@/store';
import { boosterSelector, setBooster } from '@/store/booster';
import { boosterV2Selector } from '@/store/booster-v2';
import { getRouteGroup } from '@/store/popup';
import { hasEnoughEGLDBalanceSelector, protocolSelector } from '@/store/protocol';
import {
  htmMarketSelector,
  MARKET_KEY,
  usdcMarketSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

const calcRewardsWithPremium = (
  rewards: string,
  exchangeRate: string,
  decimals: number,
  premium: string,
) => {
  const rewardsInHtmBasicUnits = new DefiUtils(rewards)
    .multipliedBy(exchangeRate)
    .toBasicUnits(decimals)
    .toString();

  const calc1 = new DefiUtils(DefiUtils.WAD).plus(premium);
  const calc2 = new DefiUtils(rewardsInHtmBasicUnits).dividedBy(DefiUtils.WAD);

  const rewardsWithPremium = calc1
    .multipliedBy(calc2)
    .toFullDecimals(decimals)
    .toString();

  return rewardsWithPremium;
};

const calcRewardsWithSlippage = (rewards: string, slippage: string) => {
  const rewardsWithSlippage = new DefiUtils(rewards)
    .multipliedBy(new DefiUtils(100).minus(slippage))
    .dividedBy(100)
    .toString();

  return rewardsWithSlippage;
};

const RewardsPopup = () => {
  const [showSlippageSettings, setShowSlippageSettings] = useState(false);
  const { claimRewards: claimControllerRewards } = useLendInteraction();
  const { claimRewards: claimBoosterRewards } = useBoosterInteraction();
  const { claimRewards: claimBoosterRewardsV2 } = useBoosterV2Interaction();
  const dispatch = useAppDispatch();
  const {
    markets: rewardMarkets,
    accountRewards: controllerAccountRewards,
    controllerAccumulators,
  } = useAppSelector(rewardBatchSelector);
  const {
    boosterAccumulators,
    slippage,
    accountRewards: boosterRewardsTokens,
    accountBoosterMarkets,
  } = useAppSelector(boosterSelector);
  const {
    controllerAccountRewards: controllerAccountRewardsV2,
    controllerAccumulators: controllerAccumulatorsV2,
    controllerAccountRewardsPerMarket,
  } = useAppSelector(boosterV2Selector);
  const { isLoadingFirstInfo } = useAppSelector(protocolSelector);
  const usdMarket = useAppSelector(usdcMarketSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const isLargeScreen = useTailwindBreakpoint('lg');
  const isMediumScreen = useTailwindBreakpoint('md');
  const { isDark } = useTheme();
  const htmMarket = useAppSelector(htmMarketSelector);

  const { signTransactions } = useSignMultipleTransactions();
  const { t } = useTranslation();

  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector)

  const totalRewards = useMemo(() => {
    const data = [
      ...boosterRewardsTokens,
      ...controllerAccountRewards,
      ...controllerAccountRewardsV2,
    ];

    return Object.values(
      data.reduce((prev, current) => {
        return {
          ...prev,
          [current.symbol]: {
            ...current,
            userRewardBalance: new DefiUtils(
              get(prev, `${current.symbol}.userRewardBalance`, 0),
            )
              .plus(current.userRewardBalance)
              .toString(),
            userRewardBalanceUSD: new DefiUtils(
              get(prev, `${current.symbol}.userRewardBalanceUSD`, 0),
            )
              .plus(current.userRewardBalanceUSD)
              .toString(),
          },
        };
      }, {}),
    ) as {
      decimals: string;
      logo: string;
      name: string;
      priceUSD: string;
      speed: string;
      symbol: string;
      tokenId: string;
      type: string;
      userRewardBalance: string;
      userRewardBalanceUSD: string;
    }[];
  }, [
    boosterRewardsTokens,
    controllerAccountRewardsV2,
    controllerAccountRewards,
  ]);

  const hasBoosterReward = useMemo(() => {
    return !boosterRewardsTokens
      .reduce(
        (prev, current) => prev.plus(current.userRewardBalanceUSD),
        new DefiUtils(0),
      )
      .isZero();
  }, [boosterRewardsTokens]);

  const hasControllerReward = useMemo(() => {
    return !controllerAccountRewards
      .reduce(
        (prev, current) => prev.plus(current.userRewardBalanceUSD),
        new DefiUtils(0),
      )
      .isZero();
  }, [controllerAccountRewards]);

  const hasControllerRewardV2 = useMemo(() => {
    return !controllerAccountRewardsV2
      .reduce(
        (prev, current) => prev.plus(current.userRewardBalanceUSD),
        new DefiUtils(0),
      )
      .isZero();
  }, [controllerAccountRewardsV2]);

  const totalBoosterRewardEarnedInHtm = useMemo(() => {
    return boosterRewardsTokens
      .map(({ symbol, userRewardBalance, decimals }) => {
        const { premium, exchangeRate } = boosterAccumulators[symbol] || {
          premium: '0',
          exchangeRate: '0',
        };

        const rewardsWithPremium = calcRewardsWithPremium(
          userRewardBalance,
          exchangeRate,
          decimals,
          premium,
        );

        const rewardsWithSlippage = calcRewardsWithSlippage(
          rewardsWithPremium,
          slippage,
        );

        return {
          symbol,
          total: rewardsWithSlippage,
        };
      })
      .reduce((prev, current) => prev.plus(current.total), new DefiUtils(0))
      .toString();
  }, [boosterAccumulators, boosterRewardsTokens, slippage]);

  const totalControllerRewardEarnedInHtm = useMemo(() => {
    return controllerAccountRewards
      .map(({ symbol, userRewardBalance, decimals }) => {
        const { premium, exchangeRate } = controllerAccumulators[symbol] || {
          premium: '0',
          exchangeRate: '0',
        };

        const rewardsWithPremium = calcRewardsWithPremium(
          userRewardBalance,
          exchangeRate,
          decimals,
          premium,
        );

        const rewardsWithSlippage = calcRewardsWithSlippage(
          rewardsWithPremium,
          slippage,
        );

        return {
          symbol,
          total: rewardsWithSlippage,
        };
      })
      .reduce((prev, current) => prev.plus(current.total), new DefiUtils(0))
      .toString();
  }, [controllerAccumulators, controllerAccountRewards, slippage]);

  const totalControllerRewardV2EarnedInHtm = useMemo(() => {
    return controllerAccountRewardsV2
      .map(({ symbol, userRewardBalance, decimals }) => {
        const { premium, exchangeRate } = controllerAccumulatorsV2[symbol] || {
          premium: '0',
          exchangeRate: '0',
        };

        const rewardsWithPremium = calcRewardsWithPremium(
          userRewardBalance,
          exchangeRate,
          decimals,
          premium,
        );

        const rewardsWithSlippage = calcRewardsWithSlippage(
          rewardsWithPremium,
          slippage,
        );

        return {
          symbol,
          total: rewardsWithSlippage,
        };
      })
      .reduce((prev, current) => prev.plus(current.total), new DefiUtils(0))
      .toString();
  }, [controllerAccumulatorsV2, controllerAccountRewardsV2, slippage]);

  const totalEarnedInHtm = useMemo(() => {
    return new DefiUtils(totalBoosterRewardEarnedInHtm)
      .plus(totalControllerRewardEarnedInHtm)
      .plus(totalControllerRewardV2EarnedInHtm)
      .toString();
  }, [
    totalBoosterRewardEarnedInHtm,
    totalControllerRewardEarnedInHtm,
    totalControllerRewardV2EarnedInHtm,
  ]);

  const xExchangeHtmPriceUSD = useMemo(() => {
    return new DefiUtils(1)
      .dividedBy(
        boosterAccumulators?.[usdMarket.underlying.symbol]?.exchangeRate || 0,
      )
      .toString();
  }, [boosterAccumulators, usdMarket.underlying.symbol]);

  const totalEarnedInUsd = useMemo(
    () =>
      totalRewards
        .reduce(
          (prev, { userRewardBalanceUSD = '0' }) =>
            prev.plus(userRewardBalanceUSD),
          new DefiUtils('0'),
        )
        .toString(),
    [totalRewards],
  );

  const hasInteractionWithTheProtocol = useMemo<boolean>(
    () =>
      Object.values(rewardMarkets).filter(({ rewards }) => rewards.length)
        .length > 0 || new DefiUtils(totalEarnedInUsd).isGreaterThan('0'),
    [rewardMarkets, totalEarnedInUsd],
  );

  const hasEarnings = useMemo<boolean>(
    () => new DefiUtils(totalEarnedInUsd).isGreaterThan('0'),
    [totalEarnedInUsd],
  );

  const canClaimRewards = useMemo(
    () => hasEarnings && hasEnoughEGLDBalance && hasInteractionWithTheProtocol,
    [hasEarnings, hasEnoughEGLDBalance, hasInteractionWithTheProtocol],
  );

  const handleSubmit =
    (boost = false) =>
    async () => {
      if (hasPendingTransactions) {
        return;
      }

      const rewardsTokensSymbol: string[] = [
        ...(new Set(totalRewards.map(({ symbol }) => symbol)) as any),
      ];

      const hasMoreThanOneRewardToken = rewardsTokensSymbol.length > 1;

      const config = {
        group: getRouteGroup(),
        subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS,
        token: boost
          ? MARKET_KEY.HTM
          : !hasMoreThanOneRewardToken
          ? rewardsTokensSymbol[0]
          : undefined,
        result: boost
          ? totalEarnedInHtm
          : !hasMoreThanOneRewardToken
          ? totalRewards[0]?.userRewardBalance || '0'
          : undefined,
      };

      const hasSlippage = new DefiUtils(slippage).isGreaterThan(0);

      const totalControllerRewardEarnedInHtmBasicUnits = hasSlippage
        ? new DefiUtils(totalControllerRewardEarnedInHtm)
            .toBasicUnits(htmMarket.underlying.decimals)
            .toFixed(0)
        : '0';

      const totalControllerRewardV2EarnedInHtmBasicUnits = hasSlippage
        ? new DefiUtils(totalControllerRewardV2EarnedInHtm)
            .toBasicUnits(htmMarket.underlying.decimals)
            .toFixed(0)
        : '0';

      // const totalStakingAccumulatorsEarnedInHtmBasicUnits = hasSlippage
      //   ? new DefiUtils(totalStakingAccumulatorsEarnedInHtm)
      //       .toBasicUnits(htmMarket.underlying.decimals)
      //       .toFixed(0)
      //   : '0';

      const boosterV2TokensIds = controllerAccountRewardsPerMarket
        .filter(({ s_amount }) => s_amount !== '0')
        .map(({ tokenId }) => tokenId);

      const totalBoosterRewardEarnedInHtmBasicUnits = hasSlippage
        ? new DefiUtils(totalBoosterRewardEarnedInHtm)
            .toBasicUnits(htmMarket.underlying.decimals)
            .toFixed(0)
        : '0';

      const txs = [
        ...(hasControllerReward
          ? [
              claimControllerRewards(
                boost,
                totalControllerRewardEarnedInHtmBasicUnits,
              ),
            ]
          : []),
        ...(hasControllerRewardV2
          ? [
              claimBoosterRewardsV2({
                programId: 1,
                boost,
                minBoostedRewardsOut:
                  totalControllerRewardV2EarnedInHtmBasicUnits,
                tokenIds: boosterV2TokensIds,
              }),
            ]
          : []),
        // ...(hasStakingAccumulators
        //   ? [
        //       claimBoosterRewardsV2({
        //         programId: 2,
        //         boost,
        //         minBoostedRewardsOut:
        //           totalStakingAccumulatorsEarnedInHtmBasicUnits,
        //       }),
        //     ]
        //   : []),
        ...(hasBoosterReward
          ? [
              claimBoosterRewards(
                boost,
                accountBoosterMarkets,
                totalBoosterRewardEarnedInHtmBasicUnits,
              ),
            ]
          : []),
      ];

      await signTransactions(txs, config);
    };

  return (
    <PopupBed
      close={false}
      enableScaling={false}
      width={isLargeScreen ? 460 : 328}
      secondaryPopupContent={
        showSlippageSettings ? (
          <SlippageSettingsPopupContent
            value={slippage}
            onSave={(value) => {
              dispatch(setBooster({ slippage: value }));
              setShowSlippageSettings(false);
            }}
          />
        ) : null
      }
    >
      <div className='relative h-fit w-[328px] lg:w-[460px]'>
        <div
          className={classNames(
            'text-[#3C3A40] dark:text-white',
            'lg:h-fit lg:p-[16px] lg:pt-[16px] ',
            'h-[43.45px] px-[12.41px] py-[12.23px]',
          )}
        >
          <div
            className={clsxm(
              'relative flex h-full w-full  items-center ',
              'lg:h-[24px] lg:max-h-[24px] lg:px-[121.5px]',
              '-mt-[1px] px-[81px]',
              !isLoadingFirstInfo && totalRewards.length > 0
                ? 'justify-between'
                : 'justify-center',
            )}
          >
            <p
              className={clsxm([
                'inline-block font-hass font-[600] ',
                'lg:pt-[2px] lg:text-[18px] lg:leading-[25px] ',
                ' -mb-[0.25px] pt-[0.2px] text-[14px] leading-[18.62px]',
                !isLoadingFirstInfo && totalRewards.length > 0 && 'pr-[0.8px]',
              ])}
            >
              {t('rewards-balance')}
            </p>

            {totalRewards.length > 0 && (
              <>
                <div
                  className={clsxm([
                    'inline-block rounded-full bg-[#3C3A40]',
                    'lg:h-[17px] lg:w-[1px]',
                    'mt-[2px] h-[13.4px] w-[0.5px]',
                  ])}
                />

                <FilterButton
                  className={clsxm([
                    'inline-block',
                    'mt-[2px] lg:h-[16px] lg:w-[16px] lg:p-[1px] dark:bg-[#8F64C2] dark:hover:bg-[#8F64C2]/50',
                    'mt-[2px] h-[12.45px] w-[12.45px] p-[0.6px] pr-[0.5]',
                  ])}
                  onClick={() => setShowSlippageSettings(!showSlippageSettings)}
                />
              </>
            )}

            <ClosePopupBtn
              className={clsxm([
                'absolute right-0 top-1/2 z-10  m-0 flex -translate-y-1/2 items-center  justify-center ',
                'lg:mt-[0.7px] lg:w-[18px] lg:px-[1.6px] lg:pb-[1.05px] lg:pt-[2.05px]',
                // 'w-[14px] h-[14px] pl-[3px] pb-[2.9px] pt-[2px] pr-[2.8px]  mt-[1.25px]',
                'mt-[0.8px] h-[13.97px] w-[13.97px] px-[2.8px] py-[2.7px]',
              ])}
              iconClassName={clsxm(['w-[11px]'])}
              theme={{ light: '#1E1E1E', dark: 'white' }}
            />
          </div>
        </div>

        <div
          className={clsxm([
            'lg:pb-[14.8px] lg:pt-[21px]',
            'pb-[10.4px] pt-[8px]',
          ])}
        >
          {isLoadingFirstInfo ? (
            <div className='grid gap-5 px-[8px] lg:px-[20.5px]'>
              {new Array(3).fill(0).map((_, index) => (
                <RewardSkeleton key={index} />
              ))}
            </div>
          ) : (
            <>
              {!totalRewards.length ? (
                <div className='flex-xy-center flex-col px-[8px] lg:px-[20.5px]'>
                  <WrappedLottiePlayer
                    className='w-[220px] h-[220px]'
                    src='https://cdn.app.hatom.com/animations/magic-rabbit-hat.json'
                  />
                  <TransactionButton
                    disabled
                    text={t('no-rewards-to-claim')}
                    className='mt-4'
                  ></TransactionButton>
                </div>
              ) : (
                <>
                  <div className='mb-[7.125px] px-[8px] lg:mb-[19px] lg:px-[20.5px]'>
                    <div className='flex flex-col gap-3'>
                      {totalRewards.map((rewardItem, rewardItemIndex) => (
                        <Reward
                          key={rewardItemIndex}
                          symbol={rewardItem.symbol}
                          name={rewardItem.name}
                          logo={rewardItem.logo}
                          decimals={rewardItem.decimals}
                          s_amount={rewardItem.userRewardBalance}
                        />
                      ))}
                    </div>

                    <div className=' w-full my-[10px]'>
                      <Separator />
                    </div>

                    <Reward
                      symbol=''
                      name='USH Airdrop'
                      logo='https://cdn.app.hatom.com/images/ush.svg'
                      decimals={0}
                      s_amount='0'
                      determined={false}
                      badge='https://cdn.app.hatom.com/images/airdrop-badge.svg'
                      state={true}
                    />
                  </div>

                  <div className='mb-[7.25px] px-[7.5px] lg:mb-[9px] lg:px-[20px]'>
                    <div className='h-[1.45px] w-full rounded-full border-none bg-[#535367] dark:bg-[#625E8F] lg:h-0.5' />
                  </div>

                  <div className='px-[8px] lg:px-[20px]'>
                    <div
                      className={classNames(
                        'flex font-semibold dark:text-white',
                        'lg:px-[10.5px] lg:pb-[10px] text-[10px] lg:text-[14px] lg:leading-[24px]',
                        'px-[8px] pb-[4px] text-[10px] leading-[18.62px]',
                      )}
                    >
                      <p className='mr-auto'>{t('total-earned-in-usd')}:</p>
                      <Hint content={formatNumber(totalEarnedInUsd, 6)}>
                        <span>{formatNumber(totalEarnedInUsd)}</span>
                      </Hint>
                      <span
                        className={clsxm(
                          'dark:text-indigo-100',
                          'lg:ml-[4px]',
                          'ml-[3.5px]',
                        )}
                      >
                        USD
                      </span>
                    </div>

                    <div
                      className={clsxm(
                        'flex items-center font-[600] text-[#3C3A40]',
                        'lg:mb-[10px] lg:pt-[12px]',
                        'mb-[2px] pt-[4.5px]',
                      )}
                    >
                      <p
                        className={clsxm(
                          'text-[10px] dark:text-white',
                          'lg:mr-[3px] lg:text-[14px] lg:leading-[24px]',
                          'mr-[2px] text-[10px] leading-[18.7px]',
                        )}
                      >
                        {t('Claim Rewards')}
                      </p>
                      <Hint
                        {...(isMediumScreen ? { placement: 'top-center' } : {})}
                        autoReplace
                        delayLeave={300}
                        content={
                          <div
                            className={clsxm([
                              'max-w-[241px] md:max-w-[372px] font-[400] leading-[12.5px] text-[#fff] text-[8px] md:text-[10px] lg:leading-[12.5px] ',
                            ])}
                          >
                            <Trans
                              i18nKey='tooltip-rewards'
                              components={[
                                <span className='text-[#2775CA]' />,
                                <span className='text-[#5AF1B0]' />,
                                <span className='text-[#7367F1]' />,
                              ]}
                              ns='translation'
                            />
                          </div>
                        }
                      >
                        <div className='pt-[1px] lg:pt-[0.5px]'>
                          {/* <img
                            src='https://cdn.app.hatom.com/images/pages/lend/exclamation-circle.svg'
                            width={isLargeScreen ? 13 : 10}
                            height={isLargeScreen ? 13 : 10}
                            alt='exclamation circle'
                          /> */}
                          <InfoIcon
                            className='h-[11px] w-[11px] lg:h-[12px] lg:w-[12px]'
                            color={isDark ? 'white' : '#6A6A8C'}
                          />
                        </div>
                      </Hint>
                    </div>

                    <div
                      className={clsxm(
                        'flex',
                        'w-full',
                        'items-center',
                        'justify-between',
                        'lg:gap-[12px]',
                        'lg:pt-[0px]',
                        'gap-[8px]',
                        'pt-[2px]',
                        'lg:mb-[20px]',
                        'mb-[16px]',
                      )}
                    >
                      <Hint
                        content={
                          hasPendingTransactions
                            ? t('translation:wait-tx-inprogress')
                            : ''
                        }
                        placement='top-center'
                        className='w-full'
                        unvisible={!hasPendingTransactions}
                      >
                        <ClaimRewardButton
                          HTMText='144.25 HTM'
                          rewardLogos={totalRewards.map(({ logo }) => logo)}
                          text={
                            totalEarnedInUsd === '0'
                              ? t('no-rewards-to-claim')
                              : `$${formatNumber(totalEarnedInUsd)}`
                          }
                          disabled={!canClaimRewards}
                          onClick={
                            canClaimRewards ? handleSubmit(false) : () => {}
                          }
                        />
                      </Hint>

                      {false && (
                        <>
                          <p
                            className={clsxm(
                              'font-[600]',
                              'lg:ml-[0px] lg:text-[14px] lg:leading-[17.5px]',
                              'ml-[-1px] text-[10.2px] leading-[19px]',
                            )}
                          >
                            Or
                          </p>
                          <Hint
                            content={
                              hasPendingTransactions
                                ? t('translation:wait-tx-inprogress')
                                : ''
                            }
                            placement='top-center'
                            unvisible={!hasPendingTransactions}
                          >
                            <ClaimRewardButton
                              HTMText={`${formatNumber(totalEarnedInHtm)} HTM`}
                              rewardLogos={[]}
                              text={
                                !canClaimRewards && hasEnoughEGLDBalance
                                  ? t('no-rewards-to-claim')
                                  : `$${formatNumber(
                                      new DefiUtils(totalEarnedInHtm)
                                        .toUSD(xExchangeHtmPriceUSD)
                                        .toSafeString(),
                                    )}`
                              }
                              disabled={!canClaimRewards}
                              onClick={
                                canClaimRewards ? handleSubmit(true) : () => {}
                              }
                              isWithBoost
                            />
                          </Hint>
                        </>
                      )}
                    </div>

                    <div
                      className={clsxm([
                        'flex items-start justify-between border-[#F8E4FA] bg-[#FEF4FF] dark:border-[#836B97] dark:bg-[#5d486f]',
                        'lg:h-[42px] lg:max-w-[416px] lg:rounded-[8px] lg:border lg:p-[7px]',
                        'min-h-[30.41px] max-w-[312px] rounded-[6px] border-[0.78px] pt-[6px] pb-[5.4px] pl-[5.4px] pr-[3px]',
                      ])}
                    >
                      <div
                        className={clsxm([
                          'relative flex items-center justify-center bg-black',
                          'lg:mr-[8px] lg:h-[14px] lg:min-h-[14px] lg:w-[14px] lg:min-w-[14px] lg:rounded-[4px]',
                          'mr-[6.5px] min-h-[11px] min-w-[11px] rounded-[3.25px]',
                        ])}
                      >
                        <img
                          src='https://cdn.app.hatom.com/images/pages/lend/meta.svg'
                          width={isLargeScreen ? 12 : 8}
                          height={isLargeScreen ? 7.75 : 7}
                          alt='flame'
                        />
                        <div
                          className={clsxm([
                            'absolute',
                            'lg:-bottom-[2px] lg:-right-[2.1px]',
                            '-bottom-[1.8px] -right-[2px]',
                          ])}
                        >
                          <img
                            width={isLargeScreen ? 9 : 7}
                            height={isLargeScreen ? 9 : 7}
                            src='https://cdn.app.hatom.com/images/pages/lend/v2.svg'
                            alt='v2'
                          />
                        </div>
                      </div>
                      <p
                        className={clsxm([
                          'flex-grow whitespace-pre-wrap font-[550] text-[#6A6A8C] dark:text-[#E6D8F2]',
                          'lg:pt-[0] lg:text-[10px] lg:leading-[13px]',
                          'pt-[1px] text-[7px] leading-[8.75px]',
                        ])}
                      >
                        <span>
                          xExchange enables reward conversion, including
                          bonuses, into
                        </span>
                        <span className='text-[#4AAD7C] '> HTM tokens</span>
                        <span>
                          . The displayed total is an estimate, with
                          customizable slippage options.
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              )}

              {totalRewards.length > 0 && hasInteractionWithTheProtocol && (
                <div className='px-[8px] lg:px-[20.5px]'>
                  <OperationFormBottomAlerts />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </PopupBed>
  );
};

export default RewardsPopup;
