import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatIsolatedLendingProtocolList,
  formatStakePools,
} from '@/store/parsers/ush-app-parser';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import logger from '@/utils/logger';

export interface IsolatedToken {
  symbol: string;
  defaultSymbol: string;
  decimals: number;
  balance: string;
  s_balance: string;
  priceUSD: string;
  identifier: string;
}

export interface IsolatedLendingProtocol {
  isolatedKey: string;
  totalBorrowsUSD: string;
  totalCollateralUSD: string;
  tokensMap: Record<string, IsolatedToken>;
  collateralFactor: string;
  minCollateralAmount: string;
  s_minCollateralAmount: string;
  borrowCap: string | null;
  s_borrowCap: string | null;
  collateralCap: string | null;
  s_collateralCap: string | null;
  collateralToken: IsolatedToken;
  ushToken: IsolatedToken;
  lsToken: IsolatedToken;
  hLsToken: IsolatedToken;
  account: {
    collateralBalance: string;
    s_collateralBalance: string;
    borrowBalance: string;
    s_borrowBalance: string;
    collateralBalanceUSD: string;
    borrowBalanceUSD: string;
    borrowLimitUSD: string;
    hasTakenBorrow: boolean;
    tokenBorrowLimit: string;
    suggestedTokenBorrowLimit: string;
    borrowLimitUsedPercent: string;
    hasCollateral: boolean;
    hasPosition: boolean;
  };
}

interface StakePoolToken {
  isMetaEsdt: boolean;
  nonce?: string;
  id: string;
  description: string;
  symbol: string;
  defaultSymbol: string;
  logo: string;
  inlineLogo: string;
  decimals: number;
  priceUSD: string;
  account: {
    walletBalance: string;
    s_walletBalance: string;
    stakedBalance: string;
    s_stakedBalance: string;
    stakedBalanceUSD: string;
  };
}

interface StakePools {
  id: string;
  protocol: string;
  logo: string;
  mainToken: StakePoolToken;
  poolTokens: StakePoolToken[];
  s_totalStake: string;
  totalStakeUSD: string;
  account: {
    totalStakedUSD: string;
    totalStaked: string;
  };
}

export interface UshAppState {
  stakePools: StakePools[];
  isolatedLendingProtocolsList: IsolatedLendingProtocol[];
}

const initialState: UshAppState = {
  stakePools: [],
  isolatedLendingProtocolsList: [],
};

export const ushAppSlice = createSlice({
  name: 'ushApp',
  initialState,
  reducers: {
    setUshApp: (state, action: PayloadAction<Partial<UshAppState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        // @ts-ignore
        state[key as keyof UshAppState] = value;
      });
    },
  },
});

export const { setUshApp } = ushAppSlice.actions;

export const getUshAppInfo =
  ({ accountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        auth: {
          account: { tokens: accountTokens },
        },
        protocol: {
          tokensMap,
        },
      } = getState();

      const isolatedLendingProtocols = await Promise.all([
        blockchainService.lens.getEgldIsolatedLendingProtocolData(),
        blockchainService.lens.getWtaoIsolatedLendingProtocolData(),
      ]);

      const [
        accountIsolatedLendingProtocolData,
        tokenPrices,
        stakePools,
        accountStakingModuleData,
      ] = await Promise.all([
        accountAddress
          ? Promise.all([
              blockchainService.lens.getAccountEgldIsolatedLendingProtocolData(
                accountAddress,
              ),
              blockchainService.lens.getAccountWtaoIsolatedLendingProtocolData(
                accountAddress,
              ),
            ])
          : [],
        blockchainService.lens.getTokenPricesV2(),
        blockchainService.lens.getUSHStakingStakePools(),
        blockchainService.lens.getAccountUSHStakingData(accountAddress),
      ]);

      const stakingMainTokensIds = stakePools.map((token) => token.mainToken);

      const [stakeMainTokensPrice] = await Promise.all([
        blockchainService.lens.getBoosterV2TokenPriceUsd(stakingMainTokensIds),
      ]);

      const results: Partial<UshAppState> = {
        isolatedLendingProtocolsList: formatIsolatedLendingProtocolList({
          isolatedLendingProtocols,
          accountIsolatedLendingProtocolData,
          tokensMap,
          accountTokens: accountTokens,
          tokenPrices,
        }),
        stakePools: formatStakePools({
          stakePools,
          accountTokens,
          tokensMap,
          tokenPrices: stakeMainTokensPrice,
          accountStakingModuleData,
        }),
      };

      await dispatch(addAction(setUshApp(results)));
    } catch (error) {
      logger.error('store:getUshAppInfo', error);
      captureException(error);
    }
  };

export const ushAppSelector = (state: RootState) => state.ushApp;

export default ushAppSlice.reducer;
