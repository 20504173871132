import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import { formatHistoryPoints } from '@/services/indexer/market/parsers';
import {
  QueryHistoryPoint,
  ResponseHistoryPoint,
} from '@/services/indexer/market/types';

export const getHistoryPoints = async (
  address: string,
  assetKey: string,
  first: number,
): Promise<ResponseHistoryPoint> => {
  const query = gql`
    query Market_QueryHistoryPoint($address: String!, $first: Int!)
    @cacheControl(maxAge: 3600) {
      getMoneyMarket(address: $address) {
        address
        dailyStateHistory(first: $first, order: { desc: day }) {
          day
          moneyMarketState {
            totalSupply
            borrows
            cash
            reserves
            supplyRatePerSecond
            borrowRatePerSecond
            timestamp
          }
        }
        underlying {
          dailyPriceHistory(first: $first, order: { desc: day }) {
            day
            quote {
              priceInEgld
              timestamp
            }
          }
        }
      }
    }
  `;

  const variables = {
    address,
    first,
  };

  const queryHistoryPointResponse = await client.request<QueryHistoryPoint>(
    query,
    variables,
  );

  const historyPoints = formatHistoryPoints(
    queryHistoryPointResponse,
    assetKey,
    first,
  );

  return {
    address,
    assetKey,
    historyPoints,
  };
};
