import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { useCollapse } from '@/hooks/layout/useCollapse';
import useTheme from '@/hooks/useTheme';

import { cardClasses } from '../../global';

export const Question: React.FC<{
  q: string;
  a: string;
  list?: Array<any>;
  open?: boolean;
  onChange?: () => void;
  className?: string;
}> = ({ q, a, list, open, onChange, className }) => {
  const [ref, isExpanded, toggle] = useCollapse<HTMLDivElement>({
    defaultValue: open,
    callback: onChange,
  });
  const { isDark } = useTheme();

  const [aHTML, setAHTML] = useState(a);

  useEffect(() => {
    const regExp = /•\s(.+?)<br\/>/g;
    const ul = Array.from(a.matchAll(regExp))?.map((e) => e[1]);
    if (ul) {
      const html = ReactDOMServer.renderToStaticMarkup(
        <ul className='list-disc pl-4'>
          {ul.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      );

      setAHTML(
        a
          .replace(regExp, '[]')
          .replace(/\[.+\]/g, html)
          .replaceAll('&nbsp;', '')
      );
    }
  }, [a]);

  return (
    <button
      type='button'
      className={classNames(
        cardClasses,
        'grid w-full grid-cols-[1fr,auto] items-center text-left font-hass text-[14px] md:text-[16px]',
        'dark:transparent bg-[rgba(255,255,255,0.8)] dark:bg-[rgba(25,23,51,0.6)] dark:[backdrop-filter:blur(9px)]',
        // 'border-2 border-red',
        className,
        'theme-switch-transition'
      )}
      onClick={() => toggle()}
    >
      <p
        className={classNames('font-semibold')}
        dangerouslySetInnerHTML={{ __html: q }}
      ></p>
      <div
        className={classNames(
          'flex-xy-center -mr-3 h-6 w-6 transition-transform',
          {
            'rotate-180': !isExpanded,
          }
        )}
      >
        <svg
          width={8}
          height={6}
          viewBox='0 0 8 6'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7 4.5L4 1.5L1 4.5'
            stroke='currentColor'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
            color={isDark ? '#625E8F' : '#333333'}
          />
        </svg>
      </div>
      <div
        ref={ref}
        className={classNames(
          'col-span-full font-normal text-[#535367] dark:text-[#625E8F]',
          'text-[12px] md:text-[14px]'
        )}
      >
        <p
          className='pt-3 leading-6'
          dangerouslySetInnerHTML={{ __html: aHTML }}
        ></p>
        {list?.length ? (
          <ol className='mt-3'>
            {list.map((e, i) => (
              <li key={i}>
                {i + 1}. {e}
              </li>
            ))}
          </ol>
        ) : (
          <></>
        )}
      </div>
    </button>
  );
};
