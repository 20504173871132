import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  labelTextSizeClassName,
  valueTextColorClassName,
} from '@/components/popups/HTMBoosterPopup1/components/XExchangeDashboard/components/Tokens/components/token.utils';

import { useAppDispatch } from '@/store';
import { openDropdown } from '@/store/dropdown';
import { openPopup } from '@/store/popup';

import clsxm from '@/services/clsxm';

const TokenSubCategoryItemDropdownItem = ({
  label,
  onclick,
}: {
  label: string;
  onclick: () => void;
}) => (
  <div
    onClick={onclick}
    className='w-full  flex justify-start items-center pl-[6px] md:pl-[8px] lg:pl-[12px] py-[3px]  lg:py-[4px] hover:bg-[#ECEEFA] cursor-pointer'
  >
    <span
      className={clsxm(
        valueTextColorClassName,
        'dark:text-[#C1BCE8] tracking-normal',
      )}
    >
      {label}
    </span>
  </div>
);

export const ManageDropdownContent = () => {
  const dispatch = useDispatch();

  return (
    <div
      className={clsxm(
        'w-full',
        'overflow-hidden',
        'text-[8px] leading-[10px] font-semibold  tracking-normal',
        'lg:text-[12px] lg:leading-[15px]',
        'w-[44px]  md:w-[47px] lg:w-[73px]',
      )}
    >
      <button
        type='button'
        className={clsxm(
          'h-[16px] lg:h-[23px]',
          // 'w-[42px] md:w-[49px] lg:w-[80px]',
          'w-[44px]  md:w-[47px] lg:w-[73px]',
          'bg-[#ECEEFA] border-[#D3D6EE] dark:bg-[#3F3B76] dark:border-[#3F3B76] border rounded-t-[6px]',
        )}
      >
        <span
          className={clsxm(
            labelTextSizeClassName,
            valueTextColorClassName,
            'dark:text-white',
          )}
        >
          Manage
        </span>
      </button>

      <div className='w-full bg-white dark:bg-[#292651]  border-[#D3D6EE] dark:border-[#3F3B76]  border !border-t-none overflow-hidden !rounded-b-[6px] translate-y-[-1px]'>
        <TokenSubCategoryItemDropdownItem
          label='Upgrade'
          onclick={() => {
            dispatch(
              openPopup({
                name: 'upgrade_position',
              }),
            );
          }}
        />
        <div className='w-full h-px bg-[#E2E4F2] dark:bg-[#3F3B76] ' />

        <TokenSubCategoryItemDropdownItem
          label='Add'
          onclick={() => {
            dispatch(
              openPopup({
                name: 'stakeHtmPopup',
              }),
            );
          }}
        />
        <div className='w-full h-px bg-[#E2E4F2] dark:bg-[#3F3B76] ' />

        <TokenSubCategoryItemDropdownItem
          label='Close'
          onclick={() => {
            dispatch(
              openPopup({
                name: 'unstakeHtmPopup',
              }),
            );
          }}
        />
      </div>
    </div>
  );
};
export const TokenSubCategoryItemDropdown = () => {
  // const [open, setOpened] = useState(false);
  const ref = useRef<any>();
  const dispatch = useAppDispatch();

  return (
    <div
      className={clsxm(
        'relative cursor-pointer lg:translate-x-[-11px]',
        'text-[8px] leading-[10px] font-semibold  tracking-normal',
        'lg:text-[12px] lg:leading-[15px]',
      )}
    >
      <button
        type='button'
        ref={ref}
        onClick={() => {
          dispatch(
            openDropdown({
              name: 'htm_booster_manage',
              parentButtonRef: ref.current,
              // className: 'translate-x-[36.2px] md:translate-x-[33.4px] lg:translate-x-[7px]'
            }),
          );
        }}
        className={clsxm(
          'group w-[44px]  md:w-[47px] lg:w-[73px] h-[16px] lg:h-[23px] flex justify-center items-center gap-[8px] rounded-[4px] lg:rounded-[6px]',
          'bg-white hover:bg-[#FAFAFC] dark:hover:bg-[#FAFAFC] dark:bg-[#3F3B76]',
        )}
      >
        <span
          className={clsxm(
            'text-[8px] leading-[10px] font-semibold ',
            'md:text-[9px] md:leading-[11.25px]',
            'lg:text-[12px] lg:leading-[15px]',
            valueTextColorClassName,
            'group-hover:text-[#9F9FC0] dark:group-hover:text-[#9F9FC0]',
            'dark:text-white',
          )}
        >
          Manage
        </span>
      </button>
    </div>
  );
};
