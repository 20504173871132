import DefiUtils from 'defi-utils';
import { useRef, useState } from 'react';

import { COLLATERAL_MODE } from '@/components/popups/SupplyPopup/types/collateral-mode';
import { SUPPLY_TABS, TABS } from '@/components/popups/SupplyPopup/types/tab';

import { useAppSelector } from '@/store';
import { lendAppSelector } from '@/store/lend-app';
import { popupSelector } from '@/store/popup';
import { MARKET_KEY } from '@/store/protocol';

const useSupplyForm = ({}: {}) => {
  const { markets } = useAppSelector(lendAppSelector);

  const {
    data: { collateralTab, assetKey },
  } = useAppSelector(popupSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const market = markets[assetKey as MARKET_KEY];

  const [selectedTab, setSelectedTab] = useState<TABS>(
    collateralTab ? TABS.COLLATERAL : TABS.SUPPLY,
  );
  const [maxSelected, setMaxSelected] = useState<boolean>(false);
  const [collateralOption, setCollateralOption] = useState<string>(
    COLLATERAL_MODE.ADD,
  );
  const [hasFocus, setHasFocus] = useState(false);
  const isSupplying = selectedTab === TABS.SUPPLY;
  const isCollateralying = selectedTab === TABS.COLLATERAL;
  const isWithdrawing = selectedTab === TABS.WITHDRAW;
  const isAddingCollateral =
    isCollateralying && collateralOption === COLLATERAL_MODE.ADD;
  const isRemovingCollateral =
    isCollateralying && collateralOption === COLLATERAL_MODE.REMOVE;

  const [inputValue, setInputValue] = useState<string>('');
  const [inputValueAsBigNumber, setInputValueAsBigNumber] =
    useState<string>('');
  const [inputValueAsHTokenBigNumber, setInputValueAsHTokenBigNumber] =
    useState<string>('');
  const [isRemovingCollateralAndWithdraw, setIsRemovingCollateralAndWithdraw] =
    useState<boolean>(false);
  const [isAddingSupplyAndCollateral, setIsAddingSupplyAndCollateral] =
    useState<boolean>(false);

  const onTabToggle = (tabNumber: number) => {
    setSelectedTab(SUPPLY_TABS[tabNumber]);
  };

  const handleSetMaxAmount = () => {
    setMaxSelected(!maxSelected);
  };

  const handleInputValueChange = (value: any) => {
    if (maxSelected) {
      return;
    }

    if (!value) {
      setInputValue('');
      setInputValueAsHTokenBigNumber('');
      setInputValueAsBigNumber('');
      return;
    }

    setInputValue(value);
    setInputValueAsHTokenBigNumber(
      new DefiUtils(value)
        .toBasicUnits(market.underlying.decimals)
        .toTokens(market.hTokenExchangeRate)
        .toSafeString(),
    );
    setInputValueAsBigNumber(
      new DefiUtils(value)
        .toBasicUnits(market.underlying.decimals)
        .toSafeString(),
    );
  };

  const [submitButton, setSubmitButton] = useState({
    disabled: true,
    label: '',
  });

  const handleInputFocus = () => {
    setHasFocus(true);
    inputRef.current?.focus();
  };

  const handleInputBlur = () => {
    setHasFocus(false);
  };

  return {
    inputRef,
    inputValue,
    setInputValue,
    inputValueAsBigNumber,
    setInputValueAsBigNumber,
    inputValueAsHTokenBigNumber,
    setInputValueAsHTokenBigNumber,
    isAddingSupplyAndCollateral,
    setIsAddingSupplyAndCollateral,
    selectedTab,
    setSelectedTab,
    maxSelected,
    setMaxSelected,
    collateralOption,
    setCollateralOption,
    isSupplying,
    isCollateralying,
    isWithdrawing,
    isAddingCollateral,
    isRemovingCollateral,
    hasFocus,
    onTabToggle,
    handleSetMaxAmount,
    handleInputValueChange,
    submitButton,
    setSubmitButton,
    isRemovingCollateralAndWithdraw,
    setIsRemovingCollateralAndWithdraw,
    handleInputFocus,
    handleInputBlur,
  };
};

export type ReturnUseSupplyForm = ReturnType<typeof useSupplyForm>;

export default useSupplyForm;
