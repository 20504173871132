import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';

import Hint from '@/components/Hint';
import { GriffinHeadIcon } from '@/components/Icons/GriffinHeadIcon';
import WrappedImg from '@/components/WrappedImg';

import { MARKET_KEY } from '@/store/protocol';

import { parseSmallNumber } from '@/utils/parseSmallNumber';

export interface IEstimationAnnual<ValueType> {
  token: MARKET_KEY;
  value: ValueType;
  valueUSD: ValueType;
  liquidStakingAPY?: boolean;
}

export default function AnnualEstimation({
  debts = false,
  data,
}: {
  debts?: boolean;
  data: Array<IEstimationAnnual<string | number>>;
}) {
  const { t } = useTranslation();
  const gradient = debts
    ? 'linear-gradient(291.9deg, #FFF3F3 27.56%, #E2A6A6 112.79%)'
    : 'linear-gradient(277.82deg, #E9FFF9 3.43%, #99DCC9 115.78%)';
  const background = debts ? '#FAB0B0' : '#AEE5D6';

  return (
    <div
      className={classNames(
        'gap-y-1 rounded p-1 pl-1.5 text-[10px]',
        'grid grid-cols-[1fr,12px]',
      )}
      style={{ background: gradient }}
    >
      <p className='capitalize text-[#69698F]'>
        {debts
          ? t('estimated-annual-debt', 'Estimated Annual Debt')
          : t('estimated-annual-returns', 'Estimated Annual Returns')}
      </p>

      <div
        style={{ background }}
        className='flex-xy-center h-3 w-3 rounded-full text-white'
      >
        <svg
          width='7'
          height='7'
          viewBox='0 0 7 7'
          fill='none'
          stroke='currentColor'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.87793 3.5H6.12793'
            strokeWidth='1.4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          {!debts && (
            <path
              d='M3.50293 0.875V6.125'
              strokeWidth='1.4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          )}
        </svg>
      </div>

      <div className='col-span-2 space-y-1'>
        {data.map(({ token, value, valueUSD, liquidStakingAPY }, i) => {
          const lsToken =
            token === MARKET_KEY.EGLD ? MARKET_KEY.sEGLD : MARKET_KEY.sWTAO;

          return (
            <div
              key={i}
              className='grid grid-cols-[12px,auto,1fr] items-center gap-x-1'
            >
              <WrappedImg
                src={`https://cdn.app.hatom.com/images/tokens/${token}.svg`}
                alt=''
              />
              {parseSmallNumber(value)} {token} (
              {parseSmallNumber(valueUSD, true)})
              {liquidStakingAPY && (
                <Hint
                  placement='bottom-end'
                  content={
                    <div className='max-w-[256px] text-[10px] leading-tight'>
                      <Trans
                        i18nKey='tooltip-mihx9'
                        values={{
                          native: token,
                          lsToken: `${lsToken
                            .slice(0, 1)
                            .toLowerCase()}${lsToken.slice(1)}`,
                        }}
                        components={[<span className='text-[#1679F9]' />]}
                        ns='translation'
                      />
                    </div>
                  }
                >
                  <span
                    className={classNames(
                      '-ml-0.5',
                      'flex-xy-center h-3 w-3 rounded-[5px] text-[10px]',
                      'bg-white text-[#1679F9]',
                    )}
                  >
                    <GriffinHeadIcon />
                  </span>
                </Hint>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
