import DefiUtils from 'defi-utils';

import {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';

import { AccountToken } from '@/store/auth';
import { Token, TOKEN_LOGO_V2_MAP, TOKEN_SYMBOL_MAP } from '@/store/protocol';
import { IsolatedToken, UshAppState } from '@/store/ush-app';

import {
  AccountIsolatedLendingProtocolData,
  AccountUSHStakingData,
  BoosterV2TokenPriceUsdData,
  IsolatedLendingProtocolData,
  USHStakingStakePools,
} from '@/services/blockchain/lens/types';
import { calcBorrowLimit } from '@/utils/math/market';

export const formatIsolatedLendingProtocolList = ({
  isolatedLendingProtocols,
  accountIsolatedLendingProtocolData,
  tokensMap: tokensDetailsMap,
  accountTokens,
  tokenPrices,
}: {
  isolatedLendingProtocols: IsolatedLendingProtocolData[];
  accountIsolatedLendingProtocolData: AccountIsolatedLendingProtocolData[];
  tokensMap: Record<string, Token>;
  accountTokens: AccountToken[];
  tokenPrices: Record<string, string>;
}): UshAppState['isolatedLendingProtocolsList'] => {
  return isolatedLendingProtocols.map(
    ({
      collateralTokenId,
      lsTokenId,
      hLsTokenId,
      ushTokenId,
      collateralFactor,
      minCollateralAmount,
      borrowCap,
      collateralCap,
      totalBorrows,
      totalCollateral,
    }) => {
      const accountIsolatedLendingProtocolItem =
        accountIsolatedLendingProtocolData.find(
          ({ ilpData }) => ilpData.collateralTokenId === collateralTokenId,
        );

      const collateralBalance =
        accountIsolatedLendingProtocolItem?.collateral || '0';
      const borrowBalance = accountIsolatedLendingProtocolItem?.borrow || '0';

      const tokenIds = [collateralTokenId, lsTokenId, hLsTokenId, ushTokenId];

      const tokensMap = tokenIds.reduce(
        (prev, tokenId) => {
          const [defaultSymbol] = tokenId.split('-');

          const accountTokenItem = accountTokens.find(
            ({ tokenIdentifier }) => tokenIdentifier === tokenId,
          );

          const decimals = tokensDetailsMap[defaultSymbol]?.decimals || 1;
          const balance = accountTokenItem?.balance || '0';

          return {
            ...prev,
            [defaultSymbol]: {
              symbol: TOKEN_SYMBOL_MAP[defaultSymbol] || defaultSymbol,
              defaultSymbol,
              identifier: tokenId,
              decimals,
              balance,
              s_balance: new DefiUtils(balance)
                .toFullDecimals(decimals)
                .toSafeString(),
              priceUSD: tokenPrices[defaultSymbol] || '1',
            },
          };
        },
        {} as Record<string, IsolatedToken>,
      );

      const isolatedKey = collateralTokenId.split('-')[0] || '';

      const collateralToken = tokensMap[isolatedKey];
      const ushToken = tokensMap['USH'];
      const lsToken = tokensMap[lsTokenId.split('-')[0]];
      const hLsToken = tokensMap[hLsTokenId.split('-')[0]];

      const s_collateralBalance = new DefiUtils(collateralBalance)
        .toFullDecimals(collateralToken.decimals)
        .toString();

      const collateralBalanceUSD = new DefiUtils(s_collateralBalance)
        .toUSD(collateralToken.priceUSD)
        .toString();

      const s_borrowBalance = new DefiUtils(borrowBalance)
        .toFullDecimals(ushToken.decimals)
        .toString();

      const borrowBalanceUSD = new DefiUtils(s_borrowBalance)
        .toUSD(ushToken.priceUSD)
        .toString();

      const borrowLimitUSD = calcBorrowLimit(
        collateralBalance,
        collateralFactor,
        collateralToken.priceUSD,
        collateralToken.decimals,
      ).toString();

      const hasTakenBorrow = !new DefiUtils(s_borrowBalance).isZero();
      const hasCollateral = !new DefiUtils(collateralBalance).isZero();
      const hasPosition = hasTakenBorrow || hasCollateral;

      const tokenBorrowLimit = new DefiUtils(
        borrowLimitUSD,
      ).isGreaterThanOrEqualTo(new DefiUtils(borrowBalanceUSD))
        ? new DefiUtils(borrowLimitUSD)
            .multipliedBy(MAX_BORROW_LIMIT_MARGIN)
            .minus(borrowBalanceUSD)
            .fromUSD(ushToken.priceUSD)
            .toString()
        : '0';

      const suggestedTokenBorrowLimit = new DefiUtils(
        borrowLimitUSD,
      ).isGreaterThanOrEqualTo(new DefiUtils(borrowBalanceUSD))
        ? new DefiUtils(borrowLimitUSD)
            .multipliedBy(MAX_RECOMMENDED_BORROW_LIMIT_MARGIN)
            .minus(borrowBalanceUSD)
            .fromUSD(ushToken.priceUSD)
            .toString()
        : '0';

      const borrowLimitUsedPercent = new DefiUtils(borrowBalanceUSD)
        .dividedBy(borrowLimitUSD)
        .multipliedBy(100)
        .toSafeString();

      const s_minCollateralAmount = new DefiUtils(minCollateralAmount)
        .toFullDecimals(collateralToken.decimals)
        .toString();

      const s_borrowCap = borrowCap
        ? new DefiUtils(borrowCap).toFullDecimals(ushToken.decimals).toString()
        : null;

      const s_collateralCap = collateralCap
        ? new DefiUtils(collateralCap)
            .toFullDecimals(collateralToken.decimals)
            .toString()
        : null;

      const totalBorrowsUSD = new DefiUtils(totalBorrows)
        .toFullDecimals(ushToken.decimals)
        .toUSD(ushToken.priceUSD)
        .toString();
      const totalCollateralUSD = new DefiUtils(totalCollateral)
        .toFullDecimals(collateralToken.decimals)
        .toUSD(collateralToken.priceUSD)
        .toString();

      return {
        isolatedKey,
        borrowCap,
        collateralCap,
        tokensMap,
        collateralFactor,
        minCollateralAmount,
        s_minCollateralAmount,
        collateralTokenId,
        lsTokenId,
        hLsTokenId,
        s_borrowCap,
        s_collateralCap,
        totalBorrowsUSD,
        totalCollateralUSD,
        collateralToken,
        ushToken,
        lsToken,
        hLsToken,
        account: {
          collateralBalance,
          s_collateralBalance,
          borrowBalance,
          s_borrowBalance,
          collateralBalanceUSD,
          borrowBalanceUSD,
          borrowLimitUSD,
          hasTakenBorrow,
          tokenBorrowLimit,
          suggestedTokenBorrowLimit,
          borrowLimitUsedPercent,
          hasCollateral,
          hasPosition,
        },
      };
    },
  );
};

const PROTOCOL_MAP: Record<string, string> = {
  USH: 'egld',
  ['USH-EGLD']: 'egld',
  ['USH-USDC']: 'ashswap',
  ['USH-USDT-USDC']: 'ashswap',
};

const TOKEN_DESCRIPTION_MAP: Record<string, string> = {
  USH: 'Single Asset',
  SUSH: 'LP Token',
  ['USHWEGLD']: 'LP Token',
  METAUSHLK: 'LP Mestastaking Token',
  ['USHWEGLDFL']: 'Farm Token',
  ['USHUSDC']: 'LP Token',
  ['ALP']: 'LP Token',
  ['FARM']: 'Farm Token',
};

const formatStakePoolToken = ({
  tokenId,
  priceUSD,
  tokensMap,
  accountTokens,
  isMetaEsdt,
  accountStakingModuleData,
}: {
  tokenId: string;
  priceUSD: string;
  tokensMap: Record<string, Token>;
  accountTokens: AccountToken[];
  isMetaEsdt: boolean;
  accountStakingModuleData: AccountUSHStakingData[];
}) => {
  const tokenDetailsItem = tokensMap[tokenId.split('-')[0]];

  if (!tokenDetailsItem) {
    return null;
  }

  const nonce = isMetaEsdt ? tokenId.split('-')[2] : '0';

  const accountStakingModuleItem = accountStakingModuleData.find(
    (item) =>
      tokenId.includes(item.tokenId) &&
      parseInt(item.nonce) === parseInt(nonce, 16),
  );

  const accountToken = accountTokens.find(
    (accountTokenItem) => accountTokenItem.tokenIdentifier == tokenId,
  );

  const walletBalance = accountToken?.balance || '0';
  const s_walletBalance = new DefiUtils(walletBalance)
    .toFullDecimals(tokenDetailsItem.decimals)
    .toString();

  const defaultSymbol = tokenDetailsItem.symbol;

  const symbol = TOKEN_SYMBOL_MAP[defaultSymbol] || defaultSymbol;

  const stakedBalance = accountStakingModuleItem?.totalStake || '0';
  const s_stakedBalance = new DefiUtils(stakedBalance)
    .toFullDecimals(tokenDetailsItem.decimals)
    .toString();
  const stakedBalanceUSD = new DefiUtils(s_stakedBalance)
    .toUSD(priceUSD)
    .toString();

  return {
    isMetaEsdt,
    nonce,
    id: tokenId,
    description: TOKEN_DESCRIPTION_MAP[defaultSymbol] || '',
    symbol,
    defaultSymbol,
    logo:
      TOKEN_LOGO_V2_MAP[defaultSymbol] ||
      'https://cdn.app.hatom.com/images/coin_placeholder.png',
    inlineLogo:
      TOKEN_LOGO_V2_MAP[`${defaultSymbol}-INLINE`] ||
      'https://cdn.app.hatom.com/images/coin_placeholder.png',
    decimals: tokenDetailsItem.decimals,
    priceUSD,
    account: {
      walletBalance,
      s_walletBalance,
      stakedBalance,
      s_stakedBalance,
      stakedBalanceUSD,
    },
  };
};

export const formatStakePools = ({
  stakePools,
  tokensMap,
  accountTokens,
  tokenPrices,
  accountStakingModuleData,
}: {
  stakePools: USHStakingStakePools[];
  tokensMap: Record<string, Token>;
  accountTokens: AccountToken[];
  tokenPrices: BoosterV2TokenPriceUsdData[];
  accountStakingModuleData: AccountUSHStakingData[];
}): UshAppState['stakePools'] => {
  return stakePools
    .map((stakePoolItem) => {
      const tokenPricesItem = tokenPrices.find(
        (tokenPriceItem) => tokenPriceItem.tokenId === stakePoolItem.mainToken,
      );

      const priceUSD = new DefiUtils(tokenPricesItem?.amount || '0')
        .toFullDecimals(18)
        .toString();

      const mainToken = formatStakePoolToken({
        accountStakingModuleData,
        tokenId: stakePoolItem.mainToken,
        priceUSD,
        tokensMap,
        accountTokens,
        isMetaEsdt: false,
      });

      if (!mainToken) {
        return null;
      }

      const s_totalStake = new DefiUtils(
        stakePoolItem.totalStake,
      ).toFullDecimals(mainToken.decimals);
      const totalStakeUSD = s_totalStake.toUSD(mainToken.priceUSD);

      const poolTokens = stakePoolItem.poolTokens
        .map((tokenId) => {
          const tokenDetailsItem = tokensMap[tokenId.split('-')[0]];

          const isMetaEsdt = tokenDetailsItem?.type === 'MetaESDT';

          const accountMetaTokenDetails = accountTokens.filter((token) =>
            token.tokenIdentifier.includes(tokenId),
          );

          const foundedStakingModuleData = accountStakingModuleData.filter(
            (item) => tokenId.includes(item.tokenId),
          );

          const metaTokenIds: string[] = [
            ...(new Set([
              ...foundedStakingModuleData.map(
                ({ tokenId, nonce }) =>
                  `${tokenId}-${parseInt(nonce).toString(16).padStart(2, '0')}`,
              ),
              ...accountMetaTokenDetails.map(
                ({ tokenIdentifier }) => tokenIdentifier,
              ),
            ]) as any),
          ];

          if (!isMetaEsdt || metaTokenIds.length === 0) {
            return formatStakePoolToken({
              accountStakingModuleData,
              tokenId,
              priceUSD,
              tokensMap,
              accountTokens,
              isMetaEsdt: false,
            });
          }

          return metaTokenIds.map((tokenId) => {
            return formatStakePoolToken({
              accountStakingModuleData,
              tokenId,
              priceUSD,
              tokensMap,
              accountTokens,
              isMetaEsdt: true,
            });
          });
        })
        .flat()
        .filter((item) => item !== null);

      const accountTotalStakedUSD = [mainToken, ...poolTokens]
        .reduce(
          (prev, current) => prev.plus(current.account.stakedBalanceUSD),
          new DefiUtils(0),
        )
        .toString();

      const accountTotalStakedBalance = [mainToken, ...poolTokens]
        .reduce(
          (prev, current) => prev.plus(current.account.s_stakedBalance),
          new DefiUtils(0),
        )
        .toString();

      return {
        id: stakePoolItem.id,
        protocol: PROTOCOL_MAP[mainToken.symbol] || '',
        mainToken,
        poolTokens,
        logo: TOKEN_LOGO_V2_MAP[`${mainToken.defaultSymbol}-LP`] || '',
        s_totalStake: s_totalStake.toString(),
        totalStakeUSD: totalStakeUSD.toString(),
        account: {
          totalStakedUSD: accountTotalStakedUSD,
          totalStaked: accountTotalStakedBalance,
        },
      };
    })
    .filter((item) => item !== null);
};
