import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';

import { boosterV2Selector } from '@/store/booster-v2';
import { useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import {
  H_TOKEN_DECIMALS,
  nativeMarketSelector,
  sEgldMarketSelector,
  sEgldUserBalanceSelector,
} from '@/store/protocol';

import { getTotalSupplyAPYByMarketLiquidity } from '@/utils/math/apy';

export enum Operation {
  STAKE = 'stake',
  UNSTAKE = 'unstake',
  MIGRATE = 'migrate',
}

const useLiquidStakingFormValue = () => {
  const { t } = useTranslation('liquid-app');
  const { apy } = useAppSelector(liquidStakingAppSelector);
  const {
    controllerAccount,
    controllerRewardBatches: controllerRewardBatchesV2,
  } = useAppSelector(boosterV2Selector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);
  const { isLoggedIn } = useLogin();

  const segldControllerRewardsV2 = controllerRewardBatchesV2.find(
    ({ tokenId }) => tokenId === sEgldMarket.hToken.id,
  );

  const [formsState, setFormsState] = useState({
    operation: Operation.STAKE,
    stakingInputs: {
      inputRef: useRef<HTMLInputElement>(null),
      token: EXCHANGE_RATE_KEY.sEGLD,
      supplyHsEGLd: true,
      inputValue: '0',
      estimation: '0',
      annualEarnings: '0',
      maxSelected: false,
      changeToken: (token: EXCHANGE_RATE_KEY) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: { ..._.stakingInputs, token },
        }));
      },
      check: () => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            supplyHsEGLd: !_.stakingInputs.supplyHsEGLd,
          },
        }));
      },
      onEstimation: (estimation: string) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            estimation,
          },
        }));
      },
      onAnnualEarnings: (annualEarnings: string) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            annualEarnings,
          },
        }));
      },
      onChange: (inputValue: string) => {
        inputValue = inputValue ?? '0';

        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            inputValue,
          },
        }));
      },
      onMax: (maxSelected: boolean) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            maxSelected,
          },
        }));
      },
      reset: () => {
        setFormsState((_: any) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            inputValue: '0',
          },
        }));
      },
    },
    unstakingInputs: {
      inputRef: useRef<HTMLInputElement>(null),
      inputTokenRef: useRef<HTMLInputElement>(null),
      token: EXCHANGE_RATE_KEY.sEGLD,
      estimation: '0',
      inputValue: '0',
      maxSelected: false,
      onEstimation: (estimation: string) => {
        setFormsState((_) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            estimation,
          },
        }));
      },
      changeToken: (token: EXCHANGE_RATE_KEY) => {
        setFormsState((_) => ({
          ..._,
          unstakingInputs: { ..._.unstakingInputs, token },
        }));
      },
      onMax: (maxSelected: boolean) => {
        setFormsState((_) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            maxSelected,
          },
        }));
      },
      onChange: (inputValue: string) => {
        inputValue = inputValue ?? '0';
        setFormsState((_) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            inputValue,
          },
        }));
      },
      reset: () => {
        setFormsState((_: any) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            inputValue: '0',
          },
        }));
      },
    },
    migratingInputs: {
      inputValue: '0',
      inputRef: useRef<HTMLInputElement>(null),
      maxSelected: false,
      supplyHsEGLd: true,
      onMax: (maxSelected: boolean) => {
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            maxSelected,
          },
        }));
      },
      onChange: (inputValue: string) => {
        inputValue = inputValue ?? '0';
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            inputValue,
          },
        }));
      },
      check: () => {
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            supplyHsEGLd: !_.migratingInputs.supplyHsEGLd,
          },
        }));
      },
      reset: () => {
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            inputValue: '0',
          },
        }));
      },
    },
  });

  const segldToHsegld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.HsEGLD,
  );

  const totalRewardsSupplyAPY = useMemo(
    () => segldControllerRewardsV2?.totalAPY || '0',
    [segldControllerRewardsV2?.totalAPY],
  );

  const userRewardsSupplyAPY = useMemo(
    () => segldControllerRewardsV2?.account.totalAPY || '0',
    [segldControllerRewardsV2],
  );

  const showMarketApy = useMemo(() => {
    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld);

    const hTokenBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    const collateralBalance = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    return !(
      hsEgldBalance.isZero() ||
      (hTokenBalance.isZero() && collateralBalance.isZero())
    );
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.collateralBalance,
    sEgldMarket.underlying.decimals,
    segldToHsegld,
  ]);

  const showRewardsApy = useMemo(() => {
    if (!isLoggedIn) {
      return false;
    }

    return (
      userRewardsSupplyAPY !== '0' &&
      userRewardsSupplyAPY !== '0' &&
      userRewardsSupplyAPY !== segldControllerRewardsV2?.totalAPY
    );
  }, [isLoggedIn, userRewardsSupplyAPY, segldControllerRewardsV2?.totalAPY]);

  const showBoosterApy = useMemo(() => {
    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld);
    const hTokenBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    const collateralBalance = new DefiUtils(sEgldUserBalance.collateralBalance);

    if (new DefiUtils(controllerAccount.instaCompliance).isLessThan(1))
      return true;

    return !(
      (hsEgldBalance.isZero() && hTokenBalance.isZero()) ||
      collateralBalance.isZero()
    );
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.collateralBalance,
    sEgldMarket.underlying.decimals,
    segldToHsegld,
    controllerAccount.instaCompliance,
  ]);

  const percentages = useMemo(() => {
    const hasBalance = new DefiUtils(
      sEgldUserBalance.underlyingBalance,
    ).isGreaterThan('0');
    const hasCollateral = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).isGreaterThan('0');
    const hasTokenWalletBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).isGreaterThan('0');

    const hasLoginValidation =
      isLoggedIn && (hasBalance || hasCollateral || hasTokenWalletBalance);

    const option1 = {
      value: apy,
      color: '#E25F00',
      label: '',
      isActive: true,
    };

    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld)
      .toString();
    const hTokenBalance = new DefiUtils(sEgldUserBalance.hTokenBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
    const collateralBalance = new DefiUtils(sEgldUserBalance.collateralBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();

    const supplyAPY = sEgldMarket.supplyAPY;

    const lendingAPY = new DefiUtils(
      new DefiUtils(hTokenBalance).plus(collateralBalance),
    )
      .multipliedBy(supplyAPY)
      .dividedBy(
        new DefiUtils(hsEgldBalance)
          .plus(hTokenBalance)
          .plus(collateralBalance),
      );

    const option2 = {
      value:
        new DefiUtils(hsEgldBalance)
          .plus(hTokenBalance)
          .plus(collateralBalance)
          .isZero() ||
        lendingAPY.isNaN() ||
        lendingAPY.isZero()
          ? supplyAPY
          : lendingAPY.toString(),
      label: t('lending'),
      color: '#8c80d4',
      isActive: hasLoginValidation
        ? hasTokenWalletBalance || hasCollateral
        : formsState.stakingInputs.token === EXCHANGE_RATE_KEY.HsEGLD,
    };

    const option3 = {
      value: new DefiUtils(userRewardsSupplyAPY).isZero()
        ? segldControllerRewardsV2?.totalAPY || '0'
        : userRewardsSupplyAPY,
      label: 'Booster',
      color: '#E24949',
      isActive: hasLoginValidation
        ? hasCollateral
        : formsState.stakingInputs.token === EXCHANGE_RATE_KEY.HsEGLD &&
          formsState.stakingInputs.supplyHsEGLd,
    };

    return [option1, option2, option3];
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.collateralBalance,
    sEgldUserBalance.hTokenBalance,
    isLoggedIn,
    apy,
    sEgldMarket.underlying.decimals,
    sEgldMarket.supplyAPY,
    segldToHsegld,
    t,
    formsState.stakingInputs.token,
    formsState.stakingInputs.supplyHsEGLd,
    userRewardsSupplyAPY,
    segldControllerRewardsV2?.totalAPY,
  ]);

  const userTotalAPY = useMemo(() => {
    return getTotalSupplyAPYByMarketLiquidity({
      accountBalance: '0',
      // accountBalance: accountBalances.underlyingWallet, // aca_aca:fix
      hTokenExchangeRate: sEgldMarket.hTokenExchangeRate,
      hTokenAccountBalance: sEgldUserBalance.hTokenBalance,
      collateralBalance: sEgldUserBalance.collateralBalance,
      liquidStakingAPY: apy,
      supplyAPY: sEgldMarket.supplyAPY,
      rewardsTokensAPY: userRewardsSupplyAPY,
    });
  }, [
    sEgldMarket.hTokenExchangeRate,
    sEgldMarket.supplyAPY,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.collateralBalance,
    apy,
    userRewardsSupplyAPY,
  ]);

  const totalAPY = useMemo(() => {
    const hasBalance = new DefiUtils(
      sEgldUserBalance.underlyingBalance,
    ).isGreaterThan('0');
    const hasCollateral = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).isGreaterThan('0');
    const hasTokenWalletBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).isGreaterThan('0');

    const hasLoginValidation =
      isLoggedIn && (hasBalance || hasCollateral || hasTokenWalletBalance);

    const percentagesActives = percentages.filter(
      ({ isActive }) => isActive,
    ).length;

    if (hasLoginValidation) {
      return userTotalAPY;
    }

    if (percentagesActives === 1) {
      return apy;
    }

    if (percentagesActives === 2) {
      return new DefiUtils(apy).plus(sEgldMarket.supplyAPY).toString();
    }

    if (
      !isLoggedIn &&
      formsState.operation === 'stake' &&
      formsState.stakingInputs.supplyHsEGLd &&
      formsState.stakingInputs.token === 'HSEGLD'
    ) {
      return new DefiUtils(apy)
        .plus(sEgldMarket.supplyAPY)
        .plus(totalRewardsSupplyAPY)
        .toString();
    }

    return new DefiUtils(apy)
      .plus(sEgldMarket.supplyAPY)
      .plus(totalRewardsSupplyAPY)
      .toString();
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.collateralBalance,
    sEgldUserBalance.hTokenBalance,
    isLoggedIn,
    percentages,
    formsState.operation,
    formsState.stakingInputs.supplyHsEGLd,
    formsState.stakingInputs.token,
    apy,
    sEgldMarket.supplyAPY,
    totalRewardsSupplyAPY,
    userTotalAPY,
  ]);

  useEffect(() => {
    const estimation = new DefiUtils(formsState.stakingInputs.inputValue || '0')
      .toUSD(nativeMarket.underlying.priceUSD)
      .toString();

    const token = formsState.stakingInputs.token;
    const supplyHsEGLd = formsState.stakingInputs.supplyHsEGLd;

    let interactiveAPY = '0';

    if (token === EXCHANGE_RATE_KEY.sEGLD) {
      interactiveAPY = apy;
    } else if (token === EXCHANGE_RATE_KEY.HsEGLD && !supplyHsEGLd) {
      interactiveAPY = new DefiUtils(apy)
        .plus(sEgldMarket.supplyAPY)
        .toString();
    } else if (token === EXCHANGE_RATE_KEY.HsEGLD && supplyHsEGLd) {
      interactiveAPY = new DefiUtils(apy)
        .plus(sEgldMarket.supplyAPY)
        .plus(totalRewardsSupplyAPY)
        .toString();
    }

    const annualEarnings = new DefiUtils(estimation)
      .multipliedBy(interactiveAPY)
      .dividedBy(100)
      .toString();

    formsState.stakingInputs.onEstimation(estimation);
    formsState.stakingInputs.onAnnualEarnings(annualEarnings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formsState.stakingInputs.token,
    formsState.stakingInputs.supplyHsEGLd,
    formsState.stakingInputs.inputValue,
    nativeMarket.underlying.priceUSD,
    apy,
    sEgldMarket.supplyAPY,
    totalRewardsSupplyAPY,
  ]);

  useEffect(() => {
    switch (formsState.unstakingInputs.token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        const estimation = new DefiUtils(
          formsState.unstakingInputs.inputValue || 0,
        )
          .toUSD(sEgldMarket.hToken.priceUSD)
          .toString();

        formsState.unstakingInputs.onEstimation(estimation);
        break;
      }

      default: {
        formsState.unstakingInputs.onEstimation('0');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formsState.unstakingInputs.inputValue,
    formsState.unstakingInputs.token,
    nativeMarket.underlying.priceUSD,
    nativeMarket.hToken.priceUSD,
    totalAPY,
  ]);

  const staking = () => formsState.operation == Operation.STAKE;
  const unstaking = () => formsState.operation == Operation.UNSTAKE;
  const migrating = () => formsState.operation == Operation.MIGRATE;

  return {
    formsState,
    setFormsState,
    totalAPY,
    percentages,
    rewardsTokensAPY: totalRewardsSupplyAPY,
    boosterRewardsAPY: segldControllerRewardsV2?.totalAPY || '0',
    userRewardsSupplyAPY,
    showMarketApy,
    showRewardsApy,
    showBoosterApy,
    staking,
    unstaking,
    migrating,
  };
};

const LiquidStakingFormContext = createContext(
  {} as ReturnType<typeof useLiquidStakingFormValue>,
);

type LiquidStakingFormProviderProps = PropsWithChildren;

export const LiquidStakingFormProvider: FC<LiquidStakingFormProviderProps> = ({
  children,
}) => {
  const values = useLiquidStakingFormValue();

  return (
    <LiquidStakingFormContext.Provider value={values}>
      {children}
    </LiquidStakingFormContext.Provider>
  );
};

export const useLiquidStakingForm = () => {
  const context = useContext(LiquidStakingFormContext);

  if (!context) {
    throw new Error('useLiquidStakingForm must be used within an AppProvider');
  }

  return context;
};
