import DefiUtils from 'defi-utils';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';

import BrandPieChart, {
  BrandPieChartWrapper,
} from '@/components/BrandPieChart';
import DataRowList from '@/components/popups/LendingCurrentPositionPopup/components/DataRowList';
import TitleButton from '@/components/popups/LendingCurrentPositionPopup/components/TitleButton';

import clsxm from '@/services/clsxm';
import { parseSmallNumber } from '@/utils/parseSmallNumber';

type PositionBlockProps = {
  data: any;
  totalExpectedAnnualReturnUSD: number | string;
  borrow?: boolean;
  className?: string;
};

export default function PositionBlock({
  data,
  totalExpectedAnnualReturnUSD,
  borrow = false,
  className = '',
}: PositionBlockProps) {
  const svgRef = useRef<SVGElement>(null);
  const { t } = useTranslation();

  let SAFE_PERCENT = 5;

  const safePercentages = useMemo(() => {
    let pumped = new Map<number, number>();
    return cloneDeep(data)
      .map((e: any, i: number) => {
        if (e.percentage < SAFE_PERCENT && e.percentage > 0) {
          pumped.set(i, SAFE_PERCENT - e.percentage);
          e.percentage = SAFE_PERCENT;
        } else if (e.percentage - SAFE_PERCENT < SAFE_PERCENT) {
          pumped.set(i, 0);
        }
        return e;
      })
      .map((e: any, i: number) => {
        if (pumped.has(i)) return e;

        const unpumpedLen = data.length - Array.from(pumped.keys()).length;

        const sum =
          (Array.from(pumped.values()).reduce((a, b) => a + b, 0) ?? 0) /
          unpumpedLen;

        if (e.token - sum < SAFE_PERCENT) return e;

        e.percentage = e.percentage - sum;
        return e;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const doOrder = useRef(true);
  /* 
    tail is a dot at the end of an arc, 
    this logic in order to repositioning the dot in z axis, 
    and the only way is to reorder the dom element
  */
  useEffect(() => {
    if (!svgRef.current) return;
    const $0 = svgRef.current;
    const childrenGroup = svgRef.current.querySelector('g');
    if (!childrenGroup) return;

    // todo: reorder once
    if (doOrder.current) {
      const len = childrenGroup.children.length;
      for (let i = len - 1; i >= 0; i--)
        childrenGroup.appendChild(childrenGroup.children[i]);
      doOrder.current = false;
    }

    const tail = childrenGroup.querySelector('[data-js=tail]');
    if (!tail) return;
    childrenGroup?.removeChild(tail);
    $0.append(tail);
  }, [svgRef, safePercentages, data]);

  const renderArcProps = (array: Array<Record<any, any>>, i: number) => {
    const { percentage, accentColor, token, collateral, ...props } = array[i];
    // @ts-ignore
    const lastIndex = array.findLastIndex((x) => x.percentage) ?? -0;
    return {
      [`data-params`]: `${percentage | 0}-${token}${
        collateral ? '-collateral' : ''
      }`,
      tail: i === lastIndex,
      color: accentColor,
      percentage: percentage,
      rotateTurn: array
        .slice(0, i)
        .map((r) => r.percentage)
        .reduce((a, b) => a + b, 0),
      ...props,
    };
  };

  const [hoveredArc, setHoveredArc] = useState(new Map());

  const totalExpectedAnnualReturnUSDFormated = useMemo(() => {
    return parseSmallNumber(totalExpectedAnnualReturnUSD, true);
  }, [totalExpectedAnnualReturnUSD]);

  return (
    <div className={clsxm('space-y-3', className)}>
      <TitleButton className='hidden md:flex' borrow={borrow} />
      <div className='grid min-h-[192px] gap-5 text-[12px] sm:grid-cols-[160px,1fr] sm:gap-[66px] md:gap-8'>
        <BrandPieChartWrapper
          width={160}
          height={160}
          className='mt-2 place-self-center sm:place-self-start md:mt-4'
          ref={svgRef}
        >
          <BrandPieChart color='#340175' placeholder />
          <g>
            {data.map((_: any, i: number) => (
              <BrandPieChart
                key={i}
                handleHover={(state: any) => {
                  setHoveredArc((_) => {
                    const map = new Map(_);
                    map.set(safePercentages[i].id, state);
                    return map;
                  });
                }}
                {...renderArcProps(safePercentages, i)}
              />
            ))}
          </g>
        </BrandPieChartWrapper>
        <div>
          <DataRowList data={data} isHovered={(id) => hoveredArc.get(id)} />
          <div className='text-[#74748E]/88 mt-3 flex items-center justify-between border-t-2 border-[#535367] pt-2'>
            {borrow
              ? t('total-expected-annual-debts', 'Total Expected Annual Debts')
              : t(
                  'total-expected-annual-returns',
                  'Total Expected Annual Returns',
                )}
            :
            <span
              className={
                new DefiUtils(totalExpectedAnnualReturnUSD).isLessThan('0')
                  ? 'text-[#E45059]'
                  : 'text-[#4FD1A6]'
              }
            >
              ≈ {totalExpectedAnnualReturnUSDFormated}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
