import { createContext } from 'react';

import useStoreIntervalProtocolInfo from '@/contexts/ProtocolContext/hooks/useStoreIntervalProtocolInfo';
import useStoreIntervalTimeLeft from '@/contexts/ProtocolContext/hooks/useStoreIntervalTimeLeft';
import useStoreProtocolInfo from '@/contexts/ProtocolContext/hooks/useStoreProtocolInfo';
import useStoreUserBalances from '@/contexts/ProtocolContext/hooks/useStoreUserBalances';

const ProtocolContext = createContext({});

const ProtocolConsumer = ProtocolContext.Consumer;

const ProtocolProvider = ({ children }: { children: JSX.Element }) => {
  useStoreProtocolInfo();
  useStoreIntervalProtocolInfo();
  useStoreIntervalTimeLeft();
  useStoreUserBalances();

  return (
    <ProtocolContext.Provider value={{}}>{children}</ProtocolContext.Provider>
  );
};

export default ProtocolContext;

export { ProtocolConsumer, ProtocolProvider };
