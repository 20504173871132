function DoubleArrowsRight({
  className,
  stroke,
}: {
  className: string;
  stroke: string;
}) {
  return (
    <svg
      className={className}
      width='13'
      height='11'
      viewBox='0 0 13 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 1.34766L11.5 5.34766L7.5 9.34766M1.5 1.34766L5.5 5.34766L1.5 9.34766'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default DoubleArrowsRight;
