import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';

import { ReturnUseBorrowForm } from '@/components/popups/BorrowPopup/hooks/useBorrowForm';
import { TABS } from '@/components/popups/BorrowPopup/types/tab';

import { useAppSelector } from '@/store';
import { lendAppSelector } from '@/store/lend-app';
import { popupSelector } from '@/store/popup';
import {
  calcHasMaxMarketPerAccount,
  hasEnoughEGLDBalanceSelector,
  MARKET_KEY,
  nativeMarketSelector,
  protocolSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';
import { hasPendingTransactionsSelector } from '@/store/transaction';

import { getBorrowLimitUsedPercent, subtractGasFee } from '@/utils/helpers';
import { getTotalBorrowAPYByMarket } from '@/utils/math/apy';

const estimateDailyAccruedInterest = (
  underlyingAmount: string | number,
): DefiUtils => {
  return new DefiUtils(underlyingAmount).multipliedBy(0.0001);
};

const useBorrowData = ({ borrowForm }: { borrowForm: ReturnUseBorrowForm }) => {
  const {
    inputValue,
    repayDailyInterestSelected,
    inputValueAsBigNumber,
    selectedTab,
    isBorrowTab,
    isRepayTab,
    maxSelected,
  } = borrowForm;

  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    userBalances,
    controller,
    marketsInteracted,
    marketsInteractedAmount,
  } = useAppSelector(protocolSelector);

  const { markets } = useAppSelector(lendAppSelector);
  const {
    showLiquidStakingAPY: showLiquidStakingAPYRaw,
    showLiquidStakingTaoAPY: showLiquidStakingTaoAPYRaw,
    account: { borrowBalanceUSD, borrowLimitUSD },
  } = useAppSelector(lendAppSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);

  const {
    data: { assetKey },
  } = useAppSelector(popupSelector);

  const hasMaxMarketPerAccount = useMemo(
    () =>
      calcHasMaxMarketPerAccount({
        userBalances,
        controller,
        token: assetKey,
      }),
    [assetKey, controller, userBalances],
  );
  const { isLoggedIn } = useLogin();
  const { t } = useTranslation();

  const showLiquidStakingAPY: boolean = useMemo(
    () => assetKey === MARKET_KEY.sEGLD && showLiquidStakingAPYRaw,
    [showLiquidStakingAPYRaw, assetKey],
  );

  const showLiquidStakingTaoAPY: boolean = useMemo(
    () => assetKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPYRaw,
    [showLiquidStakingTaoAPYRaw, assetKey],
  );

  const market = markets[assetKey as MARKET_KEY];

  const nativeMarket = useAppSelector(nativeMarketSelector);

  const rewardsBorrowToken = useMemo(() => {
    const rewards = rewardMarkets[assetKey as MARKET_KEY]?.rewards || [];

    return rewards
      .filter(({ type }) => type === 'Borrow')
      .filter(({ speed }) => new DefiUtils(speed).isGreaterThan('0'));
  }, [assetKey, rewardMarkets]);

  const isValidInput = useMemo(
    () =>
      inputValue && new DefiUtils(inputValue).isGreaterThan(0) ? true : false,
    [inputValue],
  );

  const maxMarketReservesToBorrow = useMemo<string>(() => {
    const value = new DefiUtils(market.cash).minus(
      new DefiUtils(market.totalBorrow).multipliedBy('0.0001'),
    );

    return value.isLessThanOrEqualTo(0)
      ? '0'
      : value.toFixed(0, DefiUtils.ROUND_FLOOR);
  }, [market.cash, market.totalBorrow]);

  const s_underlyingBorrowBalance = useMemo(
    () =>
      new DefiUtils(market.accountBalances.borrow)
        .toFullDecimals(market.underlying.decimals)
        .toNumber(),
    [market.accountBalances.borrow, market.underlying.decimals],
  );

  // includes estimated daily interest to repay -> Borrow Balance + 1%
  const s_borrowIncludeDailyInterest = useMemo(
    () =>
      new DefiUtils(s_underlyingBorrowBalance)
        .plus(estimateDailyAccruedInterest(s_underlyingBorrowBalance))
        .toNumber(),
    [s_underlyingBorrowBalance],
  );

  const maxAccountBalanceAsBigNumber = useMemo(
    () =>
      assetKey === nativeMarket.underlying.symbol
        ? subtractGasFee(market.accountBalances.underlyingWallet)
        : market.accountBalances.underlyingWallet,
    [
      market.accountBalances.underlyingWallet,
      assetKey,
      nativeMarket.underlying.symbol,
    ],
  );

  const s_accountBalance = useMemo(
    () =>
      new DefiUtils(market.accountBalances.underlyingWallet)
        .toFullDecimals(market.underlying.decimals)
        .toNumber(),
    [market.accountBalances.underlyingWallet, market.underlying.decimals],
  );

  const borrowTokenBalance = useMemo(
    () =>
      repayDailyInterestSelected
        ? s_borrowIncludeDailyInterest
        : s_underlyingBorrowBalance,
    [
      s_borrowIncludeDailyInterest,
      repayDailyInterestSelected,
      s_underlyingBorrowBalance,
    ],
  );

  const borrowTokenBalanceAsBigNumber = useMemo(
    () =>
      new DefiUtils(
        new DefiUtils(borrowTokenBalance).toFixed(market.underlying.decimals),
      )
        .multipliedBy(`1e${market.underlying.decimals}`)
        .toString(),
    [borrowTokenBalance, market.underlying.decimals],
  );

  const maxRepayAmountAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxAccountBalanceAsBigNumber).isGreaterThanOrEqualTo(
        new DefiUtils(borrowTokenBalanceAsBigNumber),
      )
        ? borrowTokenBalanceAsBigNumber
        : maxAccountBalanceAsBigNumber,
    [borrowTokenBalanceAsBigNumber, maxAccountBalanceAsBigNumber],
  );

  const getRemainingTokenLimitAsBigNumber = () => {
    const remainingLimitUSD = new DefiUtils(borrowLimitUSD)
      .multipliedBy(MAX_BORROW_LIMIT_MARGIN)
      .minus(borrowBalanceUSD);

    const remainingTokenLimitAsBigNumber = new DefiUtils(remainingLimitUSD)
      .fromUSD(market.underlying.priceUSD)
      .multipliedBy(`1e${market.underlying.decimals}`)
      .toFixed(0, DefiUtils.ROUND_FLOOR);

    return remainingTokenLimitAsBigNumber;
  };

  const getRemainingTokenLimitAsBigNumber2 = () => {
    const remainingLimitUSD = new DefiUtils(borrowLimitUSD)
      .multipliedBy(MAX_RECOMMENDED_BORROW_LIMIT_MARGIN)
      .minus(borrowBalanceUSD);

    const remainingTokenLimitAsBigNumber = new DefiUtils(remainingLimitUSD)
      .fromUSD(market.underlying.priceUSD)
      .multipliedBy(`1e${market.underlying.decimals}`)
      .toFixed(0, DefiUtils.ROUND_FLOOR);

    return remainingTokenLimitAsBigNumber;
  };

  const getRemainingTokenBorrowAmountAsBigNumber = (): string => {
    if (
      new DefiUtils(borrowLimitUSD).isGreaterThanOrEqualTo(
        new DefiUtils(borrowBalanceUSD),
      )
    ) {
      const remainingTokenLimitAsBigNumber =
        getRemainingTokenLimitAsBigNumber();

      return new DefiUtils(remainingTokenLimitAsBigNumber).isLessThan(
        maxMarketReservesToBorrow,
      )
        ? remainingTokenLimitAsBigNumber
        : maxMarketReservesToBorrow;
    }

    return '0';
  };

  const getRemainingTokenBorrowAmountAsBigNumber2 = (): string => {
    if (
      new DefiUtils(borrowLimitUSD).isGreaterThanOrEqualTo(borrowBalanceUSD)
    ) {
      const remainingTokenLimitAsBigNumber =
        getRemainingTokenLimitAsBigNumber2();

      return new DefiUtils(remainingTokenLimitAsBigNumber).isLessThan(
        maxMarketReservesToBorrow,
      )
        ? remainingTokenLimitAsBigNumber
        : maxMarketReservesToBorrow;
    }

    return '0';
  };

  const estimatedUnderlyingDailyInterestAsBigNumber = useMemo(
    () =>
      estimateDailyAccruedInterest(market.accountBalances.borrow).toNumber(),
    [market.accountBalances.borrow],
  );

  const remainingTokenLimitAsBigNumber = getRemainingTokenLimitAsBigNumber();

  const isMaxMarketReservesToBorrowGreatherThanRemainingTokenLimit = useMemo(
    () =>
      new DefiUtils(maxMarketReservesToBorrow).isGreaterThan(
        remainingTokenLimitAsBigNumber,
      ),
    [maxMarketReservesToBorrow, remainingTokenLimitAsBigNumber],
  );

  const tokenBorrowLimitAsBigNumber =
    getRemainingTokenBorrowAmountAsBigNumber();

  const hasEnoughMarketReserves = useMemo(
    () =>
      new DefiUtils(maxMarketReservesToBorrow).isGreaterThanOrEqualTo(
        new DefiUtils(inputValueAsBigNumber || 0),
      ),
    [inputValueAsBigNumber, maxMarketReservesToBorrow],
  );

  const hasEnoughCollateral = useMemo(
    () =>
      new DefiUtils(remainingTokenLimitAsBigNumber).isGreaterThanOrEqualTo(
        inputValueAsBigNumber,
      ),
    [inputValueAsBigNumber, remainingTokenLimitAsBigNumber],
  );

  const remainingTokenBorrowLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(tokenBorrowLimitAsBigNumber)
        .minus(estimatedUnderlyingDailyInterestAsBigNumber)
        .toFixed(0, DefiUtils.ROUND_FLOOR),
    [estimatedUnderlyingDailyInterestAsBigNumber, tokenBorrowLimitAsBigNumber],
  );

  const suggestedTokenBorrowLimitAsBigNumber =
    getRemainingTokenBorrowAmountAsBigNumber2();

  const repaySetMaxValueAsBigNumber = maxRepayAmountAsBigNumber;

  const repayLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxAccountBalanceAsBigNumber).isGreaterThanOrEqualTo(
        new DefiUtils(market.accountBalances.borrow),
      )
        ? borrowTokenBalanceAsBigNumber
        : maxAccountBalanceAsBigNumber,
    [
      market.accountBalances.borrow,
      borrowTokenBalanceAsBigNumber,
      maxAccountBalanceAsBigNumber,
    ],
  );

  const borrowLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxMarketReservesToBorrow).isLessThanOrEqualTo(
        tokenBorrowLimitAsBigNumber,
      )
        ? maxMarketReservesToBorrow
        : new DefiUtils(remainingTokenBorrowLimitAsBigNumber).isGreaterThan('0')
        ? new DefiUtils(remainingTokenBorrowLimitAsBigNumber).toFixed(
            0,
            DefiUtils.ROUND_FLOOR,
          )
        : new DefiUtils(tokenBorrowLimitAsBigNumber).toFixed(
            0,
            DefiUtils.ROUND_FLOOR,
          ),
    [
      maxMarketReservesToBorrow,
      remainingTokenBorrowLimitAsBigNumber,
      tokenBorrowLimitAsBigNumber,
    ],
  );

  const borrowSetMaxValueAsBigNumber = suggestedTokenBorrowLimitAsBigNumber;

  const maxRequestAmounts = useMemo(() => {
    const map = {
      [TABS.BORROW]: {
        setMaxValueAsBigNumber: borrowSetMaxValueAsBigNumber,
        limitAsBigNumber: borrowLimitAsBigNumber,
      },
      [TABS.REPAY_BORROW]: {
        setMaxValueAsBigNumber: repaySetMaxValueAsBigNumber,
        limitAsBigNumber: repayLimitAsBigNumber,
      },
    };

    return map[selectedTab];
  }, [
    borrowLimitAsBigNumber,
    borrowSetMaxValueAsBigNumber,
    repayLimitAsBigNumber,
    repaySetMaxValueAsBigNumber,
    selectedTab,
  ]);

  const footerNotes = useMemo(() => {
    const map = {
      [TABS.BORROW]: [
        {
          label: t('currently-borrowing'),
          value: s_underlyingBorrowBalance,
        },
        // { label: "Token Borrow Limit", value: remainingTokenBorrowLimit },
      ],
      [TABS.REPAY_BORROW]: [
        {
          label: t('owed-amount'),
          value: s_underlyingBorrowBalance,
        },
        { label: t('wallet-balance'), value: s_accountBalance },
      ],
    };

    return map[selectedTab];
  }, [t, s_underlyingBorrowBalance, s_accountBalance, selectedTab]);

  const hasFunds = useMemo(
    () => new DefiUtils(maxRequestAmounts.limitAsBigNumber).isGreaterThan(0),
    [maxRequestAmounts],
  );

  const isValidAmountRequest = useMemo(
    () =>
      isValidInput
        ? new DefiUtils(inputValueAsBigNumber).isLessThanOrEqualTo(
            maxRequestAmounts.limitAsBigNumber,
          )
        : false,
    [inputValueAsBigNumber, isValidInput, maxRequestAmounts],
  );

  const inputPriceUSD = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue).toUSD(market.underlying.priceUSD).toNumber()
        : 0,
    [inputValue, isValidAmountRequest, market.underlying.priceUSD],
  );

  const calcNextValue = () => {
    if (isBorrowTab) {
      return new DefiUtils(borrowBalanceUSD).plus(inputPriceUSD).toNumber();
    }

    const result = new DefiUtils(borrowBalanceUSD)
      .minus(inputPriceUSD)
      .toNumber();

    return result > 0 ? result : 0;
  };

  const nextBorrowAmountUSD = isValidAmountRequest ? calcNextValue() : 0;

  const nextBorrowLimitUsedPercentage =
    isValidAmountRequest &&
    nextBorrowAmountUSD &&
    getBorrowLimitUsedPercent(nextBorrowAmountUSD, borrowLimitUSD);

  const isMaxBorrowCap = useMemo(() => {
    if (!isBorrowTab || market.borrowCap === 'Infinity') {
      return false;
    }

    const totalBorrowUnderlying = new DefiUtils(market.totalBorrow)
      .toFullDecimals(market.underlying.decimals)
      .toString();

    const futureBorrowCap = new DefiUtils(totalBorrowUnderlying)
      .plus(inputValue)
      .toString();

    return new DefiUtils(futureBorrowCap).isGreaterThanOrEqualTo(
      market.borrowCap,
    );
  }, [
    market.totalBorrow,
    market.underlying.decimals,
    isBorrowTab,
    inputValue,
    market.borrowCap,
  ]);

  const totalBorrowAPY = useMemo(() => {
    const total = getTotalBorrowAPYByMarket({
      borrowAPY: market.borrowAPY,
      rewardsTokensAPY: rewardsBorrowToken
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString(),
    });

    return total
      .plus(
        showLiquidStakingAPY
          ? liquidStakingAPY
          : showLiquidStakingTaoAPY
          ? liquidStakingTaoAPY
          : '0',
      )
      .toString();
  }, [
    market.borrowAPY,
    rewardsBorrowToken,
    showLiquidStakingAPY,
    liquidStakingAPY,
    showLiquidStakingTaoAPY,
    liquidStakingTaoAPY,
  ]);

  const showMaxButton = isBorrowTab;

  return {
    hasEnoughEGLDBalance,
    hasPendingTransactions,
    hasMaxMarketPerAccount,
    isLoggedIn,
    liquidStakingAPY,
    liquidStakingTaoAPY,
    market,
    marketsInteracted,
    marketsInteractedAmount,
    maxMarketReservesToBorrow,
    borrowTokenBalance,
    borrowTokenBalanceAsBigNumber,
    maxAccountBalanceAsBigNumber,
    borrowBalanceUSD,
    borrowLimitUSD,
    rewardsBorrowToken,
    isValidInput,
    selectedTab,
    isBorrowTab,
    isRepayTab,
    inputValue,
    inputValueAsBigNumber,
    hasEnoughMarketReserves,
    hasEnoughCollateral,
    hasFunds,
    isValidAmountRequest,
    inputPriceUSD,
    nextBorrowAmountUSD,
    nextBorrowLimitUsedPercentage,
    isMaxBorrowCap,
    showMaxButton,
    maxRequestAmounts,
    footerNotes,
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
    controller,
    isMaxMarketReservesToBorrowGreatherThanRemainingTokenLimit,
    totalBorrowAPY
  };
};

export type ReturnUseBorrowData = ReturnType<typeof useBorrowData>;

export default useBorrowData;
