import * as common from '@/services/indexer/common';
import * as liquidStakingApp from '@/services/indexer/liquid-staking-app';
import * as market from '@/services/indexer/market';
import * as prices from '@/services/indexer/prices';

const indexerService = {
  common,
  liquidStakingApp,
  market,
  prices,
};

export default indexerService;
