import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, GetRootState, RootState } from '@/store/index';

export interface QueueState {
  actionsQueue: AnyAction[];
}

const initialState: QueueState = {
  actionsQueue: [],
};

export const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    addAction: (state, action: PayloadAction<AnyAction>) => {
      state.actionsQueue.push(action.payload);
    },
    clearQueue: (state) => {
      state.actionsQueue = [];
    },
  },
});

export const { addAction, clearQueue } = queueSlice.actions;

export const execQueue =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    const {
      queue: { actionsQueue },
    } = getState();

    await Promise.all(actionsQueue.map((actionQueue) => dispatch(actionQueue)));
    await dispatch(clearQueue());
  };

export const queueSelector = (state: RootState) => state.queue;

export default queueSlice.reducer;
