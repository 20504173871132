import classNames from 'classnames';
import { m } from 'framer-motion';
import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'next-i18next';

import clsxm from '@/services/clsxm';

type SlippageSettingsPopup = {
  value: string;
  onSave: (value: string) => void;
};

const SlippageSettingsPopupContent = ({
  value,
  onSave,
}: SlippageSettingsPopup) => {
  const { t } = useTranslation();
  const [slippage, setSlippage] = useState(value);
  const [slippageValue, setSlippageValue] = useState(0.5);
  const [customSlippage, setCustomSlippage] = useState('');

  const handlePredefinedSlippage = (value: any) => {
    setSlippage(value);
    setSlippageValue(parseFloat(value));
    setCustomSlippage('');
  };

  const handleSave = (value: string) => {
    onSave(value);
  };

  return (
    <div
      className={clsxm(
        'box-border font-hass font-[600]',
        'lg:p-[10px] lg:h-[143px] lg:min-h-[143px]',
        'md:p-[6.5px]  md:h-[119.4px] md:min-h-[119.4px]',
        'min-h-[104px] h-[104px] p-[7.6px] pr-[7.6px]',
      )}
    >
      <div
        className={clsxm(
          'flex justify-between',
          'lg:mb-3',
          'md:mb-[8.5px]',
          'mb-[9px]',
        )}
      >
        <p
          className={clsxm(
            'lg:text-[1rem] lg:leading-[20px]',
            'md:text-[14px] md:leading-[17.5px]',
            'text-[12px] leading-[15px]',
          )}
        >
          {t('Settings')}
        </p>
        <button
          onClick={() => slippageValue >= 0.1 && handleSave(slippage)}
          className={classNames(
            ' cursor-pointer ',

            'lg:px-[4px] lg:pr-[5px] lg:py-[2.5px] lg:rounded-[6px]',
            'md:pl-[4px] md:pr-[4.5px] md:py-[2.5px] md:pb-[3px] md:rounded-[5px]',
            'px-[4px] pt-[2.5px] pb-[2.5px]  pr-[4.1px] rounded-[4.45px] dark:bg-[#8F64C2] dark:text-[#E3D6F3]',
            slippageValue == 0
              ? '!cursor-not-allowed bg-[#E7E7F9] text-white dark:!bg-[#302C60] dark:!text-[#837FB4]'
              : 'bg-[#ECDDFE] text-[#8963B8] hover:bg-[#F0E6FF] dark:hover:bg-[#8F64C2]/50',
          )}
        >
          <p
            className={clsxm(
              'lg:text-[12px] lg:leading-[15px]',
              'md:text-[10px] md:leading-[12.5px]',
              'text-[8px] leading-[10px] tracking-[0px]',
            )}
          >
            {t('Save')}
          </p>
        </button>
      </div>

      <p
        className={clsxm(
          'text-[#6A6A8C] ',
          'lg:text-[12px] lg:leading-[15px] lg:mb-[8px]',
          'md:text-[10px] md:leading-[12px] md:mb-[8.5px]',
          'text-[8px] leading-[10px] mb-[6px] ',
        )}
      >
        {t('Slippage Tolerance')}
      </p>

      <div className='max-w-[134.5px] md:max-w-[155.5px] lg:max-w-[182px]'>
        <div
          className={clsxm(
            'text-[#6A6A8C]',
            'lg:text-[14px] lg:leading-[17.5px]',
            'md:text-[12.29px] md:leading-[15.4px]',
            'text-[10px] leading-[12px]',
          )}
        >
          <div
            className={clsxm(
              'w-full flex justify-between items-center select-none lg:gap-[12px] md:gap-[8px] mb-[5.7px] md:mb-[8px] lg:mb-[8px]',
              'lg:h-[30px]',
              'md:h-[25.5px]',
              'h-[21.41px]',
            )}
          >
            <m.div
              className={classNames(
                'relative h-full bg-white border-[#E7E7F9]',
                'lg:w-[50px] lg:border',
                'md:w-[44.5px] md:border md:rounded-[7px]',
                'w-[36.9px] border-[0.9px] rounded-[4px]',
                'hover:text-[#0170FF] hover:bg-[#FBFAFF] dark:bg-transparent dark:border-[#302C60] dark:text-[#625E8F] dark:hover:bg-[#302C60]/50 dark:hover:text-[#0170FF]/50',
                (slippageValue === 0.1 || slippage === '0.1') &&
                  'text-[#0170FF] !bg-[#E7E7F9] dark:!bg-[#302C60] dark:!text-[#0170FF]',
              )}
              onClick={() => handlePredefinedSlippage('0.1')}
            >
              <p className='absolute lg:top-[5.2px] lg:left-[9px] md:top-[4.5px] md:left-[7.8px] top-[4.5px] left-[7px]'>
                0.1%
              </p>
            </m.div>
            <m.div
              className={classNames(
                'relative h-full bg-white border-[#E7E7F9]',
                'lg:w-[54px] lg:border lg:rounded-[8px]',
                'md:w-[47.5px] md:px-[8px] md:pt-[4.5px] md:pb-[4px] md:border md:rounded-[7px]',
                'w-[39.9px] border-[0.9px] rounded-[4px]',
                'hover:text-[#0170FF] hover:bg-[#FBFAFF] dark:bg-transparent dark:border-[#302C60] dark:text-[#625E8F] dark:hover:bg-[#302C60]/50 dark:hover:text-[#0170FF]/50',
                ((slippageValue === 0.5 && slippage == '0') ||
                  slippage == '0.5') &&
                  'text-[#0170FF] !bg-[#E7E7F9] dark:!bg-[#302C60] dark:!text-[#0170FF]',
              )}
              onClick={() => handlePredefinedSlippage('0.5')}
            >
              <p className='absolute lg:top-[4.9px] lg:left-[9px] md:top-[4.5px] md:left-[7.8px] top-[4.5px] left-[7px]'>
                0.5%
              </p>
            </m.div>
            <m.div
              className={classNames(
                'relative h-full  bg-white border-[#E7E7F9]',
                'lg:w-[54px] lg:border lg:rounded-[8px]',
                'md:w-[47.4px] md:px-[12.7px] md:pt-[4.5px] md:pb-[4px] md:border md:rounded-[7px]',
                'w-[40px] border-[0.9px] rounded-[4px]',
                'hover:text-[#0170FF] hover:bg-[#FBFAFF] dark:bg-transparent dark:border-[#302C60] dark:text-[#625E8F] dark:hover:bg-[#302C60]/50 dark:hover:text-[#0170FF]/50',
                (slippageValue === 1 || slippage === '1') &&
                  'text-[#0170FF] !bg-[#E7E7F9] dark:!bg-[#302C60] dark:!text-[#0170FF]',
              )}
              onClick={() => handlePredefinedSlippage('1')}
            >
              <p className='absolute lg:top-[4.77px] lg:left-[14.5px] md:top-[4.27px] md:left-[12.8px] top-[3.97px] left-[11.6px]'>
                1%
              </p>
            </m.div>
          </div>
          <div
            className={clsxm(
              'w-full relative',
              parseFloat(slippage) == 0
                ? 'focus-within:text-[#E24949]'
                : 'focus-within:text-[#0170FF]',
            )}
          >
            <CurrencyInput
              autoFocus={
                slippage != '0' &&
                slippage != '0.1' &&
                slippage != '0.5' &&
                slippage != '1'
                  ? true
                  : false
              }
              className={classNames(
                'relative w-full font-[600]  outline-none  focus:bg-[#E7E7F9] bg-[#FBFAFF] dark:bg-[#302C60] border-[#E7E7F9] dark:border-[#302C60] dark:text-[#837FB4]',
                'lg:border-[1px] lg:rounded-[8px] lg:px-[9px] lg:py-[5px] lg:pb-[5.5px]',
                'md:border md:rounded-[7px] md:px-[7.75px] md:pb-[3.8px] pt-[4.4px] ',
                'border-[0.75px] rounded-[4px] px-[7.5px] pl-[7.1px] py-[4.5px]',
                parseFloat(slippage) == 0
                  ? '!text-[#E24949]'
                  : '!text-[#0170FF]',
              )}
              placeholder='Custom'
              value={customSlippage}
              onFocus={() => {
                setSlippageValue(0);
                setSlippage('');
              }}
              autoComplete='off'
              aria-autocomplete='none'
              decimalSeparator='.'
              groupSeparator=','
              allowNegativeValue={false}
              onValueChange={(x) => {
                const newValue = x ? parseFloat(x) : 0;
                setCustomSlippage(x);
                if (newValue >= 100) {
                  setSlippageValue(100);
                  setSlippage('100');
                } else if (newValue < 0.1) {
                  setSlippageValue(newValue === 0 ? 0 : 0.1);
                  setSlippage(newValue === 0 ? '0' : '0.1');
                } else {
                  setSlippageValue(newValue);
                  setSlippage(x);
                }
              }}
              inputMode='text'
              max={100}
            />
            <span
              className={clsxm(
                'absolute top-1/2 transform -translate-y-1/2',
                'lg:right-[10.75px] lg:pt-[0.2px]',
                'md:right-[9.3px]',
                'right-[7.5px]',
              )}
            >
              %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlippageSettingsPopupContent;
