import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useGovernanceInteraction from '@/hooks/interaction/useGovernanceInteraction';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';
import TransactionButton from '@/components/TransactionButton';

import { governanceSelector } from '@/store/governance';
import { useAppSelector } from '@/store/index';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';
import { hasPendingTransactionsSelector } from '@/store/transaction';

import { Container } from '@/sections/Governance/Balance/components';
import { formatNumber } from '@/utils/helpers';

export const Redeem = () => {
  const { signTransactions } = useSignMultipleTransactions();
  const { htmEngagedInVoting, availableToRedeem, votesAvailableToRedeem } =
    useAppSelector(governanceSelector);

  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const { t } = useTranslation('governance');
  const { redeem: governanceRedeem } = useGovernanceInteraction();

  const isBtnDisabled = useMemo(() => {
    if (
      hasPendingTransactions ||
      !hasEnoughEGLDBalance ||
      availableToRedeem === '0'
    ) {
      return true;
    }

    return false;
  }, [hasEnoughEGLDBalance, hasPendingTransactions, availableToRedeem]);

  const handleRedeemAll = async () => {
    const votesId = votesAvailableToRedeem.map(({ voteId }) => voteId);

    const transactions = [
      ...votesId.map((nonce) => governanceRedeem({ nonce })),
    ];

    await signTransactions(transactions);
  };

  return (
    <Container>
      <div
        className={classNames(
          'relative flex justify-between',
          'z-10',
          'pb-6 pt-4',
        )}
      >
        <div
          className={classNames(
            'flex text-[14px] font-semibold',
            'leading-[125%]',
          )}
        >
          <img
            src='https://cdn.app.hatom.com/images/htm-green.svg'
            className='mr-2 h-[20px] w-[20px]'
            alt=''
          />
          <p>
            {t('htm-engaged-in-voting', 'HTM Engaged in Voting')}{' '}
            <button className='inline-block translate-y-1 text-[#607C99] hover:opacity-80'>
              <Hint
                content={
                  <div
                    className={classNames('max-w-[240px] tracking-[0.01em]')}
                  >
                    {t(
                      'tooltip-uACU4',
                      'This value indicates your HTM tokens that are used for voting on active proposals.',
                    )}
                  </div>
                }
                placement='top-center'
                backgroundColor='#030303'
              >
                <InfoIcon className='w-[10px]' />
              </Hint>
            </button>
          </p>
        </div>
        <div>
          <p className='text-[14px] font-semibold text-[#77778B]'>
            {formatNumber(htmEngagedInVoting, 2, DefiUtils.ROUND_FLOOR)}
          </p>
        </div>
        <div
          className={classNames(
            'absolute',
            'h-[2px]',
            'bg-[#D3CFE2]',
            'w-full',
            'bottom-0',
            'left-0',
            'z-1',
            'dark:bg-[#585584]',
          )}
        />
      </div>
      <div>
        <div
          className={classNames(
            'flex justify-between text-[14px] font-semibold text-[#77778B]',
            'leading-[125%]',
            'pt-3',
          )}
        >
          <div className={classNames('flex font-semibold', 'leading-[125%]')}>
            <p>{t('available-to-redeem', 'Available to Redeem')}</p>
            <button className='ml-1 flex items-end text-[#607C99] hover:opacity-80'>
              <Hint
                content={
                  <div
                    className={classNames('max-w-[276px] tracking-[0.01em]')}
                  >
                    {t(
                      'tooltip-8CLzt',
                      'This value represents the HTM tokens that have been used for voting and can now be withdrawn.',
                    )}
                  </div>
                }
                placement='top-center'
                backgroundColor='#030303'
              >
                <InfoIcon className='w-[10px]' />
              </Hint>
            </button>
          </div>
          <div>
            <p>{formatNumber(availableToRedeem, 2, DefiUtils.ROUND_FLOOR)}</p>
          </div>
        </div>

        <Hint
          content={
            hasPendingTransactions
              ? t('translation:wait-tx-inprogress')
              : t('translation:tx-not-allowed')
          }
          placement='top-center'
          className='w-full'
          unvisible={!hasPendingTransactions}
        >
          <TransactionButton
            disabled={isBtnDisabled}
            onClick={handleRedeemAll}
            className='mt-5 h-[34px] capitalize'
            text={
              isBtnDisabled
                ? t('no-funds-to-redeem', 'No Funds To Redeem')
                : t('redeem-all', 'Redeem All')
            }
          />
        </Hint>
      </div>
    </Container>
  );
};
