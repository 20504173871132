import * as boosterBatch from '@/services/blockchain/booster-batch';
import * as controller from '@/services/blockchain/controller';
import * as lens from '@/services/blockchain/lens';
import * as liquidLocking from '@/services/blockchain/liquid-locking';
import * as moneyMarket from '@/services/blockchain/money-market';
import * as protocol from '@/services/blockchain/protocol';

const blockchainService = {
  controller,
  moneyMarket,
  protocol,
  boosterBatch,
  liquidLocking,
  lens,
};

export default blockchainService;
