import {
  Address,
  ContractFunction,
  Interaction,
  IPlainTransactionObject,
  SmartContract,
  TokenTransfer,
  Transaction,
  TransactionOptions,
  TransactionVersion,
  TransactionWatcher,
  TypedValue,
} from '@multiversx/sdk-core';
import { ExtensionProvider } from '@multiversx/sdk-extension-provider';
import { HWProvider } from '@multiversx/sdk-hw-provider';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import { WalletConnectV2Provider } from '@multiversx/sdk-wallet-connect-provider';
import { WalletProvider } from '@multiversx/sdk-web-wallet-provider';
import { captureException } from '@sentry/nextjs';
import { chunkPromise, PromiseFlavor } from 'chunk-promise';
import DefiUtils from 'defi-utils';
import { event } from 'nextjs-google-analytics';

import { accountSelector } from '@/store/auth';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { networkSelector } from '@/store/network';
import { closePopup, openTransactionPopup } from '@/store/popup';
import { getIsLoadingInfo } from '@/store/protocol';
import {
  addCurrentTransaction,
  addOrUpdateTransactionGroup,
  defaultTransactionConfig,
  deleteTransactionGroup,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
  TransactionConfig,
  transactionSelector,
  updateCurrentTransaction,
} from '@/store/transaction';

import gasLimitMap from '@/config/gasLimitMap';
import { chainType, DAPP_INIT_ROUTE, networkConfig } from '@/config/network';
import { WebviewProvider } from '@/providers/webviewProvider';
import { ExperimentalWebviewProvider } from '@/services/experimentalWebViewProvider';
import gatewayService from '@/services/gateway';
import multiversxSDK, {
  Transaction as MultiversxTransaction,
} from '@/services/multiversx-sdk';
import { sleep } from '@/utils/helpers';
import logger from '@/utils/logger';
import { MINUTE_IN_MILISECONDS, SECOND_IN_MILISECONDS } from '@/utils/query';

interface TokenPayType {
  tokenIdentifier: string;
  amount: DefiUtils.Value;
  numDecimals?: number;
  isFromBigInteger?: boolean;
}

interface MetaTokenPayType {
  tokenIdentifier: string;
  amount: DefiUtils.Value;
  nonce: number;
  numDecimals?: number;
  isFromBigInteger?: boolean;
}

interface NFTPayType {
  tokenIdentifier: string;
  nonce: number;
}

export interface BuildTransactionParams {
  smartContractAddress: string;
  func: string;
  args?: TypedValue[];
  value?: string;
  isPayable?: boolean;
  token?: TokenPayType;
  metaToken?: MetaTokenPayType;
  nft?: NFTPayType;
  isFromBigInteger?: boolean;
  group: string;
  gasLimitArgs?: any[];
}

export enum TX_STATUS {
  IN_PROCESS = 'IN_PROCESS',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
}

export enum POPUP_VARIATION {
  NORMAL = 'pendingtransaction',
  LIQUID = 'liquidpendingtransaction',
  LENDING = 'lendingpendingtransaction',
  LIQUID_TAO = 'liquidtaopendingtransaction',
  USH = 'ushpendingtransaction',
}

export const POPUP_VARIATIONS = [
  POPUP_VARIATION.LENDING,
  POPUP_VARIATION.LIQUID,
  POPUP_VARIATION.LIQUID_TAO,
  POPUP_VARIATION.NORMAL,
  POPUP_VARIATION.USH,
];

const getLastNonce = async (accountAddress: string) => {
  const account = await gatewayService.address.getAddressNonce(accountAddress);

  return account.nonce;
};

const buildCallback = () => {
  return encodeURIComponent(
    `${window.location.origin}${
      window.location.pathname
    }?signSession=${Date.now()}`,
  );
};

const useSignMultipleTransactions = () => {
  const dispatch = useAppDispatch();

  const { dappProvider, apiNetworkProvider } = useAppSelector(networkSelector);
  const {
    address: accountAddress,
    isGuarded,
    activeGuardianAddress,
  } = useAppSelector(accountSelector);
  const { silentModeActive } = useAppSelector(transactionSelector);

  const signTransactions = async (
    transactions: Transaction[],
    config: Partial<TransactionConfig> = {},
  ) => {
    if (!apiNetworkProvider || transactions.length === 0) {
      return;
    }

    const configUpdated = {
      ...defaultTransactionConfig,
      ...config,
      accountAddress,
    };

    try {
      if (silentModeActive) {
        cancelTransactions();
      }
      await dispatch(closePopup());

      await dispatch(
        addOrUpdateTransactionGroup({
          id: `${accountAddress}_${configUpdated.group}`,
          config: configUpdated,
          amount: transactions.length,
          transactions: [],
        }),
      );

      await handlePopupVariation(
        getPopupVariation(configUpdated, TX_STATUS.AWAITING_CONFIRMATION),
        {
          txStatus: TX_STATUS.AWAITING_CONFIRMATION,
        },
      );

      const latestNonce = await getLastNonce(accountAddress);

      const mappedTransactions = setTransactionNonces(
        latestNonce,
        transactions,
      );

      const transactionSigned =
        await signTransactionsByProvider(mappedTransactions);

      if (transactionSigned.length === 0) {
        return;
      }

      await Promise.all(
        transactionSigned.map((transaction) => {
          return dispatch(
            addCurrentTransaction(`${accountAddress}_${configUpdated.group}`, {
              hash: transaction.getHash().toString(),
              status: 'signed',
              transactionSigned: transaction.toPlainObject(),
            }),
          );
        }),
      );

      await sendOrWatchTransactions(transactionSigned, configUpdated);
    } catch (error) {
      logger.error('hooks:signTransactions', error);
      await handleTransactionsError(error, configUpdated);
    }
  };

  const setTransactionNonces = (
    latestNonce: number,
    transactions: Transaction[],
  ) => {
    const isLedger = dappProvider instanceof HWProvider;

    return transactions.map((tx: Transaction, index: number) => {
      tx.setNonce(latestNonce + index);

      if (isGuarded && !(dappProvider instanceof WalletProvider)) {
        tx.setSender(Address.fromBech32(accountAddress));
        tx.setVersion(TransactionVersion.withTxOptions());
        tx.setGuardian(Address.fromBech32(activeGuardianAddress));
        const options = {
          guarded: true,
          ...(isLedger ? { hashSign: true } : {}),
        };
        tx.setOptions(TransactionOptions.withOptions(options));
      }

      return tx;
    });
  };

  const cancelTransactions = () => {
    if (dappProvider instanceof ExtensionProvider) {
      return dappProvider.cancelAction();
    }
    if (dappProvider instanceof ExperimentalWebviewProvider) {
      return dappProvider.cancelAction();
    }
    return Promise.resolve();
  };

  const sendOrWatchTransactions = async (
    transactions: (
      | Transaction
      | IPlainTransactionObject
      | MultiversxTransaction
    )[],
    config: TransactionConfig,
  ) => {
    const promises = transactions.map((transaction) => () => {
      return sendOrWatchTransaction(transaction, config);
    });

    await handlePopupVariation(getPopupVariation(config, TX_STATUS.SENT), {
      txStatus: TX_STATUS.SENT,
    });

    await chunkPromise(promises, {
      concurrent: config.isSecuencial ? 1 : transactions.length,
      promiseFlavor: PromiseFlavor.PromiseAll,
    });

    waitForIndexerLoadFinished().then(() => {
      handlePopupVariation(getPopupVariation(config, TX_STATUS.COMPLETED), {
        txStatus: TX_STATUS.COMPLETED,
      });
    });
  };

  const sendOrWatchTransaction = async (
    transaction: Transaction | IPlainTransactionObject | MultiversxTransaction,
    config: TransactionConfig,
  ) => {
    if (transaction instanceof Transaction) {
      const hash = await sendTransaction(transaction, config);

      event(`submit_tx:${chainType}`, {
        category: 'tx',
        label: `${hash}`,
      });
      return watchTransaction(hash, config);
    }

    if ((transaction as MultiversxTransaction)?.status === undefined) {
      const hash = await sendTransaction(
        Transaction.fromPlainObject(transaction as IPlainTransactionObject),
        config,
      );

      event(`submit_tx:${chainType}`, {
        category: 'tx',
        label: `${hash}`,
      });
      return watchTransaction(hash, config);
    }

    return watchTransaction(
      (transaction as MultiversxTransaction)?.txHash,
      config,
    );
  };

  const waitForIndexerLoadFinished = async () => {
    return new Promise(async (resolve) => {
      await sleep(1000);

      const interval = setInterval(async () => {
        const isLoadingInfo = await dispatch(getIsLoadingInfo());

        if (!isLoadingInfo) {
          clearInterval(interval);
          resolve(undefined);
          return;
        }
      }, 100);
    });
  };

  const watchTransaction = async (hash: string, config: TransactionConfig) => {
    try {
      await sleep(10_000);
      const transactionWatcher = new TransactionWatcher(apiNetworkProvider, {
        pollingIntervalMilliseconds: SECOND_IN_MILISECONDS * 5,
        timeoutMilliseconds: MINUTE_IN_MILISECONDS * 30,
      });
      const transactionOnNetwork = await transactionWatcher.awaitCompleted({
        getHash: () => ({
          hex: () => hash,
        }),
      });

      const txData = await multiversxSDK.transactionDetails(hash);

      const status = transactionOnNetwork.status.toString();

      await dispatch(
        updateCurrentTransaction(
          `${config.accountAddress}_${config.group}`,
          hash,
          {
            status,
            txData,
          },
        ),
      );
    } catch (error) {
      captureException(error);
      logger.error('hooks:watchTransaction', error);
      await dispatch(
        updateCurrentTransaction(
          `${config.accountAddress}_${config.group}`,
          hash,
          {
            status: 'fail',
          },
        ),
      );
    }
  };

  const getPopupVariation = (
    config: Partial<TransactionConfig>,
    status: TX_STATUS,
  ) => {
    if (
      status === TX_STATUS.COMPLETED &&
      [TRANSACTION_SUBGROUP_TYPE.DEPLOY_ACCOUNT_MANAGER].includes(
        config.subgroup as TRANSACTION_SUBGROUP_TYPE,
      )
    ) {
      return POPUP_VARIATION.NORMAL;
    }
    if (
      status === TX_STATUS.COMPLETED &&
      [
        TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS,
        TRANSACTION_SUBGROUP_TYPE.STAKE_HTM,
        TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM,
        TRANSACTION_SUBGROUP_TYPE.REALLOCATE_HTM,
        TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM,
        TRANSACTION_SUBGROUP_TYPE.STAKE_CLAIM_HTM,
      ].includes(config.subgroup as TRANSACTION_SUBGROUP_TYPE)
    ) {
      return POPUP_VARIATION.LENDING;
    }

    if (
      status === TX_STATUS.COMPLETED &&
      [
        TRANSACTION_SUBGROUP_TYPE.UNLOCK,
        TRANSACTION_SUBGROUP_TYPE.UNBOND,
        TRANSACTION_SUBGROUP_TYPE.LOCK,
      ].includes(config.subgroup as TRANSACTION_SUBGROUP_TYPE)
    ) {
      return POPUP_VARIATION.LIQUID;
    }

    switch (config.group) {
      case TRANSACTION_GROUP_TYPE.LIQUID: {
        return POPUP_VARIATION.LIQUID;
      }
      case TRANSACTION_GROUP_TYPE.LENDING: {
        return POPUP_VARIATION.LENDING;
      }
      case TRANSACTION_GROUP_TYPE.LIQUID_TAO: {
        return POPUP_VARIATION.LIQUID_TAO;
      }
      case TRANSACTION_GROUP_TYPE.USH: {
        return POPUP_VARIATION.USH;
      }
      default: {
        return POPUP_VARIATION.NORMAL;
      }
    }
  };

  const signTransactionsByProvider = async (
    transactions: Transaction[],
  ): Promise<Transaction[]> => {
    if (dappProvider instanceof WalletProvider) {
      const callbackUrl = buildCallback();

      if (transactions.length === 1) {
        await dappProvider.signTransaction(transactions[0], {
          callbackUrl,
        });
        return [];
      }

      await dappProvider.signTransactions(transactions, {
        callbackUrl,
      });

      return [];
    }

    if (dappProvider instanceof ExperimentalWebviewProvider) {
      if (transactions.length === 1) {
        return Promise.all([dappProvider.signTransaction(transactions[0])]);
      }

      return dappProvider.signTransactions(transactions);
    }

    if (dappProvider instanceof HWProvider) {
      await dappProvider.init();
      const callbackUrl = buildCallback();

      if (!isGuarded && transactions.length === 1) {
        return Promise.all([dappProvider.signTransaction(transactions[0])]);
      }

      if (!isGuarded && transactions.length > 1) {
        return dappProvider.signTransactions(transactions);
      }

      const _dappProvider = new WalletProvider(
        `${networkConfig[chainType].walletAddress}${DAPP_INIT_ROUTE}`,
      );

      const signedTransactions =
        await dappProvider.signTransactions(transactions);

      await _dappProvider.guardTransactions(signedTransactions, {
        callbackUrl,
      });

      return [];
    }

    if (dappProvider instanceof ExtensionProvider) {
      return transactions.length === 1
        ? Promise.all([dappProvider.signTransaction(transactions[0])])
        : dappProvider.signTransactions(transactions);
    }

    if (dappProvider instanceof WalletConnectV2Provider) {
      const callbackUrl = buildCallback();

      if (!isGuarded && transactions.length === 1) {
        return Promise.all([dappProvider.signTransaction(transactions[0])]);
      }

      if (!isGuarded && transactions.length > 1) {
        return dappProvider.signTransactions(transactions);
      }

      const _dappProvider = new WalletProvider(
        `${networkConfig[chainType].walletAddress}${DAPP_INIT_ROUTE}`,
      );

      const signedTransactions =
        await dappProvider.signTransactions(transactions);

      await _dappProvider.guardTransactions(signedTransactions, {
        callbackUrl,
      });

      return [];
    }

    if (dappProvider instanceof WebviewProvider) {
      return transactions.length === 1
        ? Promise.all([dappProvider.signTransaction(transactions[0])])
        : dappProvider.signTransactions(transactions);
    }

    throw new Error('Provider not supported');
  };

  const sendTransaction = async (
    transaction: Transaction,
    config: TransactionConfig,
  ): Promise<string> => {
    const hash = transaction.getHash().toString();

    await dispatch(
      updateCurrentTransaction(
        `${config.accountAddress}_${config.group}`,
        hash,
        {
          status: 'pending',
          transactionSigned: undefined,
        },
      ),
    );

    return (apiNetworkProvider as ApiNetworkProvider).sendTransaction(
      transaction,
    );
  };

  const handleTransactionsError = async (
    error: unknown,
    config: Partial<TransactionConfig>,
  ) => {
    const erorrString = String(error).toString();
    const isTransactionCanceled = erorrString.includes('Transaction canceled');

    if (!isTransactionCanceled) {
      captureException(error);
    }

    await dispatch(closePopup());
    await handlePopupVariation(getPopupVariation(config, TX_STATUS.FAILED), {
      txStatus: TX_STATUS.FAILED,
    });

    cancelTransactions();
    await dispatch(
      deleteTransactionGroup(
        `${config?.accountAddress}_${config?.group}` || '',
      ),
    );
  };

  const handlePopupVariation = async (name: POPUP_VARIATION, data: any) => {
    await dispatch(
      openTransactionPopup({
        name,
        data,
      }),
    );
  };

  const buildTransaction = ({
    smartContractAddress,
    func,
    args,
    value,
    isPayable,
    token,
    nft,
    isFromBigInteger,
    group,
    metaToken,
    gasLimitArgs = [],
  }: BuildTransactionParams) => {
    const contract = new SmartContract({
      address: new Address(smartContractAddress),
    });

    const interaction = new Interaction(
      contract,
      new ContractFunction(func),
      args || [],
    );

    let transaction;

    const gasLimitKey = `${group}.${func}`;

    // @ts-ignore
    const _gasLimit = gasLimitMap()?.[gasLimitKey]?.(...gasLimitArgs);

    transaction = interaction
      .withGasLimit(_gasLimit || 200_000_000)
      .withChainID(networkConfig[chainType].shortId)
      .withSender(new Address(accountAddress));

    if (isPayable && token) {
      transaction = interaction.withSingleESDTTransfer(
        token.isFromBigInteger
          ? TokenTransfer.fungibleFromBigInteger(
              token.tokenIdentifier,
              token.amount,
              token.numDecimals,
            )
          : TokenTransfer.fungibleFromAmount(
              token.tokenIdentifier,
              token.amount,
              token.numDecimals || 1,
            ),
      );
    }

    if (isPayable && metaToken) {
      transaction = interaction.withSingleESDTNFTTransfer(
        metaToken.isFromBigInteger
          ? TokenTransfer.metaEsdtFromBigInteger(
              metaToken.tokenIdentifier,
              metaToken.nonce,
              metaToken.amount,
              metaToken.numDecimals,
            )
          : TokenTransfer.metaEsdtFromAmount(
              metaToken.tokenIdentifier,
              metaToken.nonce,
              metaToken.amount,
              metaToken.numDecimals || 1,
            ),
      );
    }

    if (isPayable && nft) {
      transaction = interaction.withSingleESDTNFTTransfer(
        TokenTransfer.nonFungible(nft.tokenIdentifier, nft.nonce),
      );
    }

    if (isPayable && value) {
      transaction = interaction.withValue(
        isFromBigInteger
          ? TokenTransfer.egldFromBigInteger(value)
          : TokenTransfer.egldFromAmount(value),
      );
    }

    return transaction.buildTransaction() as Transaction;
  };

  return {
    signTransactions,
    sendOrWatchTransactions,
    buildTransaction,
    cancelTransactions,
    handleTransactionsError,
  };
};

export default useSignMultipleTransactions;
