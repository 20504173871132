import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import {
  ONCE,
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';
import useLiquidStakingInteraction from '@/hooks/interaction/useLiquidStakingInteraction';
import { useCollapse } from '@/hooks/layout/useCollapse';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';

import { useAppSelector } from '@/store/index';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import { cardClasses } from '@/sections/Liquid/App/global';
import { useShimmerControllerContext } from '@/sections/Liquid/App/Provider';
import clsxm from '@/services/clsxm';

const isLend = (pathname: string) => {
  return pathname.includes('/lend');
};
const isUsh = (pathname: string) => {
  return pathname.includes('/ush');
};

import HintTransaction from '@/components/HintTransaction';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { liquidStakingAppSelector } from '@/store/liquid-staking-app';

import ClaimsAndRequestsUSHRow from '@/sections/Liquid/App/ClaimsAndRequests/ClaimsAndRequestsUSHRow';
import { useHintProps } from '@/sections/Liquid/App/global';
import Shimmer from '@/sections/Liquid/App/Shimmer';

const Tab: React.FC<any> = ({
  active,
  children,
  onClick,
  className,
  buttonClassName,
}) => {
  return (
    <div className={classNames(className)}>
      <button
        type='button'
        onClick={onClick}
        className={classNames(
          'inline-block whitespace-nowrap rounded-t-2xl border border-b-0 p-2.5 px-5 pb-3 text-[12px] leading-6 md:text-[16px]',
          !active
            ? 'border-transparent text-[#535367] dark:text-[#625E8F]'
            : 'border-[#DCDCE3] bg-[#F9F9FE] dark:border-[#625E8F] dark:bg-[#292651]',
          buttonClassName,
        )}
      >
        {children}
      </button>
      <hr
        className={classNames(
          'border-t border-[#DCDCE3] dark:border-[#625E8F]',
        )}
      />
    </div>
  );
};

const Button: React.FC<any> = ({ className, children, small, ...props }) => {
  const router = useRouter();
  const md1 =
    isLend(router.pathname) || isUsh(router.pathname)
      ? useMediaQuery('(min-width: 1344px)')
      : useMediaQuery('(min-width: 980px)');

  return (
    <button
      type='button'
      className={classNames(
        className,
        'disabled:bg-[#D3D6EE] disabled:text-[#6A6A8C] disabled:dark:bg-[#676198] disabled:dark:text-white',
        {
          btnHover: !small,
        },
        ' rounded-[5px] md:rounded-[8px] text-[10px] md:text-[14px]  capitalize leading-tight',
        small
          ? [
              'px-2 py-1 hover:opacity-90 min-w-[47px] md:min-w-[70px] ',
              md1 && '!min-w-[64px] !px-4 ',
            ]
          : [
              !md1 && 'px-2 py-1.5  w-[61px] md:w-[83px]  ',
              md1 && '!px-5 !w-[99px]',
            ],
      )}
      {...props}
    >
      {children}
    </button>
  );
};

const EstimatedValueText: React.FunctionComponent<any> = ({
  currency = 'USD',
}) => {
  const xl = useMediaQuery('(min-width: 1280px)');
  const { t } = useTranslation('liquid-app');

  return <>{t(`estimated-value-by-x${xl ? '' : '-short'}`, { currency })}</>;
};

const ClaimsAndRequestsShimmer: React.FC<any> = () => {
  return (
    <Fragment>
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Fragment key={i}>
            <div className='flex-xy-center gap-1.5 place-self-start'>
              <Shimmer circular w={15} />
              <Shimmer sm />
            </div>
            <Shimmer />
            <Shimmer />
            <div className='flex-xy-center justify-end gap-2'>
              <Shimmer sm />
              <Shimmer sm />
            </div>
          </Fragment>
        ))}
    </Fragment>
  );
};

const TimerAnimation: React.FC<any> = () => {
  return (
    <WrappedLottiePlayer
      className='w-[12px]'
      src='https://cdn.app.hatom.com/animations/clock.json'
    />
  );
};

const HintableCellBare: React.FC<any> = ({ children, content }) => {
  const defaultHintProps = useHintProps();

  return (
    <Hint
      {...defaultHintProps}
      content={content}
      layerStyle={{ textAlign: 'center', minWidth: 30 }}
    >
      {children}
    </Hint>
  );
};

const HintableCell = React.memo(HintableCellBare);
const ClaimsAndRequests: React.FC<any> = () => {
  const { t } = useTranslation('liquid-app');
  const md = useMediaQuery('(min-width: 768px)');
  const router = useRouter();
  const md1 =
    isLend(router.pathname) || isUsh(router.pathname)
      ? useMediaQuery('(min-width: 1344px)')
      : useMediaQuery('(min-width: 980px)');
  const lg = useMediaQuery('(min-width: 1024px)');
  const xl = useMediaQuery('(min-width: 1280px)');

  const [tab, setTab] = useState(0);
  const { undelegateNfts } = useAppSelector(liquidStakingAppSelector);
  const { unbond } = useLiquidStakingInteraction();
  const { signTransactions } = useSignMultipleTransactions();
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);

  const tokenImgMap = {
    sEGLD: 'https://cdn.app.hatom.com/images/sEGLD.svg',
    HsEGLD:
      'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
  };

  const handleAllUnbond = (nonces: number[], result: string) => {
    signTransactions(
      nonces.map((nonce) => unbond({ nonce })),
      {
        group: TRANSACTION_GROUP_TYPE.LIQUID,
        subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM,
        result,
      },
    );
  };

  const handleUnbond = (nonce: number, result: string) => {
    signTransactions([unbond({ nonce })], {
      group: TRANSACTION_GROUP_TYPE.LIQUID,
      subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM,
      result,
    });
  };

  const undelegateNftsPopulated = useMemo(() => {
    return undelegateNfts.map(
      ({
        segldAmount,
        hsegldAmount,
        egldAmount: valueEGLD,
        amountUsd: valueUSD,
        tokenNonce,
        source,
        availableToRedeemDate,
        canRedeem,
      }) => {
        const token = source === 'LiquidStaking' ? 'sEGLD' : 'HsEGLD';
        const value = source === 'LiquidStaking' ? segldAmount : hsegldAmount;
        const expireIn = !canRedeem ? new Date(availableToRedeemDate) : null;

        return {
          token,
          value,
          valueEGLD,
          valueUSD,
          tokenNonce,
          source,
          expireIn,
          canRedeem,
        };
      },
    );
  }, [undelegateNfts]);

  const activeClaimsNfts = useMemo(
    () => undelegateNftsPopulated.filter(({ canRedeem }) => canRedeem),
    [undelegateNftsPopulated],
  );

  const unstakeRequestNfts = useMemo(
    () => undelegateNftsPopulated.filter(({ canRedeem }) => !canRedeem),
    [undelegateNftsPopulated],
  );

  const activeTab = useMemo(() => {
    return !activeClaimsNfts.length && unstakeRequestNfts.length ? 1 : 0;
  }, [activeClaimsNfts, unstakeRequestNfts]);

  const panels = useMemo(
    () => [
      {
        caption: t('active-claims'),
        th: [
          t('unstaked-tokens'),
          <EstimatedValueText currency='EGLD' />,
          <EstimatedValueText />,
        ],
        thClassNames: [
          '',
          '',
          clsxm(
            'translate-x-[4.8px] md:translate-x-[8px] ',
            md1 && '!translate-x-[-8px]',
          ),
        ],
        data: activeClaimsNfts,
        actions: ({
          all = false,
          lg = false,
          key = '',
          nonce = 0,
          valueEGLD = '0',
        }) => {
          return [
            <HintTransaction className='!w-auto' placement='left-center'>
              <Button
                disabled={!hasEnoughEGLDBalance || hasPendingTransactions}
                className={classNames(
                  'flex items-center bg-[#4AAD7C] text-white',
                  all
                    ? 'h-[21px] md:h-[27px] translate-y-px'
                    : 'h-[19px] md:h-[23px]',
                  md1 && all && 'bridge2xl:h—[30px]',
                  md1 && !all && 'bridge2xl:h—[26px]',
                )}
                small={!all}
                onClick={() => {
                  if (!hasEnoughEGLDBalance || hasPendingTransactions) return;

                  if (all) {
                    handleAllUnbond(
                      activeClaimsNfts.map(({ tokenNonce }) => tokenNonce),
                      activeClaimsNfts
                        .reduce(
                          (prev, { valueEGLD }) => prev.plus(valueEGLD),
                          new DefiUtils('0'),
                        )
                        .toString(),
                    );
                  } else {
                    handleUnbond(nonce, valueEGLD);
                  }
                }}
              >
                <span
                  className={clsxm(
                    'capitalize w-full text-center ',
                    md1 && '!w-fit !text-start',
                  )}
                >
                  {all
                    ? t('translation:claim-all', 'Claim all')
                    : t('translation:claim', 'Claim')}
                </span>
              </Button>
            </HintTransaction>,
          ];
        },
      },
      {
        caption: t('unstake-requests'),
        th: [
          t('unstaked-tokens'),
          <EstimatedValueText currency='EGLD' />,
          <EstimatedValueText />,
        ],
        thClassNames: ['', '', clsxm(' ', md1 && 'xl:!translate-x-[-8.4px]')],
        data: unstakeRequestNfts,
        actions: ({ all = false, lg = false }) => [],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeClaimsNfts,
      hasEnoughEGLDBalance,
      hasPendingTransactions,
      t,
      unstakeRequestNfts,
    ],
  );

  const getAnimationClassNames = (visible = true) => {
    return md
      ? classNames(
          'transition-all ease-in-out',
          visible
            ? 'relative active z-20 duration-700 opacity-100 scale-100'
            : 'duration-300  opacity-0 scale-[0.98]',
        )
      : 'transition-all ease-in-out max-h-[0] overflow-hidden';
  };

  const [p1Ref, , p1Toggle] = useCollapse<HTMLDivElement>({
    defaultValue: true,
    callback: () => {},
  });

  const [p2Ref, , p2Toggle] = useCollapse({
    defaultValue: false,
    callback: () => {},
  });

  const handleTabClick = (tabI: number) => {
    if (tabI == tab) return;
    setTab(tabI);
    p1Toggle();
    p2Toggle();
  };

  useEffect(() => {
    if (!tab) p1Toggle(true);
    else p2Toggle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels, md]);

  useEffect(() => {
    handleTabClick(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const [shimmerEnabled] = useShimmerControllerContext();

  const isUnstakeTab = tab == 1;
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial='hidden'
        custom={{ onlyY: true, movePx: 12 }}
        variants={TRANSITION_VARIANTS}
        whileInView='visible'
        viewport={{ once: ONCE, amount: 0.3 }}
        className='relative w-full'
      >
        <div
          className={classNames(
            cardClasses,
            '!p-[7px] !pt-[8px] !pb-[0px] md:!pb-[10px] md:!p-[18px] md:!pt-[18px]',
            ' !border md:!border-[2px] !rounded-[12px]  md:!rounded-[16px] font-hass font-semibold',
            'w-full whitespace-nowrap text-[10px]  md:text-[14px]',
            md1 && 'lg:mx-auto xl:!p-[18px] xl:!pt-[18px] xl:pb-[10px] w-full',
            lg && !xl && 'max-w-[540px]',
          )}
        >
          <div className='relative w-full '>
            <hr
              className={classNames(
                'absolute top-[34.8px] md:top-[43px] w-full border-t border-[#DCDCE3] dark:border-[#625E8F] ',
                md1 && '  xl:top-[43.1px]',
              )}
            />
            <div
              className={clsxm(
                'flex flex-col gap-[8.5px]  md:gap-[12px]  ',
                md1 && 'xl:gap-[11.6px]',
              )}
            >
              {/* tabs */}
              <div
                className={clsxm(
                  'relative flex gap-[4px] md:gap-[8px] items-center',
                  md1 && ' xl:gap-[32px] ',
                )}
              >
                {panels.map(({ caption, data, actions }, i) => (
                  <>
                    <Tab
                      className={classNames({
                        'text-center': i === 1,
                      })}
                      buttonClassName={clsxm(
                        'pl-[11px] pr-[11.5px] pb-[4.5px] pt-[5px] md:pl-[11px] md:pr-[12px] md:pb-[9px] md:pt-[9px] ',
                        md1 && ' xl:pr-[20px] xl:pl-[19px] xl:pb-[9.4px]',
                      )}
                      onClick={() => handleTabClick(i)}
                      active={tab === i}
                    >
                      {caption}
                    </Tab>

                    {!!data.length && (
                      <div
                        className={classNames(
                          'right-0 top-[7.3px] justify-center gap-[12px] !absolute flex ',
                          md1 && 'xl:top-1.5 ',
                          getAnimationClassNames(tab === i),
                        )}
                      >
                        {actions({
                          all: true,
                          lg: true,
                          key: 'action-all-2',
                        }).map((e, i) => e)}
                      </div>
                    )}

                    {!!data.length && (
                      <div
                        className={classNames(
                          ' absolute bottom-[8px] md:bottom-0 right-0 justify-center gap-[8px] md:hidden',
                          md1 && 'xl:bottom-2 ',
                          // tab === i ? 'flex' : 'hidden',
                          'flex',
                        )}
                      >
                        {actions({
                          all: true,
                          lg: true,
                          key: 'actions-all',
                        }).map((e, i) => e)}
                      </div>
                    )}
                  </>
                ))}
              </div>

              {/* empty state */}
              {panels.map(({ caption, data, actions }, i) => (
                <>
                  {!shimmerEnabled && !data.length && tab === i && (
                    <div
                      className={clsxm(
                        'w-full relative flex justify-center mt-[3px] md:mt-0  h-[38.8px] md:h-[60.8px] translate-y-[-4px] md:translate-y-[-4px]  translate-x-[14.9px] md:translate-x-[-10.1px]  items-center',
                        md1 &&
                          'xl:h-[66.3px] xl:translate-y-[10px] xl:translate-x-[-10px] xl:mt-[-10.2px]',
                      )}
                    >
                      <m.div
                        ref={(current) => {
                          // @ts-ignore
                          if (i === 0) p1Ref.current = current;
                          // @ts-ignore
                          else p2Ref.current = current;
                        }}
                        className={classNames(
                          ' flex justify-center items-center',
                          'text-center text-[10px] md:text-[14px] h-full',
                          {
                            'pointer-events-none': tab !== i,
                          },
                          getAnimationClassNames(true),
                        )}
                      >
                        <span>
                          {t('you-have-no-x', { topic: caption.toLowerCase() })}
                        </span>
                      </m.div>
                    </div>
                  )}
                </>
              ))}

              {panels.map(({ caption, th, thClassNames, data, actions }, i) => {
                return (
                  <Fragment key={i}>
                    {data && data.length > 0 && tab === i && (
                      <>
                        <div
                          className={clsxm(
                            'w-full flex items-center justify-between text-[8px]  md:text-[12px] leading-tight',
                            'xl:text-[14px]',
                          )}
                        >
                          <div
                            className={clsxm(
                              'w-full flex items-center gap-[32.4px] md:gap-[40px]',
                              !isUnstakeTab && 'xl:gap-[174.5px]',
                              isUnstakeTab && 'xl:gap-[147.5px]',
                            )}
                          >
                            {th.map((e, i) => (
                              <div
                                key={i}
                                className={classNames(
                                  {
                                    'place-self-start': i == 0,
                                  },
                                  'self-start eading-tight text-[#606060] dark:text-[#FFFFFF]',
                                  thClassNames[i],
                                )}
                              >
                                {e}
                              </div>
                            ))}
                          </div>

                          {isUnstakeTab && (
                            <div
                              key={i}
                              className={classNames(
                                'self-start text-[#606060] dark:text-[#FFFFFF]',
                              )}
                            >
                              {t('redeemable-at')}
                            </div>
                          )}
                        </div>

                        <SimpleBar
                          autoHide={false}
                          className={classNames(
                            'h-full  transition-all  md:!mb-[6.5px]',

                            md1 && 'xl:mb-0',
                            {
                              'md:h-[0px]': tab !== i,
                              'pointer-events-none': tab !== i,
                            },
                            data &&
                              data.length === 1 &&
                              'min-h-[26px] mb-[0px]',

                            data &&
                              data.length > 1 &&
                              '!max-h-[45.3px] md!min-h-[45.3px] mb-[7.5px]',
                            data &&
                              data.length > 1 &&
                              'md:!max-h-[63px] md:!min-h-[63px]',

                            md1 &&
                              data &&
                              data.length > 1 &&
                              'xl:min-h-[calc(26px_*_2_+_12px)] xl:max-h-[calc(26px_*_2_+_12px)]',

                            'w-full',
                          )}
                        >
                          <style jsx global>
                            {`
                              .simplebar-track.simplebar-vertical {
                                right: -7.4px;
                                width: 8px;
                              }

                              @media screen and (min-width: 768px) {
                                .simplebar-track.simplebar-vertical {
                                  right: -9px;
                                }
                              }

                              @media screen and (min-width: 1024px) {
                                .simplebar-track.simplebar-vertical {
                                  right: -16px;
                                  width: 10px;
                                }
                              }
                            `}
                          </style>

                          <m.div
                            ref={(current) => {
                              // @ts-ignore
                              if (i === 0) p1Ref.current = current;
                              // @ts-ignore
                              else p2Ref.current = current;
                            }}
                            className={classNames(
                              'w-full flex flex-col  items-start gap-[5.3px]  md:gap-[12.3px] ',
                              data.length > 2 && ' !w-full',
                              md1 && 'xl:gap-[12.4px] ',
                              getAnimationClassNames(tab === i),
                            )}
                            key={`panel-${i}`}
                          >
                            {shimmerEnabled ? (
                              <ClaimsAndRequestsShimmer />
                            ) : (
                              <Fragment>
                                <div
                                  className={clsxm(
                                    'w-full flex flex-col gap-[7.3px] md:gap-[9px] md:mt-[1.5px]',
                                    md1 && 'xl:mt-0 xl:gap-[12px]',
                                  )}
                                >
                                  {data &&
                                    data.map(
                                      (
                                        {
                                          token,
                                          value,
                                          valueEGLD,
                                          valueUSD,
                                          expireIn,
                                          tokenNonce,
                                          source,
                                        },
                                        ri,
                                      ) => (
                                        <ClaimsAndRequestsUSHRow
                                          key={ri}
                                          {...{
                                            token,
                                            value,
                                            valueEGLD,
                                            valueUSD,
                                            expireIn,
                                            tokenNonce,
                                            source,
                                            actions,
                                            isUnstake: isUnstakeTab,
                                          }}
                                          _isUsh={false}
                                        />
                                      ),
                                    )}
                                </div>
                              </Fragment>
                            )}
                          </m.div>

                          {/* {data.length >= 2 && (
                          <div
                            className={classNames(
                              // show only when viewport between md and lg
                              'my-[12px] hidden justify-center gap-[12px] md:flex lg:hidden',
                              getAnimationClassNames(tab === i),
                            )}
                          >
                            {actions({
                              all: true,
                              lg: true,
                              key: 'action-all-2',
                            }).map((e, i) => e)}
                          </div>
                          )} */}
                        </SimpleBar>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default ClaimsAndRequests;
