import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';

import { MIN_EGLD_FEE } from '@/hooks/interaction/useLendInteraction';
import { MESSAGE_ERROR_KEYS } from '@/hooks/liquidStakingApp/useLiquidStakingMessageError';

import InfoIcon from '@/components/Icons/InfoIcon';

import { formatNumber, nFormatter } from '@/utils/helpers';

export const AmountLimitErrors: React.FC<{
  errorKey: string;
  max?: string;
  min?: string;
  decimalPlaces?: number;
  operation?: string;
  token?: string;
  onChange: (value: string) => void;
}> = ({
  errorKey,
  max,
  min,
  operation,
  token,
  onChange,
  decimalPlaces = 2,
}) => {
  const { t } = useTranslation('liquid-app');

  return (
    <div
      style={{ marginTop: 20 }}
      className={classNames(
        'flex-xy-center min-h-[34px] rounded-md py-0.5 text-center text-[10px] font-normal leading-tight text-white sm:text-xs',
        errorKey === MESSAGE_ERROR_KEYS.GAS_FEE
          ? 'bg-[#D9A625]'
          : errorKey === MESSAGE_ERROR_KEYS.COLLATERAL
          ? 'bg-[#3E92FF]'
          : 'bg-[#E45059]',
      )}
    >
      {errorKey === MESSAGE_ERROR_KEYS.GAS_FEE && (
        <div>
          <span className='block opacity-80'>
            <InfoIcon
              width={10}
              height={10}
              strokeWidth={1.4}
              className='-mt-0.5 mr-1 inline-block'
            />
            {t('translation:not-enough-balance')}
          </span>
          <span className='block font-semibold'>
            {t('translation:minimum-gas-fee')}{' '}
            {`= ${new DefiUtils(MIN_EGLD_FEE)
              .toFullDecimals(18)
              .toString()} EGLD`}
          </span>
        </div>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.COLLATERAL && (
        <div>
          <div className='rounded-md p-0.5 text-center text-xs font-normal leading-tight'>
            <InfoIcon
              width={10}
              height={10}
              strokeWidth={1.4}
              className='-mt-1 mr-1 inline-block'
            />
            <span
              dangerouslySetInnerHTML={{
                __html: t('max-amount-when-having-outstanding-borrow', {
                  value: ``,
                }),
              }}
            ></span>{' '}
            <button
              type='button'
              onClick={() => onChange(max || '0')}
              className='font-semibold text-white hover:underline'
            >
              {nFormatter(max || '0')} {token}.
            </button>
          </div>
        </div>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.REPAY && (
        <div>
          <div className='rounded-md p-0.5 text-center text-xs font-normal leading-tight'>
            <InfoIcon
              width={10}
              height={10}
              strokeWidth={1.4}
              className='-mt-1 mr-1 inline-block'
            />
            <span>
              <Trans
                i18nKey='error.6134'
                components={[<br className='hiddem sm:block' />]}
                ns='liquid-app'
              />
            </span>
          </div>
        </div>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.LIQUIDATED && (
        <div>
          <div className='rounded-md p-0.5 text-center text-xs font-normal leading-tight'>
            <InfoIcon
              width={10}
              height={10}
              strokeWidth={1.4}
              className='-mt-1 mr-1 inline-block'
            />
            <span>{t('error.8796')}</span>
          </div>
        </div>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.RECOMMENDED && (
        <div>
          <div className='rounded-md p-0.5 text-center text-xs font-normal leading-tight'>
            <InfoIcon
              width={10}
              height={10}
              strokeWidth={1.4}
              className='-mt-1 mr-1 inline-block'
            />
            <span>
              <Trans
                i18nKey='error.2458'
                components={[
                  <br className='hiddem sm:block' />,
                  <br className='hiddem sm:block' />,
                ]}
                ns='liquid-app'
              />{' '}
              <button
                type='button'
                className='font-semibold text-white hover:underline'
                onClick={() => onChange(max || '0')}
              >
                {formatNumber(max, 2)} {token}.
              </button>
            </span>
          </div>
        </div>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.NO_FUNDS_AVAILABLE && (
        <>
          <InfoIcon
            width={10}
            height={10}
            strokeWidth={1.4}
            className='mr-1 inline-block'
          />

          <span className='font-semibold text-white'>
            {t('translation:no-funds-available')}.
          </span>
        </>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE && (
        <>
          <InfoIcon
            width={10}
            height={10}
            strokeWidth={1.4}
            className='mr-1 inline-block'
          />

          <span className='font-semibold text-white'>{t('errors.2648')}</span>
        </>
      )}

      {errorKey === MESSAGE_ERROR_KEYS.AMOUNT_LIMIT && (
        <>
          <InfoIcon
            width={10}
            height={10}
            strokeWidth={1.4}
            className='mr-1 inline-block'
          />
          {t('amount-limit', {
            minMax: (!!min ? t('min') : t('max')).toLowerCase(),
            operation: operation?.toLowerCase(),
          })}
          &nbsp;
          <span className='font-semibold text-white'>
            {' '}
            {!!min ? (
              <button
                type='button'
                className='hover:underline'
                onClick={() => onChange(min)}
              >
                {formatNumber(
                  min,
                  new DefiUtils(min).decimalPlaces() >= 2
                    ? decimalPlaces
                    : new DefiUtils(min).decimalPlaces(),
                )}{' '}
                {token}.
              </button>
            ) : (
              <button
                type='button'
                className='hover:underline'
                onClick={() => onChange(max || '0')}
              >
                {formatNumber(max, 2)} {token}.
              </button>
            )}
          </span>
        </>
      )}
    </div>
  );
};
