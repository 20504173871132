import React from 'react';

export const SwitchBtn: React.FC<any> = ({ onClick }) => {
  return (
    <button type='button' className='transition-all hover:brightness-105' onClick={onClick}>
      <svg
        width={34}
        height={34}
        viewBox='0 0 34 34'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='33.3333' height='33.3333' rx={4} fill='#7ECBB7' />
        <g clipPath='url(#clip0_109_4502)'>
          <path
            d='M13.333 7.33398V18.0007'
            stroke='white'
            strokeWidth='2.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M17.333 11.334L13.333 7.33398L9.33301 11.334'
            stroke='white'
            strokeWidth='2.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M20 26.0007V15.334'
            stroke='white'
            strokeWidth='2.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M16 22L20 26L24 22'
            stroke='white'
            strokeWidth='2.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_109_4502'>
            <rect
              width='17.3333'
              height='21.3333'
              fill='white'
              transform='translate(8 6)'
            />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};
