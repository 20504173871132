import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useId, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import useDebounce from '@/hooks/useDebounce';
import useTheme from '@/hooks/useTheme';

import PopupBed from '@/components/popups/PopupBed';
import ScrollBlockTargetElement from '@/components/ScrollBlockTargetElement';
import WrappedImg from '@/components/WrappedImg';

import { useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import { liquidStakingTaoAppSelector } from '@/store/liquid-staking-tao-app';
import { popupSelector } from '@/store/popup';
import { protocolSelector } from '@/store/protocol';
import { MARKET_KEY } from '@/store/protocol';

import { formatNumber } from '@/utils/helpers';

export const Sonar: React.FC<any> = () => {
  const glowEffectProps = (style = {}) => {
    return {
      initial: { [`--shadow-color`]: '#fff0' },
      animate: { [`--shadow-color`]: '#2fbe807d' },
      transition: {
        repeat: Infinity,
        repeatDelay: 1,
        repeatType: 'mirror',
        duration: 1,
      },
      style: {
        ...style,
        filter:
          'drop-shadow(1px 1px 3px var(--shadow-color)) drop-shadow(-1px -1px 3px var(--shadow-color))',
      },
    };
  };
  const id = useId();
  return (
    <div className='relative flex items-center'>
      <LazyMotion features={domAnimation}>
        <m.div
          {...glowEffectProps({
            width: 13.24,
            height: 13.24,
          })}
          className='mr-0.5 aspect-square rounded-full bg-[#2FBE81]'
        ></m.div>
        <m.div {...glowEffectProps({})}>
          <div
            style={{
              clipPath: `url(#${id})`,
              width: 9.5,
              height: 22,
            }}
            className='bg-[#2FBE81]'
          />
        </m.div>
        <svg width='0' height='0'>
          <defs>
            <clipPath id={id}>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.88151 11.4089C2.88151 8.82993 1.87372 6.49307 0.224125 4.75971L4.75729 0.445312C7.46983 3.29559 9.13949 7.16057 9.13949 11.4089C9.13949 15.6936 7.4411 19.5882 4.68747 22.4453L0.181641 18.1024C1.85645 16.3646 2.88151 14.0097 2.88151 11.4089Z'
                fill='#2FBE81'
              />
            </clipPath>
          </defs>
        </svg>
      </LazyMotion>
    </div>
  );
};

const HatomValidators = () => {
  const { data } = useAppSelector(popupSelector);
  const { validators: lsValidators } = useAppSelector(liquidStakingAppSelector);
  const { validators: lsTaoValidators } = useAppSelector(
    liquidStakingTaoAppSelector,
  );
  const { isLoadingFirstInfo } = useAppSelector(protocolSelector);
  const { t } = useTranslation('liquid-app');
  const { isLight } = useTheme();
  const debouncedLoading = useDebounce(isLoadingFirstInfo, 300);

  const isLiquidStakingSEgld = data.liquidStaking === MARKET_KEY.sEGLD;

  const filteredValidators = useMemo(() => {
    if (isLoadingFirstInfo) {
      return new Array(12).fill(0).map((_, index) => ({
        address: index,
        logo: { dark: '', light: '' },
        name: '',
        staked: '0',
        website: undefined,
      }));
    }

    return isLiquidStakingSEgld ? lsValidators : lsTaoValidators;
  }, [isLiquidStakingSEgld, isLoadingFirstInfo, lsTaoValidators, lsValidators]);

  return (
    <PopupBed width={window.innerWidth > 640 ? 794 : 445}>
      <div className='bg-[#FAFAFD] py-10 px-5 dark:bg-[#191731]'>
        <div className='flex items-center justify-center gap-2'>
          <h4 className='gradient-text font-ttFirsNeue text-[36px] font-semibold capitalize tracking-wide [--gradient:linear-gradient(90.2deg,#1F2226_13.63%,#6F19E7_99.84%)] dark:[--gradient:linear-gradient(90.2deg,#fff_13.63%,#6F19E7_99.84%)]'>
            {t('x-node-operators', { count: 'Hatom' as any })}
          </h4>
          <Sonar />
        </div>
        <ScrollBlockTargetElement
          className={classNames(
            'hideScrollbar -m-2 my-0 mt-8 h-[300px] overflow-auto p-2',
          )}
        >
          <div className='grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-[repeat(4,172px)]'>
            {filteredValidators.map(
              ({ name, staked, logo, address, website }, i) => {
                return (
                  <a
                    href={website}
                    target='_blank'
                    rel='noreferrer'
                    key={address}
                    className='block max-h-[85px] overflow-hidden rounded-2xl border border-[#DCDCE3] bg-[#F9F6FF] text-[12px] font-semibold transition-transform hover:scale-[1.03] dark:border-[#7D7AAA] dark:bg-[#676198]/70'
                  >
                    <div className='flex items-center gap-2.5 bg-white p-2.5 dark:bg-[#191731] dark:text-white'>
                      {debouncedLoading && (
                        <Skeleton height={28} width={28} circle />
                      )}
                      <div
                        className={classNames(
                          'h-[28px] w-[28px] min-w-[28px] overflow-hidden rounded-full',
                          {
                            'bg-[#F9F6FF] dark:bg-[#4F4B7A]':
                              !logo.light || logo.light.length === 0,
                            hidden: debouncedLoading,
                          },
                        )}
                      >
                        <WrappedImg
                          src={isLight ? logo.light : logo.dark}
                          alt=''
                        />
                      </div>
                      <div>
                        {debouncedLoading ? <Skeleton width={80} /> : name}
                      </div>
                    </div>
                    <div className='border-t-2 border-[#DCDCE3] py-2 text-center text-[#3A3C40] dark:border-[#7D7AAA] dark:text-white'>
                      {debouncedLoading ? (
                        <Skeleton width={60} />
                      ) : (
                        `${formatNumber(staked, 2)} ${
                          isLiquidStakingSEgld ? 'EGLD' : 'WTAO'
                        }`
                      )}
                    </div>
                  </a>
                );
              },
            )}
          </div>
        </ScrollBlockTargetElement>
      </div>
    </PopupBed>
  );
};

export default HatomValidators;
