import { BooleanValue } from '@multiversx/sdk-core';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import {
  H_TOKEN_DECIMALS,
  MARKET_KEY,
  protocolSelector,
} from '@/store/protocol';

export enum LIQUID_STAKING_METHOD {
  UN_DELEGATE = 'unDelegate',
  DELEGATE = 'delegate',
  WITHDRAW = 'withdraw',
  LENDEGATE = 'lendegate', // delegate, (mint=supply) + (enter_market=addCollateral)
  UN_LENDEGATE = 'unlendegate', // (redeem=withdraw) y undelegate
}

const useLiquidStakingInteraction = () => {
  const { buildTransaction } = useSignMultipleTransactions();
  const {
    liquidStaking: {
      undelegateTokenId,
      address: liquidStakingAddress,
      zapAddress,
    },
    markets,
  } = useAppSelector(protocolSelector);

  const unbond = ({ nonce }: { nonce: number }) => {
    return buildTransaction({
      smartContractAddress: liquidStakingAddress,
      func: LIQUID_STAKING_METHOD.WITHDRAW,
      group: 'liquid',
      isPayable: true,
      nft: {
        tokenIdentifier: undelegateTokenId,
        nonce,
      },
    });
  };

  const unDelegate = ({
    amount,
    tokenKey,
  }: {
    amount: string;
    tokenKey: string;
  }) => {
    const { underlying } = markets[tokenKey as MARKET_KEY] || {};

    return buildTransaction({
      smartContractAddress: liquidStakingAddress,
      func: LIQUID_STAKING_METHOD.UN_DELEGATE,
      group: 'liquid',
      isPayable: true,
      token: {
        tokenIdentifier: underlying.id,
        numDecimals: underlying.decimals,
        amount,
      },
    });
  };

  const delegate = ({ amount }: { amount: string }) => {
    return buildTransaction({
      smartContractAddress: liquidStakingAddress,
      func: LIQUID_STAKING_METHOD.DELEGATE,
      group: 'liquid',
      isPayable: true,
      value: amount,
    });
  };

  const lendegate = ({
    amount,
    hasEnterMarket,
  }: {
    amount: string;
    hasEnterMarket: boolean;
  }) => {
    return buildTransaction({
      smartContractAddress: zapAddress,
      func: LIQUID_STAKING_METHOD.LENDEGATE,
      args: [new BooleanValue(hasEnterMarket)],
      group: 'liquid',
      isPayable: true,
      value: amount,
      isFromBigInteger: true,
    });
  };

  const unLendegate = ({
    amount,
    tokenKey,
  }: {
    amount: string;
    tokenKey: string;
  }) => {
    const { hToken } = markets[tokenKey as MARKET_KEY] || {};

    return buildTransaction({
      smartContractAddress: zapAddress,
      func: LIQUID_STAKING_METHOD.UN_LENDEGATE,
      group: 'liquid',
      isPayable: true,
      token: {
        tokenIdentifier: hToken.id,
        numDecimals: H_TOKEN_DECIMALS,
        isFromBigInteger: true,
        amount,
      },
    });
  };

  return {
    unbond,
    unDelegate,
    delegate,
    lendegate,
    unLendegate,
  };
};

export default useLiquidStakingInteraction;
