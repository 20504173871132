function TokenCurrencyTag({
  value,
  currency,
}: {
  value: string;
  currency: string;
}) {
  return (
    <div className='rounded-[6px] h-[19px] w-fit bg-[#E2DFFB] flex items-center gap-[4px] text-[12px] px-[6px]'>
      <p className='text-[#6A6A8C]'>{value}</p>
      <p className='text-[#8787A5]'>{currency}</p>
    </div>
  );
}

export default TokenCurrencyTag;
