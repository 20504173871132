import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';

import boosterABI from '@/abis/booster';
import client from '@/services/blockchain/client';

export const getAccountStake = async (
  boosterAddress: string,
  accountAddress: string,
): Promise<string> => {
  if (accountAddress.length === 0) {
    return '0';
  }

  const response = await client({
    address: boosterAddress,
    method: 'getAccountStake',
    abi: AbiRegistry.create(boosterABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response || '0';
};

export const getTotalStake = async (
  boosterAddress: string,
): Promise<string> => {
  const response = await client({
    address: boosterAddress,
    method: 'getTotalStake',
    abi: AbiRegistry.create(boosterABI),
  });

  return response || '0';
};
