import { addMinutes } from 'date-fns';
import DefiUtils from 'defi-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import { setLiquidStakingApp } from '@/store/liquid-staking-app';
import {
  H_TOKEN_DECIMALS,
  nativeMarketSelector,
  protocolSelector,
  sEgldMarketSelector,
} from '@/store/protocol';

import liquidStakingABI from '@/abis/liquid-staking';
import indexerService from '@/services/indexer';
import { ResponseUndelegateToken } from '@/services/indexer/liquid-staking-app/types';
import { calcUnbondDate } from '@/utils/blockchain';
import { getTypeFromData } from '@/utils/helpers';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

const formatUndelegateNfts = (
  undelegateNftsFromWallet: {
    amountUsd: string;
    segldAmount: string;
    egldAmount: string;
    availableToRedeemDate: string;
    canRedeem: boolean;
    tokenNonce: number;
    tokenIdentifier: string;
  }[],
  undelegateNftsFromIndexerMap: Record<string, ResponseUndelegateToken>,
) => {
  return undelegateNftsFromWallet.map((undelegateNftItem) => {
    const source =
      undelegateNftsFromIndexerMap?.[undelegateNftItem.tokenNonce]?.source ||
      'LiquidStaking';

    const hsegldAmount = new DefiUtils(
      undelegateNftsFromIndexerMap?.[undelegateNftItem.tokenNonce]
        ?.hLsTokenAmount || '0',
    )
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();

    return {
      ...undelegateNftItem,
      source,
      hsegldAmount,
    };
  });
};

const useStoreUndelegateNfts = () => {
  const dispatch = useAppDispatch();
  const {
    liquidStaking: { undelegateTokenId },
    blockchain: { roundsPerEpoch, roundsPassed, epoch },
  } = useAppSelector(protocolSelector);
  const { tokens } = useAppSelector(accountSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const [undelegateNftsFromIndexer, setUndelegateNftsFromIndexer] = useState<
    ResponseUndelegateToken[]
  >([]);

  const undelegateNftsFromWallet = useMemo(() => {
    return tokens
      .filter((token) => token.tokenIdentifier.includes(undelegateTokenId))
      .map((token) => {
        const data = getTypeFromData({
          type: 'UndelegateAttributes',
          value: token.attributes || '',
          abi: liquidStakingABI,
        });

        const egldAmountAsBigNumber = data?.fieldsByName
          .get('egld_amount')
          .value.toString() as string;
        const unbondEpoch = parseInt(
          data?.fieldsByName.get('unbond_epoch').value.toString(),
        );
        const shares = data?.fieldsByName.get('shares').value.toString();

        const egldAmount = new DefiUtils(egldAmountAsBigNumber)
          .toFullDecimals(nativeMarket.underlying.decimals)
          .toSafeFixed(nativeMarket.underlying.decimals, DefiUtils.ROUND_DOWN);

        const amountUsd = new DefiUtils(egldAmount)
          .toUSD(nativeMarket.underlying.priceUSD)
          .removeScientificNotation()
          .toString();

        const availableToRedeemDate = calcUnbondDate({
          roundsPerEpoch,
          roundsPassed,
          unbondEpoch,
          epoch,
        });

        const updatedAvailableToRedeemDate = addMinutes(
          new Date(availableToRedeemDate),
          10,
        ).toISOString();

        const segldAmount = new DefiUtils(shares)
          .toFullDecimals(sEgldMarket.underlying.decimals)
          .toSafeFixed(sEgldMarket.underlying.decimals, DefiUtils.ROUND_DOWN);

        const canRedeem =
          Date.now() >= new Date(updatedAvailableToRedeemDate).getTime();

        return {
          amountUsd,
          segldAmount,
          egldAmount,
          availableToRedeemDate: updatedAvailableToRedeemDate,
          canRedeem,
          tokenNonce: token.nonce || 0,
          tokenIdentifier: token.tokenIdentifier,
        };
      });
  }, [
    tokens,
    epoch,
    nativeMarket,
    roundsPassed,
    roundsPerEpoch,
    sEgldMarket,
    undelegateTokenId,
  ]);

  const undelegateNftsFromWalletIds = useMemo(() => {
    return undelegateNftsFromWallet.map(({ tokenNonce }) => String(tokenNonce));
  }, [undelegateNftsFromWallet]);

  const undelegateNftsFromWalletIdsString = useMemo(() => {
    return undelegateNftsFromWalletIds.join(',');
  }, [undelegateNftsFromWalletIds]);

  const getUndelegateNfts = useCallback(async () => {
    if (undelegateTokenId === '') {
      dispatch(
        setLiquidStakingApp({
          undelegateNfts: [],
        }),
      );
      return;
    }

    const undelegateNftsFromIndexerMap = undelegateNftsFromIndexer.reduce(
      (prev, current) => ({ ...prev, [current.id]: current }),
      {} as Record<string, ResponseUndelegateToken>,
    );

    const undelegateNfts = formatUndelegateNfts(
      undelegateNftsFromWallet,
      undelegateNftsFromIndexerMap,
    );

    dispatch(
      setLiquidStakingApp({
        undelegateNfts,
      }),
    );
  }, [
    dispatch,
    undelegateNftsFromIndexer,
    undelegateNftsFromWallet,
    undelegateTokenId,
  ]);

  const getUndelegateNftsFromIndexer = useCallback(async () => {
    const ids = undelegateNftsFromWalletIdsString
      .split(',')
      .filter((item) => item.length > 0);

    const undelegateNftsFromIndexer = await queryClient.safeFetchQuery({
      queryKey: ['getUndelegateTokens', ids.join('-')],
      queryFn: () => indexerService.liquidStakingApp.getUndelegateTokens(ids),
      cacheTime: MAX_CACHE_TIME,
      staleTime: MAX_CACHE_TIME,
      defaultValue: [] as ResponseUndelegateToken[],
    });

    setUndelegateNftsFromIndexer(undelegateNftsFromIndexer);
  }, [undelegateNftsFromWalletIdsString]);

  useEffect(() => {
    getUndelegateNftsFromIndexer();
  }, [getUndelegateNftsFromIndexer]);

  useEffect(() => {
    getUndelegateNfts();
  }, [getUndelegateNfts]);
};

export default useStoreUndelegateNfts;
