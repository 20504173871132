
import DefiUtils from 'defi-utils';
import { useEffect } from 'react';

import { ReturnUseBorrowData } from '@/components/popups/BorrowPopup/hooks/useBorrowData';
import { ReturnUseBorrowForm } from '@/components/popups/BorrowPopup/hooks/useBorrowForm';

import { sleep } from '@/utils/helpers';

const useBorrowListenerFormFields = ({
  borrowForm,
  borrowData,
}: {
  borrowForm: ReturnUseBorrowForm;
  borrowData: ReturnUseBorrowData;
}) => {
  const {
    maxSelected,
    selectedTab,
    setInputValue,
    setInputValueAsBigNumber,
    handleInputFocus,
    setRepayDailyInterestSelected,
    setMaxSelected,
  } = borrowForm;
  const { market, maxRequestAmounts } = borrowData;

  useEffect(() => {
    const handleMax = async () => {
      if (!maxSelected) {
        setInputValue('');
        setInputValueAsBigNumber('');
        setRepayDailyInterestSelected(false);

        await sleep(50);

        handleInputFocus();
      } else {
        const value = new DefiUtils(maxRequestAmounts.setMaxValueAsBigNumber)
          .dividedBy(`1e${market.underlying.decimals}`)
          .toFixed(market.underlying.decimals);
        const decimalsPlaces = new DefiUtils(value).decimalPlaces() || 0;
        const valueWithMaxDecimalPlaces = new DefiUtils(value).toFixed(
          decimalsPlaces,
        );

        const valueAsBigNumber = maxRequestAmounts.setMaxValueAsBigNumber;

        if (
          parseFloat(valueWithMaxDecimalPlaces) <= 0 ||
          parseFloat(valueAsBigNumber) <= 0
        ) {
          setInputValue('');
          setInputValueAsBigNumber('');
        } else {
          setInputValue(valueWithMaxDecimalPlaces);
          setInputValueAsBigNumber(valueAsBigNumber);
        }

        await sleep(50);

        handleInputFocus();
      }
    };

    handleMax();
    // eslint-disable-next-line
  }, [maxSelected]);

  useEffect(() => {
    setMaxSelected(false);
    setInputValue('');
    setInputValueAsBigNumber('');
    setRepayDailyInterestSelected(false);
    handleInputFocus();
    // eslint-disable-next-line
  }, [selectedTab]);
};

export default useBorrowListenerFormFields;
