import DefiUtils from 'defi-utils';

import { AccountToken } from '@/store/auth';
import { LendAppState, Market } from '@/store/lend-app';
import {
  AccountDiscountRateModel,
  ASSET_ALT_NAME,
  BLACKLISTED_USH_MARKETS,
  Market as MarketProtocol,
  MARKET_KEY,
  MARKET_ORDER_MAP,
  ProtocolState,
  TOKEN_LOGO_V2_MAP,
  UserBalance,
} from '@/store/protocol';
import { Market as RewardMarket } from '@/store/reward-batch';

import { showNetApyV2 } from '@/config/envVars';
import indexerService from '@/services/indexer';
import { calcTokenApyEnumerator } from '@/utils/math/apy';
import {
  calcBorrowBalance,
  calcBorrowLimit,
  calcSupplyBalanceUSD,
} from '@/utils/math/market';

export const getBorrowTotalBalanceUSD = (state: LendAppState) => {
  const total = Object.values(state.markets).reduce(
    (prev, { underlying, accountBalances }) => {
      const result: string = calcBorrowBalance(
        accountBalances.borrow,
        underlying?.priceUSD,
        underlying?.decimals,
      );

      return prev.plus(result);
    },
    new DefiUtils('0'),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getBorrowLimitUSD = (
  markets: LendAppState['markets'],
  collateralFactorKey: 'market' | 'ush' | 'applied' = 'applied',
) => {
  const ushBorrowBalance = markets[MARKET_KEY.USH]?.accountBalances?.borrow;
  const hasUshBorrow = !new DefiUtils(ushBorrowBalance).isZero();

  const total = Object.values(markets).reduce(
    (
      acc,
      {
        underlying,
        collateralFactor: marketCollateralFactor,
        accountBalances,
        ushBorrowerCollateralFactor,
      },
    ) => {
      const appliedCollateralFactor = hasUshBorrow
        ? ushBorrowerCollateralFactor
        : marketCollateralFactor;

      const collateralBalance = calcBorrowLimit(
        accountBalances.underlyingCollateral,
        collateralFactorKey === 'applied'
          ? appliedCollateralFactor
          : collateralFactorKey === 'market'
          ? marketCollateralFactor
          : ushBorrowerCollateralFactor,
        underlying.priceUSD,
        underlying.decimals,
      );

      return acc.plus(collateralBalance);
    },
    new DefiUtils('0'),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getSupplyTotalBalanceUSD = (state: LendAppState) => {
  const total = Object.values(state.markets).reduce(
    (prev, { accountBalances, underlying, hTokenExchangeRate }) => {
      const result = calcSupplyBalanceUSD(
        accountBalances.hTokenWallet,
        hTokenExchangeRate,
        accountBalances.collateral,
        underlying.priceUSD,
        underlying.decimals,
      );

      return prev.plus(result);
    },
    new DefiUtils('0'),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getCollateralBalanceUSD = (state: LendAppState) => {
  const total = Object.values(state.markets).reduce(
    (prev, { underlying, accountBalances }) => {
      const collateralBalance = new DefiUtils(
        accountBalances.underlyingCollateral,
      )
        .toFullDecimals(underlying.decimals)
        .toUSD(underlying.priceUSD)
        .toString();

      return prev.plus(collateralBalance);
    },
    new DefiUtils(0),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getBorrowLimitUsedPercent = (
  borrowedBalance: DefiUtils.Value,
  borrowLimit: DefiUtils.Value,
) => {
  if (new DefiUtils(borrowLimit).isLessThanOrEqualTo(0)) {
    return '0';
  }

  return new DefiUtils(borrowedBalance)
    .dividedBy(borrowLimit)
    .multipliedBy(100)
    .toString();
};

export const calculateNetAPY = ({
  markets,
  rewardMarkets,
  liquidStakingAPY,
  showLiquidStakingAPY,
  liquidStakingTaoAPY,
  showLiquidStakingTaoAPY,
  accountsBoosterAPYMap,
  accountDiscountRateModel,
  userBalances,
}: {
  userBalances: ProtocolState['userBalances'];
  markets: ProtocolState['markets'];
  rewardMarkets: Record<MARKET_KEY, RewardMarket>;
  liquidStakingAPY: string;
  showLiquidStakingAPY: boolean;
  liquidStakingTaoAPY: string;
  showLiquidStakingTaoAPY: boolean;
  accountsBoosterAPYMap: Record<string, string>;
  accountDiscountRateModel: AccountDiscountRateModel;
}) => {
  const tokenAPYNumerators = Object.values(markets).reduce(
    (
      acc,
      {
        underlying,
        supplyAPY,
        borrowAPY,
        hTokenExchangeRate,
        supported,
        hToken,
      },
    ) => {
      if (!supported) {
        return acc;
      }

      const rewardsToken =
        rewardMarkets[underlying.symbol as MARKET_KEY]?.rewards || [];

      const _borrowAPY =
        underlying.symbol === MARKET_KEY.USH
          ? accountDiscountRateModel?.borrowAPY
          : borrowAPY;

      const userBalance = userBalances[underlying.symbol as MARKET_KEY];

      const accountBalances = {
        collateral: userBalance?.collateralBalance || '0',
        underlyingCollateral: userBalance?.underlyingCollateralBalance || '0',
        borrow: userBalance?.borrowBalance || '0',
        hTokenWallet: userBalance?.hTokenBalance || '0',
        underlyingHTokenWallet: new DefiUtils(userBalance?.hTokenBalance)
          .toUnderlying(hTokenExchangeRate)
          .toString(),
        underlyingWallet: userBalance?.underlyingBalance || '0',
      };

      const tokenAPY = calcTokenApyEnumerator(
        liquidStakingAPY,
        showLiquidStakingAPY,
        liquidStakingTaoAPY,
        showLiquidStakingTaoAPY,
        underlying.symbol,
        accountBalances.underlyingHTokenWallet,
        accountBalances.underlyingCollateral,
        underlying.priceUSD,
        supplyAPY,
        accountBalances.borrow,
        _borrowAPY,
        rewardsToken,
        hTokenExchangeRate,
        underlying.decimals,
        accountBalances.underlyingWallet,
        accountBalances.hTokenWallet,
        accountBalances.collateral,
        accountsBoosterAPYMap[hToken.id] || '0',
      );

      return new DefiUtils(acc).plus(tokenAPY);
    },
    new DefiUtils('0'),
  );

  const dividers = Object.values(markets).reduce(
    (acc, { underlying, supported, hTokenExchangeRate }) => {
      if (!supported) {
        return acc;
      }

      const userBalance = userBalances[underlying.symbol as MARKET_KEY];

      const accountBalances = {
        collateral: userBalance?.collateralBalance || '0',
        borrow: userBalance?.borrowBalance || '0',
        hTokenWallet: userBalance?.hTokenBalance || '0',
      };

      const accountSupplyUSD = calcSupplyBalanceUSD(
        accountBalances.hTokenWallet,
        hTokenExchangeRate,
        accountBalances.collateral,
        underlying.priceUSD,
        underlying.decimals,
      );

      let accountBorrowUSD = 0;

      if (showNetApyV2) {
        accountBorrowUSD = new DefiUtils(accountBalances.borrow)
          .toFullDecimals(underlying.decimals)
          .toUSD(underlying.priceUSD)
          .toNumber();
      }

      const divider = new DefiUtils(accountSupplyUSD).minus(accountBorrowUSD);

      return new DefiUtils(acc).plus(divider);
    },
    new DefiUtils('0'),
  );

  const result = tokenAPYNumerators.dividedBy(dividers);

  return result.toSafeString();
};

export const getHTokenByProvider = async (
  hTokenBalanceProvider: string,
  accountAddress: string,
  accountTokens: AccountToken[],
  protocolMarkets: Record<MARKET_KEY, MarketProtocol>,
): Promise<Record<MARKET_KEY, string>> => {
  switch (hTokenBalanceProvider) {
    case 'multiversx': {
      return getHTokenBalance(accountTokens, Object.values(protocolMarkets));
    }

    case 'indexer': {
      return indexerService.common.getHTokenBalance(accountAddress);
    }

    default: {
      return getHTokenBalance(accountTokens, Object.values(protocolMarkets));
    }
  }
};

export const getHTokenBalance = (
  tokenBalance: AccountToken[],
  moneyMarketsIds: { hToken: { id: string }; underlying: { symbol: string } }[],
) => {
  const hTokenAccountBalances = tokenBalance.reduce(
    (acc, token) => {
      const index = moneyMarketsIds.findIndex(
        ({ hToken }) => hToken.id === token.tokenIdentifier,
      );

      if (index === -1) return acc;

      const assetKey = moneyMarketsIds[index].underlying.symbol;

      return {
        ...acc,
        [assetKey]: token.balance,
      };
    },
    {} as Record<MARKET_KEY, string>,
  );

  return hTokenAccountBalances;
};

export const getUnderlyingBalance = (
  tokens: AccountToken[],
  moneyMarketsIds: {
    underlying: {
      symbol: string;
      id: string;
    };
  }[],
): Record<string, string> => {
  const accountBalances = tokens.reduce(
    (acc, token) => {
      const index = moneyMarketsIds.findIndex(
        ({ underlying }) => underlying.id === token.tokenIdentifier,
      );

      if (index === -1) return acc;

      const { underlying } = moneyMarketsIds[index] || {};

      return {
        ...acc,
        [underlying.symbol]: token.balance,
      };
    },
    {} as Record<MARKET_KEY, string>,
  );

  return accountBalances;
};

export const formatMarkets = (
  protocolMarkets: Record<MARKET_KEY, MarketProtocol>,
  userBalances: Record<MARKET_KEY, UserBalance>,
  accountTokens: AccountToken[],
) => {
  return Object.values(protocolMarkets)
    .map(
      ({
        underlying: { name, ...underlying },
        hToken: { name: _5, ...hToken },
        hTokenExchangeRate,
        supported,
        logo,
        address,
        supplyAPY,
        borrowAPY,
        collateralFactor,
        totalBorrow,
        totalSupplyUSD,
        totalSupply,
        supplyCap,
        borrowCap,
        mintStatus,
        borrowStatus,
        cash,
        ushBorrowerCollateralFactor,
      }) => {
        const userBalance = userBalances[underlying.symbol as MARKET_KEY];

        const hTokenWallet =
          accountTokens.find(
            (accountTokenItem) =>
              accountTokenItem.tokenIdentifier === hToken.id,
          )?.balance || '0';

        const underlyingWallet =
          accountTokens.find(
            (accountTokenItem) =>
              accountTokenItem.tokenIdentifier === underlying.id,
          )?.balance || '0';

        const accountBalances = {
          collateral: userBalance?.collateralBalance || '0',
          underlyingCollateral: userBalance?.underlyingCollateralBalance || '0',
          borrow: userBalance?.borrowBalance || '0',
          hTokenWallet,
          underlyingHTokenWallet: new DefiUtils(hTokenWallet)
            .toUnderlying(hTokenExchangeRate)
            .toString(),
          underlyingWallet,
        };

        return {
          order: MARKET_ORDER_MAP[underlying.symbol as MARKET_KEY] || 99,
          address,
          assetKey: underlying.symbol,
          underlying,
          hToken,
          hTokenExchangeRate,
          supported,
          logo,
          name,
          accountBalances,
          supplyAPY,
          borrowAPY,
          cash,
          collateralFactor,
          totalBorrow,
          totalSupplyUSD,
          totalSupply,
          supplyCap,
          borrowCap,
          mintEnabled: mintStatus === 'Active',
          borrowEnabled: borrowStatus === 'Active',
          ushBorrowerCollateralFactor,
        } as Market;
      },
    )
    .reduce(
      (prev, current) => ({
        ...prev,
        [current.assetKey]: current,
      }),
      {} as Record<MARKET_KEY, Market>,
    );
};

export const formatUshBorrowVariableRates = ({
  discountRateModels,
  markets,
}: {
  discountRateModels: ProtocolState['discountRateModels'];
  markets: ProtocolState['markets'];
}) => {
  const underlyingSymbols = Object.values(markets)
    .map(({ underlying }) => underlying.symbol)
    .filter(
      (symbol) => !BLACKLISTED_USH_MARKETS.includes(symbol as MARKET_KEY),
    );
  const ushMarket = markets[MARKET_KEY.USH];

  return underlyingSymbols
    .map((symbol) => {
      const discountRateModelItem = discountRateModels.find(
        ({ moneyMarket }) => moneyMarket == symbol,
      );

      return {
        logo: TOKEN_LOGO_V2_MAP[`H${symbol}`] || '',
        name: ASSET_ALT_NAME[symbol] || '',
        borrowAPY:
          discountRateModelItem?.borrowAPY || ushMarket?.borrowAPY || '0',
      };
    })
    .sort((a, b) => new DefiUtils(a.borrowAPY).minus(b.borrowAPY).toNumber());
};
