import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React from 'react';

import clsxm from '@/services/clsxm';

export const InlineLoader: React.FC<any> = ({
  className,
  gradient,
  label,
  onClick,
  loaderSize = 14,
  textClassName,
}) => {
  const { t } = useTranslation('liquid-app');
  return (
    <button
      type='button'
      onClick={onClick}
      className={classNames(
        className,
        'flex items-center gap-2 rounded-full bg-white p-1 pr-2 hover:bg-opacity-90 dark:bg-[#403E75]'
      )}
    >
      <div className='flex-xy-center relative h-[14px] w-[14px] md:h-[19px] md:w-[19px] rounded-full bg-[#F2F3FF] dark:bg-[#24224E]'>
        <div className='rounded-full border-2 border-[#DADCFF] dark:border-[#53508D]'
          style={{
            width: loaderSize,
            height: loaderSize,
          }}
        ></div>
        <div className='absolute-xy-center rotate-[24deg]'>
          <svg
            width={loaderSize}
            height={loaderSize}
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <LazyMotion features={domAnimation}>
              <m.path
                animate={{
                  strokeDashoffset: [90, 70, 90, 90],
                  rotate: [0, 360 * 2],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 1.8,
                  ease: [0.6, 0.2, 0.3, 1.4],
                  times: [0, 0.8, 1],
                  repeatDelay: 0.2,
                }}
                d='M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z'
                stroke='url(#paint0_linear_317_10771)'
                strokeWidth={2}
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeDasharray={100}
              />
            </LazyMotion>
            <defs>
              <linearGradient
                id='paint0_linear_317_10771'
                gradientUnits='userSpaceOnUse'
                x2='0%'
                y2='100%'
              >
                <stop offset='0.5' stopColor={gradient[0]} />
                <stop offset={1} stopColor={gradient[1]} />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <span className={clsxm('text-[14px] leading-6 text-[#3F3B65] dark:text-white truncate', textClassName)}>
        {t(label)}
      </span>
    </button>
  );
};
