import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { PlacementType } from 'react-laag/dist/PlacementType';

import Hint from '@/components/Hint';

import { useAppSelector } from '@/store';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';
import { hasPendingTransactionsSelector } from '@/store/transaction';

interface HintTransactionProps {
  children: ReactNode;
  placement?: PlacementType;
  className?: string;
}

const HintTransaction = ({ children, className, placement }: HintTransactionProps) => {
  const { t } = useTranslation();

  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);

  return (
    <Hint
      content={
        hasPendingTransactions
          ? t('translation:wait-tx-inprogress')
          : t('translation:not-enough-balance')
      }
      hidden={hasEnoughEGLDBalance && !hasPendingTransactions}
      className={classNames('w-full', className)}
      placement={placement}
    >
      {children}
    </Hint>
  );
};

export default HintTransaction;
