import blockchainService from '@/services/blockchain';
import {
  InterestRateModelData,
  MarketData,
} from '@/services/blockchain/lens/types';
import { ResponseMarkets } from '@/services/blockchain/money-market/types';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export const getMarkets = async (
  markets: { address: string; symbol: string }[],
): Promise<ResponseMarkets[]> => {
  const queryKey = ['blockchain:money-market:getMarkets'];

  const queryFn = async () => {
    const [moneyMarketsData, interestRateModels] = await Promise.all([
      blockchainService.lens.getMarketsData([]),
      blockchainService.lens.getInterestRateModelsData(),
    ]);

    const interestRateModelsMap = interestRateModels.reduce(
      (prev, current) => ({
        ...prev,
        [current.market]: current,
      }),
      {} as Record<string, InterestRateModelData>,
    );

    const moneyMarketsDataMap = moneyMarketsData.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current,
      }),
      {} as Record<string, MarketData>,
    );

    return markets.map(({ address, symbol }) => {
      const moneyMarketItem = moneyMarketsDataMap[address] || {};

      return {
        collateralFactor: moneyMarketItem?.collateralFactor || '0',
        reserveFactor: moneyMarketItem?.reserveFactor || '0',
        ushBorrowerCollateralFactor:
          moneyMarketItem?.ushBorrowerCollateralFactor || '0',
        interestRateModel: interestRateModelsMap[address] || {
          baseRate: '0',
          firstSlope: '0',
          lastSlope: '0',
          optimalUtilization: '0',
        },
        underlying: {
          symbol,
        },
        lastState: {
          totalSupply: moneyMarketItem.totalSupply || '0',
          borrows: moneyMarketItem.totalBorrows || '0',
          supplyRatePerSecond: moneyMarketItem.supplyRatePerSecond || '0',
          borrowRatePerSecond: moneyMarketItem.borrowRatePerSecond || '0',
          cash: moneyMarketItem.cash || '0',
          reserves: moneyMarketItem.totalReserves || '0',
        },
      };
    });
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<ResponseMarkets[]>;
};
