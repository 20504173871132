import DefiUtils from 'defi-utils';

import {
  HIDDEN_DELEGATION_CONTRACTS,
  IsolatedLendingProtocols,
  MARKET_KEY,
  TAO_SERVICE_FEE,
  Token,
} from '@/store/protocol';

import { showMexMarket, smartContracts } from '@/config/envVars';
import blockchainService from '@/services/blockchain';
import { AccountMarketData } from '@/services/blockchain/lens/types';
import {
  ResponseProtocol,
  ResponseProtocolAccountMarket,
  ResponseProtocolUpdated,
} from '@/services/blockchain/protocol/types';
import { getTaoApr } from '@/services/tao-stats';
import logger from '@/utils/logger';
import { calcMarketAPY, calcTaoApy } from '@/utils/math/apy';
import {
  calcLiquidStakingAPY,
  INITIAL_TAO_EXCHANGE_RATE,
} from '@/utils/math/liquid-staking';
import { MAX_CACHE_TIME, MIN_CACHE_TIME, queryClient } from '@/utils/query';

const getMarketsV2 = async ({
  tokensMap,
}: {
  tokensMap: Record<string, Token>;
}): Promise<ResponseProtocol['markets']> => {
  const responseMoneyMarketData = await blockchainService.lens.getMarketsData([]);

  const moneyMarketData = showMexMarket
    ? responseMoneyMarketData
    : responseMoneyMarketData.filter(
        ({ underlyingId }) => !underlyingId.includes('MEX-'),
      );

  const markets = moneyMarketData.map((item) => {
    const underlyingSymbol = item.underlyingId.split('-')[0];
    const hTokenSymbol = item.tokenId.split('-')[0];

    const underlying = {
      id: item.underlyingId,
      name: tokensMap?.[underlyingSymbol]?.name || 'MultiversX',
      decimals: tokensMap?.[underlyingSymbol]?.decimals || 18,
      symbol: underlyingSymbol,
    };

    const hToken = {
      id: item.tokenId,
      name: tokensMap?.[hTokenSymbol]?.name || '',
      decimals: tokensMap?.[hTokenSymbol]?.decimals || 18,
      symbol: hTokenSymbol,
    };

    const borrowCap = item.borrowCap;
    const liquidityCap = item.liquidityCap;

    return {
      mintStatus: item.isMintAllowed ? 'Active' : 'Paused',
      borrowStatus: item.isBorrowAllowed ? 'Active' : 'Paused',
      underlying,
      hToken,
      supported: true,
      ...item,
      borrowCap: borrowCap === '0' ? null : borrowCap,
      liquidityCap: liquidityCap === '0' ? null : liquidityCap,
    };
  });

  const marketsV2 = markets.reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.symbol]: current,
    }),
    {} as ResponseProtocol['markets'],
  );

  logger.info({
    tokensMap,
    moneyMarketData,
    marketsV2,
  });

  return marketsV2;
};

export const getProtocol = async ({
  tokensMap,
}: {
  tokensMap: Record<string, Token>;
}): Promise<ResponseProtocol> => {
  const queryKey = [
    'blockchain:protocol:getProtocol',
    smartContracts.lensAddress,
  ];

  const queryFn = async () => {
    const [
      lendegateAddress,
      liquidStakingData,
      delegationContracts,
      boosterData,
      controllerData,
      taoData,
      egldIsolatedLendingProtocolData,
      wtaoIsolatedLendingProtocolData,
      discountRateModelData,
      stakingModuleData,
      boosterAddressV2,
      accountManagerDeployerAddress,
      ushMinterData,
      markets,
      lsTaoApr,
    ] = await Promise.all([
      blockchainService.lens.getLendegate(),
      blockchainService.lens.getLiquidStakingData(),
      blockchainService.lens.getDelegationContractsData(),
      blockchainService.lens.getBoosterV1Data(),
      blockchainService.lens.getControllerData(),
      blockchainService.lens.getTaoData(),
      blockchainService.lens.getEgldIsolatedLendingProtocolData(),
      blockchainService.lens.getWtaoIsolatedLendingProtocolData(),
      blockchainService.lens.getDiscountRateModelData(),
      blockchainService.lens.getUSHStakingData(),
      blockchainService.lens.getBoosterV2(),
      blockchainService.lens.getAccountManagerDeployer(),
      blockchainService.lens.getUSHMinterData(),
      getMarketsV2({ tokensMap }),
      getTaoApr(),
    ]);

    const filteredDelegationContracts = delegationContracts
      .map((delegationContractItem) => ({
        ...delegationContractItem,
        serviceFee: new DefiUtils(delegationContractItem.serviceFee)
          .dividedBy(100)
          .toString(),
        apr: new DefiUtils(delegationContractItem.apr)
          .dividedBy(100)
          .toString(),
      }))
      .filter(
        (delegationContractItem) =>
          !HIDDEN_DELEGATION_CONTRACTS.includes(
            delegationContractItem.contract,
          ),
      );

    const _lsTotalFee = new DefiUtils(liquidStakingData.totalFee)
      .dividedBy(100)
      .toString();

    const lsAPY =
      filteredDelegationContracts.length === 0
        ? '6'
        : calcLiquidStakingAPY(_lsTotalFee, filteredDelegationContracts);

    const lsTotalStaked = filteredDelegationContracts
      .reduce(
        (prev, { totalDelegated, pendingToDelegate }) =>
          prev.plus(
            new DefiUtils(totalDelegated).plus(pendingToDelegate).toString(),
          ),
        new DefiUtils('0'),
      )
      .toString();

    const discountRateModels = discountRateModelData
      .map(({ discount, money_market }) => {
        const market = Object.values(markets).find(
          (marketItem) => marketItem.address === money_market,
        );

        if (!market) {
          return;
        }

        const ushBorrowAPY = calcMarketAPY(
          markets[MARKET_KEY.USH]?.borrowRatePerSecond || '0',
        );

        const s_discount = new DefiUtils(discount).dividedBy(10_000).toString();

        return {
          moneyMarket: market.underlying.symbol,
          discount,
          s_discount,
          borrowAPY: new DefiUtils(ushBorrowAPY)
            .multipliedBy(new DefiUtils(1).minus(s_discount))
            .toString(),
        };
      })
      .filter((item) => typeof item !== 'undefined');

    return {
      discountRateModels,
      ushMinter: ushMinterData,
      markets,
      boosterV2: {
        address: boosterAddressV2,
      },
      isolatedLendingProtocols: {
        ['EGLD']: {
          address: egldIsolatedLendingProtocolData.address,
          collateralTokenId: egldIsolatedLendingProtocolData.collateralTokenId,
          totalBorrows: egldIsolatedLendingProtocolData.totalBorrows,
          totalCollateral: egldIsolatedLendingProtocolData.totalCollateral,
        },
        ['WTAO']: {
          address: wtaoIsolatedLendingProtocolData.address,
          collateralTokenId: wtaoIsolatedLendingProtocolData.collateralTokenId,
          totalBorrows: wtaoIsolatedLendingProtocolData.totalBorrows,
          totalCollateral: wtaoIsolatedLendingProtocolData.totalCollateral,
        },
      } as IsolatedLendingProtocols,
      booster: {
        address: boosterData.address,
        cooldownPeriod: boosterData.cooldownPeriod,
        stakingRatioThreshold: boosterData.stakingRatioThreshold,
        totalStaked: boosterData.totalStake,
      },
      liquidStaking: {
        address: liquidStakingData.address,
        undelegateTokenId: liquidStakingData.undelegateTokenId,
        lsTokenId: liquidStakingData.lsTokenId,
        zapAddress: lendegateAddress,
        totalFee: _lsTotalFee,
        totalStaked: lsTotalStaked,
        apy: lsAPY,
        unbondPeriod: liquidStakingData.unbondPeriod,
        minEgldToDelegate: '1',
        exchangeRate: liquidStakingData.exchangeRate,
      },
      liquidStakingTao: {
        address: taoData.taoLiquidStakingData.address || '',
        lsTokenId: taoData.taoLiquidStakingData.lsTokenId || '',
        totalFee: TAO_SERVICE_FEE,
        totalStaked: taoData.taoLiquidStakingData.cash || '0',
        exchangeRate:
          taoData.taoLiquidStakingData.currentExchangeRate ||
          INITIAL_TAO_EXCHANGE_RATE,
        apy: calcTaoApy(lsTaoApr),
        zapAddress: taoData.taoLendegate || '',
        tokenSupply: taoData.wrappedTaoData.tokenSupply || '0',
      },
      controller: {
        address: controllerData.address,
        maxMarketsPerAccount: controllerData.maxMarketsPerAccount,
      },
      governance: {
        address: '',
        votingDelayInBlocks: '0',
        quorum: '0',
        votingPeriodInBlocks: '0',
        voteNftId: '',
      },
      stakingModule: {
        address: stakingModuleData.address,
      },
      accountManagerDeployer: {
        address: accountManagerDeployerAddress,
      },
    };
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  });
};

export const getProtocolUpdated = async ({
  tokensMap,
}: {
  tokensMap: Record<string, Token>;
}): Promise<ResponseProtocolUpdated> => {
  const queryKey = [
    'blockchain:protocol:getProtocolUpdated',
    smartContracts.lensAddress,
  ];

  const queryFn = async () => {
    const [
      markets,
      liquidStakingData,
      boosterData,
      taoData,
      ushMinterData,
      lsTaoApr,
    ] = await Promise.all([
      getMarketsV2({ tokensMap }),
      blockchainService.lens.getLiquidStakingData(),
      blockchainService.lens.getBoosterV1Data(),
      blockchainService.lens.getTaoData(),
      blockchainService.lens.getUSHMinterData(),
      getTaoApr(),
    ]);

    return {
      markets: markets as any,
      ushMinter: ushMinterData,
      booster: {
        address: boosterData.address,
        cooldownPeriod: boosterData.cooldownPeriod,
        stakingRatioThreshold: boosterData.stakingRatioThreshold,
        totalStaked: boosterData.totalStake,
      },
      liquidStakingTao: {
        totalFee: TAO_SERVICE_FEE,
        totalStaked: taoData.taoLiquidStakingData.cash || '0',
        exchangeRate:
          taoData.taoLiquidStakingData.currentExchangeRate ||
          INITIAL_TAO_EXCHANGE_RATE,
        apy: calcTaoApy(lsTaoApr),
        tokenSupply: taoData.wrappedTaoData.tokenSupply || '0',
      },
      liquidStaking: {
        totalStaked: liquidStakingData.cashReserve,
        exchangeRate: liquidStakingData.exchangeRate,
      },
    };
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MIN_CACHE_TIME,
    staleTime: MIN_CACHE_TIME,
  }) as Promise<ResponseProtocolUpdated>;
};

export const getProtocolAccountMarkets = async (
  accountAddress: string,
  markets: { address: string; symbol: string }[],
): Promise<ResponseProtocolAccountMarket[]> => {
  if (!accountAddress) {
    return [];
  }

  const queryKey = [
    'blockchain:protocol:getProtocolAccountMarkets',
    smartContracts.lensAddress,
    accountAddress,
  ];

  const queryFn = async () => {
    const marketAddresses = markets.map(({ address }) => address);

    const [accountMarketsData] = await Promise.all([
      blockchainService.lens.getAccountMarketsData(
        accountAddress,
        marketAddresses,
      ),
    ]);

    const accountMarketsDataMap = accountMarketsData.reduce(
      (prev, current) => ({
        ...prev,
        [current.marketData.address]: current,
      }),
      {} as Record<string, AccountMarketData>,
    );

    return markets.map(({ symbol, address }) => {
      const accountMarketItem = accountMarketsDataMap[address];

      return {
        collateralTokens: accountMarketItem?.collateral || '0',
        storedBorrowBalance: accountMarketItem?.borrowAmount || '0',
        accountBorrowIndex: accountMarketItem?.borrowIndex || '0',
        currentBorrowAmount: accountMarketItem?.currentBorrowAmount || '0',
        market: {
          address,
          underlying: {
            symbol,
          },
          lastState: {
            borrowIndex: accountMarketItem?.marketData?.borrowIndex || '0',
            timestamp: new Date(
              new DefiUtils(
                accountMarketItem?.marketData?.accrualTimestamp || 0,
              )
                .multipliedBy(1000)
                .toNumber(),
            ).toISOString(),
            borrowRatePerSecond:
              accountMarketItem?.marketData?.borrowRatePerSecond || '0',
          },
        },
      };
    });
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MIN_CACHE_TIME,
    staleTime: MIN_CACHE_TIME,
  });
};
