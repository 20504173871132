const accountManagerTemplateABI = {
  buildInfo: {
    rustc: {
      version: '1.81.0',
      commitHash: 'eeb90cda1969383f56a2637cbd3037bdf598841c',
      commitDate: '2024-09-04',
      channel: 'Stable',
      short: 'rustc 1.81.0 (eeb90cda1 2024-09-04)',
    },
    contractCrate: {
      name: 'account-manager-template',
      version: '0.0.0',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.53.2',
    },
  },
  name: 'AccountManagerTemplate',
  constructor: {
    docs: [
      'Initializes the contract with essential parameters.',
      '',
      '# Arguments',
      '',
      '- `managed_account` - the managed account address',
      '- `controller` - the controller smart contract address',
      '',
      '# Notes',
      '',
      '- The managed account is the account that will be able to perform actions on the contract.',
      '- The booster is set to the current observer set at the Controller level, and it cannot be updated.',
      '',
    ],
    inputs: [
      {
        name: 'managed_account',
        type: 'Address',
      },
      {
        name: 'controller',
        type: 'Address',
      },
    ],
    outputs: [],
  },
  upgradeConstructor: {
    inputs: [],
    outputs: [],
  },
  endpoints: [
    {
      docs: [
        'Allows the managed account to withdraw funds from the contract.',
        '',
        '# Arguments',
        '',
        '- `token_id` - The token identifier.',
        '- `opt_amount` - The amount to withdraw. If not provided, the entire balance will be withdrawn.',
        '',
        '# Notes',
        '',
        '- The managed account can use this method to withdraw rewards if the admin has claimed rewards in his name at',
        '  Controller or Booster level.',
        '',
      ],
      name: 'withdraw',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'opt_amount',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'EgldOrEsdtTokenPayment',
        },
      ],
    },
    {
      name: 'isAccountManager',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      name: 'enterMarkets',
      mutability: 'mutable',
      inputs: [
        {
          name: 'payments',
          type: 'List<EsdtTokenPayment>',
        },
      ],
      outputs: [],
    },
    {
      name: 'exitMarket',
      mutability: 'mutable',
      inputs: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'opt_tokens',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'exitMarketAndRedeem',
      mutability: 'mutable',
      inputs: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'opt_tokens',
          type: 'Option<BigUint>',
        },
        {
          name: 'opt_underlying_amount',
          type: 'Option<BigUint>',
        },
      ],
      outputs: [
        {
          type: 'EgldOrEsdtTokenPayment',
        },
        {
          type: 'EsdtTokenPayment',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'claimRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'boost',
          type: 'bool',
        },
        {
          name: 'supply',
          type: 'bool',
        },
        {
          name: 'borrow',
          type: 'bool',
        },
        {
          name: 'money_markets',
          type: 'List<Address>',
        },
        {
          name: 'accounts',
          type: 'List<Address>',
        },
        {
          name: 'opt_min_boosted_rewards_out',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'variadic<multi<Address,EgldOrEsdtTokenPayment>>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'claimRewardsTokens',
      mutability: 'mutable',
      inputs: [
        {
          name: 'boost',
          type: 'bool',
        },
        {
          name: 'supply',
          type: 'bool',
        },
        {
          name: 'borrow',
          type: 'bool',
        },
        {
          name: 'tokens',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
        {
          name: 'money_markets',
          type: 'List<Address>',
        },
        {
          name: 'accounts',
          type: 'List<Address>',
        },
        {
          name: 'opt_min_boosted_rewards_out',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'variadic<multi<Address,EgldOrEsdtTokenPayment>>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'mint',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'opt_money_market',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'mintAndEnterMarket',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'opt_money_market',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'redeem',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token_payment_in',
          type: 'EsdtTokenPayment',
        },
        {
          name: 'opt_underlying_amount',
          type: 'Option<BigUint>',
        },
        {
          name: 'opt_money_market',
          type: 'Option<Address>',
        },
      ],
      outputs: [
        {
          type: 'EgldOrEsdtTokenPayment',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'borrow',
      mutability: 'mutable',
      inputs: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'underlying_amount',
          type: 'BigUint',
        },
      ],
      outputs: [
        {
          type: 'EgldOrEsdtTokenPayment',
        },
      ],
    },
    {
      name: 'repayBorrow',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'opt_money_market',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'stake',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'opt_account',
          type: 'optional<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'unstake',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
        {
          name: 'pricing_method',
          type: 'PricingMethod',
        },
        {
          name: 'opt_max_cooldown_period',
          type: 'optional<u64>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
      ],
    },
    {
      name: 'claim',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'claim_id',
          type: 'u32',
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'stakeClaim',
      mutability: 'mutable',
      inputs: [
        {
          name: 'from',
          type: 'u32',
        },
        {
          name: 'claim_id',
          type: 'u32',
        },
        {
          name: 'to',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      name: 'claimRewardsBoosterV2',
      mutability: 'mutable',
      inputs: [
        {
          name: 'program',
          type: 'u32',
        },
        {
          name: 'boost',
          type: 'bool',
        },
        {
          name: 'accounts',
          type: 'List<Address>',
        },
        {
          name: 'pools',
          type: 'List<TokenIdentifier>',
        },
        {
          name: 'opt_min_boosted_rewards_out',
          type: 'optional<BigUint>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'variadic<multi<Address,EgldOrEsdtTokenPayment>>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'reallocate',
      mutability: 'mutable',
      inputs: [
        {
          name: 'from',
          type: 'u32',
        },
        {
          name: 'to',
          type: 'u32',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
      ],
      outputs: [],
    },
    {
      name: 'getManagedAccount',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getController',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getBooster',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
  ],
  events: [
    {
      identifier: 'set_controller_event',
      inputs: [
        {
          name: 'controller',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'set_booster_event',
      inputs: [
        {
          name: 'booster',
          type: 'Address',
          indexed: true,
        },
      ],
    },
  ],
  esdtAttributes: [],
  hasCallback: false,
  types: {
    EgldOrEsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    EsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    PricingMethod: {
      type: 'enum',
      variants: [
        {
          name: 'ReliablePrice',
          discriminant: 0,
        },
        {
          name: 'LastPrice',
          discriminant: 1,
        },
      ],
    },
  },
};

export default accountManagerTemplateABI;
