import CheckmarkIcon from '@/components/Icons/CheckmarkIcon';
import CrossIcon from '@/components/Icons/CrossIcon';
import DeleteIcon from '@/components/Icons/DeleteIcon';
import SearchIcon from '@/components/Icons/SearchIcon';
import { Spinner } from '@/components/Spinner';

import { useAppSelector } from '@/store/index';
import { transactionSelector } from '@/store/transaction';

import { chainType, networkConfig } from '@/config/network';

const FloatTransactions = () => {
  const { currentTransactions } = useAppSelector(transactionSelector);

  return (
    <>
      {currentTransactions.length > 0 && (
        <div className='fixed bottom-4 right-4 z-10 rounded-md border-2 bg-white px-5 py-3 text-indigo-900 dark:bg-card-bg dark:text-white'>
          <div className='mb-1 font-bold'>
            {currentTransactions.filter(({ status }) => status !== 'pending')
              .length === currentTransactions.length
              ? 'Transactions completed'
              : 'Processing transaction'}
          </div>

          <div className='mb-4 text-xs'>
            {
              currentTransactions.filter(({ status }) => status !== 'pending')
                .length
            }
            /{currentTransactions.length} transactions proceseed
          </div>

          <div className='flex flex-col gap-2'>
            {currentTransactions.map((transactionItem) => (
              <div
                key={transactionItem.hash}
                className='flex items-center text-base'
              >
                {transactionItem.status === 'pending' && <Spinner />}
                {transactionItem.status === 'success' && (
                  <CheckmarkIcon width={16} height={16} />
                )}
                {transactionItem.status === 'fail' && (
                  <CrossIcon strokeWidth={1.2} width={16} height={16} />
                )}

                <div className='mx-2 text-sm'>{`${transactionItem.hash.slice(
                  0,
                  11,
                )} ... ${transactionItem.hash.slice(-11)}`}</div>

                <div className='grow'></div>

                <a
                  href={`${networkConfig[chainType].explorerAddress}/transactions/${transactionItem.hash}`}
                  target='_blank'
                  rel='noreferrer'
                  className='pointer'
                >
                  <SearchIcon width={16} height={16} />
                </a>

                <div className='ml-2 flex h-4 w-4 items-center'>
                  <DeleteIcon
                    width={16}
                    height={16}
                    cursor='pointer'
                    onClick={() => {}}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FloatTransactions;
