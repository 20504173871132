import { useCallback, useEffect } from 'react';

import { accountSelector, AccountToken } from '@/store/auth';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  nativeMarketSelector,
  protocolSelector,
  setUserBalances,
} from '@/store/protocol';

import { ESDTTokenBalance } from '@/types/account';

const getUnderlyingBalance = (
  moneyMarketsIds: {
    underlying: {
      symbol: string;
      id: string;
    };
  }[],
  tokens: AccountToken[],
): Record<string, string> => {
  const accountBalances = tokens.reduce((acc, token) => {
    const index = moneyMarketsIds.findIndex(
      ({ underlying }) => underlying.id === token.tokenIdentifier,
    );

    if (index === -1) return acc;

    const { underlying } = moneyMarketsIds[index] || {};

    return {
      ...acc,
      [underlying.symbol]: token.balance,
    };
  }, {});

  return accountBalances;
};

const getHTokenBalance = (
  moneyMarketsIds: {
    hToken: { id: string };
    underlying: { symbol: string };
  }[],
  tokenBalance: ESDTTokenBalance[],
): Record<string, string> => {
  const hTokenAccountBalances = tokenBalance.reduce((acc, token) => {
    const index = moneyMarketsIds.findIndex(
      ({ hToken }) => hToken.id === token.tokenIdentifier,
    );

    if (index === -1) return acc;

    const assetKey = moneyMarketsIds[index].underlying.symbol;

    return {
      ...acc,
      [assetKey]: token.balance,
    };
  }, {});

  return hTokenAccountBalances;
};

const useStoreUserBalances = () => {
  const dispatch = useAppDispatch();
  const { markets } = useAppSelector(protocolSelector);
  const {
    tokens,
    balance,
    address: accountAddress,
    selectedTypeAddress,
  } = useAppSelector(accountSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);

  const loadUnderlyingUserBalance = useCallback(() => {
    const underlyingBalanceMap = getUnderlyingBalance(
      Object.values(markets).map(({ underlying }) => ({
        underlying,
      })),
      tokens,
    );

    const parsedValues = Object.entries(markets).reduce(
      (prev, [key]) => ({
        ...prev,
        [key]: { underlyingBalance: underlyingBalanceMap[key] || '0' },
      }),
      {} as Record<string, { underlyingBalance: string }>,
    );

    dispatch(setUserBalances(parsedValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tokens,
    balance,
    markets,
    nativeMarket.underlying.id,
    nativeMarket.underlying.symbol,
  ]);

  const loadHTokenBalance = useCallback(() => {
    const hTokenBalanceMap = getHTokenBalance(
      Object.values(markets).map(({ underlying, hToken }) => ({
        hToken,
        underlying,
      })),
      tokens,
    );

    const parsedValues = Object.entries(markets).reduce(
      (prev, [key]) => ({
        ...prev,
        [key]: { hTokenBalance: hTokenBalanceMap[key] || '0' },
      }),
      {},
    );

    dispatch(setUserBalances(parsedValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, markets]);

  useEffect(() => {
    loadUnderlyingUserBalance();
    loadHTokenBalance();
  }, [
    accountAddress,
    selectedTypeAddress,
    loadHTokenBalance,
    loadUnderlyingUserBalance,
  ]);
};

export default useStoreUserBalances;
