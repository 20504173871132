import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { TX_STATUS } from '@/hooks/core/useSignMultipleTransactions';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import { CheckIcon } from '@/components/popups/LendingPendingTransactionPopup/components/Icons/CheckIcon';
import { CrossIcon } from '@/components/popups/LendingPendingTransactionPopup/components/Icons/CrossIcon';
import { ExclamationMarkIcon } from '@/components/popups/LendingPendingTransactionPopup/components/Icons/ExclamationMarkIcon';
import PendingLoader from '@/components/popups/LendingPendingTransactionPopup/components/PendingLoader';
import TxHashBox from '@/components/popups/LendingPendingTransactionPopup/components/TxHashBox';
import StatusAnimation from '@/components/popups/PendingTransactionPopup/components/StatusAnimation';
import TransactionButton from '@/components/TransactionButton';

import { useAppSelector } from '@/store';
import {
  MARKET_KEY,
  protocolSelector,
  TOKEN_LOGO_V2_MAP,
} from '@/store/protocol';
import {
  TRANSACTION_SUBGROUP_TYPE,
  transactionSelector,
} from '@/store/transaction';

import { chainType, networkConfig } from '@/config/network';
import { nFormatter } from '@/utils/helpers';

const TxPhase: React.FC<any> = ({ txStatus, statusData }) => {
  const { t } = useTranslation();
  const { currentTransactions, transactionAmount, transactionConfig } =
    useAppSelector(transactionSelector);
  const { booster } = useAppSelector(protocolSelector);

  const transactionsSent = useMemo(() => {
    return currentTransactions.filter(({ status }) => status !== 'signed');
  }, [currentTransactions]);

  const operationType = useMemo(() => {
    return transactionConfig?.subgroup;
  }, [transactionConfig?.subgroup]);

  const assetKey = useMemo(() => {
    return transactionConfig?.token;
  }, [transactionConfig?.token]);

  const transactionValue = useMemo(() => {
    if (
      transactionConfig.subgroup === TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS &&
      transactionConfig.token
    ) {
      const transferTokens = currentTransactions
        .map(({ txData }) => txData?.operations || [])
        .flat()
        .filter(
          (operationItem) =>
            operationItem?.action === 'transfer' &&
            (operationItem?.identifier || '').includes(
              transactionConfig.token,
            ) &&
            operationItem?.receiver === transactionConfig?.accountAddress,
        );

      const amount = transferTokens
        .reduce(
          (prev, current) =>
            prev.plus(
              new DefiUtils(current.value).toFullDecimals(current.decimals),
            ),
          new DefiUtils(0),
        )
        .toString();

      return amount;
    }

    return transactionConfig?.result || '0';
  }, [
    currentTransactions,
    transactionConfig?.accountAddress,
    transactionConfig?.result,
    transactionConfig.subgroup,
    transactionConfig.token,
  ]);

  const successMsg = useMemo(() => {
    switch (operationType) {
      case TRANSACTION_SUBGROUP_TYPE.SUPPLY: {
        return t(
          'successMsg.S9s2z',
          <>
            Congratulations! You just supplied the <br /> full amount.
          </>,
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM: {
        return t(
          'lending:successMsg.unstake-htm',
          "It's Unstaked! You can claim the full amount in ~{{hours}} hour.",
          {
            hours: new DefiUtils(booster.cooldownPeriod)
              .dividedBy(3600)
              .toString(),
          },
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.REALLOCATE_HTM: {
        return t(
          'successMsg.S9s2z',
          <>
            Congratulations! You just migrating the <br /> full amount.
          </>,
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.STAKE_HTM: {
        return 'Congratulations! You just staked the <br> full amount.';
      }
      case TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS: {
        return 'Congratulations! You just received the <br> rewards in your wallet.';
      }
      case TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM: {
        return t(
          'lending-app:successMsg.claim-htm',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.STAKE_CLAIM_HTM: {
        return t(
          'lending-app:successMsg.claim-htm',
          'Congratulations! You just restaking the full amount.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.WITHDRAW: {
        return t(
          'successMsg.QCwy8',
          <>
            Congratulations! You just received the full amount
            <br /> in your wallet.
          </>,
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.SUPPLY_AND_ADD_COLLATERAL: 
      case TRANSACTION_SUBGROUP_TYPE.ADD_COLLATERAL: {
        return t(
          'successMsg.D6Mhc',
          <>
            Congratulations! You just added the full amount <br /> as
            collateral.
          </>,
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.REMOVE_COLLATERAL: {
        return t(
          'successMsg.4edjU',
          <>
            Congratulations! You just removed the full amount <br /> from your
            collateral.
          </>,
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.BORROW: {
        return t(
          'successMsg.F8Nox',
          <>
            Congratulations! You just removed the full amount <br /> from your
            collateral.
          </>,
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.REPAY_BORROW: {
        return t(
          'successMsg.y2H9m',
          <>
            Congratulations! You just repaid the <br /> full amount.
          </>,
        );
      }
      default: {
        return '';
      }
    }
  }, [operationType]);

  const getResultLabel = (status: string) => {
    switch (status.toLowerCase()) {
      case 'success':
        return {
          label: t('success'),
          icon: <CheckIcon />,
        };
      case 'fail':
        return {
          label: t('failed'),
          icon: <CrossIcon />,
        };
      default:
        return {
          label: t('unknown'),
          icon: <ExclamationMarkIcon />,
        };
    }
  };

  const getStatusLabel = () => {
    switch (txStatus) {
      case TX_STATUS.AWAITING_CONFIRMATION:
        return {
          title: t('awaiting-confirmation'),
          message: t('confirm-transaction'),
        };
      case TX_STATUS.SENT:
        return {
          title: t('transaction-sent'),
          message: (
            <>
              {t('awaiting-network-response')}{' '}
              {transactionAmount >= 2 &&
                `(${transactionsSent.length}/${transactionAmount})`}
            </>
          ),
        };
      case TX_STATUS.COMPLETED:
        const ok = statusData == 'success';
        return {
          title: ok ? t('transaction-completed') : 'Transaction failed',
          icon: ok ? <></> : <StatusAnimation status={statusData} />,
          message: (
            <span
              className='text-[14px] font-normal text-[#7575A7]'
              dangerouslySetInnerHTML={{
                __html: ok ? successMsg : 'Your transaction has failed.',
              }}
            ></span>
          ),
        };
      case TX_STATUS.FAILED:
        return {
          title: 'Transaction Canceled',
          message: (
            <span className='mt-4 inline-block text-[14px] font-normal text-[#7575A7]'>
              Your transaction has been canceled.
            </span>
          ),
          icon: <StatusAnimation status={undefined} />,
        };
      default:
        return { title: 'Awaiting user confirmation' };
    }
  };

  const resultLabel = statusData && getResultLabel(statusData);

  const statusTitle = getStatusLabel().title;
  const statusMessage = getStatusLabel().message || null;
  const statusIcon = getStatusLabel().icon || <PendingLoader />;

  const completed = ![TX_STATUS.AWAITING_CONFIRMATION, TX_STATUS.SENT].includes(
    txStatus,
  );

  const success = txStatus == TX_STATUS.COMPLETED && statusData === 'success';
  const badStatus =
    txStatus == TX_STATUS.FAILED ||
    (txStatus == TX_STATUS.COMPLETED && statusData === 'fail');

  const assetImgPath = useMemo(() => {
    return TOKEN_LOGO_V2_MAP?.[assetKey] || '';
  }, [assetKey]);

  const featureGradient = useMemo(() => {
    switch (assetKey) {
      case MARKET_KEY.EGLD: {
        return 'linear-gradient(271.36deg, #1ED7C1 32.94%, #1ED7C1 90.76%)';
      }
      case MARKET_KEY.MEX: {
        return 'linear-gradient(245.33deg, #3555F7 34.26%, #3555F7 89.62%)';
      }
      case MARKET_KEY.HTM: {
        return 'linear-gradient(245.33deg, #5AF1B0 34.26%, #5AF1B0 89.62%)';
      }
      case MARKET_KEY.RIDE: {
        return 'linear-gradient(245.33deg, #EB11A4, #26C0F6)';
      }
      case MARKET_KEY.USDC: {
        return 'linear-gradient(245.33deg, #2775CA 34.26%, #2775CA 89.62%)';
      }
      case MARKET_KEY.USDT: {
        return 'linear-gradient(245.33deg, #53AE94 34.26%, #53AE94 89.62%)';
      }
      case MARKET_KEY.BUSD: {
        return 'linear-gradient(245.33deg, #F0B90B 34.26%, #F0B90B 89.62%)';
      }
      case MARKET_KEY.UTK: {
        return 'linear-gradient(245.33deg, #6932D4 34.26%, #6932D4 89.62%)';
      }
      case MARKET_KEY.sEGLD: {
        return 'linear-gradient(245.33deg, #006FFF 34.26%, #006FFF 89.62%)';
      }
      case MARKET_KEY.WBTC: {
        return 'linear-gradient(245.33deg, #F09242 34.26%, #F09242 89.62%)';
      }
      case MARKET_KEY.WETH: {
        return 'linear-gradient(245.33deg, #627EEA 34.26%, #627EEA 89.62%)';
      }
      case MARKET_KEY.USH: {
        return 'linear-gradient(245.33deg, #6DD1FF 34.26%, #6DD1FF 89.62%)';
      }
      default: {
        return '';
      }
    }
  }, [assetKey]);

  return (
    <div
      className='text-[#3C3A40] dark:text-[#7575A7]'
      style={{ minHeight: txStatus == TX_STATUS.FAILED ? 'auto' : 348 }}
    >
      <div
        className={classNames(
          'relative h-[56px]',
          'flex items-center justify-center',
          'text-lg font-semibold leading-tight',
        )}
      >
        {completed ? (
          <div className='flex items-center gap-1'>
            <img style={{ width: 22 }} src={assetImgPath} alt='' />
            <div className='text-[14px] text-[#7575A7] dark:text-white'>
              {assetKey}
            </div>
          </div>
        ) : (
          t('transaction-details')
        )}
        <div className='absolute right-5 top-1/2 z-10 -translate-y-1/2'>
          <ClosePopupBtn
            theme={{ light: '#1E1E1E', dark: 'white' }}
            iconClassName='w-[11px]'
          />
        </div>
      </div>

      <div
        className={classNames(
          'text-superxs font-semibold leading-tight tracking-[0.02em]',
          'relative p-5 pr-0',
          'flex flex-col items-center',
        )}
      >
        {/* Pending animation */}
        {!completed && (
          <div className='flex items-start justify-center  pr-5'>
            {statusIcon}
          </div>
        )}

        <div
          className={classNames('text-center pr-5', {
            'mt-5': !completed,
          })}
        >
          <div
            className={classNames('flex items-center justify-center gap-1.5', {
              'text-[12px] text-black dark:text-white': resultLabel,
            })}
            data-testid='txStatus'
          >
            {resultLabel && resultLabel.icon}
            {statusTitle}
          </div>

          {success && (
            <div
              style={
                {
                  [`--gradient`]: featureGradient,
                } as any
              }
              className='gradient-text mt-4 text-[74px] font-normal'
            >
              {new DefiUtils(transactionValue).isLessThanOrEqualTo('0.01')
                ? '< 0.01'
                : nFormatter(transactionValue, 2, DefiUtils.ROUND_FLOOR)}
            </div>
          )}

          {badStatus && (
            <div className='mt-[30px] flex h-12 items-start justify-center'>
              {statusIcon}
            </div>
          )}

          {statusMessage && (
            <div
              className={classNames(
                'mt-3',
                completed ? 'w-[390px]' : 'w-[220px]',
              )}
            >
              {statusMessage}
            </div>
          )}
        </div>

        {!!currentTransactions.length && (
          <div className='txHashes scroll-container2 overflow-scroll-x mt-[34px] max-h-[103px] w-[97%] transition-all'>
            <div className='flex flex-col items-center gap-2 pr-5'>
              {currentTransactions.map(({ hash }, i) => (
                <LazyMotion key={i} features={domAnimation}>
                  <m.div
                    initial={i !== 0 && { height: 0, overflow: 'hidden' }}
                    animate={i !== 0 && { height: 29 }}
                    transition={{ duration: 0.2 }}
                    key={i}
                  >
                    <TxHashBox hash={hash} />
                  </m.div>
                </LazyMotion>
              ))}
            </div>
          </div>
        )}

        {/* Explore  */}
        {currentTransactions?.[currentTransactions.length - 1]?.hash && (
          <a
            href={`${
              networkConfig[chainType].explorerAddress
            }/transactions/${currentTransactions?.[
              currentTransactions.length - 1
            ]?.hash}`}
            target='_blank'
            rel='noreferrer'
            className='mt-5 w-full  pr-5'
          >
            <TransactionButton
              customTheme={(disabled: boolean) => {
                if (disabled)
                  return 'text-[#6C7DA6] bg-[#D3DBEE] dark:bg-[#2A3E6C] cursor-not-allowed';
                return [
                  'bg-gradient-to-r from-[#8474E3] to-[#49D2AE] text-white',
                ];
              }}
              text={t('view-on-explorer')}
            ></TransactionButton>
          </a>
        )}
      </div>
    </div>
  );
};

export default TxPhase;
