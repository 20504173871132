import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAppSelector } from '@/store/index';
import { protocolSelector } from '@/store/protocol';

const shimmerControllerInitialState = {
  enabled: false,
  // eslint-disable-next-line unused-imports/no-unused-vars
  toggle: (state: boolean) => {},
};

export const ShimmerControllerContext = createContext<{
  enabled: boolean;
  toggle: (state: boolean) => void;
}>(shimmerControllerInitialState);

export function useShimmerControllerContext() {
  const { enabled, toggle } = useContext(ShimmerControllerContext);
  return [enabled, toggle];
}

export const ShimmerControllerContextProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const [enabled, updateState] = useState(
    shimmerControllerInitialState.enabled
  );
  const { isLoadingFirstInfo } = useAppSelector(protocolSelector);

  const toggle = (state?: boolean) => {
    if (state === undefined) {
      updateState((_) => !_);
    } else updateState(state);
  };

  useEffect(() => {
    updateState(isLoadingFirstInfo);
  }, [isLoadingFirstInfo]);

  return (
    <ShimmerControllerContext.Provider
      value={{
        enabled,
        toggle,
      }}
    >
      {children}
    </ShimmerControllerContext.Provider>
  );
};
