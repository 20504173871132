import useMediaQuery from '@/hooks/useMediaQuery';

import { WalletTypes } from '@/components/popups/ConnectBridgeWalletPopup/components/types';

import clsxm from '@/services/clsxm';

const ConnectBridgeWalletStepLogo = ({
  type,
  className,
}: {
  type: WalletTypes;
  className: string;
}) => {
  const lg = useMediaQuery('(min-width: 1024px )');

  const isMulti = type === 'MULTIVERSE_X';
  const src = isMulti ? 'multiverseXNoBg.svg' : 'bittensorNoBg.svg';
  const width = isMulti ? (lg ? 144 : 102.2) : lg ? 102 : 72.9;

  return (
    <div
      className={clsxm(
        'h-[119.5px] lg:h-[168px] flex flex-col justify-between items-center',
        className,
      )}
    >
      <img
        src={'https://cdn.app.hatom.com/images/pages/bridge/' + src}
        alt='connect wallet logo'
        width={width}
        className={clsxm(
          isMulti &&
            'translate-x-[0.9px] translate-y-[0.4px] lg:translate-x-0 lg:translate-y-[-0.6px]',
          // type === "FINNEY" && "mb-[40px]"
        )}
        height='auto'
      />
      <p
        className={clsxm(
          'text-[12.5px] leading-[15.6px] lg:leading-[20px] lg:text-[16px] font-[600] translate-x-[0.3px]',
          isMulti ? 'text-black' : 'text-white translate-x-[-0.3px]',
        )}
      >
        {isMulti ? 'MultiversX' : 'Finney'}
      </p>
    </div>
  );
};

export default ConnectBridgeWalletStepLogo;
