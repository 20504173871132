import {
  AbiRegistry,
  Address,
  AddressType,
  AddressValue,
  BigUIntValue,
  List,
  ListType,
  TokenIdentifierType,
  TokenIdentifierValue,
} from '@multiversx/sdk-core';
import DefiUtils from 'defi-utils';

import { MARKET_KEY } from '@/store/protocol';

import lensABI from '@/abis/lens';
import { smartContracts } from '@/config/envVars';
import client from '@/services/blockchain/client';
import {
  AccountBoosterV1Rewards,
  AccountBoosterV2RewardsData,
  AccountControllerReward,
  AccountDiscountRateModelData,
  AccountExtraAmountData,
  AccountIsolatedLendingProtocolData,
  AccountMarketData,
  AccountStakeData,
  AccountUSHStakingData,
  BoosterV1AccountClaims,
  BoosterV1Accumulators,
  BoosterV1Data,
  BoosterV1RewardsBatches,
  BoosterV2AccountClaim,
  BoosterV2BatchData,
  BoosterV2RewardsAccruedPerMarket,
  BoosterV2TokenPriceUsdData,
  ControllerAccumulator,
  ControllerData,
  ControllerRewardsBatch,
  DelegationContractData,
  DiscountRateModelData,
  HatomData,
  InterestRateModelData,
  IsolatedLendingProtocolData,
  LiquidStakingData,
  MarketData,
  TaoData,
  TaoLiquidStakingData,
  TokenPriceQuote,
  USHMinterData,
  USHStakingData,
  USHStakingStakePools,
} from '@/services/blockchain/lens/types';
import logger from '@/utils/logger';
import { MID_CACHE_TIME, MIN_CACHE_TIME, SECOND_IN_MILISECONDS } from '@/utils/query';

export const getMarketsData = async (
  moneyMarketAddress: string[],
): Promise<MarketData[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getMarketsData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new List(
        new ListType(new AddressType()),
        moneyMarketAddress.map(
          (address) => new AddressValue(new Address(address)),
        ),
      ),
    ],
    cacheTime: SECOND_IN_MILISECONDS * 30,
  });

  return response || [];
};

export const getLiquidStakingData = async (): Promise<LiquidStakingData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getLiquidStakingData',
    abi: AbiRegistry.create(lensABI),
    cacheTime: MID_CACHE_TIME,
  });

  return response;
};

export const getDelegationContractsData = async (): Promise<
  DelegationContractData[]
> => {
  try {
    const response = await client({
      address: smartContracts.lensAddress,
      method: 'getDelegationContractsData',
      abi: AbiRegistry.create(lensABI),
      cacheTime: MID_CACHE_TIME
    });
    return response || [];
  } catch (error) {
    logger.error(error?.toString?.());
    return [];
  }
};

export const getBoosterV1Data = async (): Promise<BoosterV1Data> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV1Data',
    abi: AbiRegistry.create(lensABI),
    cacheTime: MID_CACHE_TIME,
  });

  return response;
};

export const getAccountMarketData = async (
  accountAddress: string,
  moneyMarketAddress: string,
): Promise<AccountMarketData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountMarketData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new AddressValue(new Address(moneyMarketAddress)),
    ],
  });

  return response;
};

export const getAccountMarketsData = async (
  accountAddress: string,
  moneyMarketsAddress: string[],
): Promise<AccountMarketData[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountMarketsData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(
        new ListType(new AddressType()),
        moneyMarketsAddress.map(
          (address) => new AddressValue(new Address(address)),
        ),
      ),
    ],
  });

  return response || [];
};

export const getInterestRateModelsData = async (): Promise<
  InterestRateModelData[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getInterestRateModelsData',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
  });

  return response || [];
};

export const getControllerRewardsBatches = async (): Promise<
  ControllerRewardsBatch[]
> => {
  const response: ControllerRewardsBatch[] = await client({
    address: smartContracts.lensAddress,
    method: 'getControllerRewardsBatches',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    cacheTime: MID_CACHE_TIME,
  });

  return (response || []).map((item) => {
    const lastTime = new Date(+item.lastTime * 1000).toISOString();
    const endTime = new Date(+item.endTime * 1000).toISOString();

    return {
      ...item,
      lastTime,
      endTime,
    };
  });
};

export const getAccountControllerRewards = async (
  accountAddress: string,
): Promise<AccountControllerReward[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountControllerRewards',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(new ListType(new AddressType()), []),
    ],
  });

  return response || [];
};

export const getAccountBoosterV1Rewards = async (
  accountAddress: string,
): Promise<AccountBoosterV1Rewards[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountBoosterV1Rewards',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(new ListType(new AddressType()), []),
    ],
  });

  return response || [];
};

export const getHatomData = async (): Promise<HatomData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getHatomData',
    abi: AbiRegistry.create(lensABI),
  });

  return response;
};

export const getBoosterV1RewardsBatches = async (): Promise<
  BoosterV1RewardsBatches[]
> => {
  const response: BoosterV1RewardsBatches[] = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV1RewardsBatches',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
  });

  return (response || []).map((item) => {
    return {
      ...item,
      lastTime: new Date(+item.lastTime * 1000).toISOString(),
      endTime: new Date(+item.endTime * 1000).toISOString(),
    };
  });
};

export const getBoosterV1AccountClaims = async (
  accountAddress: string,
): Promise<BoosterV1AccountClaims[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response: BoosterV1AccountClaims[] = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV1AccountClaims',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return (response || []).map((item) => {
    return {
      ...item,
      claimTimestamp: new Date(+item.claimTimestamp * 1000).toISOString(),
    };
  });
};

export const getAccountBoosterV1Markets = async (
  accountAddress: string,
): Promise<string[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountBoosterV1Markets',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response || [];
};

export const getBoosterV1Accumulators = async (): Promise<
  BoosterV1Accumulators[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV1Accumulators',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    cacheTime: MID_CACHE_TIME,
  });

  return response || [];
};

export const getControllerAccumulators = async (): Promise<
  ControllerAccumulator[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getControllerAccumulators',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    cacheTime: MID_CACHE_TIME,
  });

  return response || [];
};

export const getTaoData = async (): Promise<TaoData> => {
  const response: TaoData = await client({
    address: smartContracts.lensAddress,
    method: 'getTaoData',
    abi: AbiRegistry.create(lensABI),
    cacheTime: MID_CACHE_TIME,
  });

  return {
    ...(response || {}),
    wrappedTaoData: {
      ...(response?.wrappedTaoData || {}),
    },
    taoLiquidStakingData: {
      ...(response?.taoLiquidStakingData || {}),
      apr: new DefiUtils(response?.taoLiquidStakingData.apr)
        .dividedBy(100)
        .toString(),
    },
  };
};

export const getTaoLiquidStakingData =
  async (): Promise<TaoLiquidStakingData> => {
    const response: TaoLiquidStakingData = await client({
      address: smartContracts.lensAddress,
      method: 'getTaoLiquidStakingData',
      abi: AbiRegistry.create(lensABI),
      cacheTime: MID_CACHE_TIME,
    });

    return {
      ...response,
      apr: new DefiUtils(response.apr).dividedBy(100).toString(),
    };
  };

export const getControllerData = async (): Promise<ControllerData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getControllerData',
    abi: AbiRegistry.create(lensABI),
  });

  return response;
};

export const getLendegate = async (): Promise<string> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getLendegate',
    abi: AbiRegistry.create(lensABI),
  });

  return response;
};

export const getTokenPrices = async (): Promise<TokenPriceQuote[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getTokenPrices',
    abi: AbiRegistry.create(lensABI),
    cacheTime: MID_CACHE_TIME,
  });

  return response || [];
};

export const getTokenPricesV2 = async (): Promise<Record<string, string>> => {
  const [liquidStakingTaoData, liquidStakingData, tokenPrices] =
    await Promise.all([
      getTaoLiquidStakingData(),
      getLiquidStakingData(),
      getTokenPrices(),
    ]);

  const liquidStakingExchangeRate = liquidStakingData.exchangeRate;
  const liquidStakingTaoExchangeRate = liquidStakingTaoData.currentExchangeRate;

  const tokenPricesMap = tokenPrices.reduce(
    (prev, current) => ({
      ...prev,
      [current.ticker]: new DefiUtils(current.price)
        .toFullDecimals(18)
        .toString(),
    }),
    {} as Record<string, string>,
  );

  const priceSegldInUSD = new DefiUtils(1)
    .toUnderlying(liquidStakingExchangeRate)
    .toUSD(tokenPricesMap?.[MARKET_KEY.EGLD] || '0')
    .toString();

  const priceSwtaoInUSD = new DefiUtils(1)
    .toUnderlying(liquidStakingTaoExchangeRate)
    .toUSD(tokenPricesMap?.[MARKET_KEY.WTAO] || '0')
    .toString();

  return {
    ...tokenPricesMap,
    [MARKET_KEY.sEGLD]: priceSegldInUSD,
    [MARKET_KEY.sWTAO]: priceSwtaoInUSD,
    [MARKET_KEY.USH]: '1', // aca_aca:temporal
  } as Record<string, string>;
};

export const getEgldIsolatedLendingProtocolData =
  async (): Promise<IsolatedLendingProtocolData> => {
    const response = await client({
      address: smartContracts.lensAddress,
      method: 'getEgldIsolatedLendingProtocolData',
      abi: AbiRegistry.create(lensABI),
      cacheTime: MIN_CACHE_TIME
    });

    return response;
  };

export const getWtaoIsolatedLendingProtocolData =
  async (): Promise<IsolatedLendingProtocolData> => {
    const response = await client({
      address: smartContracts.lensAddress,
      method: 'getWtaoIsolatedLendingProtocolData',
      abi: AbiRegistry.create(lensABI),
      cacheTime: MIN_CACHE_TIME
    });

    return response;
  };

export const getAccountEgldIsolatedLendingProtocolData = async (
  accountAddress: string,
): Promise<AccountIsolatedLendingProtocolData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountEgldIsolatedLendingProtocolData',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response;
};

export const getAccountWtaoIsolatedLendingProtocolData = async (
  accountAddress: string,
): Promise<AccountIsolatedLendingProtocolData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountWtaoIsolatedLendingProtocolData',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response;
};

export const getDiscountRateModelData = async (): Promise<
  DiscountRateModelData[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getDiscountRateModelData',
    abi: AbiRegistry.create(lensABI),
    args: [],
  });

  return response;
};

export const getAccountDiscountRateModelData = async (
  accountAddress: string,
  actionType:
    | 'None'
    | 'Mint'
    | 'Borrow'
    | 'RepayBorrow'
    | 'EnterMarket'
    | 'ExitMarket' = 'None',
  deltaBorrow?: string,
): Promise<AccountDiscountRateModelData> => {
  const actionTypeMap = {
    None: 0,
    Mint: 1,
    Borrow: 2,
    RepayBorrow: 3,
    EnterMarket: 4,
    ExitMarket: 5,
  };

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountDiscountRateModelData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new BigUIntValue(actionTypeMap[actionType]),
      ...(deltaBorrow ? [new BigUIntValue(deltaBorrow)] : []),
    ],
  });

  return response;
};

export const getUSHStakingData = async (): Promise<USHStakingData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getUSHStakingData',
    abi: AbiRegistry.create(lensABI),
    args: [],
  });

  return response;
};

export const getBoosterV2 = async (): Promise<string> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2',
    abi: AbiRegistry.create(lensABI),
    args: [],
  });

  return response;
};

export const getAccountUSHStakingData = async (
  address: string,
): Promise<AccountUSHStakingData[]> => {
  if (!address) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountUSHStakingData',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(address))],
  });

  return response;
};

export const getBoosterV2BatchData = async ({
  programId,
}: {
  programId: number;
}): Promise<BoosterV2BatchData[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2BatchData',
    abi: AbiRegistry.create(lensABI),
    args: [new BigUIntValue(programId)],
    cacheTime: MID_CACHE_TIME,
  });

  return response;
};

export const getBoosterV2Stakes = async ({
  programId,
}: {
  programId: number;
}): Promise<string[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2Stakes',
    abi: AbiRegistry.create(lensABI),
    args: [new BigUIntValue(programId)],
    cacheTime: MID_CACHE_TIME,
  });

  return response;
};

export const getBoosterV2AccountStakes = async ({
  programId,
  accountAddress,
}: {
  programId: number;
  accountAddress: string;
}): Promise<AccountStakeData[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2AccountStakes',
    abi: AbiRegistry.create(lensABI),
    args: [
      new BigUIntValue(programId),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return response;
};

const actionMap = {
  None: 0,
  Mint: 1,
  Borrow: 2,
  RepayBorrow: 3,
  EnterMarket: 4,
  ExitMarket: 5,
  Stake: 6,
  Unstake: 7,
};

export const getBoosterV2AccountInstaCompliance = async ({
  programId,
  accountAddress,
  tokenIdentifier,
  action,
  deltaValue,
}: {
  programId: number;
  accountAddress: string;
  tokenIdentifier: string;
  action: keyof typeof actionMap;
  deltaValue?: string;
}): Promise<string> => {
  if (!accountAddress) {
    return '0';
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2AccountInstaCompliance',
    abi: AbiRegistry.create(lensABI),
    args: [
      new BigUIntValue(programId),
      new AddressValue(new Address(accountAddress)),
      new TokenIdentifierValue(tokenIdentifier),
      new BigUIntValue(actionMap[action]),
      ...(deltaValue ? [new BigUIntValue(deltaValue)] : []),
    ],
  });

  return response;
};

export const getBoosterV2AccountRewards = async ({
  programId,
  accountAddress,
}: {
  programId: number;
  accountAddress: string;
}): Promise<AccountBoosterV2RewardsData[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2AccountRewards',
    abi: AbiRegistry.create(lensABI),
    args: [
      new BigUIntValue(programId),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return response;
};

export const getBoosterV2AccountExtraAmount = async ({
  programId,
  accountAddress,
}: {
  programId: number;
  accountAddress: string;
}): Promise<AccountExtraAmountData[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2AccountExtraAmount',
    abi: AbiRegistry.create(lensABI),
    args: [
      new BigUIntValue(programId),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return response;
};

export const getBoosterV2AccountClaims = async ({
  programId,
  accountAddress,
}: {
  programId: number;
  accountAddress: string;
}): Promise<BoosterV2AccountClaim[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response: BoosterV2AccountClaim[] = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2AccountClaims',
    abi: AbiRegistry.create(lensABI),
    args: [
      new BigUIntValue(programId),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return (response || []).map((item) => {
    return {
      ...item,
      claimTimestamp: new Date(+item.claimTimestamp * 1000).toISOString(),
    };
  });
};

export const getBoosterV2TokenPriceUsd = async (
  tokensIds: string[],
): Promise<BoosterV2TokenPriceUsdData[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2TokenPriceUsd',
    abi: AbiRegistry.create(lensABI),
    args: [
      new List(
        new ListType(new TokenIdentifierType()),
        tokensIds.map((tokenId) => new TokenIdentifierValue(tokenId)),
      ),
    ],
    cacheTime: MID_CACHE_TIME,
  });

  return tokensIds.map((tokenId, tokenIndex) => ({
    tokenId,
    amount: (response?.[tokenIndex] || '0') as string,
  }));
};

export const getAccountManagerDeployer = async (): Promise<string> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountManagerDeployer',
    abi: AbiRegistry.create(lensABI),
    args: [],
  });

  return response;
};

export const getUSHStakingStakePools = async (): Promise<
  USHStakingStakePools[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getUSHStakingStakePools',
    abi: AbiRegistry.create(lensABI),
    args: [],
    cacheTime: MIN_CACHE_TIME
  });

  return response;
};

export const getBoosterV2AccountRewardsPerMarket = async ({
  programId,
  accountAddress,
}: {
  programId: number;
  accountAddress: string;
}): Promise<BoosterV2RewardsAccruedPerMarket[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response: BoosterV2RewardsAccruedPerMarket[] = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterV2AccountRewardsPerMarket',
    abi: AbiRegistry.create(lensABI),
    args: [
      new BigUIntValue(programId),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return response || [];
};

export const getAccountManager = async (
  accountAddress: string,
): Promise<string> => {
  if (!accountAddress) {
    return '';
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountManager',
    args: [new AddressValue(new Address(accountAddress))],
    abi: AbiRegistry.create(lensABI),
  });

  return response || '';
};

export const getUSHMinterData = async (): Promise<USHMinterData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getUSHMinterData',
    abi: AbiRegistry.create(lensABI),
    cacheTime: MID_CACHE_TIME,
  });

  return response;
};
