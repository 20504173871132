import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import useDebounce from '@/hooks/useDebounce';

import {
  accountSelector,
  getProxyAccount,
  updateAccountTxs,
  updateESDTTokenBalance,
} from '@/store/auth';
import { getBoosterData } from '@/store/booster';
import { getBoosterV2Data } from '@/store/booster-v2';
import { getProposals } from '@/store/governance';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { getLandingData } from '@/store/landing';
import { getLendAppMarkets } from '@/store/lend-app';
import { getLiquidLockingAppData } from '@/store/liquid-locking-app';
import { getLiquidStakingAppData } from '@/store/liquid-staking-app';
import { getLiquidStakingTaoAppData } from '@/store/liquid-staking-tao-app';
import { getHistoryPoints, getMarkets } from '@/store/market';
import { getPrices } from '@/store/price';
import { protocolSelector, setProtocol } from '@/store/protocol';
import { updateProtocol } from '@/store/protocol';
import { execQueue } from '@/store/queue';
import { getRewardsBatchData } from '@/store/reward-batch';
import {
  hasPendingTransactionsSelector,
  transactionSelector,
} from '@/store/transaction';
import { getUshAppInfo } from '@/store/ush-app';

import logger from '@/utils/logger';
import { calcFunctionTime } from '@/utils/time';

import { ROUTES } from '@/types/enums';

const MARKET_REFRESH_TIME_SM: number = 10 * 1000; // in milliseconds
const MARKET_REFRESH_TIME_LG: number = 1 * 60 * 1000; // in milliseconds

// const MARKET_REFRESH_TIME_SM: number = 10 * 1000 * 60; // in milliseconds
// const MARKET_REFRESH_TIME_LG: number = 1 * 60 * 1000 * 60; // in milliseconds

const useCompletedTransactionKey = () => {
  const { currentTransactions } = useAppSelector(transactionSelector);

  const completedTransactionStatuses = useMemo(
    () =>
      currentTransactions
        .filter(({ status }) => status !== 'pending')
        .map(({ status }) => status)
        .join('-'),
    [currentTransactions],
  );

  const completedTransactionKey = useMemo(
    () => `${completedTransactionStatuses}`,
    [completedTransactionStatuses],
  );

  return completedTransactionKey;
};

const useLoaders = () => {
  const dispatch = useAppDispatch();
  const { hasProtocolInfo, hasFirstInfo, hasFirstAccountInfo } =
    useAppSelector(protocolSelector);

  const { address: accountAddress, selectedTypeAddress } =
    useAppSelector(accountSelector);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const router = useRouter();

  const [lastRoute, setLastRoute] = useState(router.route);

  const completedTransactionKey = useCompletedTransactionKey();

  // refresh the info
  useEffect(() => {
    setRefreshCounter((state) => state + 1);
  }, [
    accountAddress,
    completedTransactionKey,
    router.route,
    hasProtocolInfo,
    selectedTypeAddress,
  ]);

  // refresh the info every x time
  useEffect(() => {
    if (hasPendingTransactions) {
      return;
    }

    const refreshInferval = accountAddress
      ? MARKET_REFRESH_TIME_SM
      : MARKET_REFRESH_TIME_LG;

    const interval = setInterval(() => {
      setRefreshCounter((state) => state + 1);
    }, refreshInferval);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter, hasPendingTransactions]);

  const refreshCounterDelay = useMemo(() => {
    if (!hasFirstInfo) {
      return 0;
    }

    return 1_000;
  }, [hasFirstInfo]);

  const refreshCounterDebounce = useDebounce(
    refreshCounter,
    refreshCounterDelay,
  );

  const isLoadingFirstInfo = useMemo(() => {
    if (lastRoute !== router.route) {
      return true;
    }

    if (
      !['/'].includes(router.route) &&
      accountAddress &&
      !hasFirstAccountInfo
    ) {
      return true;
    }

    if (!hasFirstInfo) {
      return true;
    }

    return false;
  }, [
    lastRoute,
    router.route,
    accountAddress,
    hasFirstAccountInfo,
    hasFirstInfo,
  ]);

  useEffect(() => {
    dispatch(setProtocol({ isLoadingFirstInfo }));
  }, [isLoadingFirstInfo]);

  return {
    refreshCounter,
    setRefreshCounter,
    refreshCounterDebounce,
    setLastRoute,
  };
};

declare global {
  interface Window {
    time: Record<string, string>;
    blockchainCount: number;
    gatewayCount: number;
    totalCount: number;
  }
}

const useStoreIntervalProtocolInfo = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { address: accountAddress, selectedTypeAddress } =
    useAppSelector(accountSelector);
  const { hasProtocolInfo, isLoadingInfo, hasFirstInfo, hasFirstAccountInfo } =
    useAppSelector(protocolSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const { currentTransactions } = useAppSelector(transactionSelector);
  const [lastCurrentTransactionId, setLastCurrentTransactionId] =
    useState<string>('');

  const currentTransactionId = useMemo(() => {
    return currentTransactions.map(({ hash }) => hash).join('_');
  }, [currentTransactions]);

  const { setRefreshCounter, refreshCounterDebounce, setLastRoute } =
    useLoaders();

  const promisesByRoute = () => {
    switch (router.route) {
      case ROUTES.GOVERNANCE: {
        return [getProposals];
      }

      case ROUTES.PRICES: {
        return [getPrices];
      }

      case ROUTES.MARKETS: {
        return [
          getRewardsBatchData,
          getMarkets,
          getHistoryPoints,
          getBoosterData,
          getBoosterV2Data,
        ];
      }

      case ROUTES.HOME: {
        return [getLandingData];
      }

      case ROUTES.LIQUID: {
        return [getRewardsBatchData, getBoosterData, getBoosterV2Data];
      }

      case ROUTES.LIQUID_MULTIVERSX_APP: {
        return [
          getRewardsBatchData,
          getLiquidStakingAppData,
          getLiquidLockingAppData,
          getBoosterData,
          getBoosterV2Data,
        ];
      }

      case ROUTES.LIQUID_TAO_APP: {
        return [
          getRewardsBatchData,
          getLiquidStakingTaoAppData,
          getLiquidLockingAppData,
          getBoosterData,
          getBoosterV2Data,
        ];
      }

      case ROUTES.LEND: {
        return [
          getBoosterData,
          getBoosterV2Data,
          getRewardsBatchData,
          getLendAppMarkets,
          getLiquidLockingAppData,
        ];
      }

      case ROUTES.USH: {
        return [
          getRewardsBatchData,
          getBoosterData,
          getBoosterV2Data,
          getUshAppInfo,
        ];
      }

      default: {
        return [getRewardsBatchData, getBoosterData, getBoosterV2Data];
      }
    }
  };

  const getProtocolInfo = async () => {
    await dispatch(setProtocol({ isLoadingInfo: true }));

    await calcFunctionTime(
      `-. updateProtocol + promisesByRoute${router.route}`,
      async () => {
        const proxyAddress = await dispatch(getProxyAccount());

        const selectedAccountAddress =
          router.route === ROUTES.LEND && selectedTypeAddress === 'proxy'
            ? proxyAddress
            : accountAddress;

        logger.info({
          proxyAddress,
          selectedAccountAddress,
          accountAddress,
        });

        const promises = [
          updateProtocol,
          ...(['/'].includes(router.route) ? [] : [updateESDTTokenBalance]),
          ...(!hasFirstAccountInfo ||
          currentTransactionId !== lastCurrentTransactionId
            ? []
            : [updateAccountTxs]),
        ];

        await Promise.all(
          promises.map((dispatchFn) =>
            calcFunctionTime(
              `-. before:promisesByRoute:${dispatchFn.name}`,
              () =>
                dispatch(
                  dispatchFn({ accountAddress: selectedAccountAddress }),
                ),
            ),
          ),
        );

        const promises2 = promisesByRoute();

        await Promise.all(
          promises2.map((dispatchFn) =>
            calcFunctionTime(
              `-. promisesByRoute${router.route}/${dispatchFn.name}`,
              () =>
                dispatch(
                  dispatchFn({ accountAddress: selectedAccountAddress }),
                ),
            ),
          ),
        );
      },
    );

    setRefreshCounter((state) =>
      state === refreshCounterDebounce ? state : state + 1,
    );

    setLastRoute(router.route);
    setLastCurrentTransactionId(currentTransactionId);

    await Promise.all([
      dispatch(execQueue()),
      dispatch(
        setProtocol({
          isLoadingInfo: false,
          hasFirstInfo: true,
          hasFirstAccountInfo: accountAddress ? true : false,
        }),
      ),
    ]);

    window.totalCount =
      (window.totalCount || 0) + window.blockchainCount + window.gatewayCount;

    const infoData = {
      ...window.time,
      gatewayCounters: {
        blockchainCount: window.blockchainCount,
        gatewayCount: window.gatewayCount,
        totalCount: window.totalCount,
      },
    };

    const cacheMap = window.cacheMap || {};

    logger.info(infoData);
    logger.info({
      ...cacheMap,
      total: Object.values(cacheMap).reduce(
        (prev, current) => prev + current,
        0,
      ),
    });
    window.time = {};
    window.blockchainCount = 0;
    window.gatewayCount = 0;
  };

  // get all the data
  useEffect(() => {
    if (
      !hasProtocolInfo ||
      isLoadingInfo ||
      (hasPendingTransactions && hasFirstInfo && hasFirstAccountInfo)
    ) {
      return;
    }

    getProtocolInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounterDebounce]);
};

export default useStoreIntervalProtocolInfo;
