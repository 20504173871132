import React from 'react';

type Props = {};

const Minus = (props: Props) => {
  return (
    <svg
      width='8'
      height='3'
      viewBox='0 0 8 3'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='translate-x-[-0.3px]'
    >
      <path
        d='M1.10254 1.5H6.89827'
        stroke='#EF7748'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Minus;
