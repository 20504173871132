import React from 'react';

const State = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.6296 2.42969H10.8591C12.6869 2.42969 13.8241 3.72003 13.8241 5.54665V10.4733C13.8241 12.2999 12.6869 13.5897 10.8585 13.5897H5.6296C3.80178 13.5897 2.66406 12.2999 2.66406 10.4733V5.54665C2.66406 3.72003 3.80721 2.42969 5.6296 2.42969Z'
        stroke='#359FFF'
        strokeOpacity='0.8'
        strokeWidth='0.96'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.66406 7.019V10.8472M8.26789 5.1875V10.8471M10.8294 9.042V10.8469'
        stroke='#359FFF'
        strokeOpacity='0.8'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default State;
