import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { MIN_EGLD_FEE } from '@/hooks/interaction/useLendInteraction';

import InfoIcon from '@/components/Icons/InfoIcon';

import { useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';

const Alert: React.FC<{
  children: ReactNode | ReactNode[];
  type: 'danger' | 'warning';
}> = ({ children, type = 'warning' }) => {
  return (
    <div
      className={classNames(
        'rounded-md py-0.5 text-center text-xs leading-tight text-white',
        {
          'bg-red': type === 'danger',
          'bg-[#D9A625]': type === 'warning',
        },
      )}
    >
      <InfoIcon width={10} height={10} className='-mt-1 mr-1 inline-block' />
      {children}
    </div>
  );
};

const OperationFormBottomAlerts = () => {
  const { t } = useTranslation();
  const { address } = useAppSelector(accountSelector);
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);

  if (!address) return null;
  
  return (
    <div className='mt-[5.5px] mb-[5px] md:text-[10px]'>
      {!hasEnoughEGLDBalance ? (
        <Alert type='warning'>
          <span className='opacity-80 md:mt-[10px]'>
            {t('not-enough-balance')}
          </span>{' '}
          <br />
          <span className='font-semibold'>
            {t('minimum-gas-fee')}{' '}
            {`= ${new DefiUtils(MIN_EGLD_FEE)
              .toFullDecimals(18)
              .toString()} EGLD`}
          </span>
        </Alert>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OperationFormBottomAlerts;
