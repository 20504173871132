import React from 'react';

import useSignMultipleTransactions, {
  TX_STATUS,
} from '@/hooks/core/useSignMultipleTransactions';
import useTheme from '@/hooks/useTheme';

import { useAppDispatch, useAppSelector } from '@/store';
import { closePopup, popupSelector } from '@/store/popup';

import clsxm from '@/services/clsxm';

import { CrossIcon } from './components';

interface ClosePopupBtnProps {
  className?: string;
  iconClassName?: string;
  theme?: { light?: string; dark?: string };
  onClick?: (args: any) => void;
}

const ClosePopupBtn: React.FC<ClosePopupBtnProps> = ({
  className,
  onClick,
  iconClassName,
  theme = {},
}) => {
  const dispatch = useAppDispatch();

  const { light, dark } = theme;
  const { isLight } = useTheme();
  const { data } = useAppSelector(popupSelector);
  const { cancelTransactions } = useSignMultipleTransactions();

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e);
      return;
    }

    if (data?.txStatus === TX_STATUS.AWAITING_CONFIRMATION) {
      cancelTransactions();
      dispatch(closePopup());
      return;
    }

    dispatch(closePopup());
  };

  return (
    <button
      className={clsxm('-m-2 p-2 outline-none', className)}
      onClick={handleClick}
      style={{ color: isLight ? light : dark }}
      data-testid='closePopup'
      type='button'
    >
      <CrossIcon className={iconClassName} />
    </button>
  );
};

export default ClosePopupBtn;
