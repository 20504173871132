import { IPlainTransactionObject } from '@multiversx/sdk-core';
import { useEffect } from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  deleteTransactionGroup,
  setTransactionProcessingStatus,
  TRANSACTION_PROCESSING_STATUS_TYPE,
  TransactionGroup,
  transactionSelector,
} from '@/store/transaction';

import multiversxSDK from '@/services/multiversx-sdk';
import logger from '@/utils/logger';

const useSendPendingTransactions = () => {
  const { sendOrWatchTransactions, handleTransactionsError } =
    useSignMultipleTransactions();
  const { transactionProcessingStatus, transactionGroups } =
    useAppSelector(transactionSelector);
  const dispatch = useAppDispatch();

  const loadTransactionGroup = async (transactionGroup: TransactionGroup) => {
    const { config, transactions } = transactionGroup;

    try {
      if (!config.accountAddress) {
        dispatch(
          setTransactionProcessingStatus(
            TRANSACTION_PROCESSING_STATUS_TYPE.STORING_TXS,
          ),
        );
        return;
      }

      const signedTransactions = transactions.filter(
        ({ status }) => status === 'signed',
      );
      const sentTransactions = transactions.filter(
        ({ status }) => status !== 'signed',
      );

      const foundedSentTransactions =
        sentTransactions.length > 0
          ? await multiversxSDK.accountTransactionList(config.accountAddress, {
              hashes: sentTransactions.map(({ hash }) => hash).join(','),
            })
          : [];

      const foundedSignedTransactions = signedTransactions
        .map(({ transactionSigned }) =>
          transactionSigned
            ? transactionSigned
            : ([] as IPlainTransactionObject[]),
        )
        .flat();

      const transactionsFounded = [
        ...foundedSentTransactions,
        ...foundedSignedTransactions,
      ];

      if (transactionsFounded.length === 0) {
        dispatch(deleteTransactionGroup(config.accountAddress));
        return;
      }

      await sendOrWatchTransactions(transactionsFounded, config);
    } catch (error) {
      logger.error(error);
      await handleTransactionsError(error, config);
    }
  };

  const loadTransactions = async () => {
    dispatch(
      setTransactionProcessingStatus(
        TRANSACTION_PROCESSING_STATUS_TYPE.STORING_TXS,
      ),
    );

    if (transactionGroups.length === 0) {
      return;
    }

    await Promise.all(
      transactionGroups.map((transactionGroup) =>
        loadTransactionGroup(transactionGroup),
      ),
    );
  };

  useEffect(() => {
    if (
      transactionProcessingStatus !==
      TRANSACTION_PROCESSING_STATUS_TYPE.SENDING_PENDING_TXS
    ) {
      return;
    }

    loadTransactions();
  }, [transactionProcessingStatus]);
};

export default useSendPendingTransactions;
