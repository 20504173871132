import classNames from 'classnames';
import React, { forwardRef } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { formatNumber } from '@/utils/helpers';

import { MaxButton } from './MaxButton';
import { Percent80Limit } from './Percent80Limit';

export const AmountInput = forwardRef<HTMLInputElement, any>(({
  prefix,
  suffix,
  decimalsLimit = 18,
  inputValue = 0,
  estimation = 0,
  onChange = (n: number) => {},
  maxSelected,
  onMax = () => {},
  hasHundredPercentButton = true,
}, ref) => {
  prefix = prefix ?? (
    <div>
      <img
        className='h-[24px] w-[24px] rounded-full'
        src='https://cdn.app.hatom.com/images/img1.svg'
        alt='HTM'
      />
    </div>
  );

  return (
    <div>
      <div className='relative'>
        <div className='absolute left-2.5 top-1/2 z-10 -translate-y-1/2'>
          {prefix}
        </div>
        
        <div>
          <CurrencyInput
            inputMode='text'
            autoComplete='off'
            className={classNames(
              'px-[44px] pr-20',
              'h-[60px] md:h-[72px] w-full rounded-[20px] border-[2px] border-[#DCDCE3] bg-[#F9F9FE] placeholder:text-[#5C5C71] dark:border-[#837ED3] dark:bg-[#292651] dark:placeholder:text-white/70',
              'font-hass font-semibold leading-6',
              'focus:outline-none',
              'placeholder:text-current',
              'theme-switch-transition outline-none'
            )}
            id='liquid-input'
            name='liquid-input'
            data-testid='liquidInput'
            maxLength={50}
            onValueChange={onChange}
            placeholder='0'
            max={10}
            decimalsLimit={decimalsLimit}
            allowNegativeValue={false}
            groupSeparator=','
            decimalSeparator='.'
            value={inputValue}
            defaultValue={0}
            ref={ref}
            autoFocus
          />
          <div className='absolute bottom-3 left-[52px] text-[12px] text-[#535367] dark:text-[#625E8F]'>
            ≈ ${formatNumber(estimation.toLocaleString('en-US'), 2)}
          </div>
        </div>
        <div className='absolute right-[18px] top-1/2 z-10 -translate-y-1/2'>
          {!suffix && hasHundredPercentButton && <MaxButton onMax={onMax} selected={maxSelected} />}
          {!suffix && !hasHundredPercentButton && (
            <Percent80Limit onClick={onMax} selected={maxSelected} />
          )}
        </div>
      </div>
    </div>
  );
});

AmountInput.displayName = 'AmountInput';
