
import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useIsolatedLendingProtocolInteraction from '@/hooks/interaction/useIsolatedLendingProtocolInteraction';
import {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';
import useMediaQuery from '@/hooks/useMediaQuery';

import CurrencyInputButton from '@/components/CurrencyInputButton';
import Hint from '@/components/Hint';
import HintTransaction from '@/components/HintTransaction';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import FutureStatsRightArrow from '@/components/Icons/FutureStatsRightArrow';
import InfoIcon from '@/components/Icons/InfoIcon';
import OperationFormBottomAlerts from '@/components/OperationFormBottomAlerts';
import PopupBed from '@/components/popups/PopupBed';
import CollateralOptionCard from '@/components/popups/UshCollateralPopup/components/CollateralOptionCard';
import TokenCurrencyTag from '@/components/popups/UshCollateralPopup/components/TokenCurreencyTag';
import UshCollateralPopupHead from '@/components/popups/UshCollateralPopup/components/UshColaterallPopupHead';
import {
  COLLATERAL_TABS,
  staticDataMap,
  TABS,
} from '@/components/popups/UshCollateralPopup/constants';
import Tabber from '@/components/Tabber';
import TransactionButton from '@/components/TransactionButton';

import { useAppSelector } from '@/store';
import { popupSelector } from '@/store/popup';
import {
  hasEnoughEGLDBalanceSelector,
  MARKET_KEY,
  protocolSelector,
} from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';
import { IsolatedLendingProtocol, ushAppSelector } from '@/store/ush-app';

import clsxm from '@/services/clsxm';
import {
  formatNumber,
  getBorrowLimitUsedPercent,
  getTokenBorrowLimit,
  sleep,
  subtractGasFee,
  wadBasicUnit,
} from '@/utils/helpers';

const UshCollateralPopup = () => {
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    data: { isolatedKey, tab: defaultTab },
  } = useAppSelector(popupSelector);

  const { isLoggedIn } = useLogin();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<TABS>(defaultTab);
  const [maxSelected, setMaxSelected] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('0');
  const [token, setToken] = useState<string>(isolatedKey);
  const { signTransactions } = useSignMultipleTransactions();
  const { addCollateral, removeCollateral } =
    useIsolatedLendingProtocolInteraction();
  const { isolatedLendingProtocolsList } = useAppSelector(ushAppSelector);
  const { exchangeRates, liquidStaking, markets } =
    useAppSelector(protocolSelector);

  const {
    account: {
      hasTakenBorrow,
      s_collateralBalance,
      borrowLimitUSD,
      borrowLimitUsedPercent,
      borrowBalanceUSD,
      hasCollateral,
    },
    tokensMap,
    collateralFactor,
    s_minCollateralAmount,
    s_collateralCap,
    collateralToken,
    lsToken,
  } = isolatedLendingProtocolsList.find(
    (item) => item.isolatedKey === isolatedKey,
  ) as IsolatedLendingProtocol;

  const isWTAO = isolatedKey === 'WTAO';
  const {
    collateralTokenUrl,
    lsTokenUrl,
    removeCollateralTokenDescription,
    removeLsTokenDescription,
    removeCollateralTokenTitle,
    removeLsTokenTitle,
  } = staticDataMap[isolatedKey] || {};

  const isolatedToken = tokensMap[token] || {
    balance: '0',
    decimals: 1,
    priceUSD: '0',
    identifier: '',
    symbol: '',
  };

  const [hasFocus, setFocus] = useState<boolean>(true);

  const md = useMediaQuery('(min-width: 768px)');

  const handleFocus = async (value: boolean) => {
    setFocus(value);

    if (value) {
      await sleep(50);
      inputRef.current?.focus();
    }
  };

  const handleInputFocus = () => {
    handleFocus(true);
    if (inputValue === '0') {
      setInputValue('');
    }
  };

  const handleInputBlur = () => {
    handleFocus(false);
    if (inputValue === '') {
      setInputValue('0');
    }
  };

  const handleToken = (tokenSymbol: string) => {
    setToken(tokenSymbol);
    handleMax(false);
  };

  const collateralBalanceTokens = useMemo(() => {
    return new DefiUtils(s_collateralBalance)
      .multipliedBy(
        exchangeRates[
          `${collateralToken.defaultSymbol}/${isolatedToken.defaultSymbol}`
        ] || '0',
      )
      .toString();
  }, [
    collateralToken.defaultSymbol,
    exchangeRates,
    isolatedToken.defaultSymbol,
    s_collateralBalance,
  ]);

  const finalCollateralBalanceTokens = useMemo(() => {
    return new DefiUtils(collateralBalanceTokens)
      [selectedTab === TABS.ADD_COLLATERAL ? 'plus' : 'minus'](inputValue)
      .toString();
  }, [collateralBalanceTokens, inputValue, selectedTab]);

  const extraAmountMultiplier = useMemo(() => {
    return selectedTab === TABS.ADD_COLLATERAL ? 1.0001 : 1.00001;
  }, [selectedTab]);

  const minEgldToDelegate = useMemo(() => {
    return isolatedToken.defaultSymbol === 'EGLD'
      ? new DefiUtils(liquidStaking.minEgldToDelegate)
          .multipliedBy(extraAmountMultiplier)
          .toString()
      : '0';
  }, [
    extraAmountMultiplier,
    isolatedToken.defaultSymbol,
    liquidStaking.minEgldToDelegate,
  ]);

  const minCollateralTokens = useMemo(() => {
    return new DefiUtils(s_minCollateralAmount)
      .multipliedBy(extraAmountMultiplier)
      .multipliedBy(
        exchangeRates[
          `${collateralToken.defaultSymbol}/${isolatedToken.defaultSymbol}`
        ] || '0',
      )
      .toString();
  }, [
    s_minCollateralAmount,
    extraAmountMultiplier,
    exchangeRates,
    collateralToken.defaultSymbol,
    isolatedToken.defaultSymbol,
  ]);

  const getTabIndex = (id: TABS) => {
    return COLLATERAL_TABS.indexOf(id as TABS);
  };

  const isValidInput = useMemo(
    () =>
      inputValue && new DefiUtils(inputValue).isGreaterThan('0') ? true : false,
    [inputValue],
  );

  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);

  const tokenBalance = useMemo(() => {
    return new DefiUtils(
      isolatedToken.defaultSymbol === 'EGLD'
        ? subtractGasFee(isolatedToken.balance)
        : isolatedToken.balance,
    )
      .toFullDecimals(isolatedToken.decimals)
      .toString();
  }, [
    isolatedToken.balance,
    isolatedToken.decimals,
    isolatedToken.defaultSymbol,
  ]);

  const maxRemoveCollateralUnderlyingAmount = useMemo(() => {
    const maxBorrowLimitUSD = new DefiUtils(borrowLimitUSD)
      .minus(
        new DefiUtils(borrowBalanceUSD).dividedBy(
          hasTakenBorrow ? MAX_RECOMMENDED_BORROW_LIMIT_MARGIN : 1,
        ),
      )
      .toSafeString();

    const s_collateralFactor = new DefiUtils(collateralFactor).dividedBy(
      wadBasicUnit,
    );

    const maxCollateralBorrowLimitUSD = new DefiUtils(
      maxBorrowLimitUSD,
    ).dividedBy(s_collateralFactor);

    return new DefiUtils(maxCollateralBorrowLimitUSD)
      .fromUSD(isolatedToken.priceUSD)
      .toString();
  }, [
    borrowBalanceUSD,
    borrowLimitUSD,
    collateralFactor,
    hasTakenBorrow,
    isolatedToken.priceUSD,
  ]);

  const removableCollateralBalanceLimitUSD = useMemo(
    () => new DefiUtils(borrowLimitUSD).minus(borrowBalanceUSD).toSafeString(),
    [borrowBalanceUSD, borrowLimitUSD],
  );

  const removableCollateralEquivalentLimit = useMemo(
    () =>
      new DefiUtils(removableCollateralBalanceLimitUSD)
        .dividedBy(new DefiUtils(collateralFactor).dividedBy(wadBasicUnit))
        .multipliedBy(MAX_BORROW_LIMIT_MARGIN)
        .toSafeString(),
    [collateralFactor, removableCollateralBalanceLimitUSD],
  );

  const removableCollateralTokenLimit = useMemo(
    () =>
      new DefiUtils(removableCollateralEquivalentLimit)
        .fromUSD(isolatedToken.priceUSD)
        .toString(),
    [isolatedToken.priceUSD, removableCollateralEquivalentLimit],
  );

  const removableCollateralToken = useMemo(() => {
    if (!hasTakenBorrow) {
      return collateralBalanceTokens;
    }

    return DefiUtils.min(
      collateralBalanceTokens,
      removableCollateralTokenLimit,
    ).toString();
  }, [hasTakenBorrow, collateralBalanceTokens, removableCollateralTokenLimit]);

  const suggestedRemovableCollateralToken = useMemo(() => {
    if (!hasTakenBorrow) {
      return collateralBalanceTokens;
    }

    return DefiUtils.min(
      maxRemoveCollateralUnderlyingAmount,
      collateralBalanceTokens,
    ).toString();
  }, [
    collateralBalanceTokens,
    maxRemoveCollateralUnderlyingAmount,
    hasTakenBorrow,
  ]);

  const minValueAddCollateral = useMemo(() => {
    const diffMinCollateralAmount = DefiUtils.max(
      new DefiUtils(minCollateralTokens).minus(collateralBalanceTokens),
      0,
    );

    const minHLsTokenValueInCurrentToken = new DefiUtils(1)
      .toUnderlying(
        markets[lsToken.defaultSymbol as MARKET_KEY]?.hTokenExchangeRate || '0',
      )
      .toFullDecimals(lsToken.decimals)
      .multipliedBy(
        exchangeRates[
          `${lsToken.defaultSymbol}/${isolatedToken.defaultSymbol}`
        ] || '0',
      )
      .toFixed(isolatedToken.decimals, DefiUtils.ROUND_UP);

    return DefiUtils.max(
      minEgldToDelegate,
      diffMinCollateralAmount,
      minHLsTokenValueInCurrentToken,
    ).removeScientificNotation();
  }, [
    minCollateralTokens,
    collateralBalanceTokens,
    markets,
    lsToken.defaultSymbol,
    lsToken.decimals,
    exchangeRates,
    isolatedToken.defaultSymbol,
    isolatedToken.decimals,
    minEgldToDelegate,
  ]);

  const minValueRemoveCollateral = useMemo(() => {
    const minHLsTokenValueInCurrentToken = new DefiUtils(1)
      .toUnderlying(
        markets[lsToken.defaultSymbol as MARKET_KEY]?.hTokenExchangeRate || '0',
      )
      .toFullDecimals(lsToken.decimals)
      .multipliedBy(
        exchangeRates[
          `${lsToken.defaultSymbol}/${isolatedToken.defaultSymbol}`
        ] || '0',
      )
      .toFixed(isolatedToken.decimals, DefiUtils.ROUND_UP);

    return DefiUtils.max(
      minEgldToDelegate,
      minHLsTokenValueInCurrentToken,
    ).removeScientificNotation();
  }, [
    markets,
    lsToken.defaultSymbol,
    lsToken.decimals,
    exchangeRates,
    isolatedToken.defaultSymbol,
    isolatedToken.decimals,
    minEgldToDelegate,
  ]);

  const requestAmounts = useMemo(() => {
    return {
      [TABS.ADD_COLLATERAL]: {
        minValue: minValueAddCollateral,
        maxValue: tokenBalance,
        limitValue: tokenBalance,
      },
      [TABS.REMOVE_COLLATERAL]: {
        minValue: minValueRemoveCollateral,
        maxValue: suggestedRemovableCollateralToken,
        limitValue: removableCollateralToken,
      },
    };
  }, [
    tokenBalance,
    suggestedRemovableCollateralToken,
    removableCollateralToken,
    minValueAddCollateral,
    minValueRemoveCollateral,
  ]);

  const hasFunds = useMemo(
    () =>
      new DefiUtils(requestAmounts[selectedTab].limitValue).isGreaterThan('0'),
    [requestAmounts, selectedTab],
  );

  const isExceedingCollateralCap = useMemo(() => {
    if (selectedTab !== TABS.ADD_COLLATERAL || !s_collateralCap) {
      return false;
    }

    return new DefiUtils(inputValue).isGreaterThan(s_collateralCap);
  }, [selectedTab, s_collateralCap, inputValue]);

  const isValidAmountRequest = useMemo(() => {
    if (!isValidInput) {
      return false;
    }

    if (maxSelected) {
      return true;
    }

    return new DefiUtils(inputValue).isLessThanOrEqualTo(
      requestAmounts[selectedTab].limitValue,
    );
  }, [inputValue, isValidInput, requestAmounts, maxSelected, selectedTab]);

  const inputPriceUSD = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue).toUSD(isolatedToken.priceUSD).toString()
        : '0',
    [inputValue, isValidAmountRequest, isolatedToken.priceUSD],
  );

  const getEstimationFactor = (): string => {
    return selectedTab === TABS.ADD_COLLATERAL ? '1' : '-1';
  };

  const collateralInputBorrowLimitUSD = getTokenBorrowLimit(
    inputPriceUSD.toString(),
    collateralFactor,
  );

  const nextBorrowLimitAmountUSD = DefiUtils.max(
    new DefiUtils(borrowLimitUSD)
      .plus(
        new DefiUtils(collateralInputBorrowLimitUSD as string).multipliedBy(
          getEstimationFactor(),
        ),
      )
      .toString(),
    '0',
  ).toString();

  const nextBorrowLimitUsedPercentage = getBorrowLimitUsedPercent(
    borrowBalanceUSD,
    nextBorrowLimitAmountUSD,
  );

  const hasInsufficientCollateralLeft = useMemo(() => {
    const remainingCollateralTokens = new DefiUtils(
      collateralBalanceTokens,
    ).minus(inputValue);

    const isRemovingAll = remainingCollateralTokens.isZero();

    return (
      selectedTab === TABS.REMOVE_COLLATERAL &&
      hasCollateral &&
      !isRemovingAll &&
      remainingCollateralTokens.isLessThan(minCollateralTokens)
    );
  }, [
    collateralBalanceTokens,
    inputValue,
    selectedTab,
    hasCollateral,
    minCollateralTokens,
  ]);

  const hasInsufficientToUndelegate = useMemo(() => {
    return (
      selectedTab === TABS.REMOVE_COLLATERAL &&
      isolatedKey === 'EGLD' &&
      new DefiUtils(inputValue)
        .multipliedBy(
          exchangeRates[
            `${isolatedToken.defaultSymbol}/${collateralToken.defaultSymbol}`
          ] || '0',
        )
        .isLessThan(minEgldToDelegate)
    );
  }, [
    selectedTab,
    isolatedKey,
    inputValue,
    exchangeRates,
    isolatedToken.defaultSymbol,
    collateralToken.defaultSymbol,
    minEgldToDelegate,
  ]);

  const footerNotes = useMemo(() => {
    return {
      [TABS.ADD_COLLATERAL]: [
        {
          label: t('wallet-balance'),
          value: `${formatNumber(
            isolatedToken.s_balance,
            isolatedToken.s_balance == '0' ? 2 : 7,
          )} ${isolatedToken.defaultSymbol}`,
        },
      ],
      [TABS.REMOVE_COLLATERAL]: [
        {
          label: t('supplied-liquidity'),
          value: `${formatNumber(
            s_collateralBalance,
            s_collateralBalance == '0' ? 2 : 7,
          )} ${isolatedKey}`,
        },
      ],
    };
  }, [
    t,
    isolatedToken.s_balance,
    isolatedToken.defaultSymbol,
    s_collateralBalance,
    isolatedKey,
  ]);

  const onTabToggle = (tabNumber: number) => {
    setSelectedTab(COLLATERAL_TABS[tabNumber]);
    handleMax(false);
  };

  const handleInputValueChange = (value?: string) => {
    if (maxSelected) {
      return;
    }

    setInputValue(value || '0');
  };

  const getActionButtonDisabled = (): boolean => {
    const commonValidations =
      hasPendingTransactions ||
      !isLoggedIn ||
      !hasFunds ||
      !hasEnoughEGLDBalance ||
      !isValidInput ||
      new DefiUtils(inputValue).isGreaterThan(
        requestAmounts[selectedTab].limitValue,
      ) ||
      new DefiUtils(inputValue).isLessThan(
        requestAmounts[selectedTab].minValue,
      );

    return (
      commonValidations ||
      hasInsufficientCollateralLeft ||
      hasInsufficientToUndelegate ||
      isExceedingCollateralCap
    );
  };

  const getActionButtonLabel = (): string => {
    switch (selectedTab) {
      case TABS.ADD_COLLATERAL: {
        if (!isLoggedIn || !hasFunds) {
          return t('no-funds-available');
        }

        if (!isValidInput && hasFunds) {
          return t('supply');
        }

        if (
          new DefiUtils(inputValue).isLessThan(
            requestAmounts[selectedTab].minValue,
          )
        ) {
          return t('the-amount-is-too-low');
        }

        if (isExceedingCollateralCap) {
          return t('exceed-collateral-cap');
        }

        if (
          new DefiUtils(inputValue).isGreaterThan(
            requestAmounts[selectedTab].maxValue,
          )
        ) {
          return t('no-funds-available');
        }

        return t('supply');
      }

      case TABS.REMOVE_COLLATERAL: {
        if (
          !isLoggedIn ||
          !hasFunds ||
          new DefiUtils(inputValue).isGreaterThan(s_collateralBalance)
        ) {
          return t('no-funds-available');
        }

        if (!isValidInput && hasFunds) {
          return t('withdraw');
        }

        if (hasInsufficientCollateralLeft) {
          return t('Insufficient collateral left');
        }

        if (hasInsufficientToUndelegate) {
          return t('insufficient-to-undelegate', 'insufficient to undelegate');
        }

        if (
          new DefiUtils(inputValue).isLessThan(
            requestAmounts[selectedTab].minValue,
          )
        ) {
          return t('the-amount-is-too-low');
        }

        return t('withdraw');
      }

      default: {
        return t('default');
      }
    }
  };

  const [actionButton, setActionButton] = useState({
    disabled: true,
    label: getActionButtonLabel(),
  });

  useEffect(() => {
    switch (selectedTab) {
      case TABS.ADD_COLLATERAL: {
        setToken(isolatedKey);
        break;
      }
      case TABS.REMOVE_COLLATERAL: {
        setToken(isolatedKey);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleButtonClick = async () => {
    if (hasPendingTransactions) {
      return;
    }

    switch (selectedTab) {
      case TABS.ADD_COLLATERAL: {
        const amount = new DefiUtils(DefiUtils.min(inputValue, tokenBalance))
          .toBasicUnits(isolatedToken.decimals)
          .toSafeFixed(0);

        signTransactions(
          [
            addCollateral({
              isolatedKey,
              identifier: isolatedToken.identifier,
              amount,
            }),
          ],
          {
            token: isolatedToken.defaultSymbol,
            group: TRANSACTION_GROUP_TYPE.USH,
            subgroup: TRANSACTION_SUBGROUP_TYPE.ADD_COLLATERAL,
            result: inputValue,
          },
        );
        break;
      }

      case TABS.REMOVE_COLLATERAL: {
        const inputValueInCollateral =
          isolatedToken.defaultSymbol === collateralToken.defaultSymbol
            ? inputValue
            : new DefiUtils(inputValue)
                .multipliedBy(
                  exchangeRates[
                    `${isolatedToken.defaultSymbol}/${collateralToken.defaultSymbol}` ||
                      '0'
                  ],
                )
                .toSafeFixed(collateralToken.decimals, DefiUtils.ROUND_CEIL);

        const amount = (
          maxSelected && !hasTakenBorrow
            ? new DefiUtils(s_collateralBalance)
            : new DefiUtils(
                DefiUtils.min(inputValueInCollateral, s_collateralBalance),
              )
        )
          .toBasicUnits(isolatedToken.decimals)
          .toSafeFixed(0);

        signTransactions(
          [
            removeCollateral({
              isolatedKey,
              collateralType:
                isolatedToken.defaultSymbol === collateralToken.defaultSymbol
                  ? 'Token'
                  : 'LSToken',
              amount,
            }),
          ],
          {
            token: isolatedToken.defaultSymbol,
            group: TRANSACTION_GROUP_TYPE.USH,
            subgroup: TRANSACTION_SUBGROUP_TYPE.REMOVE_COLLATERAL,
            result: inputValue,
          },
        );
        break;
      }
    }
  };

  useEffect(() => {
    setActionButton({
      disabled: getActionButtonDisabled(),
      label: getActionButtonLabel(),
    });
    // eslint-disable-next-line
  }, [inputValue, selectedTab]);

  const showEightyPercentButton = useMemo(() => {
    return selectedTab === TABS.REMOVE_COLLATERAL && hasTakenBorrow;
  }, [selectedTab, hasTakenBorrow]);

  const lsTokensInCollateralTokens = useMemo(() => {
    if (maxSelected && !hasTakenBorrow) {
      return s_collateralBalance;
    }

    const exchangeRate =
      exchangeRates[
        `${lsToken.defaultSymbol}/${collateralToken.defaultSymbol}`
      ] || '0';

    return new DefiUtils(
      new DefiUtils(inputValue || 0)
        .multipliedBy(exchangeRate)
        .toFixed(lsToken.decimals, DefiUtils.ROUND_UP),
    ).removeScientificNotation();
  }, [
    collateralToken.defaultSymbol,
    exchangeRates,
    hasTakenBorrow,
    inputValue,
    lsToken.decimals,
    lsToken.defaultSymbol,
    maxSelected,
    s_collateralBalance,
  ]);

  const handleMax = async (newMaxSelected: boolean) => {
    setMaxSelected(newMaxSelected);

    if (!newMaxSelected) {
      setInputValue('');
    } else {
      const value = requestAmounts[selectedTab].maxValue;

      if (new DefiUtils(value).isLessThanOrEqualTo(0)) {
        setInputValue('');
      } else {
        setInputValue(value);
      }
    }

    await sleep(50);

    handleFocus(true);
  };

  return (
    <PopupBed close={false} width={462} maxWidth={462}>
      <form
        className='font-semibold leading-tight tracking-normal flex flex-col'
        onSubmit={(event) => {
          event.preventDefault();

          if (actionButton.disabled) {
            return;
          }

          handleButtonClick();
        }}
      >
        <UshCollateralPopupHead
          name={removeCollateralTokenTitle}
          path={collateralTokenUrl}
          night={collateralTokenUrl}
        />

        {/* Body */}

        <>
          <div className='relative my-2'>
            <div>
              <div className='relative mx-[20px]'>
                <input type='text' style={{ display: 'none' }} />

                <Hint
                  unvisible={!maxSelected}
                  autoReplace={!md}
                  content={t('maximum-amount-is-selected')}
                  placement='top-center'
                  block
                >
                  <CurrencyInput
                    ref={inputRef}
                    className={classNames(
                      'w-full max-w-[442px]',
                      'p-4 pt-[15.6px] px-[70px]',
                      'text-center text-[28px] font-normal leading-tight placeholder:text-[#6A6A8C] disabled:text-[#6A6A8C] dark:text-indigo-100 placeholder:dark:text-[#6A6A8C] disabled:dark:text-[#6A6A8C]',
                      maxSelected &&
                        '!text-[#6A6A8C] !opacity-100',
                      'placeholder:text-current',
                      'outline-none',
                      'bg-transparent',
                    )}
                    placeholder='0'
                    autoFocus
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    suffix={!hasFocus ? ` ${isolatedToken.defaultSymbol}` : ''}
                    value={inputValue}
                    autoComplete='off'
                    aria-autocomplete='none'
                    decimalsLimit={isolatedToken.decimals}
                    groupSeparator=','
                    decimalSeparator='.'
                    allowNegativeValue={false}
                    onValueChange={handleInputValueChange}
                    inputMode='text'
                    data-testid='borrowInput'
                  />
                </Hint>
                <div className='absolute right-[2px] top-1/2 grid -translate-y-1/2 place-items-end'>
                  <Hint
                    autoReplace={!md}
                    content={
                      selectedTab === TABS.ADD_COLLATERAL
                        ? t('set-maximum-recommended-by-the-protocol')
                        : t('set-maximum')
                    }
                    placement={md ? 'top-center' : 'right-end'}
                  >
                    <CurrencyInputButton
                      onClick={() => handleMax(!maxSelected)}
                      selected={maxSelected}
                    >
                      {showEightyPercentButton ? (
                        <div>
                          80% <br />
                          {t('limit')}
                        </div>
                      ) : (
                        <span className='uppercase'>{t('max')}</span>
                      )}
                    </CurrencyInputButton>
                  </Hint>
                </div>

                {isolatedToken.defaultSymbol === lsToken.defaultSymbol && (
                  <div className='h-fit w-fit absolute bottom-[-3px] -translate-x-1/2 left-1/2 '>
                    <TokenCurrencyTag
                      currency={collateralToken.defaultSymbol}
                      value={lsTokensInCollateralTokens}
                    />
                  </div>
                )}
              </div>

              <div className='ml-[10px] mt-[21px] flex justify-between'>
                <div>
                  <div className='text-xs font-semibold dark:text-white'>
                    {t('estimated-price')}
                  </div>
                  <div className=' translate-y-[-0.9px] flex items-center gap-1 text-xs text-[#6A6A8C] dark:text-[#625E8F]'>
                    {formatNumber(inputValue || 0)}{' '}
                    {isolatedToken.defaultSymbol}
                    <ApproEqualIcon className='mt-0.5' />
                    {`$${formatNumber(
                      isValidAmountRequest
                        ? new DefiUtils(inputValue)
                            .toUSD(isolatedToken.priceUSD)
                            .toSafeString()
                        : '0',
                      2,
                    )}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        <>
          <div className='mt-[0.3px] flex-grow'>
            <Tabber
              onChange={onTabToggle}
              tabs={[...COLLATERAL_TABS].map((key) => t(key.toLowerCase()))}
              defaultSelected={getTabIndex(selectedTab)}
              customTheme={(tabIdx) => {
                if (Number.isInteger(tabIdx))
                  return 'text-[#6431B7] border-current';
                else
                  return 'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] hover:border-[#BEC2E6] dark:border-[#3F3B76]';
              }}
              className='h-full flex flex-col'
              buttonTextClassName={(tabIndex) =>
                clsxm(
                  tabIndex === 0
                    ? 'translate-x-[-0.3px]'
                    : 'translate-x-[-0.5px]',
                )
              }
            >
              <div className='flex-grow bg-[#FCFAFF] p-5 pt-[32px] pb-3 dark:bg-[#292651]'>
                {/* Borrow Rate */}
                <div className='flex justify-start items-center gap-[14px]'>
                  <CollateralOptionCard
                    token={isolatedToken.defaultSymbol}
                    selectedTab={selectedTab}
                    title={removeCollateralTokenTitle}
                    description={removeCollateralTokenDescription}
                    showDescription={
                      selectedTab === TABS.REMOVE_COLLATERAL && !isWTAO
                    }
                    iconUrl={collateralTokenUrl}
                    onClick={() => handleToken(collateralToken.defaultSymbol)}
                    isSelected={
                      isolatedToken.defaultSymbol ===
                      collateralToken.defaultSymbol
                    }
                    variant='left'
                  />

                  <div className='translate-x-[2px]'>
                    <CollateralOptionCard
                      token={isolatedToken.defaultSymbol}
                      selectedTab={selectedTab}
                      title={removeLsTokenTitle}
                      description={removeLsTokenDescription}
                      showDescription={
                        selectedTab === TABS.REMOVE_COLLATERAL && !isWTAO
                      }
                      iconUrl={lsTokenUrl}
                      onClick={() => handleToken(lsToken.defaultSymbol)}
                      variant='right'
                      isSelected={
                        isolatedToken.defaultSymbol === lsToken.defaultSymbol
                      }
                    />
                  </div>
                </div>

                {/* Borrow Limit */}
                <>
                  <div className='mt-[31.5px]'>
                    <span className='text-xs dark:text-indigo-100'>
                      Minting Limits
                    </span>
                    <div className='mt-[7px] flex items-center justify-between border-b-2 border-current pb-[8.8px] text-sm leading-tight text-[#6A6A8C] dark:border-[#625E8F]/50 dark:text-[#625E8F]'>
                      <div>Minting Limit</div>
                      <div className='translate-x-[-0.6px] flex items-center justify-center gap-1'>
                        <div className='flex items-center gap-1'>
                          ${formatNumber(borrowLimitUSD)}
                          {isValidAmountRequest && (
                            <>
                              <FutureStatsRightArrow />
                              {`$${formatNumber(
                                nextBorrowLimitAmountUSD as string,
                                2,
                              )}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center justify-between py-2 text-sm leading-tight text-[#6A6A8C] dark:text-[#625E8F]'>
                      <div>Minting Limit Used</div>
                      <div className='translate-x-[-0.9px] flex items-center gap-1'>
                        {formatNumber(borrowLimitUsedPercent)}%
                        {isValidAmountRequest && (
                          <>
                            <FutureStatsRightArrow />
                            {`${formatNumber(
                              nextBorrowLimitUsedPercentage as number,
                              2,
                            )}%`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/* Action Button */}
                <div className='mt-[14.3px]'>
                  <HintTransaction>
                    <TransactionButton
                      text={actionButton.label}
                      disabled={actionButton.disabled}
                      customTheme={(disabled) => {
                        if (disabled) {
                          return 'cursor-not-allowed bg-[#D0E4F6] text-[#6A6A8C] dark:!bg-[#676198] dark:!text-white';
                        }

                        return 'text-white [background:linear-gradient(90deg,#8276DC_0%,#74CFB1_100%)]';
                      }}
                    />
                  </HintTransaction>
                </div>

                {/* Bottom Note */}
                <div className='mt-[9.2px] space-y-1.5 text-sm text-[#6A6A8C] dark:text-[#625E8F]'>
                  {footerNotes[selectedTab].map(({ label, value }) => {
                    return (
                      <div key={label} className='flex justify-between'>
                        <span>{label}</span>

                        <span className='inline-block translate-x-[-0.5px]'>
                          {value}
                        </span>
                      </div>
                    );
                  })}

                  <OperationFormBottomAlerts />
                </div>

                {selectedTab === TABS.ADD_COLLATERAL &&
                  hasFunds &&
                  isValidInput &&
                  new DefiUtils(inputValue).isLessThan(
                    requestAmounts[selectedTab].minValue,
                  ) && (
                    <div className='mt-[10px] bg-[#B6E9FF] text-[#6A6A8C] rounded-[8px] p-[6px] text-[12px] font-semibold text-center'>
                      <InfoIcon
                        className='dark:text-[#625E8F] text-[#5C5C5C] mr-[4px] translate-y-[-1px]  inline-block text-[9px]'
                        strokeWidth='1.5'
                      />
                      The minimum amount you can supply is{' '}
                      <span
                        className='hover:underline cursor-pointer'
                        onClick={() => {
                          setInputValue(requestAmounts[selectedTab].minValue);
                        }}
                      >
                        {formatNumber(
                          requestAmounts[selectedTab].minValue,
                          new DefiUtils(
                            new DefiUtils(
                              requestAmounts[selectedTab].minValue,
                            ).toSafeFixed(8, DefiUtils.ROUND_UP),
                          ).decimalPlaces() || 0,
                        )}
                      </span>{' '}
                      <span className='text-[#3F3F62]'>
                        {isolatedToken.symbol}
                      </span>
                      .
                    </div>
                  )}

                {selectedTab === TABS.REMOVE_COLLATERAL &&
                  hasFunds &&
                  isValidInput && (
                    <>
                      {new DefiUtils(inputValue).isLessThan(
                        requestAmounts[selectedTab].minValue,
                      ) ? (
                        <div className='mt-[10px] bg-[#B6E9FF] text-[#6A6A8C] rounded-[8px] p-[6px] text-[12px] font-semibold text-center'>
                          <InfoIcon
                            className='dark:text-[#625E8F] text-[#5C5C5C] mr-[4px] translate-y-[-1px]  inline-block text-[9px]'
                            strokeWidth='1.5'
                          />
                          The minimum amount you can withdraw is{' '}
                          <span
                            className='hover:underline cursor-pointer'
                            onClick={() => {
                              setInputValue(
                                requestAmounts[selectedTab].minValue,
                              );
                            }}
                          >
                            {formatNumber(
                              requestAmounts[selectedTab].minValue,
                              new DefiUtils(
                                new DefiUtils(
                                  requestAmounts[selectedTab].minValue,
                                ).toSafeFixed(8, DefiUtils.ROUND_UP),
                              ).decimalPlaces() || 0,
                            )}
                          </span>{' '}
                          <span className='text-[#3F3F62]'>
                            {isolatedToken.symbol}
                          </span>
                          .
                        </div>
                      ) : new DefiUtils(
                          finalCollateralBalanceTokens,
                        ).isGreaterThan(0) &&
                        new DefiUtils(finalCollateralBalanceTokens).isLessThan(
                          minCollateralTokens,
                        ) ? (
                        <div className='mt-[10px] bg-[#B6E9FF] text-[#6A6A8C] rounded-[8px] p-[6px] text-[12px] font-semibold text-center'>
                          <InfoIcon
                            className='dark:text-[#625E8F] text-[#5C5C5C] mr-[4px] translate-y-[-1px]  inline-block text-[9px]'
                            strokeWidth='1.5'
                          />
                          The minimum amount you can keep as collateral is{' '}
                          <span>
                            {formatNumber(
                              minCollateralTokens,
                              new DefiUtils(
                                minCollateralTokens,
                              ).decimalPlaces() || 0,
                            )}
                          </span>{' '}
                          <span className='text-[#3F3F62]'>
                            {isolatedToken.symbol}
                          </span>
                          .
                        </div>
                      ) : (
                        !actionButton.disabled &&
                        isolatedToken.defaultSymbol === 'EGLD' && (
                          <div className='mt-[10px] bg-[#B6E9FF] text-[#6A6A8C] rounded-[8px] p-[6px] text-[12px] font-semibold text-center'>
                            <InfoIcon
                              className='dark:text-[#625E8F] text-[#5C5C5C] mr-[4px] translate-y-[-1px]  inline-block text-[9px]'
                              strokeWidth='1.5'
                            />
                            When you withdraw{' '}
                            <span className='text-[#3F3F62]'>EGLD</span>, you’ll
                            receive an{' '}
                            <span className='text-[#3F3F62]'>NFT</span> that
                            allows you
                            <br /> to claim your{' '}
                            <span className='text-[#3F3F62]'>
                              EGLD
                            </span> after{' '}
                            <span className='text-[#3F3F62]'>10 days</span>.
                          </div>
                        )
                      )}
                    </>
                  )}

                {/* {actionButton.disabled &&
                  selectedTab === TABS.REMOVE_COLLATERAL &&
                  borrowBalanceUSD !== '0' && (
                    <div className='mt-[10px] bg-[#B6E9FF] text-[#6A6A8C] rounded-[8px] p-[6px] text-[12px] font-semibold text-center'>
                      <InfoIcon
                        className='dark:text-[#625E8F] text-[#5C5C5C] mr-[4px] translate-y-[-1px]  inline-block text-[9px]'
                        strokeWidth='1.5'
                      />
                      When you still have{' '}
                      <span className='text-[#3F3F62]'>USH</span> minted, you
                      are required to <br className='hidden lg:block' />
                      keep a minimum of{' '}
                      {formatNumber(
                        minCollateralTokens,
                        new DefiUtils(minCollateralTokens).decimalPlaces() || 0,
                      )}{' '}
                      <span className='text-[#3F3F62]'>
                        {isolatedToken.symbol}
                      </span>{' '}
                      as collateral.
                    </div>
                  )}*/}
              </div>
            </Tabber>
          </div>
        </>
      </form>
    </PopupBed>
  );
};

export default UshCollateralPopup;
