import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatAccountRewardsWithoutIndexer,
  formatBoosterAccumulators,
  formatClaims,
  getAccumulatorsPairsIds,
} from '@/store/parsers/booster-parser';
import { addAction } from '@/store/queue';

import * as airdropService from '@/services/airdrop';
import blockchainService from '@/services/blockchain';
import * as xexchangeService from '@/services/xexchange';
import logger from '@/utils/logger';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export interface TTotalCollateralTokens {
  [key: string]: string;
}

export enum X_EXCHANGE_TOKEN_KEY {
  HTM = 'HTM',
  HTM_MT = 'HTM_MT',
  HTM_WEGLD = 'HTM_WEGLD',
  HTM_WEGLD_MT = 'HTM_WEGLD_MT',
  HTM_WEGLD_LF = 'HTM_WEGLD_LF',
}
export interface XExchangeToken {
  accountBalance: string;
  stakedBalance: string;
  priceUSD: string;
  decimals: number;
  name: string;
  logo: string;
  id: string;
  description: string;
}

export interface XExchange {
  energy: {
    address: string;
    accountBalance: string;
    multiplier: string;
    maxMultiplier: string;
  };
  tokens: Record<X_EXCHANGE_TOKEN_KEY, XExchangeToken>;
}

export interface BoosterAccount {
  speed: string;
  amount: string;
  reward: {
    price: string;
    decimals: number;
    token: string;
    symbol: string;
  };
  marketBooster: {
    priceIntegral: string;
    priceIntegralTimestamp: number;
  };
  accountMarketBooster: {
    staked: string;
    priceIntegral: string;
    priceIntegralTimestamp: number;
  };
  totalBoosterApy: string;
  accountBoosterApy: string;
  accountBoosterAPYWithPenalty: string;
  maxAccountBoosterApy: string;
  hasCollateral: boolean;
  percentageBoost: string;
}

export interface AccountBoosterReward {
  tokenId: string;
  userRewardBalance: string;
  userRewardBalanceUSD: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: number;
}

export const fakeBoosterAccount: Record<string, BoosterAccount> = {
  WTAO: {
    speed: '1',
    amount: '0',
    reward: {
      price: '1',
      decimals: 6,
      token: 'USDC',
      symbol: 'USDC',
    },
    marketBooster: {
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    accountMarketBooster: {
      staked: '0',
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    totalBoosterApy: '93600',
    accountBoosterApy: '93600',
    accountBoosterAPYWithPenalty: '93600',
    maxAccountBoosterApy: '93600',
    hasCollateral: false,
    percentageBoost: '0',
  },
  SWTAO: {
    speed: '1',
    amount: '0',
    reward: {
      price: '1',
      decimals: 6,
      token: 'USDC',
      symbol: 'USDC',
    },
    marketBooster: {
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    accountMarketBooster: {
      staked: '0',
      priceIntegral: '0',
      priceIntegralTimestamp: new Date().getTime(),
    },
    totalBoosterApy: '50400',
    accountBoosterApy: '50400',
    accountBoosterAPYWithPenalty: '50400',
    maxAccountBoosterApy: '50400',
    hasCollateral: false,
    percentageBoost: '0',
  },
};

export interface BoosterBatchState {
  /**
   * @deprecated by boosterV2
   */
  boosterAccount: Record<string, BoosterAccount>;
  /**
   * @deprecated by boosterV2
   */
  totalBoosterCollateralMoneyMarkets: TTotalCollateralTokens;
  /**
   * @deprecated by boosterV2
   */
  totalStaked: {
    totalStaked: string;
    totalStakedUsd: string;
  };
  /**
   * @deprecated by boosterV2
   */
  percentageBoost: string;
  claims: {
    id: string;
    claimTimestamp: string;
    claimed: boolean;
    amount: string;
  }[];
  /**
   * @deprecated by boosterV2
   */
  hasStaked: boolean;
  /**
   * @deprecated by boosterV2
   */
  maximumApyBooster: string;
  boosterAccumulators: {
    [key: string]: {
      exchangeRate: string;
      premium: string;
    };
  };
  slippage: string;
  /**
   * @deprecated by boosterV2
   */
  needRebalance: boolean;
  accountSnapshots: AccountSnapshot[];
  accountRewards: AccountBoosterReward[];
  accountBoosterMarkets: string[];
  xExchange: XExchange;
}

interface AccountSnapshot {
  snapshot: {
    date: string;
    id: string;
    totalCollateralUSD: string;
    totalBoostCollateralUSD: string;
  };
  totalCollateralUSD: string;
  totalBoostCollateralUSD: string;
  totalStakedUSD: string;
  percentageBoost: string;
  hasUshReward: boolean;
  collateralPercentage: string;
  poolShare: string;
}

const initialState: BoosterBatchState = {
  boosterAccount: {},
  totalBoosterCollateralMoneyMarkets: {} as TTotalCollateralTokens,
  totalStaked: {
    totalStaked: '0',
    totalStakedUsd: '0',
  },
  percentageBoost: '0',
  claims: {} as BoosterBatchState['claims'],
  hasStaked: false,
  maximumApyBooster: '0.1',
  boosterAccumulators: {},
  slippage: '0',
  needRebalance: false,
  accountSnapshots: [],
  accountRewards: [],
  accountBoosterMarkets: [],
};

export const boosterSlice = createSlice({
  name: 'booster',
  initialState,
  reducers: {
    setBooster: (state, action: PayloadAction<Partial<BoosterBatchState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof BoosterBatchState] = value;
      });
    },
  },
});

export const { setBooster } = boosterSlice.actions;

export const getBoosterData =
  ({ accountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { markets, tokensMap },
      } = getState();

      dispatch(getAccountSnapshots({ accountAddress })); // this will keep it asynchronous
      dispatch(getBoosterAccumulators()); // this will keep it asynchronous

      const [
        accountBoosterRewards,
        boosterAccountClaims,
        accountBoosterMarkets,
      ] = await Promise.all([
        blockchainService.lens.getAccountBoosterV1Rewards(accountAddress),
        blockchainService.lens.getBoosterV1AccountClaims(accountAddress),
        blockchainService.lens.getAccountBoosterV1Markets(accountAddress),
      ]);

      const rewardsPrices = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.symbol]: current.underlying.priceUSD,
        }),
        {} as Record<string, string>,
      );

      await dispatch(
        addAction(
          setBooster({
            claims: formatClaims({ boosterAccountClaims, accountAddress }),
            accountRewards: formatAccountRewardsWithoutIndexer({
              accountBoosterRewards,
              tokensMap,
              rewardsPrices,
            }),
            accountBoosterMarkets,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getBoosterBatch', error);
      captureException(error);
    }
  };

const getAccountSnapshots =
  ({ accountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const { boosterBatch } = getState();

      if (accountAddress.length === 0) {
        dispatch(
          setBooster({
            accountSnapshots: [],
          }),
        );
        return;
      }

      if (boosterBatch.accountSnapshots.length !== 0) {
        return;
      }

      const responseSnapshots = await queryClient.fetchQuery({
        queryKey: ['snapshots'],
        queryFn: async () => {
          return airdropService.getAllSnapshots();
        },
        cacheTime: MAX_CACHE_TIME,
        staleTime: MAX_CACHE_TIME,
      });

      const responseAccountSnapshot = await queryClient.fetchQuery({
        queryKey: ['accountSnapshots', accountAddress],
        queryFn: async () => {
          return airdropService.getAccountByAddress(accountAddress);
        },
        cacheTime: MAX_CACHE_TIME,
        staleTime: MAX_CACHE_TIME,
      });

      const accountSnapshotMap = responseAccountSnapshot.reduce(
        (prev, current) => {
          return {
            ...prev,
            [current.snapshotId]: current,
            [current.date]: current,
          };
        },
        {} as Record<string, airdropService.AccountSnapshotByAddress>,
      );

      const accountSnapshots = responseSnapshots
        .map((snapshotItem) => {
          const accountSnapshotItem =
            accountSnapshotMap[snapshotItem.snapshotId] ||
            accountSnapshotMap[snapshotItem.date] ||
            {};

          const {
            hasUshReward = false,
            totalCollateralUSD = '0',
            totalBoostCollateralUSD = '0',
            totalStakedUSD = '0',
            percentageBoost = '0',
          } = accountSnapshotItem;

          const { airdropTotalBoostCollateralUSD } = snapshotItem;

          return {
            snapshot: {
              date: new Date(parseInt(snapshotItem.timestamp)).toISOString(),
              id: snapshotItem.snapshotId,
              totalCollateralUSD: snapshotItem.airdropTotalCollateralUSD,
              totalBoostCollateralUSD: airdropTotalBoostCollateralUSD,
            },
            totalCollateralUSD,
            totalBoostCollateralUSD,
            totalStakedUSD,
            percentageBoost,
            hasUshReward,
            collateralPercentage: new DefiUtils(totalStakedUSD)
              .dividedBy(totalBoostCollateralUSD)
              .multipliedBy(100)
              .toSafeString(),
            poolShare: hasUshReward
              ? new DefiUtils(totalBoostCollateralUSD)
                  .dividedBy(airdropTotalBoostCollateralUSD)
                  .multipliedBy(100)
                  .toSafeString()
              : '0',
          };
        })
        .sort(
          (a, b) =>
            new Date(b.snapshot.date).getTime() -
            new Date(a.snapshot.date).getTime(),
        );

      dispatch(
        setBooster({
          accountSnapshots,
        }),
      );
    } catch (error) {
      logger.error('store:getAccountSnapshots', error);
      captureException(error);
    }
  };

const getBoosterAccumulators =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { markets },
      } = getState();
      const boosterAccumulators =
        await blockchainService.lens.getBoosterV1Accumulators();

      const tokensMap = Object.values(markets).reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.id]: current.underlying,
        }),
        {} as Record<
          string,
          {
            id: string;
            name: string;
            decimals: number;
            symbol: string;
          }
        >,
      );

      const accumulators = boosterAccumulators.map(
        ({ premium, tokenId, swapPath }) => {
          return {
            premium,
            rewardsToken: {
              id: tokenId,
              symbol: tokensMap[tokenId]?.symbol || '',
            },
            swapPath: swapPath.map(({ outputTokenId }) => ({
              id: outputTokenId,
            })),
          };
        },
      );

      const pairsIds = getAccumulatorsPairsIds(accumulators);

      // @ts-ignore
      const uniquePairsIds = [...new Set(pairsIds)];

      const pairs = await xexchangeService.getPairs(uniquePairsIds);

      await dispatch(
        setBooster({
          boosterAccumulators: formatBoosterAccumulators({
            pairs,
            accumulators,
          }),
        }),
      );
    } catch (error) {
      logger.error('store:getBoosterBatch', error);
      captureException(error);
    }
  };

export const boosterSelector = (state: RootState) => state.boosterBatch;

export default boosterSlice.reducer;
