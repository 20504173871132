import { addDays, subDays } from 'date-fns';

import { MARKET_KEY } from '@/store/protocol';

import { QueryHistoryPoint } from '@/services/indexer/market/types';
import { calcMarketAPY } from '@/utils/math/apy';
import { calcExchangeRate } from '@/utils/math/market';

const getDailyStateHistoryMap = (
  queryHistoryPointResponse: QueryHistoryPoint,
) =>
  (queryHistoryPointResponse &&
  queryHistoryPointResponse.getMoneyMarket &&
  queryHistoryPointResponse.getMoneyMarket.dailyStateHistory
    ? queryHistoryPointResponse.getMoneyMarket.dailyStateHistory
    : []
  ).reduce((prev, { moneyMarketState }) => {
    const timestamp = new Date(
      `${
        new Date(moneyMarketState?.timestamp || 0).toISOString().split('T')[0]
      }T00:00:00.000Z`,
    ).toISOString();

    return {
      ...prev,
      [timestamp]: moneyMarketState,
    };
  }, {}) as Record<
    string,
    {
      totalSupply: string;
      borrows: string;
      exchangeRate: string;
      cash: string;
      reserves: string;
      supplyRatePerSecond: string;
      borrowRatePerSecond: string;
      timestamp: string;
    }
  >;

const getDailyPriceHistoryMap = (
  queryHistoryPointResponse: QueryHistoryPoint,
) =>
  (queryHistoryPointResponse &&
  queryHistoryPointResponse.getMoneyMarket &&
  queryHistoryPointResponse.getMoneyMarket.underlying &&
  queryHistoryPointResponse.getMoneyMarket.underlying.dailyPriceHistory
    ? queryHistoryPointResponse.getMoneyMarket.underlying.dailyPriceHistory
    : []
  ).reduce((prev, { quote }) => {
    const timestamp = new Date(
      `${
        new Date(quote?.timestamp || 0).toISOString().split('T')[0]
      }T00:00:00.000Z`,
    ).toISOString();

    return {
      ...prev,
      [timestamp]: { priceInEgld: quote?.priceInEgld || '0' },
    };
  }, {}) as Record<string, { priceInEgld: string; timestamp: string }>;

const getTodayDateString = () =>
  addDays(
    new Date(`${new Date().toISOString().split('T')[0]}T00:00:00.000Z`),
    1,
  ).toISOString();

const getHistoryPointsDays = (first: number) => {
  const todayDateString = getTodayDateString();

  return new Array(first).fill(0).map(
    (_, index) =>
      `${
        subDays(new Date(todayDateString), index + 1)
          .toISOString()
          .split('T')[0]
      }T00:00:00.000Z`,
  );
};

export const formatHistoryPoints = (
  queryHistoryPointResponse: QueryHistoryPoint,
  assetKey: string,
  first: number,
) => {
  const dailyStateHistoryMap = getDailyStateHistoryMap(
    queryHistoryPointResponse,
  );

  const dailyPriceHistoryMap = getDailyPriceHistoryMap(
    queryHistoryPointResponse,
  );

  const days = getHistoryPointsDays(first);

  return days.map((day) => {
    const {
      cash = '0',
      borrows = '0',
      totalSupply = '0',
      supplyRatePerSecond: supplyRate = '0',
      borrowRatePerSecond: borrowRate = '0',
      reserves = '0',
    } = dailyStateHistoryMap[day] || {};

    const { priceInEgld = '0' } = dailyPriceHistoryMap[day] || {};

    const exchangeRate = calcExchangeRate({
      cash,
      borrows,
      reserves,
      totalSupply,
    });

    const borrowAPY = calcMarketAPY(borrowRate);
    const supplyAPY = calcMarketAPY(supplyRate);

    const safePriceInEgld = assetKey === MARKET_KEY.EGLD ? '1' : priceInEgld;

    return {
      totalBorrows: borrows,
      supplyRate,
      borrowRate,
      exchangeRate,
      totalSupply,
      borrowAPY,
      supplyAPY,
      priceInEgld: safePriceInEgld,
      timestamp: day,
    };
  });
};
