import { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';

import useMediaQuery from '@/hooks/useMediaQuery';

import DataRow from '@/components/popups/LendingCurrentPositionPopup/components/DataRow';

import clsxm from '@/services/clsxm';

export default function DataRowList({
  data = [],
  // eslint-disable-next-line unused-imports/no-unused-vars
  isHovered = (id: string | number) => {},
}) {
  const ref = useRef<HTMLDivElement>(null);

  const [canScroll, setCanScroll] = useState(false);
  const [scrollInProgress, setScrollInProgress] = useState(false);

  useEffect(() => {
    if (!ref?.current) return;
    setCanScroll(ref.current.scrollHeight > ref.current.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current, ref.current?.scrollHeight]);

  const md = useMediaQuery('(min-width: 768px)');

  return (
    <>
      <style jsx global>
        {`
          .data-row-list .simplebar-track.simplebar-vertical {
            right: -16px;
            width: 10px;
            opacity: 0.5;
          }
        `}
      </style>
      <div className='relative isolate -ml-3 sm:mr-0'>
        {scrollInProgress && (
          <div className='pointer-events-none absolute inset-x-0 -top-px z-10 h-3 bg-gradient-to-b from-white to-transparent dark:from-[#191733]' />
        )}

        {canScroll && (
          <div className='pointer-events-none absolute inset-x-0 -bottom-px z-10 h-3 bg-gradient-to-b from-transparent to-white dark:to-[#191733]' />
        )}
        <SimpleBar
          data-body-block-scroll
          scrollableNodeProps={{ ref: ref, [`data-body-block-scroll`]: true }}
          onScrollCapture={(_) => {
            setScrollInProgress((ref.current?.scrollTop || 0) > 2);
          }}
          className={clsxm('data-row-list pb-3')}
          style={{
            maxHeight: md ? 186 : 100,
          }}
        >
          <div className='space-y-3'>
            {data.map((r: any, ri: number) => (
              <DataRow hovered={isHovered(r.id)} key={ri} {...r} />
            ))}
          </div>
        </SimpleBar>
      </div>
    </>
  );
}
