import DefiUtils from 'defi-utils';

import {
  Blockchain,
  defaultLogo,
  H_MARKET_KEY,
  H_TOKEN_DECIMALS,
  LiquidStaking,
  LiquidStakingTao,
  Market,
  MARKET_KEY,
  ProtocolState,
  TOKEN_LOGO_MAP,
} from '@/store/protocol';

import { AccountDiscountRateModelData } from '@/services/blockchain/lens/types';
import {
  ResponseProtocol,
  ResponseProtocolAccountMarket,
  ResponseProtocolUpdated,
} from '@/services/blockchain/protocol/types';
import { NetworkStatus } from '@/services/gateway/network/types';
import { ResponseTokenPricesTuple } from '@/services/indexer/common/types';
import { calcMarketAPY } from '@/utils/math/apy';
import { calcSimulateExchangeRate } from '@/utils/math/market';

const formatMarket = (
  market: ResponseProtocol['markets']['BUSD'],
  marketPricesMap: Record<string, string>,
): Market => {
  const {
    underlying,
    hToken,
    liquidityCap,
    borrowCap,
    ushBorrowerCollateralFactor,
    address,
    collateralFactor,
    supported,
    liquidationIncentive,
    mintStatus,
    borrowStatus,
    totalSupply,
    totalBorrows: totalBorrow,
    borrowRatePerSecond,
    supplyRatePerSecond,
    cash,
    totalReserves,
    accrualTimestamp,
    exchangeRate,
  } = market;

  const defaultHTokenExchangeRate = calcSimulateExchangeRate({
    cash,
    borrows: totalBorrow,
    reserves: totalReserves,
    totalSupply: totalSupply,
    rate: supplyRatePerSecond,
    timestamp:
      new Date(
        new DefiUtils(accrualTimestamp).multipliedBy(1000).toNumber(),
      ).toISOString() || new Date().toISOString(),
  });

  const hTokenExchangeRate = new DefiUtils(defaultHTokenExchangeRate).isZero()
    ? exchangeRate
    : defaultHTokenExchangeRate;

  const updatedUnderlying = {
    ...underlying,
    priceUSD:
      marketPricesMap[underlying.id] ||
      marketPricesMap[underlying.symbol] ||
      '0',
  };

  const updatedHToken = {
    ...hToken,
    priceUSD: '0',
  };

  updatedHToken.priceUSD = new DefiUtils(1)
    .toBasicUnits(hToken.decimals)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(underlying.decimals)
    .toUSD(updatedUnderlying.priceUSD)
    .toSafeFixed(underlying.decimals);

  const s_supplyCap =
    liquidityCap === null
      ? new DefiUtils(Infinity).toString()
      : new DefiUtils(liquidityCap)
          .toFullDecimals(updatedUnderlying.decimals)
          .toString();

  const s_borrowCap =
    borrowCap === null
      ? new DefiUtils(Infinity).toString()
      : new DefiUtils(borrowCap)
          .toFullDecimals(updatedUnderlying.decimals)
          .toString();

  return {
    address,
    collateralFactor,
    supported,
    liquidationIncentive,
    mintStatus,
    borrowStatus,
    cash,
    underlying: updatedUnderlying,
    hToken: updatedHToken,
    hTokenExchangeRate,
    logo: TOKEN_LOGO_MAP[underlying.symbol] || defaultLogo,
    totalSupply,
    totalBorrow,
    ushBorrowerCollateralFactor,
    totalBorrowUSD: new DefiUtils(totalBorrow)
      .toFullDecimals(updatedUnderlying.decimals)
      .toUSD(updatedUnderlying.priceUSD)
      .toString(),
    totalSupplyUSD: new DefiUtils(totalSupply)
      .toUnderlying(hTokenExchangeRate)
      .toFullDecimals(updatedUnderlying.decimals)
      .toUSD(updatedUnderlying.priceUSD)
      .toString(),
    borrowRatePerSecond,
    supplyRatePerSecond,
    borrowAPY: calcMarketAPY(borrowRatePerSecond),
    supplyAPY: calcMarketAPY(supplyRatePerSecond),
    supplyCap: s_supplyCap,
    borrowCap: s_borrowCap,
    mintEnabled: mintStatus === 'Active',
    borrowEnabled: borrowStatus === 'Active',
  };
};

export const formatProtocol = (
  protocol: ResponseProtocol,
  marketPricesMap: Record<string, string>,
  {
    status: {
      erd_nonce: blockNonce,
      erd_epoch_number: epoch,
      erd_rounds_passed_in_current_epoch: roundsPassed,
      erd_rounds_per_epoch: roundsPerEpoch,
    },
  }: NetworkStatus,
) => {
  const markets = {
    ...Object.values(protocol.markets).reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.underlying.symbol]: formatMarket(current, marketPricesMap),
        };
      },
      {} as Record<MARKET_KEY, Market>,
    ),
  };

  return {
    ...protocol,
    liquidStakingTao: {
      ...protocol.liquidStakingTao,
      totalStakers: '0',
      totalStakedUSD: new DefiUtils(protocol.liquidStakingTao.totalStaked)
        .toFullDecimals(markets?.WTAO?.underlying?.decimals || 18)
        .toUSD(marketPricesMap?.WTAO || 0)
        .toSafeString(),
    } as LiquidStakingTao,
    liquidStaking: {
      ...protocol.liquidStaking,
      totalStakedUSD: new DefiUtils(protocol.liquidStaking.totalStaked)
        .toFullDecimals(markets?.EGLD?.underlying?.decimals || 18)
        .toUSD(marketPricesMap?.EGLD || 0)
        .toSafeString(),
      segldStakers: '0',
      hsegldStakers: '0',
      totalStakers: '0',
    } as LiquidStaking,
    markets,
    governance: { ...protocol.governance, blockNonce },
    blockchain: {
      initialRoundsPassed: roundsPassed,
      initialEpoch: epoch,
      epoch,
      roundsPassed,
      roundsPerEpoch,
      timeLeftInSeconds: (roundsPerEpoch - roundsPassed) * 6,
      timestamp: new Date().toISOString(),
    } as Blockchain,
  };
};

export const formatProtocolUpdated = (
  protocolState: ProtocolState,
  protocolResponse: ResponseProtocolUpdated,
  marketPricesMap: Record<string, string>,
) => {
  const markets = {
    ...Object.values(protocolResponse.markets).reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.underlying.symbol]: formatMarket(current, marketPricesMap),
        };
      },
      {} as Record<MARKET_KEY, Market>,
    ),
  };

  return {
    booster: {
      ...protocolState.booster,
      ...protocolResponse.booster,
    },
    liquidStakingTao: {
      ...protocolState.liquidStakingTao,
      ...protocolResponse.liquidStakingTao,
      totalStakedUSD: new DefiUtils(
        protocolResponse.liquidStakingTao.totalStaked,
      )
        .toFullDecimals(markets?.WTAO?.underlying?.decimals || 18)
        .toUSD(marketPricesMap?.WTAO || 0)
        .toString(),
    } as LiquidStakingTao,
    liquidStaking: {
      ...protocolState.liquidStaking,
      ...protocolResponse.liquidStaking,
      totalStakedUSD: new DefiUtils(protocolResponse.liquidStaking.totalStaked)
        .toFullDecimals(markets?.EGLD?.underlying?.decimals || 18)
        .toUSD(marketPricesMap?.EGLD || 0)
        .toString(),
    } as LiquidStaking,
    markets,
  };
};

export const formatAccountMarketsIds = (
  markets: Record<MARKET_KEY, Market>,
  accountAddress: string,
) => {
  return accountAddress
    ? Object.values(markets).map(
        ({ address: marketAddress }: { address: string }) =>
          `${marketAddress}-${accountAddress}`.toLowerCase(),
      )
    : [];
};

export const formatMarketUnderlyingIds = (
  markets: Record<MARKET_KEY, { underlying: { id: string } }>,
) => {
  return Object.values(markets).map(({ underlying }) => underlying.id);
};

export const formatUserBalances = (
  markets: Record<string, Market>,
  accountMarkets: ResponseProtocolAccountMarket[],
) => {
  const accountMarketsMap = accountMarkets.reduce(
    (
      prev,
      { market, collateralTokens: collateralBalance, currentBorrowAmount },
    ) => {
      const hTokenExchangeRate =
        markets[market.underlying.symbol as MARKET_KEY]?.hTokenExchangeRate ||
        '0';

      const borrowBalance = currentBorrowAmount || '0';

      return {
        ...prev,
        [market.underlying.symbol]: {
          borrowBalance,
          collateralBalance,
          underlyingCollateralBalance: new DefiUtils(collateralBalance)
            .toUnderlying(hTokenExchangeRate)
            .toString(),
        },
      };
    },
    {} as Record<
      string,
      {
        collateralBalance: string;
        borrowBalance: string;
        underlyingCollateralBalance: string;
      }
    >,
  );

  return Object.keys(markets).reduce(
    (prev, current) => ({
      ...prev,
      [current]: accountMarketsMap[current] || {
        collateralBalance: '0',
        underlyingCollateralBalance: '0',
        borrowBalance: '0',
      },
    }),
    {} as Record<
      string,
      {
        borrowBalance: string;
        collateralBalance: string;
        underlyingCollateralBalance: string;
      }
    >,
  );
};

export const formatExchangeRates = (
  markets: Record<string, Market>,
  liquidStaking: LiquidStaking,
  liquidStakingTao: LiquidStakingTao,
) => {
  const nativeMarket = markets[MARKET_KEY.EGLD] || {
    underlying: { decimals: 18 },
    hToken: { decimals: H_TOKEN_DECIMALS },
  };
  const wtaoMarket = markets[MARKET_KEY.WTAO] || {
    underlying: { decimals: 9 },
    hToken: { decimals: H_TOKEN_DECIMALS },
  };
  const swtaoMarket = markets[MARKET_KEY.sWTAO] || {
    underlying: { decimals: 18 },
    hToken: { decimals: H_TOKEN_DECIMALS },
  };
  const sEgldMarket = markets[MARKET_KEY.sEGLD] || {
    hTokenExchangeRate: '0',
    underlying: { decimals: 18 },
    hToken: { decimals: H_TOKEN_DECIMALS },
  };

  const underlyingToHToken = Object.values(markets).reduce(
    (prev, { underlying, hToken, hTokenExchangeRate, supported }) => {
      return {
        ...prev,
        [`${underlying.symbol}/${hToken.symbol}`]: !supported
          ? '0'
          : new DefiUtils(1)
              .toBasicUnits(underlying.decimals)
              .toTokens(hTokenExchangeRate)
              .toFullDecimals(hToken.decimals)
              .toSafeFixed(hToken.decimals, DefiUtils.ROUND_DOWN),
      };
    },
    {} as Record<string, string>,
  );

  const hTokenToUnderlying = Object.values(markets).reduce(
    (prev, { underlying, hToken, hTokenExchangeRate, supported }) => {
      return {
        ...prev,
        [`${hToken.symbol}/${underlying.symbol}`]: !supported
          ? '0'
          : new DefiUtils(1)
              .toBasicUnits(hToken.decimals)
              .toUnderlying(hTokenExchangeRate)
              .toFullDecimals(underlying.decimals)
              .toSafeFixed(underlying.decimals, DefiUtils.ROUND_DOWN),
      };
    },
    {} as Record<string, string>,
  );

  const underlyingToUnderlying = Object.values(markets).reduce(
    (prev, { underlying }) => {
      return {
        ...prev,
        [`${underlying.symbol}/${underlying.symbol}`]: '1',
      };
    },
    {} as Record<string, string>,
  );

  const hTokenToHToken = Object.values(markets).reduce(
    (prev, { hToken }) => {
      return {
        ...prev,
        [`${hToken.symbol}/${hToken.symbol}`]: '1',
      };
    },
    {} as Record<string, string>,
  );

  const liquidStakingExchangeRates = {
    [`${MARKET_KEY.EGLD}/${MARKET_KEY.sEGLD}`]: new DefiUtils(1)
      .toBasicUnits(nativeMarket.underlying.decimals)
      .toTokens(liquidStaking.exchangeRate)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toSafeFixed(sEgldMarket.underlying.decimals, DefiUtils.ROUND_DOWN),
    [`${MARKET_KEY.EGLD}/${H_MARKET_KEY.HsEGLD}`]: new DefiUtils(1)
      .toBasicUnits(nativeMarket.underlying.decimals)
      .toTokens(liquidStaking.exchangeRate)
      .toTokens(sEgldMarket.hTokenExchangeRate)
      .toFullDecimals(sEgldMarket.hToken.decimals)
      .toSafeFixed(sEgldMarket.hToken.decimals, DefiUtils.ROUND_DOWN),
    [`${MARKET_KEY.sEGLD}/${MARKET_KEY.EGLD}`]: new DefiUtils(1)
      .toUnderlying(liquidStaking.exchangeRate)
      .toSafeFixed(sEgldMarket.underlying.decimals, DefiUtils.ROUND_DOWN),
    [`${H_MARKET_KEY.HsEGLD}/${MARKET_KEY.EGLD}`]: new DefiUtils(1)
      .toBasicUnits(sEgldMarket.hToken.decimals)
      .toUnderlying(sEgldMarket.hTokenExchangeRate)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toUnderlying(liquidStaking.exchangeRate)
      .toSafeFixed(sEgldMarket.underlying.decimals, DefiUtils.ROUND_DOWN),
  };

  const liquidStakingTaoExchangeRates = {
    [`${MARKET_KEY.WTAO}/${MARKET_KEY.sWTAO}`]: new DefiUtils(1)
      .toBasicUnits(wtaoMarket.underlying.decimals)
      .toTokens(liquidStakingTao.exchangeRate)
      .toFullDecimals(swtaoMarket.underlying.decimals)
      .toSafeFixed(swtaoMarket.underlying.decimals, DefiUtils.ROUND_DOWN),

    [`${MARKET_KEY.WTAO}/${H_MARKET_KEY.HsWTAO}`]: new DefiUtils(1)
      .toBasicUnits(wtaoMarket.underlying.decimals)
      .toTokens(liquidStakingTao.exchangeRate)
      .toTokens(swtaoMarket.hTokenExchangeRate)
      .toFullDecimals(swtaoMarket.hToken.decimals)
      .toSafeFixed(swtaoMarket.hToken.decimals, DefiUtils.ROUND_DOWN),

    [`${MARKET_KEY.sWTAO}/${MARKET_KEY.WTAO}`]: new DefiUtils(1)
      .toUnderlying(liquidStakingTao.exchangeRate)
      .toSafeFixed(swtaoMarket.underlying.decimals, DefiUtils.ROUND_DOWN),

    [`${H_MARKET_KEY.HsWTAO}/${MARKET_KEY.WTAO}`]: new DefiUtils(1)
      .toBasicUnits(swtaoMarket.hToken.decimals)
      .toUnderlying(swtaoMarket.hTokenExchangeRate)
      .toFullDecimals(swtaoMarket.underlying.decimals)
      .toUnderlying(liquidStakingTao.exchangeRate)
      .toSafeFixed(swtaoMarket.underlying.decimals, DefiUtils.ROUND_DOWN),
  };

  return {
    ...underlyingToHToken,
    ...hTokenToUnderlying,
    ...underlyingToUnderlying,
    ...hTokenToHToken,
    ...liquidStakingExchangeRates,
    ...liquidStakingTaoExchangeRates,
  };
};

export const formatMarketsInteracted = ({
  accountMarketsList,
  markets,
  userBalances,
}: {
  accountMarketsList: string[];
  markets: Record<MARKET_KEY, Market>;
  userBalances: Record<
    string,
    {
      borrowBalance: string;
      collateralBalance: string;
      underlyingCollateralBalance: string;
    }
  >;
}) => {
  return accountMarketsList.map((moneyMarketAddress) => {
    const marketItem = Object.values(markets).find(
      ({ address }) => address === moneyMarketAddress,
    );
    const symbol = marketItem?.underlying.symbol || '';
    const balances = userBalances[symbol];

    return {
      address: moneyMarketAddress,
      canRemove:
        balances?.borrowBalance === '0' && balances?.collateralBalance === '0',
      borrowBalance: balances?.borrowBalance,
      collateralBalance: balances?.collateralBalance,
      symbol,
    };
  });
};

export const formatMarketPriceTuple = (
  marketPricesTupleResponse: ResponseTokenPricesTuple,
  taoPriceData: { price: string; '24h_change': string },
) => {
  return marketPricesTupleResponse as ResponseTokenPricesTuple;
};

export const formatAccountDiscountRateModel = (
  accountDiscountRateModelData: AccountDiscountRateModelData | undefined,
  markets: Record<MARKET_KEY, Market>,
) => {
  const ushMarket = markets['USH'];

  const s_discount = new DefiUtils(
    accountDiscountRateModelData?.discount || '0',
  )
    .toFullDecimals(18)
    .toString();

  const borrowAPY = new DefiUtils(ushMarket.borrowAPY)
    .multipliedBy(new DefiUtils(1).minus(s_discount))
    .toString();

  const discounts = (accountDiscountRateModelData?.discountData || []).map(
    (accountDiscountRateModelDataItem) => {
      const moneyMarket = Object.values(markets).find(
        (marketItem) =>
          marketItem.address === accountDiscountRateModelDataItem.moneyMarket,
      )?.underlying.symbol as MARKET_KEY;

      const s_discountedUsh = new DefiUtils(
        accountDiscountRateModelDataItem.discountedUsh,
      )
        .toFullDecimals(ushMarket.underlying.decimals)
        .toString();

      return {
        moneyMarket,
        s_discountedUsh,
      };
    },
  );

  return {
    borrowAPY,
    discounts,
  };
};
