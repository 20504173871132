import { SVGProps } from 'react';

const CheckmarkIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width='8'
      height='6'
      viewBox='0 0 8 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.801758 3.00074L2.93431 5.13224L7.19844 0.869141'
        stroke='white'
        strokeWidth='1.54643'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckmarkIcon;
