/* eslint-disable react-hooks/exhaustive-deps */
import {
  AbiRegistry,
  AddressType,
  BigUIntValue,
  BooleanValue,
  Field,
  FieldDefinition,
  List,
  ListType,
  Struct,
  StructType,
  TokenIdentifierType,
  TokenIdentifierValue,
  U32Value,
  U64Value,
} from '@multiversx/sdk-core';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { accountSelector } from '@/store/auth';
import { useAppSelector } from '@/store/index';
import { protocolSelector } from '@/store/protocol';

import boosterV2ABI from '@/abis/booster-v2';

import { ROUTES } from '@/types/enums';

export enum BOOSTER_V2_METHOD {
  STAKE = 'stake',
  UNSTAKE = 'unstake',
  REALLOCATE = 'reallocate',
  CLAIM_REWARDS = 'claimRewards',
  CLAIM_REWARDS_BOOSTER_V2 = 'claimRewardsBoosterV2',
  CLAIM = 'claim',
  STAKE_CLAIM = 'stakeClaim',
}

const useBoosterV2Interaction = () => {
  const { boosterV2, accountManagerDeployer } =
    useAppSelector(protocolSelector);
  const { proxyAddress, selectedTypeAddress } = useAppSelector(accountSelector);
  const router = useRouter();

  const { buildTransaction } = useSignMultipleTransactions();

  const isMainPool = useMemo(
    () =>
      router.route === ROUTES.LEND && selectedTypeAddress === 'proxy'
        ? false
        : true,
    [router.route, selectedTypeAddress],
  );

  const stake = ({
    programId,
    tokenIdentifier,
    amount,
    isMetaEsdt,
    nonce = '0',
  }: {
    programId: number;
    tokenIdentifier: string;
    amount: string;
    isMetaEsdt: boolean;
    nonce?: string;
  }) => {
    const provider = isMainPool
      ? 'booster'
      : proxyAddress
      ? 'account-manager-template'
      : 'account-manager-deployer';

    const commonArgs = {
      func: BOOSTER_V2_METHOD.STAKE,
      group: 'booster-v2',
      isPayable: true,
      ...(isMetaEsdt
        ? {
            metaToken: {
              tokenIdentifier,
              nonce: parseInt(nonce, 16),
              amount,
              isFromBigInteger: true,
            },
          }
        : {
            token: {
              tokenIdentifier,
              amount,
              isFromBigInteger: true,
            },
          }),
      args: [new U32Value(programId)],
    };

    switch (provider) {
      case 'booster': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: boosterV2.address,
        });
      }

      case 'account-manager-deployer': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: accountManagerDeployer.address,
        });
      }

      case 'account-manager-template': {
        return buildTransaction({
          ...commonArgs,
          smartContractAddress: proxyAddress,
        });
      }

      default: {
        throw new Error('Invalid provider');
      }
    }
  };

  const unstake = ({
    programId,
    tokenIdentifier,
    amount,
    pricingMethod,
    nonce = '0',
  }: {
    programId: number;
    tokenIdentifier: string;
    amount: string;
    pricingMethod: 'ReliablePrice' | 'LastPrice';
    nonce?: string;
  }) => {
    const abi = AbiRegistry.create(boosterV2ABI);

    const pricingMethodMap: Record<string, number> = {
      ReliablePrice: 0,
      LastPrice: 1,
    };

    const paymentType = abi.getEndpoint(BOOSTER_V2_METHOD.UNSTAKE).input[1];

    const parameters = (paymentType.type as any)
      .fieldsDefinitions as FieldDefinition[];

    return buildTransaction({
      smartContractAddress: isMainPool ? boosterV2.address : proxyAddress,
      func: BOOSTER_V2_METHOD.UNSTAKE,
      group: 'booster-v2',
      args: [
        new BigUIntValue(programId),
        new Struct(paymentType.type as StructType, [
          new Field(
            new TokenIdentifierValue(tokenIdentifier),
            parameters[0].name,
          ),
          new Field(
            new U64Value(parseInt(nonce || '0', 16)),
            parameters[1].name,
          ),
          new Field(new BigUIntValue(amount), parameters[2].name),
        ]),
        new BigUIntValue(pricingMethodMap[pricingMethod]),
      ],
    });
  };

  const reallocate = ({
    fromProgramId,
    toProgramId,
    tokenIdentifier,
    amount,
    nonce = '0',
  }: {
    fromProgramId: number;
    toProgramId: number;
    tokenIdentifier: string;
    amount: string;
    nonce?: string;
  }) => {
    const abi = AbiRegistry.create(boosterV2ABI);
    const paymentType = abi.getEndpoint(BOOSTER_V2_METHOD.REALLOCATE).input[2];

    const parameters = (paymentType.type as any)
      .fieldsDefinitions as FieldDefinition[];

    return buildTransaction({
      smartContractAddress: isMainPool ? boosterV2.address : proxyAddress,
      func: BOOSTER_V2_METHOD.REALLOCATE,
      group: 'booster-v2',
      args: [
        new BigUIntValue(fromProgramId),
        new BigUIntValue(toProgramId),
        new Struct(paymentType.type as StructType, [
          new Field(
            new TokenIdentifierValue(tokenIdentifier),
            parameters[0].name,
          ),
          new Field(
            new U64Value(parseInt(nonce || '0', 16)),
            parameters[1].name,
          ),
          new Field(new BigUIntValue(amount), parameters[2].name),
        ]),
      ],
    });
  };

  const claimRewards = ({
    programId,
    boost,
    tokenIds,
    minBoostedRewardsOut,
  }: {
    programId: number;
    boost: boolean;
    minBoostedRewardsOut?: string;
    tokenIds: string[];
  }) => {
    return buildTransaction({
      smartContractAddress: isMainPool ? boosterV2.address : proxyAddress,
      func: isMainPool
        ? BOOSTER_V2_METHOD.CLAIM_REWARDS
        : BOOSTER_V2_METHOD.CLAIM_REWARDS_BOOSTER_V2,
      group: 'booster-v2',
      args: [
        new BigUIntValue(programId),
        new BooleanValue(boost),
        new List(
          new ListType(new AddressType()),
          []
        ),
        new List(
          new ListType(new TokenIdentifierType()),
          tokenIds.map((tokenId) => new TokenIdentifierValue(tokenId)),
        ),
        // new BigUIntValue('0'),
        // ...(!minBoostedRewardsOut ||
        // new DefiUtils(minBoostedRewardsOut).isLessThanOrEqualTo(0)
        //   ? []
        //   : [new BigUIntValue(minBoostedRewardsOut)]),
      ],
    });
  };

  const claim = ({
    programId,
    claimId,
  }: {
    programId: number;
    claimId: number;
  }) => {
    return buildTransaction({
      smartContractAddress: isMainPool ? boosterV2.address : proxyAddress,
      func: BOOSTER_V2_METHOD.CLAIM,
      group: 'booster-v2',
      args: [new U32Value(programId), new U32Value(claimId)],
    });
  };

  const stakeClaim = ({
    fromProgramId,
    toProgramId,
    claimId,
  }: {
    fromProgramId: number;
    toProgramId: number;
    claimId: number;
  }) => {
    return buildTransaction({
      smartContractAddress: isMainPool ? boosterV2.address : proxyAddress,
      func: BOOSTER_V2_METHOD.STAKE_CLAIM,
      group: 'booster-v2',
      args: [
        new U32Value(fromProgramId),
        new U32Value(claimId),
        new U32Value(toProgramId),
      ],
    });
  };

  return {
    stake,
    unstake,
    reallocate,
    claimRewards,
    claim,
    stakeClaim,
  };
};

export default useBoosterV2Interaction;
