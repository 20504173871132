import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import {
  protocolSelector,
} from '@/store/protocol';

export enum ACCOUNT_MANAGER_DEPLOYER_METHOD {
  DEPLOY_ACCOUNT_MANAGER = 'deployAccountManager',
}

const useAccountManagerDeployerInteraction = () => {
  const { accountManagerDeployer } = useAppSelector(protocolSelector);
  const { buildTransaction } = useSignMultipleTransactions();

  const deployAccountManager = () => {
    return buildTransaction({
      smartContractAddress: accountManagerDeployer.address,
      func: ACCOUNT_MANAGER_DEPLOYER_METHOD.DEPLOY_ACCOUNT_MANAGER,
      group: 'account-manager-deployer',
    });
  };

  return {
    deployAccountManager,
  };
};

export default useAccountManagerDeployerInteraction;
