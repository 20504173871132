import React from 'react';

import { InputButton } from './InputButton';

export const Percent80Limit: React.FC<any> = ({ onClick, selected }) => {
  return (
    <InputButton onClick={onClick} data-testid='maxButton' selected={selected}>
      80% <br />
      Limit
    </InputButton>
  );
};
