import { motion } from 'framer-motion';
import React, { ReactNode, useRef } from 'react';

import Hint from '@/components/Hint';

import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';
import { Trans } from 'next-i18next';

type Props = {
  left: number;
  apy: string;
  instaCompliance: string;
  containerClassName?: string;
  label: ReactNode;
  lineColor: string;
  bgColor: string;
  direction?: 'left' | 'right';
  hint?: React.ReactNode;
  className?: string;
};

const FlagInfos = ({
  left,
  apy,
  instaCompliance,
  label,
  lineColor,
  bgColor,
  direction = 'right',
  hint = '',
  className,
  containerClassName,
}: Props) => {
  const currentApyContainerRef = useRef<HTMLDivElement>();

  return (
    <div
      style={{
        left: `${left}%`,
      }}
      className={clsxm(
        'absolute bottom-0  h-[33px] md:h-[43.2px] flex translate-x-[-1px] z-[12]',
        className,
      )}
    >
      <motion.div
        initial={{ scaleY: 0, opacity: 0, transformOrigin: 'bottom' }}
        animate={{ scaleY: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        exit={{ scaleY: 0, opacity: 0, transition: { delay: 0.6 } }}
        className='h-full w-px z-10'
        style={{ backgroundColor: lineColor }}
      />
      <div ref={currentApyContainerRef as any} className='relative mt-[1.35px]'>
        <div
          className={clsxm(
            'absolute top-0 whitespace-nowrap',
            containerClassName,
            direction === 'left' ? 'right-0' : 'left-0',
          )}
        >
          <motion.div
            initial={{
              scaleX: 0,
              opacity: 0,
              transformOrigin: direction === 'left' ? 'right' : 'left',
            }}
            animate={{ scaleX: 1, opacity: 1 }}
            exit={{ scaleX: 0, opacity: 0, transition: { delay: 0.3 } }}
            transition={{ duration: 0.5, delay: 1.1, ease: 'easeInOut' }}
            className={clsxm(
              'p-[3px] h-[22.9px] md:h-7 min-w-[84px] md:min-w-[104px]',
              direction === 'left'
                ? 'rounded-l-[4px] origin-right'
                : 'rounded-r-[4px] origin-left',
            )}
            style={{ backgroundColor: bgColor }}
          >
            <motion.div
              initial={{ opacity: 0, x: direction === 'left' ? 5 : -5 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, transition: { delay: 0 } }}
              transition={{ duration: 0.5, delay: 1.5, ease: 'easeInOut' }}
              className='flex flex-col items-start text-left gap-[3.5px] md:gap-[3px] font-semibold'
            >
              <Hint
                unvisible={hint === ''}
                autoReplace
                backgroundColor='#030303'
                placement='bottom-center'
                content={hint}
              >
                <div
                  className={clsxm(
                    'flex items-center text-[7px] md:text-[9px] leading-[125%] text-[#3F3B65] dark:text-white gap-0.5',
                  )}
                  style={{
                    color: lineColor,
                  }}
                >
                  {label}

                  <span className='text-dark dark:text-white'>
                    {formatNumber(apy)}%
                  </span>
                </div>
              </Hint>

              <Hint
                autoReplace
                backgroundColor='#030303'
                placement='bottom-center'
                content={
                  <div className='max-w-[260px] leading-[12.5px] md:max-w-[300px]'>
                    <Trans
                      i18nKey='tooltip-ush-419'
                      components={[
                        <span className='text-[#8FFFDB]' />,
                        <span className='text-[#5AF1B0]' />,
                        <span className='text-[#E24949]' />,
                        <span className='text-[#B487FE]' />,
                      ]}
                      ns='translation'
                    />
                  </div>
                }
              >
                <p className='translate-y-[-2px] text-[7px] md:text-[8px] leading-[125%] text-[#736DA8] dark:text-[#9A92DF]'>
                  Ratio{' '}
                  <span className='ml-[2px]'>
                    x{formatNumber(instaCompliance, 2)}
                  </span>{' '}
                </p>
              </Hint>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FlagInfos;
