/* eslint-disable react-hooks/exhaustive-deps */
import {
  Address,
  AddressType,
  AddressValue,
  BigIntType,
  BigUIntValue,
  BooleanValue,
  List,
  ListType,
  U32Value,
} from '@multiversx/sdk-core';
import DefiUtils from 'defi-utils';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import { protocolSelector } from '@/store/protocol';

export enum BOOSTER_METHOD {
  STAKE = 'stake',
  UNSTAKE = 'unstake',
  CLAIM_REWARDS = 'claimRewards',
  CLAIM = 'claim',
}

const useBoosterInteraction = () => {
  const { markets, booster } = useAppSelector(protocolSelector);

  const { buildTransaction } = useSignMultipleTransactions();

  const stake = ({ amountAsBigNumber }: { amountAsBigNumber: string }) => {
    const safeAmountAsBigNumber = new DefiUtils(amountAsBigNumber).toFixed(
      0,
      DefiUtils.ROUND_DOWN,
    );

    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.STAKE,
      group: 'booster',
      isPayable: true,
      token: {
        tokenIdentifier: markets.HTM.underlying.id,
        amount: safeAmountAsBigNumber,
        numDecimals: markets.HTM.underlying.decimals,
        isFromBigInteger: true,
      },
      args: [
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new BigIntType()), []),
      ],
    });
  };

  const unstake = ({ amountAsBigNumber }: { amountAsBigNumber: string }) => {
    const safeAmountAsBigNumber = new DefiUtils(amountAsBigNumber).toFixed(
      0,
      DefiUtils.ROUND_DOWN,
    );

    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.UNSTAKE,
      group: 'booster',
      isPayable: true,
      token: {
        tokenIdentifier: markets.HTM.underlying.id,
        amount: safeAmountAsBigNumber,
        numDecimals: markets.HTM.underlying.decimals,
        isFromBigInteger: true,
      },
      args: [
        new BigUIntValue(safeAmountAsBigNumber),
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new BigIntType()), []),
      ],
    });
  };

  const claimRewards = (
    boost: boolean,
    moneyMarketsAddressList: string[] = [],
    minBoostedRewardsOut?: string,
  ) => {
    const boostArg = new BooleanValue(boost);
    const moneyMarketsArg = new List(
      new ListType(new AddressType()),
      moneyMarketsAddressList.map(
        (address) => new AddressValue(new Address(address)),
      ),
    );
    const accountsArg = new List(new ListType(new AddressType()), []);
    const minBoostedRewardsOutArg =
      !minBoostedRewardsOut ||
      new DefiUtils(minBoostedRewardsOut).isLessThanOrEqualTo(0)
        ? []
        : [new BigUIntValue(minBoostedRewardsOut)];

    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.CLAIM_REWARDS,
      group: 'booster',
      args: [
        boostArg,
        moneyMarketsArg,
        accountsArg,
        ...minBoostedRewardsOutArg,
      ],
    });
  };

  const claim = (claimId: number) => {
    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.CLAIM,
      group: 'booster',
      args: [new U32Value(claimId)],
    });
  };

  return {
    stake,
    unstake,
    claimRewards,
    claim,
  };
};

export default useBoosterInteraction;
