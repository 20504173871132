import DefiUtils from 'defi-utils';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'next-i18next';

import Hint from '@/components/Hint';

import { useAppSelector } from '@/store';
import { boosterV2Selector } from '@/store/booster-v2';

import { ushTooltipBaseClassNames } from '@/sections/Ush/components/constants';
import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

export const BoosterLogo = ({ className }: { className?: string }) => (
  <svg
    width='21'
    height='20'
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='w-[16px] h-[16px] md:w-[21px] md:h-[20px]'
  >
    <rect x='0.5' width='20' height='20' rx='5' fill='#E04040' />
    <path
      d='M6.5412 12.4113C6.5412 11.8542 6.54737 11.2972 6.5412 10.7401C6.5376 10.5143 6.61012 10.4099 6.84981 10.4171C7.26952 10.4295 7.69027 10.4295 8.10946 10.4171C8.36253 10.4099 8.43968 10.5241 8.43453 10.7586C8.42528 11.1784 8.42939 11.5986 8.43453 12.0183C8.43762 12.311 8.52455 12.3686 8.77607 12.2348C10.2067 11.4767 11.6352 10.7142 13.0617 9.94751C13.1429 9.90379 13.1841 9.78703 13.2437 9.70422C13.1496 9.66307 13.0555 9.58695 12.9603 9.58592C12.0859 9.57666 11.2115 9.58129 10.3371 9.57974C10.009 9.57974 9.99097 9.56071 9.98994 9.22741C9.98994 8.82467 9.99303 8.42193 9.98994 8.0192C9.98582 7.6931 9.8809 7.62726 9.598 7.77745C8.71023 8.24723 7.82571 8.71974 6.94445 9.19501C6.61321 9.37195 6.54223 9.33285 6.54171 8.95223C6.54171 7.97496 6.56589 6.99769 6.534 6.02042C6.50777 5.22317 7.33382 4.75614 8.00865 5.13213C10.4035 6.46671 12.7973 7.80402 15.1901 9.14409C15.9055 9.54631 15.8906 10.467 15.1684 10.8728C13.2115 11.9715 11.2513 13.0657 9.28785 14.1554C8.87636 14.3848 8.45974 14.606 8.0534 14.8478C7.37394 15.2531 6.48154 14.804 6.53554 13.9533C6.56795 13.4415 6.5412 12.9256 6.5412 12.4113Z'
      fill='white'
    />
  </svg>
);

export const StatusBadge = ({ programId }: { programId: number }) => {
  const { stakingAccount, controllerAccount } =
    useAppSelector(boosterV2Selector);

  const { totalStakedUSD } =
    programId === 1 ? controllerAccount : stakingAccount;

  const isEnabled = totalStakedUSD !== '0';

  return (
    <div
      onClick={() => {
        // setState(state === 'Enabled' ? 'Disabled' : 'Enabled')
      }}
      className={clsxm(
        'h-[14px] md:h-[15px] pl-[3px] md:pl-[3.7px] xl:pl-[3.4px] w-[51px] md:w-[60px] flex gap-[2.4px] md:gap-[3px] items-center rounded-[5px] bg-[#EDF4FE] -translate-x-[0.5px]',
        isEnabled
          ? 'bg-[#EDF4FE] dark:bg-[#468EF033]'
          : 'bg-[#FFF3F3] dark:bg-[#F0464633]',
        'transition-all duration-[400ms] ease-in-out',
      )}
    >
      <motion.svg
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        width='9'
        height='9'
        className='w-[8px] h-[8px] md:w-[9px] md:h-[9px]'
        viewBox='0 0 9 9'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='4.5'
          cy='4.5'
          r='2'
          fill={isEnabled ? '#006FFF' : '#E24949'}
        />
        <circle
          cx='4.5'
          cy='4.5'
          r='3'
          stroke={isEnabled ? '#006FFF' : '#E24949'}
          strokeOpacity='0.4'
          strokeWidth='2'
        />
      </motion.svg>

      <motion.p
        key={isEnabled ? 'enabled' : 'disabled'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className='text-[8px] md:text-[9px] leading-[125%] text-[#7E78B2] dark:text-white font-semibold translate-y-[0px] translate-x-[0.5px]'
      >
        {isEnabled ? 'Enabled' : 'Disabled'}
      </motion.p>
    </div>
  );
};

export const BackdropShadow = () => (
  <>
    <svg
      className='hidden lg:block'
      width='265'
      height='116'
      viewBox='0 0 265 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_634_2)'>
        <rect x='0.5' width='264' height='110' rx='16' fill='white' />
        <rect
          x='1.5'
          y='1'
          width='262'
          height='108'
          rx='15'
          stroke='#F4F1FD'
          strokeWidth='2'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_634_2'
          x='0.5'
          y='0'
          width='264'
          height='116'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.827304 0 0 0 0 0.812098 0 0 0 0 0.888127 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_634_2'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_634_2'
            result='shape'
          />
        </filter>
      </defs>
    </svg>

    <svg
      className='hidden md:block lg:hidden'
      width='264'
      height='117'
      viewBox='0 0 264 117'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_0_3)'>
        <rect y='0.671875' width='264' height='110' rx='16' fill='white' />
        <rect
          x='1'
          y='1.67188'
          width='262'
          height='108'
          rx='15'
          stroke='#F4F1FD'
          strokeWidth='2'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_0_3'
          x='0'
          y='0.671875'
          width='264'
          height='116'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.827304 0 0 0 0 0.812098 0 0 0 0 0.888127 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_0_3'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_0_3'
            result='shape'
          />
        </filter>
      </defs>
    </svg>

    <svg
      className=' md:hidden'
      width='197'
      height='84'
      viewBox='0 0 197 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='1' width='197' height='83' rx='10.7455' fill='#D3CFE2' />
      <rect
        x='0.447727'
        y='0.447727'
        width='196.105'
        height='79.1045'
        rx='10.2977'
        fill='white'
      />
      <rect
        x='0.447727'
        y='0.447727'
        width='196.105'
        height='79.1045'
        rx='10.2977'
        stroke='#E7E7F9'
        strokeWidth='0.895454'
      />
    </svg>
  </>
);
export const BackdropShadowDark = () => (
  <>
    <svg
      width='293'
      height='140'
      viewBox='0 0 293 140'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='hidden md:block'
    >
      <g filter='url(#filter0_dd_645_2)'>
        <path
          d='M5.5 16C5.5 7.16344 12.6634 0 21.5 0H253.5C262.337 0 269.5 7.16344 269.5 16V94C269.5 102.837 262.337 110 253.5 110H21.5C12.6634 110 5.5 102.837 5.5 94V16Z'
          fill='#191733'
        />
        <path
          d='M21.5 1H253.5C261.784 1 268.5 7.71573 268.5 16V94C268.5 102.284 261.784 109 253.5 109H21.5C13.2157 109 6.5 102.284 6.5 94V16C6.5 7.71573 13.2157 1 21.5 1Z'
          stroke='#2D2B54'
          strokeWidth='2'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_645_2'
          x='0.5'
          y='0'
          width='292'
          height='140'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='9' dy='16' />
          <feGaussianBlur stdDeviation='7' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_645_2'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='6' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0901961 0 0 0 0 0.0784314 0 0 0 0 0.188235 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_645_2'
            result='effect2_dropShadow_645_2'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_645_2'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
    <svg
      width='197'
      height='84'
      viewBox='0 0 197 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='block md:hidden translate-x-[6px]'
    >
      <rect y='1.00391' width='197' height='83' rx='10.7455' fill='#171430' />
      <rect
        x='0.7'
        y='0.703906'
        width='195.6'
        height='78.6'
        rx='10.0455'
        fill='#191733'
      />
      <rect
        x='0.7'
        y='0.703906'
        width='195.6'
        height='78.6'
        rx='10.0455'
        stroke='#22213C'
        stroke-width='1.4'
      />
    </svg>
  </>
);

export const CurrentApyLabel = ({
  apy,
  instaCompliance,
}: {
  apy: string;
  instaCompliance: string;
}) => {
  return (
    <motion.div
      initial={{ scaleX: 0, opacity: 0 }}
      animate={{ scaleX: 1, opacity: 1 }}
      transition={{ duration: 0.5, delay: 1, ease: 'easeInOut' }}
      className='p-[3px] bg-[#FFF1F0] rounded-r-[4px] h-7 origin-left'
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.5 }}
        className='flex flex-col gap-[3px] font-semibold'
      >
        <Hint
          autoReplace
          backgroundColor='#030303'
          placement='bottom-center'
          content={
            <div className={clsxm('max-w-[330px]', ushTooltipBaseClassNames)}>
              <Trans
                i18nKey='tooltip-ush-413'
                className='!tracking-[0.01em] text-[8px] md:text-[10px]'
                components={[
                  <span className='text-[#5CD1FB]' />,
                  <span className='text-[#8FFFDB]' />,
                  <span className='text-[#5AF1B0]' />,
                ]}
                ns='translation'
              />
            </div>
          }
        >
          <p className='text-[9px] leading-[125%] text-[#3F3B65]'>
            Current APY: <span className='ml-[2px]'>{formatNumber(apy)}%</span>
          </p>
        </Hint>
        <p className='translate-y-[-2px] text-[8px] leading-[125%] text-[#736DA8]'>
          Ratio{' '}
          <span className='ml-[2px]'>x{formatNumber(instaCompliance, 0)}</span>{' '}
        </p>
      </motion.div>
    </motion.div>
  );
};

export const MaxApyLabel = ({ apy }: { apy: string }) => (
  <div className='p-[3px]  bg-[#F0FFF9] rounded-l-[4px] '>
    <div className='flex flex-col items-end gap-[3px] font-semibold'>
      <p className='text-[9px] leading-[125%] text-[#3F3B65] whitespace-nowrap'>
        Max APY: <span className='ml-[1px]'>{formatNumber(apy)}%</span>
      </p>

      <div className='flex w-full justify-end h-fit pr-0.5'>
        <p className='translate-y-[-2px] translate-x-px text-[8px] leading-[125%] text-[#736DA8]'>
          Ratio <span className='ml-[2px]'>x7</span>{' '}
        </p>
      </div>
    </div>
  </div>
);

export const heightClassName = 'h-[5px] md:h-[8px]';

interface CurrentApiInteractiveSliderProps {
  value: number;
  baseAPYWidth: number;
  baseAPYHover: boolean;
  onChange: (value: number) => void;
  onHover?: () => void;
  onUnhover?: () => void;
}

export const CurrentApiInteractiveSlider = ({
  value,
  baseAPYWidth,
  baseAPYHover,
  onChange,
  onHover,
  onUnhover,
}: CurrentApiInteractiveSliderProps) => {
  const containerRef = useRef(null);
  const circleRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startLeft, setStartLeft] = useState(22);
  const [barHovered, setBarHovered] = useState(false);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    const clientX = e.type.includes('touch') ? e.touches[0].clientX : e.clientX;
    setStartX(clientX);
    setStartLeft(value);

    // For touch devices
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);

    // For mouse
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    setIsDragging(false);

    document.removeEventListener('touchmove', handleMouseMove);
    document.removeEventListener('touchend', handleMouseUp);

    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event: any) => {
    if (!isDragging) {
      return;
    }

    const clientX = event.type.includes('touch')
      ? event.touches[0].clientX
      : event.clientX;
    const container = containerRef.current;
    const position =
      startLeft + ((clientX - startX) / container?.clientWidth) * 100;

    const s_position = DefiUtils.max(
      0,
      DefiUtils.min(100, position),
    ).toNumber();

    onChange(s_position);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('touchmove', handleMouseMove);
      document.addEventListener('touchend', handleMouseUp);

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
    };
  }, [isDragging]);

  return (
    <div className='relative h-fit w-full' ref={containerRef}>
      <div
        className={clsxm(
          heightClassName,
          'absolute top-0 left-0 z-[12]',
          'bg-[#E04040]',
        )}
        style={{
          width: `${value}%`,
        }}
      />
      <div
        onMouseEnter={() => {
          setBarHovered(true);
        }}
        onMouseLeave={() => {
          setBarHovered(false);
        }}
        className={clsxm(
          heightClassName,
          'absolute top-0 left-0 hover:cursor-pointer z-[12]',
          barHovered || baseAPYHover
            ? 'pinkDiagonalStripesTransparent bg-[#E04040]/70'
            : 'bg-transparent',
        )}
        style={{
          width: `${baseAPYWidth}%`,
        }}
      />

      {/* circle */}
      <div
        // onMouseDown={handleMouseDown}
        // onTouchStart={handleMouseDown}
        style={{
          left: `${value <= 0 ? 0.25 : value}%`,
        }}
        ref={circleRef}
        className='absolute z-[9999] top-[-1.2px] md:top-[-1px] left-0 -translate-x-1/2 pointer-events-none'
      >
        <svg
          width='11'
          height='10'
          viewBox='0 0 11 10'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='md:block hidden'
        >
          <path
            d='M9.71429 5C9.71429 7.32749 7.82749 9.21429 5.5 9.21429C3.17251 9.21429 1.28571 7.32749 1.28571 5C1.28571 2.67251 3.17251 0.785714 5.5 0.785714C7.82749 0.785714 9.71429 2.67251 9.71429 5Z'
            fill='#E04040'
            stroke='white'
            strokeWidth='1.57143'
          />
        </svg>

        <svg
          width='8'
          height='7'
          viewBox='0 0 8 7'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='md:hidden'
        >
          <path
            d='M7.27344 3.5C7.27344 5.15685 5.93029 6.5 4.27344 6.5C2.61658 6.5 1.27344 5.15685 1.27344 3.5C1.27344 1.84315 2.61658 0.5 4.27344 0.5C5.93029 0.5 7.27344 1.84315 7.27344 3.5Z'
            fill='#E04040'
            stroke='white'
          />
        </svg>
      </div>
    </div>
  );
};
