import EqualIcon from "@/components/Icons/EqualIcon"
import { TokenSubCategory } from "@/components/popups/HTMBoosterPopup1/components/XExchangeDashboard/components/Tokens/components/types"

import clsxm from "@/services/clsxm"

export const Divider = ({ className }: { className?: string }) => (<div className={clsxm('w-[1px] md:w-[0.6px] lg:w-[1px] h-[20px] md:h-[19.3px] lg:h-[32px] bg-[#E2E4F2] dark:bg-[#3F3B76]', className)} />)

export const EqualIcon_ = ({ isDark, className }: { isDark: boolean, className?: string }) => (
  <span className=' inline-block  lg:translate-y-px'>
    {' '}
    <EqualIcon className={clsxm('w-[4px] md:w-[5px] lg:w-[8px] h-auto', className)} fill={isDark ? '#625E8F' : '#6A6A8C'} />{' '}
  </span>
)

export const labelTextColorClassName = clsxm(
  'text-[#1F2226] dark:text-[white]'
)
export const labelTextSizeClassName = clsxm(
  'text-[9px] leading-[11.25px] font-semibold ',
  'lg:text-[12px] lg:leading-[15px]',
)
export const labelTextClassName = clsxm(labelTextColorClassName, labelTextSizeClassName)

export const valueTextColorClassName = clsxm(
  'dark:text-[#625E8F] text-[#6A6A8C]',
)
export const valueTextSizeClassName = clsxm(
  'font-semibold',
  'text-[7px] leading-[8.75px]',
  'md:text-[8px] md:leading-[8.75px]',
  'lg:text-[10px] lg:leading-[12.5px]',
)
export const valueTextClassName = clsxm(valueTextColorClassName, valueTextSizeClassName)

export const getCategoryIcon = (subCategory: TokenSubCategory['category']) => {
  let iconName = ""
  if (subCategory === "dual farm") iconName = "dual-farm"
  if (subCategory === "farm") iconName = "farm"
  if (subCategory === "pool") iconName = "pool"
  if (subCategory === "pig container") iconName = "pig-container"

  return '/images/xexchange/badges/' + iconName + '-badge.svg'
}
export const getCategoryLabel = (subCategory: TokenSubCategory['category']) => {
  if (subCategory === "dual farm") return "Dual Farm"
  if (subCategory === "farm") return "Farm"
  if (subCategory === "pool") return "Pool"
  if (subCategory === "pig container") return "Container"
}