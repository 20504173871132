import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import { calcUnbondDate } from '@/utils/blockchain';
import logger from '@/utils/logger';

export interface LockedToken {
  tokenIdentifier: string;
  tokenNonce: string;
  amount: string;
  symbol: string;
}

export interface UnlockedToken {
  tokenIdentifier: string;
  tokenNonce: string;
  amount: string;
  unbondEpoch: string;
  availableToRedeemDate: string;
  symbol: string;
}

export interface LiquidLockingAppState {
  address: string;
  lockedTokens: LockedToken[];
  unlockedTokens: UnlockedToken[];
}

export enum TOKEN_SOURCE {
  wallet = 'wallet',
  collateral = 'collateral',
}

const initialState: LiquidLockingAppState = {
  address: '',
  lockedTokens: [],
  unlockedTokens: [],
};

export const liquidLockingAppSlice = createSlice({
  name: 'liquidLockingApp',
  initialState,
  reducers: {
    setLiquidLockingApp: (
      state,
      action: PayloadAction<Partial<LiquidLockingAppState>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        // @ts-ignore
        state[key as keyof LiquidLockingAppState] = value;
      });
    },
  },
});

export const { setLiquidLockingApp } = liquidLockingAppSlice.actions;

export const getLiquidLockingAppData =
  ({ accountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        protocol: { liquidLocking, blockchain },
      } = getState();

      if (liquidLocking.address.length === 0) {
        return;
      }

      const [lockedTokenAmounts, unlockedTokenAmounts] = await Promise.all([
        blockchainService.liquidLocking.getLockedTokenAmounts(
          liquidLocking.address,
          accountAddress,
        ),
        blockchainService.liquidLocking.getUnlockedTokenAmounts(
          liquidLocking.address,
          accountAddress,
        ),
      ]);

      const unlockedTokens = unlockedTokenAmounts.map((item) => {
        const availableToRedeemDate = calcUnbondDate({
          roundsPerEpoch: blockchain.roundsPerEpoch,
          roundsPassed: blockchain.roundsPassed,
          unbondEpoch: parseInt(item.unbondEpoch) + 1,
          epoch: blockchain.epoch,
        });

        return {
          ...item.token,
          unbondEpoch: item.unbondEpoch,
          availableToRedeemDate,
          symbol: item.token.tokenIdentifier.split('-')[0],
        } as UnlockedToken;
      });

      const lockedTokens = lockedTokenAmounts.map((item) => {
        return {
          ...item,
          symbol: item.tokenIdentifier.split('-')[0],
        };
      });

      await dispatch(
        addAction(
          setLiquidLockingApp({
            address: liquidLocking.address,
            lockedTokens,
            unlockedTokens,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getLiquidLockingAppData', error);
      captureException(error);
    }
  };

export const liquidLockingAppSelector = (state: RootState) =>
  state.liquidLockingApp;

export default liquidLockingAppSlice.reducer;
