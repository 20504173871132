import classNames from 'classnames';

import SearchIcon from '@/components/Icons/SearchIcon';

import { chainType, networkConfig } from '@/config/network';
import { shortenHash } from '@/utils/shortenHash';

const TxHashBox: React.FC<any> = ({ hash, className }) => {
  return (
    <a
      href={`${networkConfig[chainType].explorerAddress}/transactions/${hash}`}
      target='_blank'
      rel='noreferrer'
      className={classNames(
        'w-[220px] whitespace-nowrap',
        'group flex items-center gap-4 rounded-[8px] border p-1.5 px-4',
        'border-[#DCDCE3] text-[#535367] dark:border-current dark:text-[#A5A3C6]',
        className,
      )}
    >
      <span className='dark:text-white'>Tx Hash {shortenHash(hash, 8)}</span>

      <div className='ml-auto transition-transform duration-75 group-hover:scale-110'>
        <SearchIcon width={15} height={15} />
      </div>
    </a>
  );
};

export default TxHashBox;
