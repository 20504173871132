import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import { TX_STATUS } from '@/hooks/core/useSignMultipleTransactions';
import useSetVariantOnLoadingEnd, {
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';
import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { accountSelector } from '@/store/auth';
import { boosterV2Selector } from '@/store/booster-v2';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import { openPopup } from '@/store/popup';
import { protocolSelector } from '@/store/protocol';
import {
  H_TOKEN_DECIMALS,
  MARKET_KEY,
  nativeMarketSelector,
  sEgldMarketSelector,
  sEgldUserBalanceSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';
import {
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
  transactionSelector,
} from '@/store/transaction';

import { Shimmer } from '@/sections/Liquid/App';
import {
  InlineLoader,
  StakeUnstakeMigrateRadioButton,
} from '@/sections/Liquid/App/Form/components';
import HtmBoosterLiquid from '@/sections/Liquid/App/Form/components/HtmBoosterLiquid/HtmBoosterLiquid';
import {
  Operation,
  useLiquidStakingForm,
} from '@/sections/Liquid/App/Form/contexts/LiquidStakingFormContext';
import { MigratingInputs } from '@/sections/Liquid/App/Form/MigratingInputs';
import ResponsiveBgImage from '@/sections/Liquid/App/Form/ResponsiveBgImage';
import { StakingInputs } from '@/sections/Liquid/App/Form/StakingInputs';
import { UnstakingInputs } from '@/sections/Liquid/App/Form/UnstakingInputs';
import {
  getLiquidTransactionGradient,
  getLiquidTransactionName,
} from '@/sections/Liquid/App/Form/utils';
import { cardClasses, useHintProps } from '@/sections/Liquid/App/global';
import { DonutAnim } from '@/sections/Liquid/Common/DonutAnim';
import { PrettyToken as ColoredName } from '@/sections/Liquid/Common/PrettyToken';
import clsxm from '@/services/clsxm';
import { formatNumber, nFormatter } from '@/utils/helpers';

const Form: React.FC<any> = () => {
  const { isLoadingFirstInfo } = useAppSelector(protocolSelector);
  const { t } = useTranslation('liquid-app');
  const { address: accountAddress } = useAppSelector(accountSelector);
  const { markets: controllerRewardMarkets } =
    useAppSelector(rewardBatchSelector);
  const { apy: liquidStakingApy } = useAppSelector(liquidStakingAppSelector);
  const { controllerRewardBatches: controllerRewardBatchesV2 } =
    useAppSelector(boosterV2Selector);

  const nativeMarket = useAppSelector(nativeMarketSelector);
  const { currentTransactions, transactionConfig, transactionAmount } =
    useAppSelector(transactionSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);
  const [cH, setCH] = useState();
  const { controls } = useSetVariantOnLoadingEnd({ timeout: 150 });
  const [showAlert, setShowAlert] = useState(false);
  const { isLoggedIn } = useLogin();
  const md = useMediaQuery('(min-width: 768px)');

  const segldControllerRewardsV2 = useMemo(
    () =>
      controllerRewardBatchesV2.find(
        ({ tokenId }) => sEgldMarket.hToken.id === tokenId,
      )?.rewards || [],
    [controllerRewardBatchesV2, sEgldMarket.hToken.id],
  );

  const {
    formsState,
    setFormsState,
    totalAPY,
    percentages,
    rewardsTokensAPY,
    showMarketApy,
    showRewardsApy,
    showBoosterApy,
    staking,
    unstaking,
    migrating,
  } = useLiquidStakingForm();
  const defaultHintProps = useHintProps();

  const sEgldToEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.EGLD,
  );

  const hsEgldToEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.EGLD,
  );

  const tinyFormula = useMemo(
    () => showMarketApy === true || showRewardsApy === true || showBoosterApy,
    [showBoosterApy, showMarketApy, showRewardsApy],
  );

  const liquidPendingTransaction = useMemo(() => {
    if (accountAddress !== transactionConfig.accountAddress) {
      return null;
    }

    const transactionsCompleted = currentTransactions.filter(
      ({ status }) => !['pending', 'signed'].includes(status),
    );
    const id = currentTransactions?.[currentTransactions.length - 1]?.hash;
    const isLiquidTransaction =
      !!id &&
      transactionConfig.group === TRANSACTION_GROUP_TYPE.LIQUID &&
      transactionConfig.subgroup !== TRANSACTION_SUBGROUP_TYPE.DEFAULT &&
      transactionsCompleted.length !== transactionAmount;

    if (!isLiquidTransaction) {
      return null;
    }

    return {
      id,
      gradient: getLiquidTransactionGradient(transactionConfig.subgroup),
      name: getLiquidTransactionName(transactionConfig),
    };
  }, [
    accountAddress,
    currentTransactions,
    transactionAmount,
    transactionConfig,
  ]);

  const pendingLength = useRef(-1);

  useEffect(() => {
    if (currentTransactions.length === 0) return;

    const pendingTx = currentTransactions.filter(
      ({ status }) => status == 'pending',
    ).length;

    if (pendingTx != pendingLength.current) {
      formsState?.stakingInputs?.reset();
      formsState?.unstakingInputs?.reset();
      formsState?.migratingInputs?.reset();
      pendingLength.current = pendingTx;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransactions]);

  const hsEgldAmount = useMemo(() => {
    return new DefiUtils(sEgldUserBalance.collateralBalance)
      .plus(sEgldUserBalance.hTokenBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
  }, [sEgldUserBalance.collateralBalance, sEgldUserBalance.hTokenBalance]);

  const hsEgldAmountInEgld = useMemo(() => {
    return new DefiUtils(hsEgldAmount).multipliedBy(hsEgldToEgld).toString();
  }, [hsEgldAmount, hsEgldToEgld]);

  const sEgldAmount = useMemo(() => {
    return new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toString();
  }, [sEgldMarket.underlying.decimals, sEgldUserBalance.underlyingBalance]);

  const sEgldAmountInEgld = useMemo(() => {
    return new DefiUtils(sEgldAmount).multipliedBy(sEgldToEgld).toString();
  }, [sEgldAmount, sEgldToEgld]);

  const totalStakedInEgld = useMemo(() => {
    return new DefiUtils(sEgldAmountInEgld).plus(hsEgldAmountInEgld).toString();
  }, [sEgldAmountInEgld, hsEgldAmountInEgld]);

  const totalStakedInUSD = useMemo(() => {
    return new DefiUtils(totalStakedInEgld)
      .toUSD(nativeMarket.underlying.priceUSD)
      .toString();
  }, [totalStakedInEgld, nativeMarket.underlying.priceUSD]);

  const segldMarketControllerRewardsSupplyToken = useMemo(() => {
    const rewards =
      controllerRewardMarkets[sEgldMarket.underlying.symbol as MARKET_KEY]
        ?.rewards || [];

    return rewards
      .filter(({ type }) => type === 'Supply')
      .filter(({ speed }) => speed !== '0');
  }, [controllerRewardMarkets, sEgldMarket.underlying.symbol]);

  const collateralBalanceInUSD = useMemo(
    () =>
      new DefiUtils(sEgldUserBalance.collateralBalance)
        .toFullDecimals(H_TOKEN_DECIMALS)
        .multipliedBy(hsEgldToEgld)
        .toUSD(nativeMarket.underlying.priceUSD)
        .toString(),
    [
      hsEgldToEgld,
      nativeMarket.underlying.priceUSD,
      sEgldUserBalance.collateralBalance,
    ],
  );

  const rewardsTokensEarnings = useMemo(() => {
    if (new DefiUtils(sEgldUserBalance.collateralBalance).isZero()) {
      return [];
    }

    const segldMarketBoosterRewards = segldControllerRewardsV2.map(
      ({ account, reward, logo }) => {
        return {
          apy: account.totalAPY,
          priceUSD: reward.priceUSD,
          symbol: reward.symbol,
          logo,
          batchId: `SEGLD-${reward.symbol}`,
        };
      },
    );

    const formattedRewardsItems = [
      ...segldMarketControllerRewardsSupplyToken,
      ...segldMarketBoosterRewards,
    ].map(({ apy, priceUSD, symbol, logo, batchId }) => {
      const amountInUSD = new DefiUtils(collateralBalanceInUSD)
        .multipliedBy(apy)
        .dividedBy(100)
        .toSafeString();

      const amount = new DefiUtils(amountInUSD)
        .fromUSD(priceUSD)
        .toSafeString();

      return {
        symbol,
        logo,
        amount,
        amountInUSD,
        batchId,
        apy,
      };
    });

    const rewardsMap = formattedRewardsItems.reduce(
      (prev, current) => ({
        ...prev,
        [current.symbol]: {
          ...current,
          amount: new DefiUtils(current.amount)
            .plus(prev[current.symbol]?.amount || 0)
            .toSafeString(),
          amountInUSD: new DefiUtils(current.amountInUSD)
            .plus(prev[current.symbol]?.amountInUSD || 0)
            .toSafeString(),
        },
      }),
      {} as Record<
        string,
        {
          symbol: string;
          logo: string;
          batchId: string;
          amount: string;
          amountInUSD: string;
        }
      >,
    );

    return Object.values(rewardsMap);
  }, [
    collateralBalanceInUSD,
    sEgldUserBalance.collateralBalance,
    segldControllerRewardsV2,
    segldMarketControllerRewardsSupplyToken,
  ]);

  const estimatedEarningsEGLDInUSD = useMemo(() => {
    return new DefiUtils(totalStakedInUSD)
      .multipliedBy(new DefiUtils(liquidStakingApy).plus(sEgldMarket.supplyAPY))
      .dividedBy(100)
      .toSafeString();
  }, [totalStakedInUSD, liquidStakingApy, sEgldMarket.supplyAPY]);

  const estimatedEarningsEGLD = useMemo(() => {
    return new DefiUtils(estimatedEarningsEGLDInUSD)
      .fromUSD(nativeMarket.underlying.priceUSD)
      .toString();
  }, [estimatedEarningsEGLDInUSD, nativeMarket.underlying.priceUSD]);

  const totalEstimatedEarningsUSD = useMemo(() => {
    return new DefiUtils(totalStakedInEgld)
      .toUSD(nativeMarket.underlying.priceUSD)
      .multipliedBy(totalAPY)
      .dividedBy(100)
      .toSafeString();
  }, [nativeMarket.underlying.priceUSD, totalAPY, totalStakedInEgld]);

  const hasStaked = useMemo(() => {
    return (
      new DefiUtils(sEgldAmount).isGreaterThan('0') ||
      new DefiUtils(hsEgldAmount).isGreaterThan('0')
    );
  }, [hsEgldAmount, sEgldAmount]);

  const onChangeOperation = (operation: Operation) => {
    setFormsState((_: any) => ({ ..._, operation }));
  };

  useEffect(() => {
    if (!containerRef.current) return;
    const node = containerRef.current as HTMLElement | any;
    const div = node?.querySelector('.active');
    setCH(div?.offsetHeight + 140);
  }, [containerRef, formsState, showAlert]);

  const rewardsTokens = useMemo(() => {
    return segldMarketControllerRewardsSupplyToken.map(({ logo }) => logo);
  }, [segldMarketControllerRewardsSupplyToken]);

  const [colorBooster, setColorBooster] = useState(true);

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        custom={{ movePx: 12 }}
        initial='hidden'
        variants={TRANSITION_VARIANTS}
        animate={controls}
      >
        <div
          className={classNames(
            '1xs:max-h-auto relative max-h-[254px] min-h-[190px] w-full md:min-h-[261.8px]',
            'mb-7 md:mb-[2.25rem] font-semibold',
            // 'theme-switch-transition',
            'p-[18px] pl-[13.2px] pt-[15.4px] md:pl-[19.5px] md:pt-[22.8px]',
          )}
        >
          <div
            className={clsxm(
              'w-[264] h-[84px] md:h-[110px]',
              'absolute',
              'right-[1px] bottom-[1px] md:right-[1px] md:bottom-[1.3px] xl:bottom-[1px]',
            )}
          >
            <HtmBoosterLiquid programId={1} />
          </div>
          <ResponsiveBgImage />
          {/* <ResponsiveHtmBoosterBgImage /> */}

          <div className={classNames('mb-2 md:mb-[14.8px] xl:mb-[25.3px]')}>
            <div className='flex items-center gap-[1px] md:gap-1 text-[12px] md:text-[16px] capitalize text-[#303E70] transition duration-300 ease-out dark:text-[#1F2226]'>
              {t('my-position', 'My position')}
              <button
                type='button'
                className='transform scale-[0.7] md:scale-100 -mb-0.5 h-4 transition-transform hover:scale-110'
                onClick={() => {
                  dispatch(
                    openPopup({
                      name: 'current_position',
                      data: {
                        liquidStaking: MARKET_KEY.sEGLD,
                      },
                    }),
                  );
                }}
              >
                <DonutAnim />
              </button>
            </div>

            <div className='mt-[17.8px] xl:mt-[20.7px]  md:mb-[10.8px] xl:mb-[15px] flex items-center gap-0 md:gap-1 text-[12px] leading-tight text-[#3F3B65] md:text-[16px] xl:text-[14px]'>
              {t('staking-app:total-apy')}{' '}
              <Hint
                {...defaultHintProps}
                autoReplace={true}
                className='translate-x-[2px] md:translate-x-[-1.8px] '
                content={
                  <div className='max-w-[356px] md:max-w-[364px] text-[8px] md:text-[10px] leading-tight'>
                    <Trans
                      i18nKey='pt-3'
                      tOptions={{
                        a: `${formatNumber(percentages[0].value, 2)}%`,
                        b: `${formatNumber(percentages[1].value, 2)}%`,
                        c: `${formatNumber(percentages[2].value, 2)}%`,
                        tokenA: EXCHANGE_RATE_KEY.sEGLD,
                        tokenB: EXCHANGE_RATE_KEY.HsEGLD,
                      }}
                      components={[
                        <span className='whitespace-nowrap text-[#E25F00]' />,
                        <span className='text-segld' />,
                        <span className='text-hsegld' />,
                        <ColoredName token={EXCHANGE_RATE_KEY.sEGLD} />,
                        <ColoredName token={EXCHANGE_RATE_KEY.HsEGLD} />,
                        <span className='text-[#5AF1B0]' />,
                        <span className='text-[#E24949]' />,
                        <span className='text-[#E24949]' />,
                      ]}
                      ns='liquid-app'
                    />
                  </div>
                }
              >
                <InfoIcon
                  className='text-[12px] w-[8.7px] md:w-[12.4px] xl:w-[10.8px]'
                  strokeWidth='1.5'
                />
              </Hint>
            </div>

            <div
              className={classNames(
                'flex flex-row items-center gap-[4px] text-[10px] h-[28px]  md:gap-[9.1px] xl:gap-[4.4px] md:translate-y-[-4.7px]  xl:translate-y-[1.5px] xl:h-[32px] md:flex-row md:items-center',
              )}
            >
              <div className='whitespace-nowrap text-[12.89px] leading-tight text-[#0170FF] md:translate-y-[-3.8px] md:text-[20px] xl:text-[18px]'>
                <Shimmer md>≈ {formatNumber(totalAPY)}%</Shimmer>
              </div>

              <div
                className={classNames(
                  tinyFormula
                    ? 'h-[23px] p-1 py-0.5  md:h-[32px]  text-[8px] md:text-[10px] xl:h-[26px] xl:text-[9px]'
                    : 'h-[23px] md:h-[32px] p-3 px-[3px] md:pl-3 md:pr-[2.8px] text-[10px] md:text-[12px] xl:pr-[0.8px] xl:pl-[4.1px] xl:text-[12px]',
                  'flex w-fit items-center gap-1 rounded-full bg-white font-hass transition-all dark:bg-[#0C0B21]',
                  'text-[#535360] dark:text-white',
                  'theme-switch-transition',
                )}
              >
                <div className='flex gap-1 whitespace-nowrap'>
                  {percentages
                    .filter(
                      ({ value }, index) =>
                        new DefiUtils(value).isGreaterThan(0) || index <= 1,
                    )
                    .map(({ value, label, color, isActive }, i) => {
                      const notColorBooster = i === 3 && !colorBooster;

                      return (
                        <Shimmer {...{ xl: i == 1, sm: i == 1 }} key={i}>
                          <span
                            key={i}
                            className={classNames(
                              'flex items-center',
                              !tinyFormula ? 'gap-1' : 'gap-0.5',
                              (!isActive || notColorBooster) &&
                                'text-[#A4A4AB] dark:text-[#74747E]',
                            )}
                          >
                            {i !== 0 && ' + '}
                            <span
                              style={{
                                color:
                                  !isActive || notColorBooster
                                    ? undefined
                                    : color,
                              }}
                              className={classNames(
                                tinyFormula
                                  ? 'text-[8px] md:text-[10px] xl:text-[9px]'
                                  : 'text-[10px] md:text-[12px] xl:text-[12px]',
                              )}
                            >
                              {new DefiUtils(value).isGreaterThan(1000)
                                ? nFormatter(value)
                                : formatNumber(value)}
                              %
                            </span>

                            {i === 1 && showMarketApy && isLoggedIn && (
                              <Hint
                                autoReplace
                                {...defaultHintProps}
                                content={
                                  <div
                                    className=' text-[8px] md:text-[10px] leading-tight'
                                    style={{ maxWidth: 180 }}
                                  >
                                    <Trans
                                      i18nKey='tooltip-cep2o'
                                      components={[
                                        <ColoredName
                                          token={EXCHANGE_RATE_KEY.HsEGLD}
                                        />,
                                        <ColoredName
                                          token={EXCHANGE_RATE_KEY.sEGLD}
                                        />,
                                      ]}
                                      ns='liquid-app'
                                    />
                                  </div>
                                }
                              >
                                <div
                                  className={classNames(
                                    'text-[6px] text-[#7264C9] md:text-[10px] xl:text-[8px]',
                                    'bg-[#EBE4FF] dark:bg-[#2E2153]',
                                    'rounded p-0.5 leading-tight',
                                    'theme-switch-transition',
                                  )}
                                >
                                  {new DefiUtils(
                                    sEgldMarket.supplyAPY,
                                  ).toSafeFixed(2)}
                                  %
                                </div>
                              </Hint>
                            )}
                            {i === 2 && showRewardsApy && (
                              <Hint
                                autoReplace
                                {...defaultHintProps}
                                content={
                                  <div
                                    className='text-[8px] md:text-[10px] leading-tight'
                                    style={{ maxWidth: 182 }}
                                  >
                                    <Trans
                                      i18nKey='tooltip-j9b95'
                                      components={[
                                        <ColoredName
                                          token={EXCHANGE_RATE_KEY.HsEGLD}
                                        />,
                                        <span className='text-green' />,
                                        <ColoredName
                                          token={EXCHANGE_RATE_KEY.sEGLD}
                                        />,
                                      ]}
                                      ns='liquid-app'
                                    />
                                  </div>
                                }
                              >
                                <div
                                  className={classNames(
                                    'text-[6px] text-[#B77BCD] md:text-[10px] xl:text-[8px]',
                                    'bg-[#F1E0FE] dark:bg-[#381A50]',
                                    'rounded p-0.5 leading-tight',
                                    'theme-switch-transition',
                                  )}
                                >
                                  {formatNumber(rewardsTokensAPY)}%
                                </div>
                              </Hint>
                            )}

                            {label}
                          </span>
                        </Shimmer>
                      );
                    })}
                </div>
                <div
                  className={classNames('ml-auto flex items-center', {
                    'opacity-50 saturate-0 dark:invert':
                      !percentages?.[2]?.isActive || !colorBooster,
                  })}
                >
                  {rewardsTokens.map(
                    (rewardTokenItem, rewardTokenItemIndex) => (
                      <div
                        key={`${rewardTokenItemIndex}-${rewardTokenItem}`}
                        className={classNames('-ml-1.5 w-[15px] first:m-0')}
                      >
                        <Shimmer circular w={15}>
                          <img
                            src={rewardTokenItem}
                            className={clsxm(
                              'relative h-[12.75px] w-[12.75px]',
                            )}
                            style={{
                              zIndex:
                                (rewardsTokens.length - rewardTokenItemIndex) /
                                4,
                            }}
                            alt=''
                          />
                        </Shimmer>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex max-w-[140px] justify-between  md:max-w-[240px] xl:max-w-[210px]'>
            <div className='relative grid w-full max-w-[270px] grid-cols-2 text-[10px] text-[#42446C] dark:text-[#3F3B65] md:max-w-[300px] xl:max-w-[270px]'>
              <div>
                <div className='mb-[2.9px] flex items-center gap-1 text-[8.59px] font-semibold leading-[24px]  md:mb-[16.9px] md:text-[14px] xl:text-[12px]  xl:mb-[8.7px]'>
                  {t('staking-app:total-staked')}
                  <Hint
                    autoReplace
                    {...defaultHintProps}
                    content={
                      <div className='space-y-1 text-[8px] md:text-[10px] leading-tight tracking-normal'>
                        <Trans
                          i18nKey='tp-4'
                          tOptions={{ token: 'EGLD' }}
                          ns='liquid-app'
                          components={[<span className='text-segld' />]}
                        />

                        {!hasStaked ? (
                          <>
                            <div className='flex gap-1 '>
                              <img
                                className='h-4'
                                src='https://cdn.app.hatom.com/images/sEGLD-diamond.svg'
                                alt=''
                              />
                              0 <span className='text-segld'>sEGLD</span>{' '}
                              {t('equivalent-to').toLowerCase()} ≈ 0 EGLD
                            </div>

                            <div className='flex gap-1'>
                              <img
                                className='h-4'
                                src='https://cdn.app.hatom.com/images/HsEGLD-diamond.svg'
                                alt=''
                              />
                              0 <span className='text-hsegld'>HsEGLD</span>{' '}
                              {t('equivalent-to').toLowerCase()} ≈ 0 EGLD
                            </div>
                          </>
                        ) : (
                          <>
                            {new DefiUtils(sEgldAmount).isGreaterThan('0') && (
                              <div className='flex gap-1'>
                                <img
                                  className='h-4'
                                  src='https://cdn.app.hatom.com/images/sEGLD-diamond.svg'
                                  alt=''
                                />
                                {formatNumber(sEgldAmount, 2)}{' '}
                                <span className='text-segld'>sEGLD</span>{' '}
                                {t('equivalent-to').toLowerCase()} ≈{' '}
                                {formatNumber(sEgldAmountInEgld, 2)} EGLD
                              </div>
                            )}

                            {new DefiUtils(hsEgldAmountInEgld).isGreaterThan(
                              '0',
                            ) && (
                              <div className='flex gap-1'>
                                <img
                                  className='h-4'
                                  src='https://cdn.app.hatom.com/images/HsEGLD-diamond.svg'
                                  alt=''
                                />
                                {formatNumber(hsEgldAmount, 2)}{' '}
                                <span className='text-hsegld'>HsEGLD</span>{' '}
                                {t('equivalent-to').toLowerCase()} ≈{' '}
                                {formatNumber(hsEgldAmountInEgld, 2)} EGLD
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    }
                  >
                    <InfoIcon
                      className='-ml-px mt-px text-[12px] w-[9px] h-[8.7px]  md:w-[12.9px] md:h-[10.2px]'
                      strokeWidth='1.5'
                    />
                  </Hint>
                </div>

                <div className='mb-[8.6px] md:mb-[18.5px] xl:mb-[13.1px]'>
                  <Shimmer md>
                    <div className='flex items-center gap-1 text-[8.59px] leading-[24px] md:text-[14px]'>
                      <Hint
                        autoReplace
                        unvisible={new DefiUtils(totalStakedInEgld).isZero()}
                        {...defaultHintProps}
                        content={
                          <span className='text-[8px] md:text-[10px]'>
                            {formatNumber(
                              totalStakedInEgld,
                              new DefiUtils(totalStakedInEgld).decimalPlaces(),
                            )}
                          </span>
                        }
                      >
                        {nFormatter(totalStakedInEgld, 2)} EGLD
                      </Hint>
                    </div>
                  </Shimmer>
                </div>

                <div className='text-[8.59px] font-semibold leading-tight  md:text-[14px]'>
                  <Hint
                    autoReplace
                    {...defaultHintProps}
                    unvisible={new DefiUtils(totalStakedInUSD).isZero()}
                    content={
                      <span className='text-[8px] md:text-[10px]'>
                        {`$${formatNumber(
                          totalStakedInUSD,
                          new DefiUtils(totalStakedInUSD).decimalPlaces() > 6
                            ? 6
                            : new DefiUtils(totalStakedInUSD).decimalPlaces(),
                        )}`}
                      </span>
                    }
                  >
                    <Shimmer sm>≈ ${nFormatter(totalStakedInUSD, 2)}</Shimmer>
                  </Hint>
                </div>
              </div>

              <div>
                <div className='mb-[3.1px] flex items-center gap-1 text-[8.59px] leading-[24px] md:mb-[16.5px] md:text-[14px] xl:text-[12px]  xl:mb-[8.4px]'>
                  {t('est-earnings')}
                  <Hint
                    {...defaultHintProps}
                    autoReplace={true}
                    content={
                      <div className='max-w-[208px] md:max-w-[212px] space-y-1 text-[8px] md:text-[10px] font-semibold leading-tight tracking-normal'>
                        <div>{t('tp-5')}</div>

                        <div className='flex flex-col gap-1'>
                          <div className='flex gap-1'>
                            <img
                              className='h-4'
                              src='https://cdn.app.hatom.com/images/img1.svg'
                              alt=''
                            />
                            {formatNumber(estimatedEarningsEGLD, 2)} EGLD ($
                            {nFormatter(estimatedEarningsEGLDInUSD, 2)})
                          </div>

                          {rewardsTokensEarnings.map(
                            ({
                              logo,
                              batchId,
                              symbol,
                              amount,
                              amountInUSD,
                            }) => (
                              <div key={batchId} className='flex gap-1'>
                                <img className='h-4' src={logo} alt='' />
                                {formatNumber(amount, 2)} {symbol} ($
                                {nFormatter(amountInUSD, 2)})
                              </div>
                            ),
                          )}
                        </div>

                        {!new DefiUtils(
                          sEgldUserBalance.collateralBalance,
                        ).isZero() && (
                          <div className='text-[8px] leading-tight text-[#F45300]'>
                            <Trans
                              i18nKey='liquid-app:tooltip-429ka'
                              tOptions={{
                                slice1: segldMarketControllerRewardsSupplyToken
                                  .map(({ symbol }) => symbol)
                                  .join(', '),
                                slice2: EXCHANGE_RATE_KEY.HsEGLD,
                              }}
                              components={[
                                <span className='text-hsegld' />,
                                <span className='text-[#E24949]' />,
                                <span className='text-green' />,
                                <span className='text-[#5AF1B0]' />,
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    }
                  >
                    <InfoIcon
                      className='-ml-px mt-px text-[12px]  w-[9px] h-[8.7px]  md:w-[12.9px] md:h-[10.2px]'
                      strokeWidth='1.5'
                    />
                  </Hint>
                </div>

                <div className='mb-[4.7px] text-[8.59px] font-semibold leading-[24px] md:mb-[13.5px]  md:text-[14px] xl:mb-4 md:translate-x-[0.7px] xl:-translate-x-1'>
                  <Hint
                    {...defaultHintProps}
                    unvisible={new DefiUtils(
                      totalEstimatedEarningsUSD,
                    ).isZero()}
                    content={
                      <span className='text-[8px] md:text-[10px]'>
                        {`$${formatNumber(
                          totalEstimatedEarningsUSD,
                          (new DefiUtils(
                            totalEstimatedEarningsUSD,
                          ).decimalPlaces() || 0) > 6
                            ? 6
                            : new DefiUtils(
                                totalEstimatedEarningsUSD,
                              ).decimalPlaces() || 2,
                        )}`}
                      </span>
                    }
                  >
                    <Shimmer md>
                      ${nFormatter(totalEstimatedEarningsUSD, 2)}
                    </Shimmer>
                  </Hint>
                </div>
                <div className='md:w-[86%] flex justify-end'>
                  {liquidPendingTransaction && (
                    <InlineLoader
                      onClick={() => {
                        dispatch(
                          openPopup({
                            name: 'liquidpendingtransaction',
                            data: {
                              txStatus: TX_STATUS.SENT,
                              hash: liquidPendingTransaction.id,
                            },
                          }),
                        );
                      }}
                      textClassName='text-[8px] md:text-[11px]'
                      loaderSize={md ? 12 : 10}
                      className='h-[19px] md:h-[26px] gap-[3.15px] p-0.5 pr-[3.4px] md:pr-1 translate-y-[2px] md:translate-y-0 md:translate-x-px'
                      gradient={liquidPendingTransaction.gradient}
                      label={liquidPendingTransaction.name}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={containerRef}
          style={{ height: cH }}
          className={clsxm(
            cardClasses,
            'relative z-0 font-semibold [transition:height_.7s_cubic-bezier(.4,0,.2,1),all_.3s_ease-out] ',
            'bg-[rgba(255,255,255,0.8)] dark:bg-[rgba(25,23,51,0.6)] dark:[backdrop-filter:blur(9px)]',
            'text-[12px] md:text-[16px]',
            '-mt-4 ',
          )}
        >
          <StakeUnstakeMigrateRadioButton
            defaultValue={formsState.operation}
            onChange={(_) => onChangeOperation(_)}
          />

          <StakingInputs setColorBooster={setColorBooster} />

          <UnstakingInputs />

          <MigratingInputs setShowAlert={setShowAlert} />
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default Form;
