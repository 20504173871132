import {
  EnergyItemDropdownOptions,
  EnergyPositionItem,
} from '@/components/popups/ManageEnergyPopup/components/types';


import clsxm from '@/services/clsxm';
import { useAppSelector } from '@/store';
import { dropdownSelector } from '@/store/dropdown';

const BatterIcon = ({ bars }: { bars: 1 | 2 | 3 | 0 }) => (
  <svg className='w-[10px] h-[6px] md:w-[14px] md:h-[9px]' width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_363_62672)">
      <path d="M12.7314 2.9157C12.9541 2.9157 13.1309 2.90922 13.3073 2.9157C13.7758 2.93654 13.9563 3.1268 13.9568 3.5902C13.9568 4.21516 13.9605 4.84012 13.9609 5.46509C13.9609 5.9021 13.7836 6.09282 13.3397 6.11875C13.149 6.13032 12.9573 6.11875 12.7379 6.11875C12.7379 6.4229 12.7277 6.69927 12.7402 6.97425C12.7652 7.52468 12.3749 8.04733 11.6791 8.04363C8.13738 8.0245 4.59546 8.0245 1.0534 8.04363C0.390475 8.04687 -0.00209236 7.57097 -0.0011673 6.98906C0.000993729 5.34626 0.00207424 3.703 0.00207424 2.05928C0.00253677 1.51116 0.348349 0.988046 1.09136 0.992212C4.6254 1.01227 8.15913 1.01227 11.6926 0.992212C12.3726 0.992212 12.7573 1.51255 12.7337 2.03243C12.7207 2.31528 12.7314 2.59952 12.7314 2.9157ZM6.39011 1.82549C4.86273 1.82549 3.33505 1.82549 1.80706 1.82549C1.22376 1.82549 0.909426 2.14399 0.910351 2.72868C0.910351 3.92428 0.913129 5.11974 0.918684 6.31503C0.918684 6.88815 1.23024 7.19924 1.80798 7.1997C4.85502 7.1997 7.9022 7.1997 10.9495 7.1997C11.5078 7.1997 11.8245 6.88305 11.8291 6.32568C11.8341 5.13038 11.8371 3.93509 11.8384 2.73979C11.8384 2.13797 11.525 1.82549 10.9278 1.82503C9.4143 1.82534 7.90174 1.82549 6.39011 1.82549Z"
        className="fill-[#BCBCD6] dark:fill-[#5F5BA3]"
      />
      <rect x="8.5" y="2.27344" width="2" height="4.47656" rx="1" className={clsxm(
        "fill-[#BCBCD6] dark:fill-[#5F5BA3]",
        bars >= 3 ? "opacity-100" : "opacity-0"
      )} />
      <rect x="5.5" y="2.27344" width="2" height="4.47656" rx="1" className={clsxm(
        "fill-[#BCBCD6] dark:fill-[#5F5BA3]",
        bars >= 2 ? "opacity-100" : "opacity-0"
      )} />
      <rect x="2.5" y="2.27344" width="2" height="4.47656" rx="1" className={clsxm(
        "fill-[#BCBCD6] dark:fill-[#5F5BA3]",
        bars >= 1 ? "opacity-100" : "opacity-0"
      )} />
    </g>
    <defs>
      <clipPath id="clip0_363_62672">
        <rect width="7.05327" height="13.9607" fill="white" transform="matrix(0 1 -1 0 13.9609 0.976562)" />
      </clipPath>
    </defs>
  </svg>

)
const iconsMap: Record<EnergyItemDropdownOptions, JSX.Element> = {
  Charge: (
    <BatterIcon bars={3} />
  ),
  Discharge: (
    <BatterIcon bars={1} />
  ),
  Merge: (
    <svg
      className='w-[10px] h-[6px] md:w-[14px] md:h-[11px]'
      width='14'
      height='11'
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_363_62126)'>
        <path
          d='M11.9471 9.2921C10.2694 9.2921 8.31273 9.29142 7.19384 6.21604C6.07541 3.14013 4.67763 3.14063 3 3.14063L1 3.14063'
          className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.9474 3.14062C10.2697 3.14062 8.31273 3.14062 7.19384 6.21604C6.07541 9.29193 4.67763 9.29193 3 9.29193H1'
          className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5322 7.86719C10.5322 7.86719 11.3946 8.72961 11.9474 9.2824C11.3946 9.83463 10.5322 10.697 10.5322 10.697'
          className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5322 1.71875C10.5322 1.71875 11.395 2.58102 11.9477 3.13377C11.395 3.68602 10.5322 4.54861 10.5322 4.54861'
          className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_363_62126'>
          <rect width='14' height='11' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
  Remove: (
    <BatterIcon bars={0} />
  ),
  Send: (
    <svg
      className='w-[10px] h-[6.7px] md:w-[14px] md:h-[15px]'
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.1865 11.4188L11.5741 4.28695C11.8638 3.42206 11.0357 2.6013 10.1736 2.8977L3.10854 5.33002C2.14692 5.66093 2.11128 7.00718 3.05367 7.38903L5.48203 8.37212C5.75524 8.48243 5.97303 8.69849 6.08556 8.97114L7.12751 11.49C7.51671 12.4301 8.86351 12.3832 9.1865 11.4188Z'
        className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
  'Unlock to MEX': (
    <svg
      className='w-[10px] h-[6px] md:w-[14px] md:h-[15px]'
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.42718 12.7777H8.57249C9.50291 12.7777 9.96841 12.7777 10.3277 12.6052C10.6859 12.433 10.9752 12.1438 11.1473 11.7852C11.32 11.4261 11.32 10.9608 11.32 10.0302V8.78657C11.32 7.85598 11.32 7.39072 11.1473 7.03156C10.9752 6.67298 10.6859 6.38371 10.3277 6.21155C9.96841 6.03906 9.50291 6.03906 8.57249 6.03906H5.42718C4.49676 6.03906 4.03126 6.03906 3.67193 6.21155C3.31377 6.38371 3.02443 6.67298 2.85235 7.03156C2.67968 7.39072 2.67969 7.85598 2.67969 8.78657V10.0302C2.67969 10.9608 2.67968 11.4261 2.85235 11.7852C3.02443 12.1438 3.31377 12.433 3.67193 12.6052C4.03126 12.7777 4.49676 12.7777 5.42718 12.7777Z'
        className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.95728 9.40991C7.95728 9.93829 7.52911 10.3667 7.00061 10.3667C6.47211 10.3667 6.04395 9.93829 6.04395 9.40991C6.04395 8.88158 6.47211 8.45312 7.00061 8.45312C7.52911 8.45312 7.95728 8.88158 7.95728 9.40991Z'
        className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.44732 3.96501C8.97715 2.6143 7.50079 1.90094 6.15038 2.3714C5.1161 2.7312 4.4196 3.70338 4.4126 4.79865V6.0296'
        className="stroke-[#BCBCD6] dark:stroke-[#5F5BA3]"
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
};

const DropdownItem = ({
  label,
  onclick,
  className,
  type,
}: {
  label: string;
  className: string;
  onclick: () => void;
  type: EnergyItemDropdownOptions;
}) => (
  <div
    onClick={onclick}
    className={clsxm(
      'w-full flex gap-[3px] md:gap-[4px] items-center cursor-pointer',
      className,
      type === "Unlock to MEX" && " translate-x-[-2px] md:translate-x-0"
    )}
  >
    <span className={clsxm(
      type === "Unlock to MEX" && "translate-x-[-0.25px]", 
      type === "Merge"&& " translate-y-[-0.4px] md:translate-y-0",
      )}>
      {iconsMap[type]}
    </span>
    <span
      className={clsxm(
        'text-[6px] leading-[7.5px] font-semibold ',
        'md:text-[12px] md:leading-[15px]',
        'dark:text-[#625E8F] text-[#6A6A8C]',
        'dark:text-[#C1BCE8]',
        'whitespace-nowrap',
        type === "Unlock to MEX" && " translate-x-[-2.5px] md:translate-x-[0.5px]",
        type === "Remove"&& " translate-x-[0.5px] md:translate-x-0",
        type === "Merge"&& " translate-x-[0.5px] md:translate-x-0",
        type === "Send"&& " translate-x-[0.2px] md:translate-x-0"
      )}
    >
      {label}
    </span>
  </div >
);


export const ManageEnergyPositionRowActionsDropdown = () => {
  const { data } = useAppSelector(dropdownSelector)

  const items: EnergyPositionItem['dropDownOptions'] = data.items

  if (!items) return null

  return (
    <div
      className={clsxm(
        'absolute right-0 top-[calc(100%_+_12px)]',
        'w-[55px] md:w-[119px]',
        'overflow-hidden',
        '!z-[999]',
      )}
    >
      <div
        className={clsxm(
          'relative w-full overflow-hidden',
          'md:border rounded-[3px] md:rounded-[6px]',
          '!z-[999]',
          'bg-[#EDEFFF] dark:bg-[#292651]  border-[#E7E7F9] dark:border-[#534E97]',
          'px-[2px] md:px-[8px]',
        )}
      >
        {items.map((item) => (
          <DropdownItem
            className='!h-[12px] md:!h-[27px] flex items-center'
            label={item as string}
            type={item}
            onclick={() => { }}
          />
        ))}
      </div>
    </div>
  );
}

