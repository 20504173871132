import React from 'react';
import { useTranslation } from 'next-i18next';

const useTableData = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('Snapshot'),
      style: 'px-3',
      tooltip: 'tooltip-airdrop-snapshot',
      components: [<span className='text-[#F0B90B]' />],
      tooltipStyle: '',
    },
    {
      label: t('snapshot-date'),
      style: 'pl-[8px]',
      tooltip: 'tooltip-airdrop-date',
      components: [<span className='text-[#F0B90B]' />],
      tooltipStyle: 'max-w-[166px] md:max-w-[208px]',
    },
    {
      label: t('collateral-value'),
      style: 'pl-[37px] ',
      tooltip: 'tooltip-airdrop-collateral-value',
      components: [
        <span className='text-[#F0B90B]' />,
        <span className='text-[#51C392]' />,
      ],
      tooltipStyle: 'max-w-[220px] md:max-w-[276px]',
    },
    {
      label: t('htm-staked-2'),
      style: 'pl-[18px]',
      tooltip: 'tooltip-airdrop-htm-staked',
      components: [
        <span className='text-[#F0B90B]' />,
        <span className='text-[#E24949]' />,
        <span className='text-[#429E76]' />,
      ],
      tooltipStyle: 'max-w-[257px] md:max-w-[321px]',
    },
    {
      label: 'Boost %',
      style: 'pl-[18px]',
      tooltip: 'tooltip-airdrop-boost',
      components: [<span className='text-[#E24949]' />],
      tooltipStyle: 'max-w-[180px] md:max-w-[208px]',
    },
    {
      label: 'Collateral %',
      style: 'pl-[18px]',
      tooltip: 'tooltip-airdrop-collateral-percentage',
      components: [
        <span className='text-[#429E76]' />,
        <span className='text-[#51C392]' />,
      ],
      tooltipStyle: 'max-w-[246px] md:max-w-[307px]',
    },
    {
      label: t('eligibility'),
      style: 'pl-[18px]',
      tooltip: 'tooltip-airdrop-elegibility',
      components: [
        <span className='text-[#F0B90B]' />,
        <span className='text-[#51C392]' />,
        <span className='text-[#6BD1FF]' />,
      ],
      tooltipStyle: 'max-w-[255px] md:max-w-[318px]',
    },
    {
      label: t('pool-share'),
      style: 'pl-[20px]',
      tooltip: 'tooltip-airdrop-pool-share',
      components: [<span className='text-[#F0B90B]' />],
      tooltipStyle: 'max-w-[306px] md:max-w-[386px]',
    },
    {
      label: t('amount-earned'),
      style: 'pl-[24px] pr-2',
      tooltip: 'tooltip-airdrop-amount-earned',
      components: [
        <span className='text-[#429E76]' />,
        <span className='text-[#E24949]' />,
        <span className='text-[#51C392]' />,
        <span className='text-[#51C392]' />,
        <span className='text-[#6BD1FF]' />,
        <span className='text-[#F0B90B]' />,
      ],
      tooltipStyle: 'max-w-[348px] md:max-w-[434px]',
    },
  ];
};

export default useTableData;
