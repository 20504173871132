/* eslint-disable react-hooks/exhaustive-deps */
import { BigUIntValue } from '@multiversx/sdk-core/out';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import { nativeMarketSelector, protocolSelector } from '@/store/protocol';

export enum ISOLATED_LENDING_PROTOCOL_METHOD {
  ADD_COLLATERAL = 'addCollateral',
  REMOVE_COLLATERAL = 'removeCollateral',
  BORROW = 'borrow',
  REPAY_BORROW = 'repayBorrow',
}

const useIsolatedLendingProtocolInteraction = () => {
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const { isolatedLendingProtocols } = useAppSelector(protocolSelector);

  const { buildTransaction } = useSignMultipleTransactions();

  const addCollateral = ({
    isolatedKey,
    amount,
    identifier,
  }: {
    isolatedKey: string;
    identifier: string;
    amount: string;
  }) => {
    const isEsdtToken = identifier !== nativeMarket.underlying.symbol;

    return buildTransaction({
      smartContractAddress:
        isolatedLendingProtocols[isolatedKey as 'EGLD' | 'WTAO'].address,
      func: ISOLATED_LENDING_PROTOCOL_METHOD.ADD_COLLATERAL,
      group: 'isolated-lending-protocol',
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: identifier,
              amount,
              isFromBigInteger: true,
            },
          }
        : {
            value: amount,
            isFromBigInteger: true,
          }),
    });
  };

  const collateralTypeMap = {
    HLSToken: 0,
    LSToken: 1,
    Token: 2,
  };

  const removeCollateral = ({
    isolatedKey,
    collateralType,
    amount,
  }: {
    collateralType: keyof typeof collateralTypeMap;
    isolatedKey: string;
    amount?: string;
  }) => {
    return buildTransaction({
      smartContractAddress:
        isolatedLendingProtocols[isolatedKey as 'EGLD' | 'WTAO'].address,
      func: ISOLATED_LENDING_PROTOCOL_METHOD.REMOVE_COLLATERAL,
      group: 'isolated-lending-protocol',
      args: [
        new BigUIntValue(collateralTypeMap[collateralType]),
        ...(amount ? [new BigUIntValue(amount)] : []),
      ],
    });
  };

  const borrow = ({
    isolatedKey,
    amount,
  }: {
    isolatedKey: string;
    amount: string;
  }) => {
    return buildTransaction({
      smartContractAddress:
        isolatedLendingProtocols[isolatedKey as 'EGLD' | 'WTAO'].address,
      func: ISOLATED_LENDING_PROTOCOL_METHOD.BORROW,
      group: 'isolated-lending-protocol',
      args: [new BigUIntValue(amount)],
    });
  };

  const repayBorrow = ({
    isolatedKey,
    identifier,
    amount,
  }: {
    isolatedKey: string;
    identifier: string;
    amount: string;
  }) => {
    const isEsdtToken = identifier !== nativeMarket.underlying.symbol;

    return buildTransaction({
      smartContractAddress:
        isolatedLendingProtocols[isolatedKey as 'EGLD' | 'WTAO'].address,
      func: ISOLATED_LENDING_PROTOCOL_METHOD.REPAY_BORROW,
      group: 'isolated-lending-protocol',
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: identifier,
              amount,
              isFromBigInteger: true,
            },
          }
        : {
            value: amount,
            isFromBigInteger: true,
          }),
    });
  };

  return {
    addCollateral,
    removeCollateral,
    borrow,
    repayBorrow,
  };
};

export default useIsolatedLendingProtocolInteraction;
