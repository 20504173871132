import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';

import { ReturnUseBorrowData } from '@/components/popups/BorrowPopup/hooks/useBorrowData';
import { ReturnUseBorrowForm } from '@/components/popups/BorrowPopup/hooks/useBorrowForm';

import {
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

const useBorrowSubmit = ({
  borrowForm,
  borrowData,
}: {
  borrowForm: ReturnUseBorrowForm;
  borrowData: ReturnUseBorrowData;
}) => {
  const { inputValueAsBigNumber, inputValue, isBorrowTab, isRepayTab } =
    borrowForm;

  const {
    market,
    hasPendingTransactions,
    marketsInteractedAmount,
    marketsInteracted,
    controller,
  } = borrowData;

  const { signTransactions } = useSignMultipleTransactions();
  const { removeAccountMarket, borrow, repayBorrow } = useLendInteraction();

  const handleSubmitInteraction = async () => {
    // VALIDATIONS
    if (hasPendingTransactions) {
      return;
    }

    if (isBorrowTab) {
      const marketInteractedAvaiableToRemove = marketsInteracted.find(
        ({ canRemove }) => canRemove,
      );

      const hasMaxMarketPerAccountByController =
        marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
        !marketsInteracted
          .map(({ address }) => address)
          .includes(market.address) &&
        (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

      await signTransactions(
        [
          ...(hasMaxMarketPerAccountByController
            ? [
                removeAccountMarket(
                  marketInteractedAvaiableToRemove?.address || '',
                ),
              ]
            : []),
          borrow({
            tokenKey: market.underlying.symbol,
            amountAsBigInteger: inputValueAsBigNumber,
          }),
        ],
        {
          token: market.underlying.symbol,
          group: TRANSACTION_GROUP_TYPE.LENDING,
          subgroup: TRANSACTION_SUBGROUP_TYPE.BORROW,
          result: inputValue,
          isSecuencial: hasMaxMarketPerAccountByController,
        },
      );
    }

    if (isRepayTab) {
      await signTransactions(
        [
          repayBorrow({
            tokenKey: market.underlying.symbol,
            amountAsBigInteger: inputValueAsBigNumber,
          }),
        ],
        {
          token: market.underlying.symbol,
          group: TRANSACTION_GROUP_TYPE.LENDING,
          subgroup: TRANSACTION_SUBGROUP_TYPE.REPAY_BORROW,
          result: inputValue,
        },
      );
    }
  };

  return {
    handleSubmitInteraction,
  };
};

export default useBorrowSubmit;
