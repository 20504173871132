import DefiUtils from 'defi-utils';

import { AccountBoosterReward as BoosterAccountBoosterReward } from '@/store/booster';
import { MARKET_KEY, Token, TOKEN_LOGO_V2_MAP } from '@/store/protocol';

import {
  AccountBoosterV1Rewards,
  BoosterV1AccountClaims,
} from '@/services/blockchain/lens/types';

export type ParsedBoosterAccount = Record<
  MARKET_KEY,
  {
    speed: string;
    amount: string;
    reward: {
      price: string;
      decimals: number;
      token: string;
      symbol: string;
    };
    marketBooster: {
      priceIntegral: string;
      priceIntegralTimestamp: number;
    };
    accountMarketBooster: {
      staked: string;
      priceIntegral: string;
      priceIntegralTimestamp: number;
    };
    rewards: string;
    totalBoosterApy: string;
    accountBoosterApy: string;
    accountBoosterAPYWithPenalty: string;
    maxAccountBoosterApy: string;
    hasCollateral: boolean;
    percentageBoost: string;
  }
>;

export const formatClaims = ({
  boosterAccountClaims,
  accountAddress,
}: {
  boosterAccountClaims: BoosterV1AccountClaims[];
  accountAddress: string;
}) => {
  return boosterAccountClaims
    .filter(({ claimed }) => !claimed)
    .map((claimItem) => ({
      ...claimItem,
      id: `${accountAddress}-${claimItem.id}`,
    }));
};

export const formatAccountRewardsWithoutIndexer = ({
  accountBoosterRewards,
  tokensMap,
  rewardsPrices,
}: {
  accountBoosterRewards: AccountBoosterV1Rewards[];
  tokensMap: Record<string, Token>;
  rewardsPrices: Record<string, string>;
}): BoosterAccountBoosterReward[] => {
  return accountBoosterRewards.map(({ tokenId, rewards }) => {
    const tokenSymbol = tokenId.split('-')[0];
    const priceUSD = rewardsPrices[tokenSymbol] || '0';

    const {
      decimals = 18,
      symbol = '',
      name = '',
    } = tokensMap[tokenSymbol] || {};

    const userRewardBalanceAsBigNumber = rewards;

    const userRewardBalance = new DefiUtils(userRewardBalanceAsBigNumber)
      .toFullDecimals(decimals)
      .removeScientificNotation();

    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(priceUSD)
      .removeScientificNotation();

    return {
      tokenId,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol,
      priceUSD,
      logo:
        TOKEN_LOGO_V2_MAP[symbol] ||
        'https://cdn.app.hatom.com/images/coin_placeholder.png',
      name,
      decimals,
    };
  });
};

export const formatAccountRewards = ({
  boosterAccount,
}: {
  boosterAccount: ParsedBoosterAccount;
}): BoosterAccountBoosterReward[] => {
  const accountBoosterRewardsMap = Object.values(boosterAccount).reduce(
    (prev, { reward, rewards }) => {
      const key = reward.token;

      return {
        ...prev,
        [key]: {
          rewards: new DefiUtils(prev[key]?.rewards || '0')
            .plus(rewards)
            .toString(),
          reward,
        },
      };
    },
    {} as Record<
      string,
      {
        rewards: string;
        reward: {
          price: string;
          decimals: number;
          token: string;
          symbol: string;
        };
      }
    >,
  );

  return Object.values(accountBoosterRewardsMap).map(({ reward, rewards }) => {
    const userRewardBalance = new DefiUtils(rewards).removeScientificNotation();

    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(reward.price)
      .removeScientificNotation();

    return {
      tokenId: reward.token,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol: reward.symbol,
      priceUSD: reward.price,
      logo:
        TOKEN_LOGO_V2_MAP[reward.symbol] ||
        'https://cdn.app.hatom.com/images/coin_placeholder.png',
      name: reward.symbol,
      decimals: reward.decimals,
    };
  });
};

export const getAccumulatorsPairsIds = (
  accumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[],
) => {
  return accumulators
    .map(({ rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];

      return tokensIds
        .slice(0, -1)
        .map((tokenId, index) => `${tokenId}/${tokensIds[index + 1]}`);
    })
    .flat();
};

export const formatBoosterAccumulators = ({
  accumulators,
  pairs,
}: {
  accumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[];
  pairs: {
    pairsIds: string;
    exchangeRate: string;
  }[];
}) => {
  const pairsMap = pairs.reduce(
    (prev, { pairsIds, exchangeRate }) => ({
      ...prev,
      [pairsIds]: exchangeRate,
    }),
    {} as Record<string, string>,
  );

  const boosterBoosters = accumulators.reduce(
    (prev, { premium, rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];
      const exchangeRates = tokensIds
        .slice(0, -1)
        .map(
          (tokenId, index) =>
            pairsMap[`${tokenId}/${tokensIds[index + 1]}`] || '0',
        );

      const exchangeRate = exchangeRates
        .reduce((prev, current) => prev.multipliedBy(current), new DefiUtils(1))
        .toString();

      return {
        ...prev,
        [rewardsToken.symbol]: {
          premium,
          exchangeRate,
        },
      };
    },
    {} as Record<string, { premium: string; exchangeRate: string }>,
  );

  return boosterBoosters;
};
