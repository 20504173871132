import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import useTheme from '@/hooks/useTheme';

import clsxm from '@/services/clsxm';

import Hint from './Hint';
import InfoIcon from './Icons/InfoIcon';

type Props = {
  path: string;
  name: any;
  sub?: any;
  shortSpace?: boolean;
  className?: string;
  textClassName?: string;
  imageClassName?: string;
  imageContainerClassName?: string;
  alt?: string;
  night?: any;
  notavailable?: boolean;
  withoutAvatar?: boolean;
  bed?: boolean;
  subsOpacity?: boolean;
};

const LabeledImage = ({
  path,
  name,
  className,
  imageContainerClassName,
  textClassName,
  imageClassName,
  sub,
  night,
  notavailable,
  withoutAvatar,
  bed = true,
}: Props) => {
  const { isDark } = useTheme();
  const { t } = useTranslation();

  return (
    <figure
      className={classNames('flex  gap-3', className, {
        'items-center': !sub,
      })}
    >
      {!withoutAvatar && (
        <div>
          <div
            className={classNames(
              'h-8 w-8 sm:h-10 sm:w-10',
              'rounded-[12px] md:rounded-2xl',
              'flex items-center justify-center',
              {
                'p-0.5 sm:p-1': bed,
                'theme-switch-transition bg-[#CECEE8] dark:bg-[#433D77]': bed,
              },
              imageContainerClassName,
            )}
          >
            <img
              className={classNames(
                'asset-avatar',
                'overflow-hidden rounded-full',
                'h-full w-full',
                imageClassName,
              )}
              src={isDark && night ? night : path}
              alt=''
            />
          </div>
        </div>
      )}
      <div
        className={clsxm(
          'gap-0.1 relative flex flex-col items-start font-semibold leading-5',
          textClassName,
        )}
      >
        <span className='relative flex gap-0.5 whitespace-nowrap'>
          {name}{' '}
          {notavailable && (
            <Hint placement='top-center' content={t('not-available-asset')}>
              <button className='origin-top translate-y-[3px] scale-[.8] hover:opacity-80 sm:translate-y-0.5 sm:scale-100'>
                <InfoIcon />
              </button>
            </Hint>
          )}
        </span>
        {sub}
      </div>
    </figure>
  );
};

export default LabeledImage;
