import classNames from 'classnames';
import format from 'date-fns/format';
import DefiUtils from 'defi-utils';
import React from 'react';

import { formatNumber, nFormatter } from '@/utils/helpers';
import clsxm from '@/services/clsxm';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useRouter } from 'next/router';
import Hint from '@/components/Hint';
import { useHintProps } from '@/sections/Liquid/App/global';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';
import { useAppSelector } from '@/store';
import { sidebarSelector } from '@/store/sidebar';

const isLend = (pathname: string) => {
  return pathname.includes('/lend');
};
const isUsh = (pathname: string) => {
  return pathname.includes('/ush');
};

const tokenImgMap = {
  sEGLD: 'https://cdn.app.hatom.com/images/sEGLD.svg',
  HsEGLD:
    'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
};

const HintableCellBare: React.FC<any> = ({ children, content }) => {
  const defaultHintProps = useHintProps();

  return (
    <Hint
      {...defaultHintProps}
      content={content}
      layerStyle={{ textAlign: 'center', minWidth: 30 }}
    >
      {children}
    </Hint>
  );
};

const HintableCell = React.memo(HintableCellBare);

function ClaimsAndRequestsUSHRow({
  token,
  value,
  valueEGLD,
  valueUSD,
  expireIn,
  tokenNonce,
  actions,
  isUnstake,
  _isUsh = true,
  source,
}: any) {
  const md = useMediaQuery('(min-width: 768px)');
  const router = useRouter();
  const { expanded } = useAppSelector(sidebarSelector);

  const md1 =
    isLend(router.pathname) || isUsh(router.pathname)
      ? expanded
        ? useMediaQuery('(min-width: 1394px)')
        : useMediaQuery('(min-width: 1344px)')
      : useMediaQuery('(min-width: 980px)');

  return (
    <div
      key={tokenNonce}
      className={classNames(
        'h-[19px] md:h-[26px] w-full text-[#2F2F2F] dark:text-[#625E8F]',
        'flex  items-center justify-between ',
        'text-[8px]',
        'md:text-[12px]',
        md1 && 'xl:text-[14px]',
      )}
    >
      <div
        className={clsxm(
          'flex  items-center justify-between w-[252.1px] md:w-[373.6px]',
          md1 && !isUnstake && 'xl:w-[775.4px]',
          md1 && isUnstake && 'xl:w-[694.4px]',
        )}
      >
        <div
          className={classNames(
            'flex items-center gap-[2px] md:gap-1 justify-self-start ',
            'w-[75px]  md:w-[130px] mr-[-8px] ',
            md1 && 'xl:mr-0 xl:h-[26px]',
            md1 && !isUnstake && 'xl:w-[123.2px]',
            md1 && isUnstake && 'xl:w-[138.2px]',
          )}
        >
          <div className='w-[12.5px] md:w-[18px] rounded-full'>
            <img src={tokenImgMap[token as keyof typeof tokenImgMap]} alt='' />
          </div>
          <HintableCell
            content={`${formatNumber(
              value,
              new DefiUtils(value).decimalPlaces(),
            )} ${token}`}
          >
            {nFormatter(value)} {token}
          </HintableCell>
        </div>

        <div
          className={clsxm(
            'flex items-center gap-[2px] md:gap-1',
            'w-[72px] md:w-[123px] ',
            md1 &&
              'xl:w-[124.75px] xl:translate-x-[-10.5px] xl:justify-end xl:gap-[4px]',
          )}
        >
          <div className='w-[11.5px] md:w-[18px] rounded-full'>
            <img src='https://cdn.app.hatom.com/images/img1.svg' alt='' />
          </div>
          <HintableCell content={`${valueEGLD}`}>
            <div
              className={clsxm(' flex-grow flex justify-between items-center')}
            >
              <span>{nFormatter(valueEGLD, 2)} </span>
              {md1 && <span className='hidden lg:inline'>EGLD</span>}
            </div>
          </HintableCell>
        </div>

        <div
          className={clsxm(
            'flex items-center  ',
            'w-[54px] md:w-[98px] ml-[-7px] md:ml-0 ',
            md1 && 'xl:w-[91.45px] xl:justify-end',
            isUnstake &&
              _isUsh &&
              'translate-x-[-5px] bridge2xl:translate-x-[27px]',
            isUnstake && !_isUsh && 'translate-x-[-5px] xl:translate-x-[27px]',
          )}
        >
          <HintableCell
            content={`$${formatNumber(
              valueUSD,
              new DefiUtils(valueUSD).decimalPlaces() > 6
                ? 6
                : new DefiUtils(valueUSD).decimalPlaces(),
            )}`}
          >
            {nFormatter(valueUSD)}{' '}
            {md1 && <span className='hidden lg:inline'>USD</span>}
          </HintableCell>
        </div>
      </div>

      {expireIn && (
        <HintableCell
          content={
            <div className='text-end md:text-start'>
              {format(expireIn, 'dd/MM/yy')}
              <span className='inline'>
                {' at '}
                {format(expireIn, 'hh:mm a')}
              </span>
            </div>
          }
        >
          <div
            className={clsxm(
              'flex gap-1 md:items-center',
              md1 &&
                isUnstake &&
                'xl:ml-[-35px] xl:gap-[2.4px] xl:tracking-normal',
            )}
          >
            {/* TimerAnimation */}
            <WrappedLottiePlayer
              className={clsxm('w-[8.8px] md:w-[12px]', md1 && 'xl:w-[15px]')}
              src='https://cdn.app.hatom.com/animations/clock.json'
            />

            <div className='text-end md:text-start'>
              {format(expireIn, 'dd/MM/yy')}
              <span
                className={clsxm(
                  'hidden ',
                  expanded ? 'bridge4xl:inline' : 'bridge2xl:inline',
                )}
              >
                {' at '}
                {format(expireIn, 'hh:mm a')}
              </span>
            </div>
          </div>
        </HintableCell>
      )}

      {actions({ all: false, lg: false }).length ? (
        <div
          className={clsxm(
            ' flex justify-end items-center h-[26px] grow ',
            md1 && 'xl:translate-x-[-0.3px]',
          )}
        >
          {actions({
            all: false,
            lg: false,
            key: tokenNonce.toString(),
            nonce: tokenNonce,
            valueEGLD,
          }).map((button) => button)}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ClaimsAndRequestsUSHRow;
