import DefiUtils from 'defi-utils';

import {
  TRANSACTION_SUBGROUP_TYPE,
  TransactionConfig,
} from '@/store/transaction';

import clsxm from '@/services/clsxm';

export const getFormControlClasses = (isVisible = true) => {
  return clsxm(
    'absolute inset-x-[18px]',
    isVisible
      ? 'active z-10 opacity-100 scale-100 [transition:opacity_.7s_cubic-bezier(.4,0,.2,1),transform_.7s_cubic-bezier(.4,0,.2,1)]'
      : 'opacity-0 scale-[0.98] [transition:opacity_.3s_cubic-bezier(.4,0,.2,1),transform.3s_cubic-bezier(.4,0,.2,1)]',
  );
};

export const divideValuePerMax = (value: string, max: string) => {
  const length = parseInt(
    new DefiUtils(value).dividedBy(max).toFixed(0, DefiUtils.ROUND_FLOOR),
  );

  const remainingAmount = new DefiUtils(value).modulo(max).toString();

  const hasRemaining = remainingAmount !== '0';

  return [
    ...new Array(length).fill(0).map(() => max),
    ...(hasRemaining ? [remainingAmount] : []),
  ];
};

export const getLiquidTransactionGradient = (name: string) => {
  const map = {
    // liquid locking
    [TRANSACTION_SUBGROUP_TYPE.UNLOCK]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.LOCK]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.UNBOND]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.UNBOND_ALL]: ['#ff4040', '#ff6363'],

    // booster
    [TRANSACTION_SUBGROUP_TYPE.STAKE_HTM]: ['#5AF1B0', '#00000080'],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM]: ['#5AF1B0', '#000000'],
    [TRANSACTION_SUBGROUP_TYPE.REALLOCATE_HTM]: ['#C6C151', '#B2F6FF'],
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM]: ['#4FC995', '#6FE6B3'],
    [TRANSACTION_SUBGROUP_TYPE.STAKE_CLAIM_HTM]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS]: ['#ff4040', '#ff6363'],

    // liquid staking
    [TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING]: ['#5097FF', '#44CEFF'],
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN]: ['#5097FF', '#44CEFF'],
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL]: ['#5097FF', '#44CEFF'],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL]: [
      '#7167E3',
      '#978FF5',
    ],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN]: ['#7167E3', '#978FF5'],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING]: ['#7167E3', '#978FF5'],
    [TRANSACTION_SUBGROUP_TYPE.CLAIM]: ['#E25F00', '#FF9C55'],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
  } as Record<string, string[]>;

  return map[name] || [];
};

export const getLiquidTransactionName = (config: TransactionConfig) => {
  const map = {
    // liquid locking
    [TRANSACTION_SUBGROUP_TYPE.LOCK]: `Staking ${config.token}`,
    [TRANSACTION_SUBGROUP_TYPE.UNLOCK]: `Unstaking ${config.token}`,
    [TRANSACTION_SUBGROUP_TYPE.UNBOND]: `Claiming ${config.token}`,
    [TRANSACTION_SUBGROUP_TYPE.UNBOND_ALL]: 'Claiming',

    // booster
    [TRANSACTION_SUBGROUP_TYPE.STAKE_HTM]: `Staking HTM`,
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM]: `Unstaking HTM`,
    [TRANSACTION_SUBGROUP_TYPE.REALLOCATE_HTM]: `Migrating HTM`,
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM]: `Claiming HTM`,
    [TRANSACTION_SUBGROUP_TYPE.STAKE_CLAIM_HTM]: `Restaking HTM`,
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS]: 'Claiming Rewards',

    // liquid staking
    [TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING]: 'Staking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN]: 'Staking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL]: 'Staking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL]: 'Unstaking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN]: 'Unstaking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING]: 'Unstaking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.CLAIM]: 'Claiming EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL]:
      'Migrating EGLD',
  } as Record<string, string>;

  return map[config.subgroup] || '';
};
