import LabeledImage from '@/components/LabeledImage';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';

function UshCollateralPopupHead({
  name,
  path,
  night,
}: {
  name: string;
  path: string;
  night: string;
}) {
  return (
    <PopupHead>
      <LabeledImage
        className='gap-[8px]'
        name={name}
        path={path}
        night={night}
      />
    </PopupHead>
  );
}

export default UshCollateralPopupHead;
