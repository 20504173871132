import DefiUtils from 'defi-utils';

import { WAD, WAD_WAD } from '@/data/constants';

import { AccountToken } from '@/store/auth';
import { BoosterV2State } from '@/store/booster-v2';
import {
  ASSET_ALT_NAME,
  MARKET_KEY,
  ProtocolState,
  Token,
  TOKEN_LOGO_V2_MAP,
  TOKEN_SYMBOL_MAP,
} from '@/store/protocol';

import {
  AccountBoosterV2RewardsData,
  AccountExtraAmountData,
  AccountStakeData,
  BoosterV2AccountClaim,
  BoosterV2BatchData,
  BoosterV2RewardsAccruedPerMarket,
  BoosterV2TokenPriceUsdData,
} from '@/services/blockchain/lens/types';
import {
  calcExtraCompliance,
  getBaseBoosterAPY,
  getGlobalBoosterAPY,
  getStandartBoosterAPY,
  getUserBaseAPY,
  getUserExtraBoosterAPY,
  getUserStandartBoosterAPY,
} from '@/utils/math/apy';

export const formatAccount = ({
  stakeTokens,
  accountInstaCompliance,
}: {
  stakeTokens: BoosterV2State['controllerStakeTokens'];
  accountInstaCompliance: string;
}): Partial<BoosterV2State['controllerAccount']> => {
  const instaCompliance = new DefiUtils(accountInstaCompliance)
    .dividedBy(WAD)
    .toString();

  const s_instaCompliance = new DefiUtils(instaCompliance)
    .multipliedBy(100)
    .toString();

  return {
    topCompliance: DefiUtils.min(1, instaCompliance).toString(),
    s_topCompliance: DefiUtils.min(100, s_instaCompliance).toString(),
    totalStakedUSD: stakeTokens
      .reduce(
        (prev, current) =>
          prev.plus(
            new DefiUtils(current.account.s_stakedBalance).toUSD(
              current.priceUSD,
            ),
          ),
        new DefiUtils(0),
      )
      .toString(),
    instaCompliance,
    s_instaCompliance,
    totalCollateralUSD: '0',
    maxTotalStakedUSD: '0',
  };
};

export const formatAccountRewardsPerMarket = ({
  boosterV2AccountRewardsPerMarket,
  tokensMap,
  markets,
}: {
  boosterV2AccountRewardsPerMarket: BoosterV2RewardsAccruedPerMarket[];
  tokensMap: Record<string, Token>;
  markets: ProtocolState['markets'];
}): BoosterV2State['controllerAccountRewardsPerMarket'] => {
  return boosterV2AccountRewardsPerMarket.map(
    ({ tokenId, rewardsId, amount }) => {
      const rewardDetailsItem = tokensMap[rewardsId.split('-')[0]];

      const symbol = rewardDetailsItem.symbol;
      const rewardItem = markets[symbol as MARKET_KEY];

      const decimals =
        rewardItem?.underlying.decimals || rewardDetailsItem?.decimals || 1;
      const priceUSD = rewardItem?.underlying.priceUSD || '0';

      const s_amount = new DefiUtils(amount)
        .toFullDecimals(decimals)
        .toString();
      const amountUSD = new DefiUtils(s_amount).toUSD(priceUSD).toString();

      return {
        tokenId,
        reward: {
          id: rewardsId,
          decimals,
          symbol,
          priceUSD,
        },
        s_amount,
        amountUSD,
      };
    },
  );
};

export const formatAccountRewards = ({
  accountBoosterRewardsList,
  tokensMap,
  markets,
}: {
  accountBoosterRewardsList: AccountBoosterV2RewardsData[];
  tokensMap: Record<string, Token>;
  markets: ProtocolState['markets'];
}): BoosterV2State['controllerAccountRewards'] => {
  return accountBoosterRewardsList.map(({ tokenId, amount }) => {
    const tokenDetailsItem = tokensMap[tokenId.split('-')[0]];

    const symbol = tokenDetailsItem.symbol;
    const decimals = tokenDetailsItem?.decimals || 1;
    const marketItem = markets[symbol as MARKET_KEY];
    const rewardItem = markets[symbol as MARKET_KEY];

    const priceUSD =
      marketItem?.underlying.priceUSD || String(tokenDetailsItem?.price || 0);

    const userRewardBalance = new DefiUtils(amount)
      .toFullDecimals(decimals)
      .toString();
    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(priceUSD)
      .toString();

    return {
      tokenId,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol,
      priceUSD,
      logo: rewardItem?.logo.normal,
      name: ASSET_ALT_NAME[symbol] || rewardItem?.underlying.name || '',
      decimals,
    };
  });
};

const formatStakeToken = ({
  id,
  accountTokens,
  accountStakes,
  tokensMap,
  markets,
  boosterV2TokenPriceUSD,
  isMetaEsdt,
}: {
  id: string;
  accountTokens: AccountToken[];
  accountStakes: AccountStakeData[];
  tokensMap: Record<string, Token>;
  markets: ProtocolState['markets'];
  boosterV2TokenPriceUSD: BoosterV2TokenPriceUsdData[];
  isMetaEsdt: boolean;
}) => {
  const tokenDetailsItem = tokensMap[id.split('-')[0]];

  const accountTokenDetails = accountTokens.find(
    (token) => token.tokenIdentifier === id,
  );

  const nonce = isMetaEsdt ? id.split('-')[2] : '0';

  const accountStakeItem = accountStakes.find(
    (item) =>
      id.includes(item.tokenId) && item.nonce == String(parseInt(nonce, 16)),
  );

  const defaultSymbol = tokenDetailsItem.symbol;

  const symbol = TOKEN_SYMBOL_MAP[defaultSymbol] || defaultSymbol;

  const marketItem = markets[tokenDetailsItem.symbol as MARKET_KEY];

  const boosterV2TokenPriceUSDItem = boosterV2TokenPriceUSD.find((item) =>
    id.includes(item.tokenId),
  );

  const priceUSD = boosterV2TokenPriceUSDItem
    ? new DefiUtils(boosterV2TokenPriceUSDItem.amount).dividedBy(WAD).toString()
    : marketItem?.underlying.priceUSD || '0';

  const decimals = tokenDetailsItem?.decimals || 1;
  const walletBalance = accountTokenDetails?.balance || '0';
  const stakedBalance = accountStakeItem?.amount || '0';
  const s_walletBalance = new DefiUtils(walletBalance)
    .toFullDecimals(decimals)
    .toString();
  const s_stakedBalance = new DefiUtils(stakedBalance)
    .toFullDecimals(decimals)
    .toString();

  const stakedBalanceUSD = new DefiUtils(s_stakedBalance)
    .toUSD(priceUSD)
    .toString();

  const walletBalanceUSD = new DefiUtils(s_walletBalance)
    .toUSD(priceUSD)
    .toString();

  const logo = TOKEN_LOGO_V2_MAP[defaultSymbol] || '';

  return {
    id,
    nonce,
    isMetaEsdt,
    defaultSymbol,
    symbol,
    decimals,
    priceUSD,
    logo,
    account: {
      walletBalance,
      stakedBalance,
      s_walletBalance,
      s_stakedBalance,
      stakedBalanceUSD,
      walletBalanceUSD,
    },
  };
};

const ORDER_STAKE_TOKENS: Record<string, number> = {
  HTM: 1,
  SHTM: 2,
  HTMWEGLD: 3,
  METAHTMLK: 5,
  HTMWEGLDFL: 4,
};

export const formatStakeTokens = ({
  stakes,
  accountTokens,
  tokensMap,
  accountStakes,
  markets,
  boosterV2TokenPriceUSD,
}: {
  stakes: string[];
  tokensMap: Record<string, Token>;
  accountTokens: AccountToken[];
  accountStakes: AccountStakeData[];
  markets: ProtocolState['markets'];
  boosterV2TokenPriceUSD: BoosterV2TokenPriceUsdData[];
}): BoosterV2State['controllerStakeTokens'] => {
  const results = stakes
    .map((id) => {
      const tokenDetailsItem = tokensMap[id.split('-')[0]];

      const isMetaEsdt = tokenDetailsItem?.type === 'MetaESDT';

      const accountMetaTokenDetails = accountTokens.filter((token) =>
        token.tokenIdentifier.includes(id),
      );

      const foundedAccountStakes = isMetaEsdt
        ? accountStakes.filter((item) => id.includes(item.tokenId))
        : [];

      const metaTokenIds: string[] = [
        ...(new Set([
          ...foundedAccountStakes.map(
            ({ tokenId, nonce }) =>
              `${tokenId}-${parseInt(nonce).toString(16).padStart(2, '0')}`,
          ),
          ...accountMetaTokenDetails.map(
            ({ tokenIdentifier }) => tokenIdentifier,
          ),
        ]) as any),
      ];

      if (!isMetaEsdt || metaTokenIds.length === 0) {
        return formatStakeToken({
          id,
          accountTokens,
          accountStakes,
          tokensMap,
          markets,
          boosterV2TokenPriceUSD,
          isMetaEsdt: false,
        });
      }

      return metaTokenIds.map((tokenIdentifier) =>
        formatStakeToken({
          id: tokenIdentifier,
          accountTokens,
          accountStakes,
          tokensMap,
          markets,
          boosterV2TokenPriceUSD,
          isMetaEsdt: true,
        }),
      );
    })
    .flat()
    .filter((item) => item !== undefined)
    .sort(
      (a, b) =>
        (ORDER_STAKE_TOKENS[a.defaultSymbol] || 99) -
        (ORDER_STAKE_TOKENS[b.defaultSymbol] || 99),
    );

  return results;
};

export const formatRewardBatches = ({
  programId,
  boosterBatches,
  accountExtraAmount,
  markets,
  instaCompliance,
  topCompliance,
  boosterV2TokenPriceUSD,
  tokensMap,
}: {
  programId: number;
  boosterBatches: BoosterV2BatchData[];
  accountExtraAmount: AccountExtraAmountData[];
  markets: ProtocolState['markets'];
  instaCompliance: string;
  topCompliance: string;
  boosterV2TokenPriceUSD: BoosterV2TokenPriceUsdData[];
  tokensMap: Record<string, Token>;
}): BoosterV2State['controllerRewardBatches'] => {
  const rewards = boosterBatches
    .map(
      ({
        marketId,
        rewardsId,
        speed: defaultSpeed,
        isExtra,
        totalCollateral,
        totalExtraCollateral,
        endTime,
        baseRatio: defaultBaseRatio,
        stakingRatio,
      }) => {
        const baseRatio = isExtra ? '0' : defaultBaseRatio;

        const rewardItem = Object.values(markets).find(
          ({ underlying }) => underlying.id === rewardsId,
        );
        const extraAmountItem = accountExtraAmount.find(
          (item) => item.tokenId === marketId,
        );

        const tokenPriceUSD = boosterV2TokenPriceUSD.find(
          ({ tokenId }) => tokenId === marketId,
        );

        if (!rewardItem || !tokenPriceUSD) {
          return null;
        }

        const {
          baseAmount: accountCollateral = '0',
          extraAmount: accountExtraCollateral = '0',
        } = extraAmountItem || {};

        const accountCollateralUSD = new DefiUtils(accountCollateral)
          .multipliedBy(tokenPriceUSD.amount)
          .dividedBy(WAD_WAD)
          .toString();

        const accountExtraCollateralUSD = new DefiUtils(accountExtraCollateral)
          .dividedBy(WAD)
          .multipliedBy(tokenPriceUSD.amount)
          .dividedBy(WAD_WAD)
          .toString();

        const extraCompliance = calcExtraCompliance({
          instaCompliance,
          accountCollateral,
          accountExtraCollateral,
        });

        const currentTime = new Date();
        const endDate = new Date(+endTime * 1000);

        const currentSpeed =
          currentTime.getTime() > endDate.getTime() ? '0' : defaultSpeed;

        const _totalCollateral = isExtra
          ? new DefiUtils(totalExtraCollateral).dividedBy(WAD)
          : new DefiUtils(totalCollateral);

        const totalCollateralUSD = new DefiUtils(_totalCollateral)
          .multipliedBy(tokenPriceUSD.amount)
          .dividedBy(WAD_WAD)
          .toString();

        const extraCollateralUSD = new DefiUtils(
          new DefiUtils(totalExtraCollateral).dividedBy(WAD),
        )
          .multipliedBy(tokenPriceUSD.amount)
          .dividedBy(WAD_WAD)
          .toString();

        const collateralUSD = new DefiUtils(totalCollateral)
          .multipliedBy(tokenPriceUSD.amount)
          .dividedBy(WAD_WAD)
          .toString();

        const rewardPriceItemUSD = boosterV2TokenPriceUSD.find(
          ({ tokenId }) => tokenId === rewardItem.underlying.id,
        );

        const rewardPriceUSD = rewardPriceItemUSD
          ? new DefiUtils(rewardPriceItemUSD.amount).dividedBy(WAD).toString()
          : rewardItem.underlying.priceUSD;

        const globalAPY = getGlobalBoosterAPY({
          speed: currentSpeed,
          totalCollateralUSD,
          rewardsToken: {
            priceUSD: rewardPriceUSD,
            decimals: rewardItem.underlying.decimals,
          },
        });

        // const userAPY = getUserBoosterAPY({
        //   accountCollateralUSD,
        //   accountExtraCollateralUSD,
        //   isExtra,
        //   topCompliance,
        //   instaCompliance,
        //   baseRatio,
        //   globalAPY,
        //   extraCompliance,
        // });

        const baseAPY = getBaseBoosterAPY({ isExtra, globalAPY, baseRatio });
        const userBaseAPY = getUserBaseAPY({ baseAPY, accountCollateralUSD });

        const standardAPY = getStandartBoosterAPY({
          isExtra,
          globalAPY,
          baseRatio,
        });
        const userStandardAPY = getUserStandartBoosterAPY({
          standardAPY,
          isExtra,
          topCompliance,
          accountCollateralUSD,
        });

        const extraAPY = isExtra ? globalAPY : '0';
        const userExtraAPY = getUserExtraBoosterAPY({
          isExtra,
          globalAPY,
          extraCompliance,
          instaCompliance,
        });

        const [defaultTokenSymbol] = marketId.split('-');

        const tokenLogo =
          TOKEN_LOGO_V2_MAP[
            programId === 1
              ? defaultTokenSymbol.replace('H', '')
              : defaultTokenSymbol
          ] || '';

        const speed = globalAPY === '0' ? '0' : currentSpeed;

        const isActive = globalAPY !== '0';

        return {
          accountCollateralUSD,
          accountCollateral,
          accountExtraCollateral,
          accountExtraCollateralUSD,
          extraCollateralUSD,
          collateralUSD,
          baseAPY,
          standardAPY,
          extraAPY,
          totalAPY: new DefiUtils(baseAPY)
            .plus(standardAPY)
            .plus(extraAPY)
            .toString(),
          marketId,
          tokenLogo,
          isActive,
          isExtra,
          baseRatio,
          stakingRatio,
          s_stakingRatio: new DefiUtils(stakingRatio)
            .dividedBy(WAD)
            .toSafeString(),
          totalCollateralUSD,
          reward: {
            id: rewardItem.underlying.id,
            decimals: rewardItem.underlying.decimals,
            symbol: rewardItem.underlying.symbol,
            priceUSD: rewardPriceUSD,
            logo: TOKEN_LOGO_V2_MAP[rewardItem.underlying.symbol] || '',
          },
          speed,
          logo: rewardItem.logo.normal,
          name: rewardItem.underlying.name,
          account: {
            standardAPY: userStandardAPY,
            extraAPY: userExtraAPY,
            baseAPY: userBaseAPY,
            totalAPY: new DefiUtils(userExtraAPY)
              .plus(userBaseAPY)
              .plus(userStandardAPY)
              .toString(),
          },
        };
      },
    )
    .filter((item) => item !== null);

  const rewardBatches = Object.values<
    BoosterV2State['controllerRewardBatches'][0]
  >(
    rewards.reduce(
      (
        prev,
        {
          marketId: tokenId,
          tokenLogo,
          accountCollateral,
          accountExtraCollateral,
          accountCollateralUSD,
          accountExtraCollateralUSD,
          ...current
        },
      ) => {
        const key = tokenId.split('-')[0];
        const prevItem = prev[key];

        const isActive = current.isActive || prevItem?.isActive || false;

        const tokenSymbol =
          programId === 1
            ? (tokenId.split('-')[0] || '').replace('H', '')
            : tokenId.split('-')[0] || '';

        const token = {
          id: tokenId,
          symbol: tokenSymbol,
          decimals: tokensMap[tokenSymbol]?.decimals || 1,
          logo:
            TOKEN_LOGO_V2_MAP[
              programId === 1 ? tokenSymbol : `${tokenSymbol}-INLINE`
            ] || '',
        };

        return {
          ...prev,
          [key]: {
            totalCollateralUSD: new DefiUtils(
              prevItem?.totalCollateralUSD || '0',
            )
              .plus(current.totalCollateralUSD)
              .toString(),
            collateralUSD: current.collateralUSD,
            extraCollateralUSD: current.extraCollateralUSD,
            logo: tokenLogo,
            tokenId,
            token,
            isActive,
            baseAPY: new DefiUtils(prevItem?.baseAPY || '0')
              .plus(current.baseAPY)
              .toString(),
            standardAPY: new DefiUtils(prevItem?.standardAPY || '0')
              .plus(current.standardAPY)
              .toString(),
            extraAPY: new DefiUtils(prevItem?.extraAPY || '0')
              .plus(current.extraAPY)
              .toString(),
            totalAPY: new DefiUtils(prevItem?.totalAPY || '0')
              .plus(current.totalAPY)
              .toString(),
            account: {
              baseAPY: new DefiUtils(prevItem?.account.baseAPY || '0')
                .plus(current.account.baseAPY)
                .toString(),
              standardAPY: new DefiUtils(prevItem?.account.standardAPY || '0')
                .plus(current.account.standardAPY)
                .toString(),
              extraAPY: new DefiUtils(prevItem?.account.extraAPY || '0')
                .plus(current.account.extraAPY)
                .toString(),
              totalAPY: new DefiUtils(prevItem?.account.totalAPY || '0')
                .plus(current.account.totalAPY)
                .toString(),
              collateral: accountCollateral || '0',
              collateralUSD: accountCollateralUSD || '0',
              extraCollateral: accountExtraCollateral || '0',
              extraCollateralUSD: accountExtraCollateralUSD || '0',
            },
            rewards: [...(prevItem?.rewards || []), current],
          },
        };
      },
      {} as any,
    ),
  );

  const filteredRewardBatches = rewardBatches.filter(
    ({ tokenId }) => !tokenId.includes('USH-') && !tokenId.includes('USHUSDC-'),
  );

  return filteredRewardBatches;
};

export const getAccumulatorsPairsIds = (
  acumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[],
) => {
  return acumulators
    .map(({ rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];

      return tokensIds
        .slice(0, -1)
        .map((tokenId, index) => `${tokenId}/${tokensIds[index + 1]}`);
    })
    .flat();
};

export const formatAccumulators = ({
  accumulators,
  pairs,
}: {
  accumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[];
  pairs: {
    pairsIds: string;
    exchangeRate: string;
  }[];
}) => {
  const pairsMap = pairs.reduce(
    (prev, { pairsIds, exchangeRate }) => ({
      ...prev,
      [pairsIds]: exchangeRate,
    }),
    {} as Record<string, string>,
  );

  const boosterBoosters = accumulators.reduce(
    (prev, { premium, rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];
      const exchangeRates = tokensIds
        .slice(0, -1)
        .map(
          (tokenId, index) =>
            pairsMap[`${tokenId}/${tokensIds[index + 1]}`] || '0',
        );

      const exchangeRate = exchangeRates
        .reduce((prev, current) => prev.multipliedBy(current), new DefiUtils(1))
        .toString();

      return {
        ...prev,
        [rewardsToken.symbol]: {
          premium,
          exchangeRate,
        },
      };
    },
    {} as Record<string, { premium: string; exchangeRate: string }>,
  );

  return boosterBoosters;
};

export const formatAccountClaims = ({
  accountClaims,
  tokensMap,
  markets,
  boosterV2TokenPriceUSD,
}: {
  accountClaims: BoosterV2AccountClaim[];
  tokensMap: Record<string, Token>;
  markets: ProtocolState['markets'];
  boosterV2TokenPriceUSD: BoosterV2TokenPriceUsdData[];
}): BoosterV2State['controllerAccountClaims'] => {
  return accountClaims
    .filter(({ claimed }) => !claimed)
    .map(({ id, claimTimestamp, claimed, payment }) => {
      const defaultSymbol = payment.tokenIdentifier.split('-')[0];
      const symbol = TOKEN_SYMBOL_MAP[defaultSymbol] || defaultSymbol;

      const marketItem = Object.values(markets).find(
        (marketItem) => marketItem.underlying.id === payment.tokenIdentifier,
      );
      const tokenDetailsItem = tokensMap[payment.tokenIdentifier.split('-')[0]];

      const decimals =
        marketItem?.underlying.decimals || tokenDetailsItem?.decimals || 1;

      const boosterV2TokenPriceUSDItem = boosterV2TokenPriceUSD.find(
        (item) => item.tokenId === payment.tokenIdentifier,
      );

      const priceUSD = boosterV2TokenPriceUSDItem
        ? new DefiUtils(boosterV2TokenPriceUSDItem.amount)
            .dividedBy(WAD)
            .toString()
        : String(marketItem?.underlying.priceUSD);

      const s_amount = new DefiUtils(payment.amount)
        .toFullDecimals(decimals)
        .toString();
      const amountUSD = new DefiUtils(s_amount).toUSD(priceUSD).toString();
      const logo =
        marketItem?.logo.normal || TOKEN_LOGO_V2_MAP[defaultSymbol] || '';

      return {
        id,
        claimTimestamp,
        claimed,
        s_amount,
        amountUSD,
        token: {
          id: payment.tokenIdentifier,
          nonce: payment.tokenNonce.padStart(2, '0'),
          decimals,
          priceUSD,
          symbol,
          defaultSymbol,
          logo,
        },
      };
    });
};
