export enum LoginMethodsEnum {
  ledger = 'ledger',
  walletconnect = 'walletconnect',
  wallet = 'wallet',
  webwallet = 'webwallet',
  experimentalWebview = 'experimentalWebview',
  extension = 'extension',
  maiar = 'maiar',
  extra = 'extra',
  none = '',
}

export enum EnvironmentsEnum {
  testnet = 'testnet',
  devnet = 'devnet',
  mainnet = 'mainnet',
}

export enum BaseCurrencyEnum {
  USD = 'USD',
  EGLD = 'EGLD',
}

export enum OperationType {
  Supply = 'supply',
  Borrow = 'borrow',
}

export enum Reward {
  Utrust = 'utrust',
  Zoidpay = 'zoidpay',
}

export enum Currency {
  EGLD = 'EGLD',
  MEX = 'MEX',
  HTM = 'HTM',
  RIDE = 'RIDE',
  USDC = 'USDC',
}

export enum DappCoreWCV2CustomMethodsEnum {
  erd_cancelAction = 'erd_cancelAction',
  multiversx_cancelAction = 'multiversx_cancelAction',
}

export enum TokenPriceChange {
  UP,
  DOWN,
}

export enum ROUTES {
  HOME = '/',
  LEND = '/lend',
  PRICES = '/prices',
  MARKETS = '/markets',
  GOVERNANCE = '/governance',
  LIQUID = '/liquid/learn-more',
  LIQUID_APP = '/liquid',
  LIQUID_MULTIVERSX_APP = '/liquid/multiversx',
  LIQUID_TAO_APP = '/liquid/tao',
  BECOME_AN_OPERATORS = '/liquid/become-an-operators',
  TERMS = '/terms',
  TERMS_OF_SALE = '/terms-of-sale',
  USH = '/ush',
}
