import DefiUtils from 'defi-utils';

import {
  USER_BALANCE_SOURCE,
  UserBalance,
  VOTE_TYPE,
} from '@/store/governance';
import { Market } from '@/store/protocol';

import governanceABI from '@/abis/governance';
import { getTypeFromData } from '@/utils/helpers';

import { ESDTTokenBalance } from '@/types/account';
import { AccountToken } from '@/store/auth';

export const formatVoteNfts = (
  accountTokens: AccountToken[],
  voteNftId: string,
  htmMarket: Market,
) => {
  if (accountTokens.length === 0) {
    return [];
  }

  return accountTokens
    .filter((token) => token.tokenIdentifier.includes(voteNftId))
    .map((token) => {
      const data = getTypeFromData({
        type: 'VoteNFTAttributes',
        value: token.attributes || '',
        abi: governanceABI,
      });

      const amountAsBigNumber = data?.fieldsByName
        .get('payment')
        .value.fieldsByName.get('amount')
        .value.toString() as string;
      const tokenNonce = token.nonce as number;
      const proposalId = data?.fieldsByName.get('proposal_id').value.toString();
      const amount = new DefiUtils(amountAsBigNumber)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toString();
      const voteType = data?.fieldsByName.get('vote_type').value
        .discriminant as VOTE_TYPE;

      return {
        tokenIdentifier: token.tokenIdentifier,
        proposalId,
        tokenNonce,
        amount,
        voteType,
      };
    })
    .filter((item) => item !== null);
};

export const formatUserBalances = (
  htmToken: ESDTTokenBalance,
  htmMarket: Market,
) => {
  const userBalances: Record<USER_BALANCE_SOURCE, UserBalance> = {
    [USER_BALANCE_SOURCE.wallet]: {
      source: USER_BALANCE_SOURCE.wallet,
      amount: new DefiUtils(htmToken.balance)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toString(),
    },
    [USER_BALANCE_SOURCE.staked]: {
      source: USER_BALANCE_SOURCE.staked,
      amount: '0',
    },
    [USER_BALANCE_SOURCE.safety]: {
      source: USER_BALANCE_SOURCE.safety,
      amount: '0',
    },
  };

  return userBalances;
};
