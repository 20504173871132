import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useMemo } from 'react';

import useTheme from '@/hooks/useTheme';

import { useAppDispatch } from '@/store';
import { openPopup } from '@/store/popup';

import { formatNumber } from '@/utils/helpers';

interface BalanceButtonProps {
  balance: string;
}

export const BalanceButton = ({ balance }: BalanceButtonProps) => {
  const dispatch = useAppDispatch();
  const { isDark } = useTheme();
  const splittedBalance = useMemo(
    () => formatNumber(balance, 3, DefiUtils.ROUND_FLOOR).split('.'),
    [balance],
  );

  const items = useMemo(
    () => [
      {
        id: 0,
        interaction: 'stake',
        icon: (
          <svg
            width='8'
            height='8'
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.02344 3.787H6.9823M4.00199 0.808594V6.7675'
              stroke={isDark ? 'white' : '#6A6A8C'}
              strokeWidth='1.60134'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ),
      },
      {
        id: 1,
        interaction: 'unstake',
        icon: (
          <svg
            width='8'
            height='3'
            viewBox='0 0 8 3'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.02344 1.78906H6.9823'
              stroke={isDark ? 'white' : '#6A6A8C'}
              strokeWidth='1.60134'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        ),
      },
    ],
    [isDark],
  );

  return (
    <div
      className={classNames('flex', 'items-center', 'pb-[31px]', 'gap-[20px]')}
    >
      <div className={classNames('flex', 'items-center')}>
        <img
          className={classNames('block', 'w-[32px]', 'h-[32px]', 'mr-2')}
          alt='Hatom'
          src='https://cdn.app.hatom.com/images/tokens/HTM.svg'
        />

        <div className={classNames('text-xl')}>
          {splittedBalance[0]}
          <span className={classNames('text-[#6A6A8C]', 'dark:text-[#625E8F]')}>
            .{splittedBalance[1]}
          </span>
        </div>
      </div>

      <div className={classNames('flex', 'gap-[2px]')}>
        {items.map((item, index) => (
          <button
            key={item.id}
            onClick={() => {
              dispatch(
                openPopup({
                  name: 'htm_stake',
                  data: {
                    interaction: item.interaction,
                  },
                }),
              );
            }}
            className={classNames(
              'flex',
              'items-center',
              'justify-center',
              'cursor-pointer',
              'w-[18px]',
              'h-[14px]',
              'bg-[#E7E7F9]',
              'dark:bg-[#585584]',
              index === 0 ? 'rounded-l-[8px]' : 'rounded-r-[8px]',
            )}
          >
            {item.icon}
          </button>
        ))}
      </div>
    </div>
  );
};
