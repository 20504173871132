import DefiUtils from 'defi-utils';
import { useMemo, useState } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';

import { useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import { boosterV2Selector } from '@/store/booster-v2';
import { lendAppSelector } from '@/store/lend-app';
import { calculateNetAPY } from '@/store/parsers/lend-app-parser';
import {
  BLACKLISTED_USH_MARKETS,
  MARKET_KEY,
  MARKET_ORDER_MAP,
  protocolSelector,
  ProtocolState,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

import { calcWeightAPY } from '@/utils/math/apy';

const useBoosterV2Position = ({
  programId,
  positionType,
}: {
  programId: number;
  positionType: 'ls-wtao' | 'ls-egld' | 'ush' | 'lending';
}) => {
  const {
    controllerRewardBatches,
    controllerAccount,
    controllerStakeTokens,
    stakingRewardBatches,
    stakingAccount,
    stakingStakeTokens,
    currentBatch,
  } = useAppSelector(boosterV2Selector);
  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    markets: protocolMarkets,
    userBalances,
    accountDiscountRateModel,
    hasFirstInfo,
  } = useAppSelector(protocolSelector);
  const {
    showLiquidStakingAPY: initialShowLiquidStakingAPY,
    showLiquidStakingTaoAPY: initialShowLiquidStakingTaoAPY,
  } = useAppSelector(lendAppSelector);

  const { selectedTypeAddress } = useAppSelector(accountSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);

  const showLiquidStakingAPY =
    positionType === 'ls-egld' ? true : initialShowLiquidStakingAPY;
  const showLiquidStakingTaoAPY =
    positionType === 'ls-wtao' ? true : initialShowLiquidStakingTaoAPY;

  const [baseAPYHover, setBaseAPYHover] = useState(false);

  const rewardBatches =
    programId == 1 ? controllerRewardBatches : stakingRewardBatches;
  const { totalStakedUSD, instaCompliance } =
    programId == 1 ? controllerAccount : stakingAccount;
  const stakeTokens =
    programId == 1 ? controllerStakeTokens : stakingStakeTokens;

  const htmToken = stakeTokens.find(({ symbol }) => symbol === 'HTM');

  const { isLoggedIn } = useLogin();

  const filteredUserBalances = useMemo(() => {
    return Object.entries(userBalances)
      .filter(([key]) => {
        if (positionType === 'ls-egld' && key === MARKET_KEY.sEGLD) {
          return true;
        } else if (positionType === 'ls-wtao' && key === MARKET_KEY.sWTAO) {
          return true;
        } else if (positionType === 'lending') {
          return true;
        }

        return false;
      })
      .reduce(
        (prev, [key, values]) => {
          if (positionType === 'ls-egld' && key === MARKET_KEY.sEGLD) {
            return {
              ...prev,
              [key]: {
                ...values,
                borrowBalance: '0',
              },
            };
          } else if (positionType === 'ls-wtao' && key === MARKET_KEY.sWTAO) {
            return {
              ...prev,
              [key]: {
                ...values,
                borrowBalance: '0',
              },
            };
          } else if (positionType === 'lending') {
            return {
              ...prev,
              [key]: values,
            };
          }

          return {
            ...prev,
            [key]: values,
          };
        },
        {} as typeof userBalances,
      );
  }, [positionType, userBalances]);

  const filteredProtocolMarkets = useMemo(() => {
    return Object.values(protocolMarkets)
      .filter((marketItem) => {
        if (
          positionType === 'ls-egld' &&
          marketItem.underlying.symbol === MARKET_KEY.sEGLD
        ) {
          return true;
        } else if (
          positionType === 'ls-wtao' &&
          marketItem.underlying.symbol === MARKET_KEY.sWTAO
        ) {
          return true;
        } else if (positionType === 'lending') {
          return true;
        }

        return false;
      })
      .reduce(
        (prev, current) => ({
          ...prev,
          [current.underlying.symbol]: current,
        }),
        {} as typeof protocolMarkets,
      );
  }, [positionType, protocolMarkets]);

  const filteredByAccountTypeRewardsBatches = useMemo(() => {
    let items = rewardBatches.slice();

    if (positionType === 'lending' && selectedTypeAddress === 'proxy') {
      items = items.filter(
        ({ token }) =>
          !BLACKLISTED_USH_MARKETS.includes(token.symbol as MARKET_KEY),
      );
    } else if (positionType === 'ls-egld') {
      items = items.filter(({ token }) => MARKET_KEY.sEGLD === token.symbol);
    } else if (positionType === 'ls-wtao') {
      items = items.filter(({ token }) => MARKET_KEY.sWTAO === token.symbol);
    }

    return items
      .slice()
      .sort(
        (a, b) =>
          (MARKET_ORDER_MAP[a.token.symbol] || 99) -
          (MARKET_ORDER_MAP[b.token.symbol] || 99),
      );
  }, [positionType, rewardBatches, selectedTypeAddress]);

  const hasBoosterPosition = useMemo(() => {
    const positions = filteredByAccountTypeRewardsBatches.filter(
      ({ account }) => account.standardAPY !== '0' || account.baseAPY !== '0',
    );

    const hasPosition = positions.length > 0;

    if (hasPosition) {
      return true;
    }

    if (
      positionType === 'ls-egld' &&
      (userBalances[MARKET_KEY.sEGLD]?.underlyingBalance !== '0' ||
        userBalances[MARKET_KEY.sEGLD]?.hTokenBalance !== '0' ||
        userBalances[MARKET_KEY.sEGLD]?.collateralBalance !== '0')
    ) {
      return true;
    }

    if (
      positionType === 'ls-wtao' &&
      (userBalances[MARKET_KEY.sWTAO]?.underlyingBalance !== '0' ||
        userBalances[MARKET_KEY.sWTAO]?.hTokenBalance !== '0' ||
        userBalances[MARKET_KEY.sWTAO]?.collateralBalance !== '0')
    ) {
      return true;
    }

    if (positionType === 'lending') {
      return Object.values(filteredUserBalances).some((userBalanceItem) => {
        return (
          userBalanceItem.hTokenBalance !== '0' ||
          userBalanceItem.collateralBalance !== '0'
        );
      });
    }

    return false;
  }, [
    filteredByAccountTypeRewardsBatches,
    filteredUserBalances,
    positionType,
    userBalances,
  ]);

  const totalStakedHTM = useMemo(() => {
    return new DefiUtils(totalStakedUSD)
      .fromUSD(htmToken?.priceUSD || '0')
      .toSafeString();
  }, [htmToken?.priceUSD, totalStakedUSD]);

  const filteredRewardBatches = useMemo(() => {
    if (!hasBoosterPosition) {
      const index =
        currentBatch % filteredByAccountTypeRewardsBatches.length || 0;

      const rewardBatch = filteredByAccountTypeRewardsBatches[index];

      return rewardBatch ? [rewardBatch] : [];
    }

    if (positionType === 'ls-egld') {
      return filteredByAccountTypeRewardsBatches;
    } else if (positionType === 'ls-wtao') {
      return filteredByAccountTypeRewardsBatches;
    } else if (positionType === 'lending') {
      return filteredByAccountTypeRewardsBatches.filter(
        ({ account, token }) => {
          const userBalanceItem =
            filteredUserBalances[token.symbol as MARKET_KEY];

          const accountHTokenBalance = userBalanceItem?.hTokenBalance || '0';
          const accountCollateralBalance =
            userBalanceItem?.collateralBalance || '0';

          return (
            account.collateralUSD !== '0' ||
            accountHTokenBalance !== '0' ||
            accountCollateralBalance !== '0'
          );
        },
      );
    }

    return filteredByAccountTypeRewardsBatches.filter(
      ({ account }) => account.collateralUSD !== '0',
    );
  }, [
    hasBoosterPosition,
    positionType,
    filteredByAccountTypeRewardsBatches,
    currentBatch,
    filteredUserBalances,
  ]);

  const currentRewardBatch = useMemo(() => {
    const index = currentBatch % filteredRewardBatches.length || 0;

    return filteredRewardBatches[index];
  }, [currentBatch, filteredRewardBatches]);

  const totalCurrentAPY = useMemo(() => {
    switch (programId) {
      case 1: {
        const accountsBoosterAPYMap = rewardBatches.reduce(
          (prev, { account, tokenId }) => {
            return {
              ...prev,
              [tokenId]: account.totalAPY,
            };
          },
          {} as Record<string, string>,
        );

        return calculateNetAPY({
          markets: filteredProtocolMarkets,
          rewardMarkets,
          liquidStakingAPY,
          showLiquidStakingAPY,
          liquidStakingTaoAPY,
          showLiquidStakingTaoAPY,
          accountsBoosterAPYMap,
          accountDiscountRateModel,
          userBalances: filteredUserBalances,
        });
      }

      case 2: {
        return calcWeightAPY({
          items: [
            ...filteredRewardBatches.map((item) => ({
              valueUSD: new DefiUtils(item.account.collateralUSD).toString(),
              apy: new DefiUtils(item.account.totalAPY).toString(),
            })),
          ],
        });
      }

      default: {
        return '0';
      }
    }
  }, [
    programId,
    rewardBatches,
    filteredProtocolMarkets,
    rewardMarkets,
    liquidStakingAPY,
    showLiquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    accountDiscountRateModel,

    filteredRewardBatches,
    filteredUserBalances,
  ]);

  const totalMaxAPY = useMemo(() => {
    switch (programId) {
      case 1: {
        const filteredMarkets = Object.values(filteredProtocolMarkets).filter(
          (marketItem) => {
            const userBalanceItem =
              filteredUserBalances[marketItem.underlying.symbol as MARKET_KEY];
            const accountHTokenBalance = userBalanceItem?.hTokenBalance || '0';
            const accountBorrowBalance = userBalanceItem?.borrowBalance || '0';
            const accountCollateralBalance =
              userBalanceItem?.collateralBalance || '0';

            return (
              accountHTokenBalance !== '0' ||
              accountBorrowBalance !== '0' ||
              accountCollateralBalance !== '0'
            );
          },
        );

        if (!isLoggedIn || filteredMarkets.length === 0) {
          const items = [
            ...Object.values(filteredProtocolMarkets).map((marketItem) => {
              const boosterV2RewardItem = filteredRewardBatches.find(
                (rewardItem) => rewardItem.tokenId === marketItem.hToken.id,
              );

              const isSEGLD =
                showLiquidStakingAPY &&
                marketItem.underlying.symbol === MARKET_KEY.sEGLD;
              const isSWTAO =
                showLiquidStakingTaoAPY &&
                marketItem.underlying.symbol === MARKET_KEY.sWTAO;

              return {
                valueUSD: new DefiUtils(
                  boosterV2RewardItem?.collateralUSD || '0',
                ).toString(),
                apy: new DefiUtils(boosterV2RewardItem?.totalAPY || '0')
                  .plus(marketItem.supplyAPY)
                  .plus(isSEGLD ? liquidStakingAPY : '0')
                  .plus(isSWTAO ? liquidStakingTaoAPY : '0')
                  .toString(),
              };
            }),
          ];

          return calcWeightAPY({
            items,
          });
        }

        const accountsBoosterAPYMap = rewardBatches.reduce(
          (prev, { totalAPY, tokenId }) => {
            return {
              ...prev,
              [tokenId]: totalAPY,
            };
          },
          {} as Record<string, string>,
        );

        const newUserBalances = Object.entries(filteredProtocolMarkets).reduce(
          (prev, [key, { hTokenExchangeRate }]) => {
            const userBalanceItem = filteredUserBalances[key as MARKET_KEY];

            const collateralBalance = new DefiUtils(
              userBalanceItem?.collateralBalance || '0',
            ).plus(userBalanceItem?.hTokenBalance || '0');

            return {
              ...prev,
              [key]: {
                borrowBalance: userBalanceItem?.borrowBalance || '0',
                collateralBalance: collateralBalance.toString(),
                underlyingCollateralBalance: collateralBalance
                  .toUnderlying(hTokenExchangeRate)
                  .toString(),
                underlyingBalance: '0',
                hTokenBalance: '0',
              },
            };
          },
          {} as ProtocolState['userBalances'],
        );

        return calculateNetAPY({
          markets: filteredProtocolMarkets,
          rewardMarkets,
          liquidStakingAPY,
          showLiquidStakingAPY,
          liquidStakingTaoAPY,
          showLiquidStakingTaoAPY,
          accountsBoosterAPYMap,
          accountDiscountRateModel,
          userBalances: newUserBalances,
        });
      }

      case 2: {
        return calcWeightAPY({
          items: [
            ...filteredRewardBatches.map((item) => {
              return {
                valueUSD: new DefiUtils(
                  hasBoosterPosition
                    ? item.account.collateralUSD
                    : item.collateralUSD,
                ).toString(),
                apy: new DefiUtils(item.totalAPY).toString(),
              };
            }),
          ],
        });
      }
    }

    return '0';
  }, [
    programId,
    filteredProtocolMarkets,
    isLoggedIn,
    rewardBatches,
    rewardMarkets,
    liquidStakingAPY,
    showLiquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    accountDiscountRateModel,
    filteredUserBalances,
    filteredRewardBatches,
    hasBoosterPosition,
  ]);

  const totalBoosterNormalAPY = useMemo(() => {
    switch (programId) {
      case 1: {
        const filteredMarkets = Object.values(filteredProtocolMarkets).filter(
          (marketItem) => {
            const userBalanceItem =
              filteredUserBalances[marketItem.underlying.symbol as MARKET_KEY];
            const accountHTokenBalance = userBalanceItem?.hTokenBalance || '0';
            const accountBorrowBalance = userBalanceItem?.borrowBalance || '0';
            const accountCollateralBalance =
              userBalanceItem?.collateralBalance || '0';

            return (
              accountHTokenBalance !== '0' ||
              accountBorrowBalance !== '0' ||
              accountCollateralBalance !== '0'
            );
          },
        );

        if (!isLoggedIn || filteredMarkets.length === 0) {
          const items = [
            ...Object.values(filteredProtocolMarkets).map((marketItem) => {
              const boosterV2RewardItem = filteredRewardBatches.find(
                (rewardItem) => rewardItem.tokenId === marketItem.hToken.id,
              );

              const isSEGLD =
                showLiquidStakingAPY &&
                marketItem.underlying.symbol === MARKET_KEY.sEGLD;
              const isSWTAO =
                showLiquidStakingTaoAPY &&
                marketItem.underlying.symbol === MARKET_KEY.sWTAO;

              return {
                valueUSD: new DefiUtils(
                  boosterV2RewardItem?.collateralUSD || '0',
                ).toString(),
                apy: new DefiUtils(boosterV2RewardItem?.baseAPY || '0')
                  .plus(boosterV2RewardItem?.standardAPY || '0')
                  .plus(marketItem.supplyAPY)
                  .plus(isSEGLD ? liquidStakingAPY : '0')
                  .plus(isSWTAO ? liquidStakingTaoAPY : '0')
                  .toString(),
              };
            }),
          ];

          return calcWeightAPY({
            items,
          });
        }

        const accountsBoosterAPYMap = rewardBatches.reduce(
          (prev, { baseAPY, standardAPY, tokenId }) => {
            return {
              ...prev,
              [tokenId]: new DefiUtils(baseAPY).plus(standardAPY).toString(),
            };
          },
          {} as Record<string, string>,
        );

        const newUserBalances = Object.entries(filteredProtocolMarkets).reduce(
          (prev, [key, { hTokenExchangeRate }]) => {
            const userBalanceItem = filteredUserBalances[key as MARKET_KEY];

            const collateralBalance = new DefiUtils(
              userBalanceItem?.collateralBalance || '0',
            ).plus(userBalanceItem?.hTokenBalance || '0');

            return {
              ...prev,
              [key]: {
                borrowBalance: userBalanceItem?.borrowBalance || '0',
                collateralBalance: collateralBalance.toString(),
                underlyingCollateralBalance: collateralBalance
                  .toUnderlying(hTokenExchangeRate)
                  .toString(),
                underlyingBalance: '0',
                hTokenBalance: '0',
              },
            };
          },
          {} as ProtocolState['userBalances'],
        );

        return calculateNetAPY({
          markets: filteredProtocolMarkets,
          rewardMarkets,
          liquidStakingAPY,
          showLiquidStakingAPY,
          liquidStakingTaoAPY,
          showLiquidStakingTaoAPY,
          accountsBoosterAPYMap,
          accountDiscountRateModel,
          userBalances: newUserBalances,
        });
      }

      case 2: {
        return calcWeightAPY({
          items: filteredRewardBatches.map((item) => ({
            valueUSD: new DefiUtils(item.collateralUSD).toString(),
            apy: new DefiUtils(item.baseAPY).plus(item.standardAPY).toString(),
          })),
        });
      }

      default: {
        return '0';
      }
    }
  }, [
    programId,
    filteredProtocolMarkets,
    isLoggedIn,
    rewardBatches,
    rewardMarkets,
    liquidStakingAPY,
    showLiquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    accountDiscountRateModel,
    filteredUserBalances,
    filteredRewardBatches,
  ]);

  const currentAPY = useMemo(() => {
    return calcWeightAPY({
      items: filteredRewardBatches.map((item) => ({
        valueUSD: new DefiUtils(item.collateralUSD).toString(),
        apy: new DefiUtils(item.account.standardAPY)
          .plus(item.account.extraAPY)
          .toString(),
      })),
    });
  }, [filteredRewardBatches]);

  const boosterNormalAPY = useMemo(() => {
    return calcWeightAPY({
      items: filteredRewardBatches.map((item) => ({
        valueUSD: item.collateralUSD,
        apy: new DefiUtils(item.standardAPY).toString(),
      })),
    });
  }, [filteredRewardBatches]);

  const maxAPY = useMemo(() => {
    return calcWeightAPY({
      items: filteredRewardBatches.map((item) => ({
        valueUSD: new DefiUtils(item.collateralUSD).toString(),
        apy: new DefiUtils(item.standardAPY).plus(item.extraAPY).toString(),
      })),
    });
  }, [filteredRewardBatches]);

  const baseAPYWidth = useMemo(() => {
    return new DefiUtils(boosterNormalAPY)
      .dividedBy(maxAPY)
      .multipliedBy(100)
      .toNumber();
  }, [maxAPY, boosterNormalAPY]);

  const currentAPYWidth = useMemo(() => {
    return new DefiUtils(currentAPY)
      .dividedBy(maxAPY)
      .multipliedBy(100)
      .toNumber();
  }, [currentAPY, maxAPY]);

  return {
    currentAPY,
    boosterNormalAPY,
    maxAPY,

    totalCurrentAPY,
    totalBoosterNormalAPY,
    totalMaxAPY,

    currentAPYWidth,
    baseAPYWidth,

    totalStakedHTM,
    totalStakedUSD,
    currentRewardBatch,
    filteredRewardBatches,
    filteredProtocolMarkets,
    instaCompliance,
    hasBoosterPosition,

    baseAPYHover,
    setBaseAPYHover,

    hasFirstInfo,
  };
};

export default useBoosterV2Position;
