import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import { cloneDeep } from 'lodash';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  ASSET_ALT_NAME,
  Market,
  Token,
  TOKEN_LOGO_V2_MAP,
} from '@/store/protocol';

import { STORAGE_KEYS } from '@/contexts/AuthContext/hooks/useElrondNetworkSync';
import blockchainService from '@/services/blockchain';
import gatewayService from '@/services/gateway';
import multiversxSDK from '@/services/multiversx-sdk';
import logger from '@/utils/logger';

import { TransactionResponseItem } from '@/types/account';
import { LoginMethodsEnum } from '@/types/enums';

export interface AccountToken {
  tokenIdentifier: string;
  balance: string;
  symbol: string;
  decimals: number;
  name: string;
  priceUSD?: string;
  attributes?: string;
  nonce?: number;
  logoUrl?: string;
}

interface AccountState extends Record<string, any> {
  address: string;
  proxyAddress: string;
  selectedTypeAddress: 'normal' | 'proxy';
  nonce: number;
  balance: string;
  transactions: TransactionResponseItem[];
  isGuarded: boolean;
  activeGuardianAddress: string;
  tokens: AccountToken[];
}

const accountInitialState: AccountState = {
  address: '',
  proxyAddress: '',
  selectedTypeAddress: 'normal',
  nonce: 0,
  balance: '',
  transactions: [],
  isGuarded: false,
  activeGuardianAddress: '',
  tokens: [],
};

interface LoginInfoState extends Record<string, any> {
  loginMethod: LoginMethodsEnum;
  expires: number;
  loginToken: string;
  signature: string;
}

const loginInfoInitialState: LoginInfoState = {
  loginMethod: LoginMethodsEnum.none,
  expires: 0,
  loginToken: '',
  signature: '',
};

interface LoggingInState extends Record<string, any> {
  pending: boolean;
  error: string;
  loggedIn: boolean;
}

const loggingInInitialState: LoggingInState = {
  pending: true,
  error: '',
  loggedIn: false,
};

export interface AuthState {
  account: AccountState;
  loginInfo: LoginInfoState;
  loggingIn: LoggingInState;
}

const initialState: AuthState = {
  account: cloneDeep(accountInitialState),
  loginInfo: cloneDeep(loginInfoInitialState),
  loggingIn: cloneDeep(loggingInInitialState),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccountState: (
      state,
      action: PayloadAction<{ key: keyof AccountState; value: any }>,
    ) => {
      state.account[action.payload.key] = action.payload.value;
    },

    clearAccountState: (state) => {
      const resetObj = cloneDeep(accountInitialState);
      Object.keys(resetObj).forEach((key) => {
        state.account[key] = resetObj[key];
      });
    },

    setLoginInfoState: (
      state,
      action: PayloadAction<{ key: keyof LoginInfoState; value: any }>,
    ) => {
      state.loginInfo[action.payload.key] = action.payload.value;
    },

    clearLoginInfoState: (state) => {
      const resetObj = cloneDeep(loginInfoInitialState);
      Object.keys(resetObj).forEach((key) => {
        state.loginInfo[key] = resetObj[key];
      });
    },

    setLoggingInState: (
      state,
      action: PayloadAction<{ key: keyof LoggingInState; value: any }>,
    ) => {
      state.loggingIn[action.payload.key] = action.payload.value;
    },

    clearLoggingInState: (state) => {
      const resetObj = cloneDeep(loggingInInitialState);
      Object.keys(resetObj).forEach((key) => {
        state.loggingIn[key] = resetObj[key];
      });
    },
  },
});

export const { clearAccountState, clearLoginInfoState, clearLoggingInState } =
  authSlice.actions;

// Account info state + persistance

export const setAccountState =
  (key: keyof AccountState, value: any) => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setAccountState({ key, value }));
  };

const formatAccountTokens = ({
  marketItem,
  esdtItem,
  tokenItem,
}: {
  marketItem?: Market['underlying'] | Market['hToken'];
  esdtItem: {
    balance: string;
    tokenIdentifier: string;
    attributes?: string;
    nonce?: number;
  };
  tokenItem?: Token;
}) => {
  const symbol = (tokenItem?.symbol || esdtItem.tokenIdentifier || '').split(
    '-',
  )[0];
  const decimals = marketItem?.decimals || tokenItem?.decimals || 1;
  const priceUSD = marketItem?.priceUSD ? marketItem?.priceUSD : undefined;
  const logoUrl = TOKEN_LOGO_V2_MAP[symbol] || '';
  const name = tokenItem?.name || symbol;

  return {
    tokenIdentifier: esdtItem.tokenIdentifier,
    balance: esdtItem.balance,
    symbol,
    decimals,
    name,
    ...(priceUSD ? { priceUSD } : {}),
    ...(esdtItem?.attributes ? { attributes: esdtItem.attributes } : {}),
    ...(esdtItem?.nonce ? { nonce: esdtItem.nonce } : {}),
    ...(logoUrl ? { logoUrl } : {}),
  };
};

export const updateESDTTokenBalance =
  ({ accountAddress: selectedAccountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        auth: {
          account: { address: accountAddress },
        },
        protocol: { markets, tokensMap },
      } = getState();

      if (!accountAddress) {
        await Promise.all([
          dispatch(setAccountState('tokens', [])),
          dispatch(setAccountState('balance', '0')),
        ]);
        return;
      }

      const hasSelectedProxyAccount =
        selectedAccountAddress && accountAddress !== selectedAccountAddress;

      const [accountDetails, accountGuardian, accountEsdt, accountProxyEsdt] =
        await Promise.all([
          gatewayService.address.getAddressDetails(accountAddress),
          gatewayService.address.getAddressGuardianData(accountAddress),
          gatewayService.address.getAddressEsdt(accountAddress),
          hasSelectedProxyAccount
            ? gatewayService.address.getAddressEsdt(selectedAccountAddress)
            : undefined,
        ]);

      const hTokens = Object.values(markets)
        .map(({ hToken }) => hToken.id)
        .filter((id) => ![markets['USH']?.underlying.id || ''].includes(id));

      const esdts = accountAddress !== selectedAccountAddress
        ? [
            ...Object.values(accountEsdt.esdts).filter(
              ({ tokenIdentifier }) => !hTokens.includes(tokenIdentifier),
            ),
            ...Object.values(accountProxyEsdt?.esdts || {}).filter(
              ({ tokenIdentifier }) => hTokens.includes(tokenIdentifier),
            ),
          ]
        : [...Object.values(accountEsdt.esdts)];

      const accountTokens: AccountToken[] = [
        {
          tokenIdentifier: 'EGLD',
          balance: accountDetails.account.balance.toString(),
          symbol: 'EGLD',
          decimals: 18,
          priceUSD: markets['EGLD']?.underlying?.priceUSD || '0',
          name: ASSET_ALT_NAME['EGLD'],
        },
        ...esdts.map((esdtItem) =>
          formatAccountTokens({
            marketItem: Object.values(markets)
              .map(({ underlying, hToken }) => [underlying, hToken])
              .flat()
              .find((item) => item.id === esdtItem.tokenIdentifier),
            esdtItem,
            tokenItem: tokensMap[esdtItem.tokenIdentifier.split('-')[0]],
          }),
        ),
      ];

      await Promise.all([
        dispatch(setAccountState('tokens', accountTokens)),
        dispatch(
          setAccountState('balance', accountDetails.account.balance.toString()),
        ),
        dispatch(
          setAccountState('isGuarded', accountGuardian.guardianData.guarded),
        ),
        dispatch(
          setAccountState(
            'activeGuardianAddress',
            accountGuardian.guardianData.activeGuardian?.address || '',
          ),
        ),
      ]);
    } catch (error) {
      logger.error('store:updateESDTTokenBalance', error);
      captureException(error);
    }
  };

export const updateAccountTxs =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const {
        auth: {
          account: { address: accountAddress },
        },
      } = getState();
      if (!accountAddress) {
        dispatch(setAccountState('transactions', []));
        return;
      }

      const transactions: any[] =
        (await multiversxSDK
          .accountTransfers(accountAddress, {
            from: 0,
            size: 25,
          })
          .catch(() => {})) || [];

      const onlyTransactions =
        transactions && transactions.length > 0
          ? transactions.filter((txData) => txData.type === 'Transaction')
          : [];

      await dispatch(setAccountState('transactions', onlyTransactions));
    } catch (error) {
      logger.error('store:updateAccountTxs', error);
      captureException(error);
    }
  };

export const accountSelector = (state: RootState) => state.auth.account;

// Login info state + persistance

export const setLoginInfoState =
  (key: keyof LoginInfoState, value: any) => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setLoginInfoState({ key, value }));
  };

export const loginInfoSelector = (state: RootState) => state.auth.loginInfo;

// Login info state

export const setLoggingInState =
  (key: keyof LoggingInState, value: any) => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setLoggingInState({ key, value }));
  };

export const clearAuthStates = () => (dispatch: AppDispatch) => {
  dispatch(clearAccountState());
  dispatch(clearLoginInfoState());
  dispatch(clearLoggingInState());

  localStorage.removeItem(STORAGE_KEYS.ACCOUNT_KEY);
  localStorage.removeItem(STORAGE_KEYS.LOGIN_INFO_KEY);
};

export const getProxyAccount =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    const {
      auth: {
        account: { address: accountAddress, proxyAddress },
      },
    } = getState();

    if (proxyAddress) {
      return proxyAddress;
    }

    const proxyAddressResponse =
      await blockchainService.lens.getAccountManager(accountAddress);

    dispatch(setAccountState('proxyAddress', proxyAddressResponse));

    return proxyAddressResponse;
  };

export const loggingInSelector = (state: RootState) => state.auth.loggingIn;

export default authSlice.reducer;
