import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';

import { ReturnUseSupplyData } from '@/components/popups/SupplyPopup/hooks/useSupplyData';
import { ReturnUseSupplyForm } from '@/components/popups/SupplyPopup/hooks/useSupplyForm';
import isRemainingValue from '@/components/popups/SupplyPopup/utils/isRemainingValue';

import { useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import { BLACKLISTED_USH_MARKETS, MARKET_KEY } from '@/store/protocol';

const useSupplyListenerSubmitButton = ({
  supplyForm,
  supplyData,
}: {
  supplyForm: ReturnUseSupplyForm;
  supplyData: ReturnUseSupplyData;
}) => {
  const {
    isSupplying,
    isWithdrawing,
    inputValueAsBigNumber,
    maxSelected,
    isAddingCollateral,
    isRemovingCollateral,
    selectedTab,
    collateralOption,
    setSubmitButton,
    isAddingSupplyAndCollateral,
    isRemovingCollateralAndWithdraw,
  } = supplyForm;

  const {
    hasMaxMarketPerAccount,
    hasFunds,
    isValidInput,
    hasMinHTokenValue,
    isMaxSupplyCap,
    isValidAmountRequest,
    hasEnoughCash,
    hasEnoughEGLDBalance,
    market,
  } = supplyData;

  const { t } = useTranslation();
  const { isLoggedIn } = useLogin();
  const { selectedTypeAddress } = useAppSelector(accountSelector);

  const getActionButtonLabel = (): string => {
    if (isSupplying) {
      if (!isLoggedIn || !hasFunds) return t('no-funds-available');
      if (!isValidInput && hasFunds) return t('supply');
      if (!market.mintEnabled)
        return t('mint-not-enabled', 'Supply not enabled');
      if (!hasMinHTokenValue) return t('the-amount-is-too-low');
      if (isValidInput) {
        if (isAddingSupplyAndCollateral && hasMaxMarketPerAccount) {
          return t('limit-money-market-reached');
        }

        if (isMaxSupplyCap) {
          return t('amount-exceeds-supply-cap');
        }

        return isValidAmountRequest ? t('supply') : t('no-funds-available');
      }
      return t('supply');
    }

    if (isWithdrawing) {
      const disabled =
        hasEnoughCash || isRemainingValue(inputValueAsBigNumber)
          ? t('no-funds-to-withdraw')
          : t('insufficient-market-liquidity');
      if (!isLoggedIn || !hasFunds) return t('no-funds-to-withdraw');
      if (!isValidInput && hasFunds) return t('withdraw');
      if (isRemainingValue(inputValueAsBigNumber))
        return t('no-funds-to-withdraw');
      if (!hasEnoughCash) return t('insufficient-market-liquidity');
      if (!hasMinHTokenValue) return t('the-amount-is-too-low');

      if (isValidInput) {
        return isValidAmountRequest ? t('withdraw') : disabled;
      }
      return t('withdraw');
    }

    if (isAddingCollateral) {
      if (!isLoggedIn || !hasFunds) return t('no-funds-to-add-collateral');
      if (!isValidInput && hasFunds) return t('add-collateral');

      if (isValidInput) {
        if (hasMaxMarketPerAccount) {
          return t('limit-money-market-reached');
        }
        return isValidAmountRequest
          ? t('add-collateral')
          : t('no-funds-to-add-collateral');
      }
      return t('add-collateral');
    }

    if (isRemovingCollateral) {
      if (!isLoggedIn || !hasFunds) return t('no-collateral-available');
      if (!isValidInput && hasFunds) return t('remove-collateral');
      if (isValidInput) {
        return isValidAmountRequest
          ? t('remove-collateral')
          : t('no-collateral-available');
      }
      return t('remove-collateral');
    }

    return t('unknown');
  };

  const getActionButtonDisabled = (): boolean => {
    return (
      (isSupplying && !market.mintEnabled) ||
      !isLoggedIn ||
      !isValidInput ||
      (!maxSelected && !isValidAmountRequest) ||
      !hasFunds ||
      !hasEnoughEGLDBalance ||
      (isSupplying && !hasMinHTokenValue) ||
      (isWithdrawing && !hasMinHTokenValue) ||
      isMaxSupplyCap ||
      (isWithdrawing && !hasEnoughCash) ||
      (isWithdrawing && isRemainingValue(inputValueAsBigNumber)) ||
      (isSupplying && isAddingSupplyAndCollateral && hasMaxMarketPerAccount) ||
      (isAddingCollateral && hasMaxMarketPerAccount) ||
      (isSupplying &&
        selectedTypeAddress === 'proxy' &&
        BLACKLISTED_USH_MARKETS.includes(
          market.underlying.symbol as MARKET_KEY,
        ))
    );
  };

  useEffect(() => {
    setSubmitButton({
      disabled: getActionButtonDisabled(),
      label: getActionButtonLabel(),
    });
    // eslint-disable-next-line
  }, [
    selectedTab,
    collateralOption,
    inputValueAsBigNumber,
    isAddingSupplyAndCollateral,
    isRemovingCollateralAndWithdraw,
  ]);
};

export default useSupplyListenerSubmitButton;
