export enum TABS {
  BORROW = 'borrow',
  REPAY_BORROW = 'repay',
}

export const BORROW_TABS = [TABS.BORROW, TABS.REPAY_BORROW];


export const getTabIndex = (id: TABS) => {
  return BORROW_TABS.indexOf(id as TABS);
};