import { Dispatch, SetStateAction, useEffect } from 'react';

import { useEffectOnlyOnUpdate } from '@/hooks/tools/useEffectOnlyOnUpdate';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  accountSelector,
  setAccountState,
  setLoggingInState,
} from '@/store/auth';

import { STORAGE_KEYS } from '@/contexts/AuthContext/hooks/useElrondNetworkSync';
import { JSONSafeParse } from '@/utils/helpers';

const useAccountInfoSync = ({
  setAccountDone,
}: {
  setAccountDone: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const accountSnap = useAppSelector(accountSelector);

  useEffect(() => {
    const accountStorage = localStorage.getItem(STORAGE_KEYS.ACCOUNT_KEY);
    const loginInfoStorage = localStorage.getItem(STORAGE_KEYS.LOGIN_INFO_KEY);

    const parsedLoginInfoStorage = JSONSafeParse(
      loginInfoStorage || 'null',
      null,
    );
    const parsedAccountStorage = JSONSafeParse(accountStorage || 'null', null);

    if (
      parsedAccountStorage &&
      parsedAccountStorage?.address !== '' &&
      parsedLoginInfoStorage &&
      parsedLoginInfoStorage?.loginMethod !== '' &&
      typeof parsedLoginInfoStorage?.expires === 'number' &&
      parsedLoginInfoStorage?.expires !== 0
    ) {
      dispatch(
        setAccountState(
          'selectedTypeAddress',
          parsedAccountStorage.selectedTypeAddress,
        ),
      );
      dispatch(setAccountState('address', parsedAccountStorage.address));
      dispatch(setAccountState('nonce', parsedAccountStorage.nonce));
      dispatch(setAccountState('balance', parsedAccountStorage.balance));
      if (!parsedAccountStorage.address) {
        dispatch(setLoggingInState('pending', false));
      }
      setAccountDone(true);
    } else {
      dispatch(setLoggingInState('pending', false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnlyOnUpdate(() => {
    localStorage.setItem(STORAGE_KEYS.ACCOUNT_KEY, JSON.stringify(accountSnap));
  }, [
    accountSnap.address,
    accountSnap.nonce,
    accountSnap.balance,
    accountSnap.selectedTypeAddress,
    accountSnap.proxyAddress,
  ]);
};

export default useAccountInfoSync;
