import React from 'react';
import Skeleton from 'react-loading-skeleton';

const UshShimmer: React.FunctionComponent<any> = ({
  children,
  circular,
  w = 80,
  h,
  xs,
  sm,
  md,
  lg,
  xl,
  on,
  className,
}) => {
  const wMap = {
    xs: 20,
    sm: 40,
    md: 80,
    lg: 100,
    xl: 120,
  };

  if (xs) {
    w = wMap.xs;
  } else if (sm) w = wMap.sm;
  else if (md) w = wMap.md;
  else if (lg) w = wMap.lg;
  else if (xl) w = wMap.xl;

  return on ? <Skeleton circle={circular} width={w} height={h} className={className} /> : children;
};

export default UshShimmer;
