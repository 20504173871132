import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';

import 'react-loading-skeleton/dist/skeleton.css';

import { AbsoluteDropdownProvider } from '@/components/popups/AbsoluteDropdown';

import { store } from '@/store';

import { AnimationControllerProvider } from '@/contexts/AnimationControllerContext';
import { AuthProvider } from '@/contexts/AuthContext/index';
import { BoosterV2Provider } from '@/contexts/BoosterV2Context';
import { GovernanceProvider } from '@/contexts/GovernanceContext';
import { HatomMetaTagProvider } from '@/contexts/HatomMetaTagContext';
import { LendAppProvider } from '@/contexts/LendAppContext';
import { LiquidStakingAppProvider } from '@/contexts/LiquidStakingAppContext';
import { LiquidStakingLandingProvider } from '@/contexts/LiquidStakingLandingContext';
import { LiquidStakingTaoAppProvider } from '@/contexts/LiquidStakingTaoAppContext';
import { LoaderProvider } from '@/contexts/LoaderContextProvider';
import { PopupProvider } from '@/contexts/PopupContextProvider';
import { ProtocolProvider } from '@/contexts/ProtocolContext';
import { ThemeProvider } from '@/contexts/ThemeContext';
import { TransactionProvider } from '@/contexts/TransactionContext';

const ContextProviderManager = ({ children = <></> }) => {
  const [queryClient] = useState(() => new QueryClient());

  const providers = [
    {
      component: SkeletonTheme,
      props: { baseColor: '#A3A7B8', highlightColor: '#D1D2DC' },
    },
    { component: QueryClientProvider, props: { client: queryClient } },
    { component: Provider, props: { store } },
    { component: HatomMetaTagProvider },
    { component: ThemeProvider },
    { component: AnimationControllerProvider },
    { component: PopupProvider },
    { component: AuthProvider },
    { component: TransactionProvider },
    { component: LendAppProvider },
    { component: GovernanceProvider },
    { component: LiquidStakingAppProvider },
    { component: LiquidStakingTaoAppProvider },
    { component: LiquidStakingLandingProvider },
    { component: ProtocolProvider },
    { component: LoaderProvider },
    { component: AbsoluteDropdownProvider },
    { component: BoosterV2Provider },
  ];

  return providers.reduceRight((acc, { component: Component, props = {} }) => {
    return <Component {...props}>{acc}</Component>;
  }, children);
};

export default ContextProviderManager;
