import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';

import controllerABI from '@/abis/controller';
import client from '@/services/blockchain/client';

export const getAccountMarkets = async (
  controllerAddress: string,
  accountAddress: string,
): Promise<string[]> => {
  if (!accountAddress || !controllerAddress) {
    return [];
  }

  const response = await client({
    address: controllerAddress,
    method: 'getAccountMarkets',
    args: [new AddressValue(new Address(accountAddress))],
    abi: AbiRegistry.create(controllerABI),
  });

  return response.filter((address) => address.length > 0);
};
