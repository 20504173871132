import DefiUtils from 'defi-utils';
import { isEqual, xorWith } from 'lodash';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useLiquidStakingHsEgldCollateral from '@/hooks/liquidStakingApp/useLiquidStakingHsEgldCollateral';
import useLiquidStakingMessageError, {
  OPERATION_KEYS,
} from '@/hooks/liquidStakingApp/useLiquidStakingMessageError';
import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';

import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  liquidStakingAppSelector,
  RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN,
  TOKEN_SOURCE,
} from '@/store/liquid-staking-app';
import { openPopup } from '@/store/popup';
import {
  calcHasMaxMarketPerAccount,
  H_TOKEN_DECIMALS,
  hasEnoughEGLDBalanceSelector,
  hasTakenBorrowsSelector,
  MARKET_KEY,
  protocolSelector,
  sEgldMarketSelector,
} from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import {
  AcceptDeal,
  AmountLimitErrors,
  Label,
  MigrateInput,
  Notes,
  SubmitButton,
  SwitchBtn,
} from '@/sections/Liquid/App/Form/components';
import {
  Operation,
  useLiquidStakingForm,
} from '@/sections/Liquid/App/Form/contexts/LiquidStakingFormContext';
import { getFormControlClasses } from '@/sections/Liquid/App/Form/utils';
import { useHintProps } from '@/sections/Liquid/App/global';
import { PrettyToken as ColoredName } from '@/sections/Liquid/Common/PrettyToken';
import { formatNumber } from '@/utils/helpers';

export const MigratingInputs: React.FC<any> = ({ setShowAlert }) => {
  const md = useMediaQuery('(min-width: 768px)');
  const { t } = useTranslation('liquid-app');
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useLogin();
  const {
    exchangeRates,
    userBalances,
    controller,
    marketsInteracted,
    marketsInteractedAmount,
    markets,
  } = useAppSelector(protocolSelector);
  const { tokens: liquidStakingTokens } = useAppSelector(
    liquidStakingAppSelector,
  );
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const hasTakenBorrows = useAppSelector(hasTakenBorrowsSelector);

  const hasMaxMarketPerAccount = useMemo(
    () =>
      calcHasMaxMarketPerAccount({
        userBalances,
        controller,
        token: MARKET_KEY.sEGLD,
      }),
    [controller, userBalances],
  );

  const { signTransactions } = useSignMultipleTransactions();
  const {
    withdrawLiquidity,
    removeCollateral,
    supplyLiquidity,
    addCollateral,
    removeAccountMarket,
    supplyLiquidityAndAddCollateral,
  } = useLendInteraction();
  const defaultHintProps = useHintProps();
  const [selectedNumbers, setSelectedNumbers] = useState([0, 1]);
  const router = useRouter();
  const {
    maxRemovableCollateralBalance,
    recommendedMaxRemovableCollateralBalance,
  } = useLiquidStakingHsEgldCollateral();

  const {
    formsState: {
      migratingInputs: { inputValue, maxSelected, inputRef, onChange, onMax },
      operation,
    },
    migrating,
  } = useLiquidStakingForm();

  const options = useMemo(() => {
    return Object.values(liquidStakingTokens).sort((a, b) => a.index - b.index);
  }, [liquidStakingTokens]);

  const selected = useMemo(() => {
    return selectedNumbers.map((index) => options[index]);
  }, [options, selectedNumbers]);

  const getOtherOptions = useCallback(() => {
    return xorWith(options, selected, isEqual);
  }, [options, selected]);

  const firstSelected = useMemo(() => {
    return selected[0];
  }, [selected]);

  const secondSelected = useMemo(() => {
    return selected[1];
  }, [selected]);

  const options1 = useMemo(() => {
    return [firstSelected, getOtherOptions()[0]];
  }, [firstSelected, getOtherOptions]);

  const options2 = useMemo(() => {
    return [secondSelected, getOtherOptions()[0]];
  }, [secondSelected, getOtherOptions]);

  const exchangeRate = useMemo(() => {
    return (
      exchangeRates[
        `${firstSelected.symbol.toUpperCase()}/${secondSelected.symbol.toUpperCase()}`
      ] || '0'
    );
  }, [exchangeRates, firstSelected.symbol, secondSelected.symbol]);

  const inputValueUSD = useMemo(() => {
    return new DefiUtils(inputValue).toUSD(firstSelected.priceUSD).toString();
  }, [firstSelected.priceUSD, inputValue]);

  const receivedValue = useMemo(() => {
    return new DefiUtils(inputValue).multipliedBy(exchangeRate).toString();
  }, [exchangeRate, inputValue]);

  const receivedValueUSD = useMemo(() => {
    return new DefiUtils(receivedValue)
      .toUSD(secondSelected.priceUSD)
      .toString();
  }, [receivedValue, secondSelected.priceUSD]);

  const notes = useMemo(
    () => [
      {
        caption: t('exchange-rate'),
        tooltipMax: md ? 204 : 294,
        captionTooltip: (
          <>
            <Trans
              i18nKey='tp-14'
              components={[
                <ColoredName token={firstSelected.symbol} />,
                <ColoredName token={secondSelected.symbol} />,
              ]}
              ns='liquid-app'
            />
          </>
        ),
        value: (
          <>
            1 {firstSelected.symbol} ={' '}
            <Hint
              {...defaultHintProps}
              content={exchangeRate}
              hidden={new DefiUtils(exchangeRate).isZero()}
              className='-mt-1 sm:-mt-0.5'
            >
              {formatNumber(
                exchangeRate,
                new DefiUtils(exchangeRate).decimalPlaces() > 2
                  ? 2
                  : new DefiUtils(exchangeRate).decimalPlaces(),
              )}
            </Hint>{' '}
            {secondSelected.symbol}
          </>
        ),
      },
    ],
    [
      defaultHintProps,
      exchangeRate,
      firstSelected.symbol,
      secondSelected.symbol,
      t,
    ],
  );

  const hsegldToSEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.sEGLD,
  );

  const minValue = useMemo(() => {
    switch (firstSelected.symbol) {
      // 1 htoken en segld
      case EXCHANGE_RATE_KEY.sEGLD: {
        const result = new DefiUtils(1)
          .toFullDecimals(H_TOKEN_DECIMALS)
          .multipliedBy(hsegldToSEgld)
          .removeScientificNotation();

        return result;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return '0';
      }

      default: {
        return '0';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSelected.symbol, exchangeRate]);

  const isBtnDisabled = useMemo(() => {
    const fromIsHsegldCollateral =
      firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.collateral;

    return (
      !hasEnoughEGLDBalance ||
      new DefiUtils(inputValue).isGreaterThan(firstSelected.balance) ||
      new DefiUtils(inputValue).isZero() ||
      (fromIsHsegldCollateral &&
        new DefiUtils(inputValue).isGreaterThan(maxRemovableCollateralBalance))
    );
  }, [
    firstSelected.balance,
    firstSelected.source,
    firstSelected.symbol,
    hasEnoughEGLDBalance,
    inputValue,
    maxRemovableCollateralBalance,
  ]);

  const hasEightyPercentButton = useMemo(() => {
    const isHsEgldCollateral =
      firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.collateral;

    const isBalanceEqualToRecommended = new DefiUtils(
      firstSelected.balance,
    ).isEqualTo(recommendedMaxRemovableCollateralBalance);

    return (
      isHsEgldCollateral && hasTakenBorrows && !isBalanceEqualToRecommended
    );
  }, [
    firstSelected.balance,
    firstSelected.source,
    firstSelected.symbol,
    hasTakenBorrows,
    recommendedMaxRemovableCollateralBalance,
  ]);

  const error = useLiquidStakingMessageError({
    operation: OPERATION_KEYS.MIGRATE,
    balance: firstSelected.balance,
    inputValue: inputValue || '0',
    minValue,
    symbol: firstSelected.symbol,
    source: firstSelected.source as TOKEN_SOURCE,
  });

  const handleConnectWallet = () => {
    dispatch(
      openPopup({
        name: 'connect_bridge_multiverse_wallet',
      }),
    );
  };

  const sEgldToHsEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.HsEGLD,
  );
  const hsEgldToSEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.sEGLD,
  );

  const isFromSEgldWallet =
    firstSelected.symbol === EXCHANGE_RATE_KEY.sEGLD &&
    firstSelected.source === TOKEN_SOURCE.wallet;
  const isFromHsEgldWallet =
    firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    firstSelected.source === TOKEN_SOURCE.wallet;
  const isFromHsEgldCollateral =
    firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    firstSelected.source === TOKEN_SOURCE.collateral;

  const isToSEgldWallet =
    secondSelected.symbol === EXCHANGE_RATE_KEY.sEGLD &&
    secondSelected.source === TOKEN_SOURCE.wallet;
  const isToHsEgldWallet =
    secondSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    secondSelected.source === TOKEN_SOURCE.wallet;
  const isToHsEgldCollateral =
    secondSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    secondSelected.source === TOKEN_SOURCE.collateral;

  const handleMigrate = async () => {
    if (hasPendingTransactions || hasMigrateMaxMarketPerAccount) {
      return;
    }

    const tokenKey = sEgldMarket.underlying.symbol;
    const underlyingDecimals = sEgldMarket.underlying.decimals;

    if (isFromSEgldWallet && isToHsEgldWallet) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(sEgldToHsEgld)
        .toString();

      await signTransactions(
        [
          supplyLiquidity({
            tokenKey,
            amountAsBigInteger: new DefiUtils(inputValue)
              .toBasicUnits(underlyingDecimals)
              .toString(),
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET,
          result,
        },
      );
    } else if (isFromSEgldWallet && isToHsEgldCollateral) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(sEgldToHsEgld)
        .toString();

      const marketInteractedAvaiableToRemove = marketsInteracted.find(
        ({ canRemove }) => canRemove,
      );

      const hasMaxMarketPerAccountByController =
        marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
        !marketsInteracted
          .map(({ address }) => address)
          .includes(markets[MARKET_KEY.sEGLD]?.address || '') &&
        (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

      await signTransactions(
        [
          ...(hasMaxMarketPerAccountByController
            ? [
                removeAccountMarket(
                  marketInteractedAvaiableToRemove?.address || '',
                ),
              ]
            : []),
          supplyLiquidityAndAddCollateral({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(underlyingDecimals)
              .toString(),
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL,
          result,
          isSecuencial: hasMaxMarketPerAccountByController,
        },
      );
    } else if (isFromHsEgldWallet && isToHsEgldCollateral) {
      const result = inputValue;

      const marketInteractedAvaiableToRemove = marketsInteracted.find(
        ({ canRemove }) => canRemove,
      );

      const hasMaxMarketPerAccountByController =
        marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
        !marketsInteracted
          .map(({ address }) => address)
          .includes(markets[MARKET_KEY.sEGLD]?.address || '') &&
        (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

      await signTransactions(
        [
          ...(hasMaxMarketPerAccountByController
            ? [
                removeAccountMarket(
                  marketInteractedAvaiableToRemove?.address || '',
                ),
              ]
            : []),
          addCollateral({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            maxHTokenBalance: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isUnderlyingAmount: false,
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL,
          result,
          isSecuencial: hasMaxMarketPerAccountByController,
        },
      );
    } else if (isFromHsEgldWallet && isToSEgldWallet) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(hsEgldToSEgld)
        .toString();
      await signTransactions(
        [
          withdrawLiquidity({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isUnderlyingAmount: false,
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET,
          result,
        },
      );
    } else if (isFromHsEgldCollateral && isToHsEgldWallet) {
      const result = inputValue;

      await signTransactions(
        [
          removeCollateral({
            tokenKey,
            amountAsHTokenBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isMax: false,
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET,
          result,
        },
      );
    } else if (isFromHsEgldCollateral && isToSEgldWallet) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(hsEgldToSEgld)
        .toString();

      await signTransactions(
        [
          removeCollateral({
            tokenKey,
            amountAsHTokenBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isMax: false,
          }),
          withdrawLiquidity({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isUnderlyingAmount: false,
          }),
        ],
        {
          isSecuencial: true,
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET,
          result,
        },
      );
    }
  };

  const handleSwitch = () => {
    if (selectedNumbers[0] === firstSelected.index) {
      setSelectedNumbers([secondSelected.index, firstSelected.index]);
    } else {
      setSelectedNumbers([firstSelected.index, secondSelected.index]);
    }

    onChange('0');
  };

  const handleChange = (value: string) => {
    if (maxSelected) {
      return;
    }

    if (!value) {
      onChange(value);
      return;
    }

    onChange(value);
  };

  const handleChangeAmountLimit = (value: string) => {
    onChange(value);
  };

  const hasMigrateMaxMarketPerAccount = useMemo(() => {
    const isFromSEgldWallet =
      firstSelected.symbol === EXCHANGE_RATE_KEY.sEGLD &&
      firstSelected.source === TOKEN_SOURCE.wallet;
    const isFromHsEgldWallet =
      firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.wallet;

    const isToHsEgldCollateral =
      secondSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      secondSelected.source === TOKEN_SOURCE.collateral;

    return (
      (isFromSEgldWallet && isToHsEgldCollateral && hasMaxMarketPerAccount) ||
      (isFromHsEgldWallet && isToHsEgldCollateral && hasMaxMarketPerAccount)
    );
  }, [
    firstSelected.source,
    firstSelected.symbol,
    hasMaxMarketPerAccount,
    secondSelected.source,
    secondSelected.symbol,
  ]);

  ////////////
  const handleMax = () => {
    onMax(!maxSelected);
  };

  const handleFocus = async () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (operation !== Operation.MIGRATE) {
      return;
    }

    onMax(false);
    onChange('0');
    handleFocus();
    // inputTokenRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, selected]);

  useEffect(() => {
    const handleMax = () => {
      if (!maxSelected) {
        onChange('0');
        handleFocus();
        return;
      }

      let maxValue = '0';

      const { symbol, source, balance } = firstSelected;

      const isHsEgld = symbol === EXCHANGE_RATE_KEY.HsEGLD;

      const isHsEgldCollateral = isHsEgld && source === TOKEN_SOURCE.collateral;

      if (isHsEgldCollateral && hasTakenBorrows) {
        const isBalanceEqualToRecommended = new DefiUtils(balance).isEqualTo(
          recommendedMaxRemovableCollateralBalance,
        );

        maxValue = isBalanceEqualToRecommended
          ? recommendedMaxRemovableCollateralBalance
          : new DefiUtils(recommendedMaxRemovableCollateralBalance)
              .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
              .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN);
      } else if (isHsEgldCollateral && !hasTakenBorrows) {
        maxValue = maxRemovableCollateralBalance;
      } else {
        maxValue = balance;
      }

      const value = new DefiUtils(maxValue).removeScientificNotation();

      onChange(value);
      handleFocus();
    };

    handleMax();
  }, [maxSelected]);

  return (
    <form
      className={getFormControlClasses(migrating())}
      onSubmit={(event) => {
        event.preventDefault();

        if (isBtnDisabled) {
          return;
        }

        handleMigrate();
      }}
    >
      <div className='mx-auto mb-9 max-w-[246px] pb-4 text-center text-[#535367] dark:text-white'>
        {t('token-to-swap')}
        <div className='-mb-3' />
      </div>

      <div>
        <Label
          label=''
          valueText={t('total-available')}
          token={firstSelected.symbol}
          value={firstSelected.balance}
          valueUSD={firstSelected.balanceUSD}
          className='mb-1'
        />

        <div className='mt-1'>
          <MigrateInput
            ref={inputRef}
            key='input1'
            options={options1}
            decimalsLimit={firstSelected.decimals}
            onChange={(optionSelected: any) => {
              setSelectedNumbers((_) => [optionSelected.index, _[1]]);
            }}
            allowEdit
            mutableSource
            value={inputValue}
            valueUSD={inputValueUSD}
            onInputValueChange={handleChange}
            onMax={handleMax}
            maxSelected={maxSelected}
            hasHundredPercentButton={!hasEightyPercentButton}
          />

          <div className='-mt-2 mb-1 flex justify-center'>
            <SwitchBtn onClick={handleSwitch} />
          </div>

          <MigrateInput
            key='input1-replacement'
            mutableSource
            options={options2}
            onChange={(optionSelected: any) => {
              setSelectedNumbers((_) => [_[0], optionSelected.index]);
            }}
            value={receivedValue}
            valueUSD={receivedValueUSD}
            onInputValueChange={onChange}
          />
        </div>
      </div>

      {error && (
        <AmountLimitErrors
          {...error}
          operation={operation}
          token={firstSelected.symbol}
          onChange={handleChangeAmountLimit}
        />
      )}

      {notes.length && <Notes className='mb-9 mt-9' notes={notes} />}

      <AcceptDeal
        className='mb-9'
        iconSrc='https://cdn.app.hatom.com/images/liquidstaking/app/magic-hat.png'
        asMessage
        message={
          <div>
            <Trans
              i18nKey='migrate-note'
              components={[
                <span className='text-black dark:text-white' />,
                // eslint-disable-next-line @next/next/no-html-link-for-pages
                <a
                  href='/lend'
                  rel='noreferrer'
                  onClick={(e) => {
                    e.preventDefault();
                    router.push('/lend');
                  }}
                  className='text-[#006FFF] hover:underline'
                />,
              ]}
              ns='liquid-app'
            />
          </div>
        }
        checked
      />

      {isLoggedIn ? (
        <Hint
          content={
            hasPendingTransactions
              ? t('translation:wait-tx-inprogress')
              : hasMigrateMaxMarketPerAccount
              ? t('translation:limit-money-market-reached')
              : t('translation:tx-not-allowed')
          }
          placement='top-center'
          className='w-full'
          unvisible={!(hasPendingTransactions || hasMigrateMaxMarketPerAccount)}
        >
          <SubmitButton disabled={isBtnDisabled}>{t('migrate')}</SubmitButton>
        </Hint>
      ) : (
        <SubmitButton secondary onClick={handleConnectWallet}>
          {t('translation:connect-wallet')}
        </SubmitButton>
      )}
    </form>
  );
};
