import classNames from 'classnames';
import React, { useMemo } from 'react';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';
import useToggle from '@/hooks/useToggle';

import { SelectItem } from '@/sections/Liquid/App/Form/components/SelectItem';

export const WalletCollateralBalanceSwitcher: React.FC<any> = ({
  circular,
  options = [],
  onChange,
  select = 0,
}) => {
  const [open, , set] = useToggle();

  const selected = useMemo(() => {
    return options[select];
  }, [options, select]);

  const safeOptions = useMemo(() => {
    const clone = options.slice();
    clone.splice(select, 1);

    return [options[select], ...clone];
  }, [options, select]);

  return (
    <div className='relative z-10 h-[48px]'>
      <button
        type='button'
        className={classNames(
          'relative box-content flex flex-col overflow-hidden rounded-[8px] border bg-white text-left dark:bg-[#413c80]',
          selected?.symbol == EXCHANGE_RATE_KEY.HsEGLD
            ? 'border-[#A089E2]'
            : 'border-[#016fffa3]',
          'transition-all',
          'max-w-[184px] min-w-[184px] md:min-w-[203px]',
          open ? 'h-[92px]' : 'h-[46px]',
        )}
        onClick={() => {
          set(true);
        }}
        onBlur={() => {
          setTimeout(() => set(false), 50);
        }}
        data-testid='source'
        data-testvalue={selected?.source}
      >
        {safeOptions.map((e, i) => (
          <SelectItem
            key={i}
            caret={i === 0}
            onClick={() => {
              if (open) {
                // setSelected(e);
                onChange && onChange(e);
                setTimeout(() => set(false), 50);
              }
            }}
            highLighted={i == 0}
            circular={circular}
            data-testid={`source:${e.source}`}
            {...e}
          />
        ))}
      </button>
    </div>
  );
};
