import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DefiUtils from 'defi-utils';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  getTotalBorrowUSD,
  getTotalSupplyUSD,
} from '@/store/parsers/landing-parser';
import {
  htmMarketSelector,
  MARKET_KEY,
  wtaoMarketSelector,
} from '@/store/protocol';

import blockchainService from '@/services/blockchain';
import logger from '@/utils/logger';

export interface LandingState {
  boosterTotalStakedUSD: string;
  totalSupplyUSD: string;
  totalBorrowUSD: string;
  totalValueLockedUSD: string;
}

const initialState: LandingState = {
  boosterTotalStakedUSD: '0',
  totalSupplyUSD: '0',
  totalBorrowUSD: '0',
  totalValueLockedUSD: '0',
};

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setLanding: (state, action: PayloadAction<Partial<LandingState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof LandingState] = value;
      });
    },
  },
});

export const { setLanding } = landingSlice.actions;

export const getLandingData =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const {
        markets,
        liquidStaking,
        liquidStakingTao,
        booster,
        isolatedLendingProtocols,
        tokensMap,
      } = state.protocol;

      const [stakePools] = await Promise.all([
        blockchainService.lens.getUSHStakingStakePools(),
      ]);

      const stakingMainTokensIds = stakePools.map((token) => token.mainToken);

      const [stakeMainTokensPrice] = await Promise.all([
        blockchainService.lens.getBoosterV2TokenPriceUsd(stakingMainTokensIds),
      ]);

      const ushToken = markets['USH'].underlying;

      const isolatedLendingProtocolTVL = Object.values(isolatedLendingProtocols)
        .reduce(
          (prev, { totalBorrows, totalCollateral, collateralTokenId }) => {
            const collateralToken =
              markets[collateralTokenId.split('-')[0] as MARKET_KEY]
                ?.underlying;

            const totalBorrowsUSD = new DefiUtils(totalBorrows)
              .toFullDecimals(ushToken.decimals)
              .toUSD(ushToken.priceUSD)
              .toString();
            const totalCollateralUSD = new DefiUtils(totalCollateral)
              .toFullDecimals(collateralToken?.decimals)
              .toUSD(collateralToken?.priceUSD)
              .toString();

            return prev.plus(totalBorrowsUSD).plus(totalCollateralUSD);
          },
          new DefiUtils(0),
        )
        .toString();

      const stakePoolsUSD = stakePools
        .reduce((prev, current) => {
          const tokenItem = tokensMap[current.mainToken.split('-')[0]];
          const tokenPricesItem = stakeMainTokensPrice.find(
            (tokenPriceItem) => tokenPriceItem.tokenId === current.mainToken,
          );

          const priceUSD = new DefiUtils(tokenPricesItem?.amount || '0')
            .toFullDecimals(18)
            .toString();

          const result = new DefiUtils(current.totalStake)
            .toFullDecimals(tokenItem.decimals)
            .toUSD(priceUSD)
            .toString();

          return prev.plus(result);
        }, new DefiUtils(0))
        .toString();

      const ushTVL = new DefiUtils(isolatedLendingProtocolTVL)
        .plus(stakePoolsUSD)
        .toString();

      const htmMarket = htmMarketSelector(state);
      const wtaoMarket = wtaoMarketSelector(state);

      const totalBorrowUSD = getTotalBorrowUSD(markets);
      const totalSupplyUSD = getTotalSupplyUSD(markets);

      const boosterTotalStakedUSD = new DefiUtils(booster.totalStaked)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toUSD(htmMarket.underlying.priceUSD)
        .toSafeString();

      const taoTokenSupplyUSD = new DefiUtils(liquidStakingTao.tokenSupply)
        .toFullDecimals(wtaoMarket.underlying.decimals)
        .toUSD(wtaoMarket.underlying.priceUSD)
        .toSafeString();

      const totalValueLockedUSD = new DefiUtils(totalSupplyUSD)
        .plus(taoTokenSupplyUSD)
        .plus(liquidStakingTao.totalStakedUSD)
        .plus(liquidStaking.totalStakedUSD)
        .plus(boosterTotalStakedUSD)
        .plus(isolatedLendingProtocolTVL)
        .plus(ushTVL)
        .toString();

      dispatch(
        setLanding({
          boosterTotalStakedUSD,
          totalBorrowUSD,
          totalSupplyUSD,
          totalValueLockedUSD,
        }),
      );
    } catch (error) {
      logger.error('store:getLandingData', error?.toString());
    }
  };

export const landingSelector = (state: RootState) => state.landing;

export default landingSlice.reducer;
