import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAppDispatch } from '@/store';
import { setAccountState, setLoginInfoState } from '@/store/auth';
import { setNetworkState } from '@/store/network';

import { LoginMethods } from '@/config/walletProviders';
import { webviewProvider } from '@/providers/webviewProvider';
import { sleep } from '@/utils/helpers';
import logger from '@/utils/logger';
import { decodeNativeAuthToken } from '@/utils/nativeAuth/decodeNativeAuth';

const useLoginWithNativeAuthToken = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const removeQueryParam = (param: string) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  const handleInit = async () => {
    try {
      const accessToken = router.query?.accessToken as string;
      const isWebViewProvider = !!accessToken;

      if (!isWebViewProvider) {
        return;
      }

      const nativeAuthInfo = decodeNativeAuthToken(accessToken);

      if (!nativeAuthInfo) {
        return;
      }

      localStorage.clear();

      dispatch(
        setLoginInfoState('loginMethod', LoginMethods.experimentalWebview),
      );
      dispatch(setLoginInfoState('loginToken', accessToken));
      const currentDate = new Date();
      dispatch(setLoginInfoState('expires', currentDate.setMonth(currentDate.getMonth() + 12 * 50)));
      dispatch(setLoginInfoState('signature', nativeAuthInfo.signature));
      dispatch(setAccountState('address', nativeAuthInfo.address));
      dispatch(setAccountState('nonce', 0));
      dispatch(setAccountState('balance', 0));
      dispatch(setNetworkState('dappProvider', webviewProvider));

      sleep(100).then(() => {
        removeQueryParam('accessToken');
      });
    } catch (error) {
      logger.error(error?.toString());
    }
  };

  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoginWithNativeAuthToken;
