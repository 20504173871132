import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatAccount,
  formatAccountClaims,
  formatAccountRewards,
  formatAccountRewardsPerMarket,
  formatAccumulators,
  formatRewardBatches,
  formatStakeTokens,
  getAccumulatorsPairsIds,
} from '@/store/parsers/booster-v2-parser';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import * as xexchangeService from '@/services/xexchange';
import logger from '@/utils/logger';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

interface Account {
  totalStakedUSD: string;
  instaCompliance: string;
  s_instaCompliance: string;
  topCompliance: string;
  s_topCompliance: string;
  nextInstaCompliance: string;
  s_nextInstaCompliance: string;
  totalCollateralUSD: string;
  maxTotalStakedUSD: string;
}

interface RewardBatches {
  tokenId: string;
  token: {
    id: string;
    symbol: string;
    logo: string;
    decimals: number;
  };
  isActive: boolean;
  baseAPY: string;
  standardAPY: string;
  extraAPY: string;
  totalAPY: string;
  logo: string;
  totalCollateralUSD: string;
  collateralUSD: string;
  extraCollateralUSD: string;
  account: {
    totalAPY: string;
    baseAPY: string;
    standardAPY: string;
    extraAPY: string;
    collateral: string;
    collateralUSD: string;
    extraCollateral: string;
    extraCollateralUSD: string;
  };
  rewards: {
    reward: {
      id: string;
      decimals: number;
      symbol: string;
      priceUSD: string;
      logo: string;
    };
    isActive: boolean;
    isExtra: boolean;
    stakingRatio: string;
    s_stakingRatio: string;
    baseRatio: string;
    totalCollateralUSD: string;
    speed: string;
    logo: string;
    name: string;
    /**
     * @deprecated
     */
    apy: string;
    baseAPY: string;
    standardAPY: string;
    extraAPY: string;
    totalAPY: string;
    account: {
      totalAPY: string;
      extraAPY: string;
      baseAPY: string;
      standardAPY: string;
    };
  }[];
}

interface StakeTokens {
  id: string;
  isMetaEsdt: boolean;
  nonce: string;
  symbol: string;
  defaultSymbol: string;
  decimals: number;
  priceUSD: string;
  logo: string;
  account: {
    walletBalance: string;
    stakedBalance: string;
    s_walletBalance: string;
    s_stakedBalance: string;
    stakedBalanceUSD: string;
    walletBalanceUSD: string;
  };
}

interface AccountReward {
  tokenId: string;
  userRewardBalance: string;
  userRewardBalanceUSD: string;
  symbol: string;
  priceUSD: string;
  logo: string;
  name: string;
  decimals: number;
}

interface AccountRewardPerMarket {
  tokenId: string;
  reward: {
    id: string;
    decimals: number;
    symbol: string;
    priceUSD: string;
  };
  s_amount: string;
  amountUSD: string;
}

interface Accumulators {
  [key: string]: {
    exchangeRate: string;
    premium: string;
  };
}

interface AccountClaim {
  id: string;
  claimTimestamp: string;
  claimed: boolean;
  s_amount: string;
  amountUSD: string;
  token: {
    id: string;
    nonce: string;
    decimals: number;
    priceUSD: string;
    symbol: string;
    defaultSymbol: string;
    logo: string;
  };
}

export interface BoosterV2State {
  currentBatch: number;

  controllerAccount: Account;
  controllerRewardBatches: RewardBatches[];
  controllerStakeTokens: StakeTokens[];
  controllerAccountRewards: AccountReward[];
  controllerAccumulators: Accumulators;
  controllerAccountClaims: AccountClaim[];
  controllerAccountRewardsPerMarket: AccountRewardPerMarket[];

  stakingAccount: Account;
  stakingRewardBatches: RewardBatches[];
  stakingStakeTokens: StakeTokens[];
  stakingAccountRewards: AccountReward[];
  stakingAccumulators: Accumulators;
  stakingAccountClaims: AccountClaim[];
  stakingAccountRewardsPerMarket: AccountRewardPerMarket[];
}

const defaultAccount: Account = {
  totalStakedUSD: '0',
  instaCompliance: '0',
  s_instaCompliance: '0',
  topCompliance: '0',
  s_topCompliance: '0',
  nextInstaCompliance: '0',
  s_nextInstaCompliance: '0',
  totalCollateralUSD: '0',
  maxTotalStakedUSD: '0',
};

const initialState: BoosterV2State = {
  currentBatch: 0,
  
  controllerAccount: { ...defaultAccount },
  controllerRewardBatches: [],
  controllerStakeTokens: [],
  controllerAccountRewards: [],
  controllerAccumulators: {},
  controllerAccountClaims: [],
  controllerAccountRewardsPerMarket: [],

  stakingAccount: { ...defaultAccount },
  stakingRewardBatches: [],
  stakingStakeTokens: [],
  stakingAccountRewards: [],
  stakingAccumulators: {},
  stakingAccountClaims: [],
  stakingAccountRewardsPerMarket: [],
};

export const boosterV2Slice = createSlice({
  name: 'boosterV2',
  initialState,
  reducers: {
    setBoosterV2: (state, action: PayloadAction<Partial<BoosterV2State>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof BoosterV2State] = value;
      });
    },

    updateBoosterV2: (
      state,
      action: PayloadAction<DeepPartial<BoosterV2State>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as BoosterV2State] = Array.isArray(value)
          ? value
          : typeof value === 'object'
          ? {
              ...state[key as keyof BoosterV2State],
              ...value,
            }
          : value;
      });
    },
  },
});

export const { setBoosterV2, updateBoosterV2 } = boosterV2Slice.actions;

const getBoosterDataByProgramId =
  (programId: 1 | 2, accountAddress: string) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    const {
      protocol: { markets, tokensMap },
      auth: {
        account: { tokens: accountTokens },
      },
    } = getState();

    const [
      responseBoosterBatches,
      responseStakes,
      responseAccountStakes,
      responseAccountInstaCompliance,
      responseAccumulators,
      responseAccountBoosterRewardsList,
      responseAccountExtraAmount,
      responseAccountClaims,
      responseBoosterV2AccountRewardsPerMarket,
    ] = await Promise.all([
      blockchainService.lens.getBoosterV2BatchData({ programId }),
      blockchainService.lens.getBoosterV2Stakes({ programId }),
      blockchainService.lens.getBoosterV2AccountStakes({
        programId,
        accountAddress,
      }),
      blockchainService.lens.getBoosterV2AccountInstaCompliance({
        programId,
        accountAddress,
        tokenIdentifier: 'messi',
        action: 'None',
      }),
      blockchainService.lens.getControllerAccumulators(),
      blockchainService.lens.getBoosterV2AccountRewards({
        programId,
        accountAddress,
      }),
      blockchainService.lens.getBoosterV2AccountExtraAmount({
        programId,
        accountAddress,
      }),
      blockchainService.lens.getBoosterV2AccountClaims({
        programId,
        accountAddress,
      }),
      blockchainService.lens.getBoosterV2AccountRewardsPerMarket({
        programId,
        accountAddress,
      }),
    ]);

    const accumulators = responseAccumulators.map(
      ({ premium, tokenId, swapPath }) => ({
        premium,
        rewardsToken: {
          id: tokenId,
          symbol: tokenId.split('-')[0],
        },
        swapPath: swapPath.map(({ outputTokenId }) => ({
          id: outputTokenId,
        })),
      }),
    );

    const pairsIds = getAccumulatorsPairsIds(accumulators);

    const tokenBoosterIds = [
      ...(new Set([
        ...responseBoosterBatches.map(({ marketId }) => marketId),
        ...responseStakes,
      ]) as any),
    ]

    const uniquePairsIds: string[] = [...(new Set(pairsIds) as any)];

    const [pairs = [], boosterV2TokenPriceUSD] = await Promise.all([
      xexchangeService.getPairs(uniquePairsIds).catch(() => {}),
      blockchainService.lens.getBoosterV2TokenPriceUsd(tokenBoosterIds),
    ]);

    const stakeTokens = formatStakeTokens({
      stakes: responseStakes,
      accountTokens,
      tokensMap,
      markets,
      accountStakes: responseAccountStakes,
      boosterV2TokenPriceUSD,
    });

    const account = formatAccount({
      accountInstaCompliance: responseAccountInstaCompliance,
      stakeTokens: stakeTokens,
    });

    const rewardBatches = formatRewardBatches({
      programId,
      boosterBatches: responseBoosterBatches,
      markets,
      topCompliance: account.topCompliance || '0',
      instaCompliance: account.instaCompliance || '0',
      accountExtraAmount: responseAccountExtraAmount,
      boosterV2TokenPriceUSD,
      tokensMap,
    });

    account.totalCollateralUSD = rewardBatches
      .reduce(
        (prev, current) =>
          prev
            .plus(current.account.collateralUSD)
            .plus(current.account.extraCollateralUSD),
        new DefiUtils(0),
      )
      .toString();

    account.maxTotalStakedUSD = rewardBatches
      .reduce((prev, { rewards, account }) => {
        const result = rewards
          .filter(({ isActive, isExtra }) => isActive && !isExtra)
          .reduce(
            (prev, { s_stakingRatio }) =>
              prev.plus(
                new DefiUtils(account.collateralUSD || 0)
                  .multipliedBy(s_stakingRatio)
                  .toSafeString(),
              ),
            new DefiUtils(0),
          )
          .toString();
        return prev.plus(result);
      }, new DefiUtils(0))
      .toString();

    const program = programId === 1 ? 'controller' : 'staking';

    const results: DeepPartial<BoosterV2State> = {
      [`${program}RewardBatches`]: rewardBatches,
      [`${program}StakeTokens`]: stakeTokens,
      [`${program}AccountRewardsPerMarket`]: formatAccountRewardsPerMarket({
        boosterV2AccountRewardsPerMarket:
          responseBoosterV2AccountRewardsPerMarket,
        tokensMap,
        markets,
      }),
      [`${program}AccountRewards`]: formatAccountRewards({
        accountBoosterRewardsList: responseAccountBoosterRewardsList,
        tokensMap,
        markets,
      }),
      [`${program}Accumulators`]: formatAccumulators({
        pairs,
        accumulators,
      }),
      [`${program}AccountClaims`]: formatAccountClaims({
        accountClaims: responseAccountClaims,
        tokensMap,
        markets,
        boosterV2TokenPriceUSD,
      }),
      [`${program}Account`]: account,
    };

    logger.info(results);

    await dispatch(addAction(updateBoosterV2(results)));
  };

export const getBoosterV2Data =
  ({ accountAddress }: { accountAddress: string }) =>
  async (dispatch: AppDispatch) => {
    try {
      await Promise.all([
        dispatch(getBoosterDataByProgramId(1, accountAddress)),
        dispatch(getBoosterDataByProgramId(2, accountAddress)),
      ]);
    } catch (error) {
      logger.error('store:getBoosterBatch', error);
      captureException(error);
    }
  };

export const boosterV2Selector = (state: RootState) => state.boosterV2;

export default boosterV2Slice.reducer;
