import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';

const CurrencyInputButton: React.FC<any> = ({
  onClick,
  selected,
  borderLess = false,
  children,
  className
}) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.button
        className='group right-1 top-8 cursor-pointer text-sm font-semibold leading-tight'
        onClick={onClick}
        data-testid='maxButton'
        type='button'
      >
        <div
          className={classNames(
            'rounded-[8px] border p-[9.5px] py-[3px]',
            !borderLess ? 'border-current' : 'border-transparent',
            selected
              ? [
                'border-current bg-[#9999B8]/10 text-[#9999B8]',
                'dark:bg-[#9999B8]/30 dark:text-white',
              ]
              : [
                !borderLess && 'group-hover:bg-[#9999B8]/5',
                'dark:border-[#625E8F] text-[#9999B8] dark:text-[#625E8F]',
                'dark:group-hover:bg-indigo-200/5',
              ],
              className
          )}
        >
          {children}
        </div>
      </m.button>
    </LazyMotion>
  );
};

export default CurrencyInputButton;
