import { captureException } from '@sentry/nextjs';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import {
  getLedgerErrorCodes,
  useLedgerLogin,
} from '@/hooks/auth/providers/useLedgerLogin';
import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect';

import BasicRow from '@/components/BasicRow';
import Hint from '@/components/Hint';
import LedgerConnectAnimation from '@/components/popups/LedgerAccountsPopup/components/LedgerConnectAnimation';
import PaginationButton from '@/components/popups/LedgerAccountsPopup/components/PaginationButton';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import ScamAdvice from '@/components/ScamAdvice';
import TableHeader from '@/components/TableHeader';
import Ledger from '@/components/WalletLogos/components/Ledger';

import { loggingInSelector } from '@/store/auth';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { closePopup } from '@/store/popup';

import { formatNumber } from '@/utils/helpers';
import logger from '@/utils/logger';
import { shortenHash } from '@/utils/shortenHash';

interface Account {
  address: string;
  balance: string;
  index: string;
}

const LedgerAccountsPopup = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(5);
  const { pending } = useAppSelector(loggingInSelector);

  const { getAccounts, loginAccount } = useLedgerLogin();

  const dispatch = useAppDispatch();
  const [colsSizes] = useState([60]);

  const loadAccounts = async () => {
    try {
      setIsLoadingAccounts(true);

      const accounts = await getAccounts(page, pageSize);

      setAccounts(accounts);
    } catch (error: any) {
      logger.error(error);
      const data = getLedgerErrorCodes(error);
      const errorMessage =
        data?.errorMessage || data?.defaultErrorMessage || error.toString();
      if (!errorMessage.includes('Check if MultiversX app is open on Ledger')) {
        captureException(errorMessage);
      }
      toast.error(errorMessage);
      dispatch(closePopup());
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  useIsomorphicLayoutEffect(() => {
    loadAccounts();
  }, [page]);

  const fetching = isLoadingAccounts || pending;
  const { t } = useTranslation();

  return (
    <PopupBed close={!fetching} width={439}>
      <div>
        {fetching && (
          <PopupHead>
            <div className='flex items-center gap-2'>
              <div>
                <Ledger width={18} />
              </div>
              {t('login-with', { provider: 'Ledger' })}
            </div>
          </PopupHead>
        )}

        <div
          className={classNames(
            'text-sm font-semibold leading-tight',
            'text-[#53648C]',
          )}
        >
          {fetching ? (
            <div className='flex flex-col items-center px-7 pb-[29.5px] pt-[33px]'>
              <div className='mx-3 self-stretch font-normal'>
                <ScamAdvice />
              </div>
              <div className={classNames('mt-9')}>
                {pending ? t('waiting-confirmation') : t('waiting-device')}
              </div>
              <div className={classNames('mt-9')}>
                <LedgerConnectAnimation />
              </div>
            </div>
          ) : (
            <div className='flex flex-col px-5 pb-[9.5px] pt-10'>
              <div className='mb-5 text-center text-lg leading-tight tracking-wide'>
                <div className='mb-5 flex items-center justify-center gap-2'>
                  <div>
                    <Ledger height={38} width='auto' />
                  </div>
                  <div
                    className={classNames(
                      'text-[#2A3E6C] dark:text-white',
                      'text-2xl',
                    )}
                  >
                    Ledger
                  </div>
                </div>
                <h3
                  className={classNames(
                    'text-[#1A1A1A] dark:text-white',
                    'mb-2 leading-[23px]',
                  )}
                >
                  {t('choose-wallet')}
                </h3>
                <p
                  className={classNames(
                    'text-base leading-tight',
                    'text-[#535367] dark:text-[#625E8F]',
                  )}
                >
                  {t('wallet-provider.hint')}
                </p>
              </div>

              <div className='mx-3 mb-5 self-stretch font-normal'>
                <ScamAdvice />
              </div>

              <div className=''>
                <TableHeader
                  className={classNames(
                    'gap-1 !py-0',
                    'text-[#535367] dark:text-[#625E8F]',
                  )}
                  headers={[
                    {
                      label: 'Address',
                    },
                    {
                      label: 'Balance',
                    },
                    {
                      label: '#',
                    },
                  ]}
                  themeless
                  preset={colsSizes}
                />

                {accounts.map((account) => (
                  <BasicRow
                    key={account.address}
                    onClick={() => {
                      loginAccount(parseInt(account.index));
                    }}
                    className={classNames(
                      'items-center gap-1',
                      'mt-4 h-[40px] cursor-pointer rounded-[8px] px-3',
                      'text-right text-sm leading-tight',
                      'border',
                      'transition-colors duration-75',
                      'border-[#D3D0E4] bg-white text-[#535367] dark:border-[#2E2C51] dark:bg-[#1C1A35] dark:text-[#625E8F]',
                      'hover:border-[#006FFF] hover:bg-[#006FFF] hover:text-white',
                      'dark:hover:border-transparent dark:hover:bg-[#459E81] dark:hover:text-white',
                    )}
                    cells={[
                      <Hint block content={account.address}>
                        <div className='text-left'>
                          {shortenHash(account.address, 10)}
                        </div>
                      </Hint>,
                      <span className={classNames('truncate')}>{`${formatNumber(
                        account.balance,
                      )} EGLD`}</span>,
                      account.index,
                    ]}
                    preset={colsSizes}
                  />
                ))}

                <div className='mt-2 flex justify-center gap-5'>
                  <PaginationButton
                    disabled={page <= 0}
                    onClick={() => setPage(page - 1)}
                  ></PaginationButton>
                  <PaginationButton
                    onClick={() => setPage(page + 1)}
                    next
                  ></PaginationButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PopupBed>
  );
};

export default LedgerAccountsPopup;
